import { createSlice } from '@reduxjs/toolkit'

export interface GPTState {
  threadId: string;
  regenerate: any;
}

const initialGPTState: GPTState = {
  threadId: "",
  regenerate: {}
}

const chatGptSlice = createSlice({
    name: 'chatGPT',
    initialState: initialGPTState,
    reducers: {
      setGptThreadId: (state,action) => {
        return{
          ...state,
          threadId: action.payload?.threadId,
          regenerate: action.payload?.regenerate
        }
      },
      resetGptThreadId: (state) => {
        state.threadId = ""
        state.regenerate = {}
      }
    }
})

export const { setGptThreadId, resetGptThreadId } = chatGptSlice.actions
export default chatGptSlice.reducer