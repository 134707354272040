/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Navigation } from '../Navigation'
import { RightNavigation } from '../RightNavigation'
import { Header } from '../Header'
import { APP_BG } from '../../utils/constants'

import { lefttoggle, righttoggle } from '../../features/toggle/ToggleReducer'
// for dispatching action and to getting sate
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import Notification from '../RightNavigation/Notification/Notification'
import CallLogs from '../RightNavigation/CallLogs/CallLogs'

// define interface to represent component props
interface Props {
  children: ReactNode
}
export const Layout: FC<Props> = ({ children }) => {

  const dispatch = useAppDispatch()
  // toggle using reducer
  const open = useAppSelector((state) => state.drawertoggle.leftopen)
  const toggleNavigation = () => {
    dispatch(lefttoggle())
  }
  const openRightNavigation = useAppSelector((state) => state.drawertoggle.rightopen)
  const openCallLogNavigation = useAppSelector((state) => state.notificationState.callLogNotificationOpen)
  
  const[quickViewOpen, setQuickViewOpen] = useState<boolean>(false)
  const[callLogOpen, setCallLogOpen] = useState<boolean>(false)
  const toggleRightNavigation = () => {
    dispatch(righttoggle())
  }
  useEffect(() => {
    if(callLogOpen) setQuickViewOpen(false);
  },[callLogOpen])
  useEffect(() => {
    if(quickViewOpen) setCallLogOpen(false);
  },[quickViewOpen])

  useEffect(() => {
    setCallLogOpen(false);
    setQuickViewOpen(false);
  },[openCallLogNavigation])
  
  return (
    <div
      css={css`
        min-height: 100vh;
      `}
    >
      <div
        css={css`
          display: flex;
          min-height: calc(100vh - 0px);
        `}
      >
        <Box component='header' style={{display:"flex"}}>
          <Header
            toggleNavigation={toggleNavigation}
            navigationOpen={open}
            rightNavigationOpen={openRightNavigation}
            quickViewOpen={quickViewOpen}
            callLogOpen={callLogOpen}
          />
        </Box>
        <Box style={{display:"flex"}}>
          <Navigation
            open={open}
            handleClose={toggleNavigation}
            toggleNavigation={toggleNavigation}
          />
        </Box>
        <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 8, pb: 1, backgroundColor: `${APP_BG}`, overflow: 'hidden' }}>
          {children}
        </Box>
        <Box style={{display:"flex"}} >
          <Box className='rightPanelContainer' style={{width: (quickViewOpen || callLogOpen) ? "360px" : '0'}}>
            {quickViewOpen && 
              <Box style={{width: "360px", height: '100%'}}>
                <Notification setQuickViewOpen={setQuickViewOpen}/>
              </Box>
            }
            {callLogOpen && 
              <Box style={{width: "360px", height: '100%'}}>
              <CallLogs/>
              </Box>
            }
          </Box>
          <RightNavigation
            open={openRightNavigation}
            handleClose={toggleRightNavigation}
            toggleNavigation={toggleRightNavigation}
            toggleQuickView={() => setQuickViewOpen(!quickViewOpen)}
            toggleCallLogView ={() => setCallLogOpen(!callLogOpen)}
          />
        </Box>
      </div>
    </div>
  )
}
