/** @jsxImportSource @emotion/react */
import { Box, Button, Grid } from '@mui/material'
import { Breadcrumb } from '../components/Breadcrumb'
import DashboardEvents from '../components/Dashboard/DashboardEvents/DashboardEvents'
import Popupwindow from '../components/Popupwindow/Popupwindow'
import "../App.scss"
import { useAppDispatch, useAppSelector } from '../store/hooks'
import GppBadIcon from '@mui/icons-material/GppBad';
import { useEffect, useState } from 'react'
import { mandateUnAuthorized } from '../features/mandateDetail/DrawerReducer'
import { Helmet } from 'react-helmet'
import HiringDemandWidget from '../components/Common/DashboardWidgets/HiringDemandWidget/HiringDemandWidget'
import JobswithCandidateWidget from '../components/Common/DashboardWidgets/JobswithCandidateWidget/JobswithCandidateWidget'
import OfferManagementWidget from '../components/Common/DashboardWidgets/OfferManagementWidget/OfferManagementWidget'
import ClientCandidateActivity from '../components/Common/DashboardWidgets/ClientCandidateactivity/ClientCandidateactivity'
import RecruiteConltantCanditateactivityWidget from '../components/Common/DashboardWidgets/RecruiteConltantCanditateactivityWidget/RecruiteConltantCanditateactivityWidget'
import CandidateSatusactivity from '../components/Common/DashboardWidgets/CandidateSatusactivity/CandidateSatusactivity'
import JobOrderTableWidget from '../components/Common/DashboardWidgets/JobOrderWidget/JobOrderTableWidget'
import DashboardFilter from '../components/Common/CustomeDasboardFilter/DashboardFilter'
import FunnelWidget from '../components/Common/DashboardWidgets/FunnelWidget/FunnelWidget'
import { useLocation } from 'react-router-dom'



function Dashboard() {
  const welcomeCardData = useAppSelector((state: any) => state.SystemConfig.welcomeConfigData);
  const userFirstName = useAppSelector((state: any) => state.UserDetail.firstName);
  const userId = useAppSelector((state: any) => state.UserDetail.userId);
  const unAuthorizedMessage = useAppSelector((state) => state.mandatedrawer.unAuthorizedError);
  const [hideUnAuthorized, setHideUnAuthorized] = useState(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
  const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
  const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>({})
  const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
  const [cdm, setCdm] = useState<boolean>(false);



  setTimeout(() => {
    setHideUnAuthorized(false)
    dispatch(mandateUnAuthorized({ unAuthorizedError: "" }))
  }, 5000);
  useEffect(() => {
    if (userId) {
      setCdm(true);
    }
  }, [userId])  
  if (!cdm) {
    return <div>Loading..</div>
  }

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-2Q9QZKWW6B"></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-2Q9QZKWW6B',{
          'user_id': '${userId}'
        });
        `}
        </script>
      </Helmet>
      <Breadcrumb title='Dashboard' />

      {/* <DashboardFilter HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels}/> */}

      {(hideUnAuthorized && (unAuthorizedMessage && unAuthorizedMessage.length > 0)) && <p className='unAuthorizedMessage'><GppBadIcon />{unAuthorizedMessage}</p>}
      <Box className="welcomeCardSect">        
        {welcomeCardData != undefined &&  welcomeCardData?.length >0 && <div dangerouslySetInnerHTML={{__html: welcomeCardData[0]?.configValue?.replace("{loggedInUserName}", userFirstName)}}></div>}
      </Box>

      
      {/* <Popupwindow/> */}

      {/* <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm:1, md: 2 }}>
      
      <Grid item xs={12} lg={12}>
      <HiringDemandWidget HirningDemandParamsDetails={HirningDemandParamsDetails}  reportSubmissionAPIData={((Object.keys((HirningDemandParamsDetails).length > 0 ? Object.keys(HirningDemandParamsDetails?.state?.filterList).length > 0 : false)) && Object.keys(reportSubmissionAPIData).length == 0) ? HirningDemandParamsDetails?.state?.filterList : reportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels}/>
      </Grid>

      <Grid item xs={12} lg={12}>
        <FunnelWidget reportSubmissionAPIData={reportSubmissionAPIData} reportSubmitdatalabels={reportSubmitdatalabels}/>
      </Grid>

      <Grid item xs={12} lg={6}>
        <JobswithCandidateWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>
      <Grid item xs={12} lg={6}>
      <OfferManagementWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>

      <Grid item xs={12} lg={6}>
        <ClientCandidateActivity reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>
      <Grid item xs={12} lg={6}>
      <RecruiteConltantCanditateactivityWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>

      <Grid item xs={12} lg={12}>
        <CandidateSatusactivity reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>

      <Grid item xs={12} lg={12}>
        <JobOrderTableWidget reportSubmissionAPIData={reportSubmissionAPIData}/>
      </Grid>

    </Grid> */}

    <Box sx={{marginTop:"15px"}}><DashboardEvents/></Box>
    </>
  )
}

export default Dashboard
