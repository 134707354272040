import React, { useEffect, useState } from 'react'
import ViewHeader from './ViewHeader/ViewHeader'
import ViewContainer from './ViewContainer/ViewContainer'
import moment from 'moment-timezone'
import { getAllocatedMandateCount } from '../../services/WorkManagementService';
import './index.scss';
import RecuriterInformation from './recruiterInformation/recruiterInformation';
import { useAppDispatch } from '../../store/hooks';
import { leftdrawertoggle } from '../../features/toggle/ToggleReducer';

const WorkManagementTab = () => {
  const [selectedDate, setSelectedDate] = useState<any>(moment())
  const [selectedMonth, setSelectedMonth] = useState<any>(moment().month())
  const [viewType, setViewType] = useState<string>('D')
  const [workRecuirterToggle, setWorkRecuirterToggle] = useState(false)
  const dispatch = useAppDispatch()
  const [initialState, setInitialState] = useState(false)

  useEffect(() => {
    if(initialState)setSelectedDate(moment())
      setInitialState(true)
  }, [viewType])

  dispatch(leftdrawertoggle())

  return (
    <>
      <div className={`work-management-container-wrapper ${!workRecuirterToggle ? "wrkRecuriterToggle" : ""}`}>
        <div className='col-one'>
          <ViewHeader
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            viewType={viewType}
            setViewType={setViewType}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
          />
          <ViewContainer viewType={viewType} selectedDate={selectedDate} selectedMonth={selectedMonth} />
        </div>
        <div className='col-two'>
          <RecuriterInformation setWorkRecuirterToggle = {setWorkRecuirterToggle} />
        </div>
      </div>

    </>
  )
}

export default WorkManagementTab
