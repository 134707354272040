import { FormControl,TextField, InputLabel, Select, OutlinedInput, Box, Chip, Checkbox, InputAdornment } from "@mui/material"
import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './DepartmentDropDown.scss'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { getDepartmentRoles } from "../../../services/MandateService";
import DepartmentPopOver from "./DepartmentPopOver";
import * as _ from 'lodash'
import { Label } from "@mui/icons-material";
import Popover from '@mui/material/Popover';



const DepartmentDropDown = ({onChange,value ,placeholder="Add Department/Role",reportDropdownAPIData,preSelectedItems}:any) => {



  const [inputValue, setInputValue] = useState<any>({label:'', count:"",placeholder:placeholder})
  const [selectedItems, setSelectedItems] = useState<any>({ value: preSelectedItems.length == 0 ? value=[] : preSelectedItems, labels: [] })
  const [departmentSearch, setDepartmentSearch] = useState<string>('')
  // const [selectedItems, setSelectedItems] = useState<any>(value)
  const [deptData,setDeptData] =useState<any>([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popoverWidth, setpopoverWidth] = useState<any>()

  const openMultiDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setpopoverWidth(event.currentTarget.getBoundingClientRect().width)
  }
  const closeMultiDropdown = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  

  const fetchData = async () => {
    const reqObj = {
      "filters": [],
      "page": {
          "pageNo": 0,
          "pageSize": 10
      },
      "sorting": {
          "direction": "ASC",
          "field": "createdAt"
      }
  }
    try{
      if(reportDropdownAPIData){
        setDeptData(reportDropdownAPIData);
      }else{
        const res:any = await getDepartmentRoles(reqObj)
        setDeptData(res.data.result.list);
      }
      


    } catch {
      setDeptData([]);
  
    }
  }
  
  useEffect(() => {

    // const DepartmentId = _.minBy(selectedItems.value, 'departmentId');
    if(selectedItems?.value?.length){
      const DepartmentId =selectedItems?.value[0].departmentId
      const DepartmentObj = deptData.filter((deb:any)=> deb.id ==DepartmentId)
      const Name =DepartmentObj[0]?.depName
      const anySelect = _.find(selectedItems.value, { isAllowAssociate: 1, departmentId:DepartmentId });
      let counttext=''
      const itemsWithId = _.filter(selectedItems.value, { departmentId: DepartmentId});
      const distinctDep = _.uniqBy(selectedItems.value, "departmentId")
      if(anySelect){
        counttext =`(any) \u00A0 \u00A0 ${distinctDep.length > 1 ? `+${distinctDep.length - 1}` : ""}`
      }
      else{
        counttext=`(+${itemsWithId.length}) \u00A0 \u00A0 ${distinctDep.length > 1 ? `+${distinctDep.length - 1}` : ""}`
      }

      
    setInputValue({label:Name, count:counttext,placeholder:placeholder})
    }
    else{
      setInputValue({label:'',count:'',placeholder:placeholder})
    }
    onChange(selectedItems.value)
   
  }, [selectedItems,deptData])


  useEffect(()=>{
    fetchData()
  },[])



  return (


    <div className="multilevel-dropdown">
    

      <div  className="input-box" onClick={(event:any) => openMultiDropdown(event)}>
      {inputValue.label ?
       <div className="input-label-text">
       {inputValue.label}
       </div>:
       <div className="input-placeholder-text">
      {inputValue.placeholder }
      </div>
       }
     
      {inputValue.count &&  <div className="input-count-text">
      {inputValue.count }
      </div>}

     

     
      <div className="custom-arrow-icon">
      {
        anchorEl?<ArrowDropUpIcon />:<ArrowDropDownIcon />
      }
      </div>
      
      </div>
      {/* {openMultiDrop && <div className="overlay-multiselect" onClick={closeMultiDropdown}> 
      </div>}*/}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeMultiDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: `${popoverWidth}px` },
        }}
      >
        <div className="input-list">
          <FormControl variant="standard">
              <OutlinedInput
                  className="depIndSearch"
                  placeholder="Search Department/Role"
                  value={departmentSearch}
                  onChange={(e) => setDepartmentSearch(e.target.value)}
                  startAdornment={
                  <InputAdornment position="start">
                      <SearchIcon />
                  </InputAdornment>
                  }
              />
          </FormControl>
          {deptData.map((dep: any, index: number) => {
            const isDepartmentSearchParentExist = departmentSearch == "" ? true : dep.departmentRoles.find((depSrc:any) => depSrc.roleName?.toLowerCase().includes(departmentSearch?.toLowerCase()) || depSrc.children.find((depSrc:any) => depSrc.roleName?.toLowerCase().includes(departmentSearch?.toLowerCase())))
            return <>
                {dep.depName?.toLowerCase().includes(departmentSearch?.toLowerCase()) || isDepartmentSearchParentExist ? 
                <div key={index}>
                  <DepartmentPopOver
                  index={index}
                  dep={dep}
                  selectedItems ={selectedItems}
                  setSelectedItems ={setSelectedItems}
                  departmentSearch={departmentSearch}
                  />
                </div> : <></>}
              </>
          })}
        </div>
        </Popover>
    </div>
  )
}

export default DepartmentDropDown


