import React from 'react'
import styled from '@emotion/styled'
import { Modal, ModalProps, Stack, Typography } from '@mui/material'
import { AppButton } from '../AppButton'
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg'
import { ReactComponent as FailureIcon } from '../../../assets/icons/failed.svg'
import { ReactComponent as Warning } from '../../../assets/images/Warning.svg'
import './AppErrorHandlerModal.css'

const CustomModal = styled(Modal)<CustomModalProps>(() => ({}))

type CustomModalProps = {
  iconType?: string
  handleClose?: () => void
  handleConfirm?: () => void
  factor?: string
  content?: string
  cancelText?: string
  confirmationBtn?: string
  diableConfirmBtn?:boolean
} & ModalProps

export const AppConfirmationHandlerActionModal: React.FC<CustomModalProps> = ({
  iconType,
  open,
  handleClose,
  handleConfirm,
  factor,
  content,
  cancelText,
  confirmationBtn,
  diableConfirmBtn
}): React.ReactElement => {
  return (
    <>
      <CustomModal onClose={handleClose} open={open}>
        <div className='modalbox-container confirmationAlertModal'>
          <Stack direction='column' spacing={1} sx={{ mt: 2, mb: 3 }}>
            <Typography component='div' className='check-icon'>
              {iconType == "success" ? <CheckIcon /> : iconType == "failer" ? <FailureIcon /> : <Warning />}
            </Typography>
            <Typography component='p'> {factor} </Typography>
            <h4 dangerouslySetInnerHTML={{ __html: content ?? ""}} />
          </Stack>
          <AppButton
            variant='text'
            onClick={handleClose}
            textColor='#002882'
            fontSize='18px'
            fontWeight={400}
            label={cancelText}
            className='confirmationCloseBtn'
          ></AppButton>
          <AppButton
            variant='text'
            onClick={handleConfirm}
            textColor='#002882'
            fontSize='18px'
            fontWeight={400}
            label={confirmationBtn}
            disabled={diableConfirmBtn == undefined ? false : diableConfirmBtn}
            className='confirmationActionBtn'
          ></AppButton>
        </div>
      </CustomModal>
    </>
  )
}
