import { Breadcrumb } from '../components/Breadcrumb'
import WorkManagementTab from '../components/WorkManagement'


const WorkManagement = () => {
  return (<>
    <Breadcrumb title='Work Management' />
    <WorkManagementTab/>
      </>

  )
}

export default WorkManagement