import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/backarrowicon1.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClientProfile from '../../assets/icons/clientDetails.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import "./CreateUser.scss"
import PersonalInformation from './PersonalInformation';
import EmployeeDetails from './EmployeeDetails';
import CallingUserDetails from './CallingUserDetails';
import ImageCropProvider from '../Common/ProfileUpload/ImageCropProvider';
import ImageCrop from '../Common/ProfileUpload/ImageCrop';
import ProfileUrlArray from './ProfileUrlArray';
import CreateUserProfile from "../../assets/icons/createUserProfile.svg"
import CreateUserEmployee from "../../assets/icons/createUserEmployee.svg"
import CreateUserDocument from "../../assets/icons/createUserDocument.svg"
import CreateUserCall from "../../assets/icons/createUserCall.svg"
import CreateUserSocialLink from "../../assets/icons/createUserSocialLink.svg"
import { getRoleList, postCreateUser, postUpdateUser } from '../../services/UserService';
import moment from 'moment';
import { AppErrorHandlerModal } from '../AppComponents/AppErrorHandlerModal';
import { toast } from "react-toastify";
import AttachmentFieldsFormProfile from '../Mandates/AddMandate/MandateForm/AttachmentFieldsFormProfile';

interface IFormInput {
    employeeProfile: string;
    employeeName: string;
    dateOfBirth: any;
    primaryEmail: string;
    personalEmail: string;
    residentialaddress: string;
    primaryContact: string;
    secondaryContact: string;
    employeeCode: string;
    role:string;
    xphenoJoinDate:any;
    previousExperience: any;
    designation: string;
    bussinessUnit: string;
    reportingTo: string;
    domain: string;
    employmentLocation: string;
    smartFlowUsername: string;
    smartFlowPassword: string;
    attachments: any[];
    profileUrl: any[];
    primaryContactCode: string;
    secondaryContactCode: string
  }

const CreateUser = () => {
    const location = useLocation();    
    const [userDetailData, setUserDetailData] = useState(location?.state?.userDetail)
    const isEdit:any = location?.state?.status
    const initValues = {
        employeeProfile: "",
        employeeName: isEdit ? userDetailData?.firstName : "",
        dateOfBirth: isEdit ? new Date(userDetailData?.dob) : null,
        primaryEmail: isEdit ? userDetailData?.userEmail : "",
        personalEmail: isEdit ? userDetailData?.secondaryEmail : "",
        residentialaddress: isEdit ? userDetailData?.addressDetails : "",
        primaryContact: isEdit ? userDetailData?.userMobile : "",
        secondaryContact: isEdit ? userDetailData?.secondaryMobile : "",
        employeeCode: isEdit ? userDetailData?.employeeCode : "",
        role:isEdit ? userDetailData?.roleId : "",
        xphenoJoinDate: isEdit ? userDetailData?.xphenoJoiningDate ? new Date(userDetailData?.xphenoJoiningDate) : null : null,
        previousExperience: isEdit ? parseInt(userDetailData?.previousExperienceStr) : "",
        designation: isEdit ? userDetailData?.designation : "",
        bussinessUnit: isEdit ? userDetailData?.businessUnitId : "",
        reportingTo: isEdit ? userDetailData?.reporting_to_value : "",
        domain: isEdit ? userDetailData?.domain : "",
        employmentLocation: isEdit ? userDetailData?.employmentLocation : "",
        smartFlowUsername: isEdit ? userDetailData?.sfloId : "",
        smartFlowPassword: isEdit ? userDetailData?.sfloPwd : "",
        attachments: [{ key: '', value: null }],
        profileUrl: isEdit ? userDetailData?.profileUrl.length > 0 ? JSON.parse(userDetailData?.profileUrl) : [{ profileUrl: ''}] : [{ profileUrl: ''}],
        primaryContactCode: isEdit ? userDetailData?.primaryContactNumberCode ? userDetailData?.primaryContactNumberCode : "91" : "91",
        secondaryContactCode: isEdit ? userDetailData?.secondaryContactNumberCode ? userDetailData?.secondaryContactNumberCode : "91" : "91"
        // clientId: (isEdit || isClone) ? mandateData?.clientId : "",
      }
      const scrollToError = () => {
        setTimeout(() => {
          const container = document.querySelector(`.reminder_text-danger`);
          container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },100)      
      }
    const navigate =useNavigate()    
    const [employeeRoleList,setEmployeeRoleList] = useState([])
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        navigate('/users')
      }
    const {
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      clearErrors 
    } = methods

    const getRoleListApi = async () => {
        try{
          const res:any = await getRoleList()
          setEmployeeRoleList(res.data.result.data)
        }
        catch(error:any){
            console.log(error)        
          }
    }

    useEffect(() => {
        getRoleListApi()
    },[])

    const formDataManipulation = (reqObj:any) => {
        const filterRoleId:any = employeeRoleList.filter((roleItem:any) => roleItem?.id == reqObj.role)        
        const roleMapping =  (filterRoleId[0].keycloak_id != null ? filterRoleId[0].keycloak_id : "")+"/"+filterRoleId[0].role_name;
        const formData:any = {
            "user": {
                "email": reqObj.primaryEmail,
                "firstName": reqObj.employeeName,
                "emailVerified": "true",
                "attributes": {
                    "roleMapping": [roleMapping],
                    "roleName": [reqObj.role],
                    "businessUnit": [reqObj.bussinessUnit],
                    "reportingTo": [reqObj.reportingTo.value],
                    "employeeCode": [reqObj.employeeCode]
                }
            },
            "tblUsersEntity": {
                "buId": reqObj.bussinessUnit,
                "roleId": reqObj.role,
                "secondaryContactNumberCode": reqObj.secondaryContactCode,
                "primaryContactNumberCode": reqObj.primaryContactCode,
                "reportingTo": reqObj.reportingTo.value,
                "username": reqObj.employeeName,
                "userEmail": reqObj.personalEmail,
                "userMobile": reqObj.primaryContact,
                "dob": moment(new Date(reqObj.dateOfBirth)).format('YYYY-MM-DD'),
                "addressDetails": reqObj.residentialaddress,
                "secondaryMobile": reqObj.secondaryContact,
                "secondaryEmail": reqObj.personalEmail,
                "previousExperience": moment().subtract(reqObj?.previousExperience, 'year').format('YYYY-MM-DD'),
                "employeeCode": reqObj.employeeCode,
                "xphenoJoiningDate": moment(new Date(reqObj.xphenoJoinDate)).format('YYYY-MM-DD'),
                "designation": reqObj.designation,
                "status": isEdit ? (userDetailData.status == 136 ? 0 : 1) : 1,
                "firstName": reqObj.employeeName,
                "createdBy": "32853c57-5a71-490c-9b8d-d7952d3abeb8",
                "sfloPwd": reqObj.smartFlowPassword,
                "sfloId": reqObj.smartFlowUsername,
                "domain": reqObj.domain,
                "employmentLocation": reqObj.employmentLocation,
                "profileUrl": JSON.stringify(reqObj.profileUrl)
            }
        }
        const result = reqObj?.attachments?.reduce(function (r: any, e: any) {
            if(e?.value?.name){
              r[e?.value?.name] = e?.key;
            }
            return r;
          }, {});
        
        formData['files'] = result
        return formData
    }

    const mandateSubmitHandler = async (data:any, event:any) => {   
        const mandateData = new FormData()
        const payload = formDataManipulation(data)
        mandateData.append('users', JSON.stringify({...payload}))      
        data?.attachments?.forEach((file: any) => {
            mandateData.append('documents', file.value);
        });
        mandateData.append('photoLink', data.employeeProfile)
        try{
            const response = isEdit ? await postUpdateUser (mandateData, userDetailData?.keyCloakId) : await postCreateUser(mandateData)
            if (response?.status == 200 || response?.status == 201) {
                // if (response?.data?.result?.message?.includes("successfully")) {                
                setSuccessMessage(isEdit ? 'User has been successfully Updated' : 'User has been successfully created')
                setUploadsuccess(true)
                // }
            }
        }
        catch(error:any){
            if (error?.response?.status == 500 || error?.response?.status == 409) {
                toast.error("response" in error ? error?.response?.data?.message : error?.message)
            }
        }
    }
    return (
        <>
            <Box className='mandate-wrapper'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  navigate(`/users`)}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>
                    <div className='header-text'>
                        {isEdit ? "Edit" : "New"} User
                    </div>
                </div>
                <Box className="createRoleAccordionSect">
                <FormProvider {...methods}>
                    <Box
                        component="form"
                        data-testid="formHandle"
                        id="mandateForm"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(mandateSubmitHandler)}
                    >                       
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CreateUserProfile} alt="otherInfo" />
                            <div>Personal Information</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <PersonalInformation isEdit={isEdit} profileLink={userDetailData?.profileImageUrl}/>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CreateUserEmployee} alt="otherInfo" />
                            <div>Employement Details</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <EmployeeDetails />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CreateUserSocialLink} alt="otherInfo" />
                            <div>Social Profiles</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <ProfileUrlArray/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CreateUserDocument} alt="otherInfo" />
                            <div>Documents</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <AttachmentFieldsFormProfile isEdit={(isEdit)} mandateData={userDetailData} />
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CreateUserCall} alt="otherInfo" />
                            <div>Calling</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <CallingUserDetails />
                        </AccordionDetails>
                    </Accordion>
                    <Box className='button-container'>
                        <Button 
                            className='cancel-btn'
                            onClick={()=> window.history.back()}
                        >Cancel</Button>
                        <Button 
                            className='save-btn'
                            type='submit'
                            // disabled={isSubmitting || endDateError}
                            onClick={() => {scrollToError()}}
                        >
                            Save
                        </Button>
                        </Box>
                    </Box>
                </FormProvider>
                <AppErrorHandlerModal
                open={uploadsuccess}
                content={SuccessMessage}
                handleClose={handleClose}
                factor='Success'
                ButtonText='Done'
                ><></></AppErrorHandlerModal>
                </Box>
            </Box>
        </>
    );
};

export default CreateUser;