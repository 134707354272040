import * as React from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
    Autocomplete,
    Snackbar,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    Chip,
    Checkbox,
    TextFieldProps,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, OutlinedInput, Hidden
  } from '@mui/material'
  import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
  import "./BulkAllocationViewContainer.scss"
  import { AppButton } from '../../AppComponents/AppButton';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxWidth: "1000px",
    height: 'auto',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    padding: "22px 15px 20px 20px",
  }

  type AppDialogProps = {
    title: string
    subtitle?: string
    isOpen: boolean
    handleClose: () => void
    handleConfirm: () => void
    cancelButtonText?: string
    OkButtonText?: string
    listedError?:any
    locationObj?:any
  }

const BulkAllocationConfirmationPopup = (props: AppDialogProps): React.ReactElement => { 
    const {
      title,
      subtitle,
      isOpen,
      handleClose,
      handleConfirm,
      cancelButtonText,
      OkButtonText,
      listedError,
      locationObj
    } = props

    const showErrorLocationList = (errorLocObj:any) => {
        const errorDataStru:any = []
        errorLocObj.map((item:any) => {
            errorDataStru.push(locationObj[item])
        })
        return errorDataStru.join(",")
     }
    return (
        <>
        <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        >
        <Box>
            <Box sx={style} className='popup-wrap confirmationPopupSect'>
                <div className='header-wrapper' style={{paddingRight:"22px"}}>
                    <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000', fontWeight:"600" }}>
                        {title}
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={() => handleClose()}
                    >
                        <CloseIcon style={{cursor:"pointer"}} />
                    </IconButton>
                </div>
                <h1>{subtitle}</h1>
                {OkButtonText == "null" ? 
                <ul className='assigneeBulkError'>
                {JSON.parse(listedError)?.map((errorList: any, index: number) => (
                    <>
                    <li>
                        {`${errorList?.allocatedUserName} (${showErrorLocationList(errorList?.locationIds)})`}
                    </li>
                    </>
                ))}
                </ul>  
                : <h2>{listedError}</h2>}
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                    gap: '15px',
                    paddingLeft: "32px",
                    paddingRight:"10px",
                    margin:"50px 0px 0px 0px"
                    }}
                >
                    <AppButton
                        bgcolor='#E5E8EB'
                        onClick={() => handleClose()}
                        textColor='#000'
                        fontSize='14px'
                        fontWeight={400}
                        label={cancelButtonText ? cancelButtonText : 'Cancel'}
                        width='96px'
                    ></AppButton>
                    {OkButtonText != "null" && <AppButton
                        bgcolor={`${OkButtonText == "reallocation" ? "#2bad63" : "#f34334"}`}
                        type='submit'
                        textColor='#ffffff'
                        fontSize='14px'
                        fontWeight={400}
                        onClick={() => handleConfirm()}
                        label={OkButtonText}
                        width='120px'
                        disabled={false}
                        style={{color:"#fff"}}
                    ></AppButton>}
                </Box>
            </Box>            
        </Box>
        </Modal>
        </>
    )
};
export default BulkAllocationConfirmationPopup