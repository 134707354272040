import React, { useMemo, useState } from 'react';
import './ViewTree.css';
import { TreeDataNode, Input, Tree } from 'antd';
import { Box } from '@mui/material';

const { Search } = Input;

const ViewTreeList = ({treeSelectedList}:any) => {  
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeListData, setTreeListData] = useState(treeSelectedList)
const getKeysList:any = [];
  const getAllKeys = ((obj:any) => {    
    obj.map((item:any) => {
      getKeysList.push(item.key)
      if("children" in item) getAllKeys(item.children)
    })
    return getKeysList;
  })
  const allKeys = getAllKeys(treeListData);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(allKeys);
  const onExpand = (newExpandedKeys: React.Key[]) => {
      setExpandedKeys(newExpandedKeys);
      setAutoExpandParent(false);
  };

const defaultData = treeListData;

const dataList: { key: React.Key; title: string }[] = [];
const generateList = (data: TreeDataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key } = node;
    const { title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};
generateList(defaultData);

const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};



  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newExpandedKeys = dataList
      .map((item) => {
        if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          return getParentKey(item.key, treeListData);
        }
        return null;
      })
      .filter((item, i, self): item is React.Key => !!(item && self.indexOf(item) === i));
    setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const treeData = useMemo(() => {
    const loop = (data: TreeDataNode[]):any =>
      data.map((item) => {
        const strTitle = item.title as string;
        const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
        searchValue.length > 0 && index > -1 ? (
            <span>
              {/* {beforeStr} */}
              <span className="site-tree-search-value">{strTitle}</span>
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return { title, key: item.key, children: loop(item.children) };
        }

        return {
          title,
          key: item.key,
        };
      });

    return loop(treeListData);
  }, [searchValue]);

  return (
    <div className='roleTreeViewSectScroll'>
      <div className='roleTreeViewSect' style={{float:"left"}}>
      <Box className="roleTreeViewSectBlk">
        <h5>Selected Access</h5>
        <Search placeholder="Search Keyword" onChange={onChange} />        
        <Tree
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          treeData={treeData}
        />
      </Box>
      </div>
    </div>
    
  );
};

export default ViewTreeList;