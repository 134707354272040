import jwtDecode from 'jwt-decode'


// validate the JWT token if expired or not
// if token expired, redirect to login page
const AuthTokenVerify = (token:any) => {
const authToken = token["access_token"]
  if (authToken) {
      const decodedJwt:any = jwtDecode(authToken);
      if (decodedJwt) {
          return decodedJwt.exp * 1000 < Date.now()
      }
  }
  return null;
};

export default AuthTokenVerify;
