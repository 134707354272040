import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'
import _ from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const CandidateExperience = ({primaryList}:any) => {
    const { control, formState: { errors }, getValues,watch, setValue, clearErrors, trigger }: any = useFormContext();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const candidateWorkData = watch('candidateWorkExperience')
    const { fields: attachmentFields, append: attachmentAppend, remove: attachmentRemove } = useFieldArray(
        {
            control,
            name: "candidateWorkExperience"
        }
    );
    return (
        <>
            <Grid className='container-wrapper'>
                <Box className="personalInfoWrapper" style={{ flexDirection: "column" }}>
                    <Box className="personalInfoFieldWrapper">
                        {attachmentFields.map((item: any, index: any) => {
                            return (
                                <div style={{ display: "flex", alignItems: "flex-start" }} key={item.id}>
                                    <Box style={{ width: "100%" }}>
                                        <Grid className='row-grid mb-3' >
                                            <FormControl fullWidth required>
                                                {/* <InputLabel id="demo-simple-select-label">Company Name</InputLabel> */}
                                                <Controller
                                                    name={`candidateWorkExperience.${index}.companyName`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "This field is required",
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value, onBlur } }) => (
                                                        <TextField
                                                            label='Company Name'
                                                            required={true}
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        />
                                                    )}
                                                />
                                                {_.get(errors, `candidateWorkExperience.${index}.companyName`) && (
                                                    <span className="reminder_text-danger select-danger-text" style={{ marginTop: "20px" }} role="alert">
                                                        {_.get(errors, `candidateWorkExperience.${index}.companyName`)?.message}
                                                    </span>
                                                )}
                                            </FormControl>
                                            <FormControl fullWidth required>
                                                {/* <InputLabel id="demo-simple-select-label">Title</InputLabel> */}
                                                <Controller
                                                    name={`candidateWorkExperience.${index}.workTitle`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "This field is required",
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value, onBlur } }) => (
                                                        <TextField
                                                            label='Title'
                                                            required={true}
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        />
                                                    )}
                                                />
                                               {_.get(errors, `candidateWorkExperience.${index}.workTitle`) && (
                                                    <span className="reminder_text-danger select-danger-text" style={{ marginTop: "20px" }} role="alert">
                                                        {_.get(errors, `candidateWorkExperience.${index}.workTitle`)?.message}
                                                    </span>
                                                )}
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name={`candidateWorkExperience.${index}.workLocation`}
                                                    control={control}
                                                    render={({ field: { onChange, value, onBlur } }) => (
                                                        <Autocomplete
                                                            options={primaryList}
                                                            value={primaryList.find((loc:any) => loc.label === value)}
                                                            onChange={(event, value) => {
                                                                onChange(value)
                                                            }}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Location"
                                                                // required
                                                            />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <Controller
                                                    name={`candidateWorkExperience.${index}.workPreferredLocation`}
                                                    control={control}
                                                    render={({ field: { onChange, value, onBlur } }) => (
                                                        <Autocomplete
                                                            options={primaryList}
                                                            value={primaryList.find((loc:any) => loc.label === value)}
                                                            onChange={(event, value) => {
                                                                onChange(value)
                                                            }}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Preferred Location"
                                                                // required
                                                            />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid className='row-grid mb-3' style={{width:"100%"}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Controller
                                                    name={`candidateWorkExperience.${index}.workStartDate`}
                                                    control={control}
                                                    render={({ field: { onChange, ...restField } }) => (
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: "calc(25% - 10px)" }}>
                                                            <DatePicker
                                                                label='Start Date'
                                                                className='datePicker'
                                                                inputFormat="DD/MM/YYYY"
                                                                onChange={(e: any) => { onChange(e) }}
                                                                components={{
                                                                    OpenPickerIcon: CalenderIcon,
                                                                }}
                                                                // minDate={moment().subtract(1,'years').toDate()}
                                                                // maxDate={moment().toDate()}
                                                                renderInput={(params: any) => (
                                                                    <TextField
                                                                        name='Start Date'
                                                                        style={{ width: "100%" }}
                                                                        {...params}
                                                                    />
                                                                )}
                                                                {...restField}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            <Box className={`${candidateWorkData[index].candidateWorkCurrentCompany ? "disableEndDate" : ""}`} style={{width: "calc(25% - 10px)"}}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <Controller
                                                        name={`candidateWorkExperience.${index}.workEndDate`}
                                                        control={control}                                                   
                                                        render={({ field: { onChange, ...restField } }) => (
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: "100%" }}>
                                                                <DatePicker
                                                                    label='End Date'
                                                                    className='datePicker endDataField'
                                                                    inputFormat="DD/MM/YYYY"
                                                                    onChange={(e: any) => { onChange(e) }}
                                                                    components={{
                                                                        OpenPickerIcon: CalenderIcon,
                                                                    }}
                                                                    // minDate={moment().subtract(1,'years').toDate()}
                                                                    // maxDate={moment().toDate()}
                                                                    renderInput={(params: any) => (
                                                                        <TextField
                                                                            name='End Date'
                                                                            style={{ width: "100%" }}
                                                                            {...params}
                                                                        />
                                                                    )}
                                                                    {...restField}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                                                    <Controller
                                                        name={`candidateWorkExperience.${index}.candidateWorkCurrentCompany`}
                                                        control={control}
                                                        render={({ field: { onChange, value, onBlur } }) => (
                                                            <FormControlLabel control={
                                                                <Switch 
                                                                checked={value}
                                                                onChange={onChange} />
                                                            } label="Currently working" />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <FormControl fullWidth style={{width:"50%"}}>
                                                <Controller
                                                    name={`candidateWorkExperience.${index}.workSummary`}
                                                    control={control}
                                                    render={({ field: { onChange, value, onBlur } }) => (
                                                        <TextField
                                                            label='Description'
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            multiline={true}
                                                            minRows={3}
                                                            inputProps={{ maxLength: 256 }}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Box>
                                    {attachmentFields.length > 1 && <div
                                        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                        onClick={() => attachmentRemove(index)}
                                        className='delete-icon'
                                    >
                                        <DeleteIcon style={{ marginTop: "15px", marginLeft: "15px" }} />
                                    </div>}
                                </div>
                            )
                        })}
                    </Box>
                    <Box className='button-container'
                        style={{ justifyContent: "flex-start", padding: "0rem 0rem 1rem 0rem" }}
                    >
                        <Button
                            className='draft-btn'
                            style={{ width: "180px" }}
                            onClick={() => {
                                attachmentAppend({ companyName: '',workTitle: '',workLocation: '',workPreferredLocation: '',workStartDate: null,workEndDate: null,workSummary:'',candidateWorkCurrentCompany:'' });
                            }}
                        >+ Add Experience</Button>
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

export default CandidateExperience;