import { Box, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { toast } from "react-toastify";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excelIcon.svg"
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-icon.svg"
import { ReactComponent as Wordicon } from "../../assets/icons/wordicon.svg"
import { ReactComponent as Pdf } from "../../assets/icons/pdf.svg"

import CloseIcon from '@mui/icons-material/Close';

const CandidateFileAttach = ({onChange}:any) => {
    const fileExtension = ["application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","text/csv"]
    const [attachResume, setAttachResume] = useState<any>(null)
    const handleFileChange = async (files: any) => {
        const file = files && files[0];
        setAttachResume(file)
        onChange(file)
    };
    return (
        <div>
            {attachResume == null ? <div>
                <input
                    type="file"
                    onChange={(event: any) => {
                        if (fileExtension.includes(event?.target?.files?.[0]?.type)) {
                            if (event?.target?.files?.[0]?.size <= 5000000) {
                                handleFileChange(event.target.files)
                            } else {
                                toast.error('Max file size is 5MB')
                            }
                        } else {
                            toast.error('Upload file type not accepted')
                        }
                    }}
                    className="hidden"
                    id="candidateAttach"
                    accept=".doc,.docx,.pdf"
                />
                <label htmlFor="candidateAttach" className="cursor-pointer">                    
                    <section className="container" style={{float:"left"}}>
                        <div className='custom-dropzone'>
                            <div className='file-name-container'>
                                <div className='file-upload-icon'>
                                    <UploadIcon />
                                    <span>Upload Document</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </label>
            </div> : 
            <Box className="candidateAttach">
                    <Box className="candidateAttachFileSect">
                        {attachResume.type == "application/pdf" ? <Pdf /> : <Wordicon />}<p>{attachResume?.name}</p>
                        <CloseIcon className='closeAttach' onClick={() => {setAttachResume(null)}}/>
                    </Box>
                    <Box className="candidateAttachActionSect">
                        <UploadIcon />
                        <input
                            type="file"
                            onChange={(event: any) => {
                                if (fileExtension.includes(event?.target?.files?.[0]?.type)) {
                                    if (event?.target?.files?.[0]?.size <= 5000000) {
                                        handleFileChange(event.target.files)
                                    } else {
                                        toast.error('Max file size is 5MB')
                                    }
                                } else {
                                    toast.error('Upload file type not accepted')
                                }
                            }}
                            className="hidden"
                            id="candidateReAttach"
                            accept=".doc,.docx,.pdf"
                        />
                        <label htmlFor="candidateReAttach" className="cursor-pointer"><p>Reupload CV</p></label>       
                    </Box>
                </Box>}
        </div>

    );
};

export default CandidateFileAttach;