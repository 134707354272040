import React from 'react'
import Select, { SelectProps } from '@mui/material/Select'
import styled from '@emotion/styled'
import { PRIMARY1 } from '../../../utils/constants'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, createTheme } from '@mui/material'
import './AppSelect.css'
const theme = createTheme({
  palette: { primary: { main: '#d1005e' } },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'yellow',
          '&.Mui-selected': {
            backgroundColor: 'green',
            '&.Mui-focusVisible': { background: 'orange' },
          },
        },
      },
    },
  },
})

const CustomAppselect = styled(Select)<CustomSelectProps>(() => ({
  height: '40px',
  ['& .MuiSelect-icon']: {
    marginTop: '3px',
  },
  ['& .css-10q54uo-MuiSelect-icon']: {
    marginTop: '0px',
  },
  '& fieldset': {
    borderRadius: '10px',
    border: '1px solid rgba(207, 209, 210, 1)',
  },
  '&.MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '1px solid rgba(207, 209, 210, 1)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(207, 209, 210, 1)',
      color: 'yellow',
    },
  },
  ['& .filter-funnel']: {
    fill: PRIMARY1,
  },
  ['& .filter-arrow']: {
    width: '40px',
  },

  '& .MuiInputBase-inputAdornedStart': {
    marginLeft: '7px',
  },
  '&.Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
}))

const Myplaceholder = styled('span')({
  color: '#b8b8b8',
  // marginLeft: '10px',
})

type RenderplaceholderProps = {
  placeholder?: string
}

export const Renderplaceholder: React.FC<RenderplaceholderProps> = (props): React.ReactElement => {
  const { placeholder } = props
  return <Myplaceholder>{placeholder}</Myplaceholder>
}

type CustomSelectProps = {
  name: string
} & SelectProps

export const AppControlSelect: React.FC<CustomSelectProps> = ({
  name,
  ...otherProps
}): React.ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      render={({ field }) => (
        <>
          <CustomAppselect
            sx={{ mb: 0 }}
            {...otherProps}
            {...field}
            error={!!errors[name]}
            className='appselect-cls'
          />
          <Box
            sx={{ color: '#db3131', fontSize: '12px', marginLeft: '1rem', marginBottom: '1.5rem' }}
            className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-174in1s-MuiFormHelperText-root'
          >
            {errors[name] ? (errors[name]?.message as unknown as string) : ''}
          </Box>
        </>
      )}
    />
  )
}
