import { 
    Eventcalendar, 
    setOptions, 
    CalendarNav, 
    SegmentedGroup, 
    SegmentedItem, 
    CalendarPrev, 
    CalendarToday, 
    CalendarNext, 
    MbscCalendarEvent, 
    MbscEventcalendarView,
    Button,
    Page,
    Popup,
    Input
} from '@mobiscroll/react';
import { googleCalendarSync } from '@mobiscroll/calendar-integration';
import './calendar.scss'
import {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import _ from 'lodash'
import { useAppSelector } from '../../store/hooks';
import { Alert, Snackbar } from '@mui/material';

setOptions({
    theme: 'auto',
    themeVariant: 'light'
});

const getRondomColor = () => {
    const arr: string[] = [
      '#FF6633', '#E6B333', '#3366E6', '#999966', '#B34D4D',
      '#80B300', '#809900', '#6680B3', '#66991A', '#E666FF',
      '#4D8000', '#B33300', '#CC80CC', '#66664D', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#4D8066',
      '#809980', '#1AFF33', '#999933', '#FF3380', '#CCCC00',
      '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380',
      '#FF4D4D', '#99E6E6', '#6666FF'
    ]
    const randomIndex = Math.floor(Math.random() * arr.length)
    const itemColor = arr[randomIndex]

    return itemColor;
  }

 const Calendar = ({calendarIds, setCalendarIds, eventsList, handleEventClick,logStatus, setLogStatus }:any) => {
    const [view, setView] = useState('week');
    // const [myEvents, setEvents] = useState<MbscCalendarEvent[]>([]);
    const [pageEvents, setPageEvents] = useState<any[]>([]);
    const [googleEvents, setGoogleEvents] = useState<any[]>([]);
    const [calView, setCalView] = useState<MbscEventcalendarView>(
        {
            schedule: { type: 'week' }
        }
    );
    const [searchInput, setSearchInput] = useState<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [listEvents, setListEvents] = useState<MbscCalendarEvent[]>([]);
    const [currentDate, setCurrentDate] = useState<any>();
    const [mySelectedEvent, setSelectedEvent] = useState<MbscCalendarEvent[]>([]);
    const inputRef = useRef<any>();
    const startDate = useRef<any>();
    const endDate = useRef<any>();
    const [signUpStatus, setSignUpStatus] = useState(logStatus? logStatus : null)
    const localState:any = localStorage.getItem('state')
    const configTemplate = useAppSelector((state:any) => state.SystemConfig.systemConfigData)
    const googleToken = useAppSelector((state:any) => state.SystemConfig.googleIntegrationConfig)
    const userEmailState = useAppSelector((state:any) => state?.UserDetail?.username)
    const changeView = (event: any) => {
        let calView = {};

        switch (event.target.value) {
            case 'day':
                calView = {
                    schedule: { type: 'day' }
                }
                break;
            case 'year':
                calView = {
                    calendar: { type: 'year' }
                }
                break;
            case 'month':
                calView = {
                    calendar: { labels: true }
                }
                break;
            case 'week':
                calView = {
                    schedule: { type: 'week' }
                }
                break;
            case 'agenda':
                calView = {
                    calendar: { type: 'week' },
                    agenda: { type: 'week' }
                }
                break;
            default:
                calView = {
                    schedule: { type: 'day' }
                }
                break;
        }

        setView(event.target.value);
        setCalView(calView);
    }
    const [googleSyncAccountToast, setGoogleSyncAccountToast] = useState(false)

    useEffect(() => {
        if((signUpStatus?.status != logStatus?.status) && logStatus?.status != false) signIn()
    },[logStatus])

    const listView = useMemo<MbscEventcalendarView>(() => {
        return {
            agenda: {
                type: 'year',
                size: 5
            }
        };
    }, []);

    
    const onSearch = _.debounce((ev: any)=>{
        const text = ev.target.value
        const listData = [...pageEvents, ...googleEvents]?.filter((data:any) => data?.title?.includes(text))
        setListEvents(listData)
        setOpen(true)
    }, 300)

    const onFocus  = useCallback((ev: any) => {
        if (ev.target.value.length > 0) {
            setOpen(true)
        }
    }, []);

    const popupInit = useCallback(() => {
        setSearchInput(inputRef.current.nativeElement);
    }, []);
    
    const popupClose = useCallback(() => {
        setOpen(false);
    }, []);

    const eventClick = useCallback((args: any) => {
        setCurrentDate(args.event.start);
        setSelectedEvent([args.event]);
        setOpen(false);
    }, []);

    const handleBtnClick = () => {
        if(currentDate !== undefined){
            setCurrentDate(undefined);
            setSelectedEvent([]);
        }
    }

    const onPageLoading = useCallback((args:any) => {
        startDate.current = args.viewStart;
        endDate.current = args.viewEnd;
        if (googleCalendarSync.isSignedIn()) {
        googleCalendarSync.getEvents(calendarIds, startDate.current, endDate.current)
            .then((events) => {
            const calData:any = [];
            for (const c of events) {
                calData.push({ end: c.end, color: c.color,id: c.id, start: c.start, title: c.title })
            }
            setGoogleEvents([...calData])
            }).catch();
        }
    }, [calendarIds]);


    useEffect(()=>{
        if(eventsList?.length > 0){

            const eventDataList = eventsList?.map((data:any) => {
                return {
                  start: data?.startTime ? new Date(data?.startTime) : null,
                  end: data?.endTime ? new Date(data?.endTime) : null,
                  title: data?.title ? data?.title : null,
                  color: getRondomColor(),
                  id: data?.id
                }
              })
              setPageEvents([...eventDataList])
            //   setEvents([...eventDataList, ...googleEvents])
        }
        if (googleCalendarSync.isSignedIn()) {
            googleCalendarSync.getEvents(calendarIds, startDate.current, endDate.current)
                .then((events) => {
                const calData:any = [];
                for (const c of events) {
                    calData.push({ end: c.end, color: c.color,id: c.id, start: c.start, title: c.title })
                }
                setGoogleEvents([...calData])
                // setEvents([...pageEvents, ...calData])
                }).catch();
            }
    },[eventsList])

    const setGoogleSyncState = (labelText:string, status:boolean) => {
        setLogStatus({
            label:labelText,
            status: status,
            buttonStatus: status
        })
        setSignUpStatus({
            label:labelText,
            status: status,
            buttonStatus: status
        })
    }

    const onSignedIn = () => {
        let useEmail = "";
        let IpAddress = ""
        googleCalendarSync.getCalendars()
        .then(async (calendars) => {
            calendars.sort((c:any) => c.primary ? -1 : 1);
            
            const primaryCalId = calendars[0].id;
            useEmail = calendars[0].id;            
            setCalendarIds([primaryCalId]); // saran.durai@ss.com
            await fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                IpAddress = data.ip
            })
        .catch(error => console.log(error))
            if(userEmailState !== primaryCalId){
                setGoogleSyncAccountToast(true)
                googleCalendarSync.signOut()                
            } else{
                if(JSON.parse(configTemplate[0]?.configValue).includes(IpAddress)){
                    if(primaryCalId.split("@")[1].indexOf("xpheno") >= 0){
                        setGoogleSyncState(primaryCalId,true)
                    }else{
                        googleCalendarSync.signOut()
                    }
                }else{
                    setGoogleSyncState(primaryCalId,true)
                }
                
            }
          return googleCalendarSync.getEvents([primaryCalId], startDate.current, endDate.current);
        }).then((events) => {
            const calData:any = [];
            for (const c of events) {
                calData.push({ end: c.end, color: c.color,id: c.id, start: c.start, title: c.title })
            }
            setGoogleEvents([...calData])
            // setEvents([...pageEvents, ...calData])
        }).catch();
    };

    const onSignOut = () => {
        setGoogleSyncState("Sign in with Google",false)
    }

    const signIn = () => {
        googleCalendarSync.signIn().catch();
    }

    useEffect(() => {
        googleCalendarSync.init({
            apiKey: JSON.parse(googleToken[0]?.configValue)?.apikey,
            clientId: JSON.parse(googleToken[0]?.configValue)?.clientid,
            onSignedIn: onSignedIn,
            onSignedOut: onSignOut,
        });
    }, [])

    // console.log(myEvents, "myEvents")
    

    const customWithNavButtons = () => {
      return (
        <>        
          <CalendarNav className='cal-header-nav' />
          <div className="md-seach-header-bar mbsc-flex-1-0">
            <Input startIcon="material-search" ref={inputRef} onChange={onSearch} onFocus={onFocus} inputStyle="box" placeholder="Search events" />
          </div>
          <div className='right-corner' onClick={handleBtnClick}>
            <CalendarPrev className='cal-header-prev' />
            <CalendarToday className='cal-header-today' />
            <CalendarNext className='cal-header-next' />
            <div className='cal-header-picker'>
              <SegmentedGroup value={view} onChange={changeView}>
                <SegmentedItem value='day'>Day</SegmentedItem>
                <SegmentedItem value='week'>Week</SegmentedItem>
                <SegmentedItem value='month'>Month</SegmentedItem>
                <SegmentedItem value='year'>Year</SegmentedItem>
                <SegmentedItem value='agenda'>Agenda</SegmentedItem>
              </SegmentedGroup>
            </div>
          </div>
        </>
      )
    }

    return (
        <Page>
            <Eventcalendar
                className="md-search-events"
                renderHeader={customWithNavButtons}
                height={750}
                view={calView}
                data={[...pageEvents]}
                cssClass="md-switching-view-cont"
                onEventDoubleClick={handleEventClick}
                selectedEvents={mySelectedEvent} 
                selectedDate={currentDate}
                clickToCreate={false}
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                selectMultipleEvents={true}
                onPageLoading={onPageLoading}
            />
            <Popup
                className="md-search-popup"
                display="anchored"
                showArrow={false}
                showOverlay={false}
                scrollLock={false}
                contentPadding={false}
                focusOnOpen={false}
                focusOnClose={false}
                anchor={searchInput}
                focusElm={searchInput}
                isOpen={isOpen}
                onInit={popupInit}
                onClose={popupClose}
            >
                <Eventcalendar
                    className="mbsc-popover-list"
                    view={listView}
                    data={listEvents}
                    showControls={false}
                    onEventClick={eventClick}
                />
            </Popup>
            <Snackbar
                open={googleSyncAccountToast}
                autoHideDuration={6000}
                onClose={() => {setGoogleSyncAccountToast(false)}}>
                <Alert onClose={() => {setGoogleSyncAccountToast(false)}} severity="error" sx={{ width: '100%' }}>
                    Please log with valid email account
                </Alert>
            </Snackbar>
        </Page>
    );
}
export default Calendar
