import './DepartmentDropDown.scss'
import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Checkbox, Popover,Typography ,Accordion,AccordionDetails,AccordionSummary,Tooltip} from "@mui/material"
import * as _ from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const IndustryChildDropdown = ({

    indList,
    index,
    selectedItems,
    setSelectedItems,
    industrySearch


}: any) => {

    
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

  

    const updateIndustrySelect = (industry: any) => {

        setSelectedItems((prev: any) => {
            const itemsWithId = _.filter(prev.value, { parentId: industry.id });

            const count = itemsWithId.length;
            if (!industry.children.length) {
                // _.remove(prev.value, (item: any) => item.industryCategoryId == industry.industryCategoryId );
                const existingObject = _.find(prev.value, industry);
                if (existingObject) {
                    _.remove(prev.value, industry);
                    return { ...prev }
                } else {
                    prev.value.push(industry)
                    return { ...prev }
                }
            }
            else if (count == industry.children.length) {
                _.remove(prev.value, (item: any) => item.parentId == industry.id);
                return { ...prev }
            }
            else {
                _.remove(prev.value, (item: any) => item.parentId == industry.id);
                prev.value.push(...industry.children)
                return { ...prev }
            }
        })

    }


    const updateRemoveSelect = (id: any, subOptions: any) => {
        setSelectedItems((prev: any) => {
            const existingObject = _.find(prev.value, id);

            if (existingObject) {
                _.remove(prev.value, id);

                return { ...prev }

            } else {
               
                console.log(id, "check")
                prev.value.push(id);
                return { ...prev }
            }
        })
    }




    return (

        <Accordion 
        // className='dept-accordian'
         key={index} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >

                <div className="list-item" >
                <Tooltip title={indList.industryCategoryName} placement="top-start">
                <span className='ellipsis-drop-cls-list'> {indList.industryCategoryName}</span>
                </Tooltip>
                </div>
            </AccordionSummary>
            <AccordionDetails>

                {indList.industries &&
                    <>
                        {indList.industries.map((industry: any) => {
                            // const isIndSearchChildExist = industrySearch == "" ? true : industry.children.find((indSrc:any) => indSrc.industryName?.toLowerCase().includes(industrySearch?.toLowerCase()))
                            // industry.industryName?.toLowerCase().includes(industrySearch?.toLowerCase()) || isIndSearchChildExist ? previous code commented
                            return (
                                <>
                                {<div className="multilevel-dropdown" key={industry.id}>


                                    <Typography 
                                    onClick={() => { updateIndustrySelect(industry) }} 
                                    className="list active" >
                                            <input
                                                type="checkbox"
                                                checked={_.some(selectedItems.value, (item: any) => {
                                                    if(!industry.children.length)
                                                    {
                                                     return item.parentId === industry.parentId && item.industryName === industry.industryName 
                                                    }
                                                    else{
                                                        return item.parentId === industry.id 

                                                    }
                                                })}
                                            />
                                    <Tooltip title={industry.industryName} placement="top-start">
                                    <span className='ellipsis-drop-cls-list'>{industry.industryName} </span>
                                            </Tooltip>
                                    </Typography>

                                    <div className="sub-list">
                                    {/* children.industryName?.toLowerCase().includes(industrySearch?.toLowerCase()) && /// previous code commented */}
                                        {industry.children.map((children: any, subIndex: number) => {
                                            return <>{ <div key={subIndex} className={`list active`} 
                                            onClick={() => updateRemoveSelect(children, children.industryName)}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={_.some(selectedItems.value, (item: any) => {
                                                        return item.parentId === children.parentId && item.industryName === children.industryName;
                                                    })}
                                                />
                                    <Tooltip title={children.industryName} placement="top-start">
                                    <span className='ellipsis-drop-cls-list'>{children.industryName}</span>
                                                </Tooltip>
                                                </div>}</>
                                        })}
                                    </div>
                                </div>}
                                </>

                            )
                        })
                        }
                    </>


                }
            </AccordionDetails>
        </Accordion>

    )
}

export default IndustryChildDropdown

