import React from 'react'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import LeftImageMain from '../../../assets/images/Login_page_creative_main.svg'
import PeopleEffect from '../../../assets/images/people-effect.svg'
import './Slider.css'
export const LeftContainer = styled(Box)`
  display: flex;
  padding: 3rem 1rem 2.5rem 1rem;
  align-items: end;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
`

export const Slider = () => {
  return (
    <>
      <LeftContainer>
        {' '}
        <Box component='img' alt='Xpheno-left-main' src={LeftImageMain} className='main-image' />
        <Box
          component='img'
          alt='Xpheno-people-effect'
          src={PeopleEffect}
          className='people-effect'
        />
      </LeftContainer>
    </>
  )
}
