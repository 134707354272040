import _ from "lodash";
import { useEffect, useRef } from "react";

export const objectToQueryString = (params:any) => {
    const queryStringList:any = [];
    const queryString = _.forEach(params, (param:any, key:any) => {
        if(params[key] !== "") {
            queryStringList.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        }
    });
    return queryStringList.join('&');
}

export const useDidMountEffect = (func:any, deps:any) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export const delay = (ms:number) => new Promise(res => setTimeout(res, ms));

export const jsonDataFormater = (dataString:any) => {
    try{
        const data = JSON.parse(dataString)
        return data
    }catch(error){
        console.log(error, "Not valid JSON")
        return null
    }
}
