import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import recordCall from '../../../../../assets/icons/g2176.svg'

const Waveform = ({ audio, duration }) => {
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: () => false
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      barWidth: 3,
      cursorWidth: 0,
      barRadius: 3,
      barGap: 2,
      barMinHeight: 1,
      height: 32,
      progressColor: "#002882",
      waveColor: "#727376",
      cursorColor: "transparent"
    });
    waveSurfer.load(audio);
    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  const formatTime = function (time) {
    const arr = [
        Math.floor((time % 3600) / 60) || 0 , // minutes
        ('00' + (Math.floor(time % 60) || 0)).slice(-2)   // seconds
    ]
    return `${('00' + arr[0]).slice(-2)} : ${arr[1]}`
  };

  return (
    <div style={{
      width:"200px",
      backgroundColor: "#00288212",
      borderRadius: "7px",
      display: "flex",
      height: '100%',
      padding: "8px",
      gap: '8px'
    }}>
      <div>
        <button
          style={{
            height: "32px",
            border: "none",
            padding: 0,
            backgroundColor: "transparent",
            borderRadius: "7px",
          }}
          onClick={() => {
            waveSurferRef.current.playPause();
            toggleIsPlaying(waveSurferRef.current.isPlaying());
          }}
          type="button"
        >
          {isPlaying ?  <PauseIcon/> : <PlayArrowIcon/>}
        </button>
      </div>
      <div style={{width:"100%"}}>
        <div style={{width:"100%"}} ref={containerRef} />
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <span style={{
            fontSize:'11px',
            fontWeight:400,
            color:'#727376'
          }}>{formatTime(duration)}</span>
          <img style={{ width: '14px', height: '14px' }} src={recordCall} alt='' />
        </div>
      </div>
    </div>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired
};

export default Waveform;
