import moment from "moment";

export const convertToDisplayDateTime = (datetime: string | null | undefined) => {
    if(!datetime) return "";
    return moment.utc(datetime).local().format('DD MMM YYYY, HH:mm');
}

export const convertToDisplayDate = (datetime: string | null | undefined) => {
    if(!datetime) return "";
    return moment.utc(datetime).local().format('DD MMM, YYYY');
}

export const convertToDisplayDateReport = (datetime: string | null | undefined) => {
    if(!datetime) return "";
    return moment.utc(datetime).local().format('YYYY-MM-DD');
}

export const convertToDisplayDateTimeSecond = (datetime: string | null | undefined) => {
    if(!datetime) return "";
    return moment.utc(datetime).local().format('YYYY-MM-DD HH:mm:ss');
}