import React, { useEffect, useMemo, useState } from 'react'
import './TaskDetail.css'
import { Drawer} from '@mui/material'
import _ from "lodash";
import { TaskDrawerData } from '../TaskDrawerData'

export interface DrawerProps {
  draweropen: boolean
  data: any
  handledrawerClose?: () => void
}

export const TaskDetailDrawer: React.FC<DrawerProps> = ({
  draweropen,
  handledrawerClose,
  data,
}) => {
  return (
    <>
      <Drawer
        open={draweropen}
        anchor={'right'}
        onClose={handledrawerClose}
        className='task-detail-drawer'
      >
       <TaskDrawerData data={data}/>
      </Drawer>
    </>
  )
}
