import { FormControl, Grid, MenuItem, Select, TextField,InputLabel, FormHelperText, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import RangeSlider from "../../../Common/RangeSlider/RangeSlider";
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import DepartmentDropDown from "../DepartmentDropDown";
import IndustryDropdown from "../IndustryDropdown";
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as AiImage } from '../../../../assets/icons/aiImage.svg'
import { ReactComponent as HintLight } from '../../../../assets/icons/hintLight.svg'
import CloseIcon from '@mui/icons-material/Close';
import AiGeneratorPopup from "../../../Common/AiGeneratorPopup/AiGeneratorPopup";
const ReactQuillWrapper = ({value, handleChange}:any) => {
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <div style={{height:"100%", border: "1px solid #D9D9D9", borderRadius:"10px"}}>
      <ReactQuill 
        value={value}
        modules={modules}
        formats={formats}
        onChange={handleChange} 
      />
    </div>
  )
}

const MandateSpecsForm = ({dropdownOption,setEnhanceReqObj,setEnhanceChatbotStatus,highlightJoAiStatus}:any) => {

  const { control, formState: { errors }, getValues,setError,clearErrors } : any = useFormContext()
  const [suggestedSelected, setSuggestedSelected] = useState({
    value: "",
    timeStamp: new Date().getTime()
  })  

  const checkMandateSpecObjValid = (reqObj:any) => {
    const reqObjData = reqObj
    delete reqObjData.Experience
    let validObj = false;
    Object.keys(reqObjData).map((item:any) => {
      if(reqObjData[item].length > 0){        
        validObj = true
      }
    })
    return validObj
  }
  
  useEffect(() => {
    if(suggestedSelected.value.length > 0){
      const reqestObj:any = {
      "jobTitle":getValues("jobTitle"),
      "JobType":getValues("jobType"),
      "Priority":getValues("priority"),
      "JobMode":getValues("jobMode"),
      "Shift":getValues("shift"),
      "JobFunctionIndustry":getValues("industryData").map((item:any) => item?.industryName).join(","),
      "DepartmentRole":getValues("departmentData").map((item:any) => item?.roleName).join(","),
      "Qualification":getValues("qualification"),
      "Onsite":getValues("onsite"),  
      "Experience":getValues("experienceRange")[0]+"-"+getValues("experienceRange")[1], 
      "PrimarySkills":getValues("primarySkills"),
      "SecondarySkills":getValues("secondarySkills"),
      "Joborderlocationwithcount":getValues("mandateLocation").map((item:any) => ("primaryLocation" in item) ? (item?.primaryLocation + "-" + item?.jobOrders) : "").join(",")
    }
    console.log("getValues",getValues("mandateLocation"))
    if(suggestedSelected.value == "Enhance using my current JD" && getValues("jobDescription").length == 0){
      setError('jobDescription', {message: 'Job description is empty' });
    }else if(suggestedSelected.value == "Enhance using my current JD" && getValues("jobDescription").length > 0){
      reqestObj["jobDescription"] = getValues("jobDescription")
      clearErrors("jobDescription")
      reqestObj["suggestedText"] = suggestedSelected.value
      setEnhanceReqObj(reqestObj)
      setEnhanceChatbotStatus(true)
    }else if(suggestedSelected.value == "Generate Using Mandate specs" && checkMandateSpecObjValid(reqestObj)){
      reqestObj["suggestedText"] = suggestedSelected.value
      setEnhanceReqObj(reqestObj)
      setEnhanceChatbotStatus(true)
      clearErrors("jobDescriptionAi")
    }else if(suggestedSelected.value == "Generate Using Mandate specs" && !checkMandateSpecObjValid(reqestObj)){
      setError('jobDescriptionAi', {message: 'Minimum 1 Mandate specs value needed to Enhance job description AI' });
      clearErrors("jobDescription")
    }
    }
  },[suggestedSelected])
  

  const SelectUnitLabel = () => {
    return(
      <div className='select-unit-wrapper'>
        <span className='lable-text-select' style={{paddingBottom:"unset"}}>Annual CTC  (in Lakhs)</span>
        <div className='unit-container'>
          <span>Select unit:</span>
          <FormControl size="small">
          <Controller
            name='currency'
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <Select
                value={value}
                onChange={onChange}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem value={"INR"}>INR</MenuItem>
                <MenuItem value={"USD"}>USD</MenuItem>
              </Select>
            )}
          />
          </FormControl>
        </div>
      </div>
    );
  }
  
  return (
    <Grid className='container-wrapper'>
      <Grid className='row-grid'>
        {/* <FormControl fullWidth required >
          <Controller
            name="mandateRoleCode"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Mandate Role Code'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{ maxLength: 5 }}
              />
            )}
          />
        </FormControl> */}
        <FormControl fullWidth>
        <InputLabel>Job Mode</InputLabel>
          <Controller
            name="jobMode"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
              label='Job Mode'
              value={value}
              onChange={(e) => onChange(e?.target?.value)}
              onBlur={onBlur}
            >
              {dropdownOption["Job_Mode"].map( (type: any, index: any) => (
                <MenuItem key={type?.id} value={type?.domValue
                }>{type?.domValue
                }</MenuItem>
              ))}
            </Select>
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Shift</InputLabel>

          <Controller
            name="shift"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
                label='Shift'
                value={value}
                onChange={(e) => onChange(e?.target?.value)}
                onBlur={onBlur}
              >
                {dropdownOption["SHIFT_TYPE"].map((type: any) => (
                  <MenuItem key={type?.id} value={type?.domValue}>{type?.domValue}</MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl fullWidth >
        <Controller
            name="departmentData"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <>
             <DepartmentDropDown value={value} onChange={onChange} preSelectedItems={getValues("departmentData") ?? []}/>
              </>
          )}
          />
        </FormControl>
        <FormControl fullWidth >
        <Controller
            name="industryData"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <>
          <IndustryDropdown value={value} onChange={onChange} preSelectedItems={getValues("industryData") ?? []}/>
          </>
          )}
          />
        </FormControl>
    


     
      </Grid>
      <Grid className='row-grid pt-1'>
        <FormControl fullWidth>
          <Controller
            name="qualification"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Qualification'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                multiline={true}
                minRows={3}
                inputProps={{ maxLength: 256 }}                
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth >
          <Controller
            name="onsite"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Onsite (If any)'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                multiline={true}
                minRows={3}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid className='row-grid pt-1'>
        <Grid className='res-grid' item style={{width:"50%"}}>
          <span className='lable-text-select'>Experience (in years)</span>
          <Controller
              name='experienceRange'
              control={control}
              rules={{ validate: (value) => {
                const val1:any = Number(value[0])
                const val2:any = Number(value[1])
                const val = (val1 <= val2) && (val2 >= val1)
               return val}
              }}
              render={({ field: { onChange, value },fieldState }) => (
                <>
                <RangeSlider 
                    rangeValue={value}
                    setRangeValue={onChange}
                    minValue={0}
                    maxValue={30}
                    rangeStep={1}
                    markStep={5}
                    labelText={""}
                    onChangeHandler={(e)=> {e.target.value[0] != 0 ? onChange(e.target.value) : ""}}
                    className={"mandate-slider"}
                />
                {fieldState.invalid ? (
                   <FormHelperText className="reminder_text-danger select-danger-text" error>End value should be greater than start value</FormHelperText>
                ) : null}
                </>
              )}
          />
        </Grid>
        <Grid className='res-grid' item style={{width:"50%"}}>
          <SelectUnitLabel/>
          <Controller
              name='ctcRange'
              control={control}
              rules={{ validate: (value) => {
                const val1:any = Number(value[0])
                const val2:any = Number(value[1])
                const val = (val1 <= val2) && (val2 >= val1)
               return val}
              }}
              render={({ field: { onChange, value },fieldState }) => (
                <>
                <RangeSlider 
                    rangeValue={value}
                    setRangeValue={onChange}
                    minValue={0} 
                    maxValue={100}
                    rangeStep={1}
                    markStep={20}
                    onChangeHandler={(e)=> {e.target.value[0] != 0 ? onChange(e.target.value) : ""}}
                    labelText={""}
                    className={"mandate-slider"}
                />
                {fieldState.invalid ? (
                  <FormHelperText className="reminder_text-danger select-danger-text" error>End value should be greater than start value</FormHelperText>
              ) : null}
              </>
              )}
          />
        </Grid>
      </Grid>
      <Grid className='row-grid pt-1'>
    
        <FormControl fullWidth required className="keySearchField">
        {/* <span className='lable-text-select'>Primary Skills<span style={{color:"#FF0000"}}>*</span></span> */}

          <Controller
            name="primarySkills"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              },
              pattern: {
                value: /^[a-zA-Z0-9!$%^&*()_#/+., -]+$/,
                message: "Only this specific special character !,#,$,%,^,&,*,(,),_,+ allowed"
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
              label='Primary Skills'
              required={true}
              value={value}
              onChange={onChange}
              onKeyPress={(e:any) => {                      
                if (e.which === 13) {
                    e.preventDefault();
                }
            }}
              onBlur={onBlur}
              multiline={true}
              minRows={3}
            />
            )}
          />
          {errors?.primarySkills && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.primarySkills?.message}
            </span>
          )}
          <p><InfoIcon />Enter Primary Skills using comma separated</p>
        </FormControl>
        <FormControl fullWidth className="keySearchField">
        {/* <span className='lable-text-select'>Secondary Skills</span> */}

          <Controller
            name="secondarySkills"
            control={control}
            rules={{
              pattern: {
                value: /^[a-zA-Z0-9!$%^&*()_#/+., -]+$/,
                message: "Only this specific special character !,#,$,%,^,&,*,(,),_,+ allowed"
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
              label='Secondary Skills'
              value={value}
              onChange={onChange}
              onKeyPress={(e:any) => {                      
                if (e.which === 13) {
                    e.preventDefault();
                }
            }}
              onBlur={onBlur}
              multiline={true}
              minRows={3}
            />
            )}
          />
          {errors?.secondarySkills && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.secondarySkills?.message}
            </span>
          )}
          <p><InfoIcon />Enter Secondary Skills using comma separated</p>
        </FormControl>

      </Grid>
      <Grid className='row-grid pt-1' style={{minHeight:"280px"}}>
        <FormControl fullWidth required style={{position:"relative"}}>
          <span className='lable-text-select'>Job description <span style={{color:"#FF0000"}}>*</span></span>
          <Controller
            name="jobDescription"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <ReactQuillWrapper
                value={value}
                handleChange={(e:any) => {
                  (e.replace(/<\/?[^>]+(>|$)/g, "") == "") ? onChange("") : onChange(e);
                }}
              />
            )}
          />
          {errors?.jobDescription && (
            <span style={{position:"absolute",bottom:"-20px"}} className="reminder_text-danger select-danger-text" role="alert">
                {errors?.jobDescription?.message}
            </span>
          )}
        </FormControl>
      
        <FormControl className={highlightJoAiStatus ? "jobDescHighlightEnable" : ""} fullWidth required style={{position:"relative"}}>
          <span className='lable-text-select'>Job description (AI)</span>
            <Controller
              name="jobDescriptionAi"
              control={control}
              render={({field : {onChange, value, onBlur}})=>(
                <ReactQuillWrapper
                  value={value}
                  handleChange={(e:any) => {
                    (e.replace(/<\/?[^>]+(>|$)/g, "") == "") ? onChange("") : onChange(e);
                  }}
                />
              )}
            />
            <AiGeneratorPopup setSuggestedSelected={setSuggestedSelected}/>     
          {errors?.jobDescriptionAi && (
            <span style={{position:"absolute",bottom:"-20px"}} className="reminder_text-danger select-danger-text" role="alert">
                {errors?.jobDescriptionAi?.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid className='row-grid pt-1' style={{minHeight:"280px"}}>
        <Box style={{width:"100%"}}>
          <FormControl fullWidth >
            <span className='lable-text-select'>Sourcing notes/Remarks</span>
            <Controller
              name="secondaryMatch"
              control={control}
              render={({field : {onChange, value, onBlur}})=>(
                <ReactQuillWrapper
                  value={value}
                  handleChange={onChange}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box style={{width:"100%"}}></Box>
      </Grid>
    </Grid>
  )
}

export default MandateSpecsForm;