import React, { useState, useEffect } from 'react';
import './styles.scss'
import CandidateInsights from './CandidateInsights';
import CandidateHeader from './CandidateHeader';
import CandidatePersonalDetails from './CandidatePersonalDetails';
import { ReactComponent as ForgotArrowIcon } from '../../assets/icons/arrow-left.svg';
import { GetCandidateDetails } from '../../services/CandidateService';
import { Loading } from '../../pages/Loading';
import { useParams, useNavigate, useLocation,useSearchParams } from 'react-router-dom';
import { resetNotification } from '../../features/notification/notificationReducer';
import { Alert, Snackbar } from '@mui/material';

import {
    resetFilters,
    resetFilterPreloadData,
    setFilterValues
  } from '../../features/filters/FilterReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const CandidateDetails = () => {
    const dispatch = useAppDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
  const filters = useAppSelector((state) => state.filtersReducer.filters)

    const { id }: any = useParams<any>();
    const navigate = useNavigate()
    const location = useLocation()
    const tabName = searchParams.get('tabName') || 'defaultTab';
    const notificationState = useAppSelector((state) => state?.notificationState)
    const persistProfile = useAppSelector((state)=>state?.profilePersistor)
    const searchFilter = location?.state?.searchFilter
    const candidaterouteState = location?.state
    const [candidateDetailsData, setCandidateDetailsData] = useState<any>()
    const [isLoading, setLoading] = useState<boolean>(true)
    const [selectedComponent, setSelectedComponent] = useState<string>('basicDetails')
    const [alertToast,setAlertToast] = useState(false);
    const [alertMsg,setAlertMsg] = useState("")
    const [candidateNotificationState, setCandidateNotificationState] = useState(false)
    const handleNavigation  = (source?:any) =>{        
        setSelectedComponent(location?.state?.source == "candidateRedirection" ? 'candidateTracking' : 'basicDetails')
        getCandidateDetails(source==="mail"?id:location?.state?.id)
        setTimeout(() => {
           dispatch(resetNotification()) 
        }, 300)
    }
    useEffect(() => {
        if (location?.state?.id) {
           handleNavigation();           
         }
    }, [location?.state?.id,location?.state?.timeStamp])
    const getCandidateDetails = async (navigateId:number|null) => {
        
        try {
            const response = await GetCandidateDetails(navigateId??id);
            if (response?.status === 200) {
                setLoading(false)
                if (response.data !== "No candidate found with this ID"){
                    setCandidateDetailsData(response?.data)
                }
                else if(response?.data==='No candidate found with this ID'){
                    setAlertMsg('No candidate found with this ID')
                }
            }
            else {
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }
    useEffect(() => {
        if(location?.state?.source == "candidateRedirection") setCandidateNotificationState(!candidateNotificationState)
    },[candidateDetailsData])

    const handleBackNavigation = () =>{
        // dispatch(resetPersistFilter())
        // dispatch(resetFilters())
        // dispatch(setFilterValues({...filters}))
        candidaterouteState == "submissionReportCandidate" ? navigate(`/candidatePipeline`, {state:"submissionReportCandidate"}) : navigate(-1);
    }
    useEffect(() => {
        if (notificationState?.candidateOpen === false && tabName!="candidate_tracking") {
        getCandidateDetails(null);
        }
        else if(tabName==="candidate_tracking"){
           handleNavigation("mail")
        }
    }, [])

    const [candidateDetailToggle, setCandidateDetailToggle] = useState(true)

    return (
        <div className={`candidate-details-main ${candidateDetailToggle ? "candidateDetailToggle": ""}`}>
            {isLoading ? <> <div className='task-loading-container'>
                <Loading />
            </div></> : <>
                <div className='candidate-header'>
                    <ForgotArrowIcon style={{cursor: "pointer"}} onClick={() => handleBackNavigation() } />
                    <CandidateHeader candidateDetailsData={candidateDetailsData} setSelectedComponent={setSelectedComponent} />
                    <CandidatePersonalDetails candidateDetailsData={candidateDetailsData} selectedComponent={selectedComponent} setSelectedComponent={setSelectedComponent} candidateNotificationState={candidateNotificationState}/>
                </div>
                <div className='candidate-insights'>
                    <CandidateInsights candidateDetailsData={candidateDetailsData} setCandidateDetailToggle={setCandidateDetailToggle} candidateDetailToggle={candidateDetailToggle}/>
                </div>
            </>}
            <Snackbar
                open={alertToast}
                autoHideDuration={6000}
                onClose={() => {setAlertToast(false)}}>
                <Alert onClose={() => {setAlertToast(false)}} severity="error" sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default CandidateDetails
