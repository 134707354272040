import React, { useEffect, useMemo, useState } from 'react'
import { TextField, Box, Typography } from '@mui/material'
import { MandateOptionTag } from '../../AppComponents/AppMandateDropdown/AppMandateDropdown'
import RecruiterCard from './RecruiterCard'
import TargetContainer from './TargetContainer'
import MandateTable from '../MandateTable/MandateTable'
import './RecruiterCard.scss'
import {
  getAllocatedMandateCount,
  getdetailsOnRecruiter,
} from '../../../services/WorkManagementService'

const RecruiterContainer = ({ recruiterData, getAllocatedMandate, recruiterReqObj, recruiterDataArr, viewType, activeTabStatus }: any) => {
  const [mandateOpen, setmandateOpen] = useState<boolean>(false)
  const [mandateDetails, setMandateDetails] = useState([])
  const mandateCountsparse = () => {
    try {
      return JSON.parse(recruiterData?.mandateStages)
    } catch (error) {
      return []
    }
  }

  const mandatestageCounts = useMemo(() => mandateCountsparse(), [recruiterData])
  const getDetails = async () => {
    const reqObj = {
      userId: recruiterData?.recruiterId,
      dayFilter: recruiterReqObj?.dayFilter,
      fromDate: recruiterReqObj?.fromDate,
      endDate: recruiterReqObj?.endDate,
      myTeam: false
    }
    const response = await getdetailsOnRecruiter(reqObj)
    if (response?.status === 200) {
        setMandateDetails(response.data?.result?.list)
      }
  }

  useEffect(() => {
    if(mandateOpen)setmandateOpen(false)
  },[recruiterDataArr])

  return (
    <>
      <Box className='recruiter-view-header'>
        {/* <div className='my-team-container'> */}
        <RecruiterCard
          // key={index}
          setmandateOpen={setmandateOpen}
          onChange = {()=> getDetails()}
          mandateOpen={mandateOpen}
          className={`hire-man-tag active-tag}`}
          option={{
            title: recruiterData?.recruiterName,
            mandateId: recruiterData?.roleName,
            clientName: recruiterData,
            createdByName: 'recruiterData',
          }}
          // onClick={() => setSelectedMandate(list)}
        />
        {/* </div> */}

        {/* <div className='day-view-box-container'>
                    <Typography className='day-view-header-text'>
                        Submissions
                    </Typography>
                </div> */}
        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'MON')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'CV Submission')?.status_count
          }
          target={0}
          status={'good'}
          statusType = {viewType != "W" ? "CV Submission" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'TUE')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'CCM1')?.status_count
          }
          target={0}
          status={'critical'}
          statusType = {viewType != "W" ? "CCM1" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'WED')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'CCM2')?.status_count
          }
          target={0}
          status={'attention'}
          statusType = {viewType != "W" ? "CCM2" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'THU')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'Selection Stage')?.status_count
          }
          target={0}
          status={'attention'}
          statusType = {viewType != "W" ? "Selection Stage" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'FRI')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'Joining & Post Joining')?.status_count
          }
          target={0}
          status={'good'}
          statusType = {viewType != "W" ? "Joining Stage" : activeTabStatus}
        />

        {viewType == "W" && <TargetContainer
          acheived={mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'SAT')?.count}
          target={0}
          status={'good'}
        />}
        {viewType == "W" && <TargetContainer
          acheived={mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'SUN')?.count}
          target={0}
          status={'good'}
        />}
      </Box>
      {mandateOpen ? <MandateTable mandateDisable={recruiterData?.userActiveStatus ? false: true} mandateDetails={mandateDetails} recruiterID={recruiterData?.recruiterId} getAllocatedMandate={getAllocatedMandate} getDetails={getDetails} /> : null}
    </>
  )
}

export default RecruiterContainer
