import { Autocomplete, Box, Checkbox, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import RangeSlider from '../../components/Common/RangeSlider/RangeSlider';
import NumberWithIncDec from '../../components/Common/NumberWithIncDec';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const DesireJobDetails = ({employeeType,noticePeriod,primaryList}:any) => {
    const { control, formState: { errors }, getValues, setValue, clearErrors, trigger }: any = useFormContext();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const SelectUnitLabel = ({ unitName }: any) => {
        return (
            <div className='select-unit-wrapper'>
                <span className='lable-text-select' style={{ paddingBottom: "unset" }}>Annual CTC  (in Lakhs)</span>
                <div className='unit-container'>
                    <span>Select unit:</span>
                    <FormControl size="small">
                        <Controller
                            name={unitName}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                                <Select
                                    value={value}
                                    onChange={onChange}
                                    sx={{
                                        boxShadow: "none",
                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: 0,
                                        },
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <MenuItem value={"INR"}>INR</MenuItem>
                                    <MenuItem value={"USD"}>USD</MenuItem>
                                </Select>
                            )}
                        />
                    </FormControl>
                </div>
            </div>
        );
    }
    return (
        <Grid className='container-wrapper'>
            <h5 className='cardHeadingStrip'><span>Desired Job Profile</span></h5>
            <Box className="personalInfoWrapper">
                <Box className="personalInfoFieldWrapper">
                    <Grid className='row-grid mb-3'>
                        <FormControl fullWidth required className='numberFieldSect'>
                            <label>{`Current CTC (in Lakhs) `}<span>*</span></label>
                            <Controller
                                name={"currentCtc"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "This field is required",
                                    }
                                }}
                                render={({ field: { onChange, value }, fieldState }) => (
                                    <>
                                        <NumberWithIncDec onChange={onChange} value={value} min={0} max={100} required={true} />
                                    </>
                                )}
                            />
                            {errors?.currentCtc && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {errors?.currentCtc?.message}
                                </span>
                            )}
                        </FormControl>
                        <FormControl fullWidth required className='numberFieldSect'>
                        <label>{`Expected CTC (in Lakhs) `}<span>*</span></label>
                            <Controller
                                name={"expectedCtc"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "This field is required",
                                    }
                                }}
                                render={({ field: { onChange, value }, fieldState }) => (
                                    <>
                                        <NumberWithIncDec onChange={onChange} value={value} min={0} max={100} required={true} />
                                    </>
                                )}
                            />
                            {errors?.expectedCtc && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {errors?.expectedCtc?.message}
                                </span>
                            )}
                        </FormControl>
                        <FormControl fullWidth required>
                            <Controller
                                name={"currentLocation"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "This field is required",
                                    }
                                }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Autocomplete
                                        options={primaryList}
                                        value={primaryList.find((loc:any) => loc.label === value)}
                                        onChange={(event, value) => {
                                            onChange(value)
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Current Location"
                                            required
                                        />
                                        )}
                                    />
                                )}
                            />
                            {errors?.currentLocation && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {errors?.currentLocation?.message}
                                </span>
                            )}
                        </FormControl>
                        <FormControl fullWidth required>
                            {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                            <Controller
                                name={"preferredLocation"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "This field is required",
                                    }
                                }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Autocomplete
                                        options={primaryList}
                                        value={primaryList.find((loc:any) => loc.label === value)}
                                        onChange={(event, value) => {
                                            onChange(value)
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Preferred Location"
                                            required
                                        />
                                        )}
                                    />
                                )}
                            />
                            {errors?.preferredLocation && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {errors?.preferredLocation?.message}
                                </span>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid className='row-grid mb-3' style={{width:"calc(50% - 7px)"}}>                        
                        <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">Notice Period</InputLabel>
                            <Controller
                                name={"noticePeriod"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "This field is required",
                                    }
                                }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={value}
                                        label="Notice Period"
                                        onChange={(e: any) => {
                                            onChange(e.target.value)
                                            setValue("role", e.target.value)
                                            // if(getValues("bussinessUnit"))getReportingToApi(getValues("bussinessUnit"))
                                        }}
                                    >
                                        {noticePeriod.map((item: any, index: number) => (
                                            <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors?.noticePeriod && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {errors?.noticePeriod?.message}
                                </span>
                            )}
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Employment Status</InputLabel>
                            <Controller
                                name={"employmentStatus"}
                                control={control}
                                // rules={{
                                //     required: {
                                //         value: true,
                                //         message: "This field is required",
                                //     }
                                // }}
                                render={({ field: { onChange, value, onBlur } }) => (
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={value}
                                        label="Employment Status"
                                        onChange={(e: any) => {
                                            onChange(e.target.value)
                                        }}
                                    >
                                        {employeeType.map((item: any, index: number) => (
                                            <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors?.employmentStatus && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {errors?.employmentStatus?.message}
                                </span>
                            )}
                        </FormControl>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    );
};

export default DesireJobDetails;