import { Autocomplete, Avatar, Box, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import './SourcedProfiles.scss'
import { ReactComponent as UserGroupIcon } from '../../../../assets/icons/user-group.svg'
import { ReactComponent as MockList } from '../../../../assets/icons/Group 45728.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from '@mui/material/Slider';
import { stringAvatar } from '../../../../utils/design-utils';
import { getJobOrderDetails } from '../../../../services/MandateService';
import {resetProfilePersist} from "../../../../features/mandateDetail/profilePersistReducer";
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import LaunchIcon from '@mui/icons-material/Launch';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchIcon from '@mui/icons-material/Search';
import GroupStrick from '../../../../assets/images/default-avatar.png'
type Tprop = {
  mandateData: any;
  posIndex: number;
  setPosIndex: (val: number) => number;
}
interface AssignDetail {
  allocated_user_name: string;
  user_details: number;
  allocated_user_id: string;
}

interface JobData {
  totalJobs: number;
  filledJobs: number;
  assignDetails?: AssignDetail[];
}

const SourcedProfiles: React.FC<Tprop> = ({ mandateData, posIndex, setPosIndex }) => {
  const mandatelocationLists = mandateData?.mandateLocation ? mandateData?.mandateLocation : []
  const search = useLocation()?.search
  const mId:any = new URLSearchParams(search).get("id");
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const manAllocations = mandateData?.allocations ? mandateData?.allocations : []
  const [allocationState, setAllocationState] = useState<AssignDetail[]>([])
  const [allocationMyteamState, setAllocationMyteamState] = useState<AssignDetail[]>([])
  const [allocationCollabState, setAllocationCollabState] = useState<AssignDetail[]>([])
  const [allocationCollabList, setAllocationCollabList] = useState<any>([])
  const [allocationCollabSelected, setAllocationCollabSelected] = useState<any>("")
  const [totalJobs, setTotalJobs] = useState<number>(0)
  const [filledJobs, setFilledJobs] = useState<number>(0)
  const [selectedLocation, setSelectedLocation] = useState<number>();
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setAllocationCollabSelected("")
    setValue(newValue);
  };
  const getSourcesAndRecruiterDetails = async () => {
    const response = await getJobOrderDetails(mId,selectedLocation);
    if (response && response?.result?.list && response?.result?.list?.length > 0) {
      if (response?.result?.list[0]?.assignDetails?.length > 0) {
        setAllocationState(response?.result?.list[0]?.assignDetails)
        setAllocationMyteamState(response?.result?.list[0]?.assignDetails.filter((filterItem:any) => filterItem.tab == "myteam"))
        const collaboartorFilterList = response?.result?.list[0]?.assignDetails.filter((filterItem:any) => (filterItem.tab == "collaborator" || filterItem.tab == "myteam"));
        setAllocationCollabState(collaboartorFilterList.map((collItem:any) => {
          return {
            "allocated_user": collItem.allocated_user,
            "allocated_user_name": collItem.allocated_user_name,
            "jo_filled": collItem.jo_filled,
            "role_name": collItem.role_name,
            "collaboratorOwner" : JSON.parse(collItem.collab_detail)[0]?.collab_id
          }
        }))
        setAllocationCollabList(response?.result?.list[0]?.collaborators.map((collListItem:any) => {
          return {
            label: collListItem?.collaborators,
            value: collListItem?.collaborator_id
        }}))
      }
      else{
        setAllocationState([]);
        setAllocationMyteamState([]);
        setAllocationCollabState([]);
        setAllocationCollabList([])
        setValue("1")
      }
      setFilledJobs(response?.result?.list[0]?.filledJobs)
    }
    else {
      setAllocationState([]);      
      setFilledJobs(0)
    }
  }
  useEffect(() => {
    if (mandatelocationLists?.length > 0) {
      setSelectedLocation(mandatelocationLists[0]?.id)
    }
  }, [mandatelocationLists])
  useEffect(() => {
    if (selectedLocation) {
      getSourcesAndRecruiterDetails()
      let filterLocation: any;
      if (mandatelocationLists?.length > 0) {
        filterLocation = mandatelocationLists?.filter((fil: any) => fil?.id === selectedLocation)[0]?.jobOrders;
        setTotalJobs(filterLocation)
      }
    }
  }, [selectedLocation])
  return (
    <Box className="sourced-profiles" >
      <div className='mandate-location-dropdown' >
        <Select
          value={selectedLocation}
          onChange={(e: any) => {
            setSelectedLocation(e.target.value)
          }}
          className='mandate-location-list'
          labelId='mandate-location-select-label'
          defaultValue={mandatelocationLists?.length ? mandatelocationLists[0]?.id : ""}
        >
          {mandatelocationLists?.length && mandatelocationLists?.map((mll: any, index: number) => (
            <MenuItem key={index} value={mll?.id} className='mandate-location-lists-opt' >
              <div className='primary-text' >{`${mll?.primaryLocation} (${mll?.jobOrders})`}</div>
            </MenuItem>
          ))}
          {mandatelocationLists?.length === 0 && <MenuItem value={0} disabled={true}>No mandatelocations</MenuItem>}
        </Select>
      </div>
      <div className='job-total-count-cls-out'>
        <div className='content-col-cls'>
          <div className='primary-text'>Total Job Orders</div>
          <div className='secondary-text'>{totalJobs}</div>
        </div>
        <div className='content-col-cls'>
          <div className='primary-text'>Job Orders Filled</div>
          <div className='secondary-text'>{filledJobs}</div>
        </div>
      </div>
      <div className="flex-grid">
        <div className='left-cont' >Recruiters Allocated</div>
        <div className='right-cont' onClick={() =>{ 
          navigate(`/mandates/profiles`, { state: { mandateData, posIndex } })
          dispatch(resetProfilePersist())
      }}>
          <LaunchIcon style={{width:"20px"}} />
          {/* <span>View All Profiles</span> */}
        </div>
      </div>
      <div className="profile-list">  
        <Box className="sourcingTabSect">
          <TabContext value={value}>
            <Box className="sourcingTabHeader">
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label={`All ${allocationCollabList.length > 0 && allocationState.length < 10 ? "0" : ""}${allocationState.length}`} value="1" />
                <Tab label={`My Team ${allocationMyteamState.length > 0 && allocationMyteamState.length < 10 ? "0" : ""}${allocationMyteamState.length}`} value="2" />
                <Tab label={`Collaborators ${allocationCollabList.length > 0 && allocationCollabList.length < 10 ? "0" : ""}${allocationCollabList.length}`} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {allocationState && allocationState.length > 0 ? allocationState?.map((data: any, index: number) =>
              <div key={index} className="allocation-card">
                <div className='all-header'>{
                  data?.name ?
                    <Avatar {...stringAvatar(data?.allocated_user_name)} className='search-result-avatar' /> :
                    <Avatar className='search-result-avatar' />
                }
                  <div className='rec-wrapper' >
                    <span className='rec-name' >{data?.allocated_user_name}</span>
                    <span className='rec-role'>{data?.role_name}</span>
                  </div>
                </div>
                <div className='all-footer'>
                  <div className='text-one' style={{ width: "62%" }}>{data?.jo_filled?"Job Orders Onboarded":"No Job Orders Onboarded"} </div>
                  {
                    data?.jo_filled ? (
                      <>
                        <div style={{ width: "35%" }}>
                          <Slider
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={data?.jo_filled}
                            min={0}
                            max={totalJobs}
                            className='slider-bar'
                            size='medium'
                          />
                        </div>
                        <div>{data?.jo_filled}</div>
                      </>
                    ):<></>
                  }
                </div>
              </div>): <><div className='no-allocation' >No Allocations</div></>}
            </TabPanel>
            <TabPanel value="2">
            {allocationMyteamState && allocationMyteamState.length > 0 ? allocationMyteamState?.map((data: any, index: number) =>
            <>
              <div key={index} className='allocation-card'>
                <div className='all-header'>{
                  data?.name ?
                    <Avatar {...stringAvatar(data?.allocated_user_name)} className='search-result-avatar' /> :
                    <Avatar className='search-result-avatar' />
                }
                  <div className='rec-wrapper' >
                    <span className='rec-name' >{data?.allocated_user_name}</span>
                    <span className='rec-role'>{data?.role_name}</span>
                  </div>
                </div>
                <div className='all-footer'>
                  <div className='text-one' style={{ width: "62%" }}>{data?.jo_filled?"Job Orders Onboarded":"No Job Orders Onboarded"} </div>
                  {
                    data?.jo_filled ? (
                      <>
                        <div style={{ width: "35%" }}>
                          <Slider
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={data?.jo_filled}
                            min={0}
                            max={totalJobs}
                            className='slider-bar'
                            size='medium'
                          />
                        </div>
                        <div>{data?.jo_filled}</div>
                      </>
                    ):<></>
                  }
                </div>
              </div>
              </>):<><div className='no-allocation' >No Allocations</div></>}
            </TabPanel>
            <TabPanel value="3">
            <Box className="sourcingTabHeaderSearch">
              <Autocomplete
                disabled={allocationCollabList.length == 0}
                disablePortal
                id="combo-box-demo"
                options={allocationCollabList}
                onChange={(event:any,value:any) => {
                  if(value != null){
                    setAllocationCollabSelected(value.value)
                  }else{
                    setAllocationCollabSelected("")
                  }
                }}
                renderOption={(props, option:any, { selected }) => (
                  <li {...props}>
                    <Avatar
                      alt='no-group'
                      src={GroupStrick}
                      sx={{ width: 30, height: 30, marginRight: '5px' }}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Collaborators" />}
              />
              </Box>
            {allocationCollabState.filter((item:any) => item.collaboratorOwner == allocationCollabSelected)?.map((data: any, index: number) =>
              <>
              {allocationCollabSelected == data.collaboratorOwner ? <div key={index} className='allocation-card'>
                <div className='all-header'>{
                  data?.name ?
                    <Avatar {...stringAvatar(data?.allocated_user_name)} className='search-result-avatar' /> :
                    <Avatar className='search-result-avatar' />
                }
                  <div className='rec-wrapper' >
                    <span className='rec-name' >{data?.allocated_user_name}</span>
                    <span className='rec-role'>{data?.role_name}</span>
                  </div>
                </div>
                <div className='all-footer'>
                  <div className='text-one' style={{ width: "62%" }}>{data?.jo_filled?"Job Orders Onboarded":"No Job Orders Onboarded"} </div>
                  {
                    data?.jo_filled ? (
                      <>
                        <div style={{ width: "35%" }}>
                          <Slider
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={data?.jo_filled}
                            min={0}
                            max={totalJobs}
                            className='slider-bar'
                            size='medium'
                          />
                        </div>
                        <div>{data?.jo_filled}</div>
                      </>
                    ):<></>
                  }
                </div>
              </div>:<></>}
              </>
              )}
              {allocationCollabState.filter((item:any) => item.collaboratorOwner == allocationCollabSelected).length == 0 && <div className='no-allocation' >No Allocations</div>}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </Box>
  )
}

export default SourcedProfiles