import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import './AssignModel.scss'
import { Avatar, Box, Button, FormControl, IconButton, Modal, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState, useEffect } from 'react'
import { AppButton } from '../../AppComponents/AppButton/AppButton'
import { getLoggedInUserId } from '../../../utils/user-utils'
import { stringAvatar } from '../../../utils/design-utils'
import {asssignMandate} from '../../../services/MandateService'
import {useNavigate} from 'react-router-dom'

interface TProps {
  open: boolean
  mandateIds:any
  onClose: () => void;
  horizontalMemberList: any[]
  verticalMemberList: any[]
  assigneeList?: {selectedIds:string[], isHorizontalAssignee:string|null}
}

type assignMemberType = {
  id:string;
  email: string;
  firstName: string;
  roleName: string;
}

export const AssignModal: React.FC<TProps> = ({ mandateIds, open, onClose, horizontalMemberList, verticalMemberList, assigneeList }) => {
  const handleClose = () => onClose()
  const [selectedMembers, setSelectedMembers]= useState<any[]>(assigneeList?.selectedIds ? assigneeList?.selectedIds : [])
  const [assignMemberList, setAssignMemberList] = useState<assignMemberType[]>([])
  const [hierarchy, setHierarchy] = useState<string>(assigneeList?.isHorizontalAssignee ? assigneeList?.isHorizontalAssignee : 'vertical')
  const [currentValues, setCurrentValues] = useState<any>({
    horizontal: [],
    vertical: [],
  })
  const navigate = useNavigate()

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
  const checkedIcon = <CheckBoxIcon fontSize='small' />

  const handleAssign = async() =>{
   
    const assigneeIds = selectedMembers
    const reqObj = mandateIds?.map((manId:any) => {
        const obj = {
          mandateId: manId,
          assigneeIds: assigneeIds,
          isHorizontalAssignee: hierarchy === "horizontal" ? 1 : 0
        }
        return obj;
    })
    const response = await asssignMandate(reqObj)
    if(response?.status === 200){
      handleClose()
      navigate(0)
    }else{
      console.log("Server error")
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    // height: 300,
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
  }

  const handleSelect = (e: any, v: any) => {
    const ids = v?.map((ele:any)=>ele?.id as string)
    setSelectedMembers(ids)
  }


  const handleRadioChange = (event:any) => {

    const currentChoice:any = event.target.value;
    const previousChoice:any = currentChoice === "horizontal" ? "vertical" : "horizontal";
    setCurrentValues({...currentValues,
      [previousChoice]: selectedMembers
    })

    setSelectedMembers(currentValues?.[currentChoice])

    setHierarchy(event.target.value)
  }

  useEffect(()=>{
    if(hierarchy === 'horizontal'){
      setAssignMemberList(horizontalMemberList)
    }else{
      setAssignMemberList(verticalMemberList)
    }
  },[hierarchy])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='assign-mandate-model'
      >
        <Box sx={style}>
          <div className='assign-mandates-header'>
            <Typography variant='h6' component='h3'>
              Assign Mandates
            </Typography>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'inherit !important',
                color:'#000000'
              }}
              className='app-dialog-title-icon'
            >
              <CloseIcon style={{cursor:"pointer"}} fontSize='medium' />
            </IconButton>
          </div>
          <div className='assign-radio-group' >
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Hierarchy</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={hierarchy}
                onChange={handleRadioChange}
              >
                <FormControlLabel disabled={Boolean(assigneeList?.selectedIds?.length)} value="vertical" control={<Radio />} label="Vertical" />
                <FormControlLabel disabled={Boolean(assigneeList?.selectedIds?.length)} value="horizontal" control={<Radio />} label="Horizontal" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='inside-modal-popup' style={{ width: '100%', height: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                multiple
                limitTags={2}
                id='checkboxes-tags'
                className={`inner-textfield`}
                options={assignMemberList}
                sx={{ borderRadius: '10px', maxHeight:"50px" }}
                disableCloseOnSelect
                disableListWrap
                getOptionLabel={(option) => option?.firstName}
                value={assignMemberList?.filter((ele: any) =>
                  selectedMembers?.includes(ele?.id),
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                    <Avatar {...stringAvatar(option?.firstName)} className='assignee-avatar' />
                    <div>
                      <div className='assignee-name-text'>{option?.firstName}{<span style={{color:'GrayText',fontSize:'12px', paddingLeft:'5px'}}>{getLoggedInUserId()=== option.id ? '(Assign to me)':''}</span>  }</div>
                      <div className='assignee-role-text'>{option?.roleName}</div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label='Assignee' placeholder={selectedMembers?.length===0 ? "Search and Select Assignee" : ""} />
                )}
                onChange={handleSelect}
              />
            </FormControl>
            <Box className='bottom-buttons'>
              <AppButton className='bottom-cancel-button' onClick={handleClose}>Cancel</AppButton>
              <AppButton className='bottom-assign-button' onClick={handleAssign}>Assign</AppButton>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
