import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { MandateService } from '../../../services/MandateService';
import AddMandate from "./AddMandate";
import _ from 'lodash'

interface TProps{
    isClone?:boolean
}

const EditMandate:React.FC<TProps> = ({isClone}) => {
    const {id}:any = useParams<any>();
    const isEdit = isClone ? !isClone : !!id
    const [mandateData, setMandateData] = useState<any>({})
    const [isLoading, setIsLoading] = useState<any>(false)
    const getMandateData = async() => {
        setIsLoading(true)
        const response = await MandateService.getMandateDetail(id)
        if(response?.status === 200){
            setMandateData(response?.data?.result?.data || {})
        }
        setIsLoading(false)
    }

    useEffect(()=>{
        getMandateData()
    },[])

    return (
        <>
        {(isLoading || _.isEmpty(mandateData)) ? 
        <>
            <div>loading...</div>
        </> :
            <AddMandate isEdit={isEdit} isClone={!!isClone} mandateData={mandateData} />}
        </>
    );

}

export default EditMandate;