import React from 'react'
import styled from '@emotion/styled'
import { Modal, ModalProps, Stack, Typography } from '@mui/material'
import { AppButton } from '../AppButton'
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg'
import { ReactComponent as FailureIcon } from '../../../assets/icons/failed.svg'
import './AppErrorHandlerModal.css'

const CustomModal = styled(Modal)<CustomModalProps>(() => ({}))

type CustomModalProps = {
  show?: boolean
  handleClose?: () => void
  factor?: string
  content?: string
  ButtonText?: string
} & ModalProps

export const AppErrorHandlerModal: React.FC<CustomModalProps> = ({
  show = true,
  open,
  handleClose,
  factor,
  content,
  ButtonText,
}): React.ReactElement => {
  return (
    <>
      <CustomModal onClose={handleClose} open={open}>
        <div className='modalbox-container'>
          <Stack direction='column' spacing={1} sx={{ mt: 2, mb: 3 }}>
            <Typography component='div' className='check-icon'>
              {show ? <CheckIcon /> : <FailureIcon />}
            </Typography>
            <Typography component='p'> {factor} </Typography>
            <Typography component='p'>{content}</Typography>
          </Stack>
          <AppButton
            variant='text'
            onClick={handleClose}
            textColor='#002882'
            fontSize='18px'
            fontWeight={400}
            label={ButtonText}
          ></AppButton>
        </div>
      </CustomModal>
    </>
  )
}
