import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/backarrowicon1.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClientProfile from '../../assets/icons/clientDetails.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, Controller } from "react-hook-form";
import "../../components/CreateUser/CreateUser.scss"
// import PersonalInformation from './PersonalInformation';
// import EmployeeDetails from './EmployeeDetails';
// import CallingUserDetails from './CallingUserDetails';
import ProfileUrlArray from '../../components/CreateUser/ProfileUrlArray';
import CandidateProfile from "../../assets/icons/candidateProfile.svg"
import CandidateBasicInfo from "../../assets/icons/candidateBasicInfo.svg"
import CandidateWorkExperience from "../../assets/icons/candidateWorkExperience.svg"
import CandidateSkill from "../../assets/icons/candidateSkill.svg"
import CreateUserSocialLink from "../../assets/icons/createUserSocialLink.svg"
import { ReactComponent as HintsIcon } from '../../assets/icons/info-circle.svg'
// import { getRoleList, postCreateUser, postUpdateUser } from '../../services/UserService';
import moment from 'moment';
import { toast } from "react-toastify";
import { AppErrorHandlerModal } from '../../components/AppComponents/AppErrorHandlerModal';
import PersonalCandidateInformation from './PersonalInformation';
import BasicCandidateDetails from './BasicCandidateDetails';
import DesireJobDetails from './DesireJobDetails';
import AcademicDetails from './AcademicDetails';
import CandidateExperience from './CandidateWorkExperience';
import CandidateSkills from './CandidateSkills';
import CandidateFileAttach from './CandidateFileAttach';
import CandidateSocialUrl from './CandidateSocialUrl';
import { dropDownData, dropDownLocation } from '../../services/MandateService';
import { postCandidateData, putCandidateData } from '../../services/CandidateService';
import { AppConfirmationHandlerActionModal } from '../../components/AppComponents/AppErrorHandlerModal/AppConfirmationHandlerActionModal';
import _ from 'lodash';
import { useAppSelector } from '../../store/hooks';

interface IFormInput {
    employeeProfile: string;
    candidateName: string;
    candidateEmail: any;
    primaryContact: string;
    candidateSummary: string;
    dateOfBirth: any;
    gender: string;
    physicalChallenge: string;
    profileSource: string;
    totalExperience:number;
    alternatePhoneNo:string;
    duplicateProfileUrl: string;
    martialStatus: string;
    candidateAddress: string;
    currentCtc: number;
    expectedCtc: number;
    currentLocation: string;
    preferredLocation: string;
    noticePeriod: string;
    employmentStatus: string;    
    candidateSocialFacebook: string;
    candidateSocialLinkedin: string;
    candidateSocialGitHub: string;
    candidateSocialStackoverflow: string;
    candidateWorkExperience: any[];
    candidateSkills: any[];
    cvAttachment: any
  }

const CreateCandidate = () => {
    const location = useLocation();    
    const [candidateDetailData, setCandidateDetailData] = useState(location?.state?.candidateDetail)
    const isEdit:any = location?.state?.status
    const [redirectId, setRedirectId] = useState("")
    const uploadFileAttach = isEdit == "upload" ? location?.state?.fileAttach : ""
    const fileUploadExtension:any = useAppSelector((state:any) => state?.SystemConfig?.uploadFileConfigData)
    const getUploadSkills = (skillObj:any) => {
      const skillsList:any = []
      Object.keys(skillObj).map((skillItem) => {
        candidateDetailData?.resumeData?.data?.skills[skillItem].map((list:any) => {
          skillsList.push(list)
        })        
      })
      return skillsList
    }
    const convertWorkExperience = (workExperience:any) => {
      const options = _.map(workExperience, (data: any) => {
        return { companyName: data.company,workTitle: data.role,workLocation: data.workLocation,workPreferredLocation: data.workPreferredLocation,workStartDate: data.workStartDate != null ? new Date(data.workStartDate) : null ,workEndDate: data.workEndDate != null ? new Date(data.workEndDate) : null ,workSummary:data.description,candidateWorkCurrentCompany:data.candidateWorkCurrentCompany }
      })
      return options
    }
    const convertAcademicExperience = (academicExperience:any) => {
      const options = _.map(academicExperience, (data: any) => {
        return {qualification: data.qualification, course: data.course,educationType: data.educationType,institution: data.institute,academicLocation: data.academicLocation,yearOfPass: (data.yearOfPass != null && data.yearOfPass != "Present") ? new Date(data.yearOfPass) : null,candidateCurrentCompany: data.candidateCurrentCompany}
      })
      return options
    }
    const initValues = {
        employeeProfile: "",
        candidateName: isEdit == "edit" ? candidateDetailData?.name : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.full_name : "",
        candidateEmail: isEdit == "edit" ? candidateDetailData?.email : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.email : "",
        primaryContact: isEdit == "edit" ? candidateDetailData?.phoneNumber : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.phone_number : "",
        candidateSummary: isEdit == "edit" ? candidateDetailData?.about : "",
        dateOfBirth: isEdit == "edit" ? candidateDetailData.personalDetails != null ? candidateDetailData?.personalDetails[0].date_of_birth != null ? new Date(candidateDetailData?.personalDetails[0].date_of_birth) : null : null : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.DOB != null ? (candidateDetailData?.resumeData?.data?.DOB != "Not provided" && candidateDetailData?.resumeData?.data?.DOB.length > 0) ? new Date(candidateDetailData?.resumeData?.data?.DOB) : null: null: null,
        gender: isEdit == "edit" ? candidateDetailData.personalDetails != null ? candidateDetailData?.personalDetails[0].gender : "" : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.gender : "",
        physicalChallenge: isEdit == "edit" ? candidateDetailData.personalDetails != null ? candidateDetailData?.personalDetails[0].physically_challenged : "": isEdit == "upload" ? "" : "",
        profileSource: isEdit == "edit" ? candidateDetailData?.sourceFrom : isEdit == "upload" ? "" : "",
        totalExperience: isEdit == "edit" ? candidateDetailData?.experience : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.GPA : 0,
        alternatePhoneNo: isEdit == "edit" ? candidateDetailData.personalDetails != null ? candidateDetailData?.personalDetails[0].alternate_phone_number : "": isEdit == "upload" ? "" : "",
        duplicateProfileUrl: isEdit == "edit" ? candidateDetailData.duplicatProfile : "",
        martialStatus: isEdit == "edit" ? candidateDetailData.personalDetails != null ? candidateDetailData?.personalDetails[0].marital_status : "": isEdit == "upload" ? "" : "",
        candidateAddress: isEdit == "edit" ? candidateDetailData?.address : "",
        currentCtc: isEdit == "edit" ? candidateDetailData?.ctcInLacs != null ? candidateDetailData?.ctcInLacs : null : 0,
        expectedCtc: isEdit == "edit" ? candidateDetailData?.expectedCtc != null ? candidateDetailData?.expectedCtc : null : 0,
        currentLocation: isEdit == "edit" ? candidateDetailData?.currentLocation : "",
        preferredLocation: isEdit == "edit" ? candidateDetailData?.preferredLocations : "",
        noticePeriod: isEdit == "edit" ? candidateDetailData?.noticePeriod : "",
        employmentStatus: isEdit == "edit" ? candidateDetailData?.desiredJobDetails != null ? candidateDetailData?.desiredJobDetails[0].employment_status: "" : "",
        candidateSocialFacebook: isEdit == "edit" ? candidateDetailData?.faceBook : isEdit == "upload" ? "" : "",
        candidateSocialLinkedin: isEdit == "edit" ? candidateDetailData?.linkedin : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.linkedin_url : "",
        candidateSocialGitHub: isEdit == "edit" ? candidateDetailData?.twitter : isEdit == "upload" ? candidateDetailData?.resumeData?.data?.github_main_page_url : "",
        candidateSocialStackoverflow: isEdit == "edit" ? candidateDetailData?.social : isEdit == "upload" ? "" : "",
        academicDetails: isEdit == "edit" ? candidateDetailData?.detailedEducation != null ? convertAcademicExperience(candidateDetailData?.detailedEducation) : [{ qualification: '', course: '',educationType: '',institution: '',academicLocation: '',yearOfPass: null,candidateCurrentCompany: false}] : isEdit == "upload" ? "" : [{ qualification: '', course: '',educationType: '',institution: '',academicLocation: '',yearOfPass: null,candidateCurrentCompany: false}],
        candidateWorkExperience: isEdit == "edit" ? candidateDetailData?.workExperiences != null ? convertWorkExperience(candidateDetailData?.workExperiences) : [{ companyName: '',workTitle: '',workLocation: '',workPreferredLocation: '',workStartDate: null,workEndDate: null,workSummary:'',candidateWorkCurrentCompany:false }] : isEdit == "upload" ? "" : [{ companyName: '',workTitle: '',workLocation: '',workPreferredLocation: '',workStartDate: null,workEndDate: null,workSummary:'',candidateWorkCurrentCompany:false }],
        candidateSkills: isEdit == "edit" ? candidateDetailData.keySkills.split(",") : isEdit == "upload" ? getUploadSkills(candidateDetailData?.resumeData?.data?.skills) : [],
        cvAttachment: ""
      }
      const scrollToError = () => {
        setTimeout(() => {
          const container = document.querySelector(`.reminder_text-danger`);
          container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },100)      
      }
    const navigate =useNavigate()    
    const [employeeRoleList,setEmployeeRoleList] = useState([])
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const [employeeType, setEmployeeType] = useState([])
    const [noticePeriod, setNoticePeriod] = useState([])
    const [candidateGender, setCandidateGender] = useState([])
    const [disability, setDisability] = useState([])
    const [educationType, setEducationType] = useState([])
    const [martialStatus, setMartialStatus] = useState([])
    const [existUserName, setExistUserName] = useState("")
    const [duplicateFieldsList, setDuplicateFieldsList] = useState("")
    const [alreadyExistPopup, setAlreadyExistPopup] = useState(false)
    const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        navigate(`/candidate/${redirectId}`)
      }
      const handleConfirmationClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        navigate(`/candidate-listing`)
      }
      const handleConfirmationEdit = () => {
        setAlreadyExistPopup(false);
      }
    const {
      control,
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      clearErrors 
    }:any = methods

    const getMandateType = async () => {
        const reqObj = {
          "businessUnit": false,
          "appConfiguration":false,
          "dropdownRequest":[
            {
                "drpCode": "XPN0007",
                "drpName": "Candidate_Gender"
            },
            {
                "drpCode": "XPN0009",
                "drpName": "Disability"
            },
            {
                "drpCode": "XPN0008",
                "drpName": "MartialStatus"
            },
            {
                "drpCode":"XPN00010",
                "drpName":"EmpType"
            },
            {
                "drpCode":"XPN00011",
                "drpName":"NoticePeriod"
            },
            {
                "drpCode":"XPN00012",
                "drpName":"EducationType"
            }
        ]
      }
        try{
          const res:any = await dropDownData(reqObj)
    
          if(res?.status === 200){
            // console.log("checking ...",res?.data )
    
            setEmployeeType(res?.data?.result?.dropdownData.EmpType ?? [])
            setNoticePeriod(res?.data?.result?.dropdownData.NoticePeriod ?? [])
            setCandidateGender(res?.data?.result?.dropdownData.Candidate_Gender ?? [])
            setDisability(res?.data?.result?.dropdownData.Disability ?? [])
            setMartialStatus(res?.data?.result?.dropdownData.MartialStatus ?? [])
            setEducationType(res?.data?.result?.dropdownData.EducationType ?? [])
            // console.log("checking ...",res?.data )
            // console.log("checking ...",res?.data?.result?.dropdownData.MANDATE_TYPE )
          }
        }catch(error){
          console.log(error,"error")
        }
      }
      const [primaryList,setPrimaryList] = useState<any>([])
      
      const fetchLocations = async () => {
          const reqObj = {
            "filters": [
                {
                    "field": "parentId",
                    "operator": "equals",
                    "type": "string",
                    "value": "0"
                }
            ],
            "page": {
                "pageNo": -1,
                "pageSize": 10
            },
            "sorting": {
                "direction": "ASC",
                "field": "createdAt"
            }
        }
          try{
            const res:any = await dropDownLocation(reqObj)
            const options = _.map(res.data.result.list, (data: any) => {
              return {
                label: _.get(data, 'locationName'),
                value: _.get(data, 'id')
              }})
            setPrimaryList(options);
      
          } catch {
            setPrimaryList([]);
          }
        }
    useEffect(() => {
        getMandateType();
        fetchLocations();
    },[])

    const formDataManipulation = (reqObj:any) => {
        const filterRoleId:any = employeeRoleList.filter((roleItem:any) => roleItem?.id == reqObj.role)
        const currentCompany = reqObj.candidateWorkExperience.filter((workItem:any) => workItem.candidateWorkCurrentCompany == true)
        const currentEducation = reqObj.academicDetails.filter((acadItem:any) => acadItem.candidateCurrentCompany == true)
        const formData:any = {
            "name": reqObj.candidateName,
            "dateOfBirth": reqObj.dateOfBirth != null ? moment(new Date(reqObj.dateOfBirth)).format('YYYY-MM-DD'): null,
            "experience": reqObj.totalExperience,
            "ctcInLacs": reqObj.currentCtc,
            "expectedCtc": reqObj.expectedCtc,
            "currentCompany": currentCompany.length > 0 ? currentCompany[0].companyName : reqObj.candidateWorkExperience.length > 0 ? reqObj.candidateWorkExperience[reqObj.candidateWorkExperience.length - 1].companyName: "",
            "currentDesignation": currentCompany.length > 0 ? currentCompany[0].workTitle : reqObj.candidateWorkExperience.length > 0 ? reqObj.candidateWorkExperience[reqObj.candidateWorkExperience.length - 1].workTitle : "",
            "currentLocation": typeof reqObj.currentLocation == "object" ? reqObj.currentLocation.label : reqObj.currentLocation,
            "preferredLocations": typeof reqObj.preferredLocation == "object" ? reqObj.preferredLocation.label : reqObj.preferredLocation,
            "workDetails": "",
            "previousCompany": "",            
            "keySkills": reqObj.candidateSkills.join(","),
            "otherSkills": "",
            "about": reqObj.candidateSummary,
            "address": reqObj.candidateAddress,
            "noticePeriod": reqObj.noticePeriod,
            "phoneNumber": reqObj.primaryContact,
            "email": reqObj.candidateEmail,
            "linkedin": reqObj.candidateSocialLinkedin,
            "faceBook": reqObj.candidateSocialFacebook,
            "twitter": reqObj.candidateSocialGitHub,
            "social": reqObj.candidateSocialStackoverflow,
            "duplicateProfile": reqObj.duplicateProfileUrl,
            "educationDetails": currentEducation.length > 0 ? currentEducation[currentEducation.length - 1].institution : "",
            "employment_status": reqObj.employmentStatus,
            "workExperiences": reqObj.candidateWorkExperience.map((workItem:any) => {
              return {
                "role": workItem.workTitle,
                "company": workItem.companyName,
                "date": (workItem.workStartDate != null || workItem.workEndDate != null) ? [workItem.workStartDate != null ? moment(new Date(workItem.workStartDate)).format('YYYY-MM-DD') : "",(workItem.workEndDate != null && !workItem.candidateWorkCurrentCompany) ? moment(new Date(workItem.workEndDate)).format('YYYY-MM-DD') : workItem.candidateWorkCurrentCompany ? "Present" : ""].join(" till ") : "",
                "description": workItem.workSummary,
                "projects": [],
                "workLocation": workItem.workLocation !=null ? typeof workItem.workLocation == "object" ? workItem.workLocation.label : workItem.workLocation : "",
                "workPreferredLocation": workItem.workPreferredLocation != null ? workItem.workPreferredLocation.label : "",
                "workStartDate": workItem.workStartDate !=  null ? moment(new Date(workItem.workStartDate)).format('YYYY-MM-DD') : null,
                "workEndDate": (!workItem.candidateWorkCurrentCompany) ? workItem.workEndDate != null ?  moment(new Date(workItem.workEndDate)).format('YYYY-MM-DD') : null : null,
                "candidateWorkCurrentCompany": workItem.candidateWorkCurrentCompany
              }
            }
            ),
            "detailedEducation": reqObj.academicDetails.map((acadItem:any) => {
              return {
                "designation": [acadItem.qualification,acadItem.course, !acadItem.candidateCurrentCompany ? acadItem.yearOfPass != null ? moment(new Date(acadItem.yearOfPass)).format('YYYY') : "": "Present"].join(","),
                "institute": acadItem.institution,
                "qualification": acadItem.qualification,
                "course": acadItem.course,
                "educationType": acadItem.educationType,
                "academicLocation": acadItem.academicLocation != undefined  ? typeof acadItem.academicLocation == "object" ? acadItem.academicLocation.label : acadItem.academicLocation : "",
                "yearOfPass": !acadItem.candidateCurrentCompany ? acadItem.yearOfPass != null ? moment(new Date(acadItem.yearOfPass)).format('YYYY-MM-DD') : null: "Present",
                "candidateCurrentCompany": acadItem.candidateCurrentCompany
              }
            }),
            "personalDetails": [
              {
                "date_of_birth": reqObj.dateOfBirth != null ? moment(new Date(reqObj.dateOfBirth)).format('YYYY-MM-DD'): null,
                "gender": reqObj.gender,
                "marital_status": reqObj.martialStatus,
                "category": "",
                "physically_challenged": reqObj.physicalChallenge,
                "alternate_phone_number": reqObj.alternatePhoneNo
              }
            ],
            "desiredJobDetails": [
              {
                "job_type": "",
                "employment_status": reqObj.employmentStatus
              }
            ],            
            "sourceFrom": reqObj.profileSource,
          }
          if(isEdit == "edit") formData["id"] = candidateDetailData.id
        return formData
    }

    const mandateSubmitHandler = async (data:any, event:any) => {   
        const mandateData = new FormData()
        const payload = formDataManipulation(data)
        mandateData.append('candidate', JSON.stringify({...payload}))
        if(isEdit == "upload"){
          mandateData.append('candidateCv', uploadFileAttach)
        }else{
          mandateData.append('candidateCv', data.cvAttachment)
        }        
        mandateData.append('image', data.employeeProfile)
        try{
            const response = isEdit == "edit" ? await putCandidateData(mandateData) : await postCandidateData(mandateData)
            if (response?.status == 200 || response?.status == 201) {
              if(response?.data?.status == "failed"){
                setExistUserName(response?.data?.candidateList[0].name)
                setDuplicateFieldsList(`${response?.data?.phoneDuplicate ? response?.data?.candidateList[0].phoneNumber : ""} ${(response?.data?.phoneDuplicate && response?.data?.emailDuplicate) ? "&" : ""} ${response?.data?.emailDuplicate ? response?.data?.candidateList[0].email : ""}`)
                setAlreadyExistPopup(true)
              }else{
                setRedirectId(isEdit == "edit" ? candidateDetailData.id : response?.data?.candidateList[0].id)
                setSuccessMessage(`Candidate has been successfully ${isEdit == "edit" ? "Updated" : "Created"}`)
                setUploadsuccess(true)
              }
            }
        }
        catch(error:any){
            if (error?.response?.status == 500 || error?.response?.status == 409) {
                toast.error("response" in error ? error?.response?.data?.message : error?.message)
            }
        }
    }
    return (
        <>
            <Box className='mandate-wrapper'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  window.history.back()}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>
                    <div className='header-text'>
                        {isEdit ? "Edit" : "New"} Candidate Profile
                    </div>
                </div>
                <Box className="createRoleAccordionSect">
                <FormProvider {...methods}>
                    <Box
                        component="form"
                        data-testid="formHandle"
                        id="mandateForm"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(mandateSubmitHandler)}
                    >                       
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CandidateProfile} alt="otherInfo" />
                            <div>Candidate Profile</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <PersonalCandidateInformation isEdit={isEdit} profileLink={isEdit == "edit" ? candidateDetailData.displayPicture != null ? candidateDetailData.displayPicture : "" : ""}/>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CandidateBasicInfo} alt="otherInfo" />
                            <div>Basic Details</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <BasicCandidateDetails disability={disability} candidateGender={candidateGender} martialStatus={martialStatus}/>
                            {primaryList.length > 0 && <DesireJobDetails employeeType={employeeType} noticePeriod={noticePeriod} primaryList={primaryList}/>}
                            {primaryList.length > 0 && <AcademicDetails educationType={educationType} primaryList={primaryList}/>}
                            <h5 className='cardHeadingStrip'><span>Public Profile</span></h5>
                            <CandidateSocialUrl />
                            <h5 className='cardHeadingStrip'><span>Attach CV <span style={{color:"red",fontSize:"14px"}}>*</span></span></h5>
                            {isEdit &&                             
                              <p className='cvAttachLink'>{isEdit == "upload" ? candidateDetailData?.resumeLink : candidateDetailData.candidateResume}</p>
                            }
                            {(isEdit == undefined) && <><FormControl fullWidth >
                                <Controller
                                    name={'cvAttachment'}
                                    control={control}
                                     rules={{
                                        required: {
                                            value: isEdit == "upload" ? false : true,
                                            message: "This field is required",
                                        }
                                    }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <CandidateFileAttach onChange={onChange}/>
                                    )}
                                />
                                {errors?.cvAttachment && (
                                    <span className="reminder_text-danger select-danger-text" role="alert">
                                        {errors?.cvAttachment?.message}
                                    </span>
                                )}
                            </FormControl>   
                            <Box className='helper-boxtext'>
                            {' '}
                            <HintsIcon />{' '}
                            <Box style={{width: "250px"}}>
                                {`Note: ( ${fileUploadExtension?.mandate.map((item:any) => {return "."+item.key}).join(", ")} ) Maximum Size 10 MB`}
                            </Box>
                        </Box></>}                        
                        </AccordionDetails>
                    </Accordion>                    
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CandidateWorkExperience} alt="otherInfo" />
                            <div>Work Experience</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            {primaryList.length > 0 && <CandidateExperience primaryList={primaryList}/>}
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={CandidateSkill} alt="otherInfo" />
                            <div>Skills</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <CandidateSkills />
                        </AccordionDetails>
                    </Accordion>
                    <Box className='button-container'>
                        <Button 
                            className='cancel-btn'
                            onClick={()=> window.history.back()}
                        >Cancel</Button>
                        <Button 
                            className='save-btn'
                            type='submit'
                            // disabled={isSubmitting || endDateError}
                            onClick={() => {scrollToError()}}
                        >
                            {`${isEdit == "edit" ? "Update" : "Create"}`}
                        </Button>
                        </Box>
                    </Box>
                </FormProvider>
                <AppErrorHandlerModal
                open={uploadsuccess}
                content={SuccessMessage}
                handleClose={handleClose}
                factor='Success'
                ButtonText='Done'
                ><></></AppErrorHandlerModal>
                <AppConfirmationHandlerActionModal
                iconType={"warning"}
                open={alreadyExistPopup}
                content={`<span>${existUserName}</span> profile already exists in the Artemis database <span style="font-weight:600;color:red">${duplicateFieldsList}</span> this fields are duplicated Please edit if you want to update  the profile`}
                handleClose={handleConfirmationClose}
                handleConfirm={handleConfirmationEdit}
                factor='Warning - Duplicate Profile'
                cancelText='Close'
                confirmationBtn= "Edit Profile"
                ><></></AppConfirmationHandlerActionModal>
                </Box>
            </Box>
        </>
    );
};

export default CreateCandidate;