import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@mui/material"
import _ from "lodash"
import { Controller, useFormContext } from "react-hook-form"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import ReactQuill from "react-quill";
const ReactQuillWrapper = ({ value, handleChange }: any) => {
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <div style={{ height: "100%", border: "1px solid #D9D9D9", borderRadius: "10px" }}>
      <ReactQuill
        value={value}
        modules={modules}
        formats={formats}
        onChange={handleChange}
      />
    </div>
  )
}


const PostJobsForm = ({ industriesAPI, jobCategoriesAPI, functionalAreaAPI }: any) => {

  const { control, watch, formState: { errors }, getValues, setValue, trigger }: any = useFormContext()
  const [salaryValue, setSalaryValue] = useState(getValues("salary"))
  const [JobTypeOptions, setJobTypeOptions] = useState([
    {
      label: "Full-time",
      value: 1
    },
    {
      label: "Part-time",
      value: 2
    },
    {
      label: "Internship",
      value: 3
    },
  ])
  const statusOptions = [{ id: 0, value: 1, menuName: "Active" }, { id: 1, value: 2, menuName: "Inactive" }]
  const length = 51;
  const experienceArrayList = Array.from({ length }, (_, index) => index);
  return (
    <Grid className='container-wrapper'>
      <Grid className='row-grid'>
        <FormControl fullWidth required className="job-title-box">
          <Controller
            name="jobTitle"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <TextField
                  label='Job Role / Title'
                  required={true}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )
            }}
          />
          {errors?.jobTitle && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.jobTitle?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth>
          {/* <InputLabel>Role Category</InputLabel> */}
          <Controller
            name="roleCategory"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Autocomplete
                options={jobCategoriesAPI}
                value={jobCategoriesAPI.find((loc:any) => loc.value === value)}
                onChange={(event, value) => {
                  onChange(value != null ? value.value : [])
                }}
                renderInput={(params) => <TextField {...params} label='Role Category' />}
              />
            )}
          />          
        </FormControl>
        <FormControl fullWidth>
          {/* <InputLabel>Industry Type</InputLabel> */}
          <Controller
            name="industryType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Autocomplete
                options={industriesAPI}
                value={industriesAPI.find((loc:any) => loc.value === value)}
                onChange={(event, value) => {
                  onChange(value != null ? value.value : [])
                }}
                renderInput={(params) => <TextField required={true} {...params} label='Industry Type' />}
              />
            )}
          />
          {errors?.industryType && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.industryType?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth>
          {/* <InputLabel>Functional Area</InputLabel> */}
          <Controller
            name="functionalArea"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Autocomplete
                options={functionalAreaAPI}
                value={functionalAreaAPI.find((loc:any) => loc.value === value)}
                onChange={(event, value) => {
                  onChange(value != null ? value.value : [])
                }}
                renderInput={(params) => <TextField required={true} {...params} label='Functional Area' />}
              />
            )}
          />
          {errors?.functionalArea && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.functionalArea?.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid className='row-grid pt-1'>
        <FormControl fullWidth>
          {/* <InputLabel>Experience (From)</InputLabel> */}
          <Controller
            name="expFrom"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              validate: (value) => {     
                const status = value <= getValues("expTo");
                return status
              }
            }}
            render={({ field: { onChange, value },fieldState }) => (
              <>
              <Autocomplete
                options={experienceArrayList}
                value={value}
                onChange={(event, value) => {
                  onChange(value)
                  trigger("expTo")
                }}
                renderInput={(params) => <TextField required={true} {...params} label='Experience (From)' />}
              />
              {fieldState.invalid ? (
                  <span className="reminder_text-danger select-danger-text invalid" role="alert">
                  {`Experience (From) need to be lesser than Experience (To)`}
                </span>
            ) : null}
              </>
            )}
          />
          {errors?.expFrom && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.expFrom?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth>
          {/* <InputLabel>Experience (To)</InputLabel> */}
          <Controller
            name="expTo"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              validate: (value) => {     
                const status = value >= getValues("expFrom")       
                return status
              }
            }}
            render={({ field: { onChange, value },fieldState }) => (
              <>
              <Autocomplete
                options={experienceArrayList}
                value={value}
                onChange={(event, value) => {
                  onChange(value)
                  trigger("expFrom")
                }}
                renderInput={(params) => <TextField required={true} {...params} label='Experience (To)' />}
              />
                {fieldState.invalid ? (
                  <span className="reminder_text-danger select-danger-text invalid" role="alert">
                  {`Experience (To) need to be greater than Experience (From)`}
                </span>
            ) : null}
            </>
            )}
          />
          {errors?.expTo && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.expTo?.message}
            </span>
          )}         
        </FormControl>
        <Box className="jobLocationFields">
          <FormControl fullWidth >
            <Controller
              name="jobLocation"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "This field is required",
                }
              }}
              render={({ field: { onChange, value, onBlur } }) => {
                return (
                  <Autocomplete
                    clearIcon={false}
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={value}
                    freeSolo
                    onChange={(event, value) => {
                      onChange(value)
                    }}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip variant="outlined" label={option} key={key} {...tagProps} />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        variant="filled"
                        label='Job Location'
                        placeholder="Location"
                      />
                    )}
                  />
                )
              }}
            />
            {errors?.jobLocation && (
              <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.jobLocation?.message}
              </span>
            )}
          </FormControl>
        </Box>
        
        <FormControl fullWidth className={salaryValue == "Not Disclosed by Recruiter" ? "discloseText":"sasdasd"}>
          <Controller
            name="salary"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <TextField
                  label='Salary'
                  required={true}
                  value={value}
                  // onChange={onChange}
                  onChange={(e) => {
                    onChange(e?.target?.value)
                    setSalaryValue(e?.target?.value)
                  }
                  }
                  
                  onBlur={onBlur}
                />
              )
            }}
          />
          {errors?.salary && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.salary?.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid className='row-grid pt-1'>
        <FormControl required fullWidth>
          <InputLabel>Employment Type</InputLabel>
          <Controller
            name="employeeType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
                label='Employement Type'
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                }
                }
                onBlur={onBlur}
              >
                {JobTypeOptions.map((type: any, index: any) => (
                  <MenuItem key={index} value={type?.value}>{type?.label}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors?.employeeType && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.employeeType?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="qualification"
            control={control}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "This field is required",
            //   }
            // }}
            render={({ field: { onChange, value, onBlur } }) => {
              return (
                <TextField
                  label='Qualification'
                  // required={true}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )
            }}
          />
          {/* {errors?.qualification && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.qualification?.message}
            </span>
          )} */}
        </FormControl>
        <Box style={{ display: "flex", gap: "0px",flexDirection:"column",width:"100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <Controller
              name="expiryDate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "This field is required",
                }
              }}
              render={({ field: { onChange, ...restField } }) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
                  <DatePicker
                    label='Expiry Date'
                    className='datePicker datePickerMandate'
                    inputFormat="DD/MM/YYYY"
                    onChange={(e: any) => {
                      onChange(e)
                    }}
                    components={{
                      OpenPickerIcon: CalenderIcon,
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        name='expiryDate'
                        style={{ width: "100%" }}
                        required={true}
                        error={false}
                      />
                    )}
                    {...restField}
                  />
                </div>
              )}
            />           
          </LocalizationProvider>
          {errors?.expiryDate && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.expiryDate?.message}
            </span>
          )}
        </Box>
        <FormControl fullWidth required>
          <InputLabel>Status</InputLabel>
          <Controller
            name="status"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
                label='Status'
                value={value}
                onChange={(e) => onChange(e?.target?.value)}
                onBlur={onBlur}
              >
                {_.map(statusOptions, (prio: any, index: any) => (
                  <MenuItem key={prio?.id} value={prio?.value}>{prio?.menuName}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors?.status && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.status?.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid className='row-grid pt-1' style={{marginTop:"20px"}}>
        <Box className="jobPostSkillsSect">
          <FormControl fullWidth className="keySearchField ">

            <Controller
              name="addSkills"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <Autocomplete
                  clearIcon={false}
                  multiple
                  id="tags-filled"
                  options={[]}
                  defaultValue={value}
                  freeSolo
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip variant="outlined" label={option} key={key} {...tagProps} />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Add Skills"
                      placeholder="Skills"
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Box>

        <Box className="jobPostSkillsSect">
          <FormControl fullWidth className="keySearchField ">

            <Controller
              name="jobSkills"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <Autocomplete
                  clearIcon={false}
                  multiple
                  id="tags-filled"
                  options={[]}
                  defaultValue={value}
                  freeSolo
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip variant="outlined" label={option} key={key} {...tagProps} />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label='Job Skills'
                      placeholder="Skills"
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Box>

      </Grid>
      <Grid className='row-grid pt-1' style={{ minHeight: "280px" }}>
        <FormControl fullWidth >
          <Box className="postJobJoDescField">
            <span className='lable-text-select'>Job description <span style={{color:"red"}}>*</span></span>
            {/* <FormControlLabel required control={<Switch />} label="Use Source Job Description" /> */}
          </Box>          
          <Controller
            name="jobDescription"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <ReactQuillWrapper
                value={value}
                handleChange={(e: any) => {
                  (e.replace(/<\/?[^>]+(>|$)/g, "") == "") ? onChange("") : onChange(e);
                }}
              />
            )}
          />
          {errors?.jobDescription && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.jobDescription?.message}
            </span>
          )}
        </FormControl>

        <FormControl fullWidth >
          <span className='lable-text-select'>Note to candidate</span>
          <Controller
            name="candidateNotes"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <ReactQuillWrapper
                value={value}
                handleChange={onChange}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default PostJobsForm;