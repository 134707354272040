import { TextField, Box, Typography, InputAdornment, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import DayViewRecruiter from '../RecruiterCard/RecruiterContainer'
import './WeekView.scss'
import _ from 'lodash'

type TWeekProp = {
    selectedDate: any;
    viewType: string;
    recruiterContent: any;
    getAllocatedMandate: () => void;
    isMyTeam: boolean;
    setIsMyTeam: (val:any) => any;
    recruiterReqObj: any;
    setStageId: any;
    setSearchFilterValue?:any;
    searchFilterValue?:string
}


const WeekView:React.FC<TWeekProp> = ({ selectedDate, viewType, isMyTeam, setIsMyTeam, recruiterContent, getAllocatedMandate, recruiterReqObj, setStageId, setSearchFilterValue,searchFilterValue }) => {

    const [filterText, setFilterText] = useState<string>('')
    const [active,SetActive] = useState("Submissions")
    const startOfWeek = `${selectedDate.clone().startOf('isoWeek').format('ddd DD')}`;
    const secondDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(1, 'days').format('ddd DD')}`;
    const thirdDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(2, 'days').format('ddd DD')}`;
    const fourthDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(3, 'days').format('ddd DD')}`;
    const fifthDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(4, 'days').format('ddd DD')}`;
    const sixthDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(5, 'days').format('ddd DD')}`;
    const endOfWeek = `${selectedDate.clone().startOf('isoWeek').add(6, 'days').format('ddd DD')}`;

    const [recruiterDataArr, setRecruiterDataArr] = useState<any[]>([])

    // const onInputChange = _.debounce((searchText: string) => {
    //   const arrData = recruiterContent?.filter((data: any) =>
    //     data?.recruiterName?.toLowerCase()?.includes(searchText),
    //   )
    //   setRecruiterDataArr([...arrData])
    // }, 500)

    useEffect(() => {
        setFilterText('')
        setRecruiterDataArr([...recruiterContent])
    }, [recruiterContent])


    return (
        <>
            <Box className="week-view-container" >
            <Box className="week-view-table-container">
                <Box className="workManagStickyHead">
                    <Box className="week-view-filter" >
                        <TextField
                            className='recruiter-search'
                            placeholder='Search Recruiter...'
                            value={searchFilterValue ? searchFilterValue : filterText}
                            onChange={(e: any) =>{
                                setSearchFilterValue(e.target.value)
                                setFilterText(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box className="Kpi-tab">
                            <div onClick={()=>{
                                SetActive("Submissions")
                                setStageId(3)
                                }} className={`week-view-box-container  ${active == "Submissions" && 'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    Submissions
                                </Typography>
                            </div>

                            <div onClick={()=>{
                                SetActive("CCM 1")
                                setStageId(4)
                                }} className={`week-view-box-container  ${active == "CCM 1"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    CCM 1
                                </Typography >
                            </div>

                            <div onClick={()=>{
                                SetActive("CCM 2")
                                setStageId(11)
                                }} className={`week-view-box-container  ${active == "CCM 2"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    CCM 2
                                </Typography>
                            </div>

                            <div onClick={()=>{
                                SetActive("Selects")
                                setStageId(5)
                                }} className={`week-view-box-container  ${active == "Selects"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    Selects
                                </Typography>

                            </div>


                            <div onClick={()=>{
                                SetActive("Joints")
                                setStageId(6)
                                }} className={`week-view-box-container  ${active == "Joints"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    Joins
                                </Typography>
                            </div>

                        </Box>

                    </Box>
                    <Box className="week-view-header">
                            <div className='my-team-container'>
                                <Switch checked={isMyTeam} onChange={(e:any) => setIsMyTeam(e.target.checked)} />
                                <Typography className='my-team'>My Team</Typography>
                            </div>


                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                    {startOfWeek}
                                </Typography>
                            </div>

                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {secondDayOfWeek}
                                </Typography >
                            </div>

                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {thirdDayOfWeek}
                                </Typography>
                            </div>

                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {fourthDayOfWeek}
                                </Typography>

                            </div>
                            
                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {fifthDayOfWeek}
                                </Typography>

                            </div>
                            
                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {sixthDayOfWeek}
                                </Typography>

                            </div>


                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                    {endOfWeek}
                                </Typography>
                            </div>



                        </Box>
                </Box>

                    <Box className="week-view-table">
                        {recruiterDataArr.map((eachRecruiter: any, index: number) => {
                            return <DayViewRecruiter viewType={viewType} recruiterData={eachRecruiter} key={index} getAllocatedMandate={getAllocatedMandate} recruiterReqObj={recruiterReqObj} recruiterDataArr={recruiterDataArr} activeTabStatus= {active}/>
                        })}
                        {/* <MandateTable/> */}



                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default WeekView