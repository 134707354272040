import { 
    Box,
    IconButton,
    Button
  } from '@mui/material'
  import './PostJobs.scss';
  import React, { useEffect, useState } from 'react';
  import { ReactComponent as BackIcon } from '../../../assets/icons/backarrowicon.svg';
  import { useLocation, useNavigate, useParams } from 'react-router-dom';
  import { useForm, FormProvider } from "react-hook-form";
  import 'react-quill/dist/quill.snow.css'
  import GroupStrick from '../../../assets/images/default-avatar.png'
  import CloseIcon from '@mui/icons-material/Close'
  import { getApprovalLists } from '../../../services/ApprovalListService';
  import { toast } from "react-toastify";
  import { 
    createMandateService, 
    updateMandateService, 
    dropDownData,
    MandateService,
    postJobPost,
    getIndustriesList,
    getJobCaregoriesList,
    getFunctionalAreaList,
    postJobCareerId, 
  } from '../../../services/MandateService';
  import _ from 'lodash'
  import moment from 'moment';
  import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
  import { useAppDispatch, useAppSelector } from '../../../store/hooks';
  import { drawerclose,mandateEdit } from '../../../features/mandateDetail/DrawerReducer';
  import { leftdrawertoggleclose } from '../../../features/toggle/ToggleReducer';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { AppButton } from '../../AppComponents/AppButton';
import PostJobsForm from './PostJobsForm';
import { AppConfirmationHandlerActionModal } from '../../AppComponents/AppErrorHandlerModal/AppConfirmationHandlerActionModal';
  interface IFormInput {
    jobTitle: string;
    roleCategory: any;
    industryType: any;
    functionalArea: any;
    expFrom: any;
    expTo: any;
    jobLocation: any;
    salary: string;
    employeeType: any;
    qualification: string;
    expiryDate: any;
    status: any;
    addSkills: string;
    jobSkills: string;
    candidateNotes: string;
    jobDescription: string;
  }
  
  type Props = {
    isEdit?: any;
  }
  interface TProps {
    open: boolean
    mandateIds: any
   
  }
  const PostJobs: React.FC<Props> = (props) => {
    const location = useLocation();   
    const {id}:any = useParams<any>();
    const [mandateData, setMandateData] = useState<any>(location?.state?.mandateView)
    const jobPostType = location?.state?.type
    const [isLoading, setIsLoading] = useState<any>(false)
    const userEmailState = useAppSelector((state:any) => state?.UserDetail?.username)
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const [loadCount, setLoadCount] = useState(0)
    const [savedJsonObj, setSavedJsonObj] = useState<any>({})
    const [confirmSubmittStatus, setConfirmSubmittStatus] = useState(false)
    const getMandateData = async() => {
        setIsLoading(true)
        const response = await MandateService.getMandateDetail(id)
        if(response?.status === 200){
            setMandateData(response?.data?.result?.data || {})
        }
        setIsLoading(false)
    }

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [hasError, setHasError] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [openAssignPopup,setOpenAssignPopup]= useState<boolean>(false)
    const [apiPayload, setApiPayload]= useState({})
    const [members, setMembers] = useState([])
    const [failurePopupMsg, setFailurePopupMsg] = useState<string>("Server Error")
    const [industriesAPI, setIndustriesAPI] =useState<any>([])
    const [jobCategoriesAPI, setJobCategoriesAPI] =useState<any>([])
    const [functionalAreaAPI, setFunctionalAreaAPI] =useState<any>([])
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const isEdit=false;
    let locationPreselect = []
    if(jobPostType == "create"){
      locationPreselect = mandateData.mandateLocation.map((locItem:any) => {
        return locItem.primaryLocation
      })
    }
    const initValues = {
      jobTitle: (jobPostType == "update") ? mandateData?.xp_job_title : mandateData?.jobTitle,
      roleCategory: (jobPostType == "update") ? mandateData?.xp_job_category : "",
      industryType: (jobPostType == "update") ? mandateData?.xp_job_industry : "",
      functionalArea: (jobPostType == "update") ? mandateData?.xp_job_functional_area : "",
      expFrom: (jobPostType == "update") ? mandateData?.xp_experience_from : mandateData.expRangeStart,
      expTo: (jobPostType == "update") ? mandateData?.xp_experience_to : mandateData.expRangeEnd,
      jobLocation: (jobPostType == "update") ? mandateData?.xp_job_location.split(",") : locationPreselect,
      salary: (jobPostType == "update") ? mandateData?.xp_salary : "Not Disclosed by Recruiter",
      employeeType: (jobPostType == "update") ? mandateData?.xp_employment_type : 1,
      qualification: (jobPostType == "update") ? mandateData?.xp_qualification : "",
      expiryDate: (jobPostType == "update") ? new Date(mandateData?.xp_expiry_date) : new Date(mandateData?.requestStartDate),
      status: (jobPostType == "update") ? mandateData?.xp_status : "",
      addSkills: (jobPostType == "update") ? "" : "",
      jobSkills: (jobPostType == "update") ? mandateData?.xp_key_skills.split(",") : mandateData.primarySkills.split(","),
      candidateNotes: (jobPostType == "update") ? mandateData?.xp_job_note : "",
      jobDescription: (jobPostType == "update") ? mandateData?.xp_job_description : mandateData.jobDescription
    }    

    const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });

    const {
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      clearErrors 
    } = methods

    const scrollToError = () => {
      setTimeout(() => {
        const container = document.querySelector(`.reminder_text-danger`);
        container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },100)      
    }

    const handleClose = () => {
      setUploadsuccess(false);
      setSuccessMessage('');
      navigate('/mandates')
    }

    const dataManipulation = (data:any) => {
      const obj:any = {
        "career_id": jobPostType == "update" ? mandateData.xp_career_id : "",
        "job_id" : id,
        "job_title": data?.jobTitle,
        "job_category": data.roleCategory,
        "industry_type": data.industryType,
        "functional_area" : data.functionalArea,
        "salary" : data.salary,
        "job_location" : data.jobLocation,
        "experience_from" : data.expFrom,
        "experience_to" : data.expTo,
        "employment": data.employeeType,
        "qualification" : data.qualification,
        "expiry_date" : data?.expiryDate != null ? moment(new Date(data?.expiryDate)).format('YYYY-MM-DD') : "",
        "job_skills" : data.jobSkills,
        "add_skills" : data.addSkills,
        "desc": data.jobDescription,
        "job_note": data.candidateNotes,
        "status" : data.status,
        "post_type" : 1,
        "posted_by" : userEmailState
      }  
      return obj;
    }

    const SubmittConfirmed = async () => {
      const jobPostPayload = dataManipulation(savedJsonObj)
      setConfirmBtnDisable(true)
      try{
      const response = await postJobPost(jobPostPayload)
      if (response?.status == 200 || response?.status == 201) {
        if (response?.data?.message?.includes("successfully")) {
          try{
          const updateResponse = await postJobCareerId({
            "mandateId": id,
            "xpCareerId": response?.data?.id
        })
          if (updateResponse?.status == 200) {
            setConfirmBtnDisable(false)
            setConfirmSubmittStatus(false)
            setUploadsuccess(true)
            setSuccessMessage(jobPostType == "update" ? `${mandateData.mandateCode} Mandate has been successfully updated in the Xpheno job portal` : `${mandateData.mandateCode} Mandate has been successfully published in the Xpheno job portal`)
          }}catch(error:any){
            console.log('error', error.message)
          }
          }else if(response?.data?.message?.includes("nothing")){
            toast.error(response?.data?.message)
          }else{
            setHasError(true)
        }
      }}catch(error:any){
        console.log(error)
        setFailurePopupMsg(error?.response?.data?.error?.message || "Server Error")
        setHasError(true)
        setConfirmBtnDisable(false)
      }
    }

    const jobPostSubmitHandler = async (data:any, event:any) => {
      setSavedJsonObj(data);
      setConfirmSubmittStatus(true)
    }

    const getIndustriesApi = async () => {
      try{
        const res:any = await getIndustriesList()    
        const options = _.map(res, (data: any) => {
          return {
            label: _.get(data, 'xp_industry_name'),
            value: _.get(data, 'xp_industry_id')
          }})  
        setIndustriesAPI(options)
      }catch(error:any){
        toast.error(error?.response?.data?.message)
      }
    }

    const getJobCategoriesApi = async () => {
      try{
        const res:any = await getJobCaregoriesList()
        const options = _.map(res, (data: any) => {
          return {
            label: _.get(data, 'cat_title'),
            value: _.get(data, 'id')
          }})  
        setJobCategoriesAPI(options)
      }catch(error:any){
        toast.error(error?.response?.data?.message)
      }
    }

    const getFunctionalAreaApi = async () => {
      try{
        const res:any = await getFunctionalAreaList()
        const options = _.map(res, (data: any) => {
          return {
            label: _.get(data, 'xp_functional_area'),
            value: _.get(data, 'xp_functional_area_id')
          }})  
        setFunctionalAreaAPI(options)
      }catch(error:any){
        toast.error(error?.response?.data?.message)
      }
    }

    const LoadFieldAPi = async () => {
      await getIndustriesApi();
      await getJobCategoriesApi();
      await getFunctionalAreaApi();
      setLoadCount(3)
    }

    useEffect(()=>{
      LoadFieldAPi()
    },[])
  
    return (
      <Box className='postjobs-wrapper'>
        <div className='nav-header'>
          <IconButton
              onClick={() =>  {window.history.back()}}
              aria-label='back'
              disableRipple
              disableFocusRipple
          >
              <BackIcon />
          </IconButton>
          <div className='header-text'>
            {`${jobPostType == "update" ? "Update " : "Post "} Job in Xpheno Job Portal`}
          </div>
        </div>
        <div className='alert-header red' style={{marginTop:"15px"}}>
        Please don’t post any confidential information such as Client Name and Client Contacts ,.etc.
        </div>
        <FormProvider {...methods}>
          <Box
            component="form"
            data-testid="formHandle"
            id="postjobsForm"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(jobPostSubmitHandler)}
          >

            <PostJobsForm key={loadCount} industriesAPI={industriesAPI} jobCategoriesAPI={jobCategoriesAPI} functionalAreaAPI={functionalAreaAPI}/>

            <Box className='button-container'>
              <Button 
                className='cancel-btn'
                onClick={()=> window.history.back()}
              >Cancel</Button>
              <Button 
                className='save-btn'
                type='submit'
                disabled={isSubmitting}
                onClick={() => {scrollToError()}}
              >
                {jobPostType == "update" ? "Update" : "Publish"}
              </Button>
            </Box>
          </Box>
        </FormProvider>
        <AppErrorHandlerModal
          open={uploadsuccess}
          content={SuccessMessage}
          handleClose={handleClose}
          factor='Success'
          ButtonText='Done'
        ><></></AppErrorHandlerModal>
          <AppConfirmationHandlerActionModal
            iconType={"warning"}
            open={confirmSubmittStatus}
            content={`Please confirm that no confidential information, such as client details or client contacts, has been included in this post.`}
            handleClose={() => {setConfirmSubmittStatus(false)}}
            handleConfirm={SubmittConfirmed}
            factor=''
            cancelText='Cancel'
            diableConfirmBtn={confirmBtnDisable}
            confirmationBtn= "Publish"
            ><></></AppConfirmationHandlerActionModal>
      </Box>
    );
  }
  
  export default PostJobs;