import React, { useState } from 'react'
import { AppBar as MuiAppBar, Box, SvgIcon, styled, Stack, IconButton } from '@mui/material'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Search } from './Search'
import { UserAvatar } from './UserAvatar'
import NoProfilePhoto from "../../assets/images/no_profile_photo.png";
import { DefaultMenu, MobileMenu, MobileNavigationMenu } from './Menu'
import {
  DRAWER_WIDTH,
  SIDENAV_MIN_WIDTH,
  ICON_COLOR,
  RIGHTNAV_MIN_WIDTH,
  RIGHTNAV_MAX_WIDTH,
} from '../../utils/constants'
import Divider from '@mui/material/Divider'
import './Header.css'
import { ReactComponent as HomeIcon } from '../../../src/assets/icons/home.svg'
import { ReactComponent as TourIcon } from '../../../src/assets/icons/tourIcon.svg'
import { More, NavigationMenus } from '../Actions'
import jwtDecode from 'jwt-decode'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { logout } from '../../features/auth/AuthReducer'
import { useNavigate } from 'react-router-dom'
import { authLogoutUser, toggleTour } from '../../features/userDetail/UserDetailReducer'
import {resetPersistFilter} from '../../features/filters/PersistFilter'
import {
  resetFilters,
  resetFilterPreloadData
} from '../../features/filters/FilterReducer'
import { resetNotificationAll } from '../../features/notification/notificationReducer'
import { AppButton } from '../AppComponents/AppButton'
interface HeaderProps {
  toggleNavigation: () => void
  navigationOpen: boolean
  rightNavigationOpen: boolean
  open?: boolean
  quickViewOpen?: boolean;
  callLogOpen?:boolean
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  rightNavigationOpen?: boolean
  quickLinkWidth?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, rightNavigationOpen, quickLinkWidth=0 }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: ` ${DRAWER_WIDTH}px`,
    marginRight: ` ${RIGHTNAV_MIN_WIDTH + quickLinkWidth}px`,
    width: `calc(100% - ${DRAWER_WIDTH + RIGHTNAV_MIN_WIDTH + quickLinkWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: ` ${SIDENAV_MIN_WIDTH}px`,
    marginRight: ` ${RIGHTNAV_MIN_WIDTH + quickLinkWidth}px`,
    width: `calc(100% - ${SIDENAV_MIN_WIDTH + RIGHTNAV_MIN_WIDTH + quickLinkWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!rightNavigationOpen &&
    open && {
      marginLeft: ` ${DRAWER_WIDTH}px`,
      marginRight: ` ${RIGHTNAV_MIN_WIDTH +quickLinkWidth}px`,
      width: `calc(100% - ${DRAWER_WIDTH + RIGHTNAV_MIN_WIDTH + quickLinkWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  ...(rightNavigationOpen &&
    open && {
      marginLeft: ` ${DRAWER_WIDTH}px`,
      marginRight: ` ${RIGHTNAV_MAX_WIDTH + quickLinkWidth}px`,
      width: `calc(100% - ${DRAWER_WIDTH + RIGHTNAV_MAX_WIDTH + quickLinkWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  ...(rightNavigationOpen &&
    !open && {
      marginLeft: ` ${RIGHTNAV_MIN_WIDTH}px`,
      marginRight: ` ${RIGHTNAV_MAX_WIDTH + quickLinkWidth}px`,
      width: `calc(100% - ${SIDENAV_MIN_WIDTH + RIGHTNAV_MAX_WIDTH + quickLinkWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}))

export const Header = ({ navigationOpen, rightNavigationOpen, quickViewOpen, callLogOpen }: HeaderProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const token = useAppSelector((state) => state.auth.token)  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileNavigationAnchorEl, setMobileNavigationAnchorEl] = useState<null | HTMLElement>(null)
  const toggleOpenState = useAppSelector((state) => state.drawertoggle.leftopen)

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMoreAnchorEl(event.currentTarget)

  const handleMobileNavigationMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileNavigationAnchorEl(event.currentTarget)

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null)
  const handleMobileNavigationMenuClose = () => setMobileNavigationAnchorEl(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
    handleMobileNavigationMenuClose()
  }

  // handleLogout
  const handleLogout = async () => {
    dispatch(resetPersistFilter())
    dispatch(resetFilters())
    dispatch(resetFilterPreloadData({}))
    dispatch(resetNotificationAll())
    const accesstoken: { [key: string]: any; id: string } = jwtDecode(token?.access_token)
    const id = accesstoken?.sub
    dispatch(logout(id)).then(() => {
      navigate('/login')
    })
    setTimeout(() =>{
      if(window.location.pathname.includes("login")){
        location.reload()
      }      
    },1000)
    dispatch(authLogoutUser())
  }
  const callTourJS = () => {
    const currentLocation = location.pathname.replace("/","")
    let viewedScreen = document.cookie.split(";").filter((item:any) => item.trim().indexOf("tourScreenViewed") > -1);
    viewedScreen = JSON.parse(viewedScreen[0].replace("tourScreenViewed=","")).filter((item:any) => item != currentLocation)
    document.cookie = `tourScreenViewed=${JSON.stringify(viewedScreen)}`
    dispatch(toggleTour())
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        className={`appbar ${toggleOpenState ? "headerExpand" : "headerShrink"}`}
        elevation={0}
        position='fixed'
        open={navigationOpen}
        rightNavigationOpen={rightNavigationOpen}
        quickLinkWidth={(quickViewOpen || callLogOpen) ? 360 : 0}
      >
        <Box justifyContent='space-between' sx={{ display: { xs: 'flex' }, alignItems: 'center' }} className='xpheno-navigationHeader'>
          <Box display='flex' sx={{ alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'block', md: 'none', sm: 'none' } }} className='hamburger-icon-div' >
              <NavigationMenus onClick={handleMobileNavigationMenuOpen} />
            </Box>
            <div className='home-icon-div' onClick={() => navigate('/dashboard')}>
              <SvgIcon sx={{ color: `${ICON_COLOR}` }}>
                <HomeIcon className='home-icon' />
              </SvgIcon>
            </div>
            {/* <Divider orientation='vertical' flexItem className='divider' /> */}
            {/* <Search /> */}
          </Box>
          <Stack direction='row' className='appHeaderRight'>
            {/* <AppButton
              variant='contained'
              bgcolor={"#000"}
              onClick={() => {callTourJS()}}
              textColor={"#fff"}
              fontSize='13px'
              fontWeight={400}
              label={"Start Tour"}
            >
              <TourIcon />
            </AppButton> */}
            <Box display='flex'>
                <img style={{width:"30px",marginRight:"20px",borderRadius:"5px",cursor:"pointer"}} onClick={handleMenu} src={NoProfilePhoto} />
            </Box>
            <Box
              sx={{ display: { xs: 'block', md: 'none', sm: 'none' } }}
              className='mobile-right-menu'
            >
              <More onClick={handleMobileMenuOpen} open={Boolean(mobileMoreAnchorEl)} />
            </Box>
          </Stack>
        </Box>
      </AppBar>
      <MobileMenu
        isMenuOpen={Boolean(mobileMoreAnchorEl)}
        handleMenuOpen={handleMobileMenuOpen}
        handleMenuClose={handleMobileMenuClose}
        anchorEl={mobileMoreAnchorEl}
      />
      <MobileNavigationMenu
        isMenuOpen={Boolean(mobileNavigationAnchorEl)}
        handleMenuOpen={handleMobileNavigationMenuOpen}
        handleMenuClose={handleMobileNavigationMenuClose}
        anchorEl={mobileNavigationAnchorEl}
      />
      <DefaultMenu
        isMenuOpen={Boolean(anchorEl)}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
        handleLogout={handleLogout}
      />
    </Box>
  )
}
