import React from 'react'
import { AppButton } from '../../../AppComponents/AppButton/AppButton'
import { Modal, ModalProps, Box, Typography,Tooltip } from '@mui/material'
import './RemarkPopup.scss'
import { ReactComponent as CloseIcon } from '../../../..//assets/icons/modalcloseicon.svg';





const RemarkPopup = ({
  open,
  onclose,
  card,
  statusName,
  stageName,
}:any): React.ReactElement => {


  const isRemarkOpen = true
  const remarkData = ""


  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className="remark-model"
        onClose={onclose}
      >
        <Box onClick={()=>console.log("modal click")} className="model-container">
          <Box className="header-wrapper">
            <Typography className='heading'>{"Remarks"}</Typography>
            <CloseIcon style={{cursor:"pointer"}} onClick={onclose} />
          </Box>

          <Box className="body-wrapper">

            <div className="field-container">
              <div className='rowContainer' >
                <span>
                  <div className="label-text" >stage</div>
                  <div className="row-text">{stageName} </div>
                </span>
                <span>
                  <div className="label-text" >Secondary Level</div>
                  <div className="row-text">{stageName} </div>
                </span>
              </div>

            </div>

            <div className="field-container">
              <div className="label-text" >status</div>
              <Tooltip title={statusName ?? "-"} placement='bottom' >
              <div className="status">{statusName} 
              </div>
              </Tooltip>
            </div>

            <div className="field-container">
              <div className="label-text" >Remarks</div>
              <div className="remark-text">{``}
              </div>
            </div>
          </Box>
          <Box className="footer-wrapper"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '20px',
              gap: '15px',
            }}
          >
            <AppButton
              bgcolor='#E5E8EB'
              onClick={onclose}
              textColor='#000'
              fontSize='14px'
              fontWeight={400}
              label='Close'
              width='96px'
            ></AppButton>

          </Box>
        </Box>
      </Modal>
    </>
  )
}
export default RemarkPopup



