/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import './styles.scss';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useMemo } from 'react';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'


type Tprop = {
    candidateDetailsData: any;
}

const Skills: React.FC<Tprop> = ({ candidateDetailsData }) => {

    const renderEmptySearchBox = () => {
        const emptyMessage = `No data found`;
        return (
            <Box className="candidate-empty-search-box candidateDetailWraper">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    {emptyMessage}
                </Box>
            </Box>
        )
    }

    const skillTags = useMemo(() => candidateDetailsData?.keySkills?.length > 0 ? candidateDetailsData?.keySkills?.replace(/["'\\{}]/g,'').split(','): [], [candidateDetailsData]);
    return (
        <div className='skills-wrap'>
            <div className='skill-sec-main'>
                <p className='skill-header'>Primary Skills</p>
                <div className='skill'>
                    {candidateDetailsData?.itSkills?.length ? <table>
                        <thead>
                            <th>SKILLS</th>
                            <th>VERSION</th>
                            <th>LAST USED</th>
                            <th>EXPERIENCE</th>
                        </thead>
                        <tbody>
                        {candidateDetailsData?.itSkills.map((skills: any, index: number) => (
                                <tr key={index}>
                                    <td>{skills?.skills}</td>
                                    <td>{skills?.version}</td>
                                    <td>{skills?.last_used}</td>
                                    <td>{skills?.experience}</td>
                                </tr>
                               
                        ))}
                        </tbody>
                    </table> : renderEmptySearchBox()}
                </div>
            </div>
            <div className='skill-sec-main'>
                <p className='detail-header'>Skill Tags</p>
                <div className='skill-body' style={{width:"100%"}}>
                    {skillTags?.length > 0 ? (
                        skillTags?.map((skill: string, index: number) => {
                            return (
                                <p key={index}>{skill}</p>
                            )
                        })
                    ): renderEmptySearchBox()}
                </div>
            </div>
        </div>
    )
}

export default Skills;
