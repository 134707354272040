import { Avatar, Box, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './MandateLocationListModel.scss'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { AppButton } from '../../AppComponents/AppButton'
import { getCandidateAssignedMandates } from '../../../services/CandidateService'
import { stringAvatar } from '../../../utils/design-utils'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import LocationCityIcon from '@mui/icons-material/LocationCity'

type Tprop = {
  selectedCandidate: any;
  onClose: () => void;
}

const MandateLocationListModel:React.FC<Tprop> = ({ selectedCandidate, onClose }) => {

  const [detailList, setDetailList] = useState<any[]>([])

  const getMandateLocationDetails = async () => {
    try{
      const res = await getCandidateAssignedMandates(selectedCandidate?.id)
      if(res?.status === 200){
        setDetailList(res?.data?.result?.data ?? [])
      }
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    getMandateLocationDetails()
  },[])

  return (
    <Modal
      open={true}
      aria-labelledby='mandate-location-list-title'
      aria-describedby='modal-list-description'
      className='mandate-location-list-model'
    >
      <Box className='mandate-location-container'>
        <Box className='header-box' >
          <Typography>Mandate Locations Mapped</Typography>
          <CloseIcon style={{cursor:"pointer"}} onClick={onClose} />
        </Box>
        <Box className='candidate-profile'>
          <Avatar {...stringAvatar(selectedCandidate?.name)} className='avatar-name'/>
          <Box className='prof-detail'>
            <Typography className='can-name'>{selectedCandidate?.name ?? ""}</Typography>
            <Box className="d-flex">
              {selectedCandidate?.currentLocation && (<Box className="d-flex icon-wrapper">
                <PlaceOutlinedIcon />
                <Typography className='secondary-text'>{selectedCandidate?.currentLocation}</Typography>
              </Box>)}
              {selectedCandidate?.currentDesignation && (<Box className="d-flex icon-wrapper">
                <BusinessCenterOutlinedIcon />
                <Typography className='secondary-text'>{selectedCandidate?.currentDesignation}</Typography>
              </Box>)}
              <Box className="d-flex icon-wrapper">
                <CalendarTodayOutlinedIcon />
                <Typography className='secondary-text'>{selectedCandidate?.experience ?? 0} years</Typography>
              </Box>
              {selectedCandidate?.currentCompany && (<Box className="d-flex icon-wrapper">
                <LocationCityIcon />
                <Typography className='secondary-text'>{selectedCandidate?.currentCompany }</Typography>
              </Box>)}
            </Box>
          </Box>
        </Box>
        <Box className='table-box'>
          <Table>
            <TableHead className='t-head'>
              <TableRow>
                <TableCell>Mandate</TableCell>
                <TableCell>Mandate Location</TableCell>
                <TableCell>Recruiter</TableCell>
                <TableCell>Business UNIT</TableCell>
                <TableCell>Stage</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='t-body'>
              {detailList?.map((data:any, index:number) => (
                <TableRow key={index} >
                  <TableCell>
                    <Typography className='primary-text'>{data?.jobtitle ?? "-"}</Typography>
                    <Typography className='secondary-text'>{data?.uniqueid ?? "-"}</Typography>
                  </TableCell>
                  <TableCell>
                    {/* <Typography className='primary-text'>{data?.location ?? "-"}</Typography> */}
                    <Typography className='secondary-text'>{data?.primary_location ?? "-"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box className='d-flex'>
                      {data?.sourced_by ? <Avatar {...stringAvatar(data?.sourced_by ?? "")} className='avatar-name' /> : <Avatar/>}
                      <Box>
                        <Typography className='primary-text'>{data?.sourced_by ?? "-"}</Typography>
                        <Typography className='secondary-text'>{"Recruiter"}</Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography className='primary-text'>{data?.businessunit ?? "-"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className='primary-text'>{data?.stagename ?? "-"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={data?.mpctrackstatusname ?? "-"} placement='bottom' arrow>
                      <Typography className='primary-text status-tag'>{data?.mpctrackstatusname ?? "-"}</Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box className='btn-box'>
          <AppButton
            label='Cancel'
            bordercolor='#E5E8EB'
            bgcolor='#E5E8EB'
            size='large'
            type='button'
            sx={{ color: '#000;', backgroundColor: '#000;', p: 1 }}
            onClick={onClose}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default MandateLocationListModel