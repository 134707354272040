import { Navigate, Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword";
import ForgotPasswordOTP from "../pages/ForgotPasswordOtp";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";


export const PublicRoutes = () => {

    return (
        <Routes>
            <Route path='*' element={<Navigate to='/login' replace />} />
            <Route index path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/forgot-password-otp' element={<ForgotPasswordOTP />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            {/* <Route path="/*" element={<Navigate replace to="/" />} />
            <Route path='/' element={<LandindPage />} />
            <Route path="/login" element={<LoginPage />} /> */}
        </Routes>
    );
};