import React, {useEffect, useRef, useState } from 'react'
import { Paper, Box, Divider, Typography, Grid, Stack, Button, Checkbox, FormControl, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar  } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { AppButton } from '../../AppComponents/AppButton'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../utils/constants'
import { ReportSearch } from '../Search/Search'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as SortIcon } from '../../../assets/icons/sort-arrow.svg'
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs';
import { AppAutoComplete } from '../../AppComponents/AppAutoComplete'
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { ReactComponent as RotateIcon } from '../../../../src/assets/icons/reset.svg'
import Popover from '@mui/material/Popover';
import { MandateService, getDepartmentRoles, getMandateLocation } from '../../../services/MandateService'
import _ from 'lodash'
import { ReportkManagementService } from '../../../services/ReportListService'
import DepartmentDropDown from '../../Mandates/AddMandate/DepartmentDropDown'
import { useAppDispatch,useAppSelector } from "../../../store/hooks";
import { convertToDisplayDate } from '../../../utils/date-utils'
import moment from 'moment'
import IndustryDropdown from '../../Mandates/AddMandate/IndustryDropdown'
import MuiAlert from '@mui/material/Alert';
import { TaskManagementService } from '../../../services/TaskService'
import { reportFilterLoadData } from '../../../features/filters/FilterReducer'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const FilterComponent = ({filteredColumns,reportData,setTableKeyWordSearch,reportClientAPIList,reportBussinessAPIList,setReportSubmissionAPIData,reportSubmissionAPIData, referAPIObjKeys, mLocationList, setMLocationList, reportFilterPreload, setResetFilter,reportBussinessUnitAPIList}:any) => {
  const [filterChecked, setfilterChecked] = useState<boolean>(false)
  const [columnFilter, setColumnFilter] = useState<boolean>(false)
  const [columnFilterSearch, setColumnFilterSearch] = useState<string>('')
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [keyWordSearch, setKeyWordSearch] = useState("")
  let userRoleState = useAppSelector((state:any) => state?.UserDetail?.userRole);
  const userStateId = useAppSelector((state) => state.UserDetail.userId)
  userRoleState = userRoleState ? userRoleState.replace("/","").replace(/ /g,"").trim() : "";
  const dispatch = useAppDispatch()
  const [filterTabCloseStatus, setfilterTabCloseStatus] = useState<string>()
  const handleFilter = () => {
    setfilterChecked((prev) => !prev)
  }
  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
  const [selectAllColumnsState, setSelectAllColumnsState] = useState<any>(false)  
  const [selectColumnsState, setSelectColumnsState] = useState<any>({
    "Job Title": true,
    "Client": false,
    "Current Organization": false,
    "Total exp": true,
    "Relevant Exp": false,
    "Current Location": true,
    "Preferred Location": false,
    "Notice Period": true,
    "Contact Number": false,
    "e-Mail": false,
    "Current CTC": true,
    "Expected CTC": false,
    "Source": false,
    "CV Send Date": true,
    "Stage": true,
    "Status": true,
    "Recruiter Name": true,
    "Profile entry date in the Artemis": false,
    "Profile last modified date": false,
    "Profile last active date": false
})

  const userbasedReportFilterCase:any = {
    Admin:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],  
    Management:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],
    BUHead:["Delivery/Account Manager","Team Lead","Recruiter"],
    DeliveryAccountManager: ["Team Lead","Recruiter"],
    TeamLead:["Recruiter"],
    Recruiter:[]
  }
  const bussiUnitKeyRefer:any = {
    "BUHead": "bUHead",
    "Delivery/Account Manager": "deliveryManager",
    "Team Lead": "teamLead",
    "Recruiter":"recruiter"
  } 
  const [bussinessUnitOveallObj, setBussinessUnitOveallObj] = useState<any>({})
  const [bussinessUnitOveallObjInitial, setBussinessUnitOveallObjInitial] = useState<any>({})
  const[bussinessUnitObjData, setBussinessUnitObjData] = useState<any>({})
  const currentRoleOrder = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
  const [currentRoleSelected, setCurrentRoleSelected] = useState(userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] ? userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState][0]: "")
  const [heirarchyRoleList, setHeirarchyRoleList] = useState<any>()
  const [userIdSelected, setUserIdSelected] = useState<any>(userId)    
  const [currentRoleStr, setCurrentRoleStr] = useState<any>([])
  const getBussinessUnitObj = () => {
    if(Object.keys(reportBussinessAPIList).length > 0){
      const bussObjArray:any = {};
        const parentRole = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
        const disableRoleObj:any = {}
       
        // const roleBaseObj = reportBussinessAPIList[parentRole[0] == "Recruiter" ? "recuriterDropDown" : parentRole[0] == "Team Lead" ? "teamLeadDropDown": parentRole[0] == "Delivery/Account Manager" ? "accountManagerDropDown" : parentRole[0] == "BUHead" ? "buHeadDropDown": ""]
        // bussObjArray[parentRole[0]] = roleBaseObj
        parentRole.map((item:any, index:number) => {
          let userRoleOption = _.map(reportBussinessAPIList[item == "Recruiter" ? "recuriterDropDown" : item == "Team Lead" ? "teamLeadDropDown": item == "Delivery/Account Manager" ? "accountManagerDropDown" : item == "BUHead" ? "buHeadDropDown": ""], (data: any,index:any) => {
            return {
              label: _.get(data, 'firstName'),
              value: _.get(data, 'id'),
              index: index + 1,
              reporting: _.get(data, 'reporting')
            }
          })
          userRoleOption = prepend({label: "All",value: "All", index: 0}, userRoleOption)
          disableRoleObj[item] = {
            disabled : index > 0 ? true : false,
            options: userRoleOption,
            selectedValue: 0
          }
          })
          setBussinessUnitOveallObj(disableRoleObj)
          if(Object.keys(bussinessUnitOveallObjInitial).length == 0)setBussinessUnitOveallObjInitial(JSON.parse(JSON.stringify(disableRoleObj)))
      setBussinessUnitObjData(bussObjArray)
    }    
  }

  function prepend(value:any, array:any) {
    const newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  const getUserBaseData = (userRole:string) => {
    let userRoleOption = []
    userRoleOption = _.map(bussinessUnitObjData[userRole], (data: any) => {
      return {
        label: _.get(data, 'firstName'),
        value: _.get(data, 'id'),
      }
    })
    return prepend({
      label: "All",
      value: "All",
    }, userRoleOption)
  }

  useEffect(() => {    
    if(userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] != undefined)getBussinessUnitObj()
  },[reportBussinessAPIList])
  

  const [columnFields, setColumnFields] = useState<any>([
    {label: 'Job Title', id: 1, checkStatus: true},
    {label: 'Client', id: 2, checkStatus: false},
    {label: 'Current Organization', id: 3, checkStatus: false},
    {label: 'Total exp', id: 4, checkStatus: true},
    {label: 'Relevant Exp', id: 5, checkStatus: false},
    {label: 'Current Location', id: 6, checkStatus: true},
    {label: 'Preferred Location', id: 7, checkStatus: false},
    {label: 'Notice Period', id: 8, checkStatus: true},    
    {label: 'Contact Number', id: 9, checkStatus: false},
    {label: 'e-Mail', id: 10, checkStatus: false},
    {label: 'Current CTC', id: 11, checkStatus: true},
    {label: 'Expected CTC', id: 12, checkStatus: false},
    {label: 'Source', id: 13, checkStatus: false},
    {label: 'CV Send Date', id: 15, checkStatus: true},    
    {label: 'Stage', id: 16, checkStatus: true},
    {label: 'Status', id: 17, checkStatus: true},
    {label: 'Recruiter Name', id: 18, checkStatus: true},
    {label: 'Profile entry date in the Artemis', id: 19, checkStatus: false},
    {label: 'Profile last modified date', id: 20, checkStatus: false},
    {label: 'Profile last active date',id: 21, checkStatus: false}
  ])

  const onFilterSearchChange = (event:any) => {
    setKeyWordSearch(event.target.value)
    setTableKeyWordSearch(event.target.value)
  }

  const openCoumnFilter = (event:any) => {
    setAnchorEl(event.currentTarget)
    setColumnFilter(true)
    let selectAllFilter = true;
    columnFields.map((columns:any) => {
      !columns.checkStatus ? selectAllFilter = false : "";
    })
    setSelectAllColumnsState(selectAllFilter)
  }

  useEffect(() => {
    const omitKeysObj = ["Delivery/Account Manager","Mandate","Recruiter","Team Lead","client","departmentData","industryData","mandateLocation","submissionDate"]
    let selectAllFilter = true;
    Object.keys(selectColumnsState).map((columns:any) => {
      !omitKeysObj.includes(columns) ? (selectColumnsState[columns] == false ? selectAllFilter = false : "") : "";
    })
    Object.keys(selectColumnsState).length > 0 ? setSelectAllColumnsState(selectAllFilter) : "";
  },[selectColumnsState,columnFilter])

  const { handleSubmit, control, reset, watch, getValues,setValue } = useForm({})

  const hitSave = (data:any,type:string) => {
    filteredColumns(data)
    setSelectColumnsState(data)
  }

  const updateCheckedColumns = (isChecked:boolean) => {
    columnFields.forEach((columns:any) => {
      if(columns.label.toLowerCase().includes(columnFilterSearch)) setValue(columns.label, isChecked)
    })
  }
  const getReportExport = async () => {
    const reportSubmissionAPIDataObj = JSON.parse(JSON.stringify(reportSubmissionAPIData))
    const selColObj = JSON.parse(JSON.stringify(selectColumnsState))
    let columnSelect = _.filter(Object.keys(selColObj), (data: any) => {
      return selColObj[data] && referAPIObjKeys[data]
    })
    columnSelect = _.map(columnSelect , (columItem:any) => {
      return referAPIObjKeys[columItem]
    })
    reportSubmissionAPIDataObj["clientName"] = reportSubmissionAPIDataObj["client"]
    reportSubmissionAPIDataObj["client"] = null
    reportSubmissionAPIDataObj["recruiterName"] = reportSubmissionAPIDataObj["recruiter"]
    reportSubmissionAPIDataObj["mandateId"] = reportSubmissionAPIDataObj["Mandate"]
    reportSubmissionAPIDataObj["recruiter"] = ""
    reportSubmissionAPIDataObj["locationId"] = reportSubmissionAPIDataObj["mandateLocation"]
    reportSubmissionAPIDataObj["mandateLocation"] = ""
    const requestBody = {...reportSubmissionAPIDataObj,...{selectedColumn:[...["candidateName"],...columnSelect]}}
    try{
     const res = await ReportkManagementService.exportSubmitionReport(requestBody)
      if(res?.status === 200){
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CandidateDetails${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }      
    }catch(error){
      console.log(error)
    }
  }
  const [preloadDataObj , setPreloadDataObj] = useState<any>()
  const [submissionStartDate, setSubmissionStartDate] = React.useState<Dayjs | null>(null);
  const [submissionEndDate, setSubmissionEndDate] = React.useState<Dayjs | null>(null);
  useEffect(() => {
    const reportFilterPreloadData = JSON.parse(JSON.stringify(reportFilterPreload))
    delete reportFilterPreloadData['reportList']; 
    delete reportFilterPreloadData['defaultPageCount']; 
    delete reportFilterPreloadData['pageCount']; 
    delete reportFilterPreloadData['pageSize']; 
    setTimeout(() => {
      if(Object.keys(reportFilterPreloadData).length > 0){
        const preloadDataObj = {
          client: reportFilterPreloadData?.client,
          mandate: reportFilterPreloadData?.mandate,
          mandateLocation: reportFilterPreloadData?.mandateLocation,
          submissionDate: reportFilterPreloadData?.submissionDate ? dayjs(moment(new Date(reportFilterPreloadData?.submissionDate)).format('YYYY/MM/DD')): null,
          submissionEndDate: reportFilterPreloadData?.submissionEndDate ? dayjs(moment(new Date(reportFilterPreloadData?.submissionEndDate)).format('YYYY/MM/DD')) : null,
        }
        setPreloadDataObj(preloadDataObj)
        setValue('client', reportFilterPreload?.client)
        setValue('Mandate', reportFilterPreload?.mandate)
        setValue('mandateLocation', reportFilterPreload?.mandateLocation)
        setSubmissionStartDate(preloadDataObj?.submissionDate)
        setSubmissionEndDate(preloadDataObj?.submissionEndDate)
        setBussinessUnitOveallObj((prevOverallUnit: any) => ({...prevOverallUnit, ...JSON.parse(JSON.stringify(reportFilterPreload?.roleBaseObj))}))
        getMandateLocationPreloadList(reportFilterPreloadData?.mandate)
      }
    },500)
    
  },[reportFilterPreload])

  const [mandateLocationPreloadLists, setMandateLocationPreloadLists] = useState([])

  const getMandateLocationPreloadList = async (manId:any) => {
    setMandateLocationPreloadLists([])
    // const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : "" 
    const apiQuery = `?mandateBehaviour=${manId?.mandateBehaviour}`
    const response = await getMandateLocation(manId?.value, apiQuery)
    if(response?.status === 200){
      const locationOptions =_.map(response?.data?.result?.mandateLocations, (data: any) => {
        return {
          label: _.get(data, 'location'),
          value: _.get(data, 'id'),
        }
      })
      setMandateLocationPreloadLists(locationOptions)
      setValue("mandateLocation", preloadDataObj?.mandateLocation)
    }
  }

  const FilterForms = () => {
    const [reportClientList,setReportClientList] = useState(reportClientAPIList)
    const [mandateList,setMandateList] = useState([])
    const [mandateLocationLists, setMandateLocationLists] = useState(mLocationList ?? [])
    const [searchtextonMandate, setSearchtextonMandate] = useState("")
    const [searchMandateList, setSearchMandateList] = useState<any>([])
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    // const [searchMandateListFilter, setSearchMandateListFilter] = useState<any>([])
    const [deptData, setDeptData] =useState([])
    const [snackStatus, setSnackStatus] = useState(false)
    const [snackStatusMessage, setSnackStatusMessage] = useState("")
    const [snackStatusType, setSnackStatusType] = useState<any>("")
    const autoC = useRef<any>(null);
    const autoMandate = useRef<any>(null);    
    const enableFields = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] != undefined ? false : true 

    const getSelectedObj = (optionItem:any,role:string) => {
      let userRoleOption = _.filter(optionItem, (data: any) => {
        return (data?.roleName == "BU Head" ? "BUHead" : data?.roleName) == role
      })
      userRoleOption = _.map(userRoleOption, (data: any, index:any) => {
          return {
            label: _.get(data, 'firstName'),
            value: _.get(data, 'id'),
            index: index + 1,
            reporting: _.get(data, 'reportingTo'),
          }           
      })
      userRoleOption = prepend({label: "All",value: "All", index: 0}, userRoleOption) 
      return userRoleOption
    }
    const getUserListAPI = async (role:string,value:any) => {
      const overAllObj = bussinessUnitOveallObj  
      const RoleStr:any = []     
      const parentRole = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
        const nextRole = (value == "All" && parentRole[0] == role) ? currentRoleOrder : currentRoleOrder.filter((list:any,index:number) => (index) > (value != "All" ? currentRoleOrder?.indexOf(role) : (currentRoleOrder?.indexOf(role) - 1)))
          let userRoleId:any = ""
          if(value == "All" || value == null){
            if(parentRole[0] == role){   
              setUserIdSelected(userId)           
              userRoleId = userId
            }else{
              let PreviousRoleID:any = ""
              heirarchyRoleList?.backwardHierarchy.map((heriList:any,heirIndex:number) => {
                if((heriList.roleName == "BU Head" ? "BUHead" : heriList.roleName) == currentRoleStr[(currentRoleStr.indexOf(role) - 1)]){
                  PreviousRoleID = heirarchyRoleList?.backwardHierarchy[heirIndex].id
                }
              })
              userRoleId = parentRole[0] == role ? userId : PreviousRoleID
              setUserIdSelected(PreviousRoleID)
            }
            
            
          }else{
            setUserIdSelected(value)
            userRoleId = value
          }
          const response = await TaskManagementService.getReportAssignedToUsersHierarchy(userRoleId);  
          setHeirarchyRoleList(response.data)     
          if(response.data.forwardHierarchy.length > 0){            
            nextRole.map((item:any) => {              
              const filterObject = getSelectedObj(response.data.forwardHierarchy,item)
              overAllObj[item] = {
                  options: filterObject,
                  selectedValue: 0
                }
                if(filterObject.length > 1)RoleStr.push(item)
            })
            
            if(value != "All" && parentRole[0] != role){
              RoleStr.unshift(role)
              let currentRoleOption = overAllObj[role].options.filter((list:any,childIndex:any) => list?.reporting == response.data.backwardHierarchy[0]?.id)
              currentRoleOption = prepend({label: "All",value: "All", index: 0}, currentRoleOption)
              currentRoleOption.map((curRole:any,curRoleIndex:number) => curRole.index = curRoleIndex)
              const selectedObj = currentRoleOption.filter((list:any,index:any) => list?.value == value); 
              overAllObj[role] = {
                options: currentRoleOption,
                selectedValue: selectedObj[0]?.index
              } 
            }else{
              const selectedObj = overAllObj[role].options.filter((list:any,index:any) => list?.value == value);
              overAllObj[role] = {
                options: overAllObj[role].options,
                selectedValue: selectedObj[0]?.index
              }
              RoleStr.unshift(currentRoleStr[(currentRoleStr.indexOf(role) - 1)])
              // overAllObj[currentRoleOrder[currentRoleOrder?.indexOf(role)]].selectedValue = selectedObj[0]?.index 
            }
            if(response.data.backwardHierarchy.length > 0){
              let hideRoleList = currentRoleOrder.slice(0, currentRoleOrder.indexOf(role))     
              response.data.backwardHierarchy.map((item:any,index:number) => {
                const roleNameKey = item.roleName == "BU Head" ? "BUHead" : item.roleName                
                if(currentRoleOrder.includes(roleNameKey)){
                  hideRoleList = hideRoleList.filter((e: any) => e !== roleNameKey)
                  RoleStr.unshift(roleNameKey)
                  if(parentRole[0] == (roleNameKey)){                  
                    const parentRoleOption = overAllObj[roleNameKey].options;
                    const hierarchyParentSelection = response.data.backwardHierarchy.filter((heirarchyList:any) => (heirarchyList?.roleName == "BU Head" ? "BUHead" : heirarchyList?.roleName) == parentRole[0])
                    overAllObj[roleNameKey] = {    
                      options: parentRoleOption,          
                      selectedValue: parentRoleOption.filter((list:any,childIndex:any) => list?.value == hierarchyParentSelection[0]?.id)[0]?.index 
                    }
                  }else{
                    let updatedOption = reportBussinessAPIList[roleNameKey == "Recruiter" ? "recuriterDropDown" : roleNameKey == "Team Lead" ? "teamLeadDropDown": roleNameKey == "Delivery/Account Manager" ? "accountManagerDropDown" : roleNameKey == "BUHead" ? "buHeadDropDown": ""].filter((roleObj:any,roleIndex:number) => roleObj?.reporting == response.data.backwardHierarchy[index + 1]?.id)
                    updatedOption = _.map(updatedOption, (data: any, index:any) => {
                      return {
                        label: _.get(data, 'firstName'),
                        value: _.get(data, 'id'),
                        reporting: _.get(data, 'reporting'),
                        index: index + 1
                      }           
                    })
                      updatedOption = prepend({label: "All",value: "All", index: 0}, updatedOption)
                      const selectedValue = updatedOption.filter((list:any,childIndex:any) => list?.value == item?.id)
                      overAllObj[roleNameKey] = {
                        options: updatedOption,
                        selectedValue: selectedValue[0]?.index 
                      }
                    }
                  }                  
              }) 
              if(value == "All") hideRoleList = hideRoleList.filter((e: any) => e !== RoleStr[RoleStr.indexOf(role) -1])
              hideRoleList.map((itemRole:any) => {
                overAllObj[itemRole] = {
                  options: [],
                  selectedValue: 0 
                }
              })
            }         
            // const selectedObj = overAllObj[role].options.filter((list:any,index:any) => list?.value == value);
            // overAllObj[currentRoleOrder[currentRoleOrder?.indexOf(role)]].selectedValue = selectedObj[0]?.index  
        }else{
          const selectedObj = overAllObj[role].options.filter((list:any,index:any) => list?.value == value);
          overAllObj[(currentRoleOrder?.indexOf(role) + 1 == currentRoleOrder.length)? currentRoleOrder[currentRoleOrder?.indexOf(role)] : currentRoleOrder[currentRoleOrder?.indexOf(role) + 1]] = {
            options: [],
            selectedValue: 0
          }
          overAllObj[currentRoleOrder[currentRoleOrder?.indexOf(role)]].selectedValue = selectedObj[0]?.index 
        }
        setCurrentRoleStr(RoleStr)
        setBussinessUnitOveallObj((prevOverallUnit: any) => ({...prevOverallUnit, ...overAllObj}))
    }
    
    const getData = (searchTerm: string) => {
      setSearchtextonMandate(searchTerm)
      MandateService.getSearchMandates(searchTerm).then((res: any) => {      
        const dataItems = _.get(res, 'data.result.list', [])
        setSearchMandateList(dataItems)
        const filterData = dataItems ? dataItems?.filter((item: any) => item?.status !== 'draft') : []
        const options = _.map(filterData, (data: any) => {
          return {
            label: _.get(data, 'title') + ' - ' + _.get(data, 'mandateId'),
            value: _.get(data, 'id'),
            mandateBehaviour: _.get(data, 'mandateBehaviour')
          }
        })
        setMandateList(options)
      })
    }

    const onInputChange = _.debounce((event: any, value: string)=>{
      if (_.trim(value) !== '') {
        getData(value)
      } else {
        setSearchtextonMandate('')
        setMandateList([])
      }
    }, 500)

    const getMandateLocationList = async (manId:any) => {
      setMandateLocationLists([])
      const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : `?mandateBehaviour=${manId?.mandateBehaviour}`
      const response = await getMandateLocation(manId?.value, apiQuery)      
      if(response?.status === 200){
        const locationOptions =_.map(response?.data?.result?.mandateLocations, (data: any) => {
          return {
            label: _.get(data, 'location'),
            value: _.get(data, 'id'),
          }
        })
        setMandateLocationLists(locationOptions)
        setMLocationList(locationOptions)
      }
    }

    const convertToArray = (data:any) => {
      const selectedArray = _.map(data, (dataItem: any) => {
        return dataItem?.id
      })
      return selectedArray
    }

    const filterSubmitHandler = (Data:any) => {
      // let userId:any = "";
      let mandateLocation = false;
      if(getValues("Mandate")){
        if(preloadDataObj?.mandateLocation && !getValues("mandateLocation")){
          mandateLocation = true
        }
        else if(!getValues("mandateLocation")) {
          setSnackStatusMessage("Mandate Location Need to select");
          setSnackStatus(true);
          setSnackStatusType("error");
          return
        }
      }
      if(getValues("submissionDate")){
        if(!getValues("submissionEndDate")){
          setSnackStatusMessage("Submission end date Need to select");
          setSnackStatus(true);
          setSnackStatusType("error");
          return
        }
      }
      // if(Data[currentRoleSelected]?.label == "All"){
      //   userId = currentRoleOrder.indexOf(currentRoleSelected) == 0  ? userStateId : Data[currentRoleOrder[currentRoleOrder.indexOf(currentRoleSelected) - 1]]?.value
      // }else if((Data[currentRoleSelected] == null && currentRoleOrder.indexOf(currentRoleSelected) == 0 && !bussinessUnitOveallObj[currentRoleOrder[currentRoleOrder.indexOf(currentRoleSelected)]]?.disabled) || userRoleState == "Recruiter"){
      //   userId = userStateId
      // }
      // else if(Data[currentRoleSelected] == null && !bussinessUnitOveallObj[currentRoleOrder[currentRoleOrder.indexOf(currentRoleSelected) - 1]]?.disabled){
      //   userId = currentRoleOrder.indexOf(currentRoleSelected) == 0  ? userStateId : bussinessUnitOveallObj[currentRoleOrder[currentRoleOrder.indexOf(currentRoleSelected) - 1]]?.options[bussinessUnitOveallObj[currentRoleOrder[currentRoleOrder.indexOf(currentRoleSelected) - 1]]?.selectedValue]?.value
      // }else{
      //   userId = Data[currentRoleOrder[currentRoleOrder.indexOf(currentRoleSelected)]]?.value
      // }
      const filterSubmitObj = JSON.parse(JSON.stringify(reportSubmissionAPIData));
      Object.keys(Data).map((item:any) => {
        filterSubmitObj[item] = (item == "client" ) ? (Data?.client?.label == "All" ? "" : Data[item]?.label) : Data[item]?.value;
      })
      if(mandateLocation) filterSubmitObj["mandateLocation"] = preloadDataObj?.mandateLocation?.value
      filterSubmitObj["userId"] = userIdSelected
      filterSubmitObj["submissionDate"] = Data?.submissionDate != null ? moment(new Date(Data?.submissionDate)).format('YYYY-MM-DD') : "";
      filterSubmitObj["submissionEndDate"] = Data?.submissionEndDate != null ? moment(new Date(Data?.submissionEndDate)).format('YYYY-MM-DD') : "";
      filterSubmitObj["buid"] = Data?.bussinessUnit != null ? Data?.bussinessUnit.value != "All" ?  Data?.bussinessUnit.value : "": ""
      setReportSubmissionAPIData(filterSubmitObj)
      const reduxFilterData = {
        client: Data?.client,
        mandate: Data?.Mandate,
        mandateLocation: Data?.mandateLocation ? Data?.mandateLocation : reportFilterPreload?.mandateLocation,
        submissionDate: Data?.submissionDate,
        submissionEndDate: Data?.submissionEndDate,
        roleBaseObj : JSON.parse(JSON.stringify(bussinessUnitOveallObj))
      }
      dispatch(reportFilterLoadData({reportFiler: reduxFilterData}))
    }

    const resetFilterData = () => {
      reset()
      setUserIdSelected(userId)
      clearLocationField();
      clearLocationMandateField()
      const filterSubmitObj = JSON.parse(JSON.stringify(reportSubmissionAPIData));
      // const resetObj = ["Mandate","recruiterName","buHead","deliveryManager","teamLead","client","departmentData","industryData","mandateLocation","submissionDate"]
      Object.keys(filterSubmitObj).map((item:string) => {
        filterSubmitObj[item] = (item == "industry" || item == "department") ? [] : item == "pageNo" ? 1 : item == "pageSize" ? 10 : "";
      })
      filterSubmitObj["userId"] = userStateId
      setReportSubmissionAPIData(filterSubmitObj)
      const bussinessUnitState:any = JSON.parse(JSON.stringify(bussinessUnitOveallObjInitial))
      if(!enableFields){
        bussinessUnitState[currentRoleOrder[0]].selectedValue = 0
        setCurrentRoleSelected(userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState][0])
      }
        setBussinessUnitOveallObj((prevOverallUnit: any) => ({...prevOverallUnit, ...bussinessUnitState}))
      const reduxFilterData = {
        client: {},
        mandate: {},
        mandateLocation: {},
        submissionDate: null,
        submissionEndDate: null,
        roleBaseObj : {}
      }
      dispatch(reportFilterLoadData({reportFiler: reduxFilterData}))
      setSubmissionStartDate(null)
      setSubmissionEndDate(null)
      setResetFilter(true)
    }
    const clearLocationField = () => {
      const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
      setMLocationList([])
    } 
    const clearLocationMandateField = () => {
      const ele = autoMandate.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 
    
    
    return (
      <>
        <Divider />
        <Snackbar open={snackStatus} autoHideDuration={6000} onClose={() => {setSnackStatus(false)}}>
            <Alert onClose={() => {setSnackStatus(false)}} severity={snackStatusType} sx={{ width: '100%' }}>
              {snackStatusMessage}
            </Alert>
          </Snackbar>
        <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
          <Grid item xs={12} md={12} sm={3} lg={12}>
            <Typography className='filter-title'> Filter</Typography>
          </Grid>
          <Box component={'form'} onSubmit={handleSubmit((data) => filterSubmitHandler(data))}>
            <div className='filterGrid reportFilters'>
            <Controller
                control={control}
                name='bussinessUnit'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    disablePortal
                    options={reportBussinessUnitAPIList}
                    defaultValue={reportBussinessUnitAPIList[0]}
                    sx={{ width: 300 }}
                    value={watch('bussinessUnit')}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("bussinessUnit", value)   
                      if(value?.label == "All"){
                        clearLocationField();
                        clearLocationMandateField();
                      }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Bussiness Unit" label={`Bussiness Unit`} />}
                  />
                )}
              />
              <Controller
                control={control}
                name='client'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    disablePortal
                    options={reportClientAPIList}
                    defaultValue={reportClientAPIList[0]}
                    sx={{ width: 300 }}
                    value={watch('client')}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("client", value)   
                      if(value?.label == "All"){
                        clearLocationField();
                        clearLocationMandateField();
                      }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Client" label={`Client`} />}
                  />
                )}
              />
              {userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] && userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState].map((item:string,index:number) => {  
                return (
                  <>
                  {bussinessUnitOveallObj[item]?.options.length > 1 && <Box key={index} className={"submissionFilterEnableField"}>
                  <Controller                    
                    key={index}
                    control={control}
                    name={item}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete  
                        value={bussinessUnitOveallObj[item]?.options[bussinessUnitOveallObj[item]?.selectedValue]}
                        options={bussinessUnitOveallObj[item]?.options}
                        // disabled={bussinessUnitOveallObj[item]?.disabled}
                        // disablePortal
                        // defaultValue={!bussinessUnitOveallObj[item].disabled ? bussinessUnitOveallObj[item].options[0]: ""}
                        sx={{ width: 300 }}
                        onChange={(event, value:any) => {
                            onChange(value)  
                            setValue(item, value)
                            getUserListAPI(item, value == null ? null : value?.value)
                            setCurrentRoleSelected(item)
                        }}
                        renderInput={(params) => <TextField  {...params} InputLabelProps={{ shrink: false }} placeholder={item} label={item} />}
                      />
                    )}
                  />
                </Box>
              }</>
                )
              }) }
              
              <Controller
                control={control}
                name='Mandate'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    ref={autoMandate}
                    disablePortal
                    options={mandateList}
                    onInputChange={(e, val) => onInputChange(e, val)}
                    defaultValue={preloadDataObj?.mandate}
                    sx={{ width: 300 }}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("Mandate",value) 
                      value?.value ? getMandateLocationList(value) : clearLocationField() 
                      // setSearchMandateListFilter(searchMandateList.filter((item: any) => item?.title == value.label.split("-")[0].trim()).value)               
                    }}
                    noOptionsText={searchtextonMandate ? 'No options' : 'Type something...'}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id} >
                          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {option.label?.split('-')[0]}
                            </Typography>
                            <Typography className='dropdownSubtext'>
                              {option.label?.split('-')[1]}-{option.label?.split('-')[2]}
                            </Typography>
                          </Box>
                        </li>
                      )
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Mandate" label={"Mandate"} />}
                  />
                )}
              />
              <Controller
                control={control}
                name='mandateLocation'
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    ref={autoC}
                    disablePortal
                    onInputChange={(e, val) => onInputChange(e, val)}
                    defaultValue={preloadDataObj?.mandateLocation ? preloadDataObj?.mandateLocation : getValues('mandateLocation')}
                    options={mandateLocationLists?.length > 0 ? mandateLocationLists : mandateLocationPreloadLists}
                    sx={{ width: 300 }}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                      setValue("mandateLocation",value)
                    }}
                    renderOption={(props, option) => {
                      return ( <li {...props} key={option.id} >
                        {option.label}
                      </li>)
                      }}
                    renderInput={(params) => <TextField {...params} placeholder="Mandate Location" label={"Mandate Location"} />}
                  />
                )}
              />
              <Controller
                name='submissionDate'
                defaultValue={submissionStartDate}
                // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                control={control}
                render={({ field: { onChange, ...restField } }:any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                      onChange={onChange}
                      inputFormat='YYYY/MM/DD'                      
                      components={{
                        OpenPickerIcon: CalenderIcon,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Submission Start Date" label={"Submission Start Date"} />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
              <Controller
                name='submissionEndDate'
                defaultValue={submissionEndDate}
                // defaultValue={preloadDataObj?.submissionEndDate? preloadDataObj?.submissionEndDate : null}
                control={control}
                disabled={(watch('submissionDate') || submissionEndDate) == null}
                render={({ field: { onChange, ...restField } }:any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={onChange}
                      inputFormat='YYYY/MM/DD'
                      minDate={dayjs(moment(new Date((watch('submissionDate') || submissionEndDate))).format('YYYY/MM/DD'))}
                      components={{
                        OpenPickerIcon: CalenderIcon,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Submission End Date" label={"Submission End Date"} />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
            <Grid className="filter-btn-container" item xs={12} sm={6}>
              <Stack justifyContent="end" gap={1} direction='row'>
                  <Box className='filter-search-btn'>
                    <AppButton
                      label='Search'
                      bordercolor='black'
                      bgcolor='black'
                      size='large'
                      type='submit'
                      className='search-bth'
                      sx={{ color: '#fff' }}
                    />
                  </Box>
                  <Button
                    sx={{
                      color: '#002882',
                      textTransform: 'none',
                    }}
                    variant='text'
                    // onClick={resetAllFilters}
                  >
                    <RotateIcon />
                    <Typography className='reset-label' onClick={() => {resetFilterData()}}>Reset All</Typography>
                  </Button>
                </Stack>
            </Grid>
          </Box>
        </Box>
      </>
    )
  }

    return (
        <>
          <Paper className='box-style' elevation={1}>
            <Box display='flex' justifyContent='space-between' className='filter-parent-box'>
              <Box className='tasklist' >
                <Typography display='flex' gap={1} className='tasklist-title'>
                  Total Candidate Pipeline <Typography color={PRIMARY2}>({reportData.totalRecords})</Typography>
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between'>
                {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                <Box>
             
                  <AppButton
                    variant='contained'
                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                    margin={5}
                    minwidth={40}
                    onClick={handleFilter}
                    className='reportFilterBtn'
                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                  >
                    {' '}
                    <FunnelIcon className='add-new-icon' />{' '}
                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </AppButton>
                  {/* <AppButton minwidth={40} margin={-5}>
                    <SortIcon />
                  </AppButton> */}
                  <AppButton
                    variant='contained'
                    bgcolor={PRIMARY2}
                    margin={5}
                    textColor={TERTIARY12}
                    minwidth={40}
                    className='class-new-button'
                    onClick={(event) => openCoumnFilter(event)}
                  >
                    <MenuIcon />
                  </AppButton>
                  <AppButton
                    disabled ={reportData.totalRecords == 0 ? true : false}
                    variant='contained'
                    bgcolor={PRIMARY3}
                    margin={5}
                    minwidth={40}
                    onClick={getReportExport}
                  >
                    <DownloadIcon />{' '}
                  </AppButton>
                </Box>
              </Box>
            </Box>

    
            {filterChecked && (
              <Box>
                <FilterForms />
              </Box>
            )}
          </Paper>
          <Popover
            open={columnFilter}
            anchorEl={anchorEl}
            onClose={() => setColumnFilter(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className='reportColumnFilters'
            PaperProps={{
              style: { width: `300px` },
            }}
          >
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Search Column Name"
                value={columnFilterSearch}
                onChange={(e) => setColumnFilterSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box component={'form'}>
              <Box className='selectAllCheckbox' display='flex'>
                <Checkbox
                  checked={selectAllColumnsState}
                  onChange={(e) => {
                    updateCheckedColumns(e.target.checked)
                    setSelectAllColumnsState(!selectAllColumnsState)
                    handleSubmit((data) => hitSave(data,"all"))();
                  }}
                  className='search-result-item-checkbox'
                />
                <p>Select All Columns</p>
              </Box>
              <Box className="reportColumnCheckboxes">
                {columnFields.map((columns:any, index:any) => {
                  return (
                    <Controller
                      key={index}
                      name={columns.label}
                      defaultValue={columns.checkStatus}
                      control={control}
                      render={({ field: { value, onChange, ...restField } }:any) => (
                        <Box display={columns.label.toLowerCase().includes(columnFilterSearch) ? "flex" : 'none'}>
                          <Checkbox
                            onChange={(e) => {
                              onChange(e);
                              const columnFieldsCopy = JSON.parse(JSON.stringify(columnFields))
                              columnFieldsCopy.map((item: any) => {
                                if(item?.label ==  columns.label) {
                                  item.checkStatus = e.target.checked
                                }
                              })
                              setColumnFields(columnFieldsCopy)
                              handleSubmit((data) => hitSave(data,""))();
                            }}
                            checked={value}
                            className='search-result-item-checkbox'
                          />
                          <p>{columns.label}</p>
                        </Box>
                      )}
                    />
                  )
                })}
              </Box>
            </Box>
          </Popover>
        </>
    )
}
export const Filter = React.memo(FilterComponent);