import { TextField, Autocomplete, Box, Typography, InputAdornment, IconButton, Tooltip } from '@mui/material'
import './AppMandateDropdown.scss'
import { useEffect, useState } from 'react'
import { MandateService } from '../../../services/MandateService'
import _ from 'lodash'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { ReactComponent as NoResultIcon } from '../../../assets/icons/no-result-found.svg'
import { ReactComponent as GroupHistoryIcon } from '../../../assets/icons/GroupHistory.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as ClientLogo } from '../../../assets/icons/Client-logo-icon.svg'
import DefaultClientLogo from '../../../assets/images/defaultUserIcon.svg'
import { useLocation, useSearchParams } from 'react-router-dom';
import {resetPersistFilter, setPersistFilter} from '../../../features/filters/PersistFilter'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { ReactComponent as ShareMandateIcons } from '../../../assets/icons/shareMandateIcons.svg';

type Mprops = {
  label: string
  required: boolean
  value: any
  onChange: (val:any) => any
  tabVal?:number
  selectedLocation?:any
  setSelectedLocation?:any
  resetAllFilters?:any
}

type TpropOption = {
  title: string
  mandateId: string
  clientName: string
  createdByName: string
}

export const MandateOptionTag = ({ option, isTagRemovable = false, onCloseTagClick, className="", ...rest }: any) => {
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)  
  return (
    <Box className={`mandate-tag-wrapper ${className}`} {...rest}>
      <div className='mandate-container'>
        <div className='m-detail'>
          <img src={DefaultClientLogo} />
          <div className='mtitle-wrap' >
            <Tooltip title={option?.title}>
              <Typography className='m-title'>{option?.title}</Typography>
            </Tooltip>
            <Typography className='m-id'>{option?.mandateId}</Typography>
          </div>
        </div>
        <CloseIcon style={{cursor:"pointer"}} className={`close-btn ${!isTagRemovable && 'hidden-btn'}`} onClick={() => {if(isTagRemovable)onCloseTagClick()}} />
      </div>
      <div className='client-container'>
        <div className='client-wrapper' >
          <GroupHistoryIcon />
          <Tooltip title={option?.clientName}>
            <div className='client-name-text' >{option?.clientName}</div>
          </Tooltip>
        </div>
        <div className='creator-wrapper' >
          <ProfileIcon />
          <Tooltip title={option?.createdByName}>
            <div className='creator-name-text' >{option?.createdByName}</div>
          </Tooltip>
        </div>
      </div>
    </Box>
  )
}

const AppMandateDropdown: React.FC<Mprops> = ({ label, required, value, onChange,tabVal,selectedLocation,setSelectedLocation,resetAllFilters }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [mandateOptions, setMandateOptions] = useState<any[]>([])
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)  
  const [searchtextonMandate, setSearchtextonMandate] = useState<string>('')
  const [choosedMandate, setChoosedMandate] = useState<any>(null)
  const persistFilter = useAppSelector((state)=> state.PersistFilterReducer.persistFilter)
  const getCollaboratorPermission = (mandateData:any) => {
    const selectedCollPermission =  mandateData?.collaborators.filter((item:any) => item.collobatorId == userId)
    return (selectedCollPermission.length > 0) ? JSON.parse(selectedCollPermission[0]?.permissions).includes(24) : true;
}
  const getData = (searchTerm: string) => {
    setSearchtextonMandate(searchTerm)
    MandateService.getSearchMandates(searchTerm).then((res: any) => {
      const dataItems = _.cloneDeep(_.get(res, 'data.result.list', []))
      const filterData = dataItems?.filter((item: any) => (item?.status !== 'draft' && getCollaboratorPermission(item)))
      const dataOption = _.map(filterData, (data: any) => {
        return {
          label: _.get(data, 'title') + ' - ' + String(_.get(data, 'id')) + _.get(data?.mandateId),
          value: _.get(data, 'id'),
          title: _.get(data, 'title'),
          clientName: _.get(data, 'clientName'),
          createdByName: _.get(data, 'createdByName'),
          mandateId: _.get(data, 'mandateId'),
          collaboratorPermission: _.get(data, 'collaborators'),
          mandateBehaviour: _.get(data, 'mandateBehaviour')
        }
      })
      setMandateOptions(dataOption)
    })
  }

  const onInputChange = _.debounce((event: any, value: string) => {
    if (_.trim(value) !== '' && !(_.trim(value).includes("-"))) {
      getData(value)
    } else {
      setSearchtextonMandate('')
      setMandateOptions([])
    }
  }, 500)
  const handleReset = () =>{
    if(resetAllFilters){
      resetAllFilters()
    }
  }
  const onMandateIdUpdate = (value: any) => {
    setChoosedMandate(value ? {...value} : null)
    onChange(value ? {...value} : null)
    }
  useEffect(()=>{
    if(location?.state?.searchFilter?.module==="rpa"){
    getData("")
    }
    if(value != null){
      if(Object.keys(value)?.length > 0){
        onMandateIdUpdate(value)
      }
    }    
  },[])
  useEffect(()=>{
  if(mandateOptions?.length > 0 && value){
    setChoosedMandate(value)
  }
  },[mandateOptions])
  return (
    <>
      <Autocomplete
        options={mandateOptions}
        onInputChange={(e, val) => onInputChange(e, val)}
        sx={{
          marginBottom: { xs: '0rem', lg: '0rem' },
        }}
        className='mandate-common-dropdown'
        disablePortal
        onChange={(event, value) => {
          if(!value){
            handleReset(); 
            setChoosedMandate(null);
            onChange(null);
          }
          onMandateIdUpdate(value)
        }}
        value={choosedMandate || null}
        noOptionsText={searchtextonMandate ? 'No options' : 'Type something...'}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value} style={{ maxHeight: '200px' }}>
              <MandateOptionTag option={option} isTagRemovable={false} />
            </li>
          )
        }}
        renderInput={(params: any) => {
          return(
          <TextField
            {...params}
            label={label}
            placeholder='Select Mandate'
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            inputProps={{
              ...params.inputProps,
              value:params?.inputProps?.value?.split("-")?.[0]
            }}
          />
        )}}
      />
        <Box className='selected-container'>
          <Typography className='primary-text'>Selected Mandate</Typography>
          {choosedMandate ? 
          <MandateOptionTag 
            option={{
              title: choosedMandate?.title || "No mandate selected",
              mandateId: choosedMandate?.mandateId || "-",
              clientName: choosedMandate?.clientName || "-",
              createdByName: choosedMandate?.createdByName || "-"
            }} 
            isTagRemovable={choosedMandate ? true : false}
            onCloseTagClick={() => {handleReset();
              setChoosedMandate(null);
              onChange(null);}}
          /> :
          <div className='noMandateFound'>
            <NoResultIcon />
            No Mandate Selected
          </div>
          }
        </Box>
    </>
  )
}

export default AppMandateDropdown