import { useState, useEffect } from 'react'
import './DashboardEvents.scss'
import { ReactComponent as CalanderIcon } from '../../../assets/icons/mandates-list-calendar.svg'
import { ReactComponent as Profile } from '../../../assets/icons/profile.svg'
import { getScheduleEvents } from '../../../services/SchedulerServive'
import moment from 'moment'
import { useAppSelector } from '../../../store/hooks'
import {useNavigate} from "react-router-dom"
import remainderNoData from "../../../assets/images/Remainder_No_Data.svg"

const DashboardEvents:React.FC = () => {
    const navigate = useNavigate()
    const [remainderLists, setRemainderLists] = useState<any[]>([])
    const userName = useAppSelector((state) => state?.UserDetail?.firstName)

    const getEventList = async () => {
        const res = await getScheduleEvents();
        if(res?.status == 200 && res?.data?.length>0){
            const filtered = res.data.filter((x:any)=>{
                return (moment(x.startTime).isSame(moment(), 'week') && (new Date(x.endTime) > new Date()))
            }).sort((a:any,b:any)=>  new Date(a.startTime) > new Date(b.startTime) ? 1 : -1)
            const timerData = filtered?.map((remainder:any)=> {
                const parsedMoment = moment(remainder?.startTime, "YYYY-MM-DD HH:mm:ss");
                const timeString = parsedMoment.format("HH:mm");

                const startMoment = moment(remainder?.startTime, "YYYY-MM-DD HH:mm:ss.SSSSSS Z");
                const endMoment = moment(remainder?.endTime, "YYYY-MM-DD HH:mm:ss.SSSSSS Z");
                const duration = moment.duration(endMoment.diff(startMoment));
                const hours = duration.hours();
                const minutes = duration.minutes();
                const timeStringFinal = hours ? minutes ? `${hours}h ${minutes}min` : `${hours}hour${hours>1 && "s"}` : `${minutes}mins`
                const durationString = timeStringFinal
                const visibility = (new Date() > new Date(remainder.startTime) && new Date() < new Date(remainder.endTime))

                return{
                    timeString,
                    durationString,
                    createdDate: moment(remainder?.startTime).format('DD MMM YYYY'),
                    taskName: remainder?.title,
                    userName: remainder?.userName,
                    visibility
                }
            })
            
            setRemainderLists(timerData)
        }
    }

    useEffect(()=>{
        getEventList()
    },[])

    return (
        <div className='remainders-wrapper' >
            <div className='header-text' >Reminder</div>
            <div className="container">
                <div className="container-text">THIS WEEK</div>
                <div className="remainders-list">
                    {remainderLists?.map((list:any, index:number)=>(
                        <div key={index} className="remainder" onClick={()=>navigate('/scheduler')}>
                            <div className='timer-container'>
                                <div className="topline"></div>
                                <div>{list?.timeString}</div>
                                <div>{list?.durationString}</div>
                            </div>
                            <div className='event-details'>
                                <div className="event-name">
                                    Candidate Interview : {list?.taskName}
                                </div>
                                <div className="event-assign">
                                    <div className="secondory-text">
                                        <CalanderIcon/>
                                        <span>{list?.createdDate}</span>
                                    </div>
                                    <div className="secondory-text">
                                        <Profile/>
                                        <span>{list?.userName ? list?.userName : userName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={list?.visibility ? "now-blk" : "hidden-btn"} >
                                <span>Now</span>
                            </div>
                        </div>
                    ))}
                    {remainderLists?.length === 0 && <div className="remainderNoData"><img src={remainderNoData}/>No Upcoming Interviews</div> }
                </div>
            </div>
        </div>
    )
}

export default DashboardEvents;