import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/backarrowicon1.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClientProfile from '../../assets/icons/clientDetails.png'
import "./RoleAccess.scss"
import RoleInformation from './RoleInformation';
import { FormProvider, useForm } from 'react-hook-form';
import { AppErrorHandlerModal } from '../AppComponents/AppErrorHandlerModal';
import Access from "../../assets/images/Access.svg"
import RoleDetails from "../../assets/images/Role_Details.svg"
import { toast } from "react-toastify";
import { postCreateRole, postUpdateRole, postAvailableAccessList, postSelectedAccessList, getUserDetails } from '../../services/UserService';
import ListTreeAntTranfer from '../Common/ListTreetransfer/ListTreeAntTranfer';
import { authUserDetails, updatePermission } from '../../features/userDetail/UserDetailReducer';
import { useAppDispatch } from '../../store/hooks';

interface IFormInput {
    roleName:string;
    roleDescription: string;
}
interface TreeNode {
    key: string;
    title: string;
    children?: TreeNode[];
    value: number;
    type: string
}
const CreateRoleAccess = () => {
    const location = useLocation();    
    const [roleDetailData, setRoleDetailData] = useState(location?.state?.roleDetail)
    const isEdit:any = location?.state?.status
    const [treeViewDataObj, setTreeViewDataObj] = useState([])
    const [treeViewDataParentPath, setTreeViewDataParentPath] = useState<any>([])
    const [treeViewSelectedDataObj, setTreeViewSelectedDataObj] = useState([])
    const [userModifiedTreeView, setUserModifiedTreeView] = useState([])
    const dispatch = useAppDispatch()
    const initValues = {
        roleName: isEdit ? roleDetailData?.roleName : "",
        roleDescription: isEdit ? roleDetailData?.description : ""
      }
      const scrollToError = () => {
        setTimeout(() => {
          const container = document.querySelector(`.reminder_text-danger`);
          container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },100)      
      }
      useEffect(() => {
        console.log(userModifiedTreeView)
      },[userModifiedTreeView])
      useEffect(() => {
        console.log("treeViewDataParentPath",treeViewDataParentPath)
      },[treeViewDataParentPath])      
    const navigate =useNavigate()
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        navigate('/roleAccess')
      }
    const {
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      clearErrors 
    } = methods

    const findNodePath = (data: TreeNode[], targetKey: string, path: TreeNode[] = []): TreeNode[] | null => {
        for (const node of data) {
            const newPath = path.concat({
                key: node.key,
                title: node.title,
                children: node.children ? [] : undefined,
                value: node.value,
                type: node.type
            });
    
            if (node.key === targetKey) {
                return newPath;
            }
    
            if (node.children) {
                const result = findNodePath(node.children, targetKey, newPath);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };
    
    const getFullPath = (data: TreeNode[], targetKey: string): TreeNode | null => {
        const path = findNodePath(data, targetKey);
        if (!path) {
            return null;
        }
    
        let result: TreeNode | null = null;
        let currentLevel: TreeNode | null = null;
        for (let i = path.length - 1; i >= 0; i--) {
            const node = path[i];
            if (currentLevel === null) {
                currentLevel = node;
            } else {
                node.children = [currentLevel];
                currentLevel = node;
            }
            result = currentLevel;
        }
        return result;
    };

    // const getPayloadData = ((treeViewDataObj:any) => {
    //     // const selectedKeys = [];
    //     function collectSelectedKeys(nodes:any) {
    //           for (const node of nodes) {
    //               if("children" in node == false){     
    //                 setTreeViewDataParentPath((oldArray: any) => [...oldArray, getFullPath(treeViewDataObj, node.key)])                    
    //               }
    //               if (node.children) {
    //                   collectSelectedKeys(node.children);
    //               }
    //           }
    //       }
    //       collectSelectedKeys(treeViewDataObj);
    //     //   return selectedKeys
    //   })


    const getPayloadData = (treeViewDataObj:any) => {
        const selectedKeys:any = [];
    
        function collectSelectedKeys(nodes:any) {
            for (const node of nodes) {
                if ("children" in node == false || (node.children && node.children.length == 0)) {
                    selectedKeys.push(getFullPath(treeViewDataObj, node.key));
                } else {
                    collectSelectedKeys(node.children);
                }
            }
        }    
        collectSelectedKeys(treeViewDataObj);
        return selectedKeys;
    };

    const getPayloadDataObj = (treeViewDataObj:any) => {
        const selectedKeys:any = {
            "roleId": isEdit ? roleDetailData?.id : null,
            "moduleId": 1,
            "pageId": 1,
            // "dlsId": 1,
            "policyId": 1
        };

        function processNode(node:any) {
            // Example action: Log the properties of the node
            console.log(`Key: ${node.key}`);
            console.log(`Title: ${node.title}`);
            console.log(`Value: ${node.value}`);
            console.log(`Type: ${node.type}`);
            console.log('-------------------');
        
            // If the node has children, process each child recursively
            if (node.children && node.children.length > 0) {
                const nodeType = node.type == "MODULE" ? "moduleId" : node.type == "PAGE" ? "pageId"  : ""
                selectedKeys[nodeType] = node.value
                node.children.forEach((child: any) => processNode(child));
            }else{
                selectedKeys["policyId"] = node.value
            }
        }    
        processNode(treeViewDataObj);
        return selectedKeys;
    };

    const getAccessAvailabilityList = (async () => {
        const response = await postAvailableAccessList({"roleId": null})
        setTreeViewDataObj(response?.data?.result?.data)
    })
    const getAccessSelectedList = (async () => {
        const response = await postSelectedAccessList({"roleId": isEdit ? roleDetailData?.id :null})
        setTreeViewSelectedDataObj(response?.data?.result?.data)
    })      

      useEffect(() => {
        getAccessAvailabilityList()
        if(isEdit)getAccessSelectedList()
      },[])

      const getUserRole = async () => {
        const response = await getUserDetails()
        dispatch(updatePermission(response?.permissions))
    }


    const roleSubmitHandler = async (data:any, event:any) => {
        const removedList:any = [];
        const addedList:any = [];
        const payloadArray = getPayloadData(userModifiedTreeView)
        const payloadOrginalArray = getPayloadData(treeViewSelectedDataObj)
        const optionsArrayList = payloadArray.map((data: any) => {
            const getValueOfObj = getPayloadDataObj(data)
            return getValueOfObj
          })
        const optionsOrginalArrayList = payloadOrginalArray.map((data: any) => {
        const getValueOfObj = getPayloadDataObj(data)
        return getValueOfObj
        })
        optionsOrginalArrayList.map((orginalArray:any) => {
            const filterData = optionsArrayList.filter((currentArray:any) => orginalArray.moduleId == currentArray.moduleId && orginalArray.pageId == currentArray.pageId && orginalArray.dlsId == currentArray.dlsId && orginalArray.policyId == currentArray.policyId)
            if(filterData.length == 0) removedList.push(orginalArray)
        })
        optionsArrayList.map((orginalArray:any) => {
            const filterData = optionsOrginalArrayList.filter((currentArray:any) => orginalArray.moduleId == currentArray.moduleId && orginalArray.pageId == currentArray.pageId && orginalArray.dlsId == currentArray.dlsId && orginalArray.policyId == currentArray.policyId)
            if(filterData.length == 0) addedList.push(orginalArray)
        })
        const payLoadObj:any = {
            "roleName": data?.roleName,
            "description": data?.roleDescription            
        }
        if(isEdit) {
            payLoadObj["roleId"] = roleDetailData.id;
            if(addedList.length > 0) payLoadObj["addList"] = addedList;
            if(removedList.length > 0) payLoadObj["removeList"] = removedList;
        }else{
            payLoadObj["addList"] = optionsArrayList
        }
        try{
            const response = isEdit ? await postUpdateRole(payLoadObj) : await postCreateRole(payLoadObj)
            if (response?.status == 200 || response?.status == 201) {              
                setSuccessMessage(isEdit ? 'Role has been successfully Updated' : 'Role has been successfully created')
                setUploadsuccess(true)
                getUserRole()
            }
        }
        catch(error:any){
            if (error?.response?.status == 500 || error?.response?.status == 409) {
                toast.error(error?.response?.data?.message)
            }
        }
    }


    return (
        <>
            <Box className='mandate-wrapper'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  navigate(`/roleAccess`)}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>
                    <div className='header-text'>
                        {isEdit ? "Edit" : "New"} Role
                    </div>
                </div>
                <Box className="createRoleAccordionSect" style={{height: "100vh",overflow: "auto"}}>
                <FormProvider {...methods}>
                <Box
                        component="form"
                        data-testid="formHandle"
                        id="roleForm"
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(roleSubmitHandler)}
                    >
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={RoleDetails} alt="otherInfo" />
                            <div>Role Details</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            <RoleInformation isEdit={isEdit}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className='accordian-header'
                        >
                            <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                            <img src={Access} alt="otherInfo" />
                            <div>Access</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='accordian-details'>
                            {treeViewDataObj.length > 0 && <ListTreeAntTranfer treeViewDataObj={treeViewDataObj} treeViewSelectedDataObj={treeViewSelectedDataObj} setUserModifiedTreeView={setUserModifiedTreeView} isEdit={isEdit}/>}
                        </AccordionDetails>
                    </Accordion>                    
                    <Box className='button-container'>
                        <Button 
                            className='cancel-btn'
                            onClick={()=> window.history.back()}
                        >Cancel</Button>
                        <Button 
                            className='save-btn'
                            type='submit'
                            // disabled={isSubmitting || endDateError}
                            onClick={() => {scrollToError()}}
                        >
                            {isEdit ? "Update" : "Save"}
                        </Button>
                        </Box>
                    </Box>
                </FormProvider>
                <AppErrorHandlerModal
                open={uploadsuccess}
                content={SuccessMessage}
                handleClose={handleClose}
                factor='Success'
                ButtonText='Done'
                ><></></AppErrorHandlerModal>
                </Box>
            </Box>
        </>
    );
};

export default CreateRoleAccess;