import React from 'react'
import Select, { SelectProps } from '@mui/material/Select'
import styled from '@emotion/styled'
import { PRIMARY1 } from '../../../utils/constants'
import { ReactComponent as ArrowIcon } from '../../../../src/assets/icons/dropdown.svg'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'

const CustomAppselect = styled(Select)<CustomSelectProps>(() => ({
  height: 'auto',
  ['& .MuiSelect-icon']: {
    marginTop: '3px',
  },
  ['& .css-10q54uo-MuiSelect-icon']: {
    marginTop: '0px',
  },
  '& fieldset': {
    borderRadius: '10px',
    border: '1px solid rgba(207, 209, 210, 1)',
  },
  '&.MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '1px solid rgba(207, 209, 210, 1)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(207, 209, 210, 1)',
    },
  },
  ['& .filter-funnel']: {
    fill: PRIMARY1,
  },
  ['& .filter-arrow']: {
    width: '40px',
  },

  '& .MuiInputBase-inputAdornedStart': {
    marginLeft: '7px',
  },
  '&.Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
  '&.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined': {
    color: '#5e5b5d',
  },
  '&.MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
    color: '#5e5b5d',
  },
  '.Mui-focused': {
    color: '#5e5b5d',
  },
}))

const Myplaceholder = styled('span')({
  color: '#b8b8b8',
  marginLeft: '10px',
})

type RenderplaceholderProps = {
  placeholder?: string
}

export const Renderplaceholder: React.FC<RenderplaceholderProps> = (props): React.ReactElement => {
  const { placeholder } = props
  return <Myplaceholder>{placeholder}</Myplaceholder>
}

type CustomSelectProps = {
  id: string
  name: string
  label?: string
} & SelectProps

export const AppSelect: React.FC<CustomSelectProps> = (props): React.ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const { name } = props
  return (
    <>
      <CustomAppselect {...props} />
      <Box className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-174in1s-MuiFormHelperText-root'>
        {errors[name] ? (errors[name]?.message as unknown as string) : ''}
      </Box>
    </>
  )
}
