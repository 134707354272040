import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { ReactComponent as RevenueIcon } from '../../../assets/icons/Revenue_chart.svg'

const CommonFormField = ({list, control, error, fieldType, watch}:any) => {
  const {
    setValue
  } = useForm<any>()
  const monthlyBill = watch("txt_mbr");
  return (
    <>
      {list?.field_type === "select" && (
        <FormControl fullWidth required={list?.validation==="mandatory"} >
          <InputLabel>{list?.field_label}</InputLabel>
          <Controller
            name={`${list?.field_id}`}
            control={control}
            rules={{
              required: {
                  value: (list?.validation==="mandatory"),
                  message: `${list?.field_label} is required`,
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <Select
                label={list?.field_label}
                value={value ? value : ""}
                onChange={onChange}
              >
                {list?.options.map((dropData:any, index:number) => (
                  <MenuItem key={index} value={dropData} >{dropData}</MenuItem>
                ))}
              </Select>
            )}
          />
          {error[list?.field_id] && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {error[list?.field_id]?.message as string}
            </span>
          )}
        </FormControl>
      )}
      {list?.field_type === "text" && (
        <FormControl fullWidth required={list?.validation==="mandatory"} >
          <Controller
            name={`${list?.field_id}`}
            control={control}
            rules={{
              required: {
                value: (list?.validation==="mandatory"),
                message: `${list?.field_label} is required`,
              },
              validate: {
                required: (value) => {
                  if ((list?.field_id == "txt_prtc") && (parseInt(value) > parseInt(monthlyBill))) return 'Pay Rate to Candidate should not be greater than the Monthly bill rate';
                }
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label={`${list?.field_label}`}
                required
                value={value ? value : ""}
                onChange={(e:any) => {
                  if(fieldType === 'number'){
                    if(e?.target?.value.length == 1 && e?.target?.value == 0){
                      setValue(`${list?.field_id}`,"")
                    }else{
                      const onlyNums = e.target.value.replace(/[^0-9.]/g, '');
                      onChange(onlyNums)
                    }  
                    
                  }else{
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                inputProps={{ maxLength: Number(list?.maxlength) || 20 }}
              />
            )}
          />
          {error[list?.field_id] && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {error[list?.field_id]?.message as string}
            </span>
          )}
        </FormControl>
      )}
      {list?.field_type === "span" && list?.field_label.includes("Potential") && (
        <div className='potential-wrapper' >
          <div>
            <RevenueIcon/>
          </div>
          <div>
            <Controller
              name={`${list?.field_id}`}
              control={control}
              render={({field : {onChange, value, onBlur}})=>(
                <div>
                  <div className='header-span' >{list?.field_label}</div>
                  <div className='text-span'>{String(value) !== "NaN" ? value : "0" }</div>
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default CommonFormField