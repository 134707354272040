import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './TaskDetail.css'
import { Box } from '@mui/system'
import { Drawer, Typography, Divider, IconButton, Select, MenuItem } from '@mui/material'
import { ReactComponent as BackIcon } from '../../../assets/icons/backarrowicon.svg'
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expandicon.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.icon.svg'
import { ReactComponent as Minimize } from '../../../assets/icons/minimize-icon.svg'
import RadioButtonCheck from "../../../assets/icons/RadioButtonCheck.svg"
import RadioButton from "../../../assets/icons/RadioButton.svg"
import RadioButtonGray from "../../../assets/icons/RadioButtonGray.svg"
import { Taskdetail } from '../../../pages/MyTasks'
import { convertToDisplayDateTime } from '../../../utils/date-utils'
import _ from "lodash";
import StatusComponent from '../../StatusComponent'
import CommentsAndTags from '../CommentsAndTags'
import { TaskManagementService } from '../../../services/TaskService'
import { leftdrawertoggle, leftdrawertoggleclose } from '../../../features/toggle/ToggleReducer'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { taskdrawerclose, taskexpanddrawer, taskminimizedrawer } from '../../../features/taskDetail/DrawerReducer'
import { getLoggedInUserId } from '../../../utils/user-utils'

export interface DrawerProps {
  data: any
}

export const TaskDrawerData: React.FC<DrawerProps> = ({
  data,
}) => {
  const loggedinUser=useMemo(() => getLoggedInUserId(), [])
  const [taskHistory, setTaskHistory] = useState<any>()
  const [selectedAssignee, setSelectedAssignee] = useState<any>()
  const [status, setStatus] = useState<any>([])
  const [firstRender, setFirstRender] = useState<boolean>(true)
  const assignees = _.get(data, 'assignees', [])
  // const isNeedFilter =data?.isHorizontal ? rawAssignees?.find((x:any)=>x?.id === loggedinUser) ?  (data?.createdById=== loggedinUser) ? false :true :false :false
  // const assignees = isNeedFilter ? rawAssignees.filter((x:any)=>x.id === loggedinUser) : rawAssignees
  const assigneeNames = _.map(assignees, 'name');
  const statusColor: any = { "open": "#835AA8", "inprogress": "#0E94F4", "completed": "#B3B5B7" }
  const statusDropdown: any = [
    {
      data: "open",
      value: 6,
      color: "#835AA8"
    },
    {
      data: "inprogress",
      value: 7,
      color: "#0E94F4"
    },
    {
      data: "completed",
      value: 9,
      color: "#B3B5B7"
    }
  ]

  const taskStatusValues:any={
    'inprogress':'In progress',
    'open':'Open',
    'completed':'Completed'
  }
  
  const [selectedStatusValue, setSelectedStatusValue] = useState<number | null>(statusDropdown?.filter((status:any)=>status?.data === data?.statusname || data?.status)?.[0]?.value)
  const dispatch = useAppDispatch()
  const hideExpandIcon = useAppSelector((state) => state.taskdrawer.hideExpandicon)

  const getTaskHistory = async () => {
    const response = await TaskManagementService.GetTaskHistory(data?.taskId)
    if (response?.status === 200) {
      setTaskHistory(response?.data)
    }
    else {
      console.log("error")
    }
  }

  const UpdatetAssigneeTask = async (e:any) => {

    setSelectedStatusValue(e.target.value);
    const payload = {
      'status': e.target.value,
      'taskId': data?.taskId,
      'taskAssignedTo': loggedinUser
    }

    const response = await TaskManagementService.UpdatetAssignee(payload)
    if (response?.status === 200) {
      setTaskHistory(response?.data)
      getTaskHistory()
    }
    else {
      console.log("error")
    }
  }
  const  sortFunction=(a:any, b:any)=> {
    const dateA = new Date(a.createdAt).getTime()
    const dateB = new Date(b.createdAt).getTime()
    return dateA > dateB ? 1 : -1
  }

  const isValidUser = useCallback((array: any) => {
    return array?.some((element: any) => element?.id === loggedinUser)
  }, [])

  useEffect(() => {
    setStatus(taskHistory?.filter((data: any) => data?.assigneeId == selectedAssignee?.assignee_id)?.[0]?.statusData.sort(sortFunction))
  }, [selectedAssignee, taskHistory])

  useEffect(() => {
    if(selectedAssignee?.id===loggedinUser) {
      setSelectedStatusValue(statusDropdown?.filter((eachStatus:any)=>eachStatus?.data === status?.[status.length-1]?.status)[0]?.value)
    }
  }, [status])
  

  useEffect(() => {
    if (data?.taskId) {
      setSelectedAssignee(assignees[0])
      getTaskHistory()
    }
    setSelectedStatusValue(statusDropdown?.filter((status:any)=>status?.data === data?.statusname || data?.status)?.[0]?.value)
  }, [data])

  
  const getDesiredStatusOfUser = () => {
    return statusDropdown?.filter((eachStatus: any) => {
      const filteredSort = taskHistory?.filter(
          (ele: any) =>
            ele.assigneeId === data.assignees?.find((ele: any) => ele.id === loggedinUser)?.assignee_id
        )?.[0]?.statusData?.sort(sortFunction)
      return filteredSort?.[filteredSort?.length-1]?.status === eachStatus?.data
    })?.[0]?.value
  }

  const handleExpand = () => {
    // dispatch(lefttoggle())
    dispatch(leftdrawertoggle())
    dispatch(taskexpanddrawer())
  }

  const handledrawerClose = () => {
    dispatch(taskdrawerclose())
    dispatch(leftdrawertoggleclose())
  }

  const handleminimize = () => {
    dispatch(taskminimizedrawer())
    dispatch(leftdrawertoggleclose())
    // setFullwidth(false)
  }

  useEffect(() => {
    if(taskHistory&&loggedinUser&&firstRender){
      setSelectedStatusValue(getDesiredStatusOfUser())
      setFirstRender(false)
    }
  }, [taskHistory,loggedinUser])

  
  return (
    <Box sx={{ bgcolor: 'background.paper', height: "100vh", overflow: "auto" }}>
      <Box display={'flex'} justifyContent='flex-start'>
        <IconButton aria-label='back' disableRipple disableFocusRipple onClick={handledrawerClose}>
          <BackIcon />
        </IconButton>
        <IconButton aria-label='goto-details' disableRipple disableFocusRipple>
          {!hideExpandIcon && (
            <ExpandIcon
              onClick={() => {
                handleExpand()
              }}
            />
          )}
        </IconButton>
        <IconButton aria-label='minimize-detail' disableRipple disableFocusRipple>
          <Minimize
            onClick={() => {
              handleminimize()
            }}
          />
        </IconButton>
      </Box>
      <Box className='task-detail-box'>
        <Box className='task-status-split'>
          <Box className='task-left-title'>
            <div className='title-of-task'>
              <Typography variant='h6' className='task-detail-title'>
                {data.taskName}
              </Typography>
              <Typography variant='h6' className='task-detail-date'>
                {convertToDisplayDateTime(data.createdAt)}
              </Typography>
            </div>
            <Box display='flex' className='task-assign' alignItems={'center'}>
              <UserIcon className='task-user-icon' />
              <Typography className='task-assigner' variant='subtitle1'>
                {data.createdByName}
              </Typography>
            </Box>
          </Box>
          {isValidUser(data?.assignees) && (
            <Box className='task-status-wrap'>
              <Typography className='task-assigner' variant='subtitle1'>
                Status
              </Typography>
              <div className='dropdown-status'>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedStatusValue ?? ''}
                  label='Age'
                  onChange={(e: any) => UpdatetAssigneeTask(e)}
                >
                  {statusDropdown.map((ele: any, index: number) => (
                    <MenuItem key={index} value={ele.value}>
                      <StatusComponent status={taskStatusValues[ele.data]} color={ele.color} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Box>
          )}
        </Box>
        <Divider variant='middle' className='task-divider' />
        <Box className='task-des-box task-des-name'>
          <Typography variant='body1' className='task-assigner'>
            Task Name
          </Typography>
          <Typography variant='body1' className='task-description '>
            {data.taskName}
          </Typography>
        </Box>
        <Box className='task-des-box '>
          <Typography variant='body1' className='task-assigner'>
            Description
          </Typography>
          <Typography variant='body1' className='task-description'>
            {data.description}
          </Typography>
        </Box>
        <Box className='task-des-box'>
          <Typography variant='body1' className='task-assigner'>
            Assigned to
          </Typography>
          <Typography variant='body1' className='task-employee-name'>
            {_.join(assigneeNames, ', ')}
          </Typography>
          {/* <Box display='flex' justifyContent='flex-start' className='task-detail-profile'>
                <Box
                  className='user-task-profile'
                  component='img'
                  alt='Profile Pic'
                  src={DefaultAvatar}
                  // src='https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2'
                />
                <Box className='task-footer-content'>
                  <Typography variant='body1' className='task-employee-name'>
                    userName
                  </Typography>
                  <Typography variant='body1' className='task-employee-role'>
                    Recruiter
                  </Typography>
                </Box>
              </Box>  */}
        </Box>
        <Box className='task-des-box '>
          <Typography variant='body1' className='task-assigner'>
            Assignee Task History
          </Typography>
          {assignees?.length > 0 && (
            <div className='assignee-list'>
              {assignees?.map(
                (assignee: { name: string; id: string; email: string }, index: number) => {
                  return (
                    <p
                      className='assignee-name'
                      key={index}
                      style={{
                        background: `${assignee?.id === selectedAssignee?.id ? '#FFFFFF' : ''}`,
                        border: `${
                          assignee?.id === selectedAssignee?.id ? '1px solid #FF6E00' : ''
                        }`,
                      }}
                      onClick={() => {
                        setSelectedAssignee(assignee)
                      }}
                    >
                      {assignee?.name}
                    </p>
                  )
                },
              )}
            </div>
          )}
        </Box>
        <Box className='task-des-box-status '>
          {status?.map((eachStatus: any, index: number) => {
            return (
              <div className='status-wrap' key={index}>
                <div className={`svgcontainer ${index !== status.length - 1 ? 'active' : ''}`}>
                  {<img src={index === status.length - 1 ? RadioButton : RadioButtonCheck} />}
                </div>
                <div>
                  <StatusComponent
                    status={taskStatusValues[eachStatus?.status]}
                    color={statusColor?.[eachStatus?.status]}
                  />
                  {/* {status.status} */}
                  <p className='date-text'>{convertToDisplayDateTime(eachStatus?.createdAt)}</p>
                </div>
              </div>
            )
          })}
        </Box>
        <Box className='task-des-box '>
          <CommentsAndTags data={data} />
        </Box>
      </Box>
    </Box>
  )
}
