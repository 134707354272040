import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Box, Tooltip } from '@mui/material';
import "../../RoleAccess/ViewTree.css"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { TreeDataNode, Input, Tree } from 'antd';
import { useEffect } from 'react';

const { Search } = Input;

function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly any[], b: readonly any[]) {
    const filterList = a.filter((value) => b.indexOf(value) !== -1)
    return filterList;
}

export default function ListTransfer({userRoleList,userRoleSelectedList,setChangesUserSelectedRole,changesUserRoleReset,setChangesUserRoleReset}:any) {
    const [checked, setChecked] = React.useState<readonly number[]>([]);
    const [left, setLeft] = React.useState<readonly any[]>(userRoleList);
    const [right, setRight] = React.useState<readonly any[]>(userRoleSelectedList);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: any) => () => {
        const currentIndex:any = checked.filter((item:any) => value.id == item.id);
        let newChecked = [...checked];

        if (currentIndex.length == 0) {
            newChecked.push(value);
        } else {
            newChecked = newChecked.filter((objItem:any) => currentIndex[0].id != objItem.id)
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    useEffect(() => {
        setChangesUserSelectedRole(right)
        setChangesUserRoleReset(false)
    },[right])

    useEffect(() => {
        if(changesUserRoleReset){
            setLeft(userRoleList)
            setRight(userRoleSelectedList)
        }
    },[changesUserRoleReset])

    const customList = (items: readonly any[]) => (
        <Paper sx={{ height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((listItem: any) => {
                    // const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItemButton
                            className={`${checked.filter((itemObj:any) => itemObj.id == listItem.id).length > 0 ? "checked" : ""} ${listItem.displayStatus ? "hideTransferList" : ""}`}
                            key={listItem.id}
                            role="listitem"
                            onClick={handleToggle(listItem)}
                        >
                            <ListItemText id={listItem.id} primary={listItem.label} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const searchFilterObj = left.map((searchItem:any) => {
            const itemObj = searchItem
            if(searchItem.label.indexOf(value) > -1 && value.length > 0){
                itemObj["displayStatus"] = false
            }else{
                if(value.length == 0){
                    itemObj["displayStatus"] = false
                }else{
                    itemObj["displayStatus"] = true
                }                
            }
            return itemObj
        })
        setLeft(searchFilterObj)
    }
    const onRightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const searchFilterObj = right.map((searchItem:any) => {
            const itemObj = searchItem
            if(searchItem.label.indexOf(value) > -1 && value.length > 0){
                itemObj["displayStatus"] = false
            }else{
                if(value.length == 0){
                    itemObj["displayStatus"] = false
                }else{
                    itemObj["displayStatus"] = true
                }                
            }
            return itemObj
        })
        setRight(searchFilterObj)
    }    

    return (
        <>
            <Box className="roleTabListTransferSect">
                <div className='roleTreeViewSectScroll' style={{width:"100%"}}>
                    <div className='roleTreeViewSect' style={{ float: "left", width: "100%" }}>
                        <Box className="roleTreeViewSectBlk">
                            <h5>Role List</h5>
                            <Search placeholder="Search Keyword" onChange={onChange} />
                            <Grid className='leftGrid' item>{customList(left)}</Grid>
                        </Box>
                    </div>
                </div>
                <Box className="listTreeAction">
                    <Tooltip title={"Move Left"} placement="right"><span><KeyboardArrowLeftIcon onClick={handleCheckedLeft}/></span></Tooltip>
                    <Tooltip title={"Move All Left"} placement="right"><span><KeyboardDoubleArrowLeftIcon onClick={handleAllLeft}/></span></Tooltip>
                    <Tooltip title={"Move Right"} placement="right"><span><KeyboardArrowRightIcon onClick={handleCheckedRight}/></span></Tooltip>
                    <Tooltip title={"Move All Right"} placement="right"><span><KeyboardDoubleArrowRightIcon onClick={handleAllRight}/></span></Tooltip>
                </Box>
                <div className='roleTreeViewSectScroll' style={{width:"100%"}}>
                    <div className='roleTreeViewSect' style={{ float: "left", width: "100%" }}>
                        <Box className="roleTreeViewSectBlk">
                            <h5>Assigned Role</h5>
                            <Search placeholder="Search Keyword" onChange={onRightChange} />
                            <Grid className='rightGrid' style={{width:"100%"}} item>{customList(right)}</Grid>
                        </Box>
                    </div>
                </div>
            </Box>
        </>
    );
}