import './styles.scss';
import ProfileDetailsCategory from '../ProfileDetailsCategory';
import BasicDetails from './BasicDetails';
import CallLogs from './CallLogs';
import LinkedInProfile from './LinkedInProfile';
import Skills from './Skills';
import WorkExperience from './WorkExperience';
import React ,{ useState } from 'react';
import CandidateTracking from './CandidateTracking';

type Tprop = {
    candidateDetailsData:any;
    selectedComponent: any;
    setSelectedComponent: any;
    candidateNotificationState:any
}

const CandidatePersonalDetails:React.FC<Tprop> = ({candidateDetailsData, selectedComponent, setSelectedComponent,candidateNotificationState}) => {

    // const [selectedComponent, setSelectedComponent] = useState<string>('basicDetails')

    return (
        <div className='insight-wraper'>
            <div>
                <ProfileDetailsCategory setSelectedTitle={setSelectedComponent} selectedTitle={selectedComponent} />
            </div>
            <div>
                {selectedComponent === 'basicDetails' && <BasicDetails candidateDetailsData={candidateDetailsData}/>}
                {selectedComponent === 'callLogs' && <CallLogs candidateDetailsData={candidateDetailsData}/>}
                {selectedComponent === 'linkedInProfile' && <LinkedInProfile candidateDetailsData={candidateDetailsData}/>}
                {selectedComponent === 'skills' && <Skills candidateDetailsData={candidateDetailsData} />}
                {selectedComponent === 'workExperience' && <WorkExperience candidateDetailsData={candidateDetailsData} />}
                {selectedComponent === 'candidateTracking' &&  <CandidateTracking candidateDetailsData={candidateDetailsData} candidateNotificationState={candidateNotificationState} />}
            </div>
        </div>
    )
}

export default CandidatePersonalDetails;
