import { forminstance, instance, reportInstance } from "./http-common";


export const getHiringDemand = async () => {
    const response = await reportInstance.post(`/dashboard/hiringDemand`);
    return response;
}

export const getstageWiseConversation = async (listData:any) => {
    const response = await reportInstance.post(`/dashboard/stageWiseConversation`,listData);
    return response;
}

export const getofferMgtEffectiveness = async () => {
    const response = await reportInstance.post(`/dashboard/offerMgtEffectiveness`);
    return response;
}


export const dashoardClient = async () => {
    const response = await instance.get(`/master/clients`);
    return response;
}

export const MandateSPOCapi = async (reqobj:any) => {
    const response = await instance.post(`/mandate/getMandateSpocById`,reqobj);
    return response;
}


export const getJobTitleapi = async (queryString:any,reqObj:any) => {
    console.log("getJobTitleapi",reqObj)
    const response = await instance.post(`mandate/getJobTitleDropDown?${queryString}`,reqObj);
    return response;
}


export const getJoLocationsapicall = async (reqObj:any) => {
    const response = await instance.post(`mandate/getMandateJoLocations`,reqObj);
    return response;
}


