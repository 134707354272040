import React, { useState } from 'react'
import './MandateTable.scss'
import MandateCard from './MandateCard/MandateCard'
import Allocation from './Allocation/Allocation'
import { Button, Typography, Box } from '@mui/material'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as NoResultIcon } from '../../../assets/icons/no-result-found.svg'
import AddMandatePopUp from '../AddMandate/AddMandate'
import { getAllocationDetails } from '../../../services/WorkManagementService'
import Tooltip from '@mui/material/Tooltip';
import { useAppSelector } from '../../../store/hooks'

const MandateTable = ({ mandateDetails = [], recruiterID, getAllocatedMandate, getDetails, mandateDisable }:any) => {

    const [isAssignPopup, setIsAssignPopup] = useState<boolean>(false)
    const [selectedCandidate, setSelectedCandidate] = useState<any>({})
    const [editAllocateData, setEditAllocateData] = useState<any>(null)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isUnassign, setIsUnassign] = useState<boolean>(false)
    const [selectedMandateTag, setSelectedMandateTag] = useState<any>({})
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const status ='good'
    const acheived = 28
    const [editCaseMandate,setEditCaseMandate] = useState<any>()

    const parseData = (data:string) => {
        try {
          return JSON.parse(data) || []
        } catch (error) {
          return []
        }
      }

    const handleEditAllocation = async (index:number) => {
        const allocData = mandateDetails[index]
        setEditCaseMandate(allocData)
        const reqObj = {
            allocationId: allocData?.id,
            mandateId: allocData?.mandateId,
            allocatedUser: allocData?.allocatedUser
        }
        try{
            const res = await getAllocationDetails(reqObj)
            if(res?.status === 200){
                const tabData = {
                    title: allocData?.mandateDetails?.job_title,
                    mandateId: allocData?.mandateDetails?.mandate_code,
                    clientName: allocData?.mandateDetails?.client_name,
                    createdByName: allocData?.createdByUserName
                }
                setSelectedMandateTag(tabData)
                setEditAllocateData(res?.data?.result?.data || null)
                setIsUnassign(false)
                setIsEdit(true)
                setIsAssignPopup(true)
            }
        }catch(error){
            console.log(error)
        }
    }

    const handleUnassignAllocation = async (index:number) => {
        const allocData = mandateDetails[index]
        const reqObj = {
            allocationId: allocData?.id,
            mandateId: allocData?.mandateId,
            allocatedUser: allocData?.allocatedUser
        }
        try{
            const res = await getAllocationDetails(reqObj)
            if(res?.status === 200){
                const tabData = {
                    title: allocData?.mandateDetails?.job_title,
                    mandateId: allocData?.mandateDetails?.mandate_code,
                    clientName: allocData?.mandateDetails?.client_name,
                    createdByName: allocData?.createdByUserName
                }
                setSelectedMandateTag(tabData)
                setEditAllocateData(res?.data?.result?.data || null)
                setIsEdit(false)
                setIsUnassign(true)
                setIsAssignPopup(true)
            }
        }catch(error){
            console.log(error)
        }
    }

    return (
        <>
            <Box className='mandate-table-container' >
                <table className='mandate-table'>
                    <thead className='mandate-table-head'>
                        <tr className='mandate-header-row1'>
                            <th className='mandate-header sticky-left'>MANDATES
                            {(userPermissions?.work_management_module?.create?.allocate?.selected && userRole != "Admin") && <Button 
                                    style={{width:"100%",padding: "0px",fontSize: "12px"}}
                                    onClick={()=>{
                                        setIsEdit(false)
                                        setIsUnassign(false)
                                        setIsAssignPopup(true)
                                    }} 
                                    className='add-mandate'
                                    disabled={mandateDisable}
                                >
                                    <AddIcon className='plus-icon' style={{width:"16px"}}/>
                                    <span>
                                        Add Mandate

                                    </span>
                                </Button>}
                                {(userRole == "Admin") && <Button 
                                    style={{width:"100%",padding: "0px",fontSize: "12px"}}
                                    onClick={()=>{
                                        setIsEdit(false)
                                        setIsUnassign(false)
                                        setIsAssignPopup(true)
                                    }} 
                                    className='add-mandate'
                                    disabled={mandateDisable}
                                >
                                    <AddIcon className='plus-icon' style={{width:"16px"}}/>
                                    <span>
                                        Add Mandate

                                    </span>
                                </Button>}
                            </th>
                            <th className='allocation-header'>Allocation Details</th>
                            <th className='Kpi-header' colSpan={5}>KPI</th>
                        </tr>
                        <tr className='mandate-header-row2'>
                            <th className='sticky-left' align='left'>
                            {(userPermissions?.work_management_module?.create?.allocate?.selected && userRole != "Admin") && <Button 
                                    onClick={()=>{
                                        setIsEdit(false)
                                        setIsUnassign(false)
                                        setIsAssignPopup(true)
                                    }} 
                                    className='add-mandate'
                                >
                                    <PlusIcon className='plus-icon' />
                                    <span>
                                        Add Mandate

                                    </span>
                                </Button>}
                                {(userRole == "Admin") && <Button 
                                    onClick={()=>{
                                        setIsEdit(false)
                                        setIsUnassign(false)
                                        setIsAssignPopup(true)
                                    }} 
                                    className='add-mandate'
                                >
                                    <PlusIcon className='plus-icon' />
                                    <span>
                                        Add Mandate

                                    </span>
                                </Button>}
                                </th>
                            <th className='kpi-header'></th>
                            <th className='weekViewSubHeaderSect'>
                                <div className='kpi-header'>Submissions</div>
                                <div className='kpi-header'>CCM 1</div>
                                <div className='kpi-header'>CCM 2</div>
                                <div className='kpi-header'>Selects</div>
                                <div className='kpi-header'>Joins</div>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {mandateDetails.length ? mandateDetails.map((eachMandate:any, index:number)=> {
                            const stageData = parseData(eachMandate?.mandateDetails?.tracking_details)
                            const manStatus = eachMandate?.status
                            return (  
                                <tr className={`mandate-table-row ${manStatus === 65 ? "mandate-unassign-tag" : ""}`} key={index}>
                                    <td className='sticky-left' style={{pointerEvents: !eachMandate?.mandateAvailable ? "none": "auto"}}><MandateCard
                                        option={{
                                            title: eachMandate?.mandateDetails?.job_title || "-",
                                            mandateId: eachMandate?.mandateDetails?.mandate_code || "-",
                                            clientName: eachMandate?.mandateDetails?.client_name || "-",
                                            createdByName: eachMandate?.createdByUserName || "-",
                                            disableEdit: mandateDisable,
                                            jobOrderDetails: eachMandate?.jobOrderFilled + "/" + eachMandate?.totalJobOrder
                                        }}
                                        candidateData = {parseData(eachMandate?.mandateDetails?.candidate_details)}
                                        handleEditAllocation={() => handleEditAllocation(index)}
                                        handleUnassignAllocation={() => handleUnassignAllocation(index)}
                                    /></td>
                                    <td><Allocation eachData={eachMandate}/></td>
                                    <td >
                                        <Box className="workManagTableKPISect">
                                        <Tooltip title="CV Submission">
                                            <div className={`box ${status}`}>
                                                {/* <div className={`side-line ${status}`}></div> */}

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'CV Submission')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="CCM1">
                                            <div className={`box ${status}`}>
                                                {/* <div className={`side-line ${status}`}></div> */}

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'CCM1')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="CCM2">
                                            <div className={`box ${status}`}>
                                                {/* <div className={`side-line ${status}`}></div> */}

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'CCM2')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Selection Stage">
                                            <div className={`box ${status}`}>
                                                {/* <div className={`side-line ${status}`}></div> */}
                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'Selection Stage')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Joining Stage">
                                            <div className='box'>
                                                <div className={`side-line ${status}`}></div>

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'Joining & Post Joining')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        </Box>
                                    
                                    </td>
                                </tr>
                            )
                        }) : 
                        <tr className='mandate-table-row'>
                            <td colSpan={7}><div className='noMandateFound'><NoResultIcon />No Mandates Added</div></td>
                        </tr>
                        }

                    </tbody>
                </table>
            </Box>
            {isAssignPopup && <AddMandatePopUp
                isPopupOpen={isAssignPopup}
                handlePopupClose={() => { setIsAssignPopup(false); getAllocatedMandate(); getDetails()}}
                candidateDetailsData={selectedCandidate}
                recruiterID={recruiterID}
                editAllocateData={editAllocateData}
                isEdit={isEdit}
                isUnassign={isUnassign}
                selectedMandateTag={selectedMandateTag}
                editCaseMandate={editCaseMandate}
            />}
        </>
    )
}

export default MandateTable


