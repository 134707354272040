import {
  Popover,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material'
import './SmartCallPop.scss'
import { AppButton } from '../../AppComponents/AppButton'
import { useContext, useEffect, useState } from 'react'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import {
  smartflowClicktoCall,
  smartflowCallhangup,
} from '../../../services/CandidateService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { hangUpCall } from '../../../features/CallReducer/CallReducer'
import { ReactComponent as ModelCloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as MinLine } from '../../../assets/icons/minimiseLine.svg'
import { ReactComponent as RecordCircle } from '../../../assets/icons/record-circle.svg'
import { ReactComponent as MaximizeIcon } from '../../../assets/icons/maximize-3.svg'
import { ReactComponent as CallVector } from '../../../assets/icons/call-vector.svg'
import socketContext from '../../../contexts/SocketContext/SocketContext'
import CallDuration from './CallDuration'
import ScreeningModal from './screenModal'

// let toasterMessage = "Internal Server Error"

const SmartCallPop = ({ agentList, isAgentLoading }:any) => {
  const isCallPop = useAppSelector((state) => state?.smartflow.isCallPop)
  const agentState:any = useAppSelector((state)=>state?.smartflow?.agentState)
  const candidateDetails: any = useAppSelector((state) => state?.smartflow?.candidateDetails)
  const dispatch = useAppDispatch()
  const [callButtonText, setCallButtonText] = useState<string>('Call')
  const [selectedAgent, setSelectedAgent] = useState<any>({})
  const [numberList, setNumberList] = useState<any>([])
  const [selectedNumber, setSelectedNumber] = useState<any>({})
  const [callId, setCallId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [candidateNumber, setCandidateNumber] = useState(candidateDetails?.phonenumber)
  const [hasError, setHasError] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [isMinimize, setIsMinimize] = useState<boolean>(false);
  const [isSocketListen, setIsSocketListen] = useState<boolean>(false)
  const [screeningPopup,setScreeningPopup] = useState<boolean>(false);
  const [recruiterDetails,setRecruiterDetails] = useState<any>({
    name:"",
    number:"",
    id:""
  });
  const [callDetails,setCallDetails] = useState<any>([])
  const [toasterMessage,setToasterMessage] = useState<any>("Internal Server Error")

  const stompClient:any = useContext(socketContext)

  useEffect(()=>{
    if(candidateNumber?.length !== 10){
      setHasError(true)
    }else{
      setHasError(false)
    }
  },[candidateNumber])

  const hangupPhone = async () => {
    // setScreeningPopup(true)
    try {
      const res = await smartflowCallhangup(callId,Number(recruiterDetails?.number))
      if (res?.status == 200) {
        setCallButtonText('Call')
      }
      setCallButtonText('Call')
      dispatch(hangUpCall())
    } catch {
      // setServerError(true) hide server error untill websocket implementation
      setCallButtonText('Call')
      setTimeout(()=>{
        dispatch(hangUpCall())
      },1000)
    }
    
  }

  const handlePhone = async () => {
    // setScreeningPopup(true)
    setIsSocketListen(true)
    const obj = {
      recruiterMobile: recruiterDetails?.number,
      recruiterId: selectedAgent?.id,
      candidateMobile: `+91${candidateNumber}`,
      candidateId: candidateDetails?.candidateId,
      callerId:Number(selectedNumber)
    }
    setCallButtonText('Calling..')
    
    try {
      const response:any = await smartflowClicktoCall(obj)
      if(response?.data?.success===true){
        setCallButtonText('Disconnect')
      }
      else{
        setServerError(true)
        if(response?.data?.error){
            setToasterMessage(response?.data?.message)
        }else{
          setToasterMessage("Agent missed the call")
        }        
        
        setCallButtonText('Call')
        // setTimeout(()=>{
        //   dispatch(hangUpCall())
        // },500)
        
      }
      // if (response?.status == 200 || response?.status == 201) {
      //   if(response?.data?.call_id){
      //     setCallId(response?.data?.call_id)
      //     setCallButtonText('Disconnect')
      //   }else{
      //     toasterMessage = "Agent missed the call"
      //     setServerError(true)
      //   }
      // }
    } catch {
      setServerError(true)
      setToasterMessage("Agent missed the call")
      setCallButtonText('Call')
      setIsSocketListen(false)
    }
  }

  const handleSelectRecruiter = async (recId: any) => {
    setIsLoading(true)
    const obj = await agentList?.find((list: any) => list?.id === recId)
    const selectNumList = await agentList?.filter((list: any) => list?.name === obj?.name)
    setSelectedAgent(obj)
    setSelectedNumber(obj)
    setNumberList(selectNumList)
    setIsLoading(false)
  }

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setServerError(false)
  }

  useEffect(()=>{
    if(stompClient && isSocketListen){
      const subscription = stompClient.subscribe('/topic/calltracker', (massage:any)=>{
        const data = JSON.parse(massage.body)
        if(data?.call_to_number.slice(-10) == candidateNumber){
          if(data?.call_status === "answered"){
            setServerError(true)
            setToasterMessage("Call disconnected") 
            setCallButtonText('Call')
          }
          if(data?.call_status === "missed"){
            setServerError(true)
            setToasterMessage("Candidate missed the call")
            setCallButtonText('Call')
          }
        }
      })

      return () => {
        subscription.unsubscribe()
      }
    }
  },[stompClient, isSocketListen])
  useEffect(()=>{
    if(agentState && Object?.keys(agentState)?.length){
      setRecruiterDetails({
        ...recruiterDetails,
        name:agentState?.name,
        number:agentState?.phone,
        id:agentState?.id
      })
      if(agentState?.callId?.length){
        setCallDetails(agentState?.callId)
        setSelectedNumber(agentState?.callId[0].did)
      }
      else{
        setCallDetails([])
      }
    }
  },[agentState])
  return (
    <Box className='popup-wrapper'>
      <Popover
        // id={id}
        open={isCallPop}
        // anchorEl={anchorEl}
        // onClose={()=>setIsCallPop(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className='popup-container'
        // style={{position:"relative"}}
      >
        <div className={!isMinimize ? "call-container" : "minimize-container"}>
          <div className='header-tab'>
            <span className='primary-text'>{!isMinimize ? "Candidate call" : candidateDetails?.name}</span>
            <div className='header-icons'>
              {!isMinimize ? 
              <>
                {callButtonText === 'Disconnect' && <span 
                  className='min-icon'
                  onClick={()=> {
                    if(callButtonText === 'Disconnect')
                      setIsMinimize(true)
                    }}
                >
                  <MinLine style={{cursor: "pointer"}} />
                </span>
                }
                <ModelCloseIcon style={{cursor: "pointer"}} onClick={() => {
                  if(callButtonText === 'Disconnect'){
                    hangupPhone()
                    dispatch(hangUpCall())
                  }else{
                    dispatch(hangUpCall())
                  }
                }}/>
              </> : 
                <span>
                  <MaximizeIcon onClick={()=> {
                      setIsMinimize(false)
                    }}
                  />
                </span>
              }
            </div>
          </div>
          <div className='form-container'>
            {callButtonText === 'Call' ? (
              <>
                <TextField
                  label='Candidate Number'
                  value={candidateNumber}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        +91
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e:any)=> setCandidateNumber(e.target.value)}
                  required={true}
                />
                {hasError && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      Please enter the correct number
                  </span>
                )}
                <FormControl fullWidth required>
                <TextField
                      label="Recruiter Name & Number"
                      value={`${recruiterDetails?.name} &  ${recruiterDetails?.number}`}
                      style={{
                      borderRadius: '10px',
                      width: '100%',
                      }}
                      className="textBox"
                      />
                </FormControl>
                <FormControl fullWidth required>
                  <InputLabel>Select Number</InputLabel>
                  {!isLoading && (
                    <Select
                      label='Select Number'
                      value={selectedNumber}
                      style={{
                        width: '100%',
                        borderRadius: '10px',
                      }}
                      onChange={(e) => {
                        setSelectedNumber(e.target.value);
                      }}
                      // disabled={numberList?.length === 0}
                    >
                      {callDetails?.length > 0 &&callDetails?.map((list: any) => {
                        return (
                          <MenuItem key={list?.id} value={list?.did}>
                            {list?.did}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )}
                </FormControl>
                <TextField
                  label='Call Display Name'
                  value={'Unknown Number'}
                  style={{ width: '100%' }}
                />
              </>
            ) : (
              <>
                <div className='connected-call-wrapper'>
                  <Avatar sx={{ width: 80, height: 80 }} src={candidateDetails?.profilePicture} />
                  {!isMinimize &&
                  <span className='candidate-name'>
                    {candidateDetails?.name}
                  </span>}
                  {!isMinimize &&
                  <span className='candidate-number'>
                  +91 {candidateNumber}
                  </span>}
                  <div className='record-container' style={{ opacity: `${callButtonText === "Calling.." ? 0.5 : 1}`}}>
                    {!isMinimize && <RecordCircle/>}
                    {callButtonText === "Calling.." ? 
                      <span className='duration'>
                        00:00
                      </span> 
                      : <CallDuration/>
                    }
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='footer'>
          {!isMinimize &&
            <AppButton
              label={callButtonText}
              disabled={agentState&&Object.keys(agentState)?.length===0}
              bordercolor='#002882'
              bgcolor={callButtonText === 'Disconnect' ? '#E51E1E' : '#2BAD63'}
              size='large'
              type='button'
              // className={`edit-btn ${Object.keys(selectedAgent)?.length===0 && "disabled-btn"}`}
              sx={{ color: '#fff', backgroundColor: '#2BAD63', p: 1 }}
              onClick={() => (callButtonText === 'Call' ? handlePhone() : hangupPhone())}
            >
              {callButtonText !== 'Disconnect' && <LocalPhoneOutlinedIcon />}
            </AppButton>}
            {isMinimize && 
              <div className='hangup-btn' onClick={hangupPhone}>
                <CallVector/>
              </div>
            }
          </div>
        </div>
        {/* {
      screeningPopup && (
        <ScreeningModal />
      )
    } */}
      </Popover>
      {
        serverError &&
        <Snackbar
        open={serverError}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} variant='filled'>
            {toasterMessage}
        </Alert>
      </Snackbar>
      }
    
    </Box> 
  )
}

export default SmartCallPop
