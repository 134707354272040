import { Box, Button, Divider, FormControl, Grid, TextField, Tooltip } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg'
import { ReactComponent as HintsIcon } from '../../../../assets/icons/info-circle.svg'
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";

const UploadDocumentButton = ({ file, setFiles, setFileSizeErrorMessage, index, fileUploadExtensionType, ...props }: any) => {
  const {
      getRootProps,
      getInputProps,
      open,
      isDragAccept,
      isFocused,
      isDragActive,
      isDragReject,
  } = useDropzone({
      multiple: false,
      maxSize: 10485760,
      accept: fileUploadExtensionType,
      // {
      //     "application/msword": [".doc"],
      //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      //     "application/pdf": [".pdf"],
      // },
      noKeyboard: true,
      noDrag: true,
      onDropAccepted: async (acceptedFiles) => {
          setFiles(acceptedFiles)
          setFileSizeErrorMessage((pre: any) => {
              const clone = { ...pre }
              delete clone[index]
              return clone
          })
      },
      onDropRejected: (rejectedFiless) => {
          // setFiles([])
          if (rejectedFiless[0].errors[0].code === "file-too-large") {
              setFileSizeErrorMessage((pre: any) => {
                  const clone = { ...pre }
                  clone[index] = "File size exceeded, please upload file within 10 MB"
                  return clone
              })
          } else if (rejectedFiless[0].errors[0].code === "file-invalid-type") {
              setFileSizeErrorMessage((pre: any) => {
                  const clone = { ...pre }
                  clone[index] = "File format incorrect, please upload only specified file formats"
                  return clone
              })
          } else {
              setFileSizeErrorMessage((pre: any) => {
                  const clone = { ...pre }
                  clone[index] = rejectedFiless[0].errors[0].message
                  return clone
              })
          }

      },
      // ... props
  });

  return (
      <section className="container">
          <div {...getRootProps({ className: 'custom-dropzone' })}>
              <input {...getInputProps()} />
              <div className='file-name-container'>
                  <div className='file-upload-icon'>
                      <UploadIcon />
                      <span>Upload Document</span>
                  </div>
                  <Tooltip title={file?.name}>
                      <div className='file-preview-text'>{file?.name}</div>
                  </Tooltip>
              </div>
          </div>
      </section>
  )
}

const AttachmentFieldsForm = ({isEdit, mandateData}:any) => {
  const fileUploadExtension:any = useAppSelector((state:any) => state?.SystemConfig?.uploadFileConfigData)
  const [fileUploadExtensionType, setFileUploadExtensionType] = useState({})

const fileExtensionObj:any = () => {
  const options:any = {}
  fileUploadExtension?.mandate.map((items:any) => {
    options[items.value] = "[."+items.key+"]"
  })
  setFileUploadExtensionType(options)
}

useEffect(() => {
  fileExtensionObj()
},[])

  const { control, formState: { errors }, getValues } : any = useFormContext()

  const { fields: attachmentFields, append : attachmentAppend, remove: attachmentRemove } = useFieldArray(
    {
      control,
      name: "attachments"
    }
  );
  const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState<any>({})
  
  return (
    <Grid className='container-wrapper'>
      {isEdit &&
        <Box>
            {mandateData?.file?.map((file: any, index: any) => (
                <div key={index} className='uploaded-file-wrapper'>
                    <span>{file?.title}</span>
                    {!!mandateData?.file[0]["filename"] && <span>-</span>}
                    <span className='uploaded-file-names'>{file?.filename}</span>
                </div>
            ))}
        </Box>
      }
      {attachmentFields.map((item:any, index:any)=>{
        return (
          <div key={item.id}>
            <Grid key={item.id} style={{display: "flex", justifyContent: "space-between", gap: "5px"}}>
              <div style={{width: "100%"}}>
                <Grid className={`row-grid ${index !==0 && "pt-1"} res-grid`} style={{width: "50%"}}>
                  <FormControl className='res-grid' fullWidth style={{width: "50%"}} >
                    <Controller
                      name={`attachments.${index}.key`}
                      control={control}
                      render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                          label='Document Name'
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </FormControl>
                  <Grid style={{zIndex: "1000", width:"50%"}}>
                    <span className='Attachment-label'>Attachment</span>
                    <FormControl fullWidth className="schAdminInputCtrSelect upload-form-control">
                      <Controller
                        name={`attachments.${index}.value`}
                        control={control}
                        render={({field : {onChange, value, onBlur}})=>(
                          <UploadDocumentButton
                              setFiles={(file:any)=>onChange(file[0])}
                              file={value}
                              setFileSizeErrorMessage={setFileSizeErrorMessage}
                              index={index}
                              fileUploadExtensionType={fileUploadExtensionType}
                          />
                        )}
                      />
                    </FormControl>
                    {fileSizeErrorMessage?.[index] && <p className='error'>{fileSizeErrorMessage?.[index]}</p>}
                    <Box className='helper-boxtext'>
                        {' '}
                        <HintsIcon />{' '}
                        <Box style={{width: "250px"}}>
                            {`Note: ( ${fileUploadExtension?.mandate.map((item:any) => {return "."+item.key}).join(", ")} ) Maximum Size 10 MB`}
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              <div
                style={{display:"flex", alignItems:"center", cursor: "pointer"}} 
                onClick={() => attachmentRemove(index)}
                className='delete-icon'
              >
                <DeleteIcon />
              </div>
            </Grid>
            {(attachmentFields?.length-1 !== index) && <Divider style={{paddingTop: "24px"}}/>}
          </div>
        )
      })}
      <Box className='button-container' 
        style={{justifyContent:"flex-start", padding: "1rem 0rem"}}
      >
        <Button 
          className='draft-btn'
          onClick={() => {
            attachmentAppend({ key: '', value: null });
          }}
        >+ Add Document</Button>
      </Box>
    </Grid>
  );
}

export default AttachmentFieldsForm;