import IconButton from '@mui/material/IconButton'
import { SvgIcon } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { ReactComponent as ToggleMenu } from '../../../assets/images/leftMenuToggleIcon.svg'
import { ReactComponent as SidebarToggle } from '../../../assets/icons/collapse_toggle.svg'
import './Hamburger.css'
import { useAppSelector } from '../../../store/hooks'
interface HamburgerProps {
  toggleNavigation: () => void
}

export const Hamburger = ({ toggleNavigation }: HamburgerProps) => {
  // const [isOpen, setIsOpen] = useState(false)
  const isOpen = useAppSelector((state) => state.drawertoggle.leftopen)
  const toggle = () => {
    console.log('hamburger')
    console.log('hamopen', isOpen)
    // setIsOpen(!isOpen)
    toggleNavigation()
  }
  console.log('hamopen', isOpen)
  return (
    <IconButton onClick={toggle}>
      <SvgIcon>{!isOpen ? <ToggleMenu /> : <SidebarToggle />}</SvgIcon>
    </IconButton>
  )
}
