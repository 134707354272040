import { createSlice } from '@reduxjs/toolkit'

const initialCallState = {
  isCallPop: false,
  candidateDetails: {},
  agentState:{}
}

const CallSlice = createSlice({
  name: 'smartflow',
  initialState: initialCallState,
  reducers: {
    clickToCall: (state, action) => {
      return{
        ...state,
        isCallPop:true,
        candidateDetails:action.payload
      }
    },
    hangUpCall: (state) => {
      return{
        ...state,
        isCallPop:false,
        candidateDetails:{}
      }
    },
    setAgentState:(state,action) =>{
      return{
        ...state,
        agentState:action.payload
      }
    }
  }
})

export const {clickToCall, hangUpCall,setAgentState} = CallSlice.actions;
export default CallSlice.reducer;