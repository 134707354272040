/* eslint-disable react/prop-types */
import { AppButton } from '../../../AppComponents/AppButton';
import { Box, FormControl, InputLabel, MenuItem, Popover, Select, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modalcloseicon.svg';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AppMandateDropdown from '../../../AppComponents/AppMandateDropdown/AppMandateDropdown';
import { getMandateLocation, assignCandidateToMandateLocation } from '../../../../services/MandateService'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warningfill.svg';
import { AppErrorHandlerModal } from '../../../AppComponents/AppErrorHandlerModal';
import { ReactComponent as InfoCircleIcon } from '../../../../assets/icons/info-circle.svg';
import { useAppSelector } from '../../../../store/hooks';

type Tprop = {
    candidateDetailsData:any;
    isPopupOpen: boolean;
    handlePopupClose: ()=> any;
}

const initialValues = {
    selectedMandate: '',
    selectedMandateLocation: ''
}



const AssignMandateLocationPopup:React.FC<Tprop> = ({candidateDetailsData, isPopupOpen, handlePopupClose}) => {

  const [isPopOpen, setIsPopOpen] = useState<boolean>(true)
  const [mandatelocationLists, setMandateLocationLists] = useState<any[]>([])
  const [assignError, setAssignError] = useState<boolean>(false)
  const [successPopup, setSuccessPopup] = useState<{isOpen:boolean, message:string}>({isOpen:false, message:""})
  const [assignedErrorMsg, setAssignedErrorMsg] = useState<string>('')
  const [allowMultiple, setAllowMultiple] = useState<boolean>(false)
  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)

  const {
      handleSubmit,
      reset,
      control,
      formState:{errors},
      watch,
      setValue
    } = useForm<any>({defaultValues: initialValues});

    const manChange:any = watch("selectedMandate")
    
    const getMandateLocationList = async (manId:any)=> {
      // const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : ""
      const apiQuery = `?mandateBehaviour=${manId?.mandateBehaviour}`
      const response = await getMandateLocation(manId?.value, apiQuery)
      if(response?.status === 200){
          setMandateLocationLists(response?.data?.result?.mandateLocations)
      }else{
          setMandateLocationLists([])
      }
    }

    const handleAssignMandateLocation = async (data:any) => {

      try{
          const obj = {
              mandateId: data?.selectedMandate?.value,
              mlocationId: data?.selectedMandateLocation,
              candidateId: candidateDetailsData?.id,
              isAllowMultiple:allowMultiple
          }
  
          const res = await assignCandidateToMandateLocation(obj)
          
          if(res?.status === 200){
              setIsPopOpen(false)
              setAssignError(false)
              setSuccessPopup({
                  isOpen:true, 
                  message:`XPH${obj?.candidateId} successfully assigned to the ${data?.selectedMandate?.mandateId}`
              })
              setAllowMultiple(false)
          }

      }catch(error:any){

          if( error?.response?.data?.error?.errorCode === "ERR102" ){
              setAllowMultiple(true)
          }else{
              setAllowMultiple(false)
          }
          if(error?.response?.data?.error?.message){
              setAssignedErrorMsg(error?.response?.data?.error?.message)
              setAssignError(true)
          }
      }
    }

    useEffect(()=>{
      if(manChange){
          setValue("selectedMandateLocation", "")
          getMandateLocationList(manChange)
      }
      if(!manChange){
          reset(initialValues)
          setMandateLocationLists([])
          setAssignError(false)
          setAllowMultiple(false)
      }
    }, [manChange])


  return (
    <div>
      <Popover open={isPopOpen} className='assign-mandate-location-popup'>
        <Box
          className='assign-container'
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(handleAssignMandateLocation)}
        >
          <Box className='header'>
            <Typography>Assign JO to Candidate</Typography>
            <CloseIcon style={{cursor:"pointer"}} onClick={handlePopupClose} />
          </Box>
          <Box className='body-conatiner'>
            <FormControl fullWidth required>
              <Controller
                name='selectedMandate'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Mandate is required',
                  },
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <AppMandateDropdown
                    label='Select Mandate'
                    required={true}
                    value={value}
                    onChange={(e: any) => onChange(e)}
                  />
                )}
              />
              {errors?.selectedMandate && (
                <span className='reminder_text-danger select-danger-text' role='alert'>
                  {errors?.selectedMandate?.message as string}
                </span>
              )}
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel>Select Mandate Location</InputLabel>
              <Controller
                name='selectedMandateLocation'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Mandate Location is required',
                  },
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Select
                    label='Select Mandate Location'
                    value={value}
                    onChange={(e: any) => {
                      onChange(e)
                      if(assignError)setAssignError(false)
                    }}
                    className='test-dropdown'
                    labelId='mandate-location-select-label'
                  >
                    {mandatelocationLists?.length &&
                      mandatelocationLists?.map((mll: any, index: number) => (
                        <MenuItem key={index} value={mll?.id} className='mandate-location-lists-opt'>
                          <div className='primary-text'>{`${mll?.location} (${mll?.jobOrderCount})`}</div>
                          {/* <div className='secondary-text'>{`XRDMAN${mll?.mandateId}LOC${mll?.id}`}</div> */}
                        </MenuItem>
                      ))}
                    {mandatelocationLists?.length === 0 && (
                      <MenuItem disabled={true}>No Location</MenuItem>
                    )}
                  </Select>
                )}
              />
              {errors?.selectedMandateLocation && (
                <span className='reminder_text-danger select-danger-text' role='alert'>
                  {errors?.selectedMandateLocation?.message as string}
                </span>
              )}
            </FormControl>
          </Box>
          {assignError && (
            <div className={`assign-warning ${allowMultiple ? 'warning-msg' : 'error-msg'}`}>
              <div>{allowMultiple ? <InfoCircleIcon /> : <WarningIcon />}</div>
              <div>{assignedErrorMsg}</div>
            </div>
          )}
          <Box className='footer'>
            <AppButton
              label='Cancel'
              bordercolor='#E5E8EB'
              bgcolor='#E5E8EB'
              size='large'
              type='button'
              sx={{ color: '#000;', backgroundColor: '#000;', p: 1 }}
              onClick={handlePopupClose}
            />
            <AppButton
              label='Assign'
              bordercolor={assignError && !allowMultiple ? '#919395' : '#002882'}
              bgcolor={assignError && !allowMultiple ? '#919395' : '#002882'}
              size='large'
              type='submit'
              className='assign-btn'
              sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
            />
          </Box>
        </Box>
      </Popover>
        <AppErrorHandlerModal
          open={successPopup?.isOpen}
          content={successPopup?.message}
          handleClose={() => {
            setSuccessPopup({ isOpen: false, message: '' })
            handlePopupClose()
          }}
          factor='Success'
          ButtonText='Done'
        >
          <></>
        </AppErrorHandlerModal>
    </div>
  )
}

export default AssignMandateLocationPopup
