/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {
  Icon,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Box,
} from '@mui/material'
import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Route } from '../../../../types/Route'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg'
import { useAppDispatch } from '../../../../store/hooks'
import { resetGptThreadId } from '../../../../features/chatGpt/ChatGptReducer'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
interface RouteItemProps {
  route: Route
  nested?: boolean
  hasChildren?: boolean
  handleMenuClick?: (route: Route) => void
  open: boolean | undefined,
  subMenuToggle?:boolean,
  toggleSubMenuHandler?:any,
  setSubMenuSelected?:any,
  subMenuEnable?:any
}

export const RouteItem = ({
  route,
  hasChildren = false,
  handleMenuClick = () => {},
  open,
  subMenuToggle,
  toggleSubMenuHandler,
  setSubMenuSelected,
  subMenuEnable
}: RouteItemProps) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [toogleSubMenu, setToogleSubMenu] = useState(false)

  const handleNavigate = (e:any,targetRoute:any) => {
    if (!route.isEnabled || hasChildren){
      e.preventDefault()
    }else if(subMenuToggle){
      setToogleSubMenu(!toogleSubMenu)
      toggleSubMenuHandler(targetRoute)
      e.preventDefault()
    }
    if(!("staticMenu" in targetRoute)){
      if(subMenuEnable){
        setSubMenuSelected(new Date().getTime())
      }else if(!subMenuEnable && !subMenuToggle){
        setSubMenuSelected("")
      }
    }
    
    dispatch(resetGptThreadId())
  }

  const isSelected =
    location.pathname === route.path ||
    (hasChildren && route.subRoutes?.some((e) => location.pathname === e.path))

  const item = (
    <ListItem disablePadding sx={{ display: 'block' }} className='router-item'>
      <ListItemButton
        css={css`
          width: ${!open && '50px'};
        `}
        onClick={() => handleMenuClick(route)}
        className={` ${isSelected && !hasChildren ? 'selected-route' : 'default-router-item'}`}
      >
        {!hasChildren && (
          <ListItemIcon className='router-icon-button'>
            <IconButton>
              {route.icon && (
                <Icon
                  component={route.icon}
                  css={css`
                    fill: ${isSelected ? '#000000' : '#727376'};
                    min-width: ${hasChildren && '0px'};
                  `}
                  className='router-icon'
                />
              )}
            </IconButton>
          </ListItemIcon>
        )}
        {open && (
          <ListItemText
            className={` ${hasChildren ? 'route-title route-title-parent' : 'route-title'}`}
          >
            {route.title}{' '}
          </ListItemText>
        )}
        <Box className={` ${!open && 'expand-icon'}`}>
          {hasChildren &&
            (route.expanded ? (
              <PlusIcon style={{ fill: '#727376' }} />
            ) : (
              <PlusIcon style={{ fill: '#727376' }} />
            ))}
        </Box>
        {subMenuToggle && 
        <Box className="">
             {toogleSubMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} 
        </Box>
        }
        

        {open && (
          <Box
            className={` ${isSelected && !hasChildren ? 'selected-route-highlighter' : ''}`}
          ></Box>
        )}
      </ListItemButton>
    </ListItem>
  )

  return (
    <NavLink
      className={`${!route.isEnabled ? "noLinkMenu" : ""}`}
      to={`${!route.isEnabled ? "" : route.path}`}
      key={route.key}
      onClick={(e:any) => handleNavigate(e,route)}
      css={css`
        text-decoration: none;
        color: inherit;
      `}
    >
      {route.description ? <>{item}</> : item}
    </NavLink>
  )
}
