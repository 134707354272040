import React, { useState } from 'react';
import './styles.scss';
import businessSuitcase from "../../../../assets/icons/suitcase.svg"
import businessSuitcaseBlack from "../../../../assets/icons/businesssuitcase.svg"
import { Box } from '@mui/material';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'

type Tprop = {
    candidateDetailsData: any;
}
const WorkExperience: React.FC<Tprop> = ({ candidateDetailsData }) => {
    const workExperienceList = candidateDetailsData?.workExperiences != undefined ? JSON.parse(JSON.stringify(candidateDetailsData?.workExperiences)).reverse() : [];
    const renderEmptySearchBox = () => {
        const emptyMessage = `No data found`;
        return (
            <Box className="candidate-empty-search-box candidateDetailWraper">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    {emptyMessage}
                </Box>
            </Box>
        )
    }

    return (
        <div className='workexperience-wrap'>
            {
                workExperienceList?.length ? <div>
                    {workExperienceList?.map((experinces: any, index: number) => {
                        return (
                            <div key={index} className='each-experience-card candidateWorkExper'>
                                <div className={`svgcontainer${index === 0 ? "-active" : ""}`}>
                                    <img src={index === 0 ? businessSuitcase : businessSuitcaseBlack} />
                                </div>
                                <div className={`experience-content${index === workExperienceList?.length - 1 ? "-last" : ""}`}>
                                    <div className='exp-title'>
                                        <span className='head'>{experinces?.company ? experinces?.role +' at ' + experinces?.company : experinces?.role}</span>
                                        <span className='date'> {experinces?.date}</span>
                                    </div>
                                    <div className='description'>{experinces?.description}</div>
                                    {/* {experinces?.rolesAndDescriptions&& <>
                                        <div className='rolesandresp'>
                                            <span className='head'>Roles & Responsibilities</span>
                                            <ul>
                                                <li>Keyword and Competitor Analysis.</li>
                                                <li>ON-Page and OFF-Page SEO optimization.</li>
                                            </ul>
                                        </div>
                                        <div className="on-page">
                                            <span className="head">ON-Page</span>
                                            <ul>
                                                <li>Develop efficient SEO strategies to improve organic traffic.</li>
                                                <li>Worked with google webmaster and google analytics.</li>
                                                <li>Develop link-building tactics to improve ranking of keywords in Google Organic Search</li>
                                                <li>ON-Page and OFF-Page SEO optimization.</li>
                                            </ul>
                                        </div>
                                        <div className="off-page">
                                            <span className="head">OFF-Page</span>
                                            <ul>
                                                <li>Keyword and Competitor Analysis.</li>
                                                <li>ON-Page and OFF-Page SEO optimization.</li>
                                            </ul>
                                        </div>
                                    </>} */}
                                </div>
                            </div>
                        )
                    })}
                </div>
                    : renderEmptySearchBox()
            }
        </div>
    )
}

export default WorkExperience;
