// APP TEXT
export const APP_TITLE = 'Xpheno'
export const APP_DESCRIPTION = `Fastest-growing Specialist Staffing Solutions company offering IT Staffing, Engineering Services, Sales Staffing and
Direct Hire services..`

// UI CONSTANTS
export const FOOTER_HEIGHT = 30
export const HEADER_HEIGHT = 48
export const DRAWER_WIDTH = 245
export const SIDENAV_MIN_WIDTH = 65
export const RIGHTNAV_MIN_WIDTH = 60
export const RIGHTNAV_MAX_WIDTH = 205
// APP THEME
export const DARK_MODE_THEME = 'dark'
export const LIGHT_MODE_THEME = 'light'
export const COLOR_WHITE = '#FFFFFF'
export const APP_BG = '#F3F3F7'
export const ICON_COLOR = '#B3B5B7'
export const PRIMARY1 = '#FF6E00'
export const PRIMARY2 = '#002882'
export const PRIMARY3 = '#000000'
export const SECONDARY1 = '#0E94F4'
export const SECONDARY2 = '#F34334'
export const SECONDARY3 = '#727376'
export const TERTIARY1 = '#2BAD63'
export const TERTIARY2 = '#FCB12B'
export const TERTIARY3 = '#E5E8EB'
export const TERTIARY4 = '#F0F6FB'
export const TERTIARY5 = '#4FADFF'
export const TERTIARY6 = '#DAE354'
export const TERTIARY7 = '#FC6481'
export const TERTIARY8 = '#D36EC6'
export const TERTIARY9 = '#9085DA'
export const TERTIARY10 = '#38D996'
export const TERTIARY11 = '#835AA8'
export const TERTIARY12 = '#FFFFFF'
export const TERTIARY13 = '#D9D9D9'


export const modelData = {
    "id": 60,
    "name": "Rushikesh Netaji Sudke",
    "experience": 1.0,
    "ctcInLacs": 1.5,
    "currentCompany": "Data Analyst at growsoft India Ltd",
    "currentDesignation": "Data Analyst",
    "currentLocation": "Mumbai",
    "preferredLocations": "Mumbai,  Gurgaon/Gurugram,  Pune,f  Chennai,  Noida,  Ahmedabad,  Bangalore/Bengaluru,  Hyderabad/Secunderabad,  Kolkata,  Delhi / NCR pref. locations ",
    "previousCompany": "",
    "educationDetails": "BCA Swami Ramanand Teerth Marathawada University (SRTMU) 2022",
    "keySkills": "MySQL Database Administration,Data Processing,Python,MySQL,Anaconda,SQL,Azure Devops,Data Science,Pandas,Numpy,Data Analysis,AWS,Matplotlib",
    "otherSkills": "MySQL Database Administration,Data Processing,Python,MySQL,Anaconda,SQL,Azure Devops,Data Science,Pandas,Numpy,Data Analysis,AWS,Matplotlib",
    "about": "Data Analyst with BCA in Computers currently living in Mumbai",
    "profileViewedStatus": "Profile viewed by you on 2023-04-06",
    "modifiedOn": "2023-04-28",
    "profileViews": 9,
    "downloads": 14,
    "lastActive": "2023-04-17",
    "address": "",
    "uniqueId": "f58004f455d455215105f590a521f415843564342590c0e024f400816",
    "displayPicture": "",
    "noticePeriod": "15 Days or less",
    "dateOfBirth": "2001-07-19",
    "phoneNumber": "7057278731",
    "email": "mailto:rushisudke7371@gmail.com",
    "linkedin": "",
    "workSummary": "Bachelor of Computer Applications(BCA), From COCSIT College, Latur with an aggregate of 8.5 CGPA. A highly skilled, competent, and diligent individual is seeking an opportunity to establish a career as a Data Analyst. Strong willingness to exhibit my proficiency in Analytical tools, Statistics and Computing Methodologies in the professional environment.",
    "workExperiences": [
        {
            compname:"aaaaa",
            date:"12'223;'23"
        },
        {
            compname:"bbb",
            date:"12'223;'23"
        },
        {
            compname:"cccccccc",
            date:"12'223;'23"
        },
        {
            compname:"cccccccc",
            date:"12'223;'23"
        },
        {
            compname:"cccccccc",
            date:"12'223;'23"
        }
    ],
    "itSkills": [
        {
            "skills": "mysql database administration",
            "version": "SQL server",
            "last_used": "2023",
            "experience": "1y"
        }
    ],
    "certification": [],
    "detailedEducation": [
        {
            "designation": "BCA,Computers,2022 - UG",
            "institute": "Swami Ramanand Teerth Marathawada University (SRTMU)"
        }
    ],
    "knownLanguages": [],
    "candidateResume": "https://artemis-uploads-dev.s3.ap-south-1.amazonaws.com/rpa_cv/Rushikesh_Netaji_Sudke.pdf",
    "status": "complete"
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

