import { Box, Button, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'

const ProfileUrlArray = () => {
  const { control, formState: { errors }, getValues } : any = useFormContext()

  const { fields: attachmentFields, append : attachmentAppend, remove: attachmentRemove } = useFieldArray(
    {
      control,
      name: "profileUrl"
    }
  );
    return (
        <Box>
            {attachmentFields.map((item:any, index:any)=>{
            return (
            <div key={item.id}>
                <Grid key={item.id} style={{display: "flex", justifyContent: "space-between", gap: "5px"}}>
                <div style={{width: "100%",marginBottom:"30px"}}>
                    <Grid className={`row-grid ${index !==0 && "pt-1"} res-grid`} style={{width: "50%",display:"flex",gap:"30px"}}>
                    <FormControl className='res-grid' fullWidth style={{width: "50%"}} >
                        <Controller
                        name={`profileUrl.${index}.profileUrl`}
                        control={control}
                        render={({field : {onChange, value, onBlur}})=>(
                            <TextField
                            label='Profile URL'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            />
                        )}
                        />
                    </FormControl>
                    {attachmentFields.length > 1 && <div
                        style={{display:"flex", alignItems:"center", cursor: "pointer"}} 
                        onClick={() => attachmentRemove(index)}
                        className='delete-icon'
                    >
                        <DeleteIcon />
                    </div>}
                    </Grid>
                </div>
            </Grid>
        </div>
        )
        })}
        <Box className='button-container' 
        style={{justifyContent:"flex-start", padding: "0rem 0rem 1rem 0rem"}}
      >
        <Button 
          className='draft-btn'
          onClick={() => {
            attachmentAppend({ profileUrl: ''});
          }}
        >+ Add Profile URL</Button>
      </Box>
        </Box>
    );
};

export default ProfileUrlArray;