import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  Dialog,
  DialogProps,
  Box,
  IconButton,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  createTheme,
  ThemeProvider,
  Grid,
  Autocomplete,
  Checkbox,
  Avatar,
  TextField,
} from '@mui/material'
import { AppButton } from '../../AppComponents/AppButton'
import { AppTextField } from '../../AppComponents/AppTextField'
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal'
import './MandatesModal.scss'
import { TERTIARY13, PRIMARY2, TERTIARY3, COLOR_WHITE, PRIMARY3 } from '../../../utils/constants'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as HintsIcon } from '../../../assets/icons/info-circle.svg'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg'
import { object, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler, FormProvider, Controller } from 'react-hook-form'
import _ from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete'
import { Renderplaceholder, AppControlSelect } from '../../AppComponents/AppSelect'
import { MandateService } from '../../../services/MandateService'
import { toast } from 'react-toastify'
import Tooltip from '@mui/material/Tooltip'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import GroupStrick from '../../../assets/images/default-avatar.png'
import { getApprovalLists } from '../../../services/ApprovalListService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { mandateUploadStatus } from '../../../features/mandateDetail/DrawerReducer'
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom'
import StarRateIcon from '@mui/icons-material/StarRate';

const ModalStyle = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: '10px',
  },
  '& .MuiTypography-root.MuiDialogTitle-root': {
    fontSize: '1rem',
    color: '#000',
    fontWeight: 600,
    lineHeight: '20px',
  },
  // '&.MuiDialog-paper::-webkit-scrollbar': {
  //   display: 'none'
  // }
}))

// theme provider
export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
})

// Dialog titlt props
export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function ModalDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon style={{cursor:"pointer"}}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
// type of props taskmodel will recive
type ModalProps = {
  handleClose: () => void
  open: boolean
  title?: string
  children?: React.ReactNode
  onClose?: () => void
} & DialogProps

// type of form inputs and incoming props
export type MandateFormInputProps = {
  candidateName: string
  cvUploadEmail: string
  cvUploadContact: string
  cvUploadFile: string[]
  cvRemarks:string
}
export type ErrorResponse = {
  configModule: string
  configValue: string
  configVariable: string
  id: number
}
const maxFileSize = 25000000
const AcceptedFile = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]
export const CvProfileUploadModal: React.FC<ModalProps> = ({
  open,
  handleClose,
  ...props
}) => {
  
  const [isDisable, setIsDisable] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [uploadsuccess, setUploadsuccess] = useState(false)
  const [SuccessMessage, setSuccessMessage] = useState('')
  const [cvUploadCreatedId, setCvUploadCreatedId] = useState('')
  const navigate =useNavigate()

  // Initial values for form input field
  const formSchema = object({
    // clientId: z.any().refine(value => (value !== null && value !== undefined && value !== ''),  `Client name is required ` ),
    candidateName: z.string().min(1, { message: 'Candidate Name is required' }),
    cvUploadEmail: z.string().min(1, { message: 'Email is required' }).email({
      message: "Must be a valid email",
    }),
    cvUploadContact: z.string().min(1, { message: 'Contact is required' }).min(10, { message: 'Minimum of 10 digit number fields only allowed' }).max(10, { message: 'Maximum of 10 digit number fields only allowed' }),
    cvUploadFile: z
      .any()
      .refine((val) => val.length > 0, 'File is required')
      .refine((files) => files?.[0]?.size <= maxFileSize, `Max file size is 10MB.`)
      .refine(
        (files) => AcceptedFile.includes(files?.[0]?.type),
        'PDF, Docx are accepted',
      ),
    cvRemarks: z.string()
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: FormValues = {
    candidateName: '',
    cvUploadEmail: '',
    cvUploadContact: '',
    cvUploadFile: [],
    cvRemarks:''
  }

  const initialvalues: FormValues = {
    candidateName: '',
    cvUploadEmail: '',
    cvUploadContact: '',
    cvUploadFile: [],
    cvRemarks:''
  }

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    shouldUnregister: true,
  })

  const [modal, setModalForm] = useState<FormValues>({
    candidateName: '',
    cvUploadEmail: '',
    cvUploadContact: '',
    cvUploadFile: [],
    cvRemarks: ''
  })

  useEffect(() => {
    methods.reset(modal)
  }, [modal])

  // async function ApproverList(){
  //   const response = await getApprovalLists()
  //   setMembers(response)
  // }


  const handlemodalclose = () => {
    methods.clearErrors()
    methods.reset()
    handleClose()
    setErrorMessage('')
    setIsDisable(false)
  }

  // success modal
  const handlsuccessClose = () => {
    setUploadsuccess(false)
    setSuccessMessage('')
    handlemodalclose()    
    navigate(`/candidate/${cvUploadCreatedId}`)
  }

  const PreView = methods.watch('cvUploadFile', [])
  const { error }:any = methods.getFieldState('cvUploadFile', methods.formState)
  // form submit
  const onSubmitHandler: SubmitHandler<FormValues> = async (formValues: FormValues) => {
    setIsDisable(true)
    console.log('formvalues', formValues)
    console.log('file', formValues.cvUploadFile[0])
    const CvUploadObject = {
      candidateName:formValues.candidateName,
      email:formValues.cvUploadEmail,
      candidateMobile:formValues.cvUploadContact,
      remarks: formValues.cvRemarks
    }
    const formData = new FormData()
    formData.append('candidateProfile', JSON.stringify(CvUploadObject))
    formData.append('files', formValues.cvUploadFile[0])

    await MandateService.cvUploadProfile(formData)
      .then((res) => {
        console.log('res', res)
        if(res?.status == 201){
          setCvUploadCreatedId(res?.data?.result?.data?.id)
          setUploadsuccess(true)
          setSuccessMessage('CV file uploaded successfully.')
          methods.reset()
        }else{
          // setErrorMessage(res?.data?.)
        }
        // dispatch(mandateUploadStatus({uploadMandate: !uploadMandateStatus}))
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message)
        setIsDisable(false)
      })
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <ModalStyle
          onClose={handlemodalclose}
          aria-labelledby='customized-dialog-title'
          open={open}
          fullWidth
          maxWidth='xs'
          className='mandate-modal-style cv-profile-Modal'
        >
          <ModalDialogTitle id='customized-dialog-title' onClose={handlemodalclose}>
            {props.title}
          </ModalDialogTitle>          
          <FormProvider {...methods}>
            <Box
              display='flex'
              flexDirection='column'
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <DialogContent sx={{ pb: 2 }}>
                <Box className="cvProfileScrollSect">
                <Typography variant='h6' component='h2' className='mandatedetails-header'>
                    Candidate Details
                  </Typography>
                  <AppTextField
                    focused
                    margin='dense'
                    label='Candidate Name'
                    type='text'
                    fullWidth
                    variant='outlined'
                    placeholder='Candidate Name'
                    borderRadius='10px'
                    className='mandate-modalform form-spaces'
                    borderColor={TERTIARY13}
                    name='candidateName'
                    size='small'
                    required
                  />
                  <AppTextField
                    focused
                    margin='dense'
                    label='Email'
                    type='text'
                    fullWidth
                    variant='outlined'
                    placeholder='Email'
                    borderRadius='10px'
                    className='mandate-modalform form-spaces'
                    borderColor={TERTIARY13}
                    name='cvUploadEmail'
                    size='small'
                    required
                  />
                  <Box className="cvProfileContactSect">
                    <AppTextField
                    name='cvUploadContact'
                    focused
                    size='small'
                    margin='dense'
                    id='clientspoc'
                    label='Contact'
                    type='number'
                    fullWidth
                    variant='outlined'
                    placeholder='Contact'
                    className='mandate-modalform form-spaces'
                    borderColor={TERTIARY13}
                    required
                  />
                  </Box>
                  <Box className="uploadCvSection">
                    <p>Upload CV <StarRateIcon/></p>
                    <input
                      type='file'
                      id='mandateFile'
                      {...methods.register('cvUploadFile')}
                      accept=".docx,.pdf"
                      name='cvUploadFile'
                    />
                    <label htmlFor='mandateFile' className='upload-custom-btn'>
                      <UploadIcon />
                      <span className='file-upload-text'>Upload Document</span>
                    </label>
                    <Box className='error-message'>{error?.message}</Box>
                    <Box className='helper-boxtext'>
                      {' '}
                      <HintsIcon />
                      <Box style={{marginLeft:"5px"}}>
                        Note {`.docx,.pdf`} Maximum size{' '}
                        25mb
                      </Box>
                    </Box>
                  </Box>
                  {PreView.length > 0 && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Tooltip title={PreView[0]?.name}>
                        <Grid item xs={10} className='file-preview-name'>
                          {PreView[0]?.name}
                        </Grid>
                      </Tooltip>
                      <Grid item xs={2}>
                        <IconButton
                          aria-label='delete'
                          color='error'
                          size='small'
                          onClick={() => {
                            methods.unregister('cvUploadFile')
                          }}
                        >
                          <DeleteIcon fontSize='inherit' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}

                  <AppTextField
                    focused
                    margin='dense'
                    label='Remarks'
                    type='text'
                    fullWidth
                    variant='outlined'
                    placeholder='Remarks'
                    borderRadius='10px'
                    className='mandate-modalform form-spaces'
                    borderColor={TERTIARY13}
                    name='cvRemarks'
                    size='small'
                    multiline={true}
                    rows={3}
                  />
                  {errorMessage && <Box className="cvUploadErrorMsg"><InfoIcon />{errorMessage}</Box>}
                </Box>
              </DialogContent>
              <DialogActions sx={{ mt: 5, mb: 2 }} className='action-btn-cls'>
                <AppButton
                  autoFocus
                  onClick={handlemodalclose}
                  type='button'
                  className='modal-cancel-btn'
                  width={'96px'}
                  bgcolor={TERTIARY3}
                  textColor={PRIMARY3}
                >
                  Cancel
                </AppButton>
                <AppButton
                  // onClick={handleClose}
                  autoFocus
                  type='submit'
                  bgcolor={PRIMARY2}
                  width={'124px'}
                  textColor={COLOR_WHITE}
                  className='modal-assign-btn'
                  disabled={isDisable}
                >
                  Upload
                </AppButton>
              </DialogActions>
            </Box>
          </FormProvider>
        </ModalStyle>
      </ThemeProvider>
      <Box>
        {/* <AppErrorHandlerModal
        open={uploaderror}
        content={errorMessage}
        handleClose={handlerrorClose}
        factor='Error'
        ButtonText='Close'
      >
        <></>
      </AppErrorHandlerModal> */}
        <AppErrorHandlerModal
          open={uploadsuccess}
          content={SuccessMessage}
          handleClose={handlsuccessClose}
          factor='Success'
          ButtonText='Close'
        >
          <></>
        </AppErrorHandlerModal>
      </Box>
    </>
  )
}
