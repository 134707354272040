import React, { useEffect, useMemo, useState } from "react";
import ColumnContainer from "./ColumnContainer";
import {
  rectIntersection,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import './KanbanView.scss'
import { Box } from "@mui/material";
import { getCandidateList, getCandidateKanbanData } from "../../../../services/CandidateService";
import _ from 'lodash'
import { getMandateStagesbyOrder } from "../../../../services/MandateService";
import { AppErrorHandlerModal } from "../../../AppComponents/AppErrorHandlerModal";



const MandateProfileKanban = ({
  mandateData,
  mandatelocationLists,
  setUpdateHireProp,
  setSelectedCandidate,
  setIsOpen,
  setFilteredStageList,
  isOpen,
  setDefaultRollback,
  mandatelocationIndex
 }: any) => {


  const [listData, setListData] = useState<any[]>([]);
  const [tempList , setTempList] =useState<any[]>([]);
  const [dragStartColumn, setDragStartColumn] = useState<any>(null)
  const [forwardRestriction, setForwardRestriction] = useState<boolean>(false)
  const [popActive , setPopActive] = useState<boolean>(false)
  const [activeCard, setActiveCard] = useState<any>(null)

  useEffect(() => {
    if(!isOpen){
      fetchCandidateList()
      setIsOpen(false)
    }
  }, [isOpen,mandatelocationIndex])

  const findColumn = (unique: string | null) => {
    if (!unique) {
      return null;
    }
    if (listData.some((c: any) => c.stageId == unique)) {
      return listData.find((c: any) => c.stageId == unique);
    }
    const id = String(unique);
    const itemWithColumnId = listData.flatMap((c: any) => {
      const columnId = c.stageId;
      return c.candidates?.map((i: any) => ({ itemId: i.id, columnId: columnId }));
    });
    const columnId = itemWithColumnId.find((i: any) => i.itemId == id)?.columnId;
    return listData.find((c: any) => c.stageId == columnId) ?? null;
  };


  const handleUpdateHirePopup = async (canData:any,stageId:any,candidateId:any ) => {

    const obj = {
      id: mandateData?.id,
      mlocationid: mandatelocationLists[mandatelocationIndex]?.id,
      mpctrackstatus: stageId,
      stageid: stageId,
      stageorder: canData?.stageOrder,
      statusid: canData?.statusId,
      statusorder: canData?.statusOrder,
      uniqueid: mandateData?.xphenoId,
      isend: canData?.isEnd,
    }
    setUpdateHireProp(obj)
    setSelectedCandidate(canData)
    setIsOpen(true)

    const manId = mandateData?.id
    const posId = mandatelocationLists[mandatelocationIndex]?.id
    const stageid = stageId
    const stageorder = canData?.stageOrder
    const isend = canData?.isEnd

    try {
      const res = await getMandateStagesbyOrder(manId, posId,candidateId, stageid, stageorder, isend)
      if (res?.status === 200) {
        const listData = res?.data?.result?.data ?? []
        setFilteredStageList(listData)

      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDragStart = (event:DragStartEvent) =>{
    setDragStartColumn(event?.active?.data?.current?.sortable?.containerId )
    setActiveCard(event?.active?.id)

  }

  const handleDragOver = async (event: DragOverEvent) => {
    const { active, over, delta } = event;
    const activeId = String(active?.id);
    let overId = over ? String(over.id) : null;
    const activeColumn = findColumn(activeId);
    if(activeId == overId){
      if(delta.x < 0){
        overId = String(activeColumn.stageId -1)
      }
  
    }
    const overColumn = findColumn(overId);
    if (!activeColumn || !overColumn || activeColumn == overColumn) {
      return null;
    }
    const card =activeColumn.candidates.filter((i: any) => i.id == activeId)[0]
   
    const activeItems = activeColumn.candidates;
    const overItems = overColumn.candidates;
    const activeIndex = activeItems.findIndex((i: any) => i.id == activeId);
    const overIndex = overItems.findIndex((i: any) => i.id == overId);
    tempList.forEach((elem:any)=>{
      elem.candidates.forEach((item:any)=>{
          if(item.id == card.id) {
            setDragStartColumn(item.stageId)
          }
      })
  })

  setListData((prevState) => {
    const newIndex = () => {
      const putOnBelowLastItem =
        overIndex == overItems.length - 1 && delta.y > 0;
      const modifier = putOnBelowLastItem ? 1 : 0;
      return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    };

    const result = prevState.map((c: any) => {
      if (c.stageId == activeColumn.stageId) {
        c.candidates = activeItems.filter((i: any) => i.id != activeId);
        return c;
      } else if (c.stageId == overColumn.stageId) {
        c.candidates = [
          ...overItems.slice(0, newIndex()),
          activeItems[activeIndex],
          ...overItems.slice(newIndex(), overItems.length)
        ];
        return c;
      } else {
        return c;
      }
    });
    return result
  });
   

   
  };
 

  const handleDragEnd = (event: DragEndEvent) => {

    const { active, over , delta}: any = event;
    const activeId = String(active.id);
    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);                    
    const card =activeColumn.candidates.filter((i: any) => i.id == activeId)[0]
    if(card?.isEnd){

      if (((dragStartColumn -1) == overColumn.stageId) &&(dragStartColumn != 0)) {
        setDefaultRollback(true)
        handleUpdateHirePopup(card,overColumn.stageId,active.id )
      }
      else{
        setIsOpen(null)
        if (dragStartColumn != overColumn.stageId) {
        setForwardRestriction(true)
        }
      }

    } else {
      if (overColumn.stageId && ((dragStartColumn + 1) == overColumn.stageId || (dragStartColumn - 1) == overColumn.stageId)){
        if((dragStartColumn - 1) == overColumn.stageId) {
          setDefaultRollback(true)
        }
        else {
          setDefaultRollback(false)
        }
        handleUpdateHirePopup(card,overColumn.stageId,active.id )
      }
      else{
        setIsOpen(null)
      }
    }
    setDragStartColumn(null)
    setActiveCard(null)

  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const fetchCandidateList = async () => {
    const queryParams = {
      profileType: "All",

    }
    const requestBody = {
      screenFlag:"mandateprofile",
      filterMandateId: mandateData?.id,
      mlocationId: mandatelocationLists[mandatelocationIndex]?.id,
    }
    const page = 1
    try {
      const res:any = await getCandidateKanbanData(requestBody, queryParams)

      setListData(res.data);
      setTempList(res.data)
      setDragStartColumn(null)

    } catch (error) {
      console.log(error)
    }
  }

 
  return (
    <Box
      className="kanbanBoardContainer"
    >
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragEnd={!popActive ?handleDragEnd :()=>{}}
        onDragOver={!popActive ?handleDragOver:()=>{}}
        onDragStart={!popActive ?handleDragStart:()=>{}}
      >
        <div className="DnDcontainer">
          {listData.map((column: any) => (
            <>
              <ColumnContainer
                key={column.stageId}
                id={String(column.stageId)}
                title={column.stageName}
                cards={column?.candidates}
                mandateData={mandateData}
                mandatelocationLists={mandatelocationLists}
                setUpdateHireProp={setUpdateHireProp}
                setSelectedCandidate={setSelectedCandidate}
                setIsOpen={setIsOpen}
                setDefaultRollback={setDefaultRollback}
                setFilteredStageList={setFilteredStageList}
                setPopActive={setPopActive}
                activeCard={activeCard}
                popActive ={popActive}
                mandatelocationIndex={mandatelocationIndex}

              ></ColumnContainer>
            </>
          ))}
        
        </div>

      </DndContext>
      <AppErrorHandlerModal
        open={forwardRestriction}
        content='forward stage movement is restricted'
        show={false}
        handleClose={()=>setForwardRestriction(false)}
        factor='Warning'
        ButtonText='Ok'
      >
        <></>
     
      </AppErrorHandlerModal>   
    </Box>
  );

}




export default MandateProfileKanban
