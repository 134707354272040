/* eslint-disable react/prop-types */
import { AppButton } from '../../AppComponents/AppButton';
import { Box, FormControl, InputLabel, MenuItem, Popover, Select, Typography,TextField, OutlinedInput, Snackbar, Alert, Checkbox } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import AppMandateDropdown, { MandateOptionTag } from '../../AppComponents/AppMandateDropdown/AppMandateDropdown';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warningfill.svg';
import { ReactComponent as AllocationCalenderIcon } from '../../../assets/icons/allocation-calender.svg'

import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import { ReactComponent as InfoCircleIcon } from '../../../assets/icons/info-circle.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import './AddMandate.scss'
import { getMandateLocation } from '../../../services/MandateService'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/mandates-list-calendar.svg'
import moment from 'moment';
import { mandateAllocate, UpdateMandateAllocation, unassignMandateAllocation, postBulkUpdate } from '../../../services/WorkManagementService'
import { useAppSelector } from '../../../store/hooks';


type Tprop = {
    candidateDetailsData:any;
    isPopupOpen: boolean;
    handlePopupClose: ()=> any;
    recruiterID: string;
    editAllocateData: any;
    isEdit: boolean;
    isUnassign: boolean;
    selectedMandateTag: any;
    path?:string;
    editCaseMandate?:any
}

type TFormProps = {
  selectedMandate : null | number;
  selectedLocations : number[];
  dueDate : Date;
}

const initialValues : TFormProps = {
    selectedMandate: null,
    selectedLocations: [],
    dueDate: new Date()
}



const AddMandatePopUp:React.FC<Tprop> = ({isPopupOpen, handlePopupClose, candidateDetailsData, recruiterID, editAllocateData, isEdit, isUnassign, selectedMandateTag, path,editCaseMandate }) => {
  const [selectedMandate, setSelectedMandate] = useState<any>(null)
  const [locationLists, setLocationLists] = useState<any[]>([])
  const [successPopup, setSuccessPopup] = useState<{isOpen:boolean, message:string}>({isOpen:false, message:""})
  const [failurePopup, setFailurePopup] = useState<{isOpen:boolean, message:string}>({isOpen:false, message:""})
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)
  const {
      handleSubmit,
      reset,
      control,
      formState:{errors},
      watch,
      setValue
    } = useForm<TFormProps>({defaultValues: initialValues});

    const manChange:any = watch("selectedMandate")

    const getMandateLocationList = async (manId:any)=> {
      const response = await getMandateLocation(manId,`?status=133,134&mandateBehaviour=${isEdit ? editCaseMandate?.mandateBehaviour : selectedMandate?.mandateBehaviour}`)
      if(response?.status === 200){
        const selectedCollaboartor = selectedMandate?.collaboratorPermission.filter((item:any) => item.collobatorId == userId)
        if(selectedCollaboartor?.length > 0){
          const selectedLocationId:any = []
          JSON.parse(selectedCollaboartor[0].locations).map((locItem:any) => {
            selectedLocationId.push(locItem.mlocationId)
          })
          const filterLocationList = response?.data?.result?.mandateLocations.filter((locFilter:any) => selectedLocationId.includes(locFilter.id))
          setLocationLists(filterLocationList)
        }else{
          setLocationLists(response?.data?.result?.mandateLocations)
        }
        
      }else{
        setLocationLists([])
      }
    }

  const handleAllocationSubmit = async (data:any) => {

    if(isUnassign){
      const unAssgObj = {
        allocationId: editAllocateData?.allocationId,
        mandateId: editAllocateData?.mandateId,
        allocatedUser: editAllocateData?.allocatedUser
      }
      try{
        const res = await unassignMandateAllocation(unAssgObj)
        if(res?.status === 200){
          setSuccessPopup({isOpen:true, message:"Mandate unassigned successfully"})
        }
      }catch(error:any){
        console.log(error, "server error")
        setFailurePopup({ isOpen: true, message: error?.response?.data?.error?.message || "Server Error" })
      }

    }else{
      let reqObj:any = {}
      if(path == "allocation"){
        reqObj = {
          "mandateId": data?.selectedMandate,
          "allocatedUser": recruiterID,
          "maLocations": data?.selectedLocations.map((locId:any) => {
            return {
              mandateId: data?.selectedMandate,
              mandateLocationId: locId
            }
          }),
          "dueDt": moment(data?.dueDate).format('YYYY-MM-DD')
        }
      }else{
        reqObj = {
          mandateId: data?.selectedMandate,
          allocatedUser: recruiterID,
          maLocations: data?.selectedLocations.map((locId:any) => {
            return {
              mandateId: data?.selectedMandate,
              mandateLocationId: locId
            }
          }),
          dueDt: moment(data?.dueDate).format('YYYY-MM-DD')
        }
      }
      
  
      if(isEdit) reqObj["allocationId"] = editAllocateData?.allocationId
  
      try{
        const res = isEdit ? path == "allocation" ? await postBulkUpdate({"mandateAllocation": [reqObj]}) :  await UpdateMandateAllocation(reqObj) : await mandateAllocate(reqObj)
        if(res?.status === 200){
          setSuccessPopup({isOpen:true, message: isEdit ? "Allocation updated successfully" : "Mandate assigned successfully"})
        }
      }catch(error:any){
        console.log(error, "server error")
        setFailurePopup({ isOpen: true, message: error?.response?.data?.error?.message || "Server Error" })
      }
    }

  }

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setFailurePopup({isOpen:false, message:""})
  }

  useEffect(()=>{
    if(manChange){
        setValue("selectedLocations", [])
        getMandateLocationList(manChange)
    }else{
      setValue("selectedLocations", [])
      setLocationLists([])
    }
    
  }, [manChange])

  useEffect(()=>{
    if(isEdit || isUnassign){
      const filterData = editAllocateData?.maLocations.filter((locItem:any) => locItem?.status == 64)
      const obj = {
        selectedMandate: editAllocateData?.mandateId,
        selectedLocations: filterData?.map((loc:any) => loc?.mandateLocationId),
        dueDate: new Date(editAllocateData?.dueDt)
      }
      reset(obj)
    }
  },[locationLists])
  
  const locationChnage = watch("selectedLocations")
  
  return (
    <div>
      <Popover open={isPopupOpen} className='add-position-popup'>
        <Box
          className='add-container'
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(handleAllocationSubmit)}
        >
          <Box className='header'>
            <Typography>{isEdit ? "Edit Allocation" : isUnassign ? "Unassign Allocation" : "Add Mandate"}</Typography>
            <CloseIcon style={{cursor: "pointer"}} onClick={handlePopupClose} />
          </Box>
          <Box className='body-conatiner'>
            {(!isEdit && !isUnassign) ? 
              <FormControl fullWidth required>
                <Controller
                  name='selectedMandate'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Mandate is required',
                    },
                  }}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <AppMandateDropdown
                      label='Select Mandate'
                      required={true}
                      value={selectedMandate}
                      onChange={(e: any) => {
                        setSelectedMandate(e)
                        onChange(e?.value)
                      }}
                    />
                  )}
                />
                {errors?.selectedMandate && (
                  <span className='reminder_text-danger select-danger-text' role='alert'>
                    {errors?.selectedMandate?.message as string}
                  </span>
                )}
              </FormControl> : 
              <div style={{width:"270px"}} >
                <MandateOptionTag
                  option={{
                    title: selectedMandateTag?.title,
                    mandateId: selectedMandateTag?.mandateId,
                    clientName: selectedMandateTag?.clientName || "-",
                    createdByName: selectedMandateTag?.createdByName
                  }} 
                  className="man-option-tag"
                />
              </div>
            }
            <FormControl fullWidth required>
              <InputLabel>Location</InputLabel>
              <Controller
                name='selectedLocations'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Location is required',
                  },
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Select
                    label='Location'
                    disabled={isUnassign}
                    multiple
                    value={value}
                    onChange={(e:any) => {
                      onChange(e.target.value)
                    }}
                    className='location-mandate-dropdown'
                    labelId='location-select-label'
                  >
                    {locationLists?.length &&
                      locationLists?.map((loc: any, index: number) => (
                        <MenuItem disabled={(loc?.filledJoCount >= loc?.jobOrderCount)} key={index} value={loc?.id} >
                          <Checkbox checked={value.indexOf(loc?.id) > -1} />
                          {loc?.location}
                        </MenuItem>
                      ))}
                    {locationLists?.length === 0 && (
                      <MenuItem disabled={true}>No Location</MenuItem>
                    )}
                  </Select>
                )}
              />
              {errors?.selectedLocations && (
                <span className='reminder_text-danger select-danger-text' role='alert'>
                  {errors?.selectedLocations?.message as string}
                </span>
              )}
              <Box className="selectedLocationJobOrder">
              {locationLists.map((locItem:any) => {
                return(
                  <>
                  {locationChnage.includes(locItem?.id) && <p>{locItem.location} <span>{`(${locItem.openJobOrders} / ${locItem.jobOrderCount})`}</span></p>}
                  </>
                )                
              })}
            </Box>
            </FormControl>            
            <FormControl fullWidth required>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name='dueDate'
                control={control}
                rules={{
                  required: {
                      value: true,
                      message: "Date is required",
                  }
                }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label='Date'
                    disabled={isUnassign}
                    className='date-picker'
                    value={value}
                    inputFormat='DD MMM YYYY'
                    onChange={(e: any) => {
                      onChange(e.$d)
                    }}
                    components={{
                      OpenPickerIcon: (props: any) => (
                        <AllocationCalenderIcon style={{ height: 20, width: 20 }} {...props} />
                      ),
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        name='receivedOn'
                        {...params}
                        required
                      />
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            </FormControl>
          </Box>
          <Box className='footer'>
            <AppButton
              label='Cancel'
              bordercolor='#E5E8EB'
              bgcolor='#E5E8EB'
              size='large'
              type='button'
              sx={{ color: '#000;', backgroundColor: '#000;', p: 1 }}
              onClick={handlePopupClose}
            />
            <AppButton
              label={isEdit ? 'Save' : isUnassign ? 'Unassign' : 'Add'}
              bordercolor={'#002882'}
              bgcolor={'#002882'}
              size='large'
              type='submit'
              className='add-btn'
              sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
            />
          </Box>
        </Box>
      </Popover>
      <AppErrorHandlerModal
        open={successPopup?.isOpen}
        content={successPopup?.message}
        handleClose={() => {
          setSuccessPopup({ isOpen: false, message: '' })
          handlePopupClose()
        }}
        factor='Success'
        ButtonText='Done'
      >
        <></>
      </AppErrorHandlerModal>
      <Box>
        <Snackbar
        open={failurePopup?.isOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        >
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} variant='filled'>
            {failurePopup?.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  )
}

export default AddMandatePopUp