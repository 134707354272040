import React, { useEffect, useState } from "react";
import CloseIcon from "./Close.svg"
import "./styles.scss"

import { Button } from "@mui/material";

type productProps = {
  setDialog: (data: boolean) => void
  popOverSkills: string[];
  skillKeywordsSelected: string[];
  setSkillKeywordsSelected: (data: string) => void
}

const AddSkillDialogbox: React.FC<productProps> = ({ setDialog, popOverSkills, skillKeywordsSelected, setSkillKeywordsSelected }) => {
  console.log(popOverSkills, "popOverSkills")
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>(skillKeywordsSelected.filter((x: string) => x !== ""))

  useEffect(() => {
    console.log("selectedKeywords", selectedKeywords)
  }, [selectedKeywords])

  return (
    <div className="bulk-uploader-result-page" >
      <div className="add-skil-box">

        <div className="header-wrapper">
          <img className="close-icon" src={CloseIcon} onClick={() => setDialog(false)} alt={"Close"} />
          <div className="header-bar">
            <p>Skill Keywords</p>
          </div>
        </div>
        <div className="skill-wrapper">
          <div className="brand-wrapper">
            {popOverSkills?.map((skill: string, index: number) => {
              return (
                <div
                  key={index}
                  className={selectedKeywords?.some((x: string) => x === skill) ? "skill-style-selected" : "skill-style"}
                  onClick={() => {
                    if (selectedKeywords?.some((x: string) => x === skill)) setSelectedKeywords(selectedKeywords.filter((x: string) => x !== skill))
                    else setSelectedKeywords([...selectedKeywords, skill])
                  }}>
                  {skill}
                </div>
              )
            })}
          </div>
        </div>
        <div className='cancel-save-btn'>
          <Button style={{ textTransform: "none" }} onClick={() => setDialog(false)} className='cancel-btn'>
            Cancel
          </Button>
          <Button style={{ textTransform: "none" }} className='save-btn' onClick={() => {
            setDialog(false);
            setSkillKeywordsSelected(selectedKeywords.join(","))
          }}>
            Add
          </Button>
        </div>
      </div>
    </div >
  )
}

export default AddSkillDialogbox;
