import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../Breadcrumb';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Checkbox, FormControl, InputLabel, Input, InputAdornment, OutlinedInput, MenuItem, Select, Pagination, Tooltip, capitalize } from "@mui/material"
import { ReactComponent as SortUpDown } from '../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../assets/icons/sort-down.svg'
import { ReactComponent as NoResultFound } from '../../assets/icons/no-result-found.svg'
import RoleListEdit from "../../assets/icons/userListEdit.svg"
import { useNavigate } from 'react-router-dom';
import { getAllRoleList, getRoleDetail } from '../../services/UserService';
import { convertToDisplayDateTime } from '../../utils/date-utils';
import RoleAccessFilter from './RoleAccessFilter';
import { useAppSelector } from '../../store/hooks';

const RoleAccess = () => {
    const [sortColumn, setSortColumn] = useState<any>()
    const [paginationCountSize, setPaginationCountSize] = useState({
        pageSize: 10,
        pageCount: 1
      })
    const { roleId } = useAppSelector((state) => state.UserDetail)
    const [sortingTableHead,setSortingTableHead] = useState<any>({
        "id": "",
        "role_name": "",
        "created_at": "",
        "updated_at": "",
        "last_modified_by": "",
        "status": ""
    })
    const [roleList, setRoleList] = useState([])
    const [roleListInfo, setRoleListInfo] = useState<any>({})
    const navigate =useNavigate()
	const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [roleListFilter,setRoleListFilter] = useState<any>({})
    const [pageSize, setPageSize] = useState(10)
    const [pageCount, setPageCount] = useState(1);

    const getRoleDetailInfo = async (roleId:string) => {
        const res:any = await getRoleDetail({
            "roleId": roleId
        })
        if(res.status == 200){
            navigate(`/viewRoledetails`,{state:{roleDetail: res.data.result.data.role,status:"create"}})
        }
    }
    const roleEditList = async (roleId:string) => {
        const res:any = await getRoleDetail({
            "roleId": roleId
        })
        if(res.status == 200){
            navigate(`/createrole`,{state:{roleDetail: res.data.result.data.role,status:"edit"}})
        }
    }
    
    const getAllRoleListApi = async () => {
        const sortingTableHeadObj = JSON.parse(JSON.stringify(sortingTableHead));
        const selectedSort:any[] = []
        Object.keys(sortingTableHeadObj).map((item:any) => {
            if(sortingTableHeadObj[item].length > 0){
                selectedSort.push({
                    key: item,
                    value: sortingTableHeadObj[item]
                })
            }
        })
        const roleObj = {
            "keyword":roleListFilter.keyword,
            "status":roleListFilter?.roleStatus,
            "sortDto":{
                "sortColumn":selectedSort.length > 0 ? selectedSort[0]?.key:"",
                "sortDirection":selectedSort.length > 0 ? selectedSort[0]?.value:""
            },
            "pageSize":paginationCountSize.pageSize,
            "pageNo":paginationCountSize.pageCount
        }
        try{
          const res:any = await getAllRoleList(roleObj)
          setRoleList(res.data.result?.list)
          setRoleListInfo(res.data.result)
          setPageSize(res?.data?.result.pageSize)
          setPageCount(res?.data?.result.totalPages)
        }
        catch(error:any){
            console.log(error)        
          }
    }

    const sortTableHeadHandler = (tableHead:string) => {
        const sortTableObj = JSON.parse(JSON.stringify(sortingTableHead));
        const selectedTableHead = sortTableObj[tableHead]
        Object.keys(sortTableObj).map((item:any) => {
            sortTableObj[item] = "" 
        })
        sortTableObj[tableHead] = selectedTableHead == "" ? "Asc" : selectedTableHead == "Asc" ? "Desc" : "";
        setSortingTableHead(sortTableObj)
    }

    useEffect(() => {
        getAllRoleListApi()
    },[sortingTableHead,roleListFilter,paginationCountSize])

    const updatePagination = (paginationKey:any, changeValue:any) => {
        setPaginationCountSize(prevState => ({...prevState,[paginationKey]: changeValue}))
    }
    
    const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }
    
    return (
        <div>
            <Breadcrumb title='Role & Access' />
            <Box>
				<RoleAccessFilter totalList={roleListInfo?.totalRecords} setRoleListFilter={setRoleListFilter}/>
				<Box className="reportTableContSect">
					<TableContainer className="submissionReportContainer roleListTableSect" component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									{/* <TableCell>
										<Checkbox
											// onChange={}
											checked={false}
											className='search-result-item-checkbox'
										/>
									</TableCell> */}
									
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("id")}>
                                        {"Role Id"}
                                        {sortingTableHead && sortingTableHead["id"].length > 0 ? (sortingTableHead["id"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("role_name")}>
                                        {"Role Name"}
                                        {sortingTableHead && sortingTableHead["role_name"].length > 0 ? (sortingTableHead["role_name"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("created_at")}>
                                        {"Created Date"}
                                        {sortingTableHead && sortingTableHead["created_at"].length > 0 ? (sortingTableHead["created_at"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("updated_at")}>
                                        {"Last Modified Date"}
                                        {sortingTableHead && sortingTableHead["updated_at"].length > 0 ? (sortingTableHead["updated_at"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("last_modified_by")}>
                                        {"Last Modified By"}
                                        {sortingTableHead && sortingTableHead["last_modified_by"].length > 0 ? (sortingTableHead["last_modified_by"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("status")}>
                                        {"Role Status"}
                                        {sortingTableHead && sortingTableHead["status"].length > 0 ? (sortingTableHead["status"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div>
                                        {"ACTION"}
                                    </div>
                                </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
                                <>
                                {roleList.length > 0 ? <>
                                {roleList.map((roleList:any) => {
                                    return (
                                        <>
                                        <TableRow>
                                            <TableCell><Tooltip title={roleList?.id}><div>{roleList?.id}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={roleList?.role_name}><div onClick={() => getRoleDetailInfo(roleList?.id)}>{roleList?.role_name}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={roleList?.created_at != null ? convertToDisplayDateTime(roleList?.created_at) : "-"}><div>{roleList?.created_at != null ? convertToDisplayDateTime(roleList?.created_at) : "-"}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={roleList?.updated_at != null ? convertToDisplayDateTime(roleList?.updated_at) : "-"}><div>{roleList?.updated_at != null ? convertToDisplayDateTime(roleList?.updated_at) : "-"}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={roleList?.last_modified_by != null ? roleList?.last_modified_by : "-"}><div>{roleList?.last_modified_by != null ? roleList?.last_modified_by : "-"}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={roleList?.status_name != null ? capitalize(roleList?.status_name) : "-"}><div>{roleList?.status_name != null ? capitalize(roleList?.status_name) : "-"}</div></Tooltip></TableCell>
                                            <TableCell>{roleList.role_slug != "Admin" && <Tooltip title={"Edit Role"}><img onClick={() => roleEditList(roleList?.id)} src={RoleListEdit} /></Tooltip>}</TableCell>
                                        </TableRow>
                                        </>
                                    )
                                })}</>:renderEmptySearchBox()}
                                </>
							</TableBody>
						</Table>
					</TableContainer>
					<Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={pageSize}
									label="Age"
									onChange={(event:any) => {setDefaultPageCount(1);setPageSize(event.target.value);updatePagination("pageSize",event.target.value)}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={pageCount} page={defaultPageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setDefaultPageCount(pageNumber);updatePagination("pageCount",pageNumber)}}/>
					</Box>
					</Box>
				</Box>
        </div>
    );
};

export default RoleAccess;