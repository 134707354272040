import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Checkbox, FormControl, InputLabel, Input, InputAdornment, OutlinedInput, MenuItem, Select, Pagination, Tooltip } from "@mui/material"
import TablePagination from '@mui/material/TablePagination';
import { models } from 'powerbi-client';
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/search.svg'
import { ReactComponent as SortUpDown } from '../../../../../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../../../../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../../../../../assets/icons/sort-down.svg'
import { ReactComponent as NoResultFound } from '../../../../../../assets/icons/no-result-found.svg'
import './HiringDemadFilter.scss'
import { getPowerBiToken } from '../../../../../../services/CandidateService';
import { AccountCircle } from "@mui/icons-material";
import {ReportkManagementService} from "../../../../../../services/ReportListService"
import { convertToDisplayDate, convertToDisplayDateTime, convertToDisplayDateReport } from '../../../../../../utils/date-utils'
import { MandateService, dropDownData, dropDownLocation, getAllListMandates, getDepartmentRoles, getIndustryData, getMandateLocation, getbranchlocation, getprimaryskill } from "../../../../../../services/MandateService";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { IMaskInput } from 'react-imask';
import { useLocation, useNavigate } from "react-router";
import { reportFilterLoadData } from "../../../../../../features/filters/FilterReducer";
import { dashoardClient } from "../../../../../../services/DashboardService";
import { getBussinessUnit, getSystemConfig } from "../../../../../../services/UserService";
import { HiringDemandListServiceApi } from "../../../../../../services/WidgetListservice/HiringDemandListService";
import HiringFilterComponent from "./Filters/Filter";


interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
  }

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
	function TextMaskCustom(props, ref) {
	  const { onChange, ...other } = props;
	  return (
		<IMaskInput
		  {...other}
		  mask="0000-00-00"
		  definitions={{
			'#': /[1-9]/,
		  }}
		  inputRef={ref}
		  onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
		  overwrite
		/>
	  );
	},
  );

const HiringDemandFilterApp = ({exportdatareqest, setReportSubmissionAPIData,HirningDemandParamsDetails,setHirningDemandParamsDetails,setApplyBtnActive,candidates}:any) => {
	
	const [reportData, setReportData] = useState<any>([])
	const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(10)
	const [defaultPageCount, setDefaultPageCount] = useState(1)
	const [tableKeyWordSearch, setTableKeyWordSearch] = useState("");
	const [tableHeadKeyWordSearch, setTableHeadKeyWordSearch] = useState("");
	const [mLocationList, setMLocationList] = useState([])
	const dispatch = useAppDispatch()
	const navigate =useNavigate()
	const [reportFilterPreload, setReportFilterPreload] = useState({})
	const location = useLocation()
	const candidaterouteState = location?.state
	const reportDataCandidateDetails = useAppSelector((state) => state.filtersReducer.reportFiler)
	const [resetFilter, setResetFilter] = useState(false)

	const [reportClientAPIList,setReportClientAPIList] = useState([])
	const [reportBussinessAPIList,setReportBussinessAPIList] = useState([])
    
	const [Coelist,setCoelist] = useState<any>([]);
	const [ClientList, setClientList] = useState<any>([]);
	const [businessUnitList, setBusinessUnitList] = useState<any>([]);
	const [jobModeList, setjobModeList] = useState<any>([]);
	const [buheadList, setbuheadList] = useState<any>([]);
	const [industryList, setindustryList] = useState<any>([]);
	const [mandateSpocList, setMandateSpocList] = useState<any>([]);
	const [mandateTypeList, setMandateTypeList] = useState<any>([]);
	const [JobtitleList, setJobtitleList] = useState<any>([]);
	const [JobLocationList, setJobLocationList] = useState<any>([]);
	const [rolesdataList, setrolesdataList] = useState<any>([]);
	const [rolesList, setrolesList] = useState<any>([]);
	const [primaryskillList,setprimaryskillList] = useState<any>([]);
	
	const [industryData, setIndustryData] = useState<any>([])
	const [deptData, setDeptData] = useState<any>([])
	const userDetaiId = useAppSelector((state) => state.UserDetail.userId)
	
	  function prepend(value:any, array:any) {
		const newArray = array.slice();
		newArray.unshift(value);
		return newArray;
	  }


	  const bussinessUnitHierarchy = async () => {
		const response = await ReportkManagementService.getBussinessHierarchy()
		console.log("dependancy data :",response?.data)
		setReportBussinessAPIList(response?.data)
	  }
	  const getClientList = async () => {
		await MandateService.getClientname()
		.then((res: any) => {
		  let options = _.map(res.data.result.list[0], (data: any) => {
			return {
			  label: _.get(data, 'clientName'),
			  value: _.get(data, 'id'),
			}
		  })
		  
		  options = prepend({label: "All",value: "All"}, options)
		  setReportClientAPIList(options)
		})
		.catch((error: any) => {
		  console.log(error.message)
		})  
	  }


	  const fetchbranchLocations = async () => {
		await getbranchlocation()
		.then((res: any) => {
			const filteredData = _.filter(res.data, (data: any) => {
			   return data.branchName !== "Participated" && data.branchName !== "Assigned" ;
			});
   
			const options = _.map(filteredData, (data: any) => {
				return {
					 label: _.get(data, 'branchName'),
					 value: _.get(data, 'id'),
				 }
			 });
   
		   // You can prepend an "All" option if needed
		   // options = [{ label: "All", value: "All" }, ...options];
   
			setCoelist(options);
			console.log("My COE:", options);
		})
		.catch((error: any) => {
		   console.log(error.message);
		});
	}


	

	  

  const getClientDataApi = async () => {
    try {
        const response = await dashoardClient();
        if (response?.data?.result && response?.data?.result.list.length > 0) {
            const clients = response.data.result.list[0].map((client: any) => client.clientName);
            setClientList(clients);
        }
    } catch (error: any) {
        console.log(error);
    }
};

const getBussinessUnitDetailsApi = async () => {
    try {
        // const res: any = await getBussinessUnit();
        // const businessUnits = res?.data?.map((item: any) => item?.businessUnitName);
        // setBusinessUnitList(businessUnits);

		await getBussinessUnit()
		.then((res: any) => {
			let Bussnussuinitoptions = _.map(res?.data, (data: any) => {
			  return {
				label: _.get(data, 'businessUnitName'),
				value: _.get(data, 'id'),
			  }
			})
			
			Bussnussuinitoptions = prepend({label: "All",value: "All"}, Bussnussuinitoptions)
			setBusinessUnitList(Bussnussuinitoptions)
		})
		
    } catch (error: any) {
        console.log(error);
    }
};


const getprimaryskillDetailsApi = async () => {

	const reqObj = {
		"keyword": null,
		"pageNumber": 1,
		"pageSize" : Number.MAX_SAFE_INTEGER
	} 

	try {
	  await getprimaryskill(reqObj)
		.then((res: any) => {
			let primaryskilloptions = _.map(res?.data, (data: any) => {
			  return {
				label: data,
				value: data,
			  }
			})
			
			primaryskilloptions = prepend({label: "All",value: "All"}, primaryskilloptions)
			setprimaryskillList(primaryskilloptions);
		})
      

	} catch (error) {
	  console.log(error);
	}
  };
  



const getJobtitleListApi = async () => {
	try {
		await getAllListMandates().then((res: any) => {
			const dataItems = res?.data?.result?.list || []
			if (dataItems) {
				const filterData = dataItems.filter((item: any) => item?.status !== 'draft')
				
				// Use a Set to track unique job titles
				const uniqueJobTitles = new Set();
				const options :any = [];

				filterData.forEach((data: any) => {
					const jobTitle = _.get(data, 'jobtitle');
					const mandateCode = _.get(data, 'mandatecode');

					// Check if the job title is already in the set
					if (!uniqueJobTitles.has(jobTitle)) {
						uniqueJobTitles.add(jobTitle);
						options.push({
							label: jobTitle,
							value: mandateCode,
						});
					}
				});
				
				setJobtitleList([{label: "All",value: "All"},...options]);
			} else {
				setJobtitleList([]);
			}
		});
	} catch (error: any) {
		console.log(error);
	}
};


const fetchLocations = async () => {
	const reqObj = {
	  "filters": [
		  {
			  "field": "parentId",
			  "operator": "equals",
			  "type": "string",
			  "value": "0"
		  }
	  ],
	  "page": {
		  "pageNo": -1,
		  "pageSize": 10
	  },
	  "sorting": {
		  "direction": "ASC",
		  "field": "createdAt"
	  }
  }
	try{
	  const res:any = await dropDownLocation(reqObj)
	  const options = _.map(res.data.result.list, (data: any) => {
		return {
		  label: _.get(data, 'locationName'),
		  value: _.get(data, 'id')
		}})
		setJobLocationList([{label: "All",value: "All"},...options]);

	} catch {
		setJobLocationList([]);
	}
  }


  const getindustrylistApi = async () => {
	const reqObj = {
	  "filters": [],
	  "page": {
		"pageNo": 0,
		"pageSize": 10
	  },
	  "sorting": {
		"direction": "ASC",
		"field": "createdAt"
	  }
	}
	try {
	  await getIndustryData(reqObj)
	  .then((res: any) => {
		let industrrtlistoptions = _.map(res?.data?.result?.list, (data: any) => {
		  return {
			label: _.get(data, 'industryCategoryName'),
			value: _.get(data, 'id'),
		  }
		})
		industrrtlistoptions = prepend({label: "All",value: "All"}, industrrtlistoptions)
	  	setindustryList(industrrtlistoptions)
	});

	//   setindustryList(industRes.data.result.list);

	  await getDepartmentRoles(reqObj)
	  .then((res: any) => {
		console.log("job option :",res?.data?.result?.list)
		let rolelistoptions = _.map(res?.data?.result?.list?.map((departmentRoles:any)=>departmentRoles), (data: any) => {
		  return {
			label: _.get(data, 'depName'),
			value: _.get(data, 'id'),
		  }
		})
		rolelistoptions = prepend({label: "All",value: "All"}, rolelistoptions)
		// setrolesdataList(rolelistoptions)
	})

	//   setDeptData(departRes.data.result.list);
	} catch {
	  setIndustryData([]);	
	}
  }





const getsomedropdownlist = async () => {
	const reqObj = {
        businessUnit: false,
        appConfiguration: false,
        dropdownRequest: [
            { drpCode: "XPN0001", drpName: "MANDATE_TYPE" },
            { drpCode: "XPN0002", drpName: "SHIFT_TYPE" },
            { drpCode: "XPN0003", drpName: "Job_Type" },
            { drpCode: "XPN0004", drpName: "Job_Mode" },
            { drpCode: "XPN0005", drpName: "SPOC_Type" },
            { drpCode: "XPN0006", drpName: "Permissions" }
        ]
    };
	await dropDownData(reqObj)
	.then((res: any) => {
	  let Jobmodeoptions = _.map(res?.data?.result?.dropdownData?.Job_Mode, (data: any) => {
		return {
		  label: _.get(data, 'domValue'),
		  value: _.get(data, 'id'),
		}
	  })
	  
	  Jobmodeoptions = prepend({label: "All",value: "All"}, Jobmodeoptions)
	  setjobModeList(Jobmodeoptions)


	  let Mandatetypeoptions = _.map(res?.data?.result?.dropdownData?.MANDATE_TYPE, (data: any) => {
		return {
		  label: _.get(data, 'domValue'),
		  value: _.get(data, 'id'),
		}
	  })

	  Mandatetypeoptions = prepend({label: "All",value: "All"}, Mandatetypeoptions)
	  setMandateTypeList(Mandatetypeoptions)


	  let Mandatespocoptions = _.map(res?.data?.result?.dropdownData?.SPOC_Type, (data: any) => {
		return {
		  label: _.get(data, 'domValue'),
		  value: _.get(data, 'id'),
		}
	  })

	  Mandatespocoptions = prepend({label: "All",value: "All"}, Mandatespocoptions)
	  setMandateSpocList(Mandatespocoptions)


	})
	.catch((error: any) => {
	  console.log(error.message)
	})  
};


const getAppConfig = async () => {
	const reqObj = {
	  businessUnit: true,
	  appConfiguration: true,
	  dropdownRequest: []
	}
  
	try {
	  const res = await getSystemConfig(reqObj);
	  
	  if (res.status === 200) {
		// Transform the response data to extract roleName and id
		const rolelistoptions = res?.data?.result?.roles?.map((item: any,index:number) => {
		  console.log("roleistdataList", item?.roleName);
		  return {
			label: item?.roleName, // Extract roleName for the label
			value: item?.id,        // Extract id for the value
		  }
		});
  
		console.log("roleistdata", rolelistoptions);
		
		setrolesdataList(rolelistoptions);
  
		// If you also need a separate list of just role names
		const roles = res?.data?.result?.roles?.map((item: any) => item?.label?.roleName);
		setrolesList(roles);
	  }
	} catch (error) {
	  console.log(error, "config api error");
	}
  }

  useEffect(()=>{
    getClientDataApi()
    getBussinessUnitDetailsApi()
    bussinessUnitHierarchy()
    getsomedropdownlist()
    getAppConfig()
	// getJobtitleListApi()
	// fetchLocations()
	getindustrylistApi()
	fetchbranchLocations()
	getprimaryskillDetailsApi()
  },[])



	  const fetchData = async () => {
		const reqObj = {
		  "filters": [],
		  "page": {
			"pageNo": 0,
			"pageSize": 10
		  },
		  "sorting": {
			"direction": "ASC",
			"field": "createdAt"
		  }
		}
		try {
		  const industRes: any = await getIndustryData(reqObj)	
		  setIndustryData(industRes.data.result.list);
		  const departRes:any = await getDepartmentRoles(reqObj)
		  setDeptData(departRes.data.result.list);
		} catch {
		  setIndustryData([]);	
		}
	  }
  
  
	  useEffect(() => {
		getClientList()
		bussinessUnitHierarchy()
		// fetchData()
	  },[])

	  const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }

	const [values, setValues] = React.useState({
		textmask: '',
		numberformat: '1320',
	  });

	const reportCandidateName = (candidateId:string) => {
		dispatch(reportFilterLoadData({reportFiler: {...reportDataCandidateDetails, ...{reportList: reportData,pageCount: pageCount,defaultPageCount: defaultPageCount, pageSize: pageSize}}}))
		navigate(`/candidate/${candidateId}`, {state:"submissionReportCandidate"})
	}

	useEffect(() => {
		if(candidaterouteState == "submissionReportCandidate"){
			navigate(location.pathname, {});
			setReportData(reportDataCandidateDetails?.reportList)
			setReportFilterPreload(reportDataCandidateDetails)
			setPageCount(reportDataCandidateDetails?.pageCount);
			setPageSize(reportDataCandidateDetails?.pageSize)
			setDefaultPageCount(reportDataCandidateDetails?.defaultPageCount)
		}else{
			// dispatch(reportFilterLoadData({reportFiler: {}}))
		}
	},[candidaterouteState])

	useEffect(() => {
		if(resetFilter){
			setPageCount(0);
			setPageSize(10)
			setDefaultPageCount(1)
			setResetFilter(false)
		}		
	},[resetFilter])

	

	return (
		< >
			<Box>
				<HiringFilterComponent exportdatareqest={exportdatareqest} HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} reportData={reportData} setTableKeyWordSearch={setTableKeyWordSearch} Coelist={Coelist} reportClientAPIList={reportClientAPIList} reportBussinessAPIList={reportBussinessAPIList} jobModeList={jobModeList} mandateSpocList={mandateSpocList} mandateTypeList={mandateTypeList} businessUnitList={businessUnitList} JobtitleList={JobtitleList} JobLocationList={JobLocationList} industryList={industryList} rolesdataList={rolesdataList} primaryskillList={primaryskillList} setReportSubmissionAPIData={setReportSubmissionAPIData} mLocationList={mLocationList} setMLocationList={setMLocationList} reportFilterPreload={reportFilterPreload} setResetFilter={setResetFilter} setApplyBtnActive={setApplyBtnActive} candidates={candidates}/>
			</Box>

		</>
	)

}

export default HiringDemandFilterApp