import { createSlice } from '@reduxjs/toolkit'

export interface FilterState {
  businessUnitTemplate: any;
  appConfiguration: any;
  dropdownData: any;
  chatGptShow?:string;
  smartFlowIncomingData?:number,
  confettiEffectTimerData?:number,
  isWelcomePopupEnabled?:boolean,
  welcomePopupDisplayTimer?:number,
  welcomePopupContent?:any,
  welcomePopupClose?:any,
  welcomeRotateOffCase?:boolean,
  hideMonthlyCardStatus?: boolean,
  welcomeScreenAnimationEnabled?:boolean,
  JoStatusList?: any,
  toursConfigList?:any,
  buElementAccessRoles?:any
}

const initialConfigState: FilterState = {
  businessUnitTemplate: [],
  appConfiguration: [],
  dropdownData: [],
  chatGptShow:"",
  smartFlowIncomingData: 5000,
  confettiEffectTimerData: 10000,
  isWelcomePopupEnabled: false,
  welcomePopupDisplayTimer: 0,
  welcomePopupContent: [],
  welcomePopupClose: [],
  welcomeRotateOffCase: true,
  hideMonthlyCardStatus: false,
  welcomeScreenAnimationEnabled: false,
  JoStatusList: [],
  toursConfigList: [],
  buElementAccessRoles:[]
}

const systemConfigSlice = createSlice({
    name: 'SystemConfiguration',
    initialState: initialConfigState,
    reducers: {
      setSysTemplate: (state, action) => {
        return {
          ...state,
          businessUnitTemplate: action.payload.businessUnitTemplate,
          chatGptShow: action?.payload?.chatGptShow?.length && action?.payload?.chatGptShow[0]?.configValue,
          systemConfigData: action?.payload?.systemConfigData,
          googleIntegrationConfig: action?.payload?.googleIntegrationConfig,
          welcomeConfigData: action?.payload?.welcomeConfigData,
          uploadFileConfigData: JSON.parse(action?.payload?.uploadFileConfigData[0]?.configValue),
          smartFlowIncomingData: JSON.parse(action?.payload?.smartFlowIncomingData[0]?.configValue),
          confettiEffectTimerData: JSON.parse(action?.payload?.confettiEffectTimerData[0]?.configValue),
          isWelcomePopupEnabled: JSON.parse(action?.payload?.isWelcomePopupEnabled[0]?.configValue),
          welcomePopupDisplayTimer: JSON.parse(action?.payload?.welcomePopupDisplayTimer[0]?.configValue),
          welcomePopupContent: JSON.parse(action?.payload?.welcomePopupContent[0]?.configValue),
          welcomePopupClose: JSON.parse(action?.payload?.welcomePopupClose[0]?.configValue),
          welcomeScreenAnimationEnabled: JSON.parse(action?.payload?.welcomeScreenAnimationEnabled[0]?.configValue),
          JoStatusList : JSON.parse(action?.payload?.JoStatusList[0]?.configValue),
          toursConfigList: JSON.parse(action?.payload?.toursConfigList[0]?.configValue)[0],
          buElementAccessRoles: action?.payload?.buElementAccessRoles[0]?.configValue.split(",")
        }
      },
      resetSysConfig: (state) => {
        return{
          businessUnitTemplate: [],
          appConfiguration: [],
          dropdownData: [],
          welcomeRotateOffCase : true,
          hideMonthlyCardStatus: false
        }
      },
      offRotateScreen: (state) => {
        return{
          ...state,
          welcomeRotateOffCase : false
        }
      },
      hideMonthlyCard: (state) => {
        return{
          ...state,
          hideMonthlyCardStatus : false
        }
      },
      showMonthlyCard: (state) => {
        return{
          ...state,
          hideMonthlyCardStatus : true
        }
      }
    }
})

export const { setSysTemplate, resetSysConfig, offRotateScreen, hideMonthlyCard,showMonthlyCard } = systemConfigSlice.actions
export default systemConfigSlice.reducer