import jwtDecode from "jwt-decode"
import _ from "lodash"
import { getAccessToken } from "../services/http-common"

const VERIFY_USER_ID_STORAGE_KEY = "verify-user-id";
const RESET_USER_ID_STORAGE_KEY = "reset-user-id";
const OTP_STORAGE_KEY = "reset-user-otp"

export const getLoggedInUserId = () => {
    const tokenData: any = jwtDecode(getAccessToken())
    return _.get(tokenData, 'sub', '')
}

export const setVerifyEmailUserId = (userId: string) => {
    sessionStorage.setItem(VERIFY_USER_ID_STORAGE_KEY, userId);
}

export const removeVerifyEmailUserId = () => {
    sessionStorage.removeItem(VERIFY_USER_ID_STORAGE_KEY);
}

export const getVerifyEmailUserId = () => {
    return sessionStorage.getItem(VERIFY_USER_ID_STORAGE_KEY);
}

export const isVerifyEmailSessionEnabled = () => {
    return !!getVerifyEmailUserId();
}

export const setResetPasswordUserId = (userId: string | null) => {
    sessionStorage.setItem(RESET_USER_ID_STORAGE_KEY, userId as string);
}

export const removeResetPasswordUserId = () => {
    sessionStorage.removeItem(RESET_USER_ID_STORAGE_KEY);
}

export const getResetPasswordUserId = () => {
    return sessionStorage.getItem(RESET_USER_ID_STORAGE_KEY);
}

export const isResetPasswordSessionEnabled = () => {
    return !!getResetPasswordUserId();
}

export const setResetPasswordOtp = (otp: string | null) => {
    sessionStorage.setItem(OTP_STORAGE_KEY, otp as string);
}

export const removeResetPasswordOtp = () => {
    sessionStorage.removeItem(OTP_STORAGE_KEY);
}

export const getResetPasswordOtp = () => {
    return sessionStorage.getItem(OTP_STORAGE_KEY);
}

export const isResetPasswordOtpEnabled = () => {
    return !!getResetPasswordOtp();
}

export const getLoggedInUserName = () => {
    const tokenData: any = jwtDecode(getAccessToken())
    return _.get(tokenData, 'name', '')
}