import { TextField,FormControl } from '@mui/material'
import React from 'react'
import { Controller } from "react-hook-form";
import "./CommonTextField.css"




const CommonTextField = (props: any) => {
    const { control, errors, label, variant, rowNumber, name,required,multiline,Defalutvalue,limit, isNumber, extraRules = {} ,errorStyle = { paddingLeft: "30px" }} = props;
    return (
        <FormControl fullWidth required>
            <Controller
                name={name}
                control={control}
                rules={{
                    required: {
                        value: required,
                        message: "This field is required",
                    },
                    ...extraRules
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                    <div className="noticeInputFieldSect w-100">
                        <TextField
                            multiline={multiline?multiline:false}
                            minRows={rowNumber&&3}
                            maxRows={rowNumber&&Infinity}
                            required={required}
                            onBlur={onBlur}
                            variant={variant}
                            value={value}
                            id={name}
                            inputProps={{ "data-testid": "content-input",  maxLength: `${limit ? 10 : ""}`}}
                            label={label}
                            className="schAdminInputCtr mx-0 w-100"
                            onChange={(e:any) => {
                                if(multiline){
                                    onChange(e.target.value.trimStart())
                                }
                                else if(isNumber){
                                    if(limit && (e.target.value.length <= limit)){
                                        const v = e.target.value.replace(/[^0-9]/g, "")
                                        onChange(v.trimStart())
                                    }
                                    else if (!limit){
                                        const v = e.target.value.replace(/[^0-9]/g, "")
                                        onChange(v.trimStart())
                                    }
                                }
                                else if (e.target.value.length <= 50) {
                                    onChange(e.target.value.trimStart());
                                }
                            }}
                        />
                        {errors[name] && (
                            <span
                                className="reminder_text-danger"
                                style={errorStyle}
                                role="alert"
                            >
                                {errors[name]?.message}
                            </span>
                        )}
                    </div>

                )}
            />
        </FormControl>
    )
}

export default CommonTextField
