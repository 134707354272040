/* eslint-disable react/prop-types */
import './styles.scss';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NoProfilePhoto from "../../../assets/images/no_profile_photo.png";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clickToCall } from '../../../features/CallReducer/CallReducer'
import { ReactComponent as CallIcon } from '../../../assets/icons/CallFrame.svg';
import { ReactComponent as LinkPath } from '../../../assets/icons/linkpath.svg';
import { AppButton } from '../../AppComponents/AppButton';
import { ReactComponent as ClippadIcon } from '../../../assets/icons/clippad.svg';
import { Box, FormControl, InputLabel, MenuItem, Popover, Select, Snackbar, Typography, Alert, Avatar, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg';
import { useForm, Controller } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import AppMandateDropdown from '../../AppComponents/AppMandateDropdown/AppMandateDropdown';
import { getMandateLocation, assignCandidateToMandateLocation } from '../../../services/MandateService'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warningfill.svg';
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import { ReactComponent as InfoCircleIcon } from '../../../assets/icons/info-circle.svg';
import { assignMandateStatus } from '../../../features/mandateDetail/DrawerReducer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;

type Tprop = {
    candidateDetailsData:any;
    setSelectedComponent: (tab:string) => any;
}

const initialValues = {
    selectedMandate: '',
    selectedMandateLocation: ''
}


const CandidateHeader:React.FC<Tprop> = ({ candidateDetailsData, setSelectedComponent }) => {

    const dispatch = useAppDispatch()
    const [isPopOpen, setIsPopOpen] = useState<boolean>(false)
    const [mandatelocationLists, setMandateLocationLists] = useState<any[]>([])
    const [assignError, setAssignError] = useState<boolean>(false)
    const [successPopup, setSuccessPopup] = useState<{isOpen:boolean, message:string}>({isOpen:false, message:""})
    const [assignedErrorMsg, setAssignedErrorMsg] = useState<string>('')
    const [allowMultiple, setAllowMultiple] = useState<boolean>(false)
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    const assignMandate = useAppSelector((state) => state?.mandatedrawer?.assignMandate) 
    const smartCallMessage = useAppSelector((state:any) => state?.smartflow?.agentState) 
    const [smartFlowErrorStatus,setSmartFlowErrorStatus] = useState(false)
    const [smartFlowErrorStatusMsg, setSmartFlowErrorStatusMsg] = useState("")
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)

    const navigate = useNavigate()
    const handleClickCall = () => {
        const obj = {
            candidateId: candidateDetailsData?.id,
            phonenumber: candidateDetailsData?.phoneNumber,
            profilePicture: candidateDetailsData?.displayPicture,
            name: candidateDetailsData?.name
        }
        if(smartCallMessage?.errorMessage && smartCallMessage?.errorMessage.length > 0){
            setSmartFlowErrorStatusMsg(smartCallMessage?.errorMessage)
            setSmartFlowErrorStatus(true)
        }
        dispatch(clickToCall(obj))
    }

    const {
        handleSubmit,
        reset,
        control,
        formState:{errors},
        watch,
        setValue
      } = useForm<any>({defaultValues: initialValues});

      const manChange:any = watch("selectedMandate")
      
      const getMandateLocationList = async (manId:any)=> {
        // const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : "" 
        const apiQuery =  `?mandateBehaviour=${manId?.mandateBehaviour}`;
        const response = await getMandateLocation(manId?.value, apiQuery)
        if(response?.status === 200){
            setMandateLocationLists(response?.data?.result?.mandateLocations)
        }else{
            setMandateLocationLists([])
        }
      }

      const handleAssignMandateLocation = async (data:any) => {

        try{
            const obj = {
                mandateId: data?.selectedMandate?.value,
                mlocationId: data?.selectedMandateLocation,
                candidateId: candidateDetailsData?.id,
                isAllowMultiple:allowMultiple
            }
    
            const res = await assignCandidateToMandateLocation(obj)
            
            if(res?.status === 200){
                setIsPopOpen(false)
                setAssignError(false)
                setSuccessPopup({
                    isOpen:true, 
                    message:`XPH${obj?.candidateId} successfully assigned to the ${data?.selectedMandate?.mandateId}`
                })
                setAllowMultiple(false)
            }
            dispatch(assignMandateStatus({assignMandate: !assignMandate}))

        }catch(error:any){

            if( error?.response?.data?.error?.errorCode === "ERR102" ){
                setAllowMultiple(true)
            }else{
                setAllowMultiple(false)
            }
            if(error?.response?.data?.error?.message){
                setAssignedErrorMsg(error?.response?.data?.error?.message)
                setAssignError(true)
            }
        }
      }

      const handleClosePopup = () => {
        reset(initialValues)
        setIsPopOpen(false)
        setAssignError(false)
        setAllowMultiple(false)
      }

      useEffect(()=>{
        if(manChange){
            setValue("selectedMandateLocation", "")
            getMandateLocationList(manChange)
        }
        if(!manChange){
            reset(initialValues)
            setMandateLocationLists([])
            setAssignError(false)
            setAllowMultiple(false)
        }
      }, [manChange])

      const [anchorMoreEl, setAnchorMoreEl] = React.useState<null | HTMLElement>(null);
    const openMore = Boolean(anchorMoreEl);
    const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorMoreEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorMoreEl(null);
    };

    const handleEditCandidate = () => {
        navigate(`/createCandidate`,{state:{candidateDetail: candidateDetailsData,status:"edit"}})
    }


    return (
        <div className='candidate-head-wrap'>
            <img className='profile-img' src={candidateDetailsData?.displayPicture ? candidateDetailsData?.displayPicture : NoProfilePhoto} alt='user-img'></img>
            <div className='candidate-head-left'>
                <div className='name-id' style={{justifyContent:"space-between"}} >
                    <div className='name-id' >
                        <p className='user-name'>{candidateDetailsData?.name}</p>
                        <p className='user-id'>{`XPH${candidateDetailsData?.id}`}</p>
                    </div>
                    <Box>
                    {(userPermissions?.candidate_module?.view?.assign_jo?.selected && userRole != "Admin")&& <AppButton
                      label='Assign JO'
                      bordercolor='#002882'
                      bgcolor='#002882'
                      size='large'
                      type='button'
                      className='assign-btn assignJoBtnHandler'
                      sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                      onClick={()=> {
                        reset(initialValues)
                        setIsPopOpen(true)
                    }}
                    >
                      <ClippadIcon/>
                    </AppButton>}
                    {(userRole == "Admin")&& <AppButton
                      label='Assign JO'
                      bordercolor='#002882'
                      bgcolor='#002882'
                      size='large'
                      type='button'
                      className='assign-btn assignJoBtnHandler'
                      sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                      onClick={()=> {
                        reset(initialValues)
                        setIsPopOpen(true)
                    }}
                    >
                      <ClippadIcon/>
                    </AppButton>}
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={openMore ? 'long-menu' : undefined}
                        aria-expanded={openMore ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleMoreClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    </Box>
                </div>
                <div className='candidate-details'>
                    <div className='icon-wrap'><PlaceOutlinedIcon /><span>{candidateDetailsData?.currentLocation}</span></div>
                    <div className='icon-wrap'><BusinessCenterOutlinedIcon /><span>{candidateDetailsData?.currentDesignation}</span></div>
                    <div className='icon-wrap'><CalendarTodayOutlinedIcon />{candidateDetailsData?.experience != 0 && <span>{candidateDetailsData?.experience} years</span>}</div>
                    <div className='icon-wrap'><LocationCityIcon /><span>{candidateDetailsData?.currentCompany}</span></div>
                </div>
                <p className='descr-text'>{candidateDetailsData?.about ? candidateDetailsData?.about : "-"}</p>
                <div className='candidate-contact'>
                    <div className='contact-wrap'>
                        <MailOutlineIcon />
                        <div className='contact'>
                            <p className='label-text'>Email</p>
                            <p className='value-text'>{candidateDetailsData?.email}</p>
                        </div>
                    </div>
                    <div 
                        className='contact-wrap cursor-pointer'
                        onClick={()=> {
                            if(candidateDetailsData?.phoneNumber){
                                handleClickCall()
                            }
                        }} 
                    >
                        {/* <LocalPhoneOutlinedIcon /> */}
                        <CallIcon/>
                        <div className='contact'>
                            <p className='label-text'>Contact</p>
                            {candidateDetailsData?.phoneNumber && (
                            <div className='number-container'>
                                <p className='value-text'>{candidateDetailsData?.phoneNumber && `+91 ${candidateDetailsData?.phoneNumber}`}</p>
                                <LinkPath/>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Popover
                open={isPopOpen}
                className='assign-mandate-location-popup'
            >
                <Box
                    className="assign-container"
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(handleAssignMandateLocation)}
                >
                 <Box className="header">
                   <Typography>Assign JO to Candidate</Typography>
                   <CloseIcon style={{cursor:"pointer"}} onClick={handleClosePopup} />
                 </Box>
                 <Box className="body-conatiner">
                    <FormControl fullWidth required >
                        <Controller
                            name="selectedMandate"
                            control={control}
                            rules={{
                              required: {
                                  value: true,
                                  message: "Mandate is required",
                              }
                            }}
                            render={({field : {onChange, value, onBlur}})=>(
                                <AppMandateDropdown
                                    label="Select Mandate"
                                    required={true}
                                    value={value}
                                    onChange={(e:any)=>onChange(e)}
                                />
                            )}
                        />
                        {errors?.selectedMandate && (
                            <span className="reminder_text-danger select-danger-text" role="alert">
                                {errors?.selectedMandate?.message as string}
                            </span>
                        )}
                    </FormControl>
                    <FormControl fullWidth required >
                        <InputLabel>Select Mandate Location</InputLabel>
                        <Controller
                            name="selectedMandateLocation"
                            control={control}
                            rules={{
                              required: {
                                  value: true,
                                  message: "Mandate Location is required",
                              }
                            }}
                            render={({field : {onChange, value, onBlur}})=>(
                                <Select
                                label='Select Mandate Location'
                                value={value}
                                onChange={(e: any) => {
                                    onChange(e)
                                    if(assignError)setAssignError(false)
                                  }}
                                className='test-dropdown'
                                labelId='mandate-location-select-label'
                                >
                                    {mandatelocationLists?.length && mandatelocationLists?.map((mll:any, index:number)=>(
                                        <MenuItem key={index} value={mll?.id} className='mandate-location-lists-opt' >
                                            <div className='primary-text' >{`${mll?.location} (${mll?.jobOrderCount})`}</div>
                                            {/* <div className='secondary-text' >{`XRDMAN${mll?.mandateId}LOC${mll?.id}`}</div> */}
                                        </MenuItem>
                                    ))}
                                    {mandatelocationLists?.length === 0 && <MenuItem disabled={true}>No mandatelocations</MenuItem>}
                                </Select>
                            )}
                        />
                        {errors?.selectedMandateLocation && (
                            <span className="reminder_text-danger select-danger-text" role="alert">
                                {errors?.selectedMandateLocation?.message as string}
                            </span>
                        )}
                    </FormControl>
                 </Box>
                 {assignError && <div className={`assign-warning ${allowMultiple ? "warning-msg" : "error-msg"}`} >
                    <div>{allowMultiple ? <InfoCircleIcon/> : <WarningIcon/>}</div>
                    <div>{assignedErrorMsg}</div>
                 </div>}
                 <Box className="footer">
                    <AppButton
                        label='Cancel'
                        bordercolor='#E5E8EB'
                        bgcolor='#E5E8EB'
                        size='large'
                        type='button'
                        sx={{ color: '#000;', backgroundColor: '#000;', p: 1 }}
                        onClick={handleClosePopup}
                    />
                    <AppButton
                        label='Assign'
                        bordercolor={(assignError && !allowMultiple) ? '#919395' : '#002882'}
                        bgcolor={(assignError && !allowMultiple) ? '#919395' : '#002882'}
                        size='large'
                        type='submit'
                        className='assign-btn assignJoBtnHandler'
                        sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                    />
                 </Box>
                </Box>
            </Popover>
            <AppErrorHandlerModal
                open={successPopup?.isOpen}
                content={successPopup?.message}
                handleClose={()=>{
                    setSuccessPopup({isOpen:false, message:""})
                    setSelectedComponent("candidateTracking")
                }}
                factor='Success'
                ButtonText='Done'
            ><></></AppErrorHandlerModal>
            <Snackbar
                open={smartFlowErrorStatus}
                autoHideDuration={5000}
                onClose={() => {setSmartFlowErrorStatus(false)}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                >
                <Alert onClose={() => {setSmartFlowErrorStatus(false)}} severity='error' sx={{ width: '100%' }} variant='filled'>
                    {smartFlowErrorStatusMsg}
                </Alert>
          </Snackbar>
          <Menu
            id="long-menu"
            MenuListProps={{
            'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorMoreEl}
            className='candidateMenuMore'
            open={openMore}
            onClose={handleClose}
            PaperProps={{
            style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                minWidth: '150px',
            },
            }}
        >
            <MenuItem onClick={handleEditCandidate}>
                <Edit/> Edit
            </MenuItem>
        </Menu>
        </div>
    )
}

export default CandidateHeader;
