import { Popover, Box, Typography, Modal } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modalcloseicon.svg'
import './JobOrderPopUp.scss'
import { getJobOrderStatus } from '../../../../services/MandateService'
import { useEffect, useState } from 'react'

type Tprop = {
  open: boolean
  handleClose: () => void
  location: any
  manID: number;
}

const JobOrderPopUp: React.FC<Tprop> = ({ open, handleClose, location, manID }) => {
  const [statusList, setStatusList] = useState<any[]>([])
  const getJOstatusDetails = async () => {
    const reqObj = {
      filters: [
        {
          field: 'mandateId',
          operator: 'equals',
          type: 'string',
          value: String(manID),
        },
        {
          field: 'mLocationId',
          operator: 'equals',
          type: 'string',
          value: String(location?.id),
        },
      ],
      page: {
        pageNo: -1,
        pageSize: 10,
      },
      sorting: {
        direction: 'ASC',
        field: 'createdAt',
      },
    }

    try {
      const res = await getJobOrderStatus(reqObj)
      if (res?.status === 200) {
        setStatusList(res?.data?.result?.list || [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getJOstatusDetails()
  }, [])

  return (
    <Modal
      onClose={handleClose}
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='joborder-status-model'
    >
      <Box className='job-order-container'>
        <Box className='job-heading'>
          <Typography className='heading-text'>Job Orders</Typography>
          <CloseIcon style={{cursor: "pointer"}} onClick={handleClose} />
        </Box>
        <Box className='location-wrap'>
          <div className='loc-container'>
            <div className='loc-text'>{`${location?.primaryLocation} (${location?.jobOrders})`}</div>
          </div>
        </Box>
        <Box className='jo-status-wrap'>
          {statusList?.map((data: any, index: number) => {
            let tagStyle = ''
            if (data?.status?.statusName === 'Sourcing under progress') {
              tagStyle = 'joprogress-tag'
            } else if (data?.status?.statusName === 'JO filled successfully') {
              tagStyle = 'josuccess-tag'
            } else {
              tagStyle = 'joactive-tag'
            }
            return (
              <div key={index} className='jostatus-wrap'>
                <div className='status-code'>{data?.joCode}</div>
                <div className={`status-name ${tagStyle}`}>{data?.status?.statusName}</div>
              </div>
            )
          })}
        </Box>
      </Box>
    </Modal>
  )
}

export default JobOrderPopUp
