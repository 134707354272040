/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { TaskManagementService } from '../../../services/TaskService'
import './styles.scss'
import Avatar from '@mui/material/Avatar'
import { Taskdetail } from '../../../pages/MyTasks'
import { TextField } from '@mui/material'
import { convertToDisplayDateTime } from '../../../utils/date-utils'
import { getLoggedInUserName } from '../../../utils/user-utils'
import attachSVG from '../../../assets/icons/attachment.svg'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg'
import excelIcon from '../../../assets/icons/excelIcon.svg'
import pdfIcon from '../../../assets/icons/pdfIcon.svg'
import wordIcon from '../../../assets/icons/wordicon.svg'
import { downloadMandateFile } from '../../../services/MandateService'
import { baseUrl } from '../../../services/http-common'


export interface TCommentList {
  comments: string
  created_at: string
  created_by: string
  id: number
  status: number
  task_id: number
  updated_at: string
  updated_by: string
  user_id: string
  username: string
  file_path: string
}

type Tprop = {
  data: Partial<Taskdetail>
}
const UploadDocumentButton = ({
  file,
  setFiles,
  setFileSizeErrorMessage,
  index,
  ...props
}: any) => {
  const { getRootProps, getInputProps} =
    useDropzone({
      multiple: false,
      maxSize: 10485760,
      accept: {
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/pdf': ['.pdf'],
        // 'image/jpeg': ['.jpeg', '.png'],
        // 'text/html': ['.html', '.htm'],
        // 'video/mp4': ['.mp4', '.MP4'],
      },
      noKeyboard: true,
      noDrag: true,
      onDropAccepted: async (acceptedFiles) => {
        setFiles(acceptedFiles)
        setFileSizeErrorMessage("")
      },
      onDropRejected: (rejectedFiless) => {
        // setFiles([])
        if (rejectedFiless[0].errors[0].code === 'file-too-large') {
          setFileSizeErrorMessage('File size exceeded, please upload file within 10 MB')
        } else if (rejectedFiless[0].errors[0].code === 'file-invalid-type') {
          setFileSizeErrorMessage('File format incorrect, please upload only specified file formats')
        } else {
          setFileSizeErrorMessage(rejectedFiless[0].errors[0].message)
        }
      },
      // ... props
    })

  return (
    <section className='container'>
      <div {...getRootProps({ className: 'custom-dropzone' })}>
        <input {...getInputProps()} />
        <div className='file-name-container'>
          <div className='attach-holder'>
            <img src={attachSVG} />
          </div>
        </div>
      </div>
    </section>
  )
}

const iconDecide=(file:string)=>{
  const format=file?.split(".")?.[file?.split(".")?.length-1]
  let icon = excelIcon
  switch (format) {
    case "pdf":
      icon = pdfIcon
      break;
    case "doc":
      icon = wordIcon
      break;
    case "docx":
      icon = wordIcon
      break;
    default:
      icon = excelIcon
      break;
  }
  return <img src={icon} />
}

const CommentsAndTags: React.FC<Tprop> = ({ data }) => {
  const [comments, setComments] = useState<TCommentList[]>([])
  const [addComment, setAddComment] = useState<string>('')
  const [colorForAlpha, setcolorForAlpha] = useState<any>({})
  const [fileDetails, setFileDetails] = useState<any>(null)
  const [fileSizeErrorMessage, setFileSizeErrorMessage] = useState<any>("")
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const getUserName = (name: string) => {
    const b = name?.split(' ')
    const result = `${b[0].charAt(0)}${b[1] ? b[1].charAt(0) : ''}`
    return result.toUpperCase()
  }

  const sortFunction = (a: any, b: any) => {
    const dateA = new Date(a.created_at).getTime()
    const dateB = new Date(b.created_at).getTime()
    return dateA > dateB ? -1 : 1
  }
  const getTaskComments = async () => {
    const response = await TaskManagementService.GetTaskComments(data?.taskId)
    if (response?.status === 200) {
      setComments(response?.data?.sort(sortFunction))
      setAddComment('')
    } else {
      console.log('error')
    }
  }

  const handleAddComment = async () => {
    setIsSubmitting(true)
    const payload = {
      comments: addComment,
      taskId: data?.taskId,
    }
    const createComment = new FormData()
    createComment.append('comment', JSON.stringify(payload))
    createComment.append('file', fileDetails)
    const response = await TaskManagementService.CreateTaskComment(createComment)
    setIsSubmitting(false)
    if (response?.status === 200) {
      setAddComment('')
      setFileSizeErrorMessage("")
      setFileDetails(null)
      getTaskComments()
    } else {
      console.log('error')
    }
  }
  const getRandomItem = (alpha: string) => {
    const arr: string[] = [
      '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
      '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
      '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
      '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
    ]
    const randomIndex = Math.floor(Math.random() * arr.length)
    const item = arr[randomIndex]
    setcolorForAlpha({ ...colorForAlpha, [alpha]: item })
    return item
  }
  const fileChangeHandler = (files: any) => {
    const file = files[0]
    setFileDetails(file)
    setFileSizeErrorMessage('')
  }

  useEffect(() => {
    if (data?.taskId) {
      getTaskComments()
    }
  }, [data])

  const handleMandateDownload = async (filePath:any) => {
    const excelLinkSource = `filename=${filePath}`
    try {
      const response = await downloadMandateFile(excelLinkSource)
      if (response?.data) {
        window.open(`${baseUrl}mandate/download?${excelLinkSource}`)
      } else {
        console.error("No Such File")
      }
    } catch (error) {
      console.error("Internal Server Error", error)
    }
  };

  return (
    <div className='comments-main'>
      <p className='comment-heading'>Comments & tags</p>
      <div className='comments-insert-part'>
        <div className='comment-box'>
          <Avatar
            sx={{
              bgcolor:
                colorForAlpha?.[getLoggedInUserName()] || getRandomItem(getLoggedInUserName()),
            }}
            alt='Remy Sharp'
          >
            {getUserName(getLoggedInUserName())}
          </Avatar>
          <div className='commentand-attachwrap'>
            <TextField
              multiline={true}
              minRows={1}
              maxRows={Infinity}
              value={addComment}
              placeholder='Add a Comment...'
              className='comment-text'
              onChange={(e: any) => {
                setAddComment(e.target.value)
              }}
            />
            {fileDetails ? (
              <div className='filename-spectifed'>
                <div className="icon-file-wrap">
                  {iconDecide(fileDetails?.name)}
                  <span>{fileDetails.name}</span>{' '}
                </div>
                <DeleteIcon
                  className='candidate-sidebar-close-icon'
                  onClick={() => setFileDetails(null)}
                />
              </div>
            ) : (<>
              {fileSizeErrorMessage ? <span className='error-msg'>{fileSizeErrorMessage}</span> : <></>}
            </>
            )}
          </div>
          <UploadDocumentButton
            setFiles={(file: any) => {
              fileChangeHandler(file)
            }}
            file={fileDetails}
            setFileSizeErrorMessage={setFileSizeErrorMessage}
          />
          <button
            className={`post-button ${(isSubmitting || addComment?.trim()?.length === 0) && "button-disable"}`}
            disabled={isSubmitting || addComment?.trim()?.length === 0}
            onClick={() => {
              (fileDetails || addComment?.length > 0) && handleAddComment()
            }}
          >
            post
          </button>
        </div>
      </div>
      {comments?.length > 0 && (
        <>
          {comments?.map((comment: TCommentList, index: number) => {
            return (
              <div className='comment-list' key={index}>
                <Avatar
                  sx={{
                    bgcolor: colorForAlpha?.[comment?.username] || getRandomItem(comment?.username),
                  }}
                  alt='Remy Sharp'
                >
                  {getUserName(comment?.username)}
                </Avatar>
                <div className='comment'>
                  <div className='comment-name-date'>
                    <p>{comment?.username}</p>
                    <p className='comment-date-only'>
                      {convertToDisplayDateTime(comment?.created_at)}
                    </p>
                  </div>
                  <p className='msg'>{comment?.comments}</p>
                  {comment?.file_path ? <div className='icon-file-wrap'> {iconDecide(comment?.file_path)} <a onClick={()=>handleMandateDownload(comment?.file_path)} className='show-attach' >{comment?.file_path}</a></div> : <></>}
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default CommentsAndTags
