import { createSlice } from '@reduxjs/toolkit'
// model has interface for redux

export interface ToggleState {
    drawer: boolean,
    // expanddrawer: boolean,
    hideGridlayout :boolean
    hideExpandicon:boolean
    FullPage :boolean
    isDraft : boolean
    isApproval : boolean | null
    isRecruiter: boolean | null
    isEdit: boolean
    assignMandate: boolean
    approvalMandate: boolean
    uploadMandate: boolean
    departmentData: []
    industryData: [],
    locationPosition: string,
    unAuthorizedError: string
}
const initialDrawerState: ToggleState = {
    drawer: false,
    // expanddrawer: false,
    hideGridlayout:false,
    hideExpandicon:false,
    FullPage :false,
    isDraft : false,
    isApproval: null,
    isRecruiter:null,
    isEdit: false,
    assignMandate: false,
    approvalMandate: false,
    uploadMandate: false,
    departmentData: [],
    industryData: [],
    locationPosition: "",
    unAuthorizedError: "",
}

const DrawerSlice = createSlice({
    name: 'drawer',
    initialState: initialDrawerState,
    reducers: {
        draweropen: (state, action) => {
            state.drawer =true
            state.hideExpandicon=false
            state.FullPage=false
            state.isDraft=action.payload?.isDraft
        },
       drawerclose: (state) => {
            state.drawer = false
            state.hideGridlayout =false
            state.FullPage=false
            state.isDraft=false
        },
        expanddrawer:(state)=>{
            state.drawer= false
            state.hideExpandicon=true
            state.hideGridlayout =true
            state.FullPage=true
        },
        expanddrawerMenu:(state)=>{
            state.drawer= false
            state.hideExpandicon=true
            state.hideGridlayout =false
            state.FullPage=true
        },
         minimizedrawer:(state)=>{
            state.drawer= true
            state.hideExpandicon=false
            state.hideGridlayout =false
            state.FullPage=false
        },
        listStatus:(state,action)=>{
            state.isApproval=action.payload.isApproval
            state.isRecruiter=action.payload.isRecruiter
        },
        mandateEdit:(state, action) => {
            state.isEdit=action.payload.isEdit            
        },
        assignMandateStatus: (state, action) => {
            state.assignMandate=action.payload.assignMandate
        },
        mandateApprovalStatus: (state, action) => {
            state.approvalMandate=action.payload.approvalMandate
        },
        mandateUploadStatus: (state, action) => {
            state.uploadMandate=action.payload.uploadMandate
        },
        mandateDepartmentData: (state, action) => {
            state.departmentData=action.payload.departmentData            
        },
        mandateIndustryData: (state, action) => {
            state.industryData=action.payload.industryData
        },
        mandateLocationPosition: (state, action) => {
            state.locationPosition=action.payload.locationPosition
        },
        mandateUnAuthorized: (state, action) => {
            state.unAuthorizedError = action.payload.unAuthorizedError
        }
    },
})

export const { draweropen, drawerclose,expanddrawer,minimizedrawer, listStatus, mandateEdit, assignMandateStatus, mandateApprovalStatus, mandateUploadStatus, mandateDepartmentData,mandateIndustryData, mandateLocationPosition, mandateUnAuthorized, expanddrawerMenu } = DrawerSlice.actions
export default DrawerSlice.reducer