import React from 'react';
import "./AppNotificationAlert.scss"
import { Box } from '@mui/material';
import callIcon from "../../../assets/images/callNotificationUser.png"
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const AppNotificationAlert = () => {
    const callNotification = useAppSelector((state) => state?.notificationState)
    const smartFlowInfo:any = useAppSelector((state) => state?.smartflow.agentState)
    const checkNumberMatch = (userNumber:string, smartFlowNumber: any) => {
        const matchedNumber = smartFlowNumber != null ? smartFlowNumber.filter((item:any) => item?.did.replace("+","") == userNumber) : []
        return matchedNumber 
    }
    return (
        <>
        {(callNotification?.callNotificationOpen && (checkNumberMatch(callNotification?.callNotificationInfo?.call_to_number , smartFlowInfo?.callId)).length > 0) && <div className='callNotification'>
            <Box className='callNotificationSect'>
                <img className='callUserImg' src={callIcon} />
                <Box className="callNotificationContent">
                    <h4>{callNotification?.callNotificationInfo?.candidate_name != "UNKNOWN" ? callNotification?.callNotificationInfo?.candidate_name: "Unknown Number"}</h4>
                    <p>{callNotification?.callNotificationInfo?.caller_id_number}</p>
                </Box>
                <img className='callGifImg' src={require('../../../assets/images/callNotification.gif')} />
            </Box>            
        </div>
        }
        </>
    );
};

export default AppNotificationAlert;