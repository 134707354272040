import React, { useCallback } from 'react'
import { TextField, Autocomplete, InputAdornment, styled, Popper } from '@mui/material'
import { ReactComponent as FilterIcon } from '../../../../src/assets/icons/input-filter-icon.svg'

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot {
    border-radius: 10px;
  }
  .MuiAutocomplete-inputRoot.Mui-focused fieldset {
    border: 0.5px solid rgba(207, 209, 210, 1);
  }

  .MuiOutlinedInput-root:hover fieldset {
    border: 0.5px solid rgba(207, 209, 210, 1);
  }
  .MuiInputBase-root {
    height: 40px;
    margintop: '0px';
    padding: 0px 10px;
  }
` as typeof Autocomplete

export interface AutocompleteOptionData {
  value: string
  label: string
}

interface AutocompleteFieldProps {
  error?: string | React.ReactElement
  id: string
  label: string
  name: string
  options: AutocompleteOptionData[]
  onChange?: (value: AutocompleteOptionData) => void
  value: any
}

export const AppAutoComplete: React.FC<AutocompleteFieldProps> = ({
  id,
  name,
  options,
  label,
  error,
  onChange,
  value,
}) => {

  const PopperMy = useCallback((props: any)=> {
      return <Popper {...props} id={id} />
  },[])
   
  return (
    <div>
      <StyledAutocomplete
        id={id}
        options={options}
        value={(value as AutocompleteOptionData)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(event, value) =>
          onChange &&
          onChange(
            value || {
              label: '',
              value: '',
            },
          )
        }
        PopperComponent={PopperMy}
        getOptionLabel={(data) => (data as AutocompleteOptionData).label}
        // getOptionSelected={(option: AutocompleteOptionData) => option.label === currentValue?.label}
        renderInput={(params) => (
          <div className='auto-complete-input-container'>
            <TextField
              {...params}
              error={!!error}
              name={name}
              placeholder={label}
              className='auto-input'
              InputLabelProps={{ shrink: false }}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <FilterIcon className='icon' />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        )}
      />
    </div>
  )
}
