import React from 'react';
import { Breadcrumb } from '../components/Breadcrumb';

const OverAllReport = () => {
    return (
        < >
            <Breadcrumb title='Analytics Space' />
            <iframe className='zohoAnalyticsReport' src="https://analytics.zoho.com/open-view/1898558000029209845/ffc096a2e18d159cb26e63292786eaaa"></iframe>
        
        </>
    );
};

export default OverAllReport;