import { useState } from 'react'
import { List, Divider } from '@mui/material'
import { RouteItem } from './RouteItem'
import secondaryRoutesConfig from '../../../config/secondary-routes'
import { Route } from '../../../types/Route'

interface RoutesProps {
  open: boolean | undefined
  toggleQuickView?: () => void
  toggleCallLogView?: () => void
}

export const Routes = ({ open, toggleQuickView, toggleCallLogView }: RoutesProps) => {
  const [routesState, setRoutesStage] = useState<Route[]>(secondaryRoutesConfig)

  const handleMenuClick = (route: Route) => {
    const items = routesState.map((item) => {
      if (item.key === route.key) {
        item.expanded = !item.expanded
      }
      return item
    })
    setRoutesStage(items)
  }

  return (
    <>
      <List component='nav' sx={{ height: '100%' }}>
        {routesState.map((route: Route) => (
          <div key={route.key}>
            {route.subRoutes ? (
              <>
                <RouteItem
                  key={`${route.key}`}
                  route={route}
                  hasChildren
                  handleMenuClick={handleMenuClick}
                  open={open}
                />
                <List component='div' disablePadding>
                  {route.subRoutes.map((sRoute: Route) => (
                    <RouteItem key={`${sRoute.key}`} route={sRoute} nested open={open} />
                  ))}
                </List>
              </>
            ) : (
              <RouteItem key={route.key} route={route} nested={false} open={open} toggleQuickView={toggleQuickView} toggleCallLogView={toggleCallLogView} />
            )}
            {route.appendDivider && <Divider />}
          </div>
        ))}
      </List>
    </>
  )
}
