import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'

const CandidateSocialUrl = () => {
  const { control, formState: { errors }, getValues, setValue, clearErrors, trigger }: any = useFormContext();

  return (
    <>
      <Grid className='container-wrapper'>
        <Box className="personalInfoWrapper" style={{ flexDirection: "column" }}>
          <Box className="personalInfoFieldWrapper">
            <Grid className='row-grid mb-3' >
              <FormControl fullWidth>
                <Controller
                  name={"candidateSocialFacebook"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TextField
                      label='Facebook Profile URL'                      
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <Controller
                  name={"candidateSocialLinkedin"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TextField
                      label='Linkedin Profile URL'                      
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <Controller
                  name={"candidateSocialGitHub"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TextField
                      label='GitHub Profile URL'                      
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <Controller
                  name={"candidateSocialStackoverflow"}
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TextField
                      label='Stackoverflow URL'                      
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default CandidateSocialUrl;