import React, { useEffect, useState,useRef, useCallback} from 'react'
import './Notification.scss'
import { Avatar, Switch, Alert, ToggleButtonGroup, ToggleButton, debounce } from '@mui/material'
import { getNotificationApi, updateNotificationStatus, getNotificationCount } from "../../../services/NotificationService"
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { async } from 'q';
import { setNotificationCount, openNotification,updateNotificationToggleState } from "../../../features/notification/notificationReducer";
import { useNavigate } from 'react-router-dom';
import NoNotificationIcon from "../../../assets/icons/NoNotification.svg"
import FullyLoadedIcon from "../../../assets/icons/notifull.svg"
import { convertToDisplayDateTime } from '../../../utils/date-utils';
import {
  drawerclose, expanddrawer, listStatus
} from '../../../features/mandateDetail/DrawerReducer'
import {leftdrawertoggleclose } from '../../../features/toggle/ToggleReducer'
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as SearchSmall } from "../../../assets/icons/searchSmall.svg";
import { ReactComponent as NotifBackToTop } from "../../../assets/icons/notifBackToTop.svg"

const AllNotification = ({props}:any) => {
//   const { setQuickViewOpen } = props;
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const containerRef = useRef<any>(null);
  const observerRef = useRef();
  const [noticationList, setNoticationList] = useState<any>({})
  const [notificationData, setNotificationData] = useState<any>([]);
  const [pageNo,setPageNo] = useState<number>(1)
  const [totalPages,setTotalPages] = useState<number>(1)
  const [reachedBottom,setReachedBottom] = useState<boolean>(false)
  const notificationStatus = useAppSelector((state:any)=>state?.notificationState?.enableNotificationState)
  const [unread,setUnread] = useState<any>(notificationStatus)
  const fetchTypeRef = useRef<any>(undefined);
  const [previousFetchType, setPreviousFetchType] = useState<any>("ALL");
  const[loaded,setLoaded]=useState<boolean>(false);
  const userState = useAppSelector((state) => state?.UserDetail?.userId)
  const notificationCount = useAppSelector((state:any)=>state?.notificationState?.count)
  const isRecruiter = useAppSelector((state)=>state?.mandatedrawer?.isRecruiter)
  const [alignment, setAlignment] = React.useState('all');
  const [previousAlignment,setPreviousAlignment] = useState("")
  const [notificationList, setNotificationList] = useState<any>({})
  const [searchText,setSearchText] = useState("")
  const [searchTextValue,setSearchTextValue] = useState("")
  const markAllRead = () => {
    setUnread(false);
    handleNotiClick(null, "all")
  }
  const handleNotificationCount = async () => {
    if (userState) {
      const reqObj = {
        "userId": userState
      }
      const response = await getNotificationCount(reqObj)
      if (response && response?.result?.data) {
        dispatch(setNotificationCount(response?.result?.data))
      }
      else{
        dispatch(setNotificationCount(0))
      }
    }
  }
  const handleNotiClick = async (noti?: any, type?: string) => {
    const reqObj = {
      "notificationLogId": noti?.id,
      "queryType": type
    }
    const {  id } = noti != null ? JSON.parse(noti?.metadata) : "";
    
    if(id != 'UNKNOWN' || (noti == null && type == "all")){
    const resp = await updateNotificationStatus(reqObj)
    if (resp?.result?.message === "updated") {
      if (type === "single" && noti?.metadata) {
        
          const modulescreen = noti?.modulescreen
          if (modulescreen === "mandate_detail" || modulescreen ==="mandate_approval") {
            dispatch(drawerclose())
            dispatch(leftdrawertoggleclose())
            dispatch(openNotification({ module: modulescreen, id: id }))
            dispatch(expanddrawer())
            setTimeout(() => {
              navigate(`/mandates?id=${id}`,{state:"notificationRedirection"});
            }, 300)
          }else if(modulescreen ==="mandate_sharing"){
            dispatch(drawerclose())
            dispatch(leftdrawertoggleclose())
            dispatch(openNotification({ module: modulescreen, id: id }))
            dispatch(expanddrawer())
            setTimeout(() => {
              navigate(`/mandates?id=${JSON.parse(noti?.metadata)["redirection_link"]?.split("?")[1].split("=")[1]}`,{state:"notificationRedirection"});
            }, 300)
          }
          else if (modulescreen === "candidate_tracking" || modulescreen === "smart_call") {
            dispatch(openNotification({ module: modulescreen, id: id }))
            setTimeout(() => {
              navigate(`/candidate/${id}`,{state:{source:modulescreen === "smart_call" ? "smartCall":"candidateRedirection",id:id,timeStamp: new Date().getTime()}})
            }, 500)
          }else if (modulescreen === "mandate_allocation") {
            dispatch(openNotification({ module: modulescreen, id: id }))
            dispatch(listStatus({isApproval:true,isRecruiter}))
            setTimeout(() => {
              navigate(`/mandates`,{state:"allocationRedirection"})
            }, 500)
          }else if(modulescreen == "mandate_location_joborder"){
            const mandateData = JSON.parse(noti?.metadata)            
            setTimeout(() => {
              navigate(`/jobOderDetailView?id=${mandateData?.mandate_id}&locId=${mandateData?.mandate_location_id}`,{state:{timeStamp: new Date().getTime()}})
            }, 500)
          }
         await getNotificationDetails()
         await handleNotificationCount()        
        
      }
      else{
      await getNotificationDetails()
      await handleNotificationCount()
      }
    }
    else {
      <Alert variant="filled" severity="error">
        server error
      </Alert>
    }
  }

  }
  const notificationTemplates = (data: any) => {
    const htmlMsg = data?.status_name != null ? data?.messagecontent + `<span class=${data?.status_name == "Approved" ? "statusApprover" : data?.status_name == "Rejected" ? "statusReject" : data?.status_name == "Pending" ? "statusPending" :""}>${data?.status_name}</span>` : data?.messagecontent
    console.log("data",data?.messagecontent)
    if (data?.days) {
      return (<div className={`list-wrapper ${JSON.parse(data?.metadata).id == 'UNKNOWN' ? "unknownCandidate" : ""} ${data?.readstatus ? "unread" : "active"}`} onClick={() => handleNotiClick(data, "single")}>
        <Avatar />
        <div className="list-data-container" style={{paddingRight:"10px"}}>
          <div className={data?.readstatus === 0 ? "content-row-cls" : ""}>
            <p><span dangerouslySetInnerHTML={{ __html: htmlMsg}} /></p>
            {/* {
              data?.readstatus === 0 ? (<div className='active-blue-cls'></div>) : <></>
            } */}
          </div>
          <span>{convertToDisplayDateTime(data?.createdat)}</span>
        </div>
      </div>)
    }
  }
  const handleShowUnread = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setUnread(event.target.checked)
    dispatch(updateNotificationToggleState(event.target.checked))
  }
  const getNotificationDetails = async (unreadParams?:string) => {
    const isSameFetchType = unreadParams && previousFetchType  === unreadParams;
    if (!isSameFetchType) {
      setNoticationList({});
      setPageNo(1);
    }
    const reqObj:any = {
      "page": {
        "pageNo":isSameFetchType ? pageNo : 1,
        "pageSize": 15
      },
      "fetchType":unreadParams==="UNREAD"?"UNREAD":"ALL",
      "keyword":searchText,
    }
    if(alignment == "myApprovals")reqObj["filter_type"] = 145
    const response = await getNotificationApi(reqObj)
    if (response?.result && response?.result?.list?.length > 0) {
      const data = response?.result?.list;
      if(alignment != "myApprovals")setNotificationList(response?.result)
      const organizedNotifications = data.reduce((acc: any, notification: any) => {
        const day = notification.days;
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(notification);
        return acc;
      }, (isSameFetchType && previousAlignment == alignment) ? { ...noticationList } : {});
      setNoticationList((prevList:any) => {
        if (isSameFetchType) {
          return { ...prevList, ...organizedNotifications };
        } else {
          return organizedNotifications;
        }
      });
      setTotalPages(response?.result?.totalPages)
      setPreviousFetchType(unreadParams);
      setPreviousAlignment(alignment)
     
    }
    else {
      setNoticationList({})
    }
    setLoaded(true)
  }

  useEffect(()=>{
    if (unread) {
      getNotificationDetails("UNREAD");
    } else {
      getNotificationDetails("ALL");
    }
  },[unread,pageNo,searchText,alignment])

  const debouncedSearch = useCallback(    
    debounce((nextValue) => {
      setNoticationList([])
      setSearchText(nextValue)
    }, 500),
    []
  );
  
  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    const isAtTop = scrollTop === 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
     if (isAtBottom && !reachedBottom && pageNo < totalPages) {
        setPageNo(pageNo +1)
        setReachedBottom(true);
    }
    else if (!isAtBottom) {
      // Reset the flag when scrolling away from the bottom
      setReachedBottom(false);
    }
};
const handleChange = (event: React.MouseEvent<HTMLElement>,newAlignment: string) => {
    setNoticationList([])
    setSearchText("")
    setSearchTextValue("")
    setAlignment(newAlignment);    
    setPageNo(1)
  };
  const handleScrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  };

    return (
        <div>
            <div className="notification-container allNotificationView">
                <div className="notify-header">
                    <div className="header-text">{`Notification (${notificationList?.totalRecords})`}</div>
                    <div className='searchSect'>
                        <SearchSmall />
                        <input value={searchTextValue} placeholder='Search Keyword...' onChange={(e:any) => {setSearchTextValue(e.target.value); debouncedSearch(e.target.value)}}/>
                    </div>
                </div>
                <div className='notificationBodySect'>
                    <div className="toggleActionSect">
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        >
                        <ToggleButton value="all">All <span>{notificationList?.totalRecords}</span></ToggleButton>
                        <ToggleButton value="myApprovals">My Approvals <span>{notificationList?.forApprovalCount}</span></ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                    <div className="toggle-container">
                        <div className='toggle-switch'>
                            <Switch checked={unread} onChange={handleShowUnread} />
                            <div className='primary-text' >Show only unread</div>
                        </div>
                        <div className='markasread-text' onClick={markAllRead}><DoneIcon />Mark all as read</div>
                    </div>
                    {noticationList && Object.keys(noticationList)?.length > 0 ?
                        <>
                            <div className="notify-warpper" ref={containerRef} onScroll={handleScroll} style={{scrollBehavior: 'smooth'}}>
                                {Object.keys(noticationList)?.length > 0 && Object.keys(noticationList).map((keys: any, index: number) => (
                                    <div key={index} className='keys-data-wrapper'>
                                        <div className='keys-text'>{keys}</div>
                                        <div className="nitfy-data">
                                            {noticationList[keys].map((list: any, index: number) => {
                                                return (
                                                    notificationTemplates(list)
                                                )
                                            })}
                                        </div>
                                    </div>
                                ))}
                                {
                                    pageNo === totalPages && (
                                        <div className='notifull'>
                                            <img src={FullyLoadedIcon} />
                                            <div className='font-cls' style={{marginBottom:"15px"}}>{"That's all your notifications from the last 30 days."}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                        :
                        <div className='noNotifications'>
                            {
                                loaded && (
                                    <>
                                        <img src={NoNotificationIcon} />
                                        <div className='nodata-text'>No Notifications found</div>
                                    </>
                                )
                            }
                        </div>
                    }
                </div>
                {((noticationList && Object.keys(noticationList)?.length > 0) && pageNo === totalPages) && <div onClick={handleScrollToTop} className='backToTopSect'>
                    <p>Back to Top <NotifBackToTop /></p>
                </div>}
            </div>
        </div>
    );
};

export default AllNotification;