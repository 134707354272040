import React, { useEffect, useState, useRef,useContext } from "react";
import './EnahanceChatBot.scss'
import './../../../components/Popupwindow/Popupwindow.scss'
import { Box, Typography, Button, Stack, TextField, Avatar, InputAdornment, IconButton } from "@mui/material";
import { ReactComponent as CopyIcon } from '../../../assets/icons/copyIcon.svg'
import { aiChatMessage, getAIChatHistory } from '../../../services/ChatGptService'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as SendIcon } from '../../../assets/icons/gpt-send.svg'
import { ReactComponent as BotLogo } from '../../../assets/icons/chatbot-logo.svg'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import moment from 'moment';
import CloseMaximizeIcon from "../../../assets/icons/closeMaximize.svg";
import MinimizeIcon from "../../../assets/icons/minimizeIcon.svg";
import MaximizeSendIcon from "../../../assets/icons/maximizeSend.svg";
import GenericGptIcon from "../../../assets/icons/genericChatGPT.svg";
import socketContext from '../../../contexts/SocketContext/SocketContext'
import { convertToDisplayDateTimeSecond } from '../../../utils/date-utils';
import { ReactComponent as ResetLogo } from '../../../assets/icons/reset.svg'
import EnahanceChatIcon from "../../../assets/icons/chatBotEnhanceIcon.svg"
import LoginIcon from '@mui/icons-material/Login';
const EnahanceChatBot = ({enhanceReqObj,setEnhanceReqResponse,enhanceReqResponse,setEnhanceChatbotStatus,setEnahanceJoDescription,handleInsert,minimizeHandler,chatbotMinizeEnableStatus,setChatbotMinizeEnableStatus}:any) => {
    console.log("enhanceReqObj",enhanceReqObj)
    const ModalRef = useRef(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const MinimizeRedux = useAppSelector((state) => state.minimizeState)
    const LoaderRedux = useAppSelector((state) => state?.loaderState )
    const gptRedux = useAppSelector((state) => state.chatGPT)
    const [dailougeOpen, setDailougePpen] = useState<boolean>(false)
    const [chatData, setChatData] = useState<any[]>(enhanceReqResponse)
    const [threadId, setThreadId] = useState<string>('')
    const [quesId, setQuesId] = useState<number>(0)
    const [textSearch, setTextSearch] = useState<string>("")
    const [isEndId, setIsEndId] = useState<number>(0)
    const [regeneratePayload, setRegeneratePayload] = useState<any>({})
    const [pageNo, setPageNo] = useState<number>(-1);
    const [totalPages, setTotalPages] = useState<number>(1)
    const [loadOld, setLoadOld] = useState(true);
    const [maximizeState, setMaximizeState] = useState(false);
    const [openChatId,setOpenChatId] = useState(0);
    const [historyId,setHistoryId] = useState("");
    const [previousLocation,setPreviousLocation] = useState("")
    const userState = useAppSelector((state) => state?.UserDetail?.userId)
    const stompClient:any = useContext(socketContext)
    const callNotificationTimer = useAppSelector((state) => state?.SystemConfig?.smartFlowIncomingData)
    const [copiedStatus, setCopiedStatus] = useState({
        status:false,
        index: ""
    })
    const [userChatText, setUserChatText] = useState("")

    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const pathVal = location?.pathname?.split('/')
    const isMandateView = (pathVal[1] === "mandates") && !!location.search

    const clickopen = () => {
        setDailougePpen(true)
    }
    useEffect(()=>{
    if((pathVal[1] === "mandates") && !!location.search){
        setChatData([])
        setPreviousLocation("mandate_detail")
        setQuesId(0)
        setOpenChatId(0)
        setPageNo(-1)
        setTotalPages(1)
        setHistoryId("")
    }
    else if(location?.search==="" && previousLocation==="mandate_detail"){
        setChatData([])
        setPreviousLocation("mandates_list")
        setQuesId(0)
        setOpenChatId(0)
        setPageNo(-1)
        setTotalPages(1)
        setHistoryId("")
    }
    },[location])
    const clickclose = (type?:string) => {
        setEnhanceChatbotStatus(false);
        setChatbotMinizeEnableStatus(false)
    }
    const CopyToClipboard = async (containerid: any,indexId:string) => {
        try {
            await navigator.clipboard.writeText(containerid)
            console.log('Content copied to clipboard')
            setCopiedStatus({
                status:true,
                index: indexId
            })
            setTimeout(() => {
                setCopiedStatus({
                    status:false,
                    index: ""
                })
            },3000)
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }
    function stripHTMLTags(str:any) {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    }
    const chatAPIMessage = async (searchText = "", userObj: any = null, qId = quesId, list: any = null) => {
        const screen = location?.pathname?.split('/')

        // {
        //     "questionId":23,
        //     "moduleScreen":"enhancer",
        //     "isEnd":1,
        //     "message":"{mandate_title: java developer, location: chennai, work_mode: onsite}  ",
        //     "threadId":"",
        //     "openChatEnabled":1
        // }
        // const plainString = (text:string) => {
        //     const plainText = Object.entries(text)
        //     .map(([key, value]) => `${key}: ${value}`)

        //     return plainText
        // }
        const userChat = qId == 22 ? stripHTMLTags(searchText) : ""
        const userTextChatString = userChatText.trim().length > 0 ? userChatText + ":" + userChat : userChat        
        let reqObj: any = {
            questionId: qId ?? 0,
            moduleScreen: "enhancer",
            isEnd: (qId == 22  || qId == 23) ? 1 : 1, 
            message: qId == 22 ? stripHTMLTags(userTextChatString) : qId == 23 ? "Enhance Job description Using Mandate specs" : searchText,
            threadId: threadId,
            openChatEnabled: qId == 22 ? 1 : qId == 23 ? 0 : openChatId
        }
        if(qId == 23) reqObj["answers"] = searchText
        if (list?.requiredData) {
            const data = JSON.parse(list?.requiredData)
            const answers: any = {};
            Object.keys(data).forEach(x => answers[x] = document.getElementById(data[x])?.textContent)
            reqObj = { ...reqObj, answers }
        }
        setRegeneratePayload(reqObj)
        try {
            const res = await aiChatMessage(reqObj)
            
            if (res?.status === 200) {
                const ansObj = {
                    type: "ChatBot",
                    data: res?.data?.result?.data
                }
                setUserChatText(res?.data?.result?.data[0].contentText)
                setTextSearch('')
                setThreadId(res?.data?.result?.threadId)
                if(userObj){
                    setChatData([...chatData, userObj, ansObj]);
                    setEnhanceReqResponse([...chatData, userObj, ansObj])
                 }else{
                    setChatData([...chatData, ansObj])
                    setEnhanceReqResponse([...chatData, ansObj])
                 } 
            }
        } catch (error) {
            console.log(error)
        } finally {
            const element: any = document.getElementById('chat-scroll')
            setTimeout(() => {
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100)
        }
    }


    const handleUserText = (searchText: any, qId: number, list: any) => {
        const obj: any = {
            type: "userText",
            data: { text: searchText, sendAt: convertToDisplayDateTimeSecond(list?.sendAt)}
        }
        chatAPIMessage(searchText, obj, qId, list)
    }
    const handleMinimizePopup = () => {
        minimizeHandler()
        const element: any = document.getElementById('chat-scroll')
        setTimeout(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth',
            })
        }, 100)
    }
    
    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        const isAtTop = scrollTop === 0;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
        if (isAtTop) {
            // Scrolled near the top, load more older messages
        } else if (isAtBottom) {
            // handleLoadNew()
        }

    };
    const covertTextForjoDesc = (plainText: string) => {
        const formattedText = plainText.replace(/\n+/g, (match) => {
            return '<br>'.repeat(match.length);
        });
        return `<p>${formattedText}</p>`;
    }
    const insertText = (text:string) => {
        handleInsert({value: covertTextForjoDesc(text),timeStamp: new Date().getTime()})
    }

    const renderAiContent = (data: any, indexID: number,dataIndex:any) => {
        return (
            <Box className="ai-chat-wrap">
               {
                data?.length > 0 &&
                <Avatar style={{ width: "30px", height: "30px" }} className={data[dataIndex]?.openChatEnabled===2?"addBorder":""}>
                    {
                        data[dataIndex]?.openChatEnabled ? (
                            <img src={GenericGptIcon} style={{ width: "30px", height: "30px" }} />
                        ) : (
                            <BotLogo style={{ width: "30px", height: "30px" }} />
                        )
                    }
                </Avatar>
               }
                
                <Box className="chat-text-wrap" >
                    {data?.map((list: any, index: number) => {
                        const dataType = list?.contentType
                        return (
                            <div key={index} className="msg-cont">
                                {dataType === "button" ?
                                    <div className={`chat-btn ${list?.contentText.split(" ").slice(0, 2).join("-")}`} onClick={() => { setQuesId(list?.id); handleUserText(list?.contentText, list?.id, list) }} >
                                        {list?.contentText}
                                    </div> : <div className="answer-conatiner">
                                        <div className='date-text-cls'>{convertToDisplayDateTimeSecond(list?.sendAt)}</div>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start"}}>
                                        <div className="chat-text" id={`copiyable${indexID}`} style={{ whiteSpace: 'pre-line' }}>{list?.contentText}</div>
                                        <div className="copy-icon-container" onClick={() => CopyToClipboard(list?.contentText, indexID.toString())} style={{marginLeft:"17px"}}>{(copiedStatus.status && copiedStatus.index == indexID.toString()) && <span>Text Copied!</span>}<CopyIcon /></div>
                                        <div className="insert-icon-container" onClick={() => {insertText(list?.contentText)}}><LoginIcon /> Insert</div>
                                        </div>
                                    </div>}

                            </div>
                        )
                    })}
                </Box>
            </Box>
        )
    }
    useEffect(() => {
        if (MinimizeRedux?.minimize === true) {
            clickopen()
        }
        if (MinimizeRedux.questionId) {
            setQuesId(MinimizeRedux.questionId)
        }
        if (MinimizeRedux?.isEndId === 1) {
            setIsEndId(1)
        }
    }, [MinimizeRedux])
    useEffect(()=>{
        const element: any = document.getElementById('chat-scroll')
        if(element && dailougeOpen && quesId!=0){
            setTimeout(() => {
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100)
        }
    },[dailougeOpen])

    useEffect(() => {
        const obj: any = {
            type: "userText",
            data: { text: enhanceReqObj?.suggestedText, sendAt: moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss') }
        }
        console.log("enhanceReqObj",enhanceReqObj)
        let joDescription = "";
        if("jobDescription" in enhanceReqObj){
            joDescription = enhanceReqObj?.jobDescription
        }else{
            const enhanceReqObjData = JSON.parse(JSON.stringify(enhanceReqObj))
            delete enhanceReqObjData["suggestedText"]
            joDescription = enhanceReqObjData
        }
        if(!chatbotMinizeEnableStatus)chatAPIMessage(joDescription , obj,"jobDescription" in enhanceReqObj ? 22 : 23,null)
    },[enhanceReqObj])

    const regenerateChatCall = async () => {
        const reqObj = { ...regeneratePayload }
        try {
            const res = await aiChatMessage(reqObj)
            if (res?.status === 200) {
                const ansObj = {
                    type: "ChatBot",
                    data: res?.data?.result?.data
                }
                if (threadId === '') {
                    setThreadId(res?.data?.result?.threadId)
                }
                setQuesId(res?.data?.result?.data[0]?.id)
                setIsEndId(res?.data?.result?.data[0]?.isEnd)
                setTextSearch('')
                setChatData([...chatData, ansObj])
            }
        } catch (error) {
            console.log(error)
        } finally {
            const element: any = document.getElementById('chat-scroll')
            setTimeout(() => {
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100)
        }
    }
    return (
        <>
            <div className="overlay">
                <Box className="ai-support-container" ref={ModalRef}>
                    <Box>
                            <Box className="chat-container maximize-cls" style={{ height: isMandateView ? "590px" : "574px",maxHeight: "80vh" }}>
                                <Box className="header-wrapper header-cls-maximize">
                                    <Box>
                                            <Typography className="header-text header-text-maximize"><img src={EnahanceChatIcon} />Elevate your Job Description with AI</Typography>
                                    </Box>
                                    <div className="rightSideHeader" style={{ display: "flex", alignItems: "flex-start" }}>
                                            <img src={CloseMaximizeIcon} style={{ marginRight: "13px", cursor: "pointer" }} onClick={()=>{clickclose("minimize");setEnhanceReqResponse([])}} />
                                            <img src={MinimizeIcon} style={{ marginTop: "2px", cursor: "pointer" }} onClick={handleMinimizePopup} />
                                        </div>

                                </Box>
                                <Box id='chat-scroll' className="conversation-container"
                                    ref={containerRef} onScroll={handleScroll}>
                                    <Box className="chat-wrapper">
                                        {chatData?.map((chat: any, index: number) => {
                                            return (
                                                <Box key={index} style={{ width: "100%" }}>
                                                    {chat?.type === "ChatBot" && renderAiContent(chat?.data, index,chat?.data?.map((val:any,index:number)=>index))}
                                                    {chat?.type === "userText" && <div className="user-text-wrapper" >
                                                        <div className='chat-col-cls'>
                                                            <div className='date-text-cls'>{chat?.data?.sendAt}</div>
                                                            <div className="user-text" >{chat?.data?.text}</div>
                                                        </div>
                                                    </div>}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box className="input-wrapper">
                                    <div className="regenerate-wrap" onClick={regenerateChatCall} style={{ justifyContent: "center", marginBottom: "0px", alignItems: "center" }}>
                                        <ResetLogo />
                                        <div className="regen-text" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Regenerate Response</div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <TextField
                                            style={{width: "60%"}}
                                            value={textSearch}
                                            onChange={(e: any) => setTextSearch(e.target.value)}
                                            className="text-field-wrap"
                                            placeholder="Send a message."
                                            multiline
                                            maxRows={maximizeState ? 4 : 2}
                                            autoComplete="off"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={() => {
                                                            const obj: any = {
                                                                type: "userText",
                                                                data: { text: textSearch, sendAt: moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss') }
                                                            }
                                                            chatAPIMessage(textSearch, obj , 22, null)
                                                            setTextSearch('')
                                                        }} >
                                                            {
                                                                maximizeState ?
                                                                    <img src={MaximizeSendIcon} className="send-icon" /> :
                                                                    <SendIcon className="send-icon" />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            // onKeyDown={(e) => {
                                            //     if (e.key === 'Enter' && textSearch) {
                                            //         chatAPIMessage(textSearch, {
                                            //             type: "userText",
                                            //             data: { text: textSearch, sendAt: moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss') }
                                            //         } 
                                            //         )
                                            //         setTextSearch("")
                                            //     }
                                            // }}
                                        />
                                            <div className="chatgpt-text-maximize">ChatGPT can make mistakes. Consider checking important information.</div>
                                        </div>
                                </Box>

                            </Box>
                       


                    </Box>
                </Box>
            </div>
        </>
    )
}
export default EnahanceChatBot