import { Box, Menu, MenuItem } from '@mui/material'
import { Settings, Preferences, SignOut } from '../../Actions'
import { useAppSelector } from '../../../store/hooks'
import NoProfilePhoto from "../../../assets/images/no_profile_photo.png";
import userIcon from "../../../assets/icons/userProfileIcon.svg"
import userLogoutIcon from "../../../assets/icons/userLogoutIcon.svg"
import { useNavigate } from 'react-router-dom';

interface DefaultMenuProps {
  isMenuOpen: boolean
  handleMenuClose: () => void
  handleLogout:()=>void
  anchorEl: HTMLElement | null
}

export const DefaultMenu = ({ isMenuOpen, handleMenuClose, anchorEl, handleLogout }: DefaultMenuProps) => {
  const userDetails = useAppSelector((state) => state?.UserDetail)
  const navigate = useNavigate()
  return (
  <Menu
    anchorEl={anchorEl}
    id='primary-search-account-menu'
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={isMenuOpen}
    onClose={handleMenuClose}
    className='user-menu'
  >
    <Box className="profileContentSect">
      <img src={NoProfilePhoto} />
      <Box className="profileContentDetailSect">
          <Box className="nameField">{userDetails?.firstName}</Box>
          <Box className="userRole">{userDetails?.userRole}</Box>
      </Box>
    </Box>
    {/* <MenuItem onClick={handleMenuClose} className='user-menu-item'>
      <Settings disableTooltip />
      Settings
    </MenuItem> */}
    <MenuItem onClick={() => {navigate('/UserProfile');handleMenuClose()}} className='user-menu-item'>
      {/* <Preferences disableTooltip /> */}
      <img src={userIcon} />
      My Profile
    </MenuItem>
    <MenuItem onClick={handleLogout} className='user-menu-item'>
      {/* <SignOut disableTooltip /> */}
      <img src={userLogoutIcon} />      
      Logout
    </MenuItem>
  </Menu>
)
}