import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import ILoginData from '../../types/Login'
import LoginService from '../../services/LoginService'

interface AuthState {
  loading: boolean
  token: any | null
  error: any | null
}
const initialUserState: AuthState = {
  loading: false,
  token: null,
  error: null
}

// generates pending, fulfilled and rejected action type(Redux-thunk)
export const login = createAsyncThunk('auth/login', async (logindata: ILoginData, { rejectWithValue }) => {
  try {
    const { data }: any = await LoginService.login(logindata)
    console.log('data', data)
    localStorage.setItem('access_token', data.access_token)
    return data
  } catch (error: any) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

// logout
export const logout = createAsyncThunk('auth/logout', async (logoutdata: string, { rejectWithValue }) => {
  try {
    const { data } = await LoginService.logout(logoutdata)
    return data
  } catch (error: any) {
    // return custom error message from API if any
    // if (error.response && error.response.data.message) {
    //   return rejectWithValue(error.response.data.message)
    // } else {
    //   return rejectWithValue(error.message)
    // }
    return {};
  }
})

// Reducer for Authorization
const authSlice = createSlice({
  name: 'auth',
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false
      console.log(action.payload)
      state.token = action.payload
      state.error = null
    })
    builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
      console.log('error action', action.payload)
      state.loading = false
      state.token = null
      state.error = action.payload || 'error occured'
    })
    builder.addCase(logout.pending, (state) => {
      state.loading = true
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.loading = false
      state.token = null
      localStorage.removeItem('access_token')
      state.error = null
    })
    builder.addCase(logout.rejected, (state, action: PayloadAction<any>) => {
      console.log('error action', action.payload)
      state.loading = false
      state.error = action.payload || 'error occured'
    })
  },
})

export default authSlice.reducer
