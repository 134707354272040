import { Box, Grid, Menu, MenuItem, Tooltip, useMediaQuery } from '@mui/material'
import CandidateSearchResults from '../components/Candidates/CandiateSearchResults'
import CandidateHeaderSearch from '../components/Candidates/CandiateHeaderSearch'
import CandidateSidebarSearch from '../components/Candidates/CandiateSidebarSearch'
import '../components/Candidates/styles/CandidateListing.scss'
import React, { useEffect, useState } from 'react'
import { resetFilterPreloadData, resetFilters, setFilterValues } from '../features/filters/FilterReducer'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { decode, encode } from 'base-64';
import CandidateHeaderTabs from '../components/Candidates/CandidateHeaderTabs'
import { Breadcrumb } from '../components/Breadcrumb'
import _ from 'lodash'
import { setPersistFilter, resetPersistFilter } from '../features/filters/PersistFilter'
import { AppButton } from '../components/AppComponents/AppButton'
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg'
import { CvProfileUploadModal } from '../components/Mandates/UploadModal/CvProfileUploadModal'
import { ReactComponent as UploadIcon } from '../assets/icons/upload.svg'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../utils/constants'
import { ReactComponent as AddNewIcon } from '../assets/icons/add-new.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as CandidateExport } from "../assets/icons/candidateExport.svg"
import { toast } from "react-toastify";
import { getUploadedResumeData } from '../services/CandidateService'

const initialFilterVal: any = {
  mandateId: { value: '', label: '' },
  mLocationId: null,
  keyword: '',
  matchCriteria: '',
  searchQueryId: '',
  confidenceScore: [0, 100],
  booleanSearch: false,
  tags: [],

  profileType: 'All',
  location: '',
  preferredLocation: '',
  primarySkills: [],
  secondarySkills: [],
  education: '',
  company: '',
  designation: '',
  salaryRange: [0, 100],
  experience: [0, 30],
  lastActivity: -1,
}

const initialManTab: any = {
  manTab1: null,
  manTab2: null,
  manTab3: null
}
const initialLocationTab: any = {
  locTab1: null,
  locTab2: null,
  locTab3: null
}
const initialKeysTab: any = {
  keyTab1: null,
  keyTab2: null,
  keyTab3: null,
}
const initialListTab: any = {
  searchList0: [],
  searchList1: [],
  searchList2: [],
  searchList3: [],

}
const initialCountTab = {
  searchCount0: 0,
  searchCount1: 1,
  searchCount2: 2,
  searchCount3: 3
}
const CandidateListing = () => {
  const location = useLocation();
  const persistFilter: any = useAppSelector((state) => state.PersistFilterReducer.persistFilter)
  const filters: any = useAppSelector((state) => state.filtersReducer.filters)
  const [tabVal, setTabVal] = useState<0 | 1 | 2 | 3>(persistFilter?.persistTab ? persistFilter?.persistTab : 0)
  const [searchFilter, setSearchFilter] = useState<any>(filters?.advFilters?.[`advfilTab${tabVal}`] ?? {})
  const [immediateFilter, setImmediateFilter] = useState<any>("")
  const [searchParams, setSearchParams] = useSearchParams();
  const [advancedFilters, setAdvancedFilters] = useState<any>(persistFilter?.persistAdvancedFilters ?? {})
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
  const [searchListData, setSearchListData] = useState<any[]>(persistFilter?.listData ?? initialListTab);
  const [manualLoad, setManualLoad] = useState<any>(false);
  const [searchListCount, setSearchResultCount] = useState(persistFilter?.searchCount ?? initialCountTab);

  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));

  const dispatch: any = useAppDispatch()
  const filterValue = useAppSelector((state) => state?.filtersReducer?.filters)
  const [cvProfileModalStatus, setCvProfileModalStatus] = useState(false)
  const [applyFilterStatus, setApplyFilterStatus] = useState<any>({
    status: "",
  })
  const fileExtension = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "text/csv"]
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const candidateOpenPopup = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate()
  const handleCreateCandidate = () => {
    navigate(`/createCandidate`)
  }
  const handleFileChange = async (files: any) => {
    const file = files && files[0];
    const mandateData = new FormData()    
    mandateData.append('candidateCv', file)
    try{
        const response = await getUploadedResumeData (mandateData)
        if (response?.status == 200 || response?.status == 201) {
            console.log(response)
            if(response.data.resumeData.status.indexOf("failed") > -1){
              toast.error(response.data.resumeData.Message)
            }else{
              navigate(`/createCandidate`,{state:{candidateDetail: response.data,status:"upload",fileAttach:file}})
            }
        }
      }
      catch(error:any){
        if (error?.response?.status == 500 || error?.response?.status == 409) {
            toast.error("response" in error ? error?.response?.data?.message : error?.message)
        }
    }    
  };

  const [selectedMandate, setSelectedMandate] = useState<any>(persistFilter?.persistMandate ?? initialManTab)
  const [selectedLocation, setSelectedLocation] = useState<any>(persistFilter?.persistLocation ?? initialLocationTab)
  const [selectedKeywords, setSelectedKeywords] = useState<any>(persistFilter?.keywordsPersist ?? initialKeysTab)
  const [closeFilterTab, setCloseFilterTab] = useState(false)
  useEffect(() => {
    (selectedKeywords?.[`keyTab${tabVal}`]?.length == 0) ? setCloseFilterTab(true) : setCloseFilterTab(false)
  }, [selectedKeywords])

  const handleTabInitValset = () => {
    const tabData: any = {
      tab0: { ...initialFilterVal },
      tab1: { ...initialFilterVal },
      tab2: { ...initialFilterVal },
      tab3: { ...initialFilterVal }
    }
    if (!_.isEmpty(persistFilter)) {
      const tab = persistFilter?.selectedTab
      return { ...tabData, [`tab${tab}`]: persistFilter?.filterVal }
    } else {
      return tabData
    }
  }

  const [tabFilters, setTabFilters] = useState<any>(handleTabInitValset())

  useEffect(() => {
    const searchPayload = tabFilters[`tab${tabVal}`]
    setSearchFilter(searchPayload)
    // old code commented
    // dispatch(setFilterValues(searchPayload))
    // dispatch(setFilterValues({...searchPayload,searchQueryId:filters?.searchQueryId&&filters?.searchQueryId!=''?filters?.searchQueryId:searchPayload?.searchQueryId}))
  }, [tabFilters])

  useEffect(() => {
    if (!_.isEmpty(persistFilter) && _.isEmpty(filterValue)) {
      const tabValPersist = persistFilter?.selectedTab
      setSelectedMandate({ ...selectedMandate, [`manTab${tabValPersist}`]: persistFilter?.selectMandate })
      // setTabVal(tabValPersist)
    }
    // return () => {
    //   dispatch(resetPersistFilter())
    //   dispatch(resetFilters())
    //   dispatch(resetFilterPreloadData({}))
    // };
  }, []);

  useEffect(() => {
    if (!!location?.state && location?.state !== "") {
      setSearchFilter(location?.state?.searchFilter)
      // dispatch(setFilterValues(location?.state?.searchFilter)) 
      if (location?.state?.searchFilter?.module === "rpa") {
        setTabVal(2)
      }
      // else{
      //   dispatch(resetFilterPreloadData({}))
      // }
    }
  }, [location?.state])

  let showFilterUICallback: any;

  const showFilterCallback = (callback: any) => {
    showFilterUICallback = callback;
  }

  const onShowFilter = () => {
    if (showFilterUICallback) {
      showFilterUICallback();
    }
  }

  const removeErrorParam = () => {
    if (searchParams.has('search')) {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  }

  const handleSetPersist = () => {
    const obj = {
      ...persistFilter,
      selectedTab: tabVal,
      filterVal: tabFilters[`tab${tabVal}`],
      selectMandate: tabVal === 0 ? null : selectedMandate[`manTab${tabVal}`]
    }
    setTimeout(() => {
      dispatch(setPersistFilter(obj))
    }, 500)
  }
  useEffect(() => {
    dispatch(setPersistFilter({ ...persistFilter, listData: searchListData }))
  }, [searchListData])
  useEffect(() => {
    // if(Object.keys(advancedFilters)?.length >0){
    //   dispatch(setFilterValues({...tabFilters,advancedFilters}))
    // }
    if (Object.keys(advancedFilters)?.length > 0) {
      dispatch(setPersistFilter({ ...persistFilter, persistAdvancedFilters: advancedFilters, listData: searchListData }))
    }
  }, [advancedFilters])
  useEffect(() => {
    if (Object.keys(searchFilter)?.length) {
      dispatch(setFilterValues({ ...filters, advFilters: { ...filters?.advFilters, [`advfilTab${tabVal}`]: searchFilter } }))
    }
    // console.log(searchFilter,'checkhere')
  }, [searchFilter])

  const renderBaseLayout = () => {
    return (
      <Box className="candidate-listing-container">
        <Box className="candidate-listing-left-section">
          <CandidateHeaderTabs
            immediateFilter={immediateFilter}
            setImmediateFilter={setImmediateFilter}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedKeywords={selectedKeywords}
            setSelectedKeywords={setSelectedKeywords}
            tabVal={tabVal}
            setTabVal={setTabVal}
            tabFilters={tabFilters}
            setTabFilters={setTabFilters}
            selectedMandate={selectedMandate}
            setSelectedMandate={setSelectedMandate}
            handleTabInitValset={handleTabInitValset}
            initialManTab={initialManTab}
            setAdvancedFilters={setAdvancedFilters}
            advancedFilters={advancedFilters}
            setSearchListData={setSearchListData}
            searchListData={searchListData}
            setApplyFilterStatus={setApplyFilterStatus}
          />
          {/* <CandidateHeaderSearch onShowFilter={onShowFilter} searchFilter={searchFilter} setSearchFilter={setSearchFilter} removeErrorParam={removeErrorParam} immediateFilter={immediateFilter} setImmediateFilter={setImmediateFilter} /> */}
          <CandidateSearchResults tabVal={tabVal} searchFilter={searchFilter} immediateFilter={immediateFilter} setImmediateFilter={setImmediateFilter} handleSetPersist={handleSetPersist} advancedFilters={advancedFilters} setAdvancedFilters={setAdvancedFilters}
            selectedKeywords={selectedKeywords} searchListCount={searchListCount} setSearchResultCount={setSearchResultCount}
            searchListData={searchListData} setSearchListData={setSearchListData} manualLoad={manualLoad} applyFilterStatus={applyFilterStatus} setApplyFilterStatus={setApplyFilterStatus} />
        </Box>
        <Box className={`candidate-listing-right-section ${!isSmallScreen && "sticky-filter"}`}>

          <CandidateSidebarSearch
            showFilterCallback={showFilterCallback}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            setAdvancedFilters={setAdvancedFilters}
            tabVal={tabVal}
            tabFilters={tabFilters}
            setTabFilters={setTabFilters}
            advancedFilters={advancedFilters}
            searchListData={searchListData}
            selectedMandate={selectedMandate}
            selectedKeywords={selectedKeywords}
            setManualLoad={setManualLoad}
            setApplyFilterStatus={setApplyFilterStatus}
            applyFilterStatus={applyFilterStatus}
            closeFilterTab={closeFilterTab}
          />
        </Box>
      </Box>
    )
  }

  // return null;

  return (
    <>
      <Box className="cvUploadProfileSect">
        <Breadcrumb title='Candidate Search' />
        <Box className="candidateSearchTopSect">
          <AppButton
            variant='contained'
            bgcolor={PRIMARY2}
            margin={5}
            textColor={TERTIARY12}
            // width={'80px'}
            className='class-new-button'
            onClick={handleClick}
          // tooltip='Add New Candidate'
          >
            <AddNewIcon style={{ marginRight: '10px' }} /> New
            <KeyboardArrowDownIcon style={{ marginLeft: '15px' }} />
          </AppButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={candidateOpenPopup}
            onClose={handleClose}
            className='candiateCreatePopup'
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleCreateCandidate}><AddIcon /> Create New</MenuItem>
            <MenuItem><CandidateExport />
              <input
                type="file"
                onChange={(event: any) => {
                  if (fileExtension.includes(event?.target?.files?.[0]?.type)) {
                    if (event?.target?.files?.[0]?.size <= 5000000) {
                      handleFileChange(event.target.files)
                    } else {
                      toast.error('Max file size is 5MB')
                    }
                  } else {
                    toast.error('Upload file type not accepted')
                  }
                }}
                className="hidden"
                id="candidateUpload"
                accept=".doc,.docx,.pdf"
              />
              <label htmlFor="candidateUpload" className="cursor-pointer"><p style={{margin:"0px"}}>Upload</p></label>
            </MenuItem>
          </Menu>
          {/* <Box className='download-btn'>
          {(userPermissions?.candidate_module?.create?.candidate_upload?.selected && userRole != "Admin") && <AppButton
                      label=''
                      bordercolor='#FF6E00'
                      bgcolor='#FF6E00'
                      size='large'
                      type='button'
                      className={`download-btn `}
                      sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                      onClick={() => {setCvProfileModalStatus(true)}}
                    >
                    <Tooltip title="Cv Profile Upload">
                      <UploadIcon />
                    </Tooltip>
                    </AppButton>}
                    {(userRole == "Admin") && <AppButton
                      label=''
                      bordercolor='#FF6E00'
                      bgcolor='#FF6E00'
                      size='large'
                      type='button'
                      className={`download-btn `}
                      sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                      onClick={() => {setCvProfileModalStatus(true)}}
                    >
                    <Tooltip title="Cv Profile Upload">
                      <UploadIcon />
                    </Tooltip>
                    </AppButton>}
          </Box> */}
        </Box>
      </Box>
      <Box className="candidate-listing-wrapper">
        {renderBaseLayout()}
      </Box>
      {cvProfileModalStatus && <CvProfileUploadModal
        open={cvProfileModalStatus}
        handleClose={() => { setCvProfileModalStatus(false) }}
        title='Upload Candidate Profile'
      />}
    </>
  )
}

export default CandidateListing
