import React, { useState } from 'react';
import "./MonthlyFunActivePopup.scss"
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AprilPrankCelebrationImg from "../../../assets/images/aprilPrankCelebrationImg.png" 
import CelebrationCone from "../../../assets/images/congrateImg.png" 
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { hideMonthlyCard } from '../../../features/systemConfig/SystemConfig';

const MonthlyFunActivePopup = () => {
    const isWelcomeRotateMessage = useAppSelector((state) => state?.SystemConfig?.welcomePopupContent);
    const isWelcomeCloseImg = useAppSelector((state) => state?.SystemConfig?.welcomePopupClose);
    const userName = useAppSelector((state) => state?.UserDetail?.firstName);
    const dispatch = useAppDispatch()
    const [hidePopupContent, setHidePopupContent] = useState(false)
    const [prankImg, setPrankImg] = useState("")
    const loadRandomeContent = (contentArray:any) => {
        const randomeNumner = Math.floor(Math.random() * contentArray.length)
        return contentArray[randomeNumner]
    }
    const ClosePopup = () => {
        if(isWelcomeCloseImg.length > 0){
            setHidePopupContent(true)
            setPrankImg(isWelcomeCloseImg[Math.floor(Math.random() * isWelcomeCloseImg.length)])
            setTimeout(() =>{
                dispatch(hideMonthlyCard())
            },5000)
        }else{
            dispatch(hideMonthlyCard())
        }
        
    }
    return (
        <Box className='monthlyFunActivity'>
            <Box className="monthlyFunActivitySect">
                {!hidePopupContent ? 
                <>
                <CloseIcon className='close' onClick={() => {ClosePopup()}}/>
                <Box className="monthlyFunActivitySectContent">
                    <Box className="monthlyFunActivitySectContentBlk">
                        {/* <h4>Hi {userName} !</h4> */}
                        <p dangerouslySetInnerHTML={{ __html: (isWelcomeRotateMessage && isWelcomeRotateMessage.length > 0) ? loadRandomeContent(isWelcomeRotateMessage):"Happy Day!"}} />
                    </Box>                    
                    <img src={CelebrationCone} />
                </Box>
                </>
                :<>
                    <Box className="aprilFoolSect">
                        <Box className="aprilFoolSectContent">
                            {/* <h4>Hi {userName} !</h4> */}
                            <p dangerouslySetInnerHTML={{ __html: prankImg }} />
                        </Box>                        
                        <img src="https://media.tenor.com/ooAz6cBL6FcAAAAj/funny-laughing.gif" />
                    </Box>
                    
                </>
                }
                
            </Box>
        </Box>
    );
};

export default MonthlyFunActivePopup;