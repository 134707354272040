import { useEffect, useState } from 'react'
import socketContext from './SocketContext'
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

let socketUrl = `${process.env.REACT_APP_API_ENDPOINT}`
if(socketUrl.indexOf("https://api.artemis.xpheno") == 0){
  const replaceSocket = socketUrl.split("/")
  replaceSocket[replaceSocket.indexOf("api")] = "websocket"
  socketUrl = replaceSocket.join("/")
}else{
  socketUrl = socketUrl?.replace('api', 'websocket');
}
export const SocketProvider = ({children}:any) => {
  const [socket, setSocket] = useState<any>(null)

  useEffect(()=>{
    const socket = new SockJS(socketUrl)
    const stompClient = Stomp.over(socket)

    if(stompClient){
      try{
        stompClient.connect({}, ()=>{
          console.log("socket connected")
          setSocket(stompClient)
        })
      }catch(error){
        console.log("socket not connected")
      }
    }

    return () => {
      if(stompClient){
        try{
          stompClient.disconnect(()=> console.log("socket disconnected"))
        }catch(error){
          console.log("socket not connected")
        }
      }
    }
  },[])
  return (
    <socketContext.Provider value={socket}>
      {children}
    </socketContext.Provider>
  )
}