import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material'
import { AppButton } from '../AppButton'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { TERTIARY12, TERTIARY3, PRIMARY3, SECONDARY2 } from '../../../utils/constants'
import './AppDialog.css'

type AppDialogProps = {
  title: string
  subtitle?: string
  children?: React.ReactNode
  isOpen: boolean
  handleClose: () => void
  handleConfirm?: () => void
  cancelButtonText?: string
  OkButtonText?: string
  maxWidth?: string
  btnWidth?:string
}

export const AppDialog = (props: AppDialogProps): React.ReactElement => {
  const {
    title,
    subtitle,
    children,
    isOpen,
    handleClose,
    handleConfirm,
    cancelButtonText = 'Cancel',
    OkButtonText = 'Delete',
    btnWidth
  } = props

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'xs'} className='app-modal-dialog'>
      <DialogTitle className='app-dialog-title'>
        {title}
        <IconButton aria-label='close' onClick={handleClose} className='app-dialog-title-icon'>
          <CloseIcon style={{cursor:"pointer"}} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className='app-dialog-content'>{subtitle}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <AppButton
          variant='contained'
          bgcolor={TERTIARY3}
          onClick={handleClose}
          margin={10}
          textColor={PRIMARY3}
          fontSize='14px'
          fontWeight={400}
          label={cancelButtonText}
          width='96px'
        ></AppButton>

        <AppButton
          variant='contained'
          bgcolor={SECONDARY2}
          onClick={handleConfirm}
          textColor={TERTIARY12}
          fontSize='14px'
          fontWeight={400}
          label={OkButtonText}
          width= {btnWidth ? btnWidth : '96px'}
        ></AppButton>
      </DialogActions>
    </Dialog>
  )
}
