import { Autocomplete, Box, Button, Chip, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'

const CandidateSkills = () => {
    const { control, formState: { errors }, getValues, setValue, clearErrors, trigger }: any = useFormContext();
    
    const { fields: attachmentFields, append : attachmentAppend, remove: attachmentRemove } = useFieldArray(
        {
          control,
          name: "academicDetails"
        }
      );
      const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
      ];
    return (
        <>
            <Grid className='container-wrapper'>
                <Box className="personalInfoWrapper" style={{flexDirection:"column"}}>
                    <Box className="personalInfoFieldWrapper candidateSkillsSect">
                    <FormControl fullWidth required style={{width: "50%"}}>
                                        {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                                        <Controller
                                            name={"candidateSkills"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "This field is required",
                                                }
                                            }}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                              <Autocomplete
                                              clearIcon={false}
                                              multiple
                                              id="tags-filled"
                                              options={[]}
                                              defaultValue={value}
                                              freeSolo
                                              onChange={(event, value) => {
                                                  onChange(value)
                                              }}
                                              renderTags={(value: readonly string[], getTagProps) =>
                                                value.map((option: string, index: number) => {
                                                  const { key, ...tagProps } = getTagProps({ index });
                                                  return (
                                                    <Chip variant="outlined" label={option} key={key} {...tagProps} />
                                                  );
                                                })
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="filled"
                                                  label="Primary skills"
                                                  required={true}
                                                  placeholder="Skills"
                                                />
                                              )}
                                            />
                                            )}
                                        />
                                        {errors?.candidateSkills && (
                                            <span className="reminder_text-danger select-danger-text" role="alert">
                                                {errors?.candidateSkills?.message}
                                            </span>
                                        )}
                                    </FormControl>
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

export default CandidateSkills;