import { 
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Table, 
  TableBody, 
  TableCell, 
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Checkbox,
  Button,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Pagination
} from '@mui/material';
import './ListView.scss';
import { useState } from 'react';
import ClientLogo from '../../../assets/ClientLogo.png'
import draftIcon from '../../../assets/icons/draftIcon.svg'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg'
import { ReactComponent as ShowMore } from '../../../assets/icons/showmore.svg'
import { convertToDisplayDate, convertToDisplayDateTime } from '../../../utils/date-utils'
import { PRIMARY1 } from '../../../utils/constants'
import { Loading } from '../../../pages/Loading'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { draweropen } from '../../../features/mandateDetail/DrawerReducer'
import { MandateDetail } from '../MandateDetail'
import GroupStrick from '../../../assets/icons/Groupstrick.svg'
import { AssignModal } from '../AssignModel/AssignModel';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ReactComponent as AssignIcon } from '../../../assets/icons/assign.svg'
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import { ReactComponent as DeactivateIcon } from '../../../assets/icons/deactivate.svg'
import { ReactComponent as CloneIcon } from '../../../assets/icons/clone.svg'
import { ReactComponent as PostJobsIcon } from '../../../assets/icons/postjobs.svg'
import { getLoggedInUserName } from '../../../utils/user-utils';
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg'
import defaultClientLogo from '../../../assets/images/defaultUserIcon.svg'
import { MandateService, getJobPostData } from '../../../services/MandateService';
import xphenoRocket from '../../../assets/icons/xphenoRocket.svg'

type Props = {
  data: any;
  drawerdata: any;
  handlePage: (tableObj:any) => void
  handledetial: (id: number, notification:any) => void
  totalCount: number
  noParse: boolean
  searchValue: any
  parsemsg:string
  isLoadingNextPage: boolean;
  isApprovalList: boolean
  horizontalMemberList: any[];
  verticalMemberList: any[];
  loadFirstPage: any;
  columnFields?:any
  setPageCountStatus?:any
  pageCountStatus?:any
}
const ListView: React.FC<Props> = ({
  data,
  totalCount,
  handlePage,
  handledetial,
  drawerdata,
  noParse,
  searchValue,
  parsemsg,
  isLoadingNextPage,
  isApprovalList,
  horizontalMemberList,
  verticalMemberList,
  loadFirstPage,
  columnFields,
  setPageCountStatus,
  pageCountStatus
}) => {

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [drawerid, setdrawerid] = useState<number>(0)
  const [mandateIds, setMandateIds] = useState<number[]>([])
  const [openModel, setOpenModel] = useState(false)
  const [assigneeList, setAssigneeList] = useState<{selectedIds:string[], isHorizontalAssignee:string|null}>({selectedIds:[], isHorizontalAssignee: null})
  const listStatusData = useAppSelector((state:any) => state.mandatedrawer.isApproval)
  const isApprovalStatus = listStatusData === null ? false : listStatusData
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)
  const [pageCount, setPageCount] = useState(data?.length ?? 0);
  const getPageSize = () => {
    const ArrayList:any = [10,20,30,50,75,100]
    const pageSizeList = ArrayList.filter((e:any) => { return data.length >= e})
    console.log(pageSizeList)
    const pageCountSelect:any = (data.length > pageSizeList[pageSizeList.length - 1]) ? ArrayList[pageSizeList.length] : pageSizeList[pageSizeList.length - 1]
    return pageCountSelect
  }
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { roleId } = useAppSelector((state) => state.UserDetail)
	const [pageSize, setPageSize] = useState(10)
	const [defaultPageCount, setDefaultPageCount] = useState(pageCountStatus)
  const getPageCount = () => {
    const pageCount = getPageSize()
    let pageLength = (totalCount/pageCount);
    pageLength = (pageLength % 1 > 0 && pageLength % 1 <= 0.49) ? (Math.round(pageLength) + 1) : (pageLength % 1 > 0.49) ? Math.round(pageLength) : pageLength;
    return pageLength
  }
  const getCollaboratorPermission = (mandateData:any, permissionType:string) => {
    const selectedCollPermission =  "collaborators" in mandateData ? mandateData?.collaborators.filter((item:any) => item.collobatorId == userId) : []
    return (selectedCollPermission.length > 0 && permissionType == "Edit" && userRole != "Admin") ? JSON.parse(selectedCollPermission[0]?.permissions).includes(23) : true;
}
  const statusClass:any = {
    open: "listViewOpenStatus",
    draft: "listViewDraftStatus",
    hold: "listViewHoldStatus",
    close: "listViewCloseStatus",
    inProgress: "listInProgressStatus",
    archive: "listViewArchiveStatus"
  }
  

  const assignAccess = (data:any)=>{
    if(data?.approvalStatus ==2) return false
    if(userRole == "Recruiter"){
      return false;
    } else {
      if(data?.approvedBy){
        return data?.approvedBy == userId
      }
      if(data?.approvers){
        return JSON.parse(data?.approvers).includes(userId)
      }
      return data?.createdBy == userId
    }
  }

  const options = [
    // { id: 1, label: 'Assign', icon: <AssignIcon /> },
    { id: 2, label: 'Edit', icon: <EditIcon />,disable: (userPermissions?.mandate_module?.edit?.edit?.selected && userRole != "Admin") ? true : userRole == "Admin" ? true : false },
    // { id: 3, label: 'Delete', icon: <TrashIcon /> },
    // { id: 4, label: 'Deactivate', icon: <DeactivateIcon /> },
    // { id: 5, label: 'Archive', icon: <ArchiveIcon /> },
    { id: 6, label: 'Clone', icon: <CloneIcon />,disable: (userPermissions?.mandate_module?.create?.clone?.selected && userRole != "Admin") ? true : userRole == "Admin" ? true : false },
    { id: 7, label: 'Post Jobs', icon: <PostJobsIcon />,disable: (userPermissions?.mandate_module?.list?.postjobs?.selected && userRole != "Admin") ? true : userRole == "Admin" ? true : false },
  ]

  const handledrawerdata = (id: number, draft: boolean) => {
    const obj = {isDraft: draft}
    setdrawerid(id)
    handledetial(id, null)
    dispatch(draweropen(obj))
    navigate(`/mandates?id=${id}`,{ state: { "columnFields": columnFields,"viewType": "list" }});
  }

  const handleCheckboxClick = (e: any, id: any) => {
    e.stopPropagation()
    e.target.checked
      ? setMandateIds([...mandateIds, id])
      : setMandateIds(mandateIds.filter((data) => data != id))
  }

  const handleBottomAssign = () =>{
    setOpenModel(true)
  }
  const handleBottomReset = () =>{
    setMandateIds([])
  }

  const getUserTooltipName = (name:any) => {
    let tooltipName = ""
    try{
      tooltipName = JSON.parse(name)
    }catch(error){
      // console.log(error)
      tooltipName = name
    }
    return tooltipName
  }

  // const LoadMore = ({isLoadingNextPage}:{isLoadingNextPage:boolean}) => {
  //   if(isLoadingNextPage) {
  //     return (
  //       <Box className="load-more-section-loading-container">
  //         <Loading />
  //       </Box>
  //     );
  //   }
  //   return (
  //     <Box
  //       display='flex'
  //       justifyContent='center'
  //       alignItems='center'
  //       className='load-more-container'
  //       onClick={handlePage}
  //     >
  //       <Typography component='span' className='load-more' sx={{ color: `${PRIMARY1}` }}>
  //         Load More <LoadMoreIcon className='load-more-icon' />
  //       </Typography>
  //     </Box>
  //   )
  // }

  const ITEM_HEIGHT = 52
  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  };

  function LongMenu({id, isUserEditable, innerData, careerId,mandateCode}:any) {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: any) => {
      disableScroll()
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }
    const handleClose = (event: any) => {
      event.stopPropagation()
      enableScroll()
      setAnchorEl(null)
    }
    const handleClosePopup = (event: any, option: any) => {
      event.stopPropagation()
      enableScroll()
      setAnchorEl(null)
      if(option.id === 1){
        const list:any = data?.find((item:any) => item?.id === id)
        const obj = {
          selectedIds : list?.assignTo?.map((user:any)=> user?.assigneeid),
          isHorizontalAssignee : list?.isHorizontalAssignee === 1 ? "horizontal" : "vertical"
        }
        setAssigneeList(obj)
        setMandateIds([id])
        setOpenModel(option.id === 1 ? true : false)
      }
      if(option.id === 2){
        navigate(`/edit-mandate/${id}`)
      }
      if(option?.id === 6){
        navigate(`/clone-mandate/${id}`)
      }
      if(option?.id === 7){
        if(option?.id === 7){
          if(careerId != undefined){
            getJobPostData(careerId)
            .then((res) => {
              const mandateDetails = res[0];
              mandateDetails["mandateCode"] = mandateCode
              navigate(`/post-jobs/${id}`,{state:{mandateView: mandateDetails,type:"update"}})
            })
            .catch((error) => {
              console.log('error', error.message)
            })  
          }else{
            MandateService.getMandateDetail(id)
            .then((res) => {
              navigate(`/post-jobs/${id}`,{state:{mandateView: res?.data?.result?.data,type:"create"}})
            })
            .catch((error) => {
              console.log('error', error.message)
            })  
          }              
        }
      }
    }
    
    return (
      <div>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => handleClose(e)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '12ch',
              overflow: 'hidden',
              borderRadius: '10px',
            },
          }}
        >
          {options.map((option) => {
            if(option.disable && getCollaboratorPermission(innerData, option.label)){
            return (
              <MenuItem
                className='menuuuu-item'
                key={option.id}
                selected={option==null}
                onClick={(e) => handleClosePopup(e, option)}
                
              ><Box sx={{display:'flex',gap:"10px", flexDirection:'row',justifyContent:'center', alignItems:'center'}}>{option.icon} <span>{(careerId != undefined && option.id == 7) ? "Update Jobs" : option.label}</span></Box>
              </MenuItem>
            )}
          })}
        </Menu>
      </div>
    )
  }
  const [sortColumn, setSortColumn] = useState<any>()
  const updateSorting = (column: string) => {
    const referSortColum:any = {
      "mandate" : "title",
      "CreatedOn": "createdAt",
      "DueDate": "requestEndDate",
      "ClientName": "clientName",
      "CreatedBy": "createdByName",
      "Status": "statusName"
    }
		let sortDirection = "asc"
		if (sortColumn) {
			if (sortColumn.label == column) {
				if (sortColumn.sort == "asc") {
					setSortColumn((prev: any) => {
						return { ...prev, sort: "dec" }
					})
					sortDirection = "desc"
					
				} else if (sortColumn.sort == "dec") {
					setSortColumn((prev: any) => {
						return {}
					})
					sortDirection = ""
				}
			} else {
				setSortColumn({ label: column, sort: "asc" })
				sortDirection = "asc"
			}
		} else {
			setSortColumn({ label: column, sort: "asc" })
			sortDirection = "asc"
		}
    handlePage({"pageSize": pageSize,"pageCount": defaultPageCount, sortColumn: sortDirection != "" ? referSortColum[column] : "", sortDirection: sortDirection})
	}


  return (
    <Box className='list-wrapper'>
      {isLoadingNextPage && <Box className="load-more-section-loading-container">
          <Loading />
        </Box>}
      <Box className="mandateListTableScroll">
      <Table>
        <TableHead className='head-wrapper'>
          <TableRow className='table-head'>
            <TableCell onClick={() => {updateSorting("mandate")}}><Box>Mandate {sortColumn && sortColumn.label == "mandate" ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</Box></TableCell>
            {columnFields && columnFields["Client Name"] == false ? "" : <TableCell onClick={() => {updateSorting("ClientName")}}><Box>Client Name {sortColumn && sortColumn.label == "ClientName" ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</Box></TableCell>}
            {columnFields && columnFields["Created By"] == false ? "" : <TableCell onClick={() => {updateSorting("CreatedBy")}}><Box>Created by {sortColumn && sortColumn.label == "CreatedBy" ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</Box></TableCell>}
            {columnFields && columnFields["Created On"] == false ? "" : <TableCell onClick={() => {updateSorting("CreatedOn")}}><Box>Created on {sortColumn && sortColumn.label == "CreatedOn" ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</Box></TableCell>}
            {columnFields && columnFields["Job Orders"] == false ? "" : <TableCell>Job Orders</TableCell>}
            {columnFields && columnFields["Assignees"] == false ? "" : <TableCell>Assignees</TableCell>}
            {columnFields && columnFields["Due Date"] == false ? "" : <TableCell onClick={() => {updateSorting("DueDate")}}><Box>Due Date {sortColumn && sortColumn.label == "DueDate" ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</Box></TableCell>}
            {columnFields && columnFields["Status"] == false ? "" : <TableCell onClick={() => {updateSorting("Status")}}><Box>Status {sortColumn && sortColumn.label == "Status" ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</Box></TableCell>}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='table-body'>
          {data?.map((list:any)=>{
            const assignList:any = list?.assignTo ? list?.assignTo : []
            const isUserEditable = getLoggedInUserName() === list?.createdByName;

            return (
              <TableRow 
                key={list.id}
                onClick={() => {
                  const id = list.id
                  const draft = list?.status === "draft";
                  if(userPermissions?.mandate_module?.list?.view?.selected && userRole != "Admin"){
                    handledrawerdata(id, draft)
                  }else if(userRole == "Admin") {
                    handledrawerdata(id, draft)
                  }
                }}
              >
                <TableCell>
                  <div className='title-card'>
                    <div className='image-container'>
                      <Avatar
                        sx={{width: 30, height: 30 }}
                        className={(!isApprovalStatus && isUserEditable) ? 'mandate-image' : ""}
                        src={defaultClientLogo}
                      ></Avatar>
                      {/* <p>{list?.title.charAt(0)}</p> */}
                      {/* {(!isApprovalStatus && isUserEditable) && 
                        <Checkbox
                          checked={mandateIds.some((id) => id === list.id)}
                          sx={
                            mandateIds.some((id) => id === list.id)
                              ? { width: 30, height: 30, display: 'inline-flex !important' }
                              : { width: 30, height: 30, display: 'none' }
                          }
                          onClick={(e) => {
                            const id = list.id
                            handleCheckboxClick(e, id)
                          }}
                          className='checkbox'
                        />
                      } */}
                    </div>
                    <div>
                      <div className='title'>
                        <Tooltip title={list?.title}>
                          <Typography>
                            {list?.title}
                          </Typography >
                        </Tooltip>
                        {list?.status == 10 && (
                          <Tooltip title={"Draft"}>
                            <img src={draftIcon} alt="draft" />
                          </Tooltip>
                        )}
                      </div>
                      <div style={{display:"flex",gap:"10px"}}>
                        <Typography className='mandate-id'>
                          {list?.mandateId}
                        </Typography>
                        {list.xpCareerId != undefined ? <a onClick={(event:any) => {event.stopPropagation()}} href={`https://www.xpheno.com/JobBoard/Jobs/Careerpage/JobDescription/${list.xpCareerId}`} target="_blank" rel="noreferrer"><img src={xphenoRocket} /></a> : ""}
                      </div>
                    </div>
                  </div>
                </TableCell>
                {columnFields && columnFields["Client Name"] == false ? "" :
                  <TableCell>
                    <Tooltip title={list?.clientName} placement="top"><Typography className='primary-text'>{list?.clientName}</Typography></Tooltip>
                  </TableCell>
                }
                {columnFields && columnFields["Created By"] == false ? "" :
                  <TableCell>
                    <Tooltip title={list?.createdByName} placement="top"><Typography className='primary-text'>{list?.createdByName}</Typography></Tooltip>
                  </TableCell>
                }
                {columnFields && columnFields["Created On"] == false ? "" :
                  <TableCell>
                    <Typography className='primary-text'>{convertToDisplayDateTime(list?.createdAt)}</Typography>
                  </TableCell>
                }
                {columnFields && columnFields["Job Orders"] == false ? "" :
                  <TableCell>
                    <Typography className='primary-text'>{list?.mandateLocationCount ?? 0}</Typography>
                  </TableCell>
                }
                {columnFields && columnFields["Assignees"] == false ? "" :
                  <TableCell>
                    <div className='avatar-container avatar-list-items'>
                      {assignList?.length >0 && (
                        <AvatarGroup className='group-avatar' max={4} >
                            {assignList?.map((user:any, index:number)=> (
                              <Tooltip key={index} title={getUserTooltipName(user?.assigneeName)} arrow 
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#000',
                                    '& .MuiTooltip-arrow': {
                                      color: '#000',
                                    },
                                  },
                                },
                              }}><Avatar key={index} alt={user} src={""} sx={{ width: 30, height: 30 }}/></Tooltip>
                            ))}
                        </AvatarGroup>
                      )}
                      {(assignList?.length === 0) && <Avatar alt='no-grou' src={GroupStrick} sx={{ width: 30, height: 30 }} />}
                      <Typography className='secondary-text'>{assignList?.length === 0 ? "No Assignees" : `${assignList?.length} Assignee${assignList?.length>0 && "s"}`}</Typography>
                    </div>
                  </TableCell>
                }
                {columnFields && columnFields["Due Date"] == false ? "" :
                  <TableCell>
                    <Typography className='primary-text'>{list?.requestEndDate ? convertToDisplayDate(list?.requestEndDate) : "-"}</Typography>
                  </TableCell>
                }
                {columnFields && columnFields["Status"] == false ? "" :
                  <TableCell>
                    <Tooltip title={isApprovalList ? list?.approvalStatus == 2 ? "Rejected" : "-" : list?.statusName}><Typography className={`primary-text ${(isApprovalList && list?.approvalStatus == 2) ? "listViewRejectStatus" : ""} ${!isApprovalList && statusClass[list?.statusName.indexOf("Closed") > -1 ? "close" : list?.statusName.indexOf("Hold") > -1 ? "hold" : list?.statusName == "In progress" ? "inProgress"  : list?.statusName.toLowerCase()]}`}>{isApprovalList ? list?.approvalStatus == 2 ? "Rejected" : "-" : list?.statusName != "" ? list?.statusName : "-"}</Typography></Tooltip>
                  </TableCell>
                }
                <TableCell>
                  {(![3,5,10].includes(list.status) && options.filter((item:any) => item.disable == true).length > 0 && userRole != "Admin") && 
                    <LongMenu id ={list.id} isUserEditable={isUserEditable} innerData={data} careerId={list.xpCareerId} mandateCode={list.mandateId}/>}
                    {(![3,5,10].includes(list.status) && userRole == "Admin") && 
                    <LongMenu id ={list.id} isUserEditable={isUserEditable} innerData={data} careerId={list.xpCareerId} mandateCode={list.mandateId}/>}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <div className='no-data-container' style={{display:"flex", height:"100%"}}>
          <Grid container>
            <Grid sm={12} className='no-result-found' alignItems='center' justifyContent='center'>
              <Typography>
                <NoResultFound />
              </Typography>
              <Typography>{searchValue? `No results found for “${searchValue}”`: "No data found"}</Typography>
            </Grid>
          </Grid>
        </div>
      )}
      </Box>
      
      {/* {data.length < totalCount && <LoadMore isLoadingNextPage={isLoadingNextPage} />} */}      
      <Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={getPageSize()}
									label="Age"
									onChange={(event:any) => {setDefaultPageCount(1);setPageSize(event.target.value);handlePage({"pageSize": event.target.value,"pageCount": defaultPageCount})}}
								>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={30}>30</MenuItem>
									<MenuItem value={50}>50</MenuItem>
									<MenuItem value={75}>75</MenuItem>
									<MenuItem value={100}>100</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={getPageCount()} page={defaultPageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setDefaultPageCount(pageNumber);setPageCountStatus(pageNumber);handlePage({"pageSize": pageSize,"pageCount": pageNumber})}}/>
					</Box>
      <div>
        <MandateDetail id={drawerid} isApprovalList={isApprovalList}drawerdata={drawerdata} noParse={noParse} parsemsg={parsemsg} horizontalMemberList={horizontalMemberList} verticalMemberList={verticalMemberList} handledetial={handledetial} loadFirstPage={loadFirstPage}/>
      </div>
      {openModel && <AssignModal open={openModel} onClose={() => setOpenModel(false)} mandateIds={mandateIds} horizontalMemberList={horizontalMemberList} verticalMemberList={verticalMemberList} assigneeList={assigneeList} />}
    </Box>
  );
}

export default ListView;