import { Breadcrumb } from '../components/Breadcrumb'
import ChatGPTDetails from '../components/ChatGPT/ChatGPTDetails';
import ChatGPTDialog from '../components/ChatGPT/ChatGPTDialog';

const ChatGPT:React.FC = () => {
  return (
    <div
      style={{width:"100%", height:"100%", backgroundColor:"#fff", borderRadius:"10px"}}
    >
      {/* <ChatGPTDetails/> */}
      <ChatGPTDialog/>
    </div>
  )
}

export default ChatGPT;