import { useState, useEffect } from 'react';
import { 
    Typography, 
    Grid, 
    Box, 
    FormControl, 
    InputLabel, 
    TextField, 
    Switch, 
    Select, 
    MenuItem, 
    Slider, 
    Chip, 
    Button,
    ListSubheader,
    Popover,
    Checkbox,
    ListItemText,
    Divider,
    Autocomplete
} from '@mui/material';
import { AppTextField } from '../../AppComponents/AppTextField';
import { Controller, useForm } from "react-hook-form";
import _ from 'lodash';
import CommonTextField from '../AddMandate/CommonTextField';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as AddNewIcon } from '../../../assets/icons/add-new.svg'
import { ReactComponent as Tickmark } from '../../../assets/icons/tickMark.svg'

import { createSearchQuery, dropDownLocation, getMandateLocation, locationScrapper, updateSearchQuery } from '../../../services/MandateService';
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import AddSkillDialogbox from '../../AddSkillDialogbox/AddSkillDialogbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RangeSlider from '../../Common/RangeSlider/RangeSlider';
import './QueryBuilder.scss'
import InfoIcon from '@mui/icons-material/Info';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IFormInput {
    queryName?: string;
    queryDesc?: string;
    keywords?: string;
    excludekeys?: string;
    mlocationId?: string;
    currentLocation?: string;
    preferredLocations?: string;
    experienceRange?: any;
    annualSalaryRange?: any;
    designation: string;
    noticePeriod: string;
    activeIn: string;
    maxCount: number;
}

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddQueryBuilder = ({isClone, mandateData, setIsAddQuery, editData, isEdit, getSearchQueries, queryNameLists }: any) => {

    const [rangeValue, setRangeValue] = useState<number[]>(isEdit ? [editData?.minExp, editData?.maxExp] : [0, 30])
    const [salaryValue, setSalaryValue] = useState<number[]>(isEdit ? [editData?.minSalary, editData?.maxSalary] : [0, 100])
    const [noticeTags, setNoticeTags] = useState<string[]>(isEdit ? editData?.noticePeriod?.split(',') : ["Any"])
    const [selectGender, setSelectGender] = useState<string>(isEdit ? editData?.gender : "All candidates")
    const [selectDisplayshow, setSelectDisplayShow] = useState<string>(isEdit ? editData?.showCandidates : "All candidates")
    const [selectDisplayVerified, setSelectDisplayVerified] = useState<string[]>(isEdit ? editData?.showOnlyCandidates ? editData?.showOnlyCandidates?.split(","):[] :[])
    const [desigToggle, setDesicToggle] = useState<any>(isEdit ? editData?.designationToggle : false)
    const [currency, setcurrency] = useState<any>(isEdit ? editData?.currency : "INR");
    const [searchArea, setSearchArea] = useState<string>(isEdit ? editData?.searchArea : "")
    const [keywordsToggle, setKeywordsToggle] = useState<any>(isEdit ? editData?.keywordsToggle : false)
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const [isPopOpen, setIsPopOpen] = useState<any>(false)
    const [popOverSkills, setPopOverSkills] = useState(mandateData?.primarySkills?.split(","))
    const [popOverSelectTags, setPopOverSelectTags] = useState<any>([])
    const [excludekeys, setExcludekeys] = useState<any>(isEdit ? editData?.excludekeys : "")
    const [includedkeys, setIncludedkeys] = useState<any>(isEdit ? editData?.keywords : "")
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const [preferLocations, setPreferLocation] = useState<any[]>(isEdit ? editData?.preferredLocations ? editData?.preferredLocations?.split(",")?.map((x: string, index: number) => {
        return { name: x, id: index }
    }): [] : [])
    const [mandateLocation, setMandateLocation] = useState<any>(isEdit ? editData?.mlocationId : null)
    const [curLocation, setCurLocation] = useState<any>(isEdit ? editData?.currentLocation ? editData?.currentLocation?.split(",")?.map((x: string, index: number) => {
        return { name: x, id: index }
    }): [] : [])
    const [locationlist, setLocationsList] = useState<any>({})
    const [showAddSkillDialog, setShowAddSkillDialog] = useState<boolean>(false);
    const [showExcludeSkillDialog, setShowExcludeSkillDialog] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [keywordHasError, setKeywordHasError] = useState<boolean>(false);
    const [disabilities, setDisabilities]= useState<boolean>(isEdit ? editData?.personDisabilities : false)
    const [locationlist2, setLocationsList2] = useState<any>([])
    const [locationPreferlist, setLocationPreferlist] = useState<any>([])
    const [sameNameError, setSameNameError] = useState<boolean>(false)
    const [mandatelocationLists, setMandateLocationLists] = useState<any[]>([])
    const [locationUpdateStatus, setLocationUpdateStatus] = useState(false)
    const initValues = {
        queryName: isEdit ? editData?.queryName : "",
        queryDesc: isEdit ? editData?.queryDesc : "",
        excludekeys: isEdit ? editData?.excludekeys : "",
        currentLocation: isEdit ? editData?.currentLocation : "",
        preferredLocations: isEdit ? editData?.preferredLocations : "",
        experienceRange: isEdit ? [editData?.minExp, editData?.maxExp] : [0, 30],
        annualSalaryRange: isEdit ? [editData?.minSalary, editData?.maxSalary] : [0, 100],
        designation: isEdit ? editData?.designation : "",
        noticePeriod: isEdit ? editData?.noticePeriod : "",
        activeIn: isEdit ? editData?.activeIn : "",
        maxCount: isEdit ? editData?.maxCount : null,
        keywords: isEdit ? editData?.keywords : ""

    }

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        setError,
        formState: { errors },
        watch,
    } = useForm<IFormInput>({
        mode: "onChange",
        defaultValues: initValues,
    });

    const queryNameWatch = watch('queryName')

    const queryFields = [
        { name: "queryName", label: "Search Strategy", variant: "outlined", required: true, control, errors, },
        { name: "queryDesc", label: "Search Strategy Description", variant: "outlined", multiline: true, required: true, control, errors, },
    ]

    const employmentDetails = { name: "designation", label: "Designation", variant: "outlined", required: false, control, errors }
    const activeInCount = { name: "maxCount", label: "Maximum result count allowed", variant: "outlined", required: true, control, errors, isNumber: true, maxNumber: 100 }
    const noticePeriodTag = [ "Any", "0-15 days", "1 month", "2 months", "More than 2 months", "Currently serving notice period" ]
    const genderTags = [ "All candidates", "Male candidates", "Female candidates"];
    const displayshowTags = [ "All candidates", "New registrations", "Modified candidates"];
    const displayVerifiedTags = [ "Verified mobile number", "Verified email ID", "Attached resume"];

    const activeInValues = [ "All Resumes", "1 day", "3 days", "7 days", "15 days", "30 days", "2 months", "3 months", "6 months", "1 year", "8 to 15 days", "16 to 30 days", "1 to 2 months", "2 to 3 months", "3 to 6 months", "6 to 12 months", "1+ year"]

    function rangeValueText(value: number) {
        if (value === 0 || value === 1) {
            return `${value} year`;
        }else{
            return `${value} years`;
        }
    }

    function salaryValueText(value: number) {
        if(value === 0 || value === 1){
            return `${value} Lacs`;
        }else{
            return `${value} Lacs`;
        }
    }

    const rangeChangeHandler = (event: Event, newValue: number | number[]) => {
        setRangeValue(newValue as number[]);
    };

    const salaryChangeHandler = (event: Event, newValue: number | number[]) => {
        setSalaryValue(newValue as number[]);
    };
    useEffect(() => {
        if(isEdit && locationlist2.length) {
            setCurLocation(locationlist2?.filter((e:any)=> editData?.currentLocation?.split(",").includes(e.locationName)))
            setPreferLocation(locationPreferlist?.filter((e:any)=>editData?.preferredLocations?.split(",").includes(e.locationName)))
        }
    }, [locationUpdateStatus])

    

    const querySubmitHandler = async (data: any) => {
        const excludekeysValue = excludekeys.trim().charAt(excludekeys.trim().length - 1) == "," ?  excludekeys.trim().substring(0, excludekeys.trim().length - 1): excludekeys.trim();
        const keywordsValue = includedkeys.trim().charAt(includedkeys.trim().length - 1) == "," ?  includedkeys.trim().substring(0, includedkeys.trim().length - 1): includedkeys.trim();
        setIsSubmitting(true);
        data["mandateId"] = mandateData?.id;
        data["maxCount"] = Number(data?.maxCount);
        data["noticePeriod"] = noticeTags?.join(',');
        data["designationToggle"] = Number(desigToggle);
        data["currency"] = currency;
        data["searchArea"] = searchArea;
        data["keywordsToggle"] = Number(keywordsToggle);
        data["minExp"] = String(rangeValue[0]);
        data["maxExp"] = String(rangeValue[1]);
        data["minSalary"] = String(salaryValue[0]);
        data["maxSalary"] = String(salaryValue[1]);
        data["excludekeys"] = excludekeysValue;
        data["mlocationId"] = mandateLocation ? mandateLocation : ''
        data["currentLocation"] = curLocation.map((x:any) => x.locationName).join(",");
        data["preferredLocations"] = preferLocations.map((x) => x.locationName).join(",")
        data["keywords"] = keywordsValue;
        data["gender"] = selectGender
        data["personDisabilities"]=disabilities
        data["showCandidates"]= selectDisplayshow
        data["showOnlyCandidates"]=selectDisplayVerified.join(',')

        delete data["annualSalaryRange"];
        delete data["experienceRange"];

        if (isEdit && !isClone) {
            data["id"] = editData?.id;
        }

        
        try{
            const response: any = isEdit && !isClone ? await updateSearchQuery(data) : await createSearchQuery(data);
            if (response?.status == 200 || response?.status == 201) {
                if (response?.data?.result?.message === "Search Query Updated successfully" || response?.data?.result?.message === "Search Query saved successfully") {
                    setUploadsuccess(true)
                    setSuccessMessage(isEdit && !isClone ? 'Search Query has been successfully updated' : 'Search Query saved successfully')
                }else{
                    // setHasError(true)
                    console.error("server error")
                }
                getSearchQueries()
                setIsSubmitting(false)
            }
        } catch(error){
            console.error("server error")
        }
    }

    const fetchLocations = async () =>{
        const reqObj = {
            "filters": [
                {
                    "field": "parentId",
                    "operator": "equals",
                    "type": "string",
                    "value": "0"
                }
            ],
            "page": {
                "pageNo": -1,
                "pageSize": 10
            },
            "sorting": {
                "direction": "ASC",
                "field": "createdAt"
            }
        }
        const res = await dropDownLocation(reqObj)
        // if (res?.data?.length > 0) {
        //     setLocationsList(res?.data[0]);
        //     const temp: any = [];
        //     let i = 0;
        //     Object.keys(res?.data[0])?.forEach((items: string) => {
        //         res?.data[0][items]?.split(",")?.forEach((e: string) => {
        //             temp.push({ name: e, id: i, state: items });
        //             i += 1;
        //         })
        //     })
        //     setLocationsList2(temp);
        // } else {
        //     setLocationsList({});
        // }
        setLocationsList(res.data.result.list)
        setLocationsList2(res.data.result.list)
        setLocationPreferlist(res.data.result.list)
        setLocationUpdateStatus(true)

    }

    const getMandateLocationList = async (manId:any)=> {
        const response = await getMandateLocation(manId?.id)
        setMandateLocationLists([])
        if(response?.status === 200){
          setMandateLocationLists(response?.data?.result?.mandateLocations)
        }
    }

    useEffect(()=>{
        fetchLocations()
        getMandateLocationList(mandateData)
    },[])
 
    useEffect(()=>{
        if(queryNameLists.includes(queryNameWatch?.trim())){
            setSameNameError(true)
        }else{
            setSameNameError(false)
        }
    },[queryNameWatch])

    const handleClickPop = (event:any) => {
        setAnchorEl(event.currentTarget);
        setIsPopOpen(true)
    }
    const handlePopClose = () => {
        setAnchorEl(null);
        setIsPopOpen(false)
    }

    const handleTagSelect = () => {
        const skillTags = excludekeys + popOverSelectTags?.join(",")
        setExcludekeys(skillTags)
    }

    const renderSelectlocation = (items:any, index:number, type?:string) => {
        const places = items[1]?.split(',')
        const arrVal = (type === "current") ? curLocation : preferLocations
        const listItems = places?.map((place: any, index: number) => {
            return (
                <MenuItem
                    key={`${index}-${place}`}
                    value={place}
                >
                    <Checkbox
                        checked={arrVal.indexOf(place) > -1}
                        sx={{
                            '&.Mui-checked': {
                                color: "#FF6E00",
                            },
                        }}
                    />
                    <ListItemText primary={place} />
                </MenuItem>
            );
        })

        return [
            <ListSubheader key={`${index}-${items[0]}`} style={{ fontWeight: 700 }} >{items[0]}</ListSubheader>,
            listItems,
            <Divider key={`${index}-${items[0]}`} />
        ]

    }

    const handlePreferLocation = (event: any) => {
        const val = event.target.value;
        setPreferLocation(
            // On autofill we get a stringified value.
            typeof val === 'string' ? val?.split(',') : val,
        );

    }

    const handleCurLocation = (event: any) => {
        const val = event.target.value;
        setCurLocation(
            // On autofill we get a stringified value.
            typeof val === 'string' ? val?.split(',') : val,
        );

    }

    useEffect(()=>{
        if(keywordHasError){
            if(includedkeys !== ""){
                setKeywordHasError(false);
            }
        }

    },[includedkeys])
    
    const checkKeywordError = () => {
        if(includedkeys === "") setKeywordHasError(true)
    }
    useEffect(()=>{
    if(!keywordHasError){
        setIsSubmitting(false)
    }
    else{
        setIsSubmitting(true)
    }
    },[keywordHasError])

    // useEffect(()=>{
    //     const updatedOptions = locationPreferlist.filter((option:any) => {
    //         if(!_.includes(curLocation, option)){
    //             return option
    //         }
    //     });
    //     if(curLocation.length == 0 && preferLocations.length == 0){
    //         setLocationsList2(locationlist)
    //         setLocationPreferlist(locationlist);
    //     }else{
    //         setLocationPreferlist(updatedOptions);
    //     }        
    //   },[curLocation])
    
    return (
        <Box className='Add-query-wrapper'>
            <Box
                component="form"
                data-testid="formHandle"
                className="reminder_submit_form pt-4 mt-3"
                noValidate
                autoComplete="off"
                id="mandateForm"
                onSubmit={handleSubmit(querySubmitHandler)}
                style={{ padding: "1rem 1rem 0rem" }}
            >
                <Grid container className='jobInformation-wrap' style={{marginBottom:"unset"}}>
                    <Grid className='common-grid-text' style={{marginBottom:"0px"}}>
                    <FormControl fullWidth >
                            <InputLabel>Location</InputLabel>
                            <Controller
                            name='mlocationId'
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => {                    
                                return(
                                <Select
                                label='Location'
                                value={mandateLocation}
                                onChange={(e: any) => {
                                    onChange(e);
                                    setMandateLocation(e.target.value);
                                }}
                                className='test-dropdown input-rounded'
                                labelId='mandate-location-select-label'
                                >
                                {mandatelocationLists?.length > 0 &&
                                    mandatelocationLists?.map((mll: any, index: number) => (
                                    <MenuItem key={index} value={mll?.id} className='mandate-location-lists-opt'>
                                        <div className='primary-text'>{`${mll?.location} (${mll?.jobOrderCount})`}</div>
                                    </MenuItem>
                                    ))}
                                {mandatelocationLists?.length === 0 && (
                                    <MenuItem disabled={true}>No Location</MenuItem>
                                )}
                                </Select>
                            )}}
                            />
                        </FormControl>
                        {_.map(queryFields, (job: any) =>
                            <Grid item>
                                <CommonTextField
                                    multiline={job?.multiline || false}
                                    control={job?.control}
                                    errors={job?.errors}
                                    variant={job?.variant}
                                    required={job?.required}
                                    name={job?.name}
                                    label={job?.label}
                                    errorStyle={{paddingLeft: "5px"}}
                                />
                                {(sameNameError && job?.name === "queryName") && (
                                    <span style={{paddingLeft: "5px"}} className="reminder_text-danger select-danger-text" role="alert">
                                        {"Search query builder name already exists"}
                                    </span>
                                )}
                            </Grid>
                        )}
                        <Grid style={{ width: "100%" }}>
                        <FormControl fullWidth required className='keySearchField'>
                            <Controller
                                 name="keywords"
                                 control={control}
                                 rules={{
                                    pattern: {
                                        value: /^[a-zA-Z0-9!$%^&*()_#/+., -]+$/,
                                        message: "Only this specific special character !,#,$,%,^,&,*,(,),_,+ allowed"
                                    }
                                  }}
                                 render={({ field: { onChange, value, } }) => (
                                    <TextField
                                    label={"Keywords"}
                                    value={includedkeys || ""}
                                    required={true}
                                    multiline={true}
                                    maxRows={Infinity}
                                    onChange={(e) => {
                                        onChange(e)
                                        setIncludedkeys(e.target.value)
                                    }}
                                    onKeyPress={(e:any) => {                      
                                        if (e.which === 13) {
                                            e.preventDefault();
                                        }
                                    }}
                                    style={{ width: "100%" }}
                                />
                                 )}
                            />
                            {keywordHasError && (
                                <span style={{paddingLeft: "5px"}} className="reminder_text-danger select-danger-text" role="alert">
                                    {"This field is required"}
                                </span>
                            )}
                            {errors?.keywords && (
                                <p className="reminder_text-danger select-danger-text" role="alert">{errors?.keywords?.message}</p>
                            )}
                            <p><InfoIcon />Enter Keywords using comma separated</p>
                        </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className='search-in-wrapper' >
                    <div className='search-container'>
                        <div className='search-naukri-form'>
                            <span >Search in <span style={{color:"#002882"}}>Naukri</span></span>
                            <Select
                                value={searchArea}
                                onChange={(e)=> setSearchArea(e.target.value)}
                                style={{paddingLeft: "0px"}}
                                sx={{
                                    boxShadow: "none",
                                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: 0,
                                      },
                                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: 0,
                                      },
                                  }}
                            >
                                <MenuItem value={"Entire Resume"}>Entire Resume</MenuItem>
                                <MenuItem value={"Resume title"}>Resume title</MenuItem>
                                <MenuItem value={"Resume title and key results"}>Resume title and key results</MenuItem>
                                <MenuItem value={"Resume synopsis"}>Resume synopsis</MenuItem>
                            </Select>
                        </div>
                        <div className='naukri-toggle'>
                            <Switch
                                checked={keywordsToggle}
                                onChange={()=>setKeywordsToggle(!keywordsToggle)}
                            />
                            <span style={{fontSize:"14px"}}>Boolean</span>
                        </div>
                    </div>
                    <Typography onClick={() => setShowAddSkillDialog(true)} className='add-skills' style={{ color: "#002882", fontSize: "14px" }}><AddNewIcon className='add-new-icon' />Add Skill Keywords</Typography>
                </Grid>
                <Grid className='location-wrapper excludeKeyword'  container >
                    <Grid style={{ width: "100%" }}>
                    <FormControl fullWidth required className='keySearchField'>
                            <Controller
                                 name="excludekeys"
                                 control={control}
                                 rules={{
                                    pattern: {
                                      value: /^[a-zA-Z0-9!$%^&*()_#/+., -]+$/,
                                      message: "Only this specific special character !,#,$,%,^,&,*,(,),_,+ allowed"
                                    }
                                  }}
                                 render={({ field: { onChange, value, } }) => (
                                 <TextField
                                    label={"Exclude Keywords"}
                                    value={excludekeys || ""}
                                    multiline={true}
                                    maxRows={Infinity}
                                    // onClick={() => setShowExcludeSkillDialog(true)}
                                    onChange={(e:any) => {
                                            onChange(e)
                                            setExcludekeys(e.target.value)
                                    }}
                                    onKeyPress={(e:any) => {                      
                                        if (e.which === 13) {
                                            e.preventDefault();
                                        }
                                    }}
                                    style={{width:"100%"}}
                                />
                                )}
                           />                        
                        {errors?.excludekeys && (
                                <p className="reminder_text-danger select-danger-text" role="alert">{errors?.excludekeys?.message}</p>
                            )}
                        <p><InfoIcon />Enter Exclude Keywords using comma separated</p>
                        </FormControl>
                    </Grid>
                    <Grid item style={{width:"100%"}}>
                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete                                        
                                id="grouped-demo"
                                multiple
                                limitTags={2}
                                disableCloseOnSelect
                                disableListWrap
                                className='inner-textfield'
                                // PopperComponent={PopperMy}
                                options={locationlist2}
                                getOptionLabel={(option:any) => option?.locationName}
                                value={curLocation}
                                renderInput={(params: any) => (
                                    <TextField {...params} label='Current Location' />
                                )}
                                groupBy={(option) => option.state}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: "#FF6E00",
                                                },
                                            }}
                                        />
                                        {option?.locationName}
                                    </li>
                                )}
                                onChange={(e: any, val: any, all: any) => {
                                    setCurLocation(val)
                                    return val
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item style={{width:"100%"}}>
                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id='multiple-limit-tags'
                                disableCloseOnSelect
                                disableListWrap
                                className='inner-textfield'
                                // PopperComponent={PopperMy}
                                options={locationPreferlist}
                                getOptionLabel={(option:any) => option?.locationName}
                                value={preferLocations}
                                renderInput={(params: any) => (
                                    <TextField {...params} label='Preferred Location' />
                                )}
                                groupBy={(option) => option.state}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: "#FF6E00",
                                                },
                                            }}
                                        />
                                        {option?.locationName}
                                    </li>
                                )}
                                onChange={(e: any, val: any, all: any) => {
                                    setPreferLocation(val)
                                    return val
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item >
                        <InputLabel >
                            Experience
                        </InputLabel>
                        <Controller
                            name='experienceRange'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                            <RangeSlider 
                                rangeValue={rangeValue}
                                setRangeValue={setRangeValue}
                                minValue={0}
                                maxValue={30}
                                rangeStep={1}
                                markStep={5}
                                onChangeHandler={rangeChangeHandler}
                                labelText={"year"}
                            />
                            )}
                        />
                    </Grid>
                    <Grid item >
                        <div className='annual-salary'>
                        <InputLabel >
                            Annual Salary
                        </InputLabel>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <span>Select unit:</span>
                            <Select
                            value={currency}
                            onChange={(e)=> setcurrency(e.target.value)}
                            sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                              }}
                            >
                                <MenuItem value={"INR"}>INR</MenuItem>
                                <MenuItem value={"USD"}>USD</MenuItem>
                            </Select>
                        </div>
                        </div>
                        <Controller
                            name='annualSalaryRange'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                            <RangeSlider 
                                rangeValue={salaryValue}
                                setRangeValue={setSalaryValue}
                                minValue={0}
                                maxValue={100}
                                rangeStep={0.5}
                                markStep={10}
                                onChangeHandler={salaryChangeHandler}
                                labelText={"Lakh"}
                            />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container className='employment-details'>
                    <div className='header'>
                        <span>Employment Details</span>
                    </div>
                    <Grid className='text-area'>
                        <Grid>
                            <CommonTextField
                                control={employmentDetails?.control}
                                errors={employmentDetails?.errors}
                                variant={employmentDetails?.variant}
                                required={employmentDetails?.required}
                                name={employmentDetails?.name}
                                label={employmentDetails?.label}
                                errorStyle={{paddingLeft: "5px"}}
                            />
                        </Grid>
                        <Grid className='switch-cmt'>
                            <Switch
                                checked={desigToggle}
                                onChange={()=>setDesicToggle(!desigToggle)}
                            />
                            <span style={{fontSize:"14px"}}>Boolean</span>
                        </Grid>
                    </Grid>
                    <Grid className='notice-wrapper'>
                        <InputLabel id="demo-simple-select-label">Notice Period/Availability to join</InputLabel>
                        <Box className='notice-container'>
                            {noticePeriodTag?.map((tag:string, index:number)=>(
                                <Chip 
                                    key={index} 
                                    label={tag} 
                                    style={noticeTags?.includes(tag) ?  {color:"#002882", backgroundColor:'#F0F6FB', border:'1px solid #002882'} : {color:"#000000"}}
                                    variant="outlined"
                                    onClick={(e:any)=>{
                                        const arr = [...noticeTags]
                                        if(arr?.includes(tag)){
                                            const rval = arr.indexOf(tag)
                                            arr.splice(rval, 1)
                                            if(arr.length > 0 && arr.includes("Any")){
                                                const rval = arr.indexOf("Any")
                                                arr.splice(rval, 1)
                                            }
                                            setNoticeTags(arr)
                                        }else{
                                            if(arr.length > 0 && arr.includes("Any")){
                                                const rval = arr.indexOf("Any")
                                                arr.splice(rval, 1)
                                            }
                                            setNoticeTags([...arr, tag])
                                        }
                                    }}
                                />
                            ))}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className='additional-wrapper'>
                    <div className='header'>
                        <span>Additional Details</span>
                    </div>
                    <Grid className='additional-container' item>
                        <FormControl
                            fullWidth
                            // className='select-label-astrix'
                        >
                            <InputLabel id="demo-simple-select-label">Active In</InputLabel>
                            <Controller
                                name="activeIn"
                                control={control}
                                render={({ field: { onChange, value, } }) => (
                                    <Select
                                        value={value}
                                        onChange={(e) => onChange(e.target.value)}
                                        variant="outlined"
                                        label="Active In"
                                    >
                                        {activeInValues?.map((val:any, index: number)=> (
                                            <MenuItem key={index} value={val}>{val}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors?.activeIn && (
                                <span className="reminder_text-danger select-danger-text" role="alert">
                                    {"This field is required"}
                                </span>
                            )}
                        </FormControl>
                        <Grid>
                        <CommonTextField
                            control={activeInCount?.control}
                            errors={activeInCount?.errors}
                            variant={activeInCount?.variant}
                            required={activeInCount?.required}
                            name={activeInCount?.name}
                            label={activeInCount?.label}
                            isNumber={activeInCount?.isNumber}
                            limit={3}
                            extraRules= {{
                                max: {
                                    value: 100,
                                    message: "Maximum limit should be 100"
                                }
                            }}
                            errorStyle={{paddingLeft: "5px"}}
                        />
                        </Grid>
                    </Grid>
                </Grid>
                <div className='diversity-details'>
                    <div className='header'>
                        <span>Diversity Details</span>
                    </div>
                    <Grid className='sub-title'>
                        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                        <Box className='gender-container'>
                            {genderTags?.map((tag: string, index: number) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    style={selectGender === tag ? {color:"#002882", backgroundColor:'#F0F6FB', border:'1px solid #002882'} : {color:"#000000"}}
                                    variant="outlined"
                                    onClick={(e: any) => {
                                        setSelectGender(tag)
                                    }}
                                />
                            ))}
                        </Box>
                        <Box className='checkbox-wrapper'>
                            <Checkbox checked={disabilities ?? false} onChange={(e,v)=> setDisabilities(v)}/> Person with Disabilities
                        </Box>
                    </Grid>
                </div>
                <div className='display-details'>
                    <div className='header'>
                        <span>Display Details</span>
                    </div>
                    <Grid className='sub-title'>
                        <InputLabel id="demo-simple-select-label">Show</InputLabel>
                        <Box className='display-container'>
                            {displayshowTags?.map((tag: string, index: number) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    style={selectDisplayshow === tag ? {color:"#002882", backgroundColor:'#F0F6FB', border:'1px solid #002882'} : {color:"#000000"}}
                                    variant="outlined"
                                    onClick={(e: any) => {
                                        setSelectDisplayShow(tag)
                                    }}
                                />
                            ))}
                        </Box>
                    </Grid>
                    <Grid className='sub-title'>
                        <InputLabel id="demo-simple-select-label">Show only candidates with</InputLabel>
                        <Box className='display-container'>
                            {displayVerifiedTags?.map((tag: string, index: number) => (
                                <div key={index}>
                                
                                <Chip
                                    key={index}
                                    // avatar={<AddNewIcon color='red' className='add-new-icon'  key={index}/> }
                                    label={<div className='tag-inside'><span>{tag}</span>{selectDisplayVerified?.includes(tag)? <Tickmark/>:<AddNewIcon className='addIcon'/>}</div>}
                                    style={selectDisplayVerified?.includes(tag) ? {color:"#002882", backgroundColor:'#F0F6FB', border:'1px solid #002882'} : {color:"#000000"}}
                                    variant="outlined"
                                    onClick={(e: any) => {
                                        const arr = [...selectDisplayVerified]
                                        if(arr?.includes(tag)){
                                            const rval = arr.indexOf(tag)
                                            arr.splice(rval, 1)
                                            setSelectDisplayVerified(arr)
                                        }else{
                                            setSelectDisplayVerified([...arr, tag])
                                        }
                                    }}
                                />
                                </div>
                            ))}
                        </Box>
                    </Grid>
                </div>
                <div className='cancel-save-btn'>
                    <Button style={{ textTransform: "none" }} className='cancel-btn' onClick={() => { setUploadsuccess(false); setIsAddQuery(false) }} >
                        Cancel
                    </Button>
                    <Button 
                        disabled={(isSubmitting || sameNameError || "excludekeys" in errors || "keywords" in errors)} 
                        style={{ textTransform: "none", opacity: `${(isSubmitting || "excludekeys" in errors || "keywords" in errors) ? 0.5 : 1}` }} 
                        className='save-btn' 
                        type="submit"
                        onClick={checkKeywordError}
                    >
                        {isEdit && !isClone ? "Update" : "Save"}
                    </Button>

                </div>
            </Box>
            <Popover
                // id={id}
                open={isPopOpen}
                anchorEl={anchorEl}
                onClose={handlePopClose}
                anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
                }}
            >
                <Box style={{padding:"1rem"}}>
                    <div>
                        {popOverSkills?.map((tags:any, index:number)=>(
                            <Chip 
                                key={index} 
                                label={tags} 
                                onClick={(e:any)=>{
                                    const arr = [...popOverSelectTags]
                                    if(arr?.includes(tags)){
                                        const rval = arr.indexOf(tags)
                                        arr.splice(rval, 1)
                                        setPopOverSelectTags(arr)
                                    }else{
                                        setPopOverSelectTags([...arr, tags])
                                    }
                                }}
                            />
                        ))}
                    </div>
                    <div className='cancel-save-btn'>
                        <Button style={{ textTransform: "none" }} className='cancel-btn' onClick={()=> setIsPopOpen(false)} >
                            Cancel
                        </Button>
                        <Button style={{ textTransform: "none" }} className='save-btn' onClick={handleTagSelect} >
                            Add
                        </Button>
                    </div>
                </Box>
            </Popover>
            <AppErrorHandlerModal
                open={uploadsuccess}
                content={SuccessMessage}
                handleClose={()=> {setUploadsuccess(false); setIsAddQuery(false)}}
                factor='Success'
                ButtonText='Done'
            ><></></AppErrorHandlerModal>

            {
                showAddSkillDialog &&
                <AddSkillDialogbox
                    setDialog={setShowAddSkillDialog}
                    popOverSkills={popOverSkills}
                    setSkillKeywordsSelected={setIncludedkeys}
                    skillKeywordsSelected={includedkeys?.split(",") || []}
                />
            }
            {
                showExcludeSkillDialog &&
                <AddSkillDialogbox
                    setDialog={setShowExcludeSkillDialog}
                    popOverSkills={popOverSkills}
                    setSkillKeywordsSelected={setExcludekeys}
                    skillKeywordsSelected={excludekeys?.split(",") || []}
                />
            }
        </Box>
    )

}

export default AddQueryBuilder;