import { Container, Grid, Box, Typography, Stack, Divider, CircularProgress } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormInput } from '../FormInput'
import { ReactComponent as ForgotArrowIcon } from '../../../assets/icons/arrow-left.svg'
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg'
import { Slider } from '../SliderComponent/Slider'
import MainLogo from '../../../assets/images/xpheno.svg'
import ArtemisLogo from '../../../assets/images/Artemis.svg'
import './ForgotPassword.css'
import { ForgotPasswordService } from '../../../services/ForgotPasswordService'
import { useEffect, useState } from 'react'
import * as _ from "lodash";
import { setVerifyEmailUserId } from '../../../utils/user-utils'
import { useAppSelector } from '../../../store/hooks'
//  Login Schema with Zod
const forgotpasswordSchema = object({
  email: string().min(1, 'Email is required').email('Email is invalid'),
})

type IForgot = TypeOf<typeof forgotpasswordSchema>

const ForgotPasswordComponent = () => {
  const defaultValues: IForgot = {
    email: '',
  }
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);
  //  The object returned from useForm Hook
  const methods = useForm<IForgot>({
    resolver: zodResolver(forgotpasswordSchema),
    defaultValues,
  })
  const authUser = useAppSelector((state) => state.auth.token)
  useEffect(() => {
    if (authUser) navigate('/my-tasks')
  }, [authUser])

  const { setError } = methods;

  const handleNavigation = () => {
    navigate('/login')
  }

  const onSubmitHandler: SubmitHandler<IForgot> = (values: IForgot) => {
    showLoading();
    ForgotPasswordService.verifyEmail({email: values.email}).then((res) => {
      const userId = _.get(res, 'data.id');
      if(!userId) throw new Error("userId missing")
      setVerifyEmailUserId(userId);
      navigate('/forgot-password-otp');
      hideLoading();
    }).catch((e) => {
      setError('email', { type: 'custom', message: 'No account exists for the given email id' });
      hideLoading();
    })
  }

  return (
    <Container maxWidth={false}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className='login-screens-container-grid'
      >
        <Grid item className='forgot-password-form-container-grid'>
          <FormProvider {...methods}>
            <Grid container className='login-screens-container-sub-grid'>
              <Grid
                item
                container
                sx={{
                  marginInline: 'auto',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    paddingLeft: { sm: '3rem' },
                    paddingRight: { sm: '3rem' },
                    display: { md: 'block', sm: 'block', xs: 'none' },
                  }}
                >
                  {/* slidercomponent wrapper */}
                  <Slider />
                  {/* Formcomponent wrapper */}
                </Grid>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ mr: '-1px', display: { md: 'block', sm: 'block', xs: 'none' } }}
                />
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ paddingLeft: { sm: '3rem' }, paddingRight: { sm: '3rem' } }}
                >
                  <Box
                    sx={{
                      paddingLeft: { md: '0', lg: '3rem' },
                      paddingRight: { md: '0', lg: '3rem' },
                      mb: '1rem',
                    }}
                  >
                    <Box className="loginRightTopLogoSect">
                      <Box component='img' alt='Xpheno' src={MainLogo} />
                      <Box component='img' alt='Xpheno' src={ArtemisLogo} />                    
                    </Box>
                    <Stack direction='row' spacing={1}>
                      <ForgotArrowIcon onClick={handleNavigation} className='back-button' />
                      <Typography className='login-screen-welcome-text'>Forgot Password</Typography>
                    </Stack>
                    <Typography className='login-screen-welcome-note' sx={{ marginTop: '5px' }}>
                      Enter your e-mail ID below and we will send you the OTP for your registered
                      e-mail ID
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{
                      paddingLeft: { md: '0', lg: '3rem' },
                      paddingRight: { md: '0', lg: '3rem' },
                      marginTop: '30px',
                    }}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                  >
                    <Typography
                      variant='h6'
                      component='p'
                      sx={{
                        paddingLeft: { lg: '3rem' },
                        textAlign: 'center',
                      }}
                    ></Typography>

                    <FormInput
                      label='Email ID'
                      type='email'
                      name='email'
                      focused
                      required
                      disabled={isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <MailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <LoadingButton
                      loading={isLoading}
                      type='submit'
                      variant='contained'
                      className='login-button'
                      loadingIndicator={<CircularProgress color="primary" size={30} />}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ForgotPasswordComponent
