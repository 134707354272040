import {
  Drawer as MuiDrawer,
  styled,
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import { Routes } from './Routes'
import { rightnavClosedMixin, rightnavOpenedMixin } from '../../styles/mixins'
import { ReactComponent as ToogleOpenIcon } from '../../assets/icons/right-toggle-open.svg'
import { ReactComponent as ToogleCloseIcon } from '../../assets/icons/right-toggle-close.svg'
import mainLogo from '../../assets/images/Artemis.svg'
import collapseLogoIcon from '../../assets/artemis_logo_collapse.svg'

import './RightNavigation.css'

interface NavigationProps {
  open: boolean | undefined
  handleClose?: () => void
  toggleNavigation: () => void
  toggleQuickView: () => void
  toggleCallLogView: () => void
}

export const RightNavigation = ({ open, toggleNavigation, toggleQuickView, toggleCallLogView }: NavigationProps) => {
  console.log(open)
  return (
    <Drawer
      variant='permanent'
      className='xpheno-rightNavBar'
      open={open}
      anchor={'right'}
      sx={{
        display: { sm: 'block', xs: 'none', md: 'block' },
      }}
    >
      <Box className={`rightPanelLogo ${!open ? "expand" : ""}`}>{open ? <Box component='img' alt='Xpheno' src={mainLogo} /> : <Box component='img' alt='Xpheno' src={collapseLogoIcon}/>}</Box>
      <Routes open={open} toggleQuickView={toggleQuickView} toggleCallLogView={toggleCallLogView} />
      <Box sx={{ position: 'absolute', bottom: '25%' }}>
        <ListItemButton onClick={toggleNavigation}>
          <ListItemIcon>
            <IconButton>{open ? <ToogleCloseIcon /> : <ToogleOpenIcon />}</IconButton>
          </ListItemIcon>
        </ListItemButton>
      </Box>
    </Drawer>
  )
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...rightnavOpenedMixin(theme),
    '& .MuiDrawer-paper': rightnavOpenedMixin(theme),
  }),
  ...(!open && {
    ...rightnavClosedMixin(theme),
    '& .MuiDrawer-paper': rightnavClosedMixin(theme),
  }),
}))
