import React, { useEffect, useMemo, useState } from 'react';


interface TProps{
    mandateOptions: any[];
    onSelect: (e:string)=> void;

}

const MandateSpocSuggestion:React.FC<TProps>=({mandateOptions,onSelect})=> {
  
  return (
    <div className='main-suggestions'>
        <span className="suggested-heading">SUGGESTED Mandate SPOC</span>
        {mandateOptions?.length ? mandateOptions?.map((value,index)=>{
            return (<div key={index} className="eachboxes" onClick={(e)=>{e.preventDefault();onSelect(value)}}>
                <span className='mandate-title'>{value?.client_poc}</span>
                <span className='mandate-body-id'>{`(${value?.spoc_email} - ${value?.spoc_mobile})`}</span>
            </div>)
        }) :<></>}
    </div>
  )
}

export default MandateSpocSuggestion