import { ReactComponent as CheckListIcon } from '../assets/icons/checklist.svg'
import { ReactComponent as ScriptIcon } from '../assets/icons/script.svg'
import { ReactComponent as NotesIcon } from '../assets/icons/notes.svg'
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg'
import { ReactComponent as NotificationIcon } from '../assets/icons/notification.svg'
import { ReactComponent as CallLogIcon } from '../assets/icons/callLogIcon.svg'

import { ReactComponent as AuditHistoryIcon } from '../assets/icons/audit-history.svg'
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg'
import { Route } from '../types/Route'

const secondaryRoutesConfig: Array<Route> = [
  {
    key: 'router-checklist',
    title: 'Checklist',
    description: 'Checklist',
    path: '/checklist',
    isEnabled: true,
    icon: CheckListIcon,
    appendDivider: false,
    disable: true
  },
  {
    key: 'router-script',
    title: 'Script',
    description: 'Script',
    path: '/script',
    isEnabled: true,
    icon: ScriptIcon,
    appendDivider: false,
    disable: true
  },
  {
    key: 'router-notes',
    title: 'Notes',
    description: 'Notes',
    path: '/notes',
    isEnabled: true,
    icon: NotesIcon,
    appendDivider: false,
    disable: true
  },
  {
    key: 'router-calendar',
    title: 'Calendar',
    description: 'Calendar',
    path: '/calendar',
    isEnabled: true,
    icon: CalendarIcon,
    appendDivider: false,
    disable: true
  },
  {
    key: 'router-notifications',
    title: 'Notifications',
    description: 'Notifications',
    path: '/notifications',
    isEnabled: true,
    icon: NotificationIcon,
    appendDivider: false,
    disable: false
  },
  {
    key: 'router-calllog',
    title: 'Call Log',
    description: 'Call Log',
    path: '/callLog',
    isEnabled: true,
    icon: CallLogIcon,
    appendDivider: false,
    disable: false
  },
  {
    key: 'router-ah',
    title: 'Audit History',
    description: 'Audit History',
    path: '/audit-history',
    isEnabled: true,
    icon: AuditHistoryIcon,
    appendDivider: true,
    disable: true
  },
  {
    key: 'router-add-page',
    title: 'Add Page',
    description: 'Add Page',
    path: '/add-page',
    isEnabled: false,
    icon: PlusIcon,
    appendDivider: false,
    disable: true
  }
]

export default secondaryRoutesConfig
