import { useEffect, useState } from "react";

const CallDuration = () => {
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(()=>{
    const duration = setInterval(()=>{
      if(seconds === 59){
        setMinutes(minutes+1)
        setSeconds(0)
      }else{
        setSeconds(seconds+1)
      }
    }, 1000)

    return () =>{
      clearInterval(duration)
    }
  })

  return(
    <span className='duration'>{minutes< 10 ? "0"+minutes : minutes} : {seconds < 10 ? "0"+seconds : seconds}</span>
  )
}

export default CallDuration;