import React, { useEffect, useState } from 'react';
import ListTransfer from '../Common/ListTransfer/ListTransfer';
import { Box, Button } from '@mui/material';
import { getUserRoleList, getUserRoleSelectedList, postUserRoleSelectedList } from '../../services/UserService';
import { useAppSelector } from '../../store/hooks';
import { AppErrorHandlerModal } from '../AppComponents/AppErrorHandlerModal';
import { toast } from "react-toastify";

const UserDetailRoleView = ({userDetailData}:any) => {
    const { userId } = useAppSelector((state) => state?.UserDetail)
    const [userRoleList, setUserRoleList] = useState([])
    const [userRoleSelectedList, setUserRoleSelectedList] = useState<any[]>([])
    const [changesUserSelectedRole, setChangesUserSelectedRole] = useState([])
    const [userRoleSelectedListCopy, setUserRoleSelectedListCopy] = useState([])
    const [changesUserRoleReset, setChangesUserRoleReset] = useState(false)
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
      }
    
    const getUserRoleSelectedListApi = async () => {
        const res:any = await getUserRoleSelectedList({
            "userId": userDetailData.keyCloakId
        })
        if(res.status == 200){            
            const optionRight = res?.data?.result?.data.assignedRoles.map((item:any) => {
                return {
                    label: item.role_name,
                    id: item.id,
                    displayStatus: false
                }
            })            
             const options = res?.data?.result?.data?.availableRoles.map((item:any) => {
                return {
                    label: item.role_name,
                    id: item.id,
                    displayStatus: false
                }
            })
            setUserRoleSelectedList(optionRight)
            setUserRoleSelectedListCopy(optionRight)
            setUserRoleList(options)
        }
    }
    const postUserSelectedreset = async () => {
        setChangesUserRoleReset(true)
    }
    const postUserSelectedApi = async () => {
        const selectedListArray = changesUserSelectedRole.map((item:any) => {return item.id})
        const userRoleSelectedListArray = userRoleSelectedList.map((item:any) => {return item.id})
        const selectedUserPayload:any = {
            "userId": userDetailData.keyCloakId
        }
        if(userRoleSelectedList.length > 0 ){
            selectedUserPayload["removeList"] = userRoleSelectedListArray.filter((userItem:any) => selectedListArray.indexOf(userItem) == -1)
            selectedUserPayload["addList"] = selectedListArray.filter((userItem:any) => userRoleSelectedListArray.indexOf(userItem) == -1)
        } else{
            selectedUserPayload["addList"] = selectedListArray
        }
        const res:any = await postUserRoleSelectedList(selectedUserPayload)
        if(res.status == 200){
            toast.success(userRoleSelectedList.length > 0 ? "User Role Successfully updated": "User Role Successfully Added")
        }
    }
    const initialLoadData = (async () => {
        getUserRoleSelectedListApi()
    })
    useEffect(() => {
        initialLoadData()
    },[])
    return (
        <>
            {userRoleList.length > 0 && <ListTransfer userRoleList={userRoleList} userRoleSelectedList={userRoleSelectedList} setChangesUserSelectedRole={setChangesUserSelectedRole} changesUserRoleReset={changesUserRoleReset} setChangesUserRoleReset={setChangesUserRoleReset}/>}
            <Box className='button-container'>
                <Button 
                    className='cancel-btn'
                    onClick={() => {postUserSelectedreset()}}
                >Reset</Button>
                <Button 
                    disabled={JSON.stringify(userRoleSelectedListCopy) == JSON.stringify(changesUserSelectedRole)}
                    className='updateBtn'
                    onClick={() => {postUserSelectedApi()}}
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default UserDetailRoleView;