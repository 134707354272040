import React, { useEffect, useState,useRef} from 'react'
import '../Notification/Notification.scss'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { callLogAllList } from '../../../services/NotificationService';
import moment from 'moment';
import AgentMissedCall from "../../../assets/images/agentMissedCall.svg"
import CallMissed from "../../../assets/images/call_missed.svg"
import CallIncoming from "../../../assets/images/call_incoming.svg"
import CallOutgoing from "../../../assets/images/call_outgoing.svg"
import { Avatar, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { openNotification, resetCallNotification } from '../../../features/notification/notificationReducer';
import { useAppDispatch } from '../../../store/hooks';
import { righttoggle } from '../../../features/toggle/ToggleReducer';

const CallLogs: React.FC<any> = (props) => {
  const [value, setValue] = React.useState('1');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [todayIndex,setTodayIndex] = useState<any>(null);
  const [olderIndex, setOlderIndex] = useState<any>(null);
  const [pageCount, setPageCount] = useState(1)
  const [reachedBottom,setReachedBottom] = useState<boolean>(false)
  const [callLogError, setCallLogError] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [callLogPageData, setCallLogPageData] = useState<any>({
    "AllTab": [],
    "Missed": [],
    "Incoming": [],
    "Outgoing": []
  })
  const todayDate = moment().format('YYYY-MM-D')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    const isAtTop = scrollTop === 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
    if(isAtBottom && !reachedBottom){
      setPageCount(pageCount + 1)
      setReachedBottom(true);
    }
    else if (!isAtBottom) {
      // Reset the flag when scrolling away from the bottom
      setReachedBottom(false);
    }  
};

const checkTodayOlderData = (data:any) => {
  const todayDate = moment().format('YYYY-MM-D')
  let todayStatus = false;
  let olderStatus = false;
  data.map((item:any, index:number) => {
    if(!todayStatus){
      if(item?.date == todayDate) {
        setTodayIndex(index)
        todayStatus = true
      }
      
    }
    if(!olderStatus){
      if(item?.date != todayDate) {
        setOlderIndex(index)
        olderStatus = true;
      }
    }    
  })
}
const callLogTabAPICall = async (tab:string) => {
  const callLogTabObj:any = {
    page: "",
    limit: 15,
    fromDate: "",
    toDate: "",
    destination: null,
    callType: "",
    direction: ""
  }
  try{    
    if(tab == "AllTab"){
      callLogTabObj["page"] = pageCount;
      callLogTabObj["fromDate"] = moment().subtract(7, 'days').format('YYYY-MM-D')+ " 12:00:00";
      callLogTabObj["toDate"] = moment().format('YYYY-MM-D') + " 23:59:59"
    }else if(tab == "Missed"){
      callLogTabObj["page"] = pageCount;
      callLogTabObj["fromDate"] = moment().subtract(7, 'days').format('YYYY-MM-D')+ " 12:00:00";
      callLogTabObj["toDate"] = moment().format('YYYY-MM-D') + " 23:59:59";
      callLogTabObj["direction"] = "inbound";
      callLogTabObj["callType"] = "m"
    }
    const resp = await callLogAllList(callLogTabObj)
    // if(resp?.status === 200){      
      if(pageCount > 1){
        const olderCallLogList:any =  [...callLogPageData[tab ?? []], ...resp?.result?.list ?? []];
        checkTodayOlderData(olderCallLogList)
        setCallLogPageData({...callLogPageData,[tab]:olderCallLogList})
      }else{
        checkTodayOlderData(resp?.result?.list)
        setCallLogPageData({...callLogPageData,[tab]:resp?.result?.list})
      }
      setCallLogError(false)
    // }
    
  }catch(error:any){
    if(error?.response?.data?.message.indexOf("Unauthorized") > -1){
      setCallLogError(true)
    }
  }
}

useEffect(() =>{
  callLogTabAPICall("AllTab")
},[pageCount])

const navigateCallLog = (callItem:any) => {
  if(callItem != "UNKNOWN"){
    dispatch(openNotification({ module: "smart_call", id: callItem }))
    dispatch(resetCallNotification())
    setTimeout(() => {
      navigate(`/candidate/${callItem}`,{state:{source:"smartCall",id:callItem,timeStamp: new Date().getTime()}})
    }, 500)
  }
  
}

const getCandidateLetter = (candidateName:string) => {
  const nameArray = candidateName.trim().split(" ")
  const nameLetter:any = [];
  nameArray.map((item:any) => {
    nameLetter.push(item.charAt(0))
  })
  return nameLetter[0]
}


  return (
    <div className="notification-container callLog-container">
      <div className="notify-header">
        <div className="header-text">Call Logs</div>
        <div className='icon'></div>
      </div>
      <Box>
        <Box className="callLogScrollSect" ref={containerRef} onScroll={handleScroll}>
              {!callLogError ? callLogPageData["AllTab"].map((items:any,index:number) => {
                return(
                  <>
                  {todayIndex == index && <h3 className='todayTitle'>TODAY</h3>}
                  {olderIndex == index && <h3 className='todayTitle'>OLDER</h3>}
                  <Box className={`callLogCardSect ${items?.candidate_id == "UNKNOWN" ? "callLogListUnknow" : ""}`} onClick={() => {navigateCallLog(items?.candidate_id)}}>
                    <Box className="callLogProfile">
                      {items?.candidate_name != "UNKNOWN" ? <Box className="candidateNameCircle">{items?.candidate_name != undefined ? getCandidateLetter(items?.candidate_name) : ""}</Box> : <Avatar />}
                      <Box className="callLogProfileContent">
                        <h4 className={items?.candidate_name == "UNKNOWN" ? "noGap" : ""}><Tooltip title={items?.candidate_name != "UNKNOWN" ? items?.candidate_name : ""}><span className={`name ${items?.status == "missed"? "candidateMissed" : ""}`}>{items?.candidate_name != "UNKNOWN" ? items?.candidate_name : ""}</span></Tooltip><span className='phone'>{`${items?.candidate_name != "UNKNOWN" ? "(" : ""}${items?.client_number}${items?.candidate_name != "UNKNOWN" ? ")" : ""}`}</span></h4>
                        {/* <h5>{items?.caller_id_num}</h5> */}
                        <p>{items?.end_stamp}</p>
                      </Box>
                    </Box>                  
                    <Box>
                      <img src={(items?.status == "missed" && items?.direction == "outbound") ? CallMissed : (items?.status == "missed" && items?.direction == "inbound") ? AgentMissedCall : ( items?.status == "answered" && items?.direction == "outbound") ? CallOutgoing: CallIncoming} />
                    </Box>
                  </Box>
                  </>
                )
              }):
              <p className='unauthorizedText'>Unauthorized for smart call</p>
              }
            </Box>
      </Box>
      {/* <TabContext value={value}> */}
        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="All" value="1" onClick={()=> callLogTabAPICall("AllTab")} />
            <Tab label="Missed" disabled  value="2" onClick={()=> callLogTabAPICall("Missed")}/>
            <Tab label="Incoming" disabled  value="3" />
            <Tab label="Outgoing" disabled  value="4" />
          </TabList>
        </Box> */}
        {/* <TabPanel value="1" style={{paddingRight:"0px"}}>
          
        </TabPanel> */}
        {/* <TabPanel value="2" style={{paddingRight:"0px"}}>
          <Box className="callLogScrollSect" ref={containerRef} onScroll={handleScroll}>
          {callLogPageData["Missed"].map((items:any,index:number) => {
              return(
                <>
                <Box className="callLogCardSect">
                  <Box className="callLogProfile">
                    <Avatar />
                    <Box className="callLogProfileContent">
                      <h5>{items?.caller_id_num}</h5>
                      <p>{items?.end_stamp}</p>
                    </Box>
                  </Box>                  
                  <Box>
                    <img src={items?.status == "missed" ? CallMissed : ( items?.status == "answered" && items?.direction == "outbound") ? CallOutgoing: CallIncoming} />
                  </Box>
                </Box>
                </>
              )
            })}
          </Box>
        </TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
        <TabPanel value="4">Item Four</TabPanel> */}
      {/* </TabContext> */}
      
    </div>
  )
}

export default CallLogs