import React, { useEffect, useMemo, useState } from 'react';
// import './index.css';
import { CheckboxProps, Checkbox, Input, Tree, TreeDataNode, TreeProps } from 'antd';
// import type { TreeDataNode, TreeProps } from 'antd';
import { filterTree, renderTreeNodes } from './utils.js';
import { Box, Tooltip } from '@mui/material';
const { Search } = Input;
const defaultData: TreeDataNode[] = [];
import "./ListTreetransfer.scss"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

interface TreeNode {
  title: string;
  key: string;
  disableCheckboxClass?: boolean;
  children?: TreeNode[];
  value: number,
  type: string,
  searchValue?:boolean
}

interface SelectedTreeNode {
  title: string;
  key: string;
  children?: SelectedTreeNode[];
  value: number,
  type: string,
  searchValue?:boolean
}
const ListTreeAntTranfer = ({treeViewDataObj,treeViewSelectedDataObj,setUserModifiedTreeView,isEdit}:any) => {  
  const [listTreeData, setListTreeData] = useState<any>(treeViewDataObj);
  const [listTreeViewCopy, setListTreeViewCopy] = useState(listTreeData)
  const getKeysList:any = [];
  const getPayloadDataKeys = (treeViewDataObj:any) => {
    const selectedKeys:any = [];

    function collectSelectedKeys(nodes:any) {
        for (const node of nodes) {
            selectedKeys.push(node.key);
            if (node.children) {
                collectSelectedKeys(node.children);
            }
        }
    }

    collectSelectedKeys(treeViewDataObj);
    return selectedKeys;
};
function updateSearchValue(node:any) {
  if (node.searchValue !== undefined) {
      node.searchValue = false;
  }
  if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => updateSearchValue(child));
  }
}

function removeSearchValueKey(tree:any) {
  function traverseAndRemove(node:any) {
      if ('searchValue' in node || 'disableCheckboxClass' in node) {
          delete node.searchValue;
          delete node.disableCheckboxClass;
      }
      if (node.children && node.children.length > 0) {
          node.children.forEach(traverseAndRemove);
      }
  }
  tree.forEach(traverseAndRemove);
  return tree;
}
  
  const [allKeys, setAllKeys] = useState([])
  const [selectAllKeys, setSelectAllKeys] = useState([])
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [checkedRightNodes, setCheckedRightNodes] = useState([]);
  const [checkedNodesTransfer, setCheckedNodesTransfer] = useState(isEdit ? treeViewSelectedDataObj : []);
  const dataList: { key: React.Key; title: string }[] = [];
  const [expandedLeftKeys, setExpandedLeftKeys] = useState<React.Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [checkedRightKeys, setCheckedRightKeys] = useState([]);
  const [leftSidePanelCheckStatus, setLeftSidePanelCheckStatus] = useState(false)
  const [rightSidePanelCheckStatus, setRightSidePanelCheckStatus] = useState(false)

function traverseObject(nodes: TreeNode[]): string[] {
    const keys: string[] = [];
    function recurse(node: TreeNode) {
        keys.push(node.key);
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => recurse(child));
        }
    }
    nodes.forEach(node => recurse(node));
    return keys;
}

function traverseGetChildKey(nodes: TreeNode[]): string[] {
  const keys: string[] = [];
  function recurse(node: TreeNode) {      
      if (node.children && node.children.length > 0) {
          node.children.forEach(child => recurse(child));
      }else{
        keys.push(node.key);
      }
  }
  nodes.forEach(node => recurse(node));
  return keys;
}
useEffect(() => {
  if(isEdit){
    setUserModifiedTreeView(treeViewSelectedDataObj)
    const selectedEditObj = traverseGetChildKey(treeViewSelectedDataObj);
    setCheckedKeys(selectedEditObj)
    setListTreeData(updateDisableCheckbox(treeViewDataObj, treeViewSelectedDataObj))
    setAllKeys(getPayloadDataKeys(listTreeData))
    setExpandedKeys(getPayloadDataKeys(treeViewSelectedDataObj))
  }
  else{
    setAllKeys(getPayloadDataKeys(listTreeData))
  }
},[treeViewSelectedDataObj])

function arraysEqual(a:any, b:any) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

  const onCheck = (selectedKeys:any, info:any) => {
    setCheckedKeys(selectedKeys);
    const filteredTree = filterTree(selectedKeys, info.halfCheckedKeys, listTreeData);
    const filterTreeArrayList = traverseObject(filteredTree)
    setCheckedNodes(filteredTree);
    const checkAllStatus = arraysEqual(allKeys.sort(), selectedKeys.sort())
    setLeftSidePanelCheckStatus(checkAllStatus)
  };
  const onCheckRight = (selectedKeys:any, info:any) => {
    const selectedAllKeys = getPayloadDataKeys(checkedNodesTransfer);
    setCheckedRightKeys(selectedKeys);
    const filteredTree = filterTree(selectedKeys, info.halfCheckedKeys, listTreeData);
    setCheckedRightNodes(filteredTree);
    const checkAllStatus = arraysEqual(selectedAllKeys.sort(), selectedKeys.sort())
    setRightSidePanelCheckStatus(checkAllStatus)
  };
  const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // const newExpandedKeys = dataList
    //   .map((item) => {
    //     if (item.title.indexOf(value) > -1) {
    //       return getParentKey(item.key, defaultData);
    //     }
    //     return null;
    //   })
    //   .filter((item, i, self): item is React.Key => !!(item && self.indexOf(item) === i));
    //   setExpandedLeftKeys(newExpandedKeys);
      const searchFilterObj = searchKeyWord(listTreeData,value)
      setListTreeData(searchFilterObj)
      // setSearchValue(value);
      setAutoExpandParent(true);
  };
  const onRightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
      const searchFilterObj = searchKeyWord(checkedNodesTransfer,value)
      setCheckedNodesTransfer(searchFilterObj)
      // setSearchValue(value);
      setAutoExpandParent(true);
  };
  const onChangeCheck: CheckboxProps['onChange'] = (e) => {
    // setSelectAllKeys(e.target.checked ? allKeys : [])
    setCheckedKeys(e.target.checked ? allKeys : []);
    setCheckedNodes(listTreeViewCopy)
    setLeftSidePanelCheckStatus(e.target.checked)
    // (listTreeData)
  };
  const onChangeRightCheck: CheckboxProps['onChange'] = (e) => {
    // setSelectAllKeys(e.target.checked ? allKeys : [])
    const getRighKeys = getPayloadDataKeys(checkedNodesTransfer)
    setCheckedRightKeys(e.target.checked ? getRighKeys : []);
    setCheckedRightNodes(checkedNodesTransfer)
    setRightSidePanelCheckStatus(e.target.checked)
    // (listTreeData)
  };  
  const selectedNodeTransfer = (type:string) => {
    if(type == "singleLeft"){
      const selectedKeys = buildSelectedSet(checkedRightNodes);
      const extractedList:any = extractDifference(checkedNodesTransfer, selectedKeys)
      setCheckedNodesTransfer(extractedList)
      const updatedList = updateDisableCheckbox(listTreeData, extractedList);
      setListTreeData(updatedList)
      setRightSidePanelCheckStatus(false)
      const leafArray = traverseGetChildKey(extractedList)
      const filterKeys:any = checkedKeys.filter((itemStr:any) => leafArray.includes(itemStr))
      setCheckedKeys(filterKeys);
      setUserModifiedTreeView(removeSearchValueKey(extractedList))
    }else if(type == "AllLeft"){
      setCheckedNodesTransfer([])
      setListTreeData(listTreeViewCopy)
      setRightSidePanelCheckStatus(false)
      setCheckedKeys([]);
      setUserModifiedTreeView([])
    }else if(type == "singleRight"){
      const newTreeView = removeSearchValueKey(checkedNodes);
      setCheckedNodesTransfer(newTreeView)
      const updatedList = updateDisableCheckbox(listTreeData, checkedNodes);
      setListTreeData(updatedList)
      setCheckedRightKeys([])
      setLeftSidePanelCheckStatus(false)
      setUserModifiedTreeView(newTreeView)
      setExpandedKeys(getPayloadDataKeys(checkedNodes))
    }else{
      setCheckedNodesTransfer(listTreeViewCopy)
      const updatedList = updateDisableCheckbox(listTreeData, listTreeViewCopy);
      setListTreeData(updatedList)
      setCheckedRightKeys([])
      setLeftSidePanelCheckStatus(false)
      setUserModifiedTreeView(removeSearchValueKey(listTreeViewCopy))
    }
  }

  function updateDisableCheckbox(fullList: TreeNode[], selectedList: SelectedTreeNode[]): TreeNode[] {
    // Create a set of keys from the selectedList
    const selectedKeys = new Set<string>();

    function collectSelectedKeys(nodes: SelectedTreeNode[]) {
        for (const node of nodes) {
            selectedKeys.add(node.key);
            if (node.children) {
                collectSelectedKeys(node.children);
            }
        }
    }

    collectSelectedKeys(selectedList);

    // Function to create a new updated node list
    function updateNodes(nodes: TreeNode[]): TreeNode[] {
        return nodes.map(node => {
            const updatedNode = { ...node };

            if (node.children && node.children.length > 0) {
                updatedNode.children = updateNodes(node.children);
                // Disable if all children are in the selectedKeys set
                const allChildrenSelected = node.children.every(child => selectedKeys.has(child.key));
                if (allChildrenSelected) {
                    updatedNode.disableCheckboxClass = true;
                } else {
                  updatedNode.disableCheckboxClass = false;
                }
            } else {
                // Disable if this node is a leaf node and present in selectedKeys set
                if (selectedKeys.has(node.key)) {
                    updatedNode.disableCheckboxClass = true;
                } else {
                  updatedNode.disableCheckboxClass = false;
                }
            }

            return updatedNode;
        });
    }

    return updateNodes(fullList);
}


function buildSelectedSet(selectedNodes: TreeNode[]): Set<string> {
  const keys = new Set<string>();

  function collectKeys(nodes: TreeNode[]) {
      for (const node of nodes) {
          keys.add(node.key);
          if (node.children) {
              collectKeys(node.children);
          }
      }
  }

  collectKeys(selectedNodes);
  return keys;
}

function extractDifference(fullNodes: TreeNode[], selectedKeys: Set<string>): TreeNode[] {
  return fullNodes.reduce((acc: TreeNode[], node: TreeNode) => {
      // Check if the current node's key is in the selectedKeys set
      if (!selectedKeys.has(node.key)) {
          // If the node has children, recursively check them as well
          const newNode: TreeNode = { ...node };
          if (node.children) {
              newNode.children = extractDifference(node.children, selectedKeys);
          }
          acc.push(newNode);
      } else if (node.children) {
          // If the node is selected but has children, we need to process its children
          const childrenDifference = extractDifference(node.children, selectedKeys);
          if (childrenDifference.length > 0) {
              acc.push({ ...node, children: childrenDifference });
          }
      }
      return acc;
  }, []);
}



// search case code

const searchKeyWord = ((dataList:any,searchTxt:string) => {
  const loop = (data: any):any =>
    data.map((item:any) => {
      const strTitle = item.title as string;
      const index = strTitle.toLowerCase().indexOf(searchTxt.toLowerCase());
      const beforeStr = strTitle.substring(0, index);
      const afterStr = strTitle.slice(index + searchTxt.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{searchTxt}</span>
            {afterStr}
          </span>
        ) : (
          <span>{strTitle}</span>
        );
      if (item.children) {
        const modifiedObj:any = { title: strTitle, key: item.key, children: loop(item.children),type: item.type,disableCheckboxClass: item.disableCheckboxClass,value:item.value }
        if(searchTxt.length > 0 && index > -1) {
          modifiedObj["searchValue"] = true
        }else{
          modifiedObj["searchValue"] = false
        }
        return modifiedObj;
      }

      return {
        title: item.title,
        key: item.key,
        value: item.value,
        type: item.type,
        disableCheckboxClass: item.disableCheckboxClass,  
        searchValue: (index > -1 && searchTxt.length > 0) ? true : false
      };
    });

  return loop(dataList);
})

const onExpand = (newExpandedKeys: React.Key[]) => {
  setExpandedKeys(newExpandedKeys);
  setAutoExpandParent(false);
};


  return (
    <Box className="listTreeViewTransferBlk">
      <Box className="listTreeViewTransferLeft">
        <h5>Access List</h5>
        <Search placeholder="Search Keyword" onChange={onChange} />
        <Box className="listTreeViewCheckAll">
          <Checkbox onChange={onChangeCheck} checked={leftSidePanelCheckStatus}>Select All</Checkbox>
        </Box>
        <Tree 
        checkable 
        defaultExpandAll 
        onCheck={onCheck}
        defaultSelectedKeys={checkedKeys}
        defaultCheckedKeys={checkedKeys}
        checkedKeys={checkedKeys}
        >
          {renderTreeNodes(listTreeData)}
        </Tree>
      </Box>
      <Box className="listTreeAction">
          <Tooltip title={"Move Left"} placement="right"><span><KeyboardArrowLeftIcon onClick={() => {selectedNodeTransfer("singleLeft")}}/></span></Tooltip>
          <Tooltip title={"Move All Left"} placement="right"><span><KeyboardDoubleArrowLeftIcon onClick={() => {selectedNodeTransfer("AllLeft")}}/></span></Tooltip>
          <Tooltip title={"Move Right"} placement="right"><span><KeyboardArrowRightIcon onClick={() => {selectedNodeTransfer("singleRight")}}/></span></Tooltip>
          <Tooltip title={"Move All Right"} placement="right"><span><KeyboardDoubleArrowRightIcon onClick={() => {selectedNodeTransfer("AllRight")}}/></span></Tooltip>
      </Box>

      <Box>
        <h5>Selected Access</h5>
        <Search placeholder="Search Keyword" onChange={onRightChange} />
        <Box className="listTreeViewCheckAll">
          <Checkbox onChange={onChangeRightCheck} checked={rightSidePanelCheckStatus}>Select All</Checkbox>
        </Box>
        <Tree 
          checkable 
          defaultExpandAll 
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          checkedKeys={checkedRightKeys}
          onCheck={onCheckRight}>
            {renderTreeNodes(checkedNodesTransfer)}
        </Tree>
      </Box>
      
     
  </Box>
  );
};

export default ListTreeAntTranfer;