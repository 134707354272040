import React, { useState } from 'react'
import { Box, Menu, List, Divider, Collapse } from '@mui/material'
import { RouteItem } from '../../Navigation/Routes/RouteItem'
import secondaryRoutesConfig from '../../../config/secondary-routes'
import routes from '../../../config/routes'

import { Route } from '../../../types/Route'
interface MobileMenuProps {
  isMenuOpen: boolean
  handleMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void
  handleMenuClose: () => void
  anchorEl: HTMLElement | null
  open?: boolean | undefined
}

export const MobileMenu = ({ isMenuOpen, handleMenuClose, anchorEl, open }: MobileMenuProps) => {
  const [routesState, setRoutesStage] = useState<Route[]>(secondaryRoutesConfig)

  const handleMenuClick = (route: Route) => {
    const items = routesState.map((item) => {
      if (item.key === route.key) {
        item.expanded = !item.expanded
      }
      return item
    })
    setRoutesStage(items)
  }
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='primary-search-account-menu-mobile'
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box sx={{ textAlign: 'center' }}>
        <List component='nav' sx={{ height: '100%' }}>
          {routesState.map((route: Route) => (
            <div key={route.key}>
              {route.subRoutes ? (
                <>
                  <RouteItem
                    key={`${route.key}`}
                    route={route}
                    hasChildren
                    handleMenuClick={handleMenuClick}
                    open={open}
                  />
                  <List component='div' disablePadding>
                    {route.subRoutes.map((sRoute: Route) => (
                      <RouteItem key={`${sRoute.key}`} route={sRoute} nested open={open} />
                    ))}
                  </List>
                </>
              ) : (
                <RouteItem key={route.key} route={route} nested={false} open={!open} />
              )}
              {route.appendDivider && <Divider />}
            </div>
          ))}
        </List>
      </Box>
    </Menu>
  )
}
export const MobileNavigationMenu = ({
  isMenuOpen,
  handleMenuClose,
  anchorEl,
  open,
}: MobileMenuProps) => {
  const [routesState, setRoutesStage] = useState<Route[]>(routes)

  const handleMenuClick = (route: Route) => {
    const items = routesState.map((item) => {
      if (item.key === route.key) {
        item.expanded = !item.expanded
      }
      return item
    })
    setRoutesStage(items)
  }
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='primary-search-account-menu-mobile'
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box sx={{ textAlign: 'center' }}>
        <List component='nav' className='list'>
          {routesState.map((route: Route) => (
            <div key={route.key}>
              {route.subRoutes ? (
                <>
                  <RouteItem
                    key={`${route.key}`}
                    route={route}
                    hasChildren
                    handleMenuClick={handleMenuClick}
                    open={open}
                  />
                  <Collapse in={route.expanded} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {route.subRoutes.map((sRoute: Route) => (
                        <RouteItem key={`${sRoute.key}`} route={sRoute} nested open={!open} />
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                <RouteItem key={route.key} route={route} nested={false} open={!open} />
              )}
            </div>
          ))}
        </List>
      </Box>
    </Menu>
  )
}
