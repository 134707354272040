import React from 'react';
import { reportInstance } from '../http-common';

class HiringDemandListService {
    getHiringDemandList = async (listData:any) => {
        const response = await reportInstance.post('/dashboard/hiringDemand',listData)
        return response;
    }

  
    gethiringdemandlistapi = async (listData:any) => {
        const response = await reportInstance.post('/dashboard/clientsActiveProfile',listData)
        return response;
    } 
    // getBussinessHierarchy = async () => {
    //     const response = await instance.get('candidate/getCurrentUserDropDown')
    //     return response;
    // }
    // exportSubmitionReport = async (reqBody:any) => {
    //     const response = await instance.post('candidate/candidateSubmissionExcelExport/',reqBody,{responseType: 'blob'})
    //     return response;
    // }
};

export const HiringDemandListServiceApi = new HiringDemandListService();