import { Box, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import ImageCropProvider from '../../components/Common/ProfileUpload/ImageCropProvider';
import ImageCrop from '../../components/Common/ProfileUpload/ImageCrop';
import { ReactComponent as HintsIcon } from '../../assets/icons/info-circle.svg'
import { getCountryCode } from '../../services/UserService';
import { emailRegex } from '../../utils/constants';

const PersonalCandidateInformation = ({isEdit,profileLink}:any) => {
    const { control, formState: { errors },getValues,setValue,clearErrors,trigger } : any = useFormContext()
    const phoneNumberRegex = /^([+\d].*)?[\de]+$/gm    
    const [countryCode,setCountryCode] = useState([])
    const getCountryCodeApi = async () => {
        try{
          const response = await getCountryCode();
          setCountryCode(response?.data)
        }
          catch(error:any){
            console.log(error)        
          }
      }

      useEffect(() => {
        getCountryCodeApi()
      },[])

    return (
        <Grid className='container-wrapper'>
            <Box className="personalInfoWrapper">
                <Box className="personalInfoProfileSect">
                    <FormControl fullWidth >
                        <Controller
                            name="employeeProfile"
                            control={control}
                            // rules={{
                            // required: {
                            //     value: true,
                            //     message: "This field is required",
                            // }
                            // }}
                            render={({field : {onChange, value, onBlur}})=>(
                            <ImageCropProvider>
                                <ImageCrop onChange={onChange} onBlur={onBlur} isEdit={isEdit} profileLink={profileLink} value={value} enableClose={true}/>
                            </ImageCropProvider>
                            )}
                        />
                        <Box className='helper-boxtext' style={{width:"150px"}}>
                            {' '}
                            <HintsIcon />{' '}
                            <Box style={{width: "250px"}}>
                                {`Note: png, jpg, webp. Maximum Size 5 MB`}
                            </Box>
                        </Box>
                        {/* {errors?.employeeProfile && (
                            <span className="reminder_text-danger select-danger-text" role="alert">
                                {errors?.employeeProfile?.message}
                            </span>
                        )} */}
                    </FormControl>   
                </Box>
                <Box className="personalInfoFieldWrapper">
                <Grid className='row-grid mb-3'>
                <FormControl fullWidth required >
                    <Controller
                        name="candidateName"
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Candidate Name'
                            required={true}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                    {errors?.candidateName && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.candidateName?.message}
                        </span>
                    )}
                </FormControl>
                <FormControl fullWidth required >
                    <Controller
                        name="candidateEmail"
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        },
                        pattern: {
                            value: emailRegex,
                            message: `Please enter valid Email`,
                          },
                        // validate: {
                        // required: (value) => {
                        //     if (value.indexOf("@xpheno") == -1) return 'Please enter valid xpheno email';
                        //     if (!value) return '*Required';
                        // }
                        // },
                        }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Email'
                            required={true}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                    {errors?.candidateEmail && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.candidateEmail?.message}
                        </span>
                    )}
                </FormControl> 
                <FormControl className='manLocPhoneNumberField numberFieldSect' fullWidth required>
                        <Controller
                            name="primaryContact"
                            control={control}
                            rules={{
                            required: {
                                value: true,
                                message: "Phone number is required",
                            },
                            pattern: {
                                value: phoneNumberRegex,
                                message: `Please enter valid Phone`,
                            },
                            maxLength: {
                                value: 15,
                                message: "This input accept only max length of 15.",
                            },
                            minLength: {
                                value: 10,
                                message: "Minimum 10 number required.",
                            },
                            }}
                            render={({field : {onChange, value, onBlur}})=>(
                            <TextField
                                label='Phone no'
                                value={value}
                                type='number'
                                onChange={onChange}
                                onBlur={onBlur}
                                required
                                inputProps={{ maxLength: 100 }}
                            />
                            )}
                        />
                        {errors?.primaryContact && (
                            <span className="reminder_text-danger select-danger-text" role="alert">
                                {errors?.primaryContact?.message}
                            </span>
                        )}
                    </FormControl>
            </Grid>
            <Grid className='row-grid' style={{width:"calc(((100% / 3) * 2) - 7px)"}}>
                <FormControl fullWidth>
                    <Controller
                        name="candidateSummary"
                        control={control}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Candidate Summary'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            multiline={true}
                            minRows={3}
                            inputProps={{ maxLength: 256 }}                
                        />
                        )}
                    />
                </FormControl>
            </Grid>
                </Box>
                
            </Box>            
        </Grid>
    );
};

export default PersonalCandidateInformation;