import _ from 'lodash';
import {candidateInstance, forminstance, instance, scrapperInstance} from './http-common'
import { objectToQueryString } from '../utils/common-utils';

const mockFilterDropDownResponse = {
    "location" : ["madurai","chennai"],
    "preferredLocation" : ["madurai1","chennai1"],
    "primarySkills" : ["JAVA","SPRING","PYTHON"],
    "secondarySkills" : ["JAVA1","SPRING1","PYTHON1"],
    "education" : ["education1", "education2"],
    "company" : ["company1", "company2"],
    "designation" : ["designation1", "designation2"],
    "minSalary" : 3,
    "maxSalary" : 10,
    "matchCriteria" : ["matchCriteria1", "matchCriteria2"],
    "minConfidenceScore" : 3,
    "maxConfidenceScore" : 10,
    "searchQueryBuilder" : [{id: 1, name: "searchQuery1"}, {id: 2, name: "searchQuery2"}],
    "minExperience" : 3,
    "maxExperience" : 10,
}

export const GetCandidateDetails = async (id: any) => {
    const response = await forminstance.get(`candidate/${id}`);
    return response;

} 

export const  getCandidateFilterDropdowns = async (id: any, profileType = '', keyword = '', tabType:string,locationId:number,searchQueryId:any) => {
    // return new Promise((resolve) => resolve({data: mockFilterDropDownResponse}));
    const queryString = objectToQueryString(locationId != undefined ? {
        profileType: profileType,
        mandateId: id,
        mlocationId: locationId,
        keyword: keyword,
        tabType: tabType
    }:{
        profileType: profileType,
        mandateId: id,
        keyword: keyword,
        tabType: tabType,
        searchQueryId:searchQueryId
    });
    const response = await instance.get(`candidate/filterValues?${queryString}`);
    return response;

}

export const getCandidateList = async (requestBody: any, queryParams: any, queryType:string) => {
    const queryString = objectToQueryString({
        page: _.get(queryParams, 'page'),
        size: _.get(queryParams, 'size'),
        profileType: _.get(queryParams, 'profileType'),
        tabType: _.get(queryParams, 'tabType'),
        queryType: queryType
    });
    if(requestBody["mlocationId"] == null && requestBody["locationid"] > 0){
        requestBody["mlocationId"] = requestBody["locationid"]
        requestBody["locationid"] = ""
    }        
    const response = queryType == "FILTER_DATA" ? await candidateInstance.post(`candidate/list?${queryString}`, requestBody) : await instance.post(`candidate/list?${queryString}`, requestBody);
    return response;
       }

export const getCandidateTypeSense = async (requestBody: any, queryParams: any, pageDetails:any, tabVal:any) => {
    const queryString:any = {        
        "queryBy": "name,about,current_location,preferred_locations,current_designation,education_details,key_skills,other_skills,phone_number,e_mail",
        // "sortBy": "experience:desc",
        "tabId": tabVal,
        "offset": pageDetails?.page,
        "useCache": true,
        "cacheTime":60
    }
    const parseNumber = parseInt(requestBody.searchKey) 
    if(tabVal == 0){
        queryString["searchQuery"] = tabVal == 0 ? requestBody.searchKey : "*",
        queryString["filterBy"] = (!Number.isNaN(parseNumber) && parseNumber <= 50 && tabVal == 0) ? (queryParams.concat([`experience:=${parseInt(requestBody.searchKey)}`]).join(" && ")) : parseNumber.toString().length == 10 ? (queryParams.concat([`phone_number:=${parseInt(requestBody.searchKey)}`]).join(" && ")) : (queryParams.join(" && "))
    }
    if(tabVal == 1){
        queryString["searchQuery"] = "*",
        queryString["mandateId"] = requestBody?.mandateId;
        queryString["mlocationId"] = requestBody?.mlocationId;
        queryString["tagId"] = requestBody?.tags;
        queryString["filterBy"] = queryParams.join(" && ")
    }
    if(tabVal == 2){
        queryString["searchQuery"] = "*",
        queryString["mandateId"] = requestBody?.mandateId;
        queryString["mlocationId"] = requestBody?.mlocationId;
        queryString["rpaId"] = requestBody?.searchQueryId;
        queryString["filterBy"] = queryParams.join(" && ")
    }
    const response = await instance.post(`candidate/search`, queryString)
    return response;
} 
export const initiateRpa = async (candidateItems:any[]) => {
    const response = await scrapperInstance.post(`deep_drive_scraper/?initiate=true`, candidateItems);
    return response;
} 

export const tagListByMandateId = async (mandateId:any,locationId?:any) => {
    try{
        if(mandateId){
            const response = await instance.get(`candidate/tag/mandate/?mandateId=${mandateId}&mlocationId=${locationId}`)
            return response.status ==200 ? response.data : []
        }
        return null
    }catch(err){
        return null;
    }
}

export const candidateListByTags = async (tags:any)=>{
    try{
        const response = await instance.post(`candidate/tags/`, tags)
        return response.data;
    }catch(err){
        return null
    }

}

export const listNotes = async (requestData:any)=>{
    try{
        const response= await instance.post(`candidate/notes/list`, requestData)
        if(response.status==200){
            return response.data
        }
        else{
            return []
        }
    }catch(err){
        console.log(err)
        return []
    }
}

export const getMandateForNotes = async(uniqueId:string) =>{
    try{
        const response = await instance.get('candidate/notes/mandates?uniqueId='+uniqueId)
        if(response.status==200){
            return response.data
        }
        else{
            return []
        }
    }catch(err){
        console.log(err)
        return []
    }
}

export const GetAgentDetails = async () => {
    const response = await instance.get(`notification/smartflo/agents`);
    return response;

} 

export const GetCallerDetails = async () => {
    const response = await instance.post(`notification/smartflo/Smartflo_call_id`);
    return response;

} 

export const smartflowClicktoCall = async (obj:any) => {
    const response = await instance.post(`notification/smartflo/call`, obj);
    return response;

} 

export const smartflowCallhangup = async (callId:any,recruiterNumber:number) => {
    const response = await instance.post(`notification/smartflo/hangup?callId=${callId}&agentNumber=${recruiterNumber}`);
    return response;

} 

export const smartflowCallLog = async (callId:any, directionBound:boolean) => {
    const response = await instance.get(`/notification/smartflo/records/candidate?candidateNumber=${callId}&direction=${directionBound ? "Inbound": "Outbound"}`);
    return response;

}

export const getCandidateAssignedMandates = async (candidateId:number) => {
    const response = await instance.get(`mandate/getCandidateMandate/${candidateId}`);
    return response;

}

export const getExportToExcel = async (requestBody: any, queryParams: any) => {
    const queryString = objectToQueryString({
        page: _.get(queryParams, 'page'),
        size: _.get(queryParams, 'size'),
        profileType: _.get(queryParams, 'profileType'),
        tabType: null
    });
    const response = await instance.post(`candidate/export-to-excel/?${queryString}`, requestBody, {responseType: 'blob'});
    return response;
}


export const getCandidateKanbanData = async (requestBody: any, queryParams: any) => {
    const queryString = objectToQueryString({
        profileType: _.get(queryParams, 'profileType'),
    });
    const response = await instance.post(`candidate/profile-board/by-stages/?${queryString}&tabType=mandate_stage`, requestBody);
    return response;
}


export const getPowerBiToken = async() =>{
    try{
        const response = await instance.get('candidate/powerbi/details')
        if(response.status==200){
            return response.data
        }
        else{
            return []
        }
    }catch(err){
        console.log(err)
        return []
    }
}

export const getUploadedResumeData = async (userObj:any) => { 
    const response = await forminstance.post(`/candidate/resume_upload`,userObj);
    return response;
}

export const postCandidateData = async (userObj:any) => { 
    const response = await forminstance.post(`/candidate`,userObj);
    return response;
}
export const putCandidateData = async (userObj:any) => { 
    const response = await forminstance.put(`/candidate`,userObj);
    return response;
}

