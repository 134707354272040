import React, {useEffect, useRef, useState } from 'react'
import { Paper, Box, Typography, Grid, Stack, Button, Checkbox, FormControl, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar, Chip, InputLabel, Select, MenuItem  } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { AppButton } from '../../../AppComponents/AppButton'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../../utils/constants'
// import { ReportSearch } from '../Search/Search'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/arrow-up.svg'
import { ReactComponent as SortIcon } from '../../../../assets/icons/sort-arrow.svg'
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/input-calendar.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs';
import { AppAutoComplete } from '../../../AppComponents/AppAutoComplete'
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { ReactComponent as RotateIcon } from '../../../../assets/icons/reset.svg'
import Popover from '@mui/material/Popover';
import { MandateService, dropDownData, dropDownLocation, getAllListMandates, getDepartmentRoles, getIndustryData, getMandateLocation, getMandateStatusDropdown, getbranchlocation, getprimaryskill } from '../../../../services/MandateService'
import _ from 'lodash'
import { ReportkManagementService } from '../../../../services/ReportListService'
import DepartmentDropDown from '../../../Mandates/AddMandate/DepartmentDropDown'
import { useAppDispatch,useAppSelector } from "../../../../store/hooks";
import { convertToDisplayDate } from '../../../../utils/date-utils'
import moment from 'moment'
import IndustryDropdown from '../../../Mandates/AddMandate/IndustryDropdown'
import MuiAlert from '@mui/material/Alert';
import { TaskManagementService } from '../../../../services/TaskService'
import { reportFilterLoadData } from '../../../../features/filters/FilterReducer'
import { Breadcrumb } from '../../../Breadcrumb'
import { ReactComponent as WidgetIcon } from '../../../../assets/icons/Widget-icon.svg'
import { DateRangePicker, DateRange } from '@mui/lab';
import '../../../../components/Mandates/AddMandate/DepartmentDropDown.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { styled } from '@mui/system';
import FilterComponent from '../../CustomeDasboardFilter/DashboardFilter/Filters/Filter'
import { useLocation, useNavigate } from 'react-router-dom'
import { dashoardClient, MandateSPOCapi } from '../../../../services/DashboardService'
import { getBussinessUnit, getSystemConfig } from '../../../../services/UserService'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'


// const DrillDownContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   backgroundColor: '#f4f9ff',
//   borderRadius: '8px',
//   padding: '8px 16px',
//   flexWrap:'wrap',
//   width:'100%',
// }));

const DrillDownContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: '#f4f9ff',
  borderRadius: '8px',
  padding: '8px 16px',
  alignContent:'center',
  position: 'relative',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '8px',
    height: '100%',
    backgroundColor: '#0033a0',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
}));

const Divider = styled('div')(({ theme }) => ({
  width: '4px',
  height: '100%',
  backgroundColor: '#0033a0',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  marginRight: '8px',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '16px',
  border: '1px solid #0033a0',
  margin: '0 0px',
}));


const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f4f9ff',
  color: '#0033a0',
  border: '1px solid #0033a0',
  borderRadius: '10px',
  textTransform: 'none',
  padding: '7px',
  '&:hover': {
    backgroundColor: '#e0f3ff',
  },
}));


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CommentFilter = ({HirningDemandParamsDetails,setHirningDemandParamsDetails,setReportSubmissionAPIData,setApplyBtnActive,candidates}:any) => {
  

    const [reportData, setReportData] = useState<any>([])
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10)
    const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [tableKeyWordSearch, setTableKeyWordSearch] = useState("");
    const [tableHeadKeyWordSearch, setTableHeadKeyWordSearch] = useState("");
    const [mLocationList, setMLocationList] = useState([])
    const dispatch = useAppDispatch()
    const navigate =useNavigate()
    const [reportFilterPreload, setReportFilterPreload] = useState({})
    const location = useLocation()
    const candidaterouteState = location?.state
    const reportDataCandidateDetails = useAppSelector((state) => state.filtersReducer.reportFiler)
    const [resetFilter, setResetFilter] = useState(false)

    const [reportClientAPIList,setReportClientAPIList] = useState([])
    const [reportBussinessAPIList,setReportBussinessAPIList] = useState<any>([])
    

    const [Coelist,setCoelist] = useState<any>([]);
    const [ClientList, setClientList] = useState<any>([]);
    const [businessUnitList, setBusinessUnitList] = useState<any>([]);
    const [jobModeList, setjobModeList] = useState<any>([]);
    const [buheadList, setbuheadList] = useState<any>([]);
    const [industryList, setindustryList] = useState<any>([]);
    const [mandateSpocList, setMandateSpocList] = useState<any>([]);
    const [mandateTypeList, setMandateTypeList] = useState<any>([]);
    const [JobtitleList, setJobtitleList] = useState<any>([]);
    const [JobLocationList, setJobLocationList] = useState<any>([]);
    const [rolesdataList, setrolesdataList] = useState<any>([]);
    const [rolesList, setrolesList] = useState<any>([]);
    const [primaryskillList,setprimaryskillList] = useState<any>([]);
    
    const [industryData, setIndustryData] = useState<any>([])
    const [deptData, setDeptData] = useState<any>([])
    const userDetaiId = useAppSelector((state) => state.UserDetail.userId)



    console.log("HirningDemandParamsDetails : ",HirningDemandParamsDetails)

    const [dashboradfilterList,setdashboradfilterList] = useState<any>(HirningDemandParamsDetails?.state?.filterListLabels)
    const [filterChecked, setfilterChecked] = useState<boolean>(false)
    const [columnFilter, setColumnFilter] = useState<boolean>(false)
    const [keyWordSearch, setKeyWordSearch] = useState("")
    let userRoleState = useAppSelector((state:any) => state?.UserDetail?.userRole);
    const userStateId = useAppSelector((state) => state.UserDetail.userId)
    userRoleState = userRoleState ? userRoleState.replace("/","").replace(/ /g,"").trim() : "";
    const [filterTabCloseStatus, setfilterTabCloseStatus] = useState<string>()
    
    const [isOpendrilldownfilter, setIsOpendrilldownfilter] = useState(false);
    
      function prepend(value:any, array:any) {
        const newArray = array.slice();
        newArray.unshift(value);
        return newArray;
      }


      const bussinessUnitHierarchy = async () => {
        const response = await ReportkManagementService.getBussinessHierarchy()
        console.log("dependancy data :",response?.data)
        setReportBussinessAPIList(response?.data)
      }
      const getClientList = async () => {
        await MandateService.getClientname()
        .then((res: any) => {
          let options = _.map(res.data.result.list[0], (data: any) => {
            return {
              label: _.get(data, 'clientName'),
              value: _.get(data, 'id'),
            }
          })
          
          options = prepend({label: "All",value: "All"}, options)
          setReportClientAPIList(options)
        })
        .catch((error: any) => {
          console.log(error.message)
        })  
      }


      const fetchbranchLocations = async () => {
        await getbranchlocation()
        .then((res:any)=>{
            const options = _.map(res.data, (data: any) => {
                return {
                  label: _.get(data, 'branchName'),
                  value: _.get(data, 'id'),
                }
              })
              // options = prepend({label: "All",value: "All"}, options)
              setCoelist(options);
              console.log("My COE : ",options)          
        })
        .catch((error: any)=>{
            console.log(error.message)
        })
      }


    

      

  const getClientDataApi = async () => {
    try {
        const response = await dashoardClient();
        if (response?.data?.result && response?.data?.result.list.length > 0) {
            const clients = response.data.result.list[0].map((client: any) => client.clientName);
            console.log("clients",clients)
            setClientList(clients);
        }
    } catch (error: any) {
        console.log(error);
    }
};

const getBussinessUnitDetailsApi = async () => {
    try {
        // const res: any = await getBussinessUnit();
        // const businessUnits = res?.data?.map((item: any) => item?.businessUnitName);
        // setBusinessUnitList(businessUnits);

        await getBussinessUnit()
        .then((res: any) => {
            const Bussnussuinitoptions = _.map(res?.data, (data: any) => {
              return {
                label: _.get(data, 'businessUnitName'),
                value: _.get(data, 'id'),
              }
            })
            
            // Bussnussuinitoptions = prepend({label: "All",value: "All"}, Bussnussuinitoptions)
            setBusinessUnitList(Bussnussuinitoptions)
        })
        
    } catch (error: any) {
        console.log(error);
    }
};


const getprimaryskillDetailsApi = async () => {

    const reqObj = {
        "keyword": null,
        "pageNumber": 1,
        "pageSize" : Number.MAX_SAFE_INTEGER
    } 

    try {
      await getprimaryskill(reqObj)
        .then((res: any) => {
            const primaryskilloptions = _.map(res?.data, (data: any) => {
              return {
                label: data,
                value: data,
              }
            })
            
            // primaryskilloptions = prepend({label: "All",value: "All"}, primaryskilloptions)
            setprimaryskillList(primaryskilloptions);
        })
      

    } catch (error) {
      console.log(error);
    }
  };
  



const getJobtitleListApi = async () => {
    try {
        await getAllListMandates().then((res: any) => {
            const dataItems = res?.data?.result?.list || []
            if (dataItems) {
                const filterData = dataItems.filter((item: any) => item?.status !== 'draft')
                
                // Use a Set to track unique job titles
                const uniqueJobTitles = new Set();
                const options :any = [];

                filterData.forEach((data: any) => {
                    const jobTitle = _.get(data, 'jobtitle');
                    const mandateCode = _.get(data, 'mandatecode');

                    // Check if the job title is already in the set
                    if (!uniqueJobTitles.has(jobTitle)) {
                        uniqueJobTitles.add(jobTitle);
                        options.push({
                            label: jobTitle,
                            value: mandateCode,
                        });
                    }
                });
                
                setJobtitleList(options);
            } else {
                setJobtitleList([]);
            }
        });
    } catch (error: any) {
        console.log(error);
    }
};


const fetchLocations = async () => {
    const reqObj = {
      "filters": [
          {
              "field": "parentId",
              "operator": "equals",
              "type": "string",
              "value": "0"
          }
      ],
      "page": {
          "pageNo": -1,
          "pageSize": 10
      },
      "sorting": {
          "direction": "ASC",
          "field": "createdAt"
      }
  }
    try{
      const res:any = await dropDownLocation(reqObj)
      const options = _.map(res.data.result.list, (data: any) => {
        return {
          label: _.get(data, 'locationName'),
          value: _.get(data, 'id')
        }})
        setJobLocationList(options);

    } catch {
        setJobLocationList([]);
    }
  }


  const getindustrylistApi = async () => {
    const reqObj = {
      "filters": [],
      "page": {
        "pageNo": 0,
        "pageSize": 10
      },
      "sorting": {
        "direction": "ASC",
        "field": "createdAt"
      }
    }
    try {
      await getIndustryData(reqObj)
      .then((res: any) => {
        let industrrtlistoptions = _.map(res?.data?.result?.list, (data: any) => {
          return {
            label: _.get(data, 'industryCategoryName'),
            value: _.get(data, 'id'),
          }
        })
        industrrtlistoptions = prepend({label: "All",value: "All"}, industrrtlistoptions)
        setindustryList(industrrtlistoptions)
    });

    //   setindustryList(industRes.data.result.list);

      await getDepartmentRoles(reqObj)
      .then((res: any) => {
        console.log("job option :",res?.data?.result?.list)
        let rolelistoptions = _.map(res?.data?.result?.list?.map((departmentRoles:any)=>departmentRoles), (data: any) => {
          return {
            label: _.get(data, 'depName'),
            value: _.get(data, 'id'),
          }
        })
        rolelistoptions = prepend({label: "All",value: "All"}, rolelistoptions)
        // setrolesdataList(rolelistoptions)
    })

    //   setDeptData(departRes.data.result.list);
    } catch {
      setIndustryData([]);  
    }
  }





const getsomedropdownlist = async () => {
    const reqObj = {
        businessUnit: false,
        appConfiguration: false,
        dropdownRequest: [
            { drpCode: "XPN0001", drpName: "MANDATE_TYPE" },
            { drpCode: "XPN0002", drpName: "SHIFT_TYPE" },
            { drpCode: "XPN0003", drpName: "Job_Type" },
            { drpCode: "XPN0004", drpName: "Job_Mode" },
            { drpCode: "XPN0005", drpName: "SPOC_Type" },
            { drpCode: "XPN0006", drpName: "Permissions" }
        ]
    };
    await dropDownData(reqObj)
    .then((res: any) => {
      const Jobmodeoptions = _.map(res?.data?.result?.dropdownData?.Job_Mode, (data: any) => {
        return {
          label: _.get(data, 'domValue'),
          value: _.get(data, 'id'),
        }
      })
      
      // Jobmodeoptions = prepend({label: "All",value: "All"}, Jobmodeoptions)
      setjobModeList(Jobmodeoptions)


      const Mandatetypeoptions = _.map(res?.data?.result?.dropdownData?.MANDATE_TYPE, (data: any) => {
        return {
          label: _.get(data, 'domValue'),
          value: _.get(data, 'id'),
        }
      })

      // Mandatetypeoptions = prepend({label: "All",value: "All"}, Mandatetypeoptions)
      setMandateTypeList(Mandatetypeoptions)


      const Mandatespocoptions = _.map(res?.data?.result?.dropdownData?.SPOC_Type, (data: any) => {
        return {
          label: _.get(data, 'domValue'),
          value: _.get(data, 'id'),
        }
      })

      // Mandatespocoptions = prepend({label: "All",value: "All"}, Mandatespocoptions)
      // setMandateSpocList(Mandatespocoptions)


    })
    .catch((error: any) => {
      console.log(error.message)
    })  
};


const mandatespocapicall = async () =>{
  const reqObj = {
    "filter":null,
    "pageNumber":1,
    "pageSize":Number.MAX_SAFE_INTEGER,
    "ClientId":null
}

  try {
    const res = await MandateSPOCapi(reqObj);
    console.log("mandateiiiing",res)

    const Mandatespocoptions = _.map(res?.data, (data: any,index:number) => {
      return {
        label: data,
        value: index,
      }
    })

    // Mandatespocoptions = prepend({label: "All",value: "All"}, Mandatespocoptions)
    setMandateSpocList(Mandatespocoptions)
  } catch (error) {
    console.log(error, "config api error");
  }
}

const getAppConfig = async () => {
    const reqObj = {
      businessUnit: true,
      appConfiguration: true,
      dropdownRequest: []
    }
  
    try {
      const res = await getSystemConfig(reqObj);
      
      if (res.status === 200) {
        // Transform the response data to extract roleName and id
        const rolelistoptions = res?.data?.result?.roles?.map((item: any,index:number) => {
          console.log("roleistdataList", item?.roleName);
          return {
            label: item?.roleName, // Extract roleName for the label
            value: item?.id,        // Extract id for the value
          }
        });
  
        console.log("roleistdata", rolelistoptions);
        
        setrolesdataList(rolelistoptions);
  
        // If you also need a separate list of just role names
        const roles = res?.data?.result?.roles?.map((item: any) => item?.label?.roleName);
        setrolesList(roles);
      }
    } catch (error) {
      console.log(error, "config api error");
    }
  }

  useEffect(()=>{
    getClientDataApi()
    getBussinessUnitDetailsApi()
    bussinessUnitHierarchy()
    getsomedropdownlist()
    getAppConfig()
    getJobtitleListApi()
    fetchLocations()
    getindustrylistApi()
    fetchbranchLocations()
    getprimaryskillDetailsApi()
    mandatespocapicall()
  },[])



      const fetchData = async () => {
        const reqObj = {
          "filters": [],
          "page": {
            "pageNo": 0,
            "pageSize": 10
          },
          "sorting": {
            "direction": "ASC",
            "field": "createdAt"
          }
        }
        try {
          const industRes: any = await getIndustryData(reqObj)  
          setIndustryData(industRes.data.result.list);
          const departRes:any = await getDepartmentRoles(reqObj)
          setDeptData(departRes.data.result.list);
        } catch {
          setIndustryData([]);  
        }
      }
  
  
      useEffect(() => {
        getClientList()
        bussinessUnitHierarchy()
        // fetchData()
      },[])

      const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }

    const [values, setValues] = React.useState({
        textmask: '',
        numberformat: '1320',
      });


    // const reportCandidateName = (candidateId:string) => {
    //     dispatch(reportFilterLoadData({reportFiler: {...reportDataCandidateDetails, ...{reportList: reportData,pageCount: pageCount,defaultPageCount: defaultPageCount, pageSize: pageSize}}}))
    //     navigate(`/candidate/${candidateId}`, {state:"submissionReportCandidate"})
    // }

    // useEffect(() => {
    //     if(candidaterouteState == "submissionReportCandidate"){
    //         navigate(location.pathname, {});
    //         setReportData(reportDataCandidateDetails?.reportList)
    //         setReportFilterPreload(reportDataCandidateDetails)
    //         setPageCount(reportDataCandidateDetails?.pageCount);
    //         setPageSize(reportDataCandidateDetails?.pageSize)
    //         setDefaultPageCount(reportDataCandidateDetails?.defaultPageCount)
    //     }else{
    //         // dispatch(reportFilterLoadData({reportFiler: {}}))
    //     }
    // },[candidaterouteState])

    // useEffect(() => {
    //     if(resetFilter){
    //         setPageCount(0);
    //         setPageSize(10)
    //         setDefaultPageCount(1)
    //         setResetFilter(false)
    //     }       
    // },[resetFilter])

    


  

  const handleFilter = () => {
    setIsOpendrilldownfilter(false)
    setfilterChecked((prev) => !prev)
  }

  const handleClick = () => {
    setfilterChecked(false)
    setIsOpendrilldownfilter(!isOpendrilldownfilter);
  };

  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
  const [selectAllColumnsState, setSelectAllColumnsState] = useState<any>(false)  


  const userbasedReportFilterCase:any = {
    Admin:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],  
    Management:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],
    BUHead:["Delivery/Account Manager","Team Lead","Recruiter"],
    DeliveryAccountManager: ["Team Lead","Recruiter"],
    TeamLead:["Recruiter"],
    Recruiter:[]
  }
  const bussiUnitKeyRefer:any = {
    "BUHead": "bUHead",
    "Delivery/Account Manager": "deliveryManager",
    "Team Lead": "teamLead",
    "Recruiter":"recruiter"
  } 
  const [bussinessUnitOveallObj, setBussinessUnitOveallObj] = useState<any>({})
  const [bussinessUnitOveallObjInitial, setBussinessUnitOveallObjInitial] = useState<any>({})
  const[bussinessUnitObjData, setBussinessUnitObjData] = useState<any>({})
  
  const currentRoleOrder = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
  const [currentRoleSelected, setCurrentRoleSelected] = useState(userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] ? userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState][0]: "")
  const [heirarchyRoleList, setHeirarchyRoleList] = useState<any>()
  const [userIdSelected, setUserIdSelected] = useState<any>(userId)    
  const [currentRoleStr, setCurrentRoleStr] = useState<any>([])

  const getBussinessUnitObj = () => {
    if(Object.keys(reportBussinessAPIList).length > 0){
      const bussObjArray:any = {};
        const parentRole = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
        const disableRoleObj:any = {}
       
        // const roleBaseObj = reportBussinessAPIList[parentRole[0] == "Recruiter" ? "recuriterDropDown" : parentRole[0] == "Team Lead" ? "teamLeadDropDown": parentRole[0] == "Delivery/Account Manager" ? "accountManagerDropDown" : parentRole[0] == "BUHead" ? "buHeadDropDown": ""]
        // bussObjArray[parentRole[0]] = roleBaseObj
        parentRole.map((item:any, index:number) => {
          let userRoleOption = _.map(reportBussinessAPIList[item == "Recruiter" ? "recuriterDropDown" : item == "Team Lead" ? "teamLeadDropDown": item == "Delivery/Account Manager" ? "accountManagerDropDown" : item == "BUHead" ? "buHeadDropDown": ""], (data: any,index:any) => {
            return {
              label: _.get(data, 'firstName'),
              value: _.get(data, 'id'),
              index: index + 1,
              reporting: _.get(data, 'reporting')
            }
          })
          userRoleOption = prepend({label: "All",value: "All", index: 0}, userRoleOption)
          disableRoleObj[item] = {
            disabled : index > 0 ? true : false,
            options: userRoleOption,
            selectedValue: 0
          }
          })
          setBussinessUnitOveallObj(disableRoleObj)
          if(Object.keys(bussinessUnitOveallObjInitial).length == 0)setBussinessUnitOveallObjInitial(JSON.parse(JSON.stringify(disableRoleObj)))
          setBussinessUnitObjData(bussObjArray)
    }    
  }

  const getUserBaseData = (userRole:string) => {
    let userRoleOption = []
    userRoleOption = _.map(bussinessUnitObjData[userRole], (data: any) => {
      return {
        label: _.get(data, 'firstName'),
        value: _.get(data, 'id'),
      }
    })
    return prepend({
      label: "All",
      value: "All",
    }, userRoleOption)
  }

  useEffect(() => {    
    getBussinessUnitObj()
  },[reportBussinessAPIList])
  
  const statusdList = [
    {
      label: "All",
      value : "All"
    },
    {
      label: "Active",
      value : "1"
    },
    {
      label: "Inactive",
      value : "2"
    },
    {
      label: "Re Joined",
      value : "137"
    },
    {
      label: "De Activated",
      value : "136"
    }
] 

const StageList = [
  {
    label: "All",
    value : "All"
  },
  {
    label: "CV Submission",
    value : "1"
  },
  {
    label: "Selection Stage",
    value : "2"
  },
  {
    label: "Interview Stage",
    value : "137"
  },
  {
    label: "Joining Stage",
    value : "136"
  }
] 

  const { handleSubmit, control, reset, watch, getValues,setValue } = useForm({})

  const [preloadDataObj , setPreloadDataObj] = useState<any>()
  const [submissionStartDate, setSubmissionStartDate] = React.useState<Dayjs | null>(null);
  const [submissionEndDate, setSubmissionEndDate] = React.useState<Dayjs | null>(null);

  const [mandateLocationPreloadLists, setMandateLocationPreloadLists] = useState([])

  const getMandateLocationPreloadList = async (manId:number) => {
    setMandateLocationPreloadLists([])
    // const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : "" 
    const apiQuery = "" 
    const response = await getMandateLocation(manId, apiQuery)
    if(response?.status === 200){
      const locationOptions =_.map(response?.data?.result?.mandateLocations, (data: any) => {
        return {
          label: _.get(data, 'location'),
          value: _.get(data, 'id'),
        }
      })
      setMandateLocationPreloadLists(locationOptions)
      setValue("mandateLocation", preloadDataObj?.mandateLocation)
    }
  }


  const keyToLabel: { [key: string]: string } = {
    client: "Client",
    jobTitle: "Job Title",
    primarySkills: "Primary Skills",
    mandateType: "Mandate Type",
    jobMode: "Job Mode",
    mandateSPOC: "Mandate SPOC",
    joLocation: "Job Location",
    businessUnit: "Business Unit",
    BUHeadUserID: "BU Head User ID",
    RecruiterUserID: "Recruiter User ID",
    customStartDate: "Date",
    customEndDate: "Date",
    industry: "Industry",
    roles: "Roles"
  };


    return (
        <>
        <Box className='HiringDemadUiCustome'>
          <Box sx={{marginBottom:'5px'}}>
            <div>
            <Paper className='box-style' elevation={1} sx={{padding:'10px'}}>
            <Box display='flex' justifyContent='space-between'>
              <Box className='tasklist' >
              <Breadcrumb title={`Hiring Demand - Clients Active Profile (${HirningDemandParamsDetails?.state?.data?.count ? HirningDemandParamsDetails?.state?.data?.count : 12 })`} />
                <Typography display='flex' gap={'5px'} className='tasklist-title' sx={{marginTop:'-15px', marginBottom:'10px',cursor:'pointer'}}>
                    <span>{`Overview Dashboard`}</span><span style={{color:'#0033a0'}}>{`> Hiring Demand - Clients Active Profiles`}</span>
                </Typography>
                <Box className="hiringInfoSection">
                  <ul>
                    <li><span className='circle'></span>Mandate <span className='number' style={{color: "#2250d3"}}>{candidates?.mandateCount}</span></li>
                    <li><span className='circle'></span>Candidate <span className='number' style={{color: "#538409"}}>{candidates?.candidateCount}</span></li>
                    <li><span className='circle'></span>Client <span className='number' style={{color: "#e17413"}}>{candidates?.clientCount}</span></li>
                    <li><span className='circle'></span>Job Order <span className='number' style={{color: "#740805"}}>{candidates?.jobOrderCount}</span></li>
                  </ul>
                </Box>
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',alignContent:'center',columnGap:'8px'}}>
                {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                
                <Box>
                <StyledButton onClick={handleClick} endIcon={isOpendrilldownfilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
                  Drill Down From
                </StyledButton>
                </Box>
             
                  <AppButton
                    variant='contained'
                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                    margin={5}
                    minwidth={40}
                    onClick={handleFilter}
                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                  >
                    {' '}
                    <FunnelIcon className='add-new-icon' />{' '}
                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </AppButton>
                  <div style={{display:'flex',alignItems:'center'}}><MoreVertIcon/></div>
              </Box>
            </Box>


                    {filterChecked ?
                        <Box sx={{border:'1px solid #E5E8EB',borderRadius:'10px',marginBottom:'5px'}}>
                        <FilterComponent HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} Coelist={Coelist} reportClientAPIList={reportClientAPIList} reportBussinessAPIList={reportBussinessAPIList} jobModeList={jobModeList} mandateSpocList={mandateSpocList} mandateTypeList={mandateTypeList} businessUnitList={businessUnitList} JobtitleList={JobtitleList} JobLocationList={JobLocationList} industryList={industryList} rolesdataList={rolesdataList} primaryskillList={primaryskillList} setReportSubmissionAPIData={setReportSubmissionAPIData} setResetFilter={setResetFilter} hideFields={[]} hideDashHeader={true} setApplyBtnActive={setApplyBtnActive} candidates={candidates}/>
                      </Box> : isOpendrilldownfilter ?
                      <DrillDownContainer>
                        <Divider />
                        <div style={{width:'15%',display:'flex',alignItems:'flex-start'}}>
                        <Typography variant="body1" fontWeight="bold" mr={1}>
                          Drill Down from
                        </Typography>
                        </div>

                      <div style={{width:'85%',display:'flex',justifyContent:'flex-start',gap:'10px',flexWrap:'wrap'}}>
                      {Object.keys(dashboradfilterList).map((key,index) => {
                        let displayValue;

                        // Handle date range for customStartDate and customEndDate
                        if (key === 'customStartDate' || key === 'customEndDate') {
                          if (key === 'customStartDate') {
                            const startDate = dayjs(dashboradfilterList['customStartDate']).format('DD-MMM-YYYY');
                            const endDate = dayjs(dashboradfilterList['customEndDate']).format('DD-MMM-YYYY');
                            displayValue = `${startDate} to ${endDate}`;
                          } else {
                            return null; // Skip customEndDate
                          }
                        } else {
                          displayValue = Array.isArray(dashboradfilterList[key]) 
                            ? dashboradfilterList[key].filter((item: string) => item.length > 0).join(', ') 
                            : dashboradfilterList[key];
                        }

                        // Skip rendering if displayValue is empty or has no length
                        if (!displayValue || displayValue.length === 0) {
                          return null;
                        }
                        return(
                            <div key={index} style={{display:'flex',flex:'wrap',alignContent:'center',alignItems:'center'}}>
                                <Typography variant="body2" color="textSecondary" mr={1}>
                                    {keyToLabel[key] || key}
                                      </Typography>
                                <StyledChip label={`${displayValue}`} />
                              </div>
                            )})}
                    </div>

                    </DrillDownContainer>:null}


                </Paper>
            </div>
        </Box>
        </Box>
          
        </>
    )
}
export default CommentFilter
