import React, { useEffect, useState } from 'react'
import { Popover, Box, Typography,Button } from "@mui/material";
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash-icon.svg'
import "./KanbanView.scss"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getMandateStagesbyOrder } from '../../../../services/MandateService';
const ActionPopup = ({
   anchorEl,
   setAnchorEl,
   id,
   moveAction,
   card,
   setDefaultRollback,
   setPopActive,
   posId,
   mandateData

  }:any) => {
  const [anchor, setAnchor] = useState<any>(null)
  const [menuPosition,setMenuPosition] =useState([])

const handleRollback =(elem:any)=>{
  if(elem.stageid < card.stageId){
    setDefaultRollback(true)
  }
  else {
    setDefaultRollback(false)
 
  }
}

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, tag: string) => {
    setAnchor(event.currentTarget)
  }
  useEffect(() => {
    fetchMenuList()
  }, [anchorEl]);

  const fetchMenuList =async()=>{
    const manId = mandateData?.id
    // const posId = positionLists[positionIndex]?.id
    const stageid = card?.stageId
    const stageorder = card?.stageOrder
    const isend = card?.isEnd

    if(manId != undefined && posId != undefined && stageid != undefined && stageorder != undefined && isend != undefined){
      try {
        const res = await getMandateStagesbyOrder(manId, posId,id, stageid, stageorder, isend)
        if (res?.status === 200) {
          const listData = res?.data?.result?.data ?? []
          setMenuPosition(listData)
        }
      } catch (error) {
        console.log(error)
      }
    }
   
  }


  useEffect(() => {
    const handleScroll = () => {
      if (anchorEl) {
        setAnchorEl(null)
        setAnchor(null)
      }
    };
   

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  }, [anchorEl]);
  return (
    <>
    <Popover
    open={anchorEl ? true : false}
    anchorEl={anchorEl}
    className="popup-wrapper"
    onClose={() => {  
      setPopActive(false)
      setAnchorEl(null) }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <Box
      className="more-options"
    >
      <Button disabled={true} style={{ opacity: '0.5' }} className="hide"  >
        <VisibilityOff fontSize="small" className="icon"/>
        <span >
           Hide
        </span>
      </Button>
      <Button 
       onClick={(e: any) => handleClick(e, "sort")} 
        className="move"
         >
          <span className='move-container' >
      <DriveFileMoveIcon className="icon"/>
        {/* <span className='right-arrow-container'> */}
          <span>
          Move
          </span>
          </span>
     
        <KeyboardArrowRightIcon className="icon"/>
        {/* </span> */}
        

      </Button>
      <Button disabled={true} style={{ opacity: '0.5' }} className="delete" >
        <TrashIcon className="icon"/>
        <span>
        delete
        </span>
      </Button>
    </Box>
  </Popover>
  {anchorEl?  <Popover
  
  id={anchor ? id : undefined}
    open={anchor ? true : false}
    anchorEl={anchor}
    className="popup-Hover"
    onClose={() => { setAnchor(null) }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}>
      <div className='hover-items '>
        {menuPosition?.map((elem:any, index:number)=>{
          return(
            <Button 
              key={index}
              onClick={()=>{
                handleRollback(elem)
                moveAction(elem);
                setAnchorEl(null)
                setAnchor(null)
              }} 
              className='hover-btn'
            >
             {elem?.stagename}
            </Button>)
        })}
    
  

      </div>
 

  </Popover>:null}

  

  </>

  )
}

export default ActionPopup
