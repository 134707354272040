import { TextField, Box, Typography, InputAdornment, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { ReactComponent as NoResultIcon } from '../../../assets/icons/no-result-found.svg'
import './DayView.scss'
import RecruiterContainer from '../RecruiterCard/RecruiterContainer'
import { getAllocatedMandateCount } from '../../../services/WorkManagementService'
import _ from 'lodash'

type TDayProp = {
  selectedDate: any;
  viewType: string;
  recruiterContent: any;
  getAllocatedMandate: () => void;
  isMyTeam: boolean;
  setIsMyTeam: (val:any) => any;
  recruiterReqObj: any;
  setSearchFilterValue?:any;
  searchFilterValue?:string
}

const DayView:React.FC<TDayProp> = ({ selectedDate, viewType, recruiterContent, getAllocatedMandate, isMyTeam, setIsMyTeam, recruiterReqObj, setSearchFilterValue,searchFilterValue }) => {
  const [filterText, setFilterText] = useState<string>("")
  const dateText = selectedDate.format('MMM DD, YYYY')
  const [recruiterDataArr, setRecruiterDataArr] = useState<any[]>([])

  // const onInputChange = _.debounce((searchText: string)=>{
  //   // const arrData = recruiterContent?.filter((data:any) => data?.recruiterName?.toLowerCase()?.includes(searchText))
  //   // setRecruiterDataArr([...arrData])
    
  // }, 2000)

  useEffect(() => {
    setFilterText('')
    setRecruiterDataArr([...recruiterContent])
  }, [recruiterContent])

  return (
    <>
      <Box className={`day-view-container ${viewType}`}>
        <Box className="workManagStickyHead">
          <Box className='day-view-filter'>
            <TextField
              className='recruiter-search'
              placeholder='Search Recruiter...'
              value={searchFilterValue ? searchFilterValue : filterText}
              onChange={(e: any) =>{
                // onInputChange(e.target.value)
                setSearchFilterValue(e.target.value)
                setFilterText(e.target.value)                
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box className='selected-date'>
              <Typography className='date-label'>
                {viewType == 'D' && dateText}
                {viewType == 'CW' && 'Current Week'}
                {viewType == 'CM' && 'Current Month'}
              </Typography>
            </Box>
          </Box>
          <Box className='day-view-header'>
            <div className='my-team-container sticky-left'>
              <Switch checked={isMyTeam} onChange={(e:any) => setIsMyTeam(e.target.checked)} />
              <Typography className='my-team'>My Team</Typography>
            </div>
            <div className='day-view-box-container'>
              <Typography className='day-view-header-text'>Submissions</Typography>
            </div>

            <div className='day-view-box-container'>
              <Typography className='day-view-header-text'>CCM 1</Typography>
            </div>

            <div className='day-view-box-container'>
              <Typography className='day-view-header-text'>CCM 2</Typography>
            </div>

            <div className='day-view-box-container'>
              <Typography className='day-view-header-text'>Selects</Typography>
            </div>

            <div className='day-view-box-container'>
              <Typography className='day-view-header-text'>Joins</Typography>
            </div>
          </Box>
        </Box>
        <Box className='day-view-table-container'>
          <Box className='day-view-table'>
            
            {recruiterDataArr.length ? recruiterDataArr.map((eachRecruiter: any, index: number) => {
              return <RecruiterContainer viewType={viewType} recruiterData={eachRecruiter} key={index} getAllocatedMandate={getAllocatedMandate} recruiterReqObj={recruiterReqObj} recruiterDataArr={recruiterDataArr} />
            }) :
            <div className='noRecordsFound'><NoResultIcon />
            No Results Found.</div>
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DayView
