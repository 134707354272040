import { Avatar, Box, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import './ChatGPTDialog.scss'
import { aiChatMessage, getAIChatHistory } from '../../services/ChatGptService'
import { ReactComponent as ResetLogo } from '../../assets/icons/reset.svg'
import { ReactComponent as GptSendIcon } from '../../assets/icons/gpt-send.svg'
import { ReactComponent as BotLogo } from '../../assets/icons/chatbot-logo.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copyIcon.svg'
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg'
import _ from 'lodash'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { isEndPersist, minimize, quesPersist, reset } from '../../features/chatGpt/minimizeReducer';
import { setGptThreadId, resetGptThreadId } from '../../features/chatGpt/ChatGptReducer';
import LoadMoreIcon from "../../assets/images/loadMoreIcon.svg";
import moment from 'moment'
const ChatGPTDialog: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation()
  const gptRedux = useAppSelector((state) => state.chatGPT)
  const MinimizeRedux = useAppSelector((state) => state?.minimizeState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [chatData, setChatData] = useState<any[]>([])
  const [threadId, setThreadId] = useState<string>(gptRedux?.threadId ? gptRedux?.threadId : '')
  const [quesId, setQuesId] = useState<number>(MinimizeRedux?.questionId ? MinimizeRedux?.questionId : 0)
  const [textSearch, setTextSearch] = useState<string>('')
  const [isEndId, setIsEndId] = useState<number>(0)
  const [regeneratePayload, setRegeneratePayload] = useState<any>(gptRedux?.regenerate ? gptRedux?.regenerate : {})
  const [loadDay, setLoadDay] = useState(-7);
  const [loadOld, setLoadOld] = useState(true);
  const [loadNew, setLoadNew] = useState(false);
  const [minCount, setMinCount] = useState(-1);
  const [pageNo, setPageNo] = useState<number>(-1);
  const [totalPages, setTotalPages] = useState<number>(1)

  const getChatHistory = async (page: number, scrollDown: boolean) => {
    const screen = location?.pathname?.split('/')
    const reqObj = {
      filters: [
        {
          "field": "noOfDays",
          "operator": "equals",
          "type": "string",
          "value": 7
        },
        {
          "field": "moduleScreen",
          "operator": "equals",
          "type": "string",
          "value": MinimizeRedux?.previousModule != "" ? MinimizeRedux?.previousModule : screen[1]
        }
      ],
      page: {
        pageNo: page,
        pageSize: 10,
      },
      sorting: {
        direction: 'DESC',
        field: 'createdAt',
      },
    }
    try {
      const res = await getAIChatHistory(reqObj)
      if (res?.status === 200) {
        if (page != -1) {
          const resData = res?.data?.result?.data.map((ele: any) => {
            return {
              type: ele?.msgFrom === 'Bot' ? 'ChatBot' : 'userText',
              data: ele?.msgFrom === 'Bot' ? [ele] :{text:ele?.contentText,sendAt:ele?.sendAt} 
            }
          })
          setChatData(resData)
        }
        setTotalPages(res?.data?.result?.totalPages)
      }
    } catch (error) {
      console.log(error)
      chatAPIMessage()
    } finally {
      if (scrollDown) {
        const element: any = document.getElementById('chat-scroll')
        setTimeout(() => {
          element.scrollTo({
            top: element.scrollHeight,
            behavior: 'smooth',
          })
        }, 100)
      }
    }
  }

  const chatAPIMessage = async (
    searchText = '',
    userObj: any = null,
    qId = quesId,
    list: any = null,
  ) => {
    const screen = location?.pathname?.split('/')
    const reqObj: any = {
      questionId: qId ?? 0,
      moduleScreen: screen[1],
      isEnd: MinimizeRedux?.isEnd === true ? 1 : list?.isEnd ? list?.isEnd : isEndId ?? 0,
      message: searchText,
      threadId: threadId,
    }
    try {
      const res = await aiChatMessage(reqObj)
      if (reqObj?.isEnd === 1) {
        setRegeneratePayload(reqObj)
      } else {
        setRegeneratePayload({})
      }
      if (res?.status === 200) {
        const ansObj = {
          type: 'ChatBot',
          data: res?.data?.result?.data,
        }
        if (threadId === '') {
          setThreadId(res?.data?.result?.threadId)
        }
        if (res?.data?.result?.data[0]?.contentType === "question") {
          setQuesId(res?.data?.result?.data[0]?.id)
        }

        if (!MinimizeRedux.isEnd) {
          setIsEndId(res?.data?.result?.data[0]?.isEnd)
        }
        setTextSearch('')
        userObj ? setChatData([...chatData, userObj, ansObj]) : setChatData([...chatData, ansObj])
      }
    } catch (error) {
      console.log(error)
    } finally {
      const element: any = document.getElementById('chat-scroll')
      setTimeout(() => {
        if (element) {
          element.scrollTo({
            top: element.scrollHeight,
            behavior: 'smooth',
          })
        }
      }, 100)
    }
  }

  const regenerateChatCall = async () => {
    const reqObj = { ...regeneratePayload }
    try {
      const res = await aiChatMessage(reqObj)
      if (res?.status === 200) {
        const ansObj = {
          type: 'ChatBot',
          data: res?.data?.result?.data,
        }
        if (threadId === '') {
          setThreadId(res?.data?.result?.threadId)
        }
        setQuesId(res?.data?.result?.data[0]?.id)
        setIsEndId(res?.data?.result?.data[0]?.isEnd)
        setTextSearch('')
        setChatData([...chatData, ansObj])
      }
    } catch (error) {
      console.log(error)
    } finally {
      const element: any = document.getElementById('chat-scroll')
      setTimeout(() => {
        if (element) {
          element.scrollTo({
            top: element.scrollHeight,
            behavior: 'smooth',
          })
        }
      }, 100)
    }
  }

  const CopyToClipboard = async (containerid: any) => {
    const text: any = document.getElementById(containerid)?.textContent
    try {
      await navigator.clipboard.writeText(text)
      console.log('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  const getInitialApiCall = () => {
    if (MinimizeRedux?.questionId === 0) {
      chatAPIMessage()
    }
    else if (MinimizeRedux?.questionId != 0) {
      getChatHistory(MinimizeRedux?.pageNo != -1 ? MinimizeRedux?.pageNo : 0, true)
    }
  }

  const handleUserText = (searchText: any, qId: number, list: any) => {
    console.log(list,'list')
    const obj: any = {
      type: "userText",
      data:{text:searchText,sendAt:list?.sendAt} 
    }
    chatAPIMessage(searchText, obj, qId, list)
  }

  const renderAiContent = (data: any, indexID: number) => {
    return (
      <Box className='ai-chat-wrap'>
        <Avatar style={{ width: '30px', height: '30px' }}>
          <BotLogo style={{ width: '30px', height: '30px' }} />
        </Avatar>
        <Box className='chat-text-wrap'>
          {data?.map((list: any, index: number) => {
            const dataType = list?.contentType
            return (
              <div key={index} className='msg-cont'>
                {dataType === 'button' ? (
                  <>
                    <div
                      className='chat-btn'
                      onClick={() => {
                        setQuesId(list?.id)
                        handleUserText(list?.contentText, list?.id, list)
                      }}
                    >
                      {list?.contentText}
                    </div>
                  </>
                ) : (
                  <div className='answer-conatiner'>
                    <div className='date-text-cls'>{list?.sendAt}</div>
                    <div
                      className='chat-text'
                      id={`copiyable${indexID}`}
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {list?.contentText}
                    </div>
                    {list?.id === null && (
                      <div
                        className='copy-icon-container'
                        onClick={() => CopyToClipboard(`copiyable${indexID}`)}
                      >
                        <CopyIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </Box>
      </Box>
    )
  }
  const handleMinimize = () => {
    const obj = {
      threadId: threadId,
      regenerate: regeneratePayload
    }
    const minimizeObj = {
      threadId: threadId,
      quesId: quesId,
      isEndId: isEndId === 1 ? 1 : 0,
      pageNo: pageNo
    }
    dispatch(setGptThreadId(obj))
    dispatch(quesPersist(quesId))
    dispatch(minimize(minimizeObj))
    setTimeout(() => {
      if (MinimizeRedux?.previousModule != "") {
        navigate(`/${MinimizeRedux?.previousModule}`)
      }
      else {
        navigate('/dashboard');
      }
    }, 500)
  }
  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    const isAtTop = scrollTop === 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
    if (isAtTop) {
      // Scrolled near the top, load more older messages
      if (!loadOld && loadDay >= -7) {
        setLoadOld(true)
      }
    } else if (isAtBottom) {
      handleLoadNew()
      // Scrolled near the bottom, load more newer messages
    }

  };
  const handleLoadOld = () => {
    // setLoadOld(false);
    setLoadDay((prev) => prev + 1);
    setPageNo((prev) => prev + 1)
    getChatHistory(pageNo + 1, false)
  }
  const handleLoadNew = () => {
    setLoadNew(true);
    setLoadDay((prev) => prev === -7 ? -7 : prev - 1);
    if (pageNo >= 1) {
      setPageNo((prev) => prev - 1);
      getChatHistory(pageNo - 1, false)
    }
  }
  useEffect(() => {
    if (MinimizeRedux?.minimize === false) {
      getInitialApiCall()
    }
    if (MinimizeRedux.questionId) {
      setQuesId(MinimizeRedux.questionId)
    }
    if (MinimizeRedux?.isEndId === 1) {
      setIsEndId(1)
    }
  }, [MinimizeRedux])
  useEffect(() => {
    if (quesId === 0) {
      dispatch(isEndPersist(false))
    }
  }, [quesId])
  useEffect(() => {
    if (location?.pathname != "/dashboard" && location?.pathname != "/chatgpt" && location?.pathname != `/${MinimizeRedux?.previousModule}`) {
      reset()
      resetGptThreadId()
    }
  }, [location])
  useEffect(()=>{
  console.log(chatData,'chatData')
  },[chatData])
  return (
    <Box className='gpt-dialog-container'>
      <Box className='dialog-header'>
        <div className='icon-wrap'>
          <ArrowLeft onClick={() => handleMinimize()} />
        </div>
        <div className='header-text'>ChatGPT</div>
      </Box>
      <Box id='chat-scroll' className='dialog-chat-container' ref={containerRef} onScroll={handleScroll}>
        {
          loadOld && pageNo < (totalPages - 1) && <div onClick={handleLoadOld} className='load-text-cls'>
            <div className='font-cls'>Load More</div>
            <img src={LoadMoreIcon} />
          </div>
        }

        <Box className='chat-wrapper'>
          {chatData?.map((chat: any, index: number) => {
            return (
              <Box key={index} style={{ width: '100%' }}>
                {chat?.type === 'ChatBot' && renderAiContent(chat?.data, index)}
                {chat?.type === 'userText' && (
                  <div className='user-text-wrapper'>
                    <div className='chat-col-cls'>
                      <div className='date-text-cls'>{chat?.data?.sendAt}</div>
                      <div className='user-text'>{chat?.data?.text}</div>
                    </div>
                  </div>
                )}
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box className='footer-chat-container'>
        <Box
          className={`regenerate-container ${_.isEmpty(regeneratePayload) ? "cont-hide" : ""}`}
          onClick={regenerateChatCall}
        >
          <ResetLogo />
          <div className='regen-text'>Regenerate Response</div>
        </Box>
        <Box className='input-wrapper'>
          <TextField
            className='text-field'
            autoComplete='off'
            placeholder="Send a message."
            value={textSearch}
            onChange={(e: any) => setTextSearch(e.target.value)}
            focused={false}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => {
                      const obj: any = {
                        type: "userText",
                        data:{text:textSearch,sendAt:moment().format('YYYY-MM-DD HH:mm:ss')} 
                      }
                      chatAPIMessage(textSearch, obj)
                      setTextSearch('')
                    }}
                  >
                    <GptSendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && textSearch) {
                chatAPIMessage(textSearch, {
                  type: "userText",
                  data:{text:textSearch,sendAt:moment().format('YYYY-MM-DD HH:mm:ss')} 
                }
                )
              }
            }}
          />
        </Box>
        <div className='footer-hint'>
          Free Research Preview. ChatGPT may produce inaccurate information about people, places, or
          facts.<span style={{ textDecoration: 'underline' }}>ChatGPT May 24 Version</span>
        </div>
      </Box>
    </Box>
  )
}

export default ChatGPTDialog
