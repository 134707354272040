import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import ImageCropProvider from '../../components/Common/ProfileUpload/ImageCropProvider';
import ImageCrop from '../../components/Common/ProfileUpload/ImageCrop';
import { ReactComponent as HintsIcon } from '../../assets/icons/info-circle.svg'
import { getCountryCode } from '../../services/UserService';
import { emailRegex } from '../../utils/constants';
import NumberWithIncDec from '../../components/Common/NumberWithIncDec';
import "./CreateCandidate.scss"

const BasicCandidateDetails = ({disability,candidateGender,martialStatus}:any) => {
    const { control, formState: { errors },getValues,setValue,clearErrors,trigger } : any = useFormContext()
    const phoneNumberRegex = /^([+\d].*)?[\de]+$/gm    
    const [countryCode,setCountryCode] = useState([])
    const getCountryCodeApi = async () => {
        try{
          const response = await getCountryCode();
          setCountryCode(response?.data)
        }
          catch(error:any){
            console.log(error)        
          }
      }

      useEffect(() => {
        getCountryCodeApi()
      },[])

    return (
        <Grid className='container-wrapper'>
            <Box className="personalInfoWrapper">
                <Box className="personalInfoFieldWrapper">
                <Grid className='row-grid mb-3'>                
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <Controller
                    name="dateOfBirth"
                    control={control}
                    // rules={{
                    //     required: {
                    //         value: true,
                    //         message: "This field is required",
                    //     }
                    // }}
                    render={({field : {onChange, ...restField}})=>(
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',width:"100%"}}>
                        <DatePicker
                        label='Date of Birth'
                        className='datePicker'
                        inputFormat="DD/MM/YYYY"
                        onChange={(e:any)=>{onChange(e)}}
                        components={{
                            OpenPickerIcon: CalenderIcon,
                        }}
                        // minDate={moment().subtract(1,'years').toDate()}
                        // maxDate={moment().toDate()}
                        renderInput={(params:any) => (
                            <TextField
                                name='DOB'
                                style={{ width: "100%" }}
                                // required={true}
                                {...params}
                            />
                        )}
                        {...restField}
                    />
                    {errors?.dateOfBirth && (
                    <span className="reminder_text-danger select-danger-text" role="alert">
                        {errors?.dateOfBirth?.message}
                    </span>
                    )}
                    </div>
                    )}
                    />
                </LocalizationProvider>
                <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Controller
                        name={"gender"}
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Gender"
                        onChange={(e:any) => {
                            onChange(e.target.value)
                            setValue("role", e.target.value)
                            // if(getValues("bussinessUnit"))getReportingToApi(getValues("bussinessUnit"))
                        }}
                        >
                        {candidateGender.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {errors?.gender && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.gender?.message}
                        </span>
                    )}
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Physical Challenged</InputLabel>
                    <Controller
                        name={"physicalChallenge"}
                        control={control}
                        // rules={{
                        // required: {
                        //     value: true,
                        //     message: "This field is required",
                        // }
                        // }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Physical Challenged"
                        onChange={(e:any) => {
                            onChange(e.target.value)
                            setValue("role", e.target.value)
                            // if(getValues("bussinessUnit"))getReportingToApi(getValues("bussinessUnit"))
                        }}
                        >
                        {disability.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {errors?.physicalChallenge && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.physicalChallenge?.message}
                        </span>
                    )}
                </FormControl>
                <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Profile Source</InputLabel> */}
                    <Controller
                        name={"profileSource"}
                        control={control}
                        // rules={{
                        // required: {
                        //     value: true,
                        //     message: "This field is required",
                        // }
                        // }}
                        render={({ field: { onChange, value, onBlur } }) => (
                            <TextField
                                label='Profile Source'
                                // required={true}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                        />
                        {errors?.profileSource && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.profileSource?.message}
                        </span>
                    )}
                </FormControl>
                
            </Grid>
            <Grid className='row-grid mb-3'>               
                    <FormControl className='numberFieldSect' fullWidth required >
                        <label>Total Experience <span>*</span></label>
                        <Controller
                            name="totalExperience"
                            control={control}
                            rules={{
                            required: {
                                value: true,
                                message: "This field is required",
                            }
                            }}
                            render={({field : {onChange, value, onBlur}})=>(
                                <NumberWithIncDec onChange={onChange} value={value} min={0} max={100} required={true} hideArrow={true}/>
                            )}
                        />
                        {errors?.totalExperience && (
                            <span className="reminder_text-danger select-danger-text" role="alert">
                                {errors?.totalExperience?.message}
                            </span>
                        )}
                    </FormControl>
                    <FormControl fullWidth >
                        <Controller
                            name="alternatePhoneNo"
                            control={control}
                            rules={{
                            // required: {
                            //     value: true,
                            //     message: "This field is required",
                            // },
                            pattern: {
                                value: phoneNumberRegex,
                                message: `Please enter valid Phone`,
                            },
                            maxLength: {
                                value: 15,
                                message: "This input accept only max length of 15.",
                            },
                            minLength: {
                                value: 10,
                                message: "Minimum 10 number required.",
                            }
                            }}
                            render={({field : {onChange, value, onBlur}})=>(
                                <TextField
                                    label='Alternate Phone no'
                                    value={value}
                                    type='number'
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    // required
                                    inputProps={{ maxLength: 100 }}
                                />
                            )}
                        />
                        {errors?.alternatePhoneNo && (
                            <span className="reminder_text-danger select-danger-text" role="alert">
                                {errors?.alternatePhoneNo?.message}
                            </span>
                        )}
                    </FormControl>
                <FormControl fullWidth >
                    <Controller
                        name="duplicateProfileUrl"
                        control={control}
                        // rules={{
                        // required: {
                        //     value: true,
                        //     message: "This field is required",
                        // }
                        // }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Duplicate Profile URL'
                            // required={true}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                    {/* {errors?.duplicateProfileUrl && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.duplicateProfileUrl?.message}
                        </span>
                    )} */}
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Martial Status</InputLabel>
                    <Controller
                        name={"martialStatus"}
                        control={control}
                        // rules={{
                        // required: {
                        //     value: true,
                        //     message: "This field is required",
                        // }
                        // }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Martial Status"
                        onChange={(e:any) => {
                            onChange(e.target.value)
                            // setValue("role", e.target.value)
                            // if(getValues("bussinessUnit"))getReportingToApi(getValues("bussinessUnit"))
                        }}
                        >
                        {martialStatus.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {/* {errors?.martialStatus && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.martialStatus?.message}
                        </span>
                    )} */}
                </FormControl>
            </Grid>
            <Grid className='row-grid' style={{width:"calc((100% / 2) - 7px)"}}>
                <FormControl fullWidth>
                    <Controller
                        name="candidateAddress"
                        control={control}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Address'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            multiline={true}
                            minRows={3}
                            inputProps={{ maxLength: 256 }}                
                        />
                        )}
                    />
                </FormControl>
            </Grid>
                </Box>
                
            </Box>            
        </Grid>
    );
};

export default BasicCandidateDetails;