import TaskCard from "./TaskCard";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useDroppable ,DragOverlay} from "@dnd-kit/core";
import './KanbanView.scss'
import RemarkPopup from "./RemarkPopup";
import { createPortal } from "react-dom";


function ColumnContainer({
  id,
  title,
  cards,
  mandateData,
  mandatelocationLists,
  setUpdateHireProp,
  setSelectedCandidate,
  setIsOpen,
  setDefaultRollback,
  setFilteredStageList,
  setPopActive,
  activeCard,
  mandatelocationIndex

}: any) {
  const { setNodeRef } = useDroppable({ id: String(id) });


  return (
    <>

    <SortableContext 
    id={String(id)} 
    items={cards}
     strategy={rectSortingStrategy}
     >
      <div
        ref={ setNodeRef}
        className="columnContainer"

      >
        <div className="columnHeader">
          <span className="title">        
            {title}
          </span>
          <span className="count">
            {cards.length}
          </span>

        </div>
        <RemarkPopup/>
     
        {cards?.map((card: any) => (
           card.id == activeCard ?
           <TaskCard
           key={card?.id}
           id={card?.id}
           statusName={card?.statusName}
           name={card?.name}
           card ={card}
           currentLocation={card?.currentLocation}
           mandateData ={mandateData}
           mandatelocationLists ={mandatelocationLists}
           setUpdateHireProp ={setUpdateHireProp}
           setSelectedCandidate={setSelectedCandidate}
           setIsOpen={setIsOpen}
           stageName ={title}
           setDefaultRollback={setDefaultRollback}
           setFilteredStageList={setFilteredStageList}
           setPopActive={setPopActive}
           mandatelocationIndex={mandatelocationIndex}
           myclass="card-on-move"
         />:

          <TaskCard
            key={card?.id}
            id={card?.id}
            statusName={card?.statusName}
            name={card?.name}
            card ={card}
            currentLocation={card?.currentLocation}
            mandateData ={mandateData}
            mandatelocationLists ={mandatelocationLists}
            setUpdateHireProp ={setUpdateHireProp}
            setSelectedCandidate={setSelectedCandidate}
            setIsOpen={setIsOpen}
            stageName ={title}
            setDefaultRollback={setDefaultRollback}
            setFilteredStageList={setFilteredStageList}
            setPopActive={setPopActive}
            mandatelocationIndex={mandatelocationIndex}
          />
        ))}

         {createPortal(
          <DragOverlay>
            {cards.map((card:any)=> card.id == activeCard ?
           <TaskCard
            key={card?.id}
            id={card?.id}
            statusName={card?.statusName}
            name={card?.name}
            card ={card}
            currentLocation={card?.currentLocation}
            mandateData ={mandateData}
            mandatelocationLists ={mandatelocationLists}
            setUpdateHireProp ={setUpdateHireProp}
            setSelectedCandidate={setSelectedCandidate}
            setIsOpen={setIsOpen}
            stageName ={title}
            setDefaultRollback={setDefaultRollback}
            setFilteredStageList={setFilteredStageList}
            setPopActive={setPopActive}
          /> : null
          )}

          </DragOverlay>,
          document.body
        )}
      </div>
    </SortableContext>


   </>


  );
}

export default ColumnContainer;
