import { Box, Button, FormControl, Grid, IconButton,InputLabel,MenuItem,Pagination,Select,Stack,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField, Typography,Paper, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as BackIcon } from '../../../../assets/icons/backarrowicon1.svg'
import { AppButton } from '../../../AppComponents/AppButton';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg'
import { ReactComponent as FunnelIcon } from '../../../../assets/icons/funnel.svg'
import { ReactComponent as RotateIcon } from '../../../../assets/icons/reset.svg'
import { ReactComponent as SortUp } from '../../../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../../../assets/icons/sort-down.svg'
import { ReactComponent as SortUpDown } from '../../../../assets/icons/sort-up-down.svg'
import Profile from "../../../../assets/icons/profile.svg"
import CalendarIcon from "../../../../assets/icons/calendar-icon.svg" 
import { PRIMARY1, PRIMARY3, TERTIARY12, TERTIARY3 } from '../../../../utils/constants';
import "./JobOrderDetailView.scss"
import { MandateOptionTag } from '../../../AppComponents/AppMandateDropdown/AppMandateDropdown';
import { useLocation } from 'react-router-dom';
import { MandateService, downloadMandateFile, getJobOrderDetailList, getJobOrderDetailStatusCount, getJobOrderDetailStatusDropdown, getJobOrderDetailStatusType, getJobOrderDetailUpdateStatus, getMandateLocation } from '../../../../services/MandateService';
import { convertToDisplayDateTime } from '../../../../utils/date-utils';
import { toast } from 'react-toastify';
import InfoVector from "../../../../assets/images/infoVector.svg";
import RemarkAttachment from "../../../../assets/images/remarkAttachment.svg";
import RemarkAttachIcon from "../../../../assets/icons/remarkAttachIcon.svg";
import RemarkDownload from "../../../../assets/icons/remarkDownload.svg"
import RemarkTrash from "../../../../assets/icons/remarkTrash.svg"
import { ReactComponent as Approve } from '../../../../assets/icons/Approve.svg'
import { ReactComponent as Reject } from '../../../../assets/icons/Reject.svg'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'
import { useAppSelector } from '../../../../store/hooks';
import { baseUrl } from '../../../../services/http-common';


const JobOrderDetailView = () => {
    const [filterChecked, setfilterChecked] = useState(false)    
    const search = useLocation()?.search
    const location = useLocation()
    const mId:any = new URLSearchParams(search).get("id");
    const locId:any = new URLSearchParams(search).get("locId");
    const [mlocationId,setMlocationId] = useState([])
    const [mandateDetail, setMandateDetail] = useState<any>({})
    const [toggleFilterStatus, setToggleFilterStatus] = useState(false)
    const [mandateDetailStatusCount, setMandateDetailStatusCount] = useState<any>([])
    const [mandateDetailList, setMandateDetailList] = useState<any>([])    
    const [mandateDetailStatusDropdown, setMandateDetailStatusDropdown] = useState<any>([])
    const [mandateDetailTypeDropdown, setMandateDetailTypeDropdown] = useState<any>([])
    const [selectedMandateLocation,setSelectedMandateLocation] = useState<any>()
    const [jobOrderFilterLocation,setJobOrderFilterLocation] = useState<any>()
    const [jobStatusSelected, setJobStatusSelected] = useState("All")
    const [mandateDetailCopy, setMandateDetailCopy] = useState<any>([])
    const [mandateDetailSearchCopy, setMandateDetailSearchCopy] = useState<any>([])    
    const [sortingState, setSortingState] = useState({
        "jobOrderId": "",
        "exceptionStatus": ""
    })
    const [sortingStateSelected, setSortingStateSelected] = useState<any>({})
    const [searchKeyWord, setSearchKeyWord] = useState<any>("")
    const ref = useRef<any>(null);
    const [candidateTrackingFileUpload, setCandidateTrackingFileUpload] = useState<any>({})
    const fileExtension = ["application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","text/plain","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel","image/jpeg","image/jpeg","image/png","text/csv"]
    const [pageSizeSeleted, setPageSizeSeleted] = useState(5)
    const [pageCountSeleted, setPageCountSeleted] = useState(1)
    const [pageListCount, setPageListCount] = useState(0)
    const JoStatusList = useAppSelector((state) => state?.SystemConfig?.JoStatusList);
    const recruiterStatus = useAppSelector((state) => state.UserDetail.userRole)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const {userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const mandateDetailData = location?.state
    const reset = (selectedIndex:number) => {
        ref.current.value = "";
        setMandateDetailList((prev: any) => { return prev.map((item:any,childIndex:number) => {
            if(childIndex == selectedIndex){
                item.updatedAttachment = []                                                          
            }
            return item
        })})
      };
    const dropDownStatus:any = {
        "open_count": 61,
        "in_progress_count":62,
        "closed_xpheno_count":63,
        "closed_other_count":119,
        "hold_client_count":120,
        "hold_xpheno_count":121,
        "closed_hold_client_count":122,
        "closed_hold_xpheno_count":123
      }

      useEffect(() => {
        console.log("mandateDetailList", mandateDetail)
      },[mandateDetail])

    const handleFilter = () => {
        setfilterChecked((prev) => !prev)
        setToggleFilterStatus((toggleStatus) => !toggleStatus)
      }
    const getMandateDetail = () => {
        MandateService.getMandateDetail(mId)
      .then((res) => {
        setMandateDetail(res?.data?.result?.data)
      })
      .catch((error) => {
        console.log('error', error.message)
      })
    }
    const getMandateDetailStatus = async (locationId:any) => {
        const jobReqObj:any = {"mandateId":parseInt(mId),"locationId":locationId}
        if(locationId == "All"){
            delete jobReqObj.locationId;
        }
        try{
            const res = await getJobOrderDetailStatusCount(jobReqObj)
            if(res?.status === 200){
                // setMandateDetailStatusCount(res?.data?.result?.list[0])
                const countArry = res?.data?.result?.list;
                countArry.sort(function(a:any, b:any) {
                    return a.statusOrder - b.statusOrder;
                });
                setMandateDetailStatusCount(countArry)
            }
          }catch(error){
            console.log(error,"error")
          }
    }
    const getJobOrderTypeDropDown = async () => {        
        try{
            const res = await getJobOrderDetailStatusType({"workflowGroup": "jo_status"})
            if(res?.status === 200){
                setMandateDetailTypeDropdown(res?.data?.result?.data)
            }
          }catch(error){
            console.log(error,"error")
          }
    }
    const getMandateDetailStatusDropdown = async () => {
        const reqObj = {
            "filters": [
                {
                    "field": "workflowGroup",
                    "operator": "equals",
                    "type": "string",
                    "value": "jo_status"
                }
            ],
            "page": {
                "pageNo": -1,
                "pageSize": 50
            },
            "sorting": {
                "direction": "ASC",
                "field": "statusOrder"
            }
        }
        try{
            const res = await getJobOrderDetailStatusDropdown(reqObj)
            if(res?.status === 200){
                setMandateDetailStatusDropdown(res?.data?.result?.data)
            }
          }catch(error){
            console.log(error,"error")
          }
    }
    
    const getMandateDetailList = async (locationId:any) => {
        const jobReqObj:any = {"mandateId":parseInt(mId),"locationId":locationId}
        if(jobStatusSelected != "All"){
            jobReqObj["status"] = mandateDetailStatusCount.filter((count:any) => count.statusName == jobStatusSelected)[0].value;
        }
        if(locationId == "All"){
            delete jobReqObj.locationId;
        }
        try{
            const res = await getJobOrderDetailList(jobReqObj)
            if(res?.status === 200){      
                const jobOrderListObj:any = res?.data?.result?.list.map((item:any) => {
                    return {
                        "joid": item?.joid,
                        "status": item?.status,
                        "jocode": item?.jocode,
                        "statusname": item?.statusname,
                        "updatedbyfullname": item?.updatedbyfullname,
                        "updatedat": item?.updatedat,
                        "statusproperties": item?.statusproperties,
                        "remarks": item?.remarks != null ? item?.remarks : "",
                        "exceptiontype": item?.exceptiontype,
                        "validationCheck": false,
                        "fileAttach": item?.attachments,
                        "exceptiontypename": item?.exceptiontypename,
                        "updatedExceptionValue" : null,
                        "updatedRemarks": "",
                        "updatedAttachment": {},
                        "mandatelocationid": item?.mandatelocationid
                    }
                })
                const pagenationCount = (jobOrderListObj.length/pageSizeSeleted)
                setPageListCount((pagenationCount % 1) == 0 ? pagenationCount : Math.trunc(pagenationCount)+1)
                setMandateDetailList(jobOrderListObj)
                setMandateDetailCopy(JSON.parse(JSON.stringify(jobOrderListObj)))
                setMandateDetailSearchCopy(JSON.parse(JSON.stringify(jobOrderListObj)))
            }
          }catch(error){
            console.log(error,"error")
          }
    }
    const getLocationsForMandate = async () => {        
        const response = await getMandateLocation(mId, `?mandateBehaviour=${mandateDetailData?.mandateData?.mandateBehaviour}` )
        const mandateLocation = response?.data?.result?.mandateLocations.map((item:any) => {
            return {
            id: item?.id,
            primaryLocation: item?.location
            }
        })
        setMlocationId(mandateLocation)
    }
    const initialLoadData  = async () => {
            getMandateDetail()
            getMandateDetailStatus(locId)
            getJobOrderTypeDropDown()
            await getMandateDetailStatusDropdown()
            getMandateDetailList(locId)
            getLocationsForMandate()
            setSelectedMandateLocation(locId)
            setJobOrderFilterLocation(locId)
    }
    useEffect(() => {
            setPageCountSeleted(1)
            getMandateDetailList(selectedMandateLocation)
    },[jobStatusSelected])

    useEffect(() => {
        if(mId){
            initialLoadData()          
        }
    },[mId])

    const resetJobFilter = () => {
        setJobOrderFilterLocation("All")
        getMandateDetailList("All");
        getMandateDetailStatus("All")
        setSelectedMandateLocation("All")
        setJobStatusSelected("All")
        setSearchKeyWord("")
    }

    const updateJobOrderList = async (selectedIndex:number, validationStatus:any) => {
        const selectedMadnateList = JSON.parse(JSON.stringify(mandateDetailList))
        const selectedMadnate = selectedMadnateList[selectedIndex]
        const updateJobList:any = {
            "jobOrderId":selectedMadnate?.joid,
            "status":selectedMadnate?.status,
            "mandateId": mId,
            "locationId": selectedMadnate?.mandatelocationid,
            "joCode": selectedMadnate?.jocode
        }
        const errorList = []
        if(validationStatus?.exception_remark_mandatory){
            if(selectedMadnate?.updatedRemarks.length > 0){
                updateJobList["remark"] = selectedMadnate?.updatedRemarks
            }else{
                errorList.push("remarks")
            }
            if(validationStatus?.attachment_mandatory){
                if(Object.keys(mandateDetailList[selectedIndex]?.updatedAttachment).length == 0) errorList.push("remarks attachment")
            }
        }else {
            updateJobList["remark"] = selectedMadnate?.updatedRemarks
        }
        if(validationStatus?.exception_type_mandatory){
            if(selectedMadnate.updatedExceptionValue != null){
                updateJobList["exceptionType"] = selectedMadnate?.updatedExceptionValue
            }else{
                errorList.push("exceptionType")
            }
        }else{
            updateJobList["exceptionType"] = selectedMadnate?.updatedExceptionValue
        }
        if(errorList.length == 0){            
            const formData = new FormData()
            formData.append("joUpdateRemark", JSON.stringify(updateJobList));
            if(Object.keys(mandateDetailList[selectedIndex]?.updatedAttachment).length > 0)formData.append("files", mandateDetailList[selectedIndex]?.updatedAttachment[0]);
            try{
                const res = await getJobOrderDetailUpdateStatus(formData)
                if(res?.status === 200){       
                    getMandateDetailList(selectedMandateLocation);
                    getMandateDetailStatus(selectedMandateLocation)
                }
              }catch(error){
                console.log(error,"error")
              }
        }else{
            selectedMadnateList.map((item:any,index:number) => {
                if(index == selectedIndex){
                    item.validationCheck = true
                }
            })
            setMandateDetailList(selectedMadnateList)
        }        
    }

    const updateJobOrderListCancel = (selectedIndex:number) => {
        setMandateDetailList((prev: any) => { return prev.map((item:any,childIndex:number) => {
            if(childIndex == selectedIndex){
                item.status = mandateDetailSearchCopy[selectedIndex]?.status
                item.remarks = mandateDetailSearchCopy[selectedIndex]?.remarks
                item.updatedExceptionValue = null
                item.updatedRemarks = ""
                item.fileAttach = mandateDetailSearchCopy[selectedIndex]?.fileAttach
                item.validationCheck = false
                item.updatedAttachment = {}
            }
            return item
        })})
    }

    const sortColumnList = (column:string) => {
        const currentSort = JSON.parse(JSON.stringify(sortingState))
        let direction = "";
        if(currentSort[column] == "Asc"){
            direction = "Desc"
        }else if(currentSort[column] == "Desc"){
            direction = ""
        }else{
            direction = "Asc"            
        }
        currentSort[column] = direction
        if(column == "jobOrderId"){
            currentSort["exceptionStatus"] = ""
        }else{
            currentSort["jobOrderId"] = ""
        }
        setSortingState(currentSort)
        setSortingStateSelected({
            "column": column,
            "direction": direction
        })
    }

    useEffect(() => {
        const sortedArray:any = [];
        const currentJobOrderList = mandateDetailList.slice(((pageSizeSeleted*pageCountSeleted) - pageSizeSeleted), (pageSizeSeleted*pageCountSeleted))
        const currentJobOrderListCopy = mandateDetailSearchCopy.slice(((pageSizeSeleted*pageCountSeleted) - pageSizeSeleted), (pageSizeSeleted*pageCountSeleted))
        let originalArray = []
        if(sortingStateSelected?.direction?.length == 0){
            setMandateDetailList(mandateDetailSearchCopy)
            setMandateDetailCopy(mandateDetailSearchCopy)
        }else{
            if(sortingStateSelected?.column == "exceptionStatus"){
                originalArray = currentJobOrderList.map((item:any) => {
                    return item.statusname.split(' ').join('')+item.jocode.split(' ').join('')
                })
            }else{
                originalArray = currentJobOrderList.map((item:any) => {
                    return item.jocode
                })                
            }      
            let sortArry:any = []            
            if(sortingStateSelected?.direction == "Asc"){
                sortArry = originalArray.sort();
            }else if(sortingStateSelected?.direction == "Desc"){
                sortArry = originalArray.sort((a:any, b:any) => b.localeCompare(a));
            }
            originalArray.map((sortList:any) => {
                const filterList = currentJobOrderList.map((itemList:any) => {
                    if(sortingStateSelected?.column == "exceptionStatus"){
                        if((itemList?.["statusname"].split(' ').join('')+itemList?.["jocode"].split(' ').join('')) == sortList){
                            sortedArray.push(itemList)
                        }
                    }else{
                        if(itemList?.["jocode"] == sortList){
                            sortedArray.push(itemList)
                        }
                    }                    
                })                
            })
            const mandateSortedArray = mandateDetailList.map((manItem:any,manIndex:number) => {
                if((manIndex + 1) > (pageSizeSeleted*pageCountSeleted) - pageSizeSeleted && manIndex < (pageSizeSeleted*pageCountSeleted)){
                    return manItem = sortedArray[pageCountSeleted > 1 ? (manIndex - ((pageSizeSeleted*pageCountSeleted) - pageSizeSeleted)) : manIndex]
                }else{
                    return manItem
                }
            })
            setMandateDetailList(mandateSortedArray)
            setMandateDetailCopy(mandateSortedArray)
        }
    },[sortingStateSelected])

    useEffect(() => {
        const jobOrderListCopy = JSON.parse(JSON.stringify(mandateDetailSearchCopy))
        // const currentJobOrderList = jobOrderListCopy
        if(jobOrderListCopy.length > 0 && searchKeyWord.length > 0){
            const searchFilterArray:any = []
            const originalArray = jobOrderListCopy.map((item:any) => {
                const searchKeyWords = item.statusname +" "+ item.jocode +" "+ item.updatedbyfullname + convertToDisplayDateTime(item?.updatedat).toString()
                return {
                    "keyWord":searchKeyWords.toLowerCase(),
                    "list": item
                    }
            })
            originalArray.map((list:any) => {
                if(list?.keyWord.indexOf(searchKeyWord) > -1){
                    searchFilterArray.push(list?.list)
                }
            })
            setMandateDetailList(searchFilterArray)
            setMandateDetailCopy(searchFilterArray)
            setPageCountSeleted(1)
            const pagenationCount = (searchFilterArray.length/pageSizeSeleted)
            setPageListCount((pagenationCount % 1) == 0 ? pagenationCount : Math.trunc(pagenationCount)+1)
        }else{
            setMandateDetailList(jobOrderListCopy)
            setMandateDetailCopy(jobOrderListCopy)
            setPageCountSeleted(1)
            const pagenationCount = (jobOrderListCopy.length/pageSizeSeleted)
            setPageListCount((pagenationCount % 1) == 0 ? pagenationCount : Math.trunc(pagenationCount)+1)
        }     
    },[searchKeyWord])

    const renderEmptySearchBox = () => {
        return (
            <Box className="candidate-empty-search-box">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    {`No data found`}
                </Box>
            </Box>
        )
    }

    const checkPropStatus = (id:any) => {        
        const filterObj = mandateDetailStatusDropdown.filter((list:any) => list?.id == id)   
        return  JSON.parse(filterObj[0]?.statusproperties)
    }

    const handleAttachmentDownload = async (filePath:any) => {
        const excelLinkSource = `filename=${filePath}`
        try {
          const response = await downloadMandateFile(excelLinkSource)
          if (response?.data) {
            window.location.href = (`${baseUrl}mandate/download?${excelLinkSource}`)
          } else {
            console.error("No Such File")
          }
        } catch (error) {
          console.error("Internal Server Error", error)
        }
      };

    return (
        <Box className='jobOrderDetailSect'>
            <Box className="jobOrderDetailtopSect">
                <Box className="jobOrderDetailtopLeft">
                    <IconButton
                        onClick={()=> window.history.back()}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>
                    <h4>Job Order Status</h4>
                </Box>
                <Box className="jobOrderDetailtopRight">
                    <TextField id="outlined-basic" label="Search Keyword" placeholder='Search Keyword...' value={searchKeyWord} onChange={(e:any) => {setSearchKeyWord(e.target.value)}} variant="outlined" />
                    <AppButton
                        variant='contained'
                        margin={5}
                        minwidth={40}
                        bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                        textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                        onClick={handleFilter}
                        tooltip='Filter Mandate'
                    >
                        <FunnelIcon style={{ marginRight: '5px' }} className='add-new-icon' />
                        {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </AppButton>
                </Box>
            </Box>
            <Box className="jobOrderDetailMandateSect">
                <Box className="jobOrderDetailMandateCard">
                {mandateDetail && <MandateOptionTag
                    option={{
                        title: mandateDetail?.jobTitle,
                        mandateId: mandateDetail?.mandateCode || "-",
                        clientName: mandateDetail?.clientName,
                        createdByName: mandateDetail?.createdByName
                    }}
                    className="man-option-tag"
                    />
                }
                </Box>
            </Box>
            {toggleFilterStatus && <Box className="jobOrderFilterSect">
                <p>Filter</p>
                <Box className="jobOrderFilterSectItems">
                    <FormControl fullWidth>
                        <InputLabel>Location</InputLabel>
                        <Select
                            value={jobOrderFilterLocation}
                            label="Location"
                            className='ststus-list'
                            labelId='status-select-label'
                            onChange={(e:any)=> {
                                setJobOrderFilterLocation(e.target.value) 
                                setSelectedMandateLocation(e.target.value)                             
                            }}
                        >
                            <MenuItem value={"All"} key={"All"} >
                                <span style={{fontSize:"14px"}}>All</span>
                            </MenuItem>
                            {mlocationId?.map((status:any,index:number)=>{
                            return (
                            <MenuItem value={status?.id} key={index} >
                                <span style={{fontSize:"14px"}}>{status?.primaryLocation}</span>
                            </MenuItem>
                            )})}
                            {mlocationId?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                    <InputLabel>Hiring Manager Type</InputLabel>
                    <Select
                        value={""}
                        label="Hiring Manager Type"
                        className='ststus-list'
                        labelId='status-select-label'
                        disabled={true}
                        onChange={(e:any)=> {
                        
                        }}
                    >
                        {[]?.map((status:any,index:number)=>{
                        const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                        return (
                        <MenuItem value={status?.id} key={index} >
                            <span className="status-tag" style={
                            tagStyle
                                ? {
                                    color: tagStyle?.color,
                                    backgroundColor: tagStyle?.background,
                                }
                                : {}
                            }>{status?.status_name}</span>
                        </MenuItem>
                        )})}
                        {[]?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                    </Select>
                    </FormControl>
                    <FormControl fullWidth>
                    <InputLabel>Hiring Manager</InputLabel>
                    <Select
                        value={""}
                        label="Hiring Manager"
                        className='ststus-list'
                        labelId='status-select-label'
                        disabled={true}
                        onChange={(e:any)=> {
                        
                        }}
                    >
                        {[]?.map((status:any,index:number)=>{
                        const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                        return (
                        <MenuItem value={status?.id} key={index} >
                            <span className="status-tag" style={
                            tagStyle
                                ? {
                                    color: tagStyle?.color,
                                    backgroundColor: tagStyle?.background,
                                }
                                : {}
                            }>{status?.status_name}</span>
                        </MenuItem>
                        )})}
                        {[]?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                    </Select>
                    </FormControl>
                    <Box className="jobOrderFilterActionSect" >
                        <Grid>
                        <Stack direction='row' style={{gap:"6px"}}>
                            <Box className='filter-search-btn'>
                            <AppButton
                                label='Search'
                                bordercolor='black'
                                bgcolor='black'
                                size='large'
                                type='button'
                                onClick={() => {
                                    getMandateDetailList(jobOrderFilterLocation);
                                    getMandateDetailStatus(jobOrderFilterLocation)
                                    setJobStatusSelected("All")
                                    setPageCountSeleted(1)
                                }}
                                className='search-bth btn-class'
                                sx={{ color: '#fff' }}
                            />
                            </Box>
                            <Button
                            sx={{
                                color: '#002882',
                                textTransform: 'none',
                            }}
                            variant='text'
                            onClick={() => {resetJobFilter()}}
                            className='btn-class'
                            >
                            <RotateIcon />
                            <Typography className='reset-label'>Reset All</Typography>
                            </Button>
                        </Stack>
                        </Grid>
                    </Box>
                </Box>
            </Box>}
            <Box className="jobOrderToggleSect">
                <p>Status</p>
                <Box className="jobOrderToggleSectList">
                    {/* <p className={jobStatusSelected == "All" ? "active" : ""} onClick={() => setJobStatusSelected("All")}>All <span>{mandateDetailStatusCount.all_count}</span></p> */}
                    {mandateDetailStatusCount.map((item:any) => {
                        return (
                            <>
                                {<p className={jobStatusSelected == item?.statusName ? "active" : ""} onClick={() => setJobStatusSelected(item?.statusName)}>{item?.statusName} <span>{item?.count}</span></p>}
                            </>
                        )
                    })}
                    
                </Box>
            </Box>
            <Box className="jobOderTableSect">
                <Box className="jobOderTableSectScroll">
					<TableContainer className="jobOrderTableBlk" component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
                                    <TableCell>
                                        <div>JOB ORDER ID <span onClick={() => {sortColumnList("jobOrderId")}}>{sortingState?.jobOrderId == "Asc" ? <SortUp /> : sortingState?.jobOrderId == "Desc" ? <SortDown /> : <SortUpDown />}</span></div>
                                    </TableCell>
                                    <TableCell>
                                        <div>EXCEPTION STATUS <span onClick={() => {sortColumnList("exceptionStatus")}}>{sortingState?.exceptionStatus == "Asc" ? <SortUp /> : sortingState?.exceptionStatus == "Desc" ? <SortDown /> : <SortUpDown />}</span></div>
                                    </TableCell>
                                    <TableCell>
                                        <div>TYPE</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>REMARKS</div>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mandateDetailList && mandateDetailList.slice(((pageSizeSeleted*pageCountSeleted) - pageSizeSeleted), (pageSizeSeleted*pageCountSeleted)).map((item:any, index:number) => {
                                    const statusproperties = JSON.parse(item?.statusproperties)
                                    const mandateDetailCopyObj = mandateDetailCopy.slice(((pageSizeSeleted*pageCountSeleted) - pageSizeSeleted), (pageSizeSeleted*pageCountSeleted))
                                    const statusTypeOption:any = []
                                    const exceptionTypeOption:any = []
                                    const fileAttachement = JSON.parse(item?.fileAttach != null ? item?.fileAttach : "[]")
                                    statusproperties?.exception_status_ids.map((item:any) => {
                                        mandateDetailStatusDropdown.map((list:any) => {
                                            if(list?.id == item)exceptionTypeOption.push(list)
                                        })
                                        if(item in mandateDetailTypeDropdown){
                                            mandateDetailTypeDropdown[item].map((list:any) => {
                                                statusTypeOption.push(list)
                                            })                                            
                                        }  
                                    })
                                    return (
                                        <>
                                        {index < pageSizeSeleted && 
                                        <TableRow>
                                        <TableCell>
                                            <Box className="jobOrderIdCard">
                                                <h4>{item?.jocode}</h4>
                                                <Box>
                                                    <p><Tooltip title={"Updated User"}><img src={Profile} /></Tooltip><Tooltip title={item?.updatedbyfullname}><span>{item?.updatedbyfullname}</span></Tooltip></p>
                                                    <p style={{width:"180px"}}><Tooltip title={"Updated At"}><img src={CalendarIcon} /></Tooltip>{convertToDisplayDateTime(item?.updatedat)}</p>
                                                </Box>
                                                <h3>{item?.statusname}</h3>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {statusproperties?.show_exception_status ? <FormControl className='exceptionStatusField' fullWidth>
                                                <InputLabel required={true}>Exception  Status</InputLabel>
                                                <Select
                                                    value={item?.status}
                                                    label="Exception  Status"
                                                    className='ststus-list'
                                                    disabled={!userPermissions.mandate_module.mandate_job_order_detail.jo_status_update.selected ? true : (userRole == "Admin") ? false : false}
                                                    required={true}
                                                    labelId='status-select-label'
                                                    onChange={(e:any)=> {
                                                        setMandateDetailList((prev: any) => { return prev.map((item:any,childIndex:number) => {
                                                            if(childIndex == (pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index)){
                                                                item.status = e.target.value  
                                                                item.validationCheck = false                                                                  
                                                            }
                                                            return item
                                                        })})
                                                    }}
                                                >
                                                    {exceptionTypeOption?.map((status:any,index:number)=>{
                                                    const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                                                    return (
                                                        <MenuItem value={status?.id} key={index} >
                                                        <span className="status-tag" style={
                                                        tagStyle
                                                            ? {
                                                                color: tagStyle?.color,
                                                                backgroundColor: tagStyle?.background,
                                                            }
                                                            : {}
                                                        }>{status?.statusName}</span>
                                                    </MenuItem>
                                                    )})}
                                                    {mandateDetailStatusDropdown?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                                                </Select>
                                            </FormControl> : "-"
                                            }
                                        </TableCell>
                                        <TableCell>
                                        {(mandateDetailCopyObj[index]?.status != item?.status && checkPropStatus(item?.status)?.show_exception_type) ? <FormControl className='exceptionStatusType' fullWidth>
                                                <InputLabel required={checkPropStatus(item?.status)?.exception_type_mandatory}>Type</InputLabel>
                                                <Select
                                                    value={mandateDetailCopyObj[index]?.status == item?.status ? mandateDetailCopyObj[index]?.status : item?.updatedExceptionValue}
                                                    label="Type"
                                                    className='ststus-list'
                                                    required={checkPropStatus(item?.status)?.exception_type_mandatory}
                                                    labelId='status-select-label'
                                                    onChange={(e:any)=> {
                                                        setMandateDetailList((prev: any) => { return prev.map((item:any,childIndex:number) => {
                                                            if(childIndex == (pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index)){
                                                                item.updatedExceptionValue = e.target.value                                                               
                                                            }
                                                            return item
                                                        })})
                                                    }}
                                                >
                                                    {statusTypeOption.filter((list:any) => list?.statusId == item?.status)?.map((status:any,index:number)=>{
                                                    // const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                                                    return (
                                                    <MenuItem value={status?.id} key={index} >
                                                        <span style={{fontSize:"14px"}}>{status?.exceptionName}</span>
                                                    </MenuItem>
                                                    )})}
                                                    {mandateDetailStatusDropdown?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                                                </Select>
                                                {(item?.validationCheck && item?.updatedExceptionValue == null && checkPropStatus(item?.status)?.exception_type_mandatory) && <Box className="errorCase">
                                                    <p>Exception Type field required</p>
                                                </Box>}
                                            </FormControl> : <>{mandateDetailCopyObj[index]?.status == item?.status ? <h5>{item?.exceptiontypename?.length > 0 ? item?.exceptiontypename : "-"}</h5> : "-"}</>
                                            }                                                
                                        </TableCell>
                                        <TableCell>
                                            {(mandateDetailCopyObj[index]?.status != item?.status && checkPropStatus(item?.status)?.show_exception_remark) ? <Box className={`remarksFieldSect ${mandateDetailCopyObj[index]?.fileAttach?.length > 0 ? "candidateTrackFileUploaded":""}`}>
                                                <Box className={`remarkSect ${Object.keys(item?.updatedAttachment).length > 0 ? "uploadedRemarks" : ""}`}>     
                                                    <TextField
                                                        label="Remarks"
                                                        placeholder="Remarks"
                                                        required={checkPropStatus(item?.status)?.exception_remark_mandatory}
                                                        value={mandateDetailCopyObj[index]?.status == item?.status ? mandateDetailCopyObj[index]?.remarks : item?.updatedRemarks}
                                                        multiline
                                                        rows={4}
                                                        onChange={(e:any)=> {
                                                            setMandateDetailList((prev: any) => { return prev.map((item:any,childIndex:number) => {
                                                                if(childIndex == (pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index)){
                                                                    item.updatedRemarks = e.target.value
                                                                    if(e.target.value.length == 0){
                                                                        item.validationCheck = true
                                                                    }                                                                 
                                                                }
                                                                return item
                                                            })})
                                                        }}
                                                    />              
                                                    <Box className="remarkFileUpload">
                                                        <input
                                                            type='file'
                                                            id='mandateFile'
                                                            onChange={(event:any) => {
                                                            if(fileExtension.includes(event?.target?.files?.[0]?.type)){
                                                                if(event?.target?.files?.[0]?.size <= 5000000){
                                                                    setMandateDetailList((prev: any) => { return prev.map((item:any,childIndex:number) => {
                                                                        if(childIndex == (pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index)){
                                                                            item.updatedAttachment =  event.target.files                                                             
                                                                        }
                                                                        return item
                                                                    })})
                                                                }else{
                                                                toast.error('Max file size is 5MB')                          
                                                                }                        
                                                            }else{
                                                                toast.error('Upload file type not accepted')
                                                            }                      
                                                            }}
                                                            accept=".doc, .docx,.txt,.pdf, image/jpeg,.uml"
                                                            name='mandateFile'
                                                            ref={ref}
                                                        />
                                                        <label htmlFor='mandateFile'>
                                                        <img src={RemarkAttachment} />
                                                        </label>
                                                    </Box>
                                                    {item?.updatedAttachment.length > 0 && <Box className="remarkFileAttach">
                                                        <Tooltip title={item?.updatedAttachment[0]?.name}><p>{item?.updatedAttachment[0].name}</p></Tooltip>
                                                        <Box>
                                                            <img onClick={(e:any) => reset((pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index))} src={RemarkTrash} />
                                                        </Box>
                                                    </Box>}                                                                                                       
                                                </Box>
                                                {(item?.validationCheck && item?.updatedRemarks.length == 0 && checkPropStatus(item?.status)?.exception_remark_mandatory) &&<Box className="errorCase">
                                                    <p>Remarks field required</p>
                                                </Box>}
                                                {(item?.validationCheck && Object.keys(item?.updatedAttachment).length == 0 && checkPropStatus(item?.status)?.attachment_mandatory) &&<Box className="errorCase">
                                                    <p>Remarks Attachment required</p>
                                                </Box>}                                                                                                       
                                            </Box>
                                            : <>{mandateDetailCopyObj[index]?.status == item?.status ? <Box className="remarksViewSect">
                                                <h5>{item?.remarks ? item?.remarks : "-"}</h5>
                                                {fileAttachement.length > 0 && <Box className="remarksViewSectBlk">
                                                    <img src={RemarkAttachIcon} />
                                                    <Tooltip title={fileAttachement.at(-1)?.file_name}><p>{fileAttachement.at(-1)?.file_name}</p></Tooltip>
                                                    <h4 onClick={() => {handleAttachmentDownload(fileAttachement.at(-1)?.file_path)}}><img src={RemarkDownload} /></h4>                                                 
                                                </Box>}
                                            </Box>:"-"}</>}                                            
                                        </TableCell>
                                        <TableCell>
                                            {mandateDetailCopyObj[index]?.status != item?.status && <Box className="remarksActionSect">
                                                <IconButton 
                                                // disabled={(item?.validationCheck && (item?.exceptiontype == null || item?.remarks.length == 0 ))} 
                                                size='small' color='inherit' onClick={() => {updateJobOrderList((pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index),checkPropStatus(item?.status))}}>
                                                    <Tooltip title={"Update"}><Approve /></Tooltip>
                                                </IconButton>
                                                <IconButton size='small' color='inherit' onClick={() => {updateJobOrderListCancel((pageCountSeleted > 1 ? (index + pageSizeSeleted*(pageCountSeleted - 1)) : index))}}>
                                                    <Tooltip title={"Cancel"}><Reject /></Tooltip>
                                                </IconButton>
                                            </Box>}
                                        </TableCell>
                                    </TableRow>
                                        }                                        
                                        </>
                                    )
                                })}
                                {mandateDetailList.length == 0 && renderEmptySearchBox()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pageSizeSeleted}
                                disabled={mandateDetailList.length == 0}
                                label="Age"
                                onChange={(event:any) => {
                                    setPageSizeSeleted(event.target.value)
                                    setPageCountSeleted(1)
                                    const pagenationCount = (mandateDetailCopy.length/event.target.value)
                                    setPageListCount((pagenationCount % 1) == 0 ? pagenationCount : Math.trunc(pagenationCount)+1)
                                    setMandateDetailList(mandateDetailSearchCopy)
                                    setMandateDetailCopy(mandateDetailSearchCopy)
                                }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Pagination count={pageListCount} page={pageCountSeleted} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setPageCountSeleted(pageNumber);setSortingStateSelected({...sortingStateSelected, "direction":""});setSortingState({"jobOrderId": "","exceptionStatus": ""})}} />
                </Box>
            </Box>
        </Box>
    );
};

export default JobOrderDetailView;