/* eslint-disable react/prop-types */
import './styles.scss'


type Tprop = {
    status:string;
    color:string;
}

const StatusComponent:React.FC<Tprop> = ({status, color}) => {

    return (
        <div className='status-main'>
            <div className='ring' style={{ border:`4px solid ${color}`}}></div>
            <p className='text' style={{ color:`${color}`}}>{status}</p>
        </div>
    )
}

export default StatusComponent;
