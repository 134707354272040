import React, { useEffect, useMemo, useState } from 'react';


interface TProps{
    mandateOptions: any[];
    onSelect: (e:string)=> void;

}

const MandateSuggestion:React.FC<TProps>=({mandateOptions,onSelect})=> {
  
  return (
    <div className='main-suggestions'>
        <span className="suggested-heading">SUGGESTED JOB TITLE</span>
        {mandateOptions?.length ? mandateOptions?.map((value,index)=>{
            return (<div key={index} className="eachboxes" onClick={(e)=>{e.preventDefault();onSelect(value.title)}}>
                <span className='mandate-title'>{value?.title}</span>
                <span className='mandate-body-id'>{value?.id}</span>
            </div>)
        }) :<></>}
    </div>
  )
}

export default MandateSuggestion