import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'

const AcademicDetails = ({educationType,primaryList}:any) => {
    const { control, formState: { errors }, getValues, setValue, watch, clearErrors, trigger }: any = useFormContext();
    
    const { fields: attachmentFields, append : attachmentAppend, remove: attachmentRemove } = useFieldArray(
        {
          control,
          name: "academicDetails"
        }
      );
    const candidateAcademic = watch('academicDetails')
    return (
        <>
            <Grid className='container-wrapper'>
            <h5 className='cardHeadingStrip'><span>Academics</span></h5>
                <Box className="personalInfoWrapper" style={{flexDirection:"column"}}>
                    <Box className="personalInfoFieldWrapper">
                        {attachmentFields.map((item:any, index:any)=>{
                            return (
                            <div style={{display:"flex",alignItems:"flex-start"}} key={item.id}>
                                <Box style={{width:"100%"}}>
                                <Grid className='row-grid mb-3' >
                                    <FormControl fullWidth >
                                        <Controller
                                            name={`academicDetails.${index}.qualification`}
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <TextField
                                                    label='Qualification'
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                                        <Controller
                                            name={`academicDetails.${index}.course`}
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <TextField
                                                    label='Course'
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Education Type</InputLabel>
                                        <Controller
                                            name={`academicDetails.${index}.educationType`}
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value}
                                                    label="Education Type"
                                                    onChange={(e: any) => {
                                                        onChange(e.target.value)
                                                    }}
                                                >
                                                    {educationType.map((item: any, index: number) => (
                                                        <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="demo-simple-select-label">Institution</InputLabel> */}
                                        <Controller
                                            name={`academicDetails.${index}.institution`}
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <TextField
                                                    label='Institution'
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className='row-grid mb-3' style={{width:"calc((100% / 2) - 7px)"}}>
                                    <FormControl fullWidth style={{width:"100%"}}>
                                        {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                                        <Controller
                                            name={`academicDetails.${index}.academicLocation`}
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <Autocomplete
                                                    options={primaryList}
                                                    value={primaryList.find((loc:any) => loc.label === value)}
                                                    onChange={(event, value) => {
                                                        onChange(value)
                                                    }}
                                                    renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Location"
                                                    />
                                                    )}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <Box style={{width:"100%"}} className={`${candidateAcademic[index].candidateCurrentCompany ? "disableEndDate" : ""}`}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <Controller
                                        name={`academicDetails.${index}.yearOfPass`}
                                        control={control}
                                        render={({field : {onChange, ...restField}})=>(
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',width:"100%"}}>
                                            <DatePicker
                                            label='Year of Passing'
                                            className='datePicker yearOfPass'
                                            inputFormat="DD/MM/YYYY"
                                            onChange={(e:any)=>{onChange(e)}}
                                            components={{
                                                OpenPickerIcon: CalenderIcon,
                                            }}
                                            // minDate={moment().subtract(1,'years').toDate()}
                                            // maxDate={moment().toDate()}
                                            renderInput={(params:any) => (
                                                <TextField
                                                    name='Year of Passing'
                                                    style={{ width: "100%" }}
                                                    {...params}
                                                />
                                            )}
                                            {...restField}
                                        />
                                        </div>
                                        )}
                                        />
                                    </LocalizationProvider>
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                                        <Controller
                                            name={`academicDetails.${index}.candidateCurrentCompany`}
                                            control={control}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <FormControlLabel control={
                                                    <Switch 
                                                    checked={value}
                                                    onChange={onChange} />
                                                } label="Currently pursuing" />
                                            )}
                                        />
                                    </FormControl>
                                    </Box>                            
                                </Grid>  
                                </Box>
                                {attachmentFields.length > 1 && <div
                                    style={{display:"flex", alignItems:"center", cursor: "pointer"}} 
                                    onClick={() => attachmentRemove(index)}
                                    className='delete-icon'
                                >
                                    <DeleteIcon style={{marginTop:"15px",marginLeft:"15px"}}/>
                                </div>}
                                                              
                            </div>
                            )
                        })}
                    </Box>
                    <Box className='button-container' 
                            style={{justifyContent:"flex-start", padding: "0rem 0rem 1rem 0rem"}}
                        >
                            <Button 
                            className='draft-btn'
                            style={{width:"180px"}}
                            onClick={() => {
                                attachmentAppend({ qualification: '', course: '',educationType: '',institution: '',academicLocation: '',yearOfPass: null,candidateCurrentCompany: ''});
                            }}
                            >+ Add Qualification</Button>
                        </Box>
                </Box>
            </Grid>
        </>
    );
};

export default AcademicDetails;