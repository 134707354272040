import React, {useEffect, useMemo, useState } from 'react'
import { Box, Typography, IconButton, Divider, Avatar, Grid, Stack, Tooltip } from '@mui/material'
import { Mandatedetail } from '../../../../pages/Mandates'
import { ReactComponent as BackIcon } from '../../../../assets/icons/backarrowicon.svg'
import { ReactComponent as ExpandIcon } from '../../../../assets/icons/expandicon.svg'
import { ReactComponent as Minimize } from '../../../../assets/icons/minimize-icon.svg'
import { lefttoggle, leftdrawertoggle, leftdrawertoggleclose } from '../../../../features/toggle/ToggleReducer'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import {
  drawerclose,
  expanddrawer,
  minimizedrawer,
  mandateEdit,
  mandateDepartmentData,
  mandateIndustryData
} from '../../../../features/mandateDetail/DrawerReducer'
import { QueryBuilder } from '../../QueryBuilder'
import './DrawerData.css'
import _ from "lodash";
import ExpandCollapse from '../../../AppComponents/ExpandCollapse/ExpandCollapse'
import { downloadMandateFile, getDepartmentRoles, getIndustryData } from '../../../../services/MandateService'
import { baseUrl } from '../../../../services/http-common'
import MandateView from './MandateView'
import { AssignModal } from '../../AssignModel/AssignModel'
import { useNavigate,useLocation } from 'react-router'
import { resetNotification } from '../../../../features/notification/notificationReducer'

export interface DrawerProps {
  // draweropen: boolean
  // id:number
  data: Partial<Mandatedetail>
  noParse: boolean
  parsemsg: string
  isApprovalList?:boolean | undefined
  isRecruiter?: boolean
  horizontalMemberList: any[];
  verticalMemberList: any[];
  handledetial: any;
  loadFirstPage: any;
}
const DrawerData: React.FC<DrawerProps> = ({ data, noParse,isApprovalList, parsemsg,isRecruiter, horizontalMemberList,  verticalMemberList, handledetial, loadFirstPage}) => {
  const hideExpandIcon = useAppSelector((state) => state.mandatedrawer.hideExpandicon)
  const ShowFullPage = useAppSelector((state) => state.mandatedrawer.FullPage)
  const listStatus = useAppSelector((state) => state.mandatedrawer.isApproval)
  const mandateDepartentDataState = useAppSelector((state) => state.mandatedrawer.departmentData)
  const mandateIndustryDataState = useAppSelector((state) => state.mandatedrawer.industryData)
  const [fullwidth, setFullwidth] = useState(false)
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false);
  const [assigneeList, setAssigneeList] = useState<{selectedIds:string[], isHorizontalAssignee:string|null}>({selectedIds:[], isHorizontalAssignee: null})
  const [posIndex, setPosIndex] = useState<number>(0)
  const location = useLocation()
  const navigate = useNavigate()
  const [departmentData , setDepartmentData] = useState<any>([])
  const [industryData , setIndustryData] = useState<any>([])
  const mandateEditState = useAppSelector((state) => state.mandatedrawer.isEdit)
  const [manDetToggle, setManDetToggle] = useState(true)
  const [mandateRedirectState,setMandateRedirectState] = useState<any>(location)
  // const isUserEditable = getLoggedInUserName() === data?.createdByName ;
  const isUserEditable = true; // currently give edit access to all users
  
  const handleExpand = () => {
    // dispatch(lefttoggle())
    dispatch(leftdrawertoggle())
    dispatch(expanddrawer())
    setFullwidth((current) => !current)
  }

  const handledrawerClose = () => {
    dispatch(drawerclose())
    dispatch(leftdrawertoggleclose())
    // navigate("/mandates")
    if(mandateRedirectState.state != null){
      navigate(`/mandates`,{ state: { "columnFields": mandateRedirectState.state.columnFields,"viewType": mandateRedirectState.state.viewType }});
    }else{
      navigate(-1)
    }
    dispatch(resetNotification())
  }

  const handleminimize = () => {
    dispatch(minimizedrawer())
    dispatch(leftdrawertoggleclose())
    // setFullwidth(false)
  }


  const aligningDataLocation=(value:string)=>{
    try{
      let emptyRemoved = value
      if(value=="{'': ''}"){
        emptyRemoved='{}'
      }
      const modifiedStr = emptyRemoved.replace(/'/g, '"');
      return JSON.parse(modifiedStr);
    }catch(error){
      console.log("error in location parse",error)
      return {}
    }
  }

  const handleMandateDownload = async () => {
    const excelLinkSource = `filename=${data?.fileName}`
    try {
      const response = await downloadMandateFile(excelLinkSource)
      if (response?.data) {
        window.location.href = (`${baseUrl}mandate/download?${excelLinkSource}`)
        // window.open(`${baseUrl}mandate/download?${excelLinkSource}`)
      } else {
        console.error("No Such File")
      }
    } catch (error) {
      console.error("Internal Server Error", error)
    }
  };

  const getDepartments = async () => {
    const reqObj = {
      "filters": [],
      "page": {
          "pageNo": 0,
          "pageSize": 10
      },
      "sorting": {
          "direction": "ASC",
          "field": "createdAt"
      }
    }
    try{
      
      if(mandateDepartentDataState.length == 0) {
        const res:any = await getDepartmentRoles(reqObj)
        if(res?.status === 200){
          setDepartmentData(res.data.result.list);
          dispatch(mandateDepartmentData({departmentData: res.data.result.list}))
        }
      }else{
        setDepartmentData(mandateDepartentDataState);
      }
      
    } catch {
      setDepartmentData([]);
    }
  }
  const getIndusties = async () => {
    const reqObj = {
      "filters": [],
      "page": {
        "pageNo": 0,
        "pageSize": 10
      },
      "sorting": {
        "direction": "ASC",
        "field": "createdAt"
      }
    }
    try {
      if(mandateIndustryDataState.length == 0) {
        const res: any = await getIndustryData(reqObj)
        if(res?.status === 200){
          setIndustryData(res.data.result.list);
          dispatch(mandateIndustryData({industryData: res.data.result.list}))
        }
      }else{
          setIndustryData(mandateIndustryDataState);
      }
    } catch {
      setIndustryData([]);
    }
  }

  useEffect(() => {
      getDepartments()
      getIndusties()
  }, [mandateEditState,hideExpandIcon])
  // old code commented
  
  // useEffect(()=>{
  // if(location?.search===''){
  //   handledrawerClose()
  // }
  // },[location])
  const Mandatedetail = () => {
    
    const handleAssingClick =() =>{
      const manData:any = data;
      const obj = {
        selectedIds : manData?.assignToUsers?.map((user:any)=> user?.assigneeid),
        isHorizontalAssignee : manData?.isHorizontalAssignee === 1 ? "horizontal" : "vertical"
      }
      setAssigneeList(obj)
      setOpen(true) 
    }
    
    return (
      <>
        <Grid container direction={'column'}>
          <Grid item sx={{ width: '100%', overflow: 'hidden' }}>
            <Box  className='mandate-full-inner-box' style={{paddingLeft: "20px",paddingRight: "15px"}}>
              <IconButton
                onClick={handledrawerClose}
                aria-label='back'
                disableRipple
                disableFocusRipple
              >
                <BackIcon />
              </IconButton>
              {!hideExpandIcon && !noParse && (
                <IconButton
                  aria-label='goto-details'
                  disableRipple
                  disableFocusRipple
                  onClick={handleExpand}
                >
                  <ExpandIcon />
                </IconButton>
              )}
              <IconButton aria-label='minimize-detail' disableRipple disableFocusRipple>
                <Minimize onClick={handleminimize} />
              </IconButton>
            </Box>
            {noParse ? (
              <>
                <Box className='parsing-progress-cls'>
                  <HourglassEmptyIcon className='hour-icon-cls' />
                  <Typography className='parse-progress-test'> {parsemsg}</Typography>
                </Box>
              </>
            ) : (
              <>
              {Object.keys(data).length > 0 && <MandateView
                isRecruiter={isRecruiter}
                data={data}
                isApprovalList={listStatus}
                isUserEditable={isUserEditable}
                handleMandateDownload={handleMandateDownload}
                handleAssingClick={handleAssingClick}
                posIndex={posIndex}
                setPosIndex={setPosIndex}
                handledetial={handledetial}
                loadFirstPage={loadFirstPage}
                departmentData={departmentData}
                industryData={industryData}
              />}
              </>
            )}
          </Grid>
        </Grid>

      </>
    )
  }

  return (
    <>
      <Grid container className={ShowFullPage ? 'mandate-full-page-box mandate-container-wrapper' : ''}>
        <Grid
          item
          sx={{paddingX:'0px',paddingY:'16px'}}
          sm={12}
          md={ShowFullPage ? (manDetToggle ? 11 : 8) : 12}
          lg={ShowFullPage ? (manDetToggle ? 11 : 8) : 12}
          xl={ShowFullPage ? (manDetToggle ? 11 : 8) : 12}
          style={{width:"100%"}}
          className='full-width-minscreen mandateContentView'
        >
          <div className={ShowFullPage ? 'mandate-full-page' : 'mandate-data-drawer'}>
          <Mandatedetail />
          </div>

        </Grid>
        {ShowFullPage && (
          <Grid
            item
            sm={12}
            sx={{paddingX:'12px',paddingY:'16px' }}
            md={ShowFullPage ? (manDetToggle ? 1 : 4) : 12}
            lg={ShowFullPage ? (manDetToggle ? 1 : 4) : 12}
            xl={ShowFullPage ? (manDetToggle ? 1 : 4) : 12}
            style={{width:"100%", height:"100%"}}
            className={`full-width-minscreen sticky-filter ${manDetToggle ? "mandateDetToggleState": ""}`}
          >
             <div style={{height: '100%' }} className='mandate-full-page margin-unset h-100'>
              <QueryBuilder data={data} posIndex={posIndex} setPosIndex={setPosIndex} setManDetToggle={setManDetToggle}/>
            </div>
          </Grid>
        )}
      </Grid>
      {open && <AssignModal mandateIds={[data?.id]} open={open} onClose={()=>setOpen(false)} horizontalMemberList={horizontalMemberList} verticalMemberList={verticalMemberList} assigneeList={assigneeList} />}
    </>
  )
}

export default React.memo(DrawerData)
