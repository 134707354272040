import { useEffect } from 'react';
import './styles.scss'
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resetNotification } from '../../../features/notification/notificationReducer';
type Tprop = {
    selectedTitle: string;
    setSelectedTitle: (value: string) => void;
}

// eslint-disable-next-line react/prop-types
const ProfileDetailsCategory: React.FC<Tprop> = ({ selectedTitle, setSelectedTitle }) => {
    const ProfileDetailsCat: { value: string, label: string }[] = [
        {
            value: 'basicDetails',
            label: 'Basic Details'
        },
        {
            value: 'workExperience',
            label: 'Work experience'
        },
        {
            value: 'skills',
            label: 'Skills'
        },
        {
            value: 'callLogs',
            label: 'Call logs'
        },
        {
            value: 'linkedInProfile',
            label: 'LinkedIn Profile'
        },
        {
            value: 'candidateTracking',
            label: 'Candidate tracking'
        },
    ]
    return (
        <div className='cat-main'>
            {ProfileDetailsCat?.map((category: { value: string, label: string }, index: number) => {
                return (
                    <div className={`${category.value} eachcards${selectedTitle == category.value ? "-active" : ""}`} key={index} onClick={() => setSelectedTitle(category?.value)}>{category?.label}</div>
                )
            })}
        </div>
    )
}

export default ProfileDetailsCategory
