import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import './styles.scss'
import CloseIcon from '@mui/icons-material/Close'
import { LocalPharmacyOutlined } from '@mui/icons-material'
import { ReactComponent as InsightsIcon } from '../../../assets/icons/insights.svg'
import { ReactComponent as TaskIcon } from '../../../assets/icons/task.svg'
import { ReactComponent as NoteIcon } from '../../../assets/icons/note.svg'
import { ReactComponent as ExtensionIcon } from '../../../assets/icons/extension.svg'
import { useEffect, useState } from 'react'
import { getMandateForNotes, listNotes } from '../../../services/CandidateService'
import { uniqueId } from 'lodash'
import { convertToDisplayDateTime } from '../../../utils/date-utils'


const CandidateInsights =  ({candidateDetailsData,setCandidateDetailToggle,candidateDetailToggle}:any) => {
  const [tabButtonCode, setTabButtonCode] = useState<any>(3)
  const [sidePanelContent, setSidePanelContent] = useState({ title: 'Chrome Extension Notes' })
  const [notesArray, setNotesArray]=  useState<any[]>([])
  const [mandate, setMandate] = useState()
  const [mandateListArray, setMandateListArray]= useState<any[]>([])

  const tagChange = (code: number) => {
    setTabButtonCode(code)
    setCandidateDetailToggle(tabButtonCode == code ? !candidateDetailToggle : false)
  }


  const getNoteList = async (mandate:number |null) => {
    const response = await listNotes({ uniqueId: candidateDetailsData?.uniqueId ,mandateId:mandate })
    if(response){ setNotesArray(response) }
    else{
      return []
    }
  }
    
  

  useEffect(() => {
     getNoteList(null)
  }, [ ])

  const getMandate = async () => {
    const mandateList = await getMandateForNotes(candidateDetailsData?.uniqueId)
    if (mandateList) {
      setMandateListArray(mandateList)
    } else {
      return []
    }
  }

  useEffect(() => {
    getMandate()
  }, [])
  
  const handleChange = (value: any )=> {
    setMandate(value?.id)
    getNoteList(value?.id)
  }

  const SelectMandate = () => {
    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Autocomplete
            options={mandateListArray}
            sx={{
              marginBottom: { xs: '1rem', lg: '0rem' },
              width:"100%"
            }}
            className='mandatelist-on-notes'
            value={mandateListArray.filter((ele) => ele.id == mandate)?.[0]?.jobtitle}
            disablePortal
            onChange={(e: any, val: any) => handleChange(val)}
            noOptionsText='No options'
            renderOption={(props, option) => {
              return (
                <li style={{fontSize:'12px'}} {...props} key={option.id} value={option.id}>
                  {option.jobtitle+`-XRDMAN${option.id}`}
                </li>
              )
            }}
            renderInput={(params: any) => (
              <TextField
              onKeyPress={(e) => {e.preventDefault();}}
                {...params}
                sx={{ width: { xs: '100%', lg: '13.5rem' ,caretColor: "white"} }}
                placeholder='Select Mandate'
              />
            )}
          />
        </FormControl>
      </Box>
    )
  }

  return (
    <Box className='side-panel'>
      <Box className={`insight-wrap ${candidateDetailToggle ? "d-none":""}`}>
        <Box className='insight-head'>
          <Typography>{sidePanelContent.title}</Typography>
          <CloseIcon onClick={() => setCandidateDetailToggle(true)} style={{ marginRight: '15px', cursor:"pointer" }} />
        </Box>
        <div className='tabs'>
          {tabButtonCode === 0 && <p>other tabs</p>}
          {tabButtonCode === 1 && <p>other tabs</p>}
          {tabButtonCode === 2 && <p>other tabs</p>}
          {tabButtonCode === 3 && (
            <>
              <SelectMandate  />
              <Notes array={notesArray} />
            </>
          )}
        </div>
      </Box>
      <div className='sidepanel-right'>
        <InsightsIcon 
          className={tabButtonCode == 0 ? 'tabButton-color' : ''}
          onClick={() => tagChange(0)}
          style={{pointerEvents:"none"}}
        />
        <TaskIcon
          className={tabButtonCode == 1 ? 'tabButton-color' : ''}
          onClick={() => tagChange(1)}
          style={{pointerEvents:"none"}}
        />
        <NoteIcon
          className={tabButtonCode == 2 ? 'tabButton-color' : ''}
          onClick={() => tagChange(2)}
          style={{pointerEvents:"none"}}
        />
        <Tooltip title="Chrome Extension Notes">
          <ExtensionIcon
          style={{cursor:"pointer"}}
            className={tabButtonCode == 3 ? 'tab-button-puzzle' : 'puzzle'}
            onClick={() => tagChange(3)}
          />
        </Tooltip>
      </div>
    </Box>
  )
}

const Notes = (props: any) => {
  return (
    <div>
      {props.array.length ? (
        props.array.map((ele: any, index: number) => {
          return (
            <div key={index}>
              <Typography className='note-name'>{ele.name}</Typography>
              <p className='note-createdat'>{convertToDisplayDateTime(ele.createdAt)}</p>
              <p className='note-content'>{ele.notes}</p>
            </div>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}
export default CandidateInsights
