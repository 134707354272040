import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import _ from "lodash"
import { Controller, useFormContext } from "react-hook-form"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/input-calendar.svg'
import { useState, useEffect } from "react";
import MandateSuggestion from "./MandateSuggestion";
import { MandateService, getAllListMandates ,dropDownData} from "../../../../services/MandateService";
import moment from "moment";
import InfoIcon from '@mui/icons-material/Info';



const MandateDetailsForm = ({ endDateError, setEndDateError, mandatelocationListuse,dropdownOption }: any) => {

  const { control, watch, formState: { errors }, getValues, setValue }: any = useFormContext()
  const [mandatePreOptions, setMandatePreOptions] = useState([])
  const [mandateOptions, setMandateOptions] = useState([])
  const [mandateSearch, setMandateSearch] = useState("")
  const [mandateTypeOptions, setMandateTypeOptions] = useState(dropdownOption["MANDATE_TYPE"]??[])
  const [JobTypeOptions, setJobTypeOptions] = useState( dropdownOption["Job_Type"]??[])
  const getData = () => {
    getAllListMandates().then((res: any) => {
      const dataItems = res?.data?.result?.list || []
      if (dataItems) {
        const filterData = dataItems.filter((item: any) => item?.status !== 'draft')
        const options = filterData.map((data: any) => {
          return {
            title: _.get(data, 'jobtitle'),
            id: _.get(data, 'mandatecode'),
          }
        })
        setMandatePreOptions(options)
      } else {
        setMandatePreOptions([])
      }
    })
  }
  const getMandateType = async () => {
    const reqObj = {
      "businessUnit": false,
      "appConfiguration":false,
      "dropdownRequest": [
          {
              "drpCode": "XPN0001",
              "drpName": "MANDATE_TYPE"
          },
          {
            "drpCode": "XPN0003",
            "drpName": "Job_Type"
        }
      ]
  }
    try{
      const res:any = await dropDownData(reqObj)

      if(res?.status === 200){
        // console.log("checking ...",res?.data )

        setMandateTypeOptions(res?.data?.result?.dropdownData.MANDATE_TYPE ?? [])
        setJobTypeOptions(res?.data?.result?.dropdownData.Job_Type ?? [])
        // console.log("checking ...",res?.data )
        // console.log("checking ...",res?.data?.result?.dropdownData.MANDATE_TYPE )
      }
    }catch(error){
      console.log(error,"error")
    }
  }

  useEffect(() => {
   setValue ("totalMandateLocations",mandatelocationListuse.reduce((sum:any,itemss:any)=> sum+=itemss.position,0))
  }, [mandatelocationListuse])


  useEffect(() => {
    if (mandateSearch) {
      setMandateOptions(mandatePreOptions.filter((ele: any) => ele?.title?.toLowerCase()?.includes(mandateSearch?.toLowerCase()) || String(ele.id).includes(mandateSearch)))
    } else {
      setMandateOptions([])
    }
  }, [mandateSearch])


  const onSelectSuggestion = (value: string) => {
    setValue("jobTitle", value)
    setMandateSearch("")
    setMandateOptions([])
  }
  useEffect(() => {
    getData()
  }, [])

  const priorityMenus = [{ id: 0, value: 1, menuName: "High" }, { id: 1, value: 2, menuName: "Medium" }, { id: 2, value: 3, menuName: "Low" }]
  return (
    <Grid className='container-wrapper'>
      <Grid className='row-grid'>
        <FormControl fullWidth required className="job-title-box">
          <Controller
            name="jobTitle"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => {
              const handleChange = (e: any) => {
                onChange(e.target.value)
                setMandateSearch(e.target.value)
              }
              const onBlurred = () => {
                onBlur()
                setTimeout(() => setMandateSearch(""), 200);
              }
              return (
                <TextField
                  label='Job Title'
                  required={true}
                  value={value}
                  onChange={handleChange}
                  onBlur={onBlurred}
                />
              )
            }}
          />
          {errors?.jobTitle && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.jobTitle?.message}
            </span>
          )}
          {(mandateSearch && mandateOptions?.length) ? <MandateSuggestion mandateOptions={mandateOptions} onSelect={onSelectSuggestion} /> : <></>}
        </FormControl>
      
        <FormControl fullWidth required>
        <InputLabel>Job Type</InputLabel>
          <Controller
            name="jobType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
              label='Job Type'
              value={value}
              onChange={(e) => onChange(e?.target?.value)}
              onBlur={onBlur}
            >
              {JobTypeOptions.map( (type: any, index: any) => (
                <MenuItem key={type?.id} value={type?.domValue
                }>{type?.domValue
                }</MenuItem>
              ))}
            </Select>
            )}
          />
           {errors?.jobType && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.jobType?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel>Priority</InputLabel>
          <Controller
            name="priority"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
                label='Priority'
                value={value}
                onChange={(e) => onChange(e?.target?.value)}
                onBlur={onBlur}
              >
                {_.map(priorityMenus, (prio: any, index: any) => (
                  <MenuItem key={prio?.id} value={prio?.menuName}>{prio?.menuName}</MenuItem>
                ))}
              </Select>
            )}
          />
          {errors?.priority && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.priority?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required >
        <InputLabel>Mandate Type</InputLabel>

          <Controller
            name="mandateType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <Select
              label='Mandate Type'
              value={value}
              onChange={(e) => onChange(e?.target?.value)}
              onBlur={onBlur}
            >
              {mandateTypeOptions.map((type: any, index: any) => (
                <MenuItem key={type?.id} value={type?.domValue}>{type?.domValue}</MenuItem>
              ))}
            </Select>
            )}
          />
            {errors?.mandateType && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.mandateType?.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid className='column-grid pt-1'>
       <Box style={{display:"flex", gap:"20px"}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
              // validate: (value) => {
              //   if (!value || !value.isValid || !value.isValid()) {
              //     return;
              //   }
              //   const minDate = moment().toDate(); // Replace with your minDate
              //   const maxDate = moment().add(4,'years').toDate(); // Replace with your maxDate
              //   if (value && value.toDate() < minDate || value.toDate() > maxDate) {
              //     return 'Date must between today and less than 4 years';
              //   }
              //   return true;
              // },
            }}
            render={({ field: { onChange, ...restField } }) => (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width:"270px"}}>
              <DatePicker
                label='Start Date'
                className='datePicker datePickerMandate'
                inputFormat="DD/MM/YYYY"
                // minDate={moment().toDate()}
                // maxDate={moment().add(4,'years').toDate()}
                onChange={(e: any) => {
                  onChange(e)
                  if (new Date(getValues("endDate")) < new Date(e)) {
                    setEndDateError(true)
                  } else {
                    setEndDateError(false)
                  }
                }}
                // maxDate={getValues("requestStartDate")}
                components={{
                  OpenPickerIcon: CalenderIcon,
                }}
                renderInput={(params: any) => (
                  <TextField
                  {...params}
                    name='startDate'
                    error={false}
                    required={true}
                    style={{width:"100%"}}
                    
                  />
                )}
                {...restField}
              />
              { errors?.startDate && (
             <span className="reminder_text-danger select-danger-text" role="alert">
              {errors?.startDate?.message}
            </span>
           )}
              </div>
            )}
          />
        </LocalizationProvider>
             
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              }
              // validate: (value) => {
              //   if (!value || !value.isValid || !value.isValid()) {
              //     return;
              //   }
              //   const minDate = moment().toDate(); // Replace with your minDate
              //   const maxDate = moment().add(4,'years').toDate(); // Replace with your maxDate
              //   if (value && value.toDate() < minDate || value.toDate() > maxDate) {
              //     return 'Date must greater than startDate and less than 4 years';
              //   }
              //   return true;
              // },
            }}
            render={({ field: { onChange, ...restField } }) => (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',width:"270px"}}>
              <DatePicker
                label='End Date'
                className='datePicker datePickerMandate'
                inputFormat="DD/MM/YYYY"
                // minDate={getValues("startDate")}
                // maxDate={moment().add(4,'years').toDate()}
                onChange={(e: any) => {
                  onChange(e)
                  if (new Date(e) < new Date(getValues("startDate"))) {
                    setEndDateError(true)
                  } else {
                    setEndDateError(false)
                  }
                }}
                // maxDate={getValues("requestStartDate")}
                components={{
                  OpenPickerIcon: CalenderIcon,
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    name='endDate'
                    style={{ width: "100%" }}
                    required={true}
                    error={false}
                  />
                )}
                {...restField}
              />
              {endDateError || errors?.endDate && (
            <span className="reminder_text-danger select-danger-text" role="alert">
              {endDateError ?"End date should be greater than start date":errors?.endDate?.message}
            </span>
          )}
              </div>
            )}
          />        
        </LocalizationProvider>
      </Box>
      {endDateError && <Box className="manadateDateError"><InfoIcon /> End Date should be greater than or equal to start date</Box>}
      </Grid>
    </Grid>
  )
}

export default MandateDetailsForm;