import { FormControl, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as UserIcon } from '../../assets/icons/login-user.svg'
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CallingUserDetails = () => {
    const { control, formState: { errors },getValues,setValue,clearErrors,trigger } : any = useFormContext()
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)
    return (
        <Grid className='container-wrapper'>
            <Grid className='row-grid mb-3'>
                <FormControl fullWidth style={{width:"calc((100% / 4) - 15px)"}}>
                    <Controller
                        name="smartFlowUsername"
                        control={control}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Smartflow Username'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                </FormControl> 
                <FormControl fullWidth style={{width:"calc((100% / 4) - 15px)"}}>
                    <Controller
                        name="smartFlowPassword"
                        control={control}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Smartflow Password'
                            value={value}
                            onChange={onChange}
                            autoComplete='new-password'
                            type={showPassword ? 'text' : 'password'}
                            onBlur={onBlur}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='toggle password visibility'
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      className='eye-icon'
                                    >
                                      {showPassword ? (
                                        <Visibility className='eye-icon' />
                                      ) : (
                                        <VisibilityOff className='eye-icon' />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                        />
                        )}
                    />
                </FormControl> 
            </Grid>
        </Grid>
    );
};

export default CallingUserDetails;