import { readFile } from './cropImage';
import { useImageCropContext } from './ImageCropProvider';
import Button from './base/Button';
import Cropper from './cropper/Cropper';
import { RotationSlider, ZoomSlider } from './cropper/Sliders';
import { Box } from '@mui/material';

const ImageCropModalContent = ({ handleDone, handleClose }:any) => {
  const { setImage } = useImageCropContext();

  const handleFileChange = async ({ target: { files } }:any) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
  };

  return (
    <div className="text-center relative">
      <h5 className="text-gray-800 mb-4">Edit profile picture</h5>
      <div className="border border-dashed border-gray-200 p-6 rounded-lg">
        <div className="flex justify-center">
          <div className="crop-container mb-4">
            <Cropper />
          </div>
        </div>
        <Box className="profileUploadSetting">
          <ZoomSlider className="mb-4" />
          <RotationSlider className="mb-4" />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="hidden"
            id="avatarInput"
            accept="image/*"
          />

          <Button variant="light" className="anotherPicBtn">
            <label htmlFor="avatarInput">Upload Another Picture</label>
          </Button>
          <div className="uploadProfileAction">
            <Button variant="secondary" className="cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" className="doneSave" onClick={handleDone}>
              Done & Save
            </Button>
          </div>
        </Box>
        
      </div>
    </div>
  );
};

export default ImageCropModalContent;
