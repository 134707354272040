import React, { useEffect, useMemo, useState } from 'react'
import { TextField, Box, Typography } from '@mui/material'
import MandateTable from '../MandateTable/MandateTable'
import '../RecruiterCard/RecruiterCard.scss'
import {
  getdetailsOnRecruiter,
} from '../../../services/WorkManagementService'
import TargetContainer from '../RecruiterCard/TargetContainer'
import BulkAllocationRecuriterCard from './BulkAllocationRecuriterCard'

const BulkRecruiterContainer = ({ recruiterData, viewType, activeTabStatus }: any) => {
  
  const mandateCountsparse = () => {
    try {
      return JSON.parse(recruiterData?.mandateStages)
    } catch (error) {
      return []
    }
  }
  const mandatestageCounts = useMemo(() => mandateCountsparse(), [recruiterData])
  const parseData = (data:string) => {
    try {
      return JSON.parse(data) || []
    } catch (error) {
      return []
    }
  }
  return (
    <>
      <Box className='recruiter-view-header'>
        
        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'MON')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'CV Submission')?.status_count
          }
          target={0}
          status={'good'}
          statusType = {viewType != "W" ? "CV Submission" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'TUE')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'CCM1')?.status_count
          }
          target={0}
          status={'critical'}
          statusType = {viewType != "W" ? "CCM1" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'WED')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'CCM2')?.status_count
          }
          target={0}
          status={'attention'}
          statusType = {viewType != "W" ? "CCM2" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'THU')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'Selection Stage')?.status_count
          }
          target={0}
          status={'attention'}
          statusType = {viewType != "W" ? "Selection Stage" : activeTabStatus}
        />

        <TargetContainer
          acheived={
            viewType == "W" ? mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'FRI')?.count : mandatestageCounts?.find((x: any) => x?.stage_name === 'Joining Stage')?.status_count
          }
          target={0}
          status={'good'}
          statusType = {viewType != "W" ? "Joining Stage" : activeTabStatus}
        />

        {viewType == "W" && <TargetContainer
          acheived={mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'SAT')?.count}
          target={0}
          status={'good'}
        />}
        {viewType == "W" && <TargetContainer
          acheived={mandatestageCounts?.find((x: any) => x?.wkday?.trim() === 'SUN')?.count}
          target={0}
          status={'good'}
        />}
      </Box>
      
    </>
  )
}

export default BulkRecruiterContainer
