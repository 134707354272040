import { instance } from "./http-common";
import { objectToQueryString } from '../utils/common-utils';
import { getLoggedInUserId } from '../utils/user-utils';
export const getApprovalLists = async () => {
    const data = { "userId": getLoggedInUserId() }
    try {
        const response = await instance.post(`/user/getMandateApprovalList`, data);
        if (response.status == 200) {
            return response.data
        }
        return null
    } catch (error) {
        console.log('Error while getting approver list', error)
        return null
    }
}

export const approveOrRejectMandate = async (data: any, id: any) => {
    const arrangedData = {...data,id,approverId:data.approver}
    delete arrangedData.approver
    const response = await instance.post(`mandate/approval/actions`,arrangedData);
    return response;
    // return {status:200}
}
export const auditLogs = async (data: any) => {
    if(data?.createdBy && data?.mandateId){
        const queryString = objectToQueryString(data)
        const response = await instance.get(`mandate/get_approval_history?${queryString}`);
        return response;
    }
}
// - Approval list Api
// Get   api/mandate/list/approvers

// - Approve and reject Api
// POST  api/mandate/id/approval/process
// {
// status:"approved",
// reason:""
// }

// - Approvable mandate(Single) Api "--if needed"

// POST  api/mandate/id/request
// {
// approvers: [],
// status:"approval"

// }