
import React, { useState } from 'react'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { ReactComponent as GroupHistoryIcon } from '../../../assets/icons/GroupHistory.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import ClientDefaultLogo from '../../../assets/images/defaultUserIcon.svg'
import GroupStrick from '../../../assets/icons/Groupstrick.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as UnAssignIcon } from '../../../assets/icons/unassign.svg';
import { ReactComponent as ReallocateSingleIcon} from '../../../assets/images/ReallocateSingleIcon.svg';

import {
  Box, Typography, Tooltip, styled,
  Card,
  CardContent,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Checkbox,
  Button,
  AvatarGroup,
  Popover
} from '@mui/material'
import '../MandateTable/MandateCard/MandateCard.scss'
import { useAppSelector } from '../../../store/hooks'

const BulkAllocationRecuriterCard = ({ option,candidateData = [], isTagRemovable = false, className = "", handleEditAllocation, handleUnassignAllocation, recuriterStatus, ...rest }: any) => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isPopOpen, setIsPopOpen] = useState<boolean>(false);
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userRole, roleId } = useAppSelector((state) => state.UserDetail)

  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopOpen(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopOpen(false)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getUserTooltipName = (name: any) => {
    let tooltipName = ""
    try {
      tooltipName = JSON.parse(name)
    } catch (error) {
      console.log(error)
      tooltipName = name
    }
    return tooltipName
  }

  return (
    <Box className={`mandate-card-wrapper ${className}`} {...rest}>
      <div className='mandate-card-container' style={{display:"flex",justifyContent:"space-between", paddingBottom:"10px"}}>
        <Box className='mandate-card-detail'>
          <img src={ClientDefaultLogo} />
          <div>
          <Tooltip title={option?.recuiterName}><Typography className='mandate-card-title'>{option?.recuiterName}</Typography></Tooltip>
            <Typography className='mandate-card-id'>{option?.recuiterRole}</Typography>
          </div>
        </Box>
        <Box>
          <span className='change-option' style={{display:"flex"}}>
          {/* <Tooltip title={'Edit Recruiter'}><EditIcon style={{cursor:"pointer"}} className='edit-icon' onClick={handleEditAllocation} /></Tooltip> */}
            {recuriterStatus == 64 ? <>{(userPermissions?.work_management_module?.view?.deallocate?.selected && userRole != "Admin") ? <Tooltip title={'deallocation'}><UnAssignIcon style={{cursor:"pointer"}} className={`unassign-icon ${!option?.disableEdit ? 'disableIcon': ''}`} onClick={handleUnassignAllocation} /></Tooltip>: ""} </>: <>{userPermissions?.work_management_module?.view?.reallocate?.selected ? <Tooltip title={'reallocation'}><ReallocateSingleIcon  style={{cursor:"pointer"}} className={`unassign-icon ${!option?.disableEdit ? 'disableIcon': ''}`} onClick={handleUnassignAllocation} /></Tooltip> : "" }</> }
            {recuriterStatus == 64 ? <>{(userRole == "Admin") ? <Tooltip title={'deallocation'}><UnAssignIcon style={{cursor:"pointer"}} className={`unassign-icon ${!option?.disableEdit ? 'disableIcon': ''}`} onClick={handleUnassignAllocation} /></Tooltip>: ""} </>: <>{(userRole == "Admin") ? <Tooltip title={'reallocation'}><ReallocateSingleIcon  style={{cursor:"pointer"}} className={`unassign-icon ${!option?.disableEdit ? 'disableIcon': ''}`} onClick={handleUnassignAllocation} /></Tooltip> : "" }</> }
          </span>
        </Box>

        {/* <Box className="assign-block">
        <ListItemIcon className='mandate-card-list-icon avatar-list-items'>
          {candidateData?.length > 0 && (
            <AvatarGroup className='group-avatar' max={4} onClick={(e:any) => {if(candidateData?.length > 4)handleAvatarClick(e)}} >
              {candidateData?.map((user: any, index: number) => (
                <Tooltip
                  key={index}
                  title={getUserTooltipName(user?.name)}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#000',
                        '& .MuiTooltip-arrow': {
                          color: '#000',
                        },
                      },
                    },
                  }}
                >
                  <Avatar className={candidateData?.length > 1 ? 'assignee-avatar' : ""} key={index} alt={user} src={""} sx={{ width: 30, height: 30 }} />
                </Tooltip>
              ))}
            </AvatarGroup>)}
          {(candidateData?.length === 0) && <Avatar alt='no-grou' src={GroupStrick} sx={{ width: 30, height: 30 }} />}
        </ListItemIcon>
        <span className='change-option'>
          <EditIcon className='edit-icon' onClick={handleEditAllocation} />
          <UnAssignIcon className='unassign-icon' onClick={handleUnassignAllocation} />
        </span>
      </Box> */}

   
      </div>
    
      {/* <div className='mandate-card-client'>
        <div className='d-flex' style={{ justifyContent: "flex-start" }}>
          <GroupHistoryIcon />
          <Tooltip title={option?.clientName}><div className='overflow-restriction workFlowClientName'>{option?.clientName}</div></Tooltip>
        </div>
        <div className='d-flex' style={{ justifyContent: "flex-start" }}>
          <ProfileIcon />
          <Tooltip title={option?.createdByName}>
            <div
              className='overflow-restriction createdByText'>{option?.createdByName}</div>
          </Tooltip>
        </div>
      </div> */}
      {isPopOpen && <Popover
        id={id}
        open={isPopOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='candidate-allocation-avt-popup'
      >
        <Box className="cand-popup-container" >
          {candidateData?.slice(3, (candidateData?.length))?.map((candidate:any, index:number) => (
            <Box key={index} className="cand-wrap" >
              <Box>
                <Avatar sx={{ width: 30, height: 30 }}/>
              </Box>
              <Box>
                <div className='cand-name'>{candidate?.name}</div>
                <div className='cand-role'></div>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>}
    </Box>
  )
}

export default BulkAllocationRecuriterCard
