import { createSlice } from '@reduxjs/toolkit'

interface IMinimize {
  minimize: boolean,
  questionId?: number,
  isEnd?: boolean,
  thread: string,
  pageNo:number,
  previousModule:string;
  isEndId:number
}

const initialState: IMinimize = {
  minimize: false,
  questionId: 0,
  isEnd: false,
  thread: '',
  pageNo:-1,
  previousModule:"",
  isEndId:0

}
const minimizeGptSlice = createSlice({
  name: 'MinimizeChatGPT',
  initialState: initialState,
  reducers: {
    minimize: (state, action) => {
      return {
        ...state,
        minimize: true,
        thread: action?.payload?.threadId,
        questionId: action?.payload?.quesId,
        isEndId: action?.payload?.isEndId,
        pageNumber:action?.payload?.pageNo,
        previousModule:action?.payload?.previousModule
      }
    },
    reset: (state) => {
      return {
        ...state,
        minimize: false,
        questionId: 0,
        thread: '',
        isEnd: false,
        pageNo:-1,
        previousModule:"",
        isEndId:0
      }
    },
    quesPersist: (state, action) => {
      return {
        ...state,
        questionId: action?.payload
      }
    },
    isEndPersist: (state, action) => {
      return {
        ...state,
        isEnd: action?.payload
      }
    },
    maximize: (state, action) => {
      return {
        ...state,
        minimize: false,
        thread: action?.payload?.threadId,
        questionId: action?.payload?.quesId,
        isEnd: action?.payload?.isEndId,
        previousModule:action?.payload?.previousModule,
        isEndId:action?.payload?.isEndId,
        pageNumber:action?.payload?.pageNo,
      }
    }

  }
})

export const { minimize, reset, quesPersist, isEndPersist, maximize } = minimizeGptSlice.actions
export default minimizeGptSlice.reducer