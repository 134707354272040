import { createSlice } from '@reduxjs/toolkit'
// model has interface for redux

export interface ToggleState {
    leftopen: boolean,
    rightopen: boolean,
    quickview: boolean
}
const initialToggleState: ToggleState = {
    leftopen: true,
    rightopen: false,
    quickview:false
}

const toggleSlice = createSlice({
    name: 'sample',
    initialState: initialToggleState,
    reducers: {
        lefttoggle: (state) => {
            console.log('toggle')
            state.leftopen = !(state.leftopen)
        },
        righttoggle: (state) => {
            state.rightopen = !(state.rightopen)
        },
        leftdrawertoggle: (state) => {
            state.leftopen = false
        },
        leftdrawertoggleclose: (state) => {
            state.leftopen = true
        },
        quickViewToggle:(state) =>{
            return{
                ...state,
                quickview:false
            }
        }
    },
})

export const { lefttoggle, righttoggle,  leftdrawertoggle, leftdrawertoggleclose,quickViewToggle } = toggleSlice.actions
export default toggleSlice.reducer