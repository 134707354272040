import React, { useState } from 'react';
import "./AiGeneratorPopup.scss"
import { Box } from '@mui/material';
import { ReactComponent as AiImage } from '../../../assets/icons/aiImage.svg'
import { ReactComponent as HintLight } from '../../../assets/icons/hintLight.svg'
import CloseIcon from '@mui/icons-material/Close';

const AiGeneratorPopup = ({setSuggestedSelected}:any) => {
    const [aiImageBlkStatus, setAiImageBlkStatus] = useState(false)
    const [suggestionList, setSuggestionList] = useState(["Enhance using my current JD","Generate Using Mandate specs"])
    const [closeToolTipStatus, setCloseToolTipStatus] = useState(true)
    return (
        <>
            <Box className="aiImageSect">
                {closeToolTipStatus && <Box className="generateAiTooltip">Rewrite with AI <CloseIcon style={{cursor:"pointer"}} onClick={() => setCloseToolTipStatus(false)} /></Box>}
                <Box className="aiImageBlk" onClick={() => {setAiImageBlkStatus(!aiImageBlkStatus);setCloseToolTipStatus(false)}}><AiImage /></Box>
                {aiImageBlkStatus && <Box className="aiImagePopover">
                <CloseIcon className="closeIcon" onClick={() => {setAiImageBlkStatus(false)}}/>
                <h5>CHOOSE JD SOURCE</h5>
                <ul>
                    {suggestionList.map((suggItem:any, index:number) => (
                        <li onClick={() => {setSuggestedSelected({value: suggItem,timeStamp: new Date().getTime()});setAiImageBlkStatus(false)}} key={index}><HintLight />{suggItem}</li>
                    ))}
                </ul>
                <span className="arrow-down"></span>
                </Box>}
            </Box>
        </>
    );
};

export default AiGeneratorPopup;