import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Autocomplete,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Chip,
  Checkbox,
  TextFieldProps,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, OutlinedInput, Hidden
} from '@mui/material'
import Popover from '@mui/material/Popover';
import { AppButton } from '../../AppComponents/AppButton'
import '../../Calendar/SchedulerPopup.scss'
import './BulkAllocationPopup.scss'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState, useEffect, useMemo, useRef, JSXElementConstructor, ReactElement } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { useAppSelector } from '../../../store/hooks'
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import profileMenu from "../../../assets/images/profileMenu.jpg"
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import { Controller, useForm } from 'react-hook-form';
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../utils/constants'
import { ReactComponent as AddNewIcon } from '../../../assets/icons/add-new.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg';
import { ReactComponent as DueDate} from '../../../assets/images/due_date.svg';
import Deallocation from '../../../assets/images/DeallocationIcon.png';
import Reallocation from '../../../assets/images/ReallocationIcon.png';
import { ReactComponent as DeallocationSingle} from '../../../assets/images/DeallocationSingle.svg';
import { ReactComponent as ReallocateSingleIcon} from '../../../assets/images/ReallocateSingleIcon.svg';
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg'
import { ReactComponent as AllocationTooltipIcon } from '../../../assets/images/allocationTooltipIcon.svg'
import { getMandateAuditLogs, getMandateLocation } from '../../../services/MandateService';
import { getAssigneeList, getMandateAlloctionList, postBulkAllocationList, postBulkDeallocation, postBulkReallocation, postBulkUpdate } from '../../../services/WorkManagementService';
import dayjs from 'dayjs';
import BulkAllocationConfirmationPopup from '../BulkAllocationViewContainer/BulkAllocationConfirmationPopup';
import { AppDialog } from '../../AppComponents/AppDialog';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg'
import { toast } from "react-toastify";
import { convertToDisplayDateTime } from '../../../utils/date-utils';



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '900px',
  maxWidth: "1000px",
  height: 'auto',
  borderRadius: '10px',
  bgcolor: 'background.paper',
  border: 'none',
  padding: "32px 0px 10px 20px",
}
type TFormProps = {
  assignees : [];
  selectedLocations : any[];
  assigneeDueDate : Date;
}

const initialValues : TFormProps = {
  assignees: [],
  selectedLocations: [],
  assigneeDueDate: new Date()
}

const BulkAllocationPopup = ({ setBulkAllocationPopupStatus, bulkAllocationPopupStatus, selectedMandateId }: any) => {
  const [selectedMandateLocation, setSelectedMandateLocation] = useState<any[]>([])
  const [mandateLocationObj, setMandateLocationObj] = useState<any>({})
  const [assigneeList, setAssigneeList] = useState([])
  const [allocationList, setAllocationList] = useState([])
  const autoC = useRef<any>(null);
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [checkDisableStatus, setCheckDisableStatus] = useState(true)
  const [checkReallDisableStatus, setCheckReallDisableStatus] = useState(true)
  const { handleSubmit, control, reset, watch, getValues,setValue, formState: { errors } } = useForm<TFormProps>({defaultValues: initialValues});
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false)
  const [confirmationPopupTitle, setConfirmationPopupTitle] = useState("")
  const [confirmationPopuRequestObj, setConfirmationPopuRequestObj] = useState<any>({})
  const [updateStatusBtn, setUpdateStatusBtn] = useState(true)
  const [reSubmissionPopupOpen, setReSubmissionPopupOpen] = useState(false)
  const [assigneeErrorPopupOpen, setAssigneeErrorPopupOpen] = useState(false)
  const [assigneeErrorPopupMsg, setAssigneeErrorPopupMsg] = useState("")
  const [selectedUserName, setSelectedUserName] = useState("")
  const [selectedUserError, setSelectedUserError] = useState("")
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [historyPopupOpenStatus, setHistoryPopupOpenStatus] = useState(false)
  const [allocationHistoryId, setAllocationHistoryId] = useState<number>()
  const [allocationHistoryList, setAllocationHistoryList] = useState<any[]>([])
  const [allocationHistoryTotalCount, setAllocationHistoryTotalCount] = useState(0)
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
  const [sortingState, setSortingState] = useState<any>({
    recuriter: null,
    location: null,
    dueDate: null
  })
  const [sortSelected, setSortSelected] = useState<any>({
    sortColumn: "",
    timeStamp: ""
  })

  const resetSortObj = () => {
    setSortSelected({
      sortColumn: "",
      timeStamp: ""
    })
  }
  const getMandateLocationList = async (manId:any)=> {
    const response = await getMandateLocation(manId?.value,`?status=133,134&mandateBehaviour=${manId?.mandateBehaviour}`)
    const locationObj:any = {};
    if(response?.status === 200){
        setSelectedMandateLocation(response?.data?.result?.mandateLocations)
        response?.data?.result?.mandateLocations.map((item:any) => {
          locationObj[item?.id] = item?.location
        })
        setMandateLocationObj(locationObj)
        
    }else{
        setSelectedMandateLocation([])
    }
  }
  const getAssigneeListAPI = async (manId:any)=> {
    const response = await getAssigneeList({
      "mandateId": manId,
      "myTeam": false
  })
    if(response?.status === 200){
      setAssigneeList(response?.data?.result?.list)
    }else{
      setAssigneeList([])
    }
  }

  const getAllocationList = async (manId:any,value:string,searchType:string)=> {
    try{
      const response = await getMandateAlloctionList({
        "mandateId": manId,
        "searchColumns":{
          "allocatedUserName": searchType == "recruiter" ? value : "",
          "location":searchType == "location" ? value : "",
          "dueDt":searchType == "dueDate" ? value : ""
  
      },
      "sortColumn":{
          "sortColumn": (sortSelected?.sortColumn != null) ? (sortSelected?.sortColumn == "recuriter" ? "allocatedUserName" : sortSelected?.sortColumn == "dueDate" ? "dueDt": sortSelected?.sortColumn == "location" ? "location" : ""): "",
          "sortDirection":(sortSelected?.sortColumn != null) ? (sortingState[sortSelected?.sortColumn] == null ?  "" : sortingState[sortSelected?.sortColumn] ? "ASC" : "DESC") : ""
      }
      })
      if(response?.status === 200){
        allocationListRestructure(response?.data?.result?.list)
      }else{
        setAllocationList([])
      }
      }catch(error:any){
        console.log(error)
      }
    
  }

  const allocationListRestructure = (data:any) => {
    const allocationObjList = data.map((dataItems:any) => {
      return {
        checkedStatus: false,
        profileImage: "",
        allocationUserName: dataItems?.allocatedUserName,
        allocationUserRole: dataItems?.roleName,
        allocationLocation: dataItems?.maLocations.map((locationItem:any) => {return locationItem?.mandateLocationId}),
        dueDate: dataItems?.dueDt,
        allocationStatus: dataItems?.status,
        allocationUserId: dataItems?.allocatedUser,
        updatedStatus: false,
        initialAllocationLocation: dataItems?.maLocations.map((locationItem:any) => {return locationItem?.mandateLocationId}),
        intialDueDate: dataItems?.dueDt,
        allocationId: dataItems?.allocationId,
        userActiveStatus: dataItems?.userActiveStatus
      }
    })
    setAllocationList(allocationObjList)
  }

  const firstLoadData = async () => {
    await getMandateLocationList(selectedMandateId)
    await getAssigneeListAPI(selectedMandateId?.value)
    await getAllocationList(selectedMandateId?.value,"","")
  }
  
  
  useEffect(() => {
    if(selectedMandateId?.value){
      firstLoadData()
    }
  },[selectedMandateId])  

  

  const filterSubmitHandler = async (Data:any) => {
    console.log(Data)
    const addAllocationLoaction = Data?.selectedLocations.map((data:any) => {
      return {
        mandateId: selectedMandateId?.value,
        mandateLocationId: data,
      }
    })
    const addAllocationUsers = Data?.assignees.map((data:any) => {
      return data?.recruiterId
    })
    const addAllocationReq = {
      "mandateAllocation": {
          "mandateId": selectedMandateId?.value,
          "maLocations": addAllocationLoaction,
          "dueDt": moment(new Date(Data?.assigneeDueDate)).format('YYYY-MM-DD')
      },
      "allocatedUsers": addAllocationUsers
  }
  try{
    const response = await postBulkAllocationList(addAllocationReq)
    if(response?.status === 200){
      getAllocationList(selectedMandateId?.value,"","")
      reset()
      clearAutoField()
      getAssigneeListAPI(selectedMandateId?.value)
      toast.success('Successfully allocated to the Recruiter')
      }
    }catch(error:any){
        if(error?.response.data.error?.errorCode == "ERR126"){
          toast.error(error?.response.data.error?.message)
        }else if(error?.response.data.error?.errorCode == "ERR121"){
          setAssigneeErrorPopupOpen(true)
          setAssigneeErrorPopupMsg(error?.response.data.error?.message)

        }
      console.log(error)
    }
      
  }

  const clearAutoField = () => {
    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  }

  const allocationStatusChange = (index:number, type:string, value:any) => {
    const allocationStateObj = JSON.parse(JSON.stringify(allocationList))
    if(type == "check"){
      allocationStateObj[index].checkedStatus = value      
    }else if(type == "location"){
      allocationStateObj[index].allocationLocation = value;
      allocationStateObj[index].updatedStatus = (JSON.stringify(value.sort()) != JSON.stringify(allocationStateObj[index].initialAllocationLocation.sort())) ? true : (allocationStateObj[index].dueDate != allocationStateObj[index].intialDueDate) ? true : false;
    }else{
      allocationStateObj[index].dueDate = moment(new Date(value)).format('YYYY-MM-DD')
      allocationStateObj[index].updatedStatus =  (moment(new Date(value)).format('YYYY-MM-DD') != allocationStateObj[index].intialDueDate) ? true : (JSON.stringify(allocationStateObj[index].allocationLocation.sort()) != JSON.stringify(allocationStateObj[index].initialAllocationLocation.sort())) ? true : false;
    }
    setAllocationList(allocationStateObj)
  }

  useEffect(() => {
    if(allocationList.length > 0){
      const allocationObj = JSON.parse(JSON.stringify(allocationList));
      const checkListStatus = allocationObj.filter((List: any) => List?.checkedStatus == true);
      const activeUserList = allocationObj.filter((List: any) => List?.userActiveStatus == true)
      if(checkListStatus.length == activeUserList.length){
        if(!checkAll)setCheckAll(true)
      }else {
        if(checkAll)setCheckAll(false)
      }
    if(allocationObj.filter((List: any) => List?.updatedStatus == true).length > 0){
      setUpdateStatusBtn(false)
    }else{
      setUpdateStatusBtn(true)
    }
    if(checkListStatus.length > 0){
      if(checkListStatus.filter((List: any) => List?.allocationStatus == 65).length > 0){
        setCheckReallDisableStatus(false)
      }else{
        setCheckReallDisableStatus(true)
      }
      if(checkListStatus.filter((List: any) => List?.allocationStatus == 64).length > 0){
        setCheckDisableStatus(false)
      }else{
        setCheckDisableStatus(true)
      }
    }else{
       setCheckDisableStatus(true)
       setCheckReallDisableStatus(true)
    }
    }    
  },[allocationList])

  const reAllocationAPI = async (val:any,type:string,postType:string) => {
    const allocationCopyObj = JSON.parse(JSON.stringify(allocationList));    
    let allocationStateObj = []
    if(type == "single"){
      allocationStateObj = [
        {
          "allocatedUser": allocationCopyObj[val]?.allocationUserId,
          "locationIds": allocationCopyObj[val]?.allocationLocation
        }
      ]
    }else{
      allocationStateObj = allocationCopyObj.filter((List: any) => (List?.checkedStatus == true && List?.userActiveStatus && List?.allocationStatus == (postType == "reallocation" ? 65 : 64))).map((Item:any) => {
        return {
          "allocatedUser": Item?.allocationUserId,
          "locationIds": Item?.allocationLocation
        }
      })
    }
    const allocationFilterObj = {
      "mandateId": selectedMandateId?.value,
      "allocatedUsers": allocationStateObj,
      "mode": type == "single" ? "SINGLE" : "BULK",
      "isAllow": false
    }
    setConfirmationPopupTitle(postType);
    setConfirmationPopupOpen(true)
    setConfirmationPopuRequestObj(allocationFilterObj)
    setSelectedUserName(type == "single" ? allocationCopyObj[val]?.allocationUserName: "")
  }

  const confirmAllocationAPI = async (type:any) => {
    const allocationCopyObj = JSON.parse(JSON.stringify(confirmationPopuRequestObj));
    if(type == "confirmation") allocationCopyObj['isAllow'] = true 
    try{
      const response = confirmationPopupTitle == "deallocation" ?  await postBulkDeallocation(allocationCopyObj) : await postBulkReallocation(allocationCopyObj);
      if(response?.status === 200){
        getAllocationList(selectedMandateId?.value,"","")
        setConfirmationPopupTitle("");
        setConfirmationPopupOpen(false)
        setConfirmationPopuRequestObj({})
        if(type == "confirmation") setReSubmissionPopupOpen(false)
        setSelectedUserName("")
        resetSortObj()
        toast.success(`${confirmationPopupTitle} successfully updated`)
      }
      }catch(error:any){
        if(error.response.data.error.errorCode == 'ERR123' || error.response.data.error.errorCode == 'ERR003'){
          setConfirmationPopupOpen(false)
          setReSubmissionPopupOpen(true)
          if(selectedUserName.length == 0) {
            setSelectedUserError(error.response.data.error.message) 
          }else{
            setSelectedUserError("")
          }
        }
        console.log(error)
      }
  }

  const allCheckStatus = (checkedStatusVal:boolean) => {
    const allocationCheckObj = JSON.parse(JSON.stringify(allocationList));
    allocationCheckObj.map((item:any,index:number) => {
      if(item.userActiveStatus)allocationCheckObj[index]['checkedStatus'] = checkedStatusVal 
    })
    setAllocationList(allocationCheckObj)
  }

  const renderEmptySearchBox = () => {
    const emptyMessage = `No data found`;
    return (
        <Box className="candidate-empty-search-box candidateDetailWraper" style={{position:"absolute",textAlign:"center"}}>
            <Box>
                <NoResultFound />
            </Box>
            <Box className="empty-search-message">
                {emptyMessage}
            </Box>
        </Box>
    )
}

const locationInteration = (locationData:any) => {
  const addAllocationLoaction = locationData.map((data:any) => {
    return {
      mandateId: selectedMandateId?.value,
      mandateLocationId: data,
    }
  })
  return addAllocationLoaction
}

const allocationUpdate = async () =>{
  const allocationCopyObj = JSON.parse(JSON.stringify(allocationList));
  let allocationStateObj = []
  let locationEmptyCase = false;
  
  allocationStateObj = allocationCopyObj.filter((List: any) => List?.updatedStatus == true).map((Item:any) => {
    const locationIds = locationInteration(Item?.allocationLocation)
    if(locationIds.length == 0) locationEmptyCase = true
    return {
      "mandateId": selectedMandateId?.value,
      "allocatedUser": Item?.allocationUserId,
      "maLocations": locationIds,
      "dueDt": Item?.dueDate,
      "allocationId": Item?.allocationId
  }
  })
  if(locationEmptyCase) {
    toast.warn('Minimum one location needed for the recruiter')
    return
  }
  try{
    const response = await postBulkUpdate({
      "mandateAllocation":allocationStateObj
    });
    if(response?.status === 200){
      getAllocationList(selectedMandateId?.value,"","")
      resetSortObj()
      getAssigneeListAPI(selectedMandateId?.value)
      toast.success('Successfully Updated.')
    }
    }catch(error:any){
      if(error?.response.data.error?.errorCode == "ERR126"){
        toast.error(error?.response.data.error?.message)
      }else if(error?.response.data.error?.errorCode == "ERR121"){
        setAssigneeErrorPopupOpen(true)
        setAssigneeErrorPopupMsg(error?.response.data.error?.message)
      }
    }
}

const sortingColumn = (column:string) => {
  const sortInitailObj:any = {
    recuriter: null,
    location: null,
    dueDate: null
  }
  const sortingStateObj = JSON.parse(JSON.stringify(sortingState))
  sortingStateObj[column] = sortingStateObj[column] == null ? true : sortingStateObj[column] == true ? false : null
  sortInitailObj[column] = sortingStateObj[column]
  setSortingState(sortInitailObj)
  setSortSelected({"sortColumn": sortingStateObj[column] == null ? null : column,timeStamp: new Date().getTime()})  
}

useEffect(() => {
  if(sortSelected?.sortColumn != "")getAllocationList(selectedMandateId?.value,"","")
},[sortSelected])

const historyPopup = async (event:any, id:number) => {
      setAnchorEl(event.currentTarget)
      setHistoryPopupOpenStatus(true)
      setAllocationHistoryId(id)
      auditLog(id,0)
      
}

const auditLog = async (id:any, scrollLoad:number) => {
  if(scrollLoad == 0){
    setAllocationHistoryList([])
    setAllocationHistoryTotalCount(0)
  }
  const auditLogObj = {
    "filters":[
      {
      "field":"eventId",
      "operator":"equals",
      "type":"string",
      "value":"MANDATE_ALLOCATION_AUDIT"
      },
      {
      "field":"entityId",
      "operator":"equals",
      "type":"string",
      "value": id.toString() 
      }
    ],
      "page":{
      "pageNo":scrollLoad,
      "pageSize":10
    },
      "sorting":{
      "direction":"DESC",
      "field":"createdAt"
    }
  }
try{
  const response = await getMandateAuditLogs(auditLogObj);
  if(response?.status === 200){
    if(scrollLoad > 0){
      setAllocationHistoryList([...allocationHistoryList, ...response.data.result.list[0]])
    }else{
      setAllocationHistoryList(response.data.result.list[0])
    }
    setAllocationHistoryTotalCount(response.data.result.totalRecords)
  }
  }catch(error:any){
    console.log(error)
  }  
}

const handleScroll = (e:any) => {
  const scrollableDiv:any = document.getElementById('scrollableDiv');
  const scrollPosition = scrollableDiv.scrollTop + scrollableDiv.clientHeight;
  const divHeight = scrollableDiv.scrollHeight;
  
  if (Math.round(scrollPosition) + 1 === divHeight) {
    if((allocationHistoryList.length/10 % 1 ) == 0) auditLog(allocationHistoryId, allocationHistoryList.length/10)
  }
}

const getLocationName = (locationIds:any) => {
  const locationNameById:any = []
  locationIds.map((locItem:any) => {
    locationNameById.push(mandateLocationObj[locItem?.mandateLocationId])    
  })
  return locationNameById.join(",")
}

useEffect(() => {
console.log(mandateLocationObj)
},[mandateLocationObj])

    
  return (
    <>
    <Modal
      open={bulkAllocationPopupStatus}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box>
        <Box sx={style} className='popup-wrap'>
        <div className='header-wrapper' style={{paddingRight:"22px"}}>
          <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000', fontWeight:"600" }}>
            Manage Allocation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setBulkAllocationPopupStatus(false)}
          >
            <CloseIcon style={{cursor:"pointer"}} />
          </IconButton>
        </div>
        <Box className="bulkAllocationScrollSect">
        <Box 
        component="form"
        data-testid="formHandle"
        id="mandateForm"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(filterSubmitHandler)}
        className="bulkAllocationAssignSect">
          <Box style={{display:"flex",width:"calc(100% - 100px)",gap:"15px"}}>
          <FormControl fullWidth required className='bulkAllocationAssigneeField' style={{width:"42%"}}>
          <Controller
            control={control}
            name='assignees'
            rules={{
              required: {
                  value: true,
                  message: "Assignees is required",
              }
            }}
            render={({ field: { value, onChange } }) => (
            <Autocomplete
                ref={autoC}                
                multiple
                id="checkboxes-tags-demo"
                className='bulkAllocationAssignee'
                limitTags={1}
                options={assigneeList}
                disableCloseOnSelect
                getOptionLabel={(option:any) => option?.recruiterName}
                onChange={(event, value) => {
                  onChange(value)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Box style={{display:"flex", gap:"5px",alignItems:"center"}}>
                      <img width="40px" style={{borderRadius:"6px",marginRight: "4px"}} src={profileMenu}/>
                      <Box style={{display:"flex",flexDirection:"column",fontSize:"14px",gap:"6px"}}>
                        <span>{option.recruiterName}</span>
                        <span style={{color:"gray"}}>{option.roleName}</span>
                      </Box>
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <>
                  <TextField {...params} required={true} label="Assignees" placeholder="Search and Select Assignee"  />
                  {errors?.assignees && (
                    <span className="reminder_text-danger bulkAllocErrorText" role="alert">
                        <InfoIcon />{errors?.assignees?.message as string}
                    </span>
                  )}
                  <p className='infoSection'><InfoIcon/>You can add upto 50 people at once</p>
                  </>
                )}
              />
            )}
            />
            
          </FormControl>

          <FormControl fullWidth required style={{width:"29%"}}>
              <InputLabel>Location</InputLabel>
              <Controller
                name='selectedLocations'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Location is required',
                  },
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Select
                    label='Location'
                    multiple
                    value={value}
                    defaultValue={null}
                    onChange={(e:any) => {
                      onChange(e.target.value)
                    }}
                    className='location-mandate-dropdown'                    
                  >
                    {selectedMandateLocation?.length &&
                      selectedMandateLocation?.map((loc: any, index: number) => (
                        <MenuItem key={index} value={loc?.id} >
                          <Checkbox checked={value.indexOf(loc?.id) > -1} />
                          {loc?.location}
                        </MenuItem>
                      ))}
                    {selectedMandateLocation?.length === 0 && (
                      <MenuItem disabled={true}>No Location</MenuItem>
                    )}
                  </Select>
                )}
              />
              {errors?.selectedLocations && (
                    <span className="reminder_text-danger bulkAllocErrorText" role="alert">
                        <InfoIcon />{errors?.selectedLocations?.message as string}
                    </span>
                  )}
            </FormControl>

            <FormControl fullWidth required style={{width:"29%"}}>
              <Controller
                  name='assigneeDueDate'
                  // defaultValue={submissionStartDate}
                  // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                  control={control}
                  render={({ field: { onChange, ...restField } }:any) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                      // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                        onChange={onChange}
                        inputFormat='YYYY-MM-DD'      
                        minDate={moment(new Date()).format('YYYY-MM-DD')}                
                        components={{
                          OpenPickerIcon: DueDate,
                        }}
                        renderInput={(params) => (
                          <TextField required={true} {...params} placeholder="Due Date" label={"Due Date"} />
                        )}
                        {...restField}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>
          </Box>
        

              <AppButton
                  style={{ width: 100, marginLeft: "15px", marginRight:"0px" }}
                  variant='contained'
                  bgcolor={PRIMARY2}
                  margin={5}
                  textColor={TERTIARY12}
                  className='class-new-button'
                  onClick={() => {setBulkAllocationPopupStatus(true)}}
                  tooltip='Add Assignee'
                  type='submit'
                >
                  <AddNewIcon style={{ marginRight: '10px' }} /> Add
                </AppButton>
        </Box>

        <Box className="bulkAllocationListBlk">
          <Box className="bulkAllocationListSect">
            <p>Selected Assignees ({allocationList.length})</p>
            {/* reAllocationAPI(null ,"bulk","reallocation") */}
            <Box className="bulkAllocationListAction">
                {(userPermissions?.work_management_module?.view?.reallocate?.selected && userRole != "Admin") && <Tooltip title={"Reallocation"}><img className={`${checkReallDisableStatus ? "allocationDisable" : ""}`} style={{cursor:"pointer"}} onClick={() => {reAllocationAPI(null ,"bulk","reallocation")}} src={Reallocation}/></Tooltip>}
                {(userPermissions?.work_management_module?.view?.deallocate?.selected && userRole != "Admin") && <Tooltip title={"Deallocation"}><img className={`${checkDisableStatus ? "allocationDisable" : ""}`} style={{cursor:"pointer"}} onClick={() => {reAllocationAPI(null ,"bulk","deallocation")}} src={Deallocation}/></Tooltip>}
                {(userRole == "Admin") && <Tooltip title={"Reallocation"}><img className={`${checkReallDisableStatus ? "allocationDisable" : ""}`} style={{cursor:"pointer"}} onClick={() => {reAllocationAPI(null ,"bulk","reallocation")}} src={Reallocation}/></Tooltip>}
                {(userRole == "Admin") && <Tooltip title={"Deallocation"}><img className={`${checkDisableStatus ? "allocationDisable" : ""}`} style={{cursor:"pointer"}} onClick={() => {reAllocationAPI(null ,"bulk","deallocation")}} src={Deallocation}/></Tooltip>}
            </Box>
          </Box>
          <Box className="bulkAllocationTableSect">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>                  
                    <TableCell>
                      <Checkbox checked ={checkAll} onChange={(e:any) => {allCheckStatus(e.target.checked)}} {...{ inputProps: { 'aria-label': 'Checkbox demo' } }} />
                    </TableCell>
                    <TableCell style={{minWidth:"270px"}}>
                      <p>RECRUITERS
                      <span style={{display:"flex",cursor:"pointer"}} onClick={() => {sortingColumn("recuriter")}}>{sortingState?.recuriter == null ? <SortUpDown /> : sortingState?.recuriter == true ? <SortUp /> : <SortDown />}</span></p>
                    </TableCell>
                    <TableCell style={{minWidth:"230px"}}>
                      <p>LOCATION
                      <span style={{display:"flex",cursor:"pointer"}} onClick={() => {sortingColumn("location")}}>{sortingState?.location == null ? <SortUpDown /> : sortingState?.location == true ? <SortUp /> : <SortDown />}</span></p>
                    </TableCell>
                    <TableCell style={{minWidth:"240px"}}>
                      <p>DUE DATE
                      <span style={{display:"flex",cursor:"pointer"}} onClick={() => {sortingColumn("dueDate")}}>{sortingState?.dueDate == null ? <SortUpDown /> : sortingState?.dueDate == true ? <SortUp /> : <SortDown />}</span></p>
                    </TableCell>
                    <TableCell style={{minWidth:"0px"}}>
                      <p style={{visibility:"hidden"}}>DUE DATE
                      <SortUpDown /></p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    <OutlinedInput				
                            style={{width:"230px"}}														
														onChange={(e:any) => {getAllocationList(selectedMandateId?.value,e.target.value,"recruiter")}}
														startAdornment={
															<InputAdornment position="start">
																<SearchIcon />
															</InputAdornment>
														}
													/>
                    </TableCell>
                    <TableCell>
                    <OutlinedInput				
                            style={{width:"210px"}}										
														onChange={(e:any) => {getAllocationList(selectedMandateId?.value,e.target.value,"location")}}
														startAdornment={
															<InputAdornment position="start">
																<SearchIcon />
															</InputAdornment>
														}
													/>
                    </TableCell>
                    <TableCell>
                    <OutlinedInput				
                            style={{width:"200px"}}												
														onChange={(e:any) => {getAllocationList(selectedMandateId?.value,e.target.value,"dueDate")}}
														startAdornment={
															<InputAdornment position="start">
																<SearchIcon />
															</InputAdornment>
														}
													/>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                        {allocationList && allocationList.length ? 
                        <>
                        {allocationList?.map((user: any, index: number) => (
                          <>
                          <TableRow className={!user?.userActiveStatus ? "bulkAllocationListDisable" : ""}>
                            <TableCell>
                              <Checkbox checked={user?.checkedStatus} onChange={(e:any) => {allocationStatusChange(index,"check",e.target.checked)}} {...{ inputProps: { 'aria-label': 'Checkbox demo' } }} />
                            </TableCell>
                            <TableCell>
                            <Box style={{display:"flex", gap:"5px",alignItems:"center"}}>
                              <img width="40px" style={{borderRadius:"6px",marginRight: "4px"}} src={profileMenu}/>
                              <Box style={{display:"flex",flexDirection:"column",fontSize:"14px",gap:"6px"}}>
                                <p style={{margin:"0px",display:"flex",alignItems:"center"}}><Tooltip title={user?.allocationUserName}><span style={{maxWidth:'180px'}} className='ellipse1line'>{user?.allocationUserName}</span></Tooltip><AllocationTooltipIcon onClick={(event:any) => {historyPopup(event,user?.allocationId)}} style={{marginLeft:"5px",cursor:"pointer"}}/></p>
                                <span style={{color:"gray"}}>{user?.allocationUserRole}</span>
                              </Box>
                            </Box>
                            </TableCell>
                            <TableCell>
                            <FormControl fullWidth required className='bulkAllocationListLocation'>
                              <InputLabel id="demo-simple-select-label">Location</InputLabel>
                              <Select                                                      
                                label='Location'
                                multiple
                                value={user?.allocationLocation}
                                style={{width: "210px"}}
                                onChange={(e:any) => {
                                  allocationStatusChange(index,"location",e.target.value)
                                }}
                              >
                                {selectedMandateLocation?.length &&
                                  selectedMandateLocation?.map((loc: any, index: number) => (
                                    <MenuItem disabled={(loc?.filledJoCount >= loc?.jobOrderCount)} key={index} value={loc?.id} >
                                      <Checkbox checked={user?.allocationLocation.indexOf(loc?.id) > -1} />
                                      {loc?.location}
                                    </MenuItem>
                                  ))}
                                {selectedMandateLocation?.length === 0 && (
                                  <MenuItem disabled={true}>No Location</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                            </TableCell>
                            <TableCell>                                
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        className='bulkAssigneeListDatePicker'
                                        value={dayjs(user?.dueDate)}
                                        onChange={(e:any) => {
                                          allocationStatusChange(index, "dueDate", e)
                                        }}
                                        inputFormat='YYYY-MM-DD'   
                                        minDate={moment(new Date()).format('YYYY-MM-DD')}                   
                                        components={{
                                          OpenPickerIcon: DueDate,
                                        }}
                                        renderInput={(params) => (
                                          <TextField required style={{width: "200px"}} {...params} placeholder="Due Date" label={"Due Date"} />
                                        )}
                                      />
                                    </LocalizationProvider>
                            </TableCell>
                            <TableCell>
                                {user?.allocationStatus == 64 && <Tooltip title={'Deallocation'}><DeallocationSingle onClick={() => {reAllocationAPI(index,"single","deallocation")}} style={{cursor:"pointer"}}/></Tooltip>}
                                {user?.allocationStatus == 65 && <Tooltip title={'Reallocation'}><ReallocateSingleIcon onClick={() => {reAllocationAPI(index,"single","reallocation")}} style={{cursor:"pointer"}}/></Tooltip>}
                            </TableCell>
                          </TableRow>
                          </>
                        ))}
                        </>
                        : <>
                          {renderEmptySearchBox()}
                        </>
                          }
                    
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        </Box>
        
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '20px',
              gap: '15px',
              paddingLeft: "32px",
              paddingRight:"22px"
            }}
          >
            <AppButton
              bgcolor='#E5E8EB'
              onClick={() => setBulkAllocationPopupStatus(false)}
              textColor='#000'
              fontSize='14px'
              fontWeight={400}
              label='Cancel'
              width='96px'
            ></AppButton>
            <AppButton
              bgcolor='#002882'
              textColor='#ffffff'
              fontSize='14px'
              fontWeight={400}
              label={"Update"}
              width='96px'
              disabled={updateStatusBtn}
              onClick={() => allocationUpdate()}
              style={{color:"#fff"}}
            ></AppButton>
          </Box>
        </Box>
        <AppErrorHandlerModal
          open={false}
          content={""}
          handleClose={() => {}}
          factor='Success'
          ButtonText='Done'
        ><></></AppErrorHandlerModal>
        <Box>
          <Snackbar
          open={false}
          autoHideDuration={5000}
          onClose={() => {}}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          >
            <Alert onClose={() => {}} severity='error' sx={{ width: '100%' }} variant='filled'>
              {""}
            </Alert>
          </Snackbar>
        </Box>
      </Box>      
    </Modal>
    {confirmationPopupOpen && <BulkAllocationConfirmationPopup 
      title={'Warning'} 
      subtitle={`Are you sure you want to ${confirmationPopupTitle}`}
      isOpen={confirmationPopupOpen} 
      handleClose={() => {setConfirmationPopupOpen(false);setConfirmationPopuRequestObj({});setConfirmationPopupTitle('')}} 
      OkButtonText={confirmationPopupTitle} 
      handleConfirm={() => {confirmAllocationAPI(null)}}
      />}

    {reSubmissionPopupOpen && <BulkAllocationConfirmationPopup 
      title={'Confirmation'} 
      subtitle={`Submissions was already started ${selectedUserName != "" ? `by ${selectedUserName}` : 'for Below recuriters'} do want to deallocate ?`}
      isOpen={reSubmissionPopupOpen} 
      handleClose={() => {setReSubmissionPopupOpen(false);setConfirmationPopuRequestObj({});setConfirmationPopupTitle('')}} 
      cancelButtonText={"No"}
      listedError={selectedUserError}
      OkButtonText={"Yes"} 
      handleConfirm={() => {confirmAllocationAPI("confirmation")}}
      />}

    {assigneeErrorPopupOpen && <BulkAllocationConfirmationPopup 
      title={'Error'} 
      subtitle={'Some of the selected location are already assigned to below recruiters.'}
      isOpen={assigneeErrorPopupOpen} 
      handleClose={() => {setAssigneeErrorPopupOpen(false)} }
      cancelButtonText={"Cancel"}
      listedError={assigneeErrorPopupMsg}
      OkButtonText={"null"} 
      handleConfirm={() => {}}
      locationObj = {mandateLocationObj}
      />}
        <Popover
            open={historyPopupOpenStatus}
            anchorEl={anchorEl}
            onClose={() => {setHistoryPopupOpenStatus(false)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box className="allocationHistoryTableSect">
              <h3>Allocation History ({allocationHistoryList.length})</h3>
              <Box className="allocationHistoryScrollSect" id="scrollableDiv" onScroll={handleScroll}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width:"200px"}}>
                        ACTION BY
                      </TableCell>
                      <TableCell style={{width:"120px",paddingLeft:"0px"}}>
                        LOCATION
                      </TableCell>
                      <TableCell style={{width:"150px"}}>
                        ACTION
                      </TableCell>
                      <TableCell style={{width:"200px"}}>
                        DATE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {allocationHistoryList && allocationHistoryList.length > 0 ? 
                        <>
                        {allocationHistoryList?.map((userHistory: any, index: number) => (
                          <>
                          <TableRow>
                            <TableCell>
                              <Box style={{display:"flex", gap:"5px",alignItems:"center"}}>
                                <img width="40px" style={{borderRadius:"6px",marginRight: "4px"}} src={profileMenu}/>
                                <Box style={{display:"flex",flexDirection:"column",fontSize:"14px",gap:"6px"}}>
                                  <p style={{margin:"0px",display:"flex",alignItems:"center"}}><span style={{maxWidth:'180px'}} className='ellipse1line'>{userHistory?.userEntity?.firstName}</span></p>
                                  <span style={{color:"gray"}}>{userHistory?.userEntity?.roleName}</span>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                            <p style={{width: "120px",wordWrap: "break-word"}}>{userHistory?.eventData?.mandateLocations != null && getLocationName(userHistory?.eventData?.mandateLocations)}</p>
                            </TableCell>
                            <TableCell>
                              <p>{userHistory?.eventData?.status == 65 ? "Deallocated" : "Allocated"}</p>
                            </TableCell>
                            <TableCell>
                              <p>{convertToDisplayDateTime(userHistory?.createdAt)}</p>
                            </TableCell>
                          </TableRow>
                          </>
                        ))
                      }
                      </>
                    : <></>}
                  </TableBody>
                </Table>
              </TableContainer>
              </Box>
              
            </Box>
          </Popover>
    </>
  )
}
export default BulkAllocationPopup
