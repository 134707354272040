/** @jsxImportSource @emotion/react */
import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import ForgotPasswordComponent from '../components/Login/ForgotPassword/ForgotPassword'

function ForgotPassword() {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className='login-page-container'>
        <ForgotPasswordComponent />
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword
