import React, { useEffect, useRef, useState } from 'react'
import { Paper, Box, Divider, Typography, Grid, Stack, Button, MenuItem } from '@mui/material'
import './Filter.css'
import { AppSelect, Renderplaceholder } from '../../AppComponents/AppSelect'
import {
  AppAutoComplete,
  AutocompleteOptionData,
} from '../../AppComponents/AppAutoComplete/AppAutoComplete'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../utils/constants'
import { TaskSearch } from '../Search'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg'
import { ReactComponent as FunnelIcon } from '../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as AddNewIcon } from '../../../assets/icons/add-new.svg'
import { ReactComponent as RotateIcon } from '../../../../src/assets/icons/reset.svg'
// import { ReactComponent as ShowMore } from '../../../assets/icons/showmore.svg'
import { AppButton } from '../../AppComponents/AppButton'
import { TaskModal } from '../TaskModal'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import _ from 'lodash'
import { ListState } from '../../../pages/MyTasks'
import { resetFilters, setFilterValues } from '../../../features/filters/FilterReducer'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
// import { SelectChangeEvent } from '@mui/material/Select'
interface ISelectForm {
  name?: { value: string; label: string }
  role?: string | string[]
}

const TaskFilterComponent = ({assignedToUsers, totalRecords, onCreateSuccess, allRoles, mandateOptions}: {assignedToUsers:[], totalRecords:number, onCreateSuccess: () => void,allRoles:[], mandateOptions: any}) => {
  const [open, setOpen] = useState(false)
  const [filterChecked, setfilterChecked] = useState(false)
  const [filterRole, setFilterRole] = useState<string[]>([])

  const defaultValues: ISelectForm = {
  }
  const dispatch = useAppDispatch()
  const filters = useAppSelector((state) => state.filtersReducer.filters)

  const { handleSubmit, control, setValue, reset } = useForm<ISelectForm>({ defaultValues })

  // submit filter handler
  const filterSubmitHandler: SubmitHandler<ISelectForm> = (filterValues: ISelectForm) => {
    dispatch(setFilterValues(filterValues)) 
  }

  const handleFilter = () => {
    setfilterChecked((prev) => !prev)
  }

  const resetAllFilters = (e:any) => {
    reset();
    dispatch(resetFilters());
  }

  // modal open and close event
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleRoleChange = (event: any) => {
    const {
      target: { value },
    } = event
    setFilterRole(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }
  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ]

  // const options: AutocompleteOptionData[] = [
  //   { value: '1', label: 'Test' },
  //   { value: '2', label: 'Sample' },
  // ]

  const options: AutocompleteOptionData[] = _.map(assignedToUsers, (assignedToUser:any) => {
    return {
      value: _.get(assignedToUser, 'id'),
      label: _.get(assignedToUser, 'firstName'),
    }
  });

  const roleOptions: AutocompleteOptionData[] = _.map(allRoles, (allRoles:any) => {
    return {
      value: _.get(allRoles, 'id'),
      label: _.get(allRoles, 'name'),
    }
  });
  useEffect(() => {
    setValue("name",filters.name)
  }, [filters.name])

  const FilterForms = () => {
    return (
      <>
        <Divider />
        <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
          <Grid item xs={12} md={12} sm={3} lg={12}>
            <Typography className='filter-title'> Filter</Typography>
          </Grid>
          <Box component={'form'} onSubmit={handleSubmit(filterSubmitHandler)}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={3}>
                <Controller
                  control={control}
                  name='name'
                  render={({ field }) => (
                    <AppAutoComplete
                      {...field}
                      id='name-email-popper'
                      label='Name'
                      options={options}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <Controller
                  control={control}
                  name='role'
                  render={({ field }) => (
                    <AppAutoComplete
                      {...field}
                      id='name-email-popper'
                      label='role'
                      options={roleOptions}
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Stack direction='row'>
                    <Box className='filter-search-btn'>
                      <AppButton
                        label='Search'
                        bordercolor='black'
                        bgcolor='black'
                        size='large'
                        type='submit'
                        className='search-bth'
                        sx={{ color: '#fff' }}
                      />
                    </Box>
                    <Button
                      sx={{
                        color: '#002882',
                        textTransform: 'none',
                      }}
                      variant='text'
                      onClick={resetAllFilters}
                    >
                      <RotateIcon />
                      <Typography className='reset-label'>Reset All</Typography>
                    </Button>
                  </Stack>
                {/* <Stack direction='row'>
                  <AppButton
                      label='Search'
                      bordercolor='black'
                      bgcolor='black'
                      size='large'
                      name="filter-submit"
                      className="filter-search-button"
                      type='submit'
                    />
                  <Button
                    sx={{
                      color: '#002882',
                      textTransform: 'none',
                    }}
                    variant='text'
                    onClick={resetAllFilters}
                    className="filter-reset-button"
                  >
                    <RotateIcon />
                    <span className='filter-reset-label'>Reset All</span>
                  </Button>
                </Stack> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Paper className='box-style' elevation={1}>
        <Box display='flex' justifyContent='space-between' className='filter-parent-box'>
          <Box className='tasklist'>
            <Typography className='tasklist-title'>
              Task List
              {!!totalRecords && <span className='tasklist-count'>({totalRecords})</span>}
            </Typography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <TaskSearch />
            <Divider orientation='vertical' flexItem variant={'middle'} />
            <Box>
              {/* <AppButton variant='contained' bgcolor={PRIMARY3} margin={5} minwidth={40}>
                {' '}
                <UploadIcon />{' '}
              </AppButton> */}
              <AppButton
                variant='contained'
                bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                margin={5}
                minwidth={40}
                onClick={handleFilter}
                textColor={filterChecked ? TERTIARY12 : PRIMARY3}
              >
                {' '}
                <FunnelIcon className='add-new-icon' />{' '}
                {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </AppButton>
              <AppButton
                variant='contained'
                bgcolor={PRIMARY2}
                margin={5}
                textColor={TERTIARY12}
                width={'80px'}
                onClick={handleClickOpen}
              >
                <AddNewIcon className='add-new-icon' /> New
              </AppButton>
              {open && <TaskModal onCreateSuccess={onCreateSuccess} assignedToUsers={assignedToUsers} open={open} handleClose={handleClose} title='Create New Task' mandateOptionsList={mandateOptions} />}
              {/* <AppButton variant='text'>
                <ShowMore />
              </AppButton> */}
            </Box>
          </Box>
        </Box>

        {filterChecked && (
          <Box>
            <FilterForms />
          </Box>
        )}
      </Paper>
    </>
  )
}

export const TaskFilter = React.memo(TaskFilterComponent);