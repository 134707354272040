import React, { useEffect, useState, useRef,useContext } from "react";
import './Popupwindow.scss'
import { Box, Typography, Button, Stack, TextField, Avatar, InputAdornment, IconButton } from "@mui/material";
import DraftsIcon from '@mui/icons-material/Drafts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as CopyIcon } from '../../assets/icons/copyIcon.svg'
import { aiChatMessage, getAIChatHistory } from '../../services/ChatGptService'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as SmartFlowIcon } from '../../assets/icons/CallFrame.svg'
import { ReactComponent as ChatGptIcon } from '../../assets/icons/chatgpt-icon.svg'
import { ReactComponent as HelperDeskIcon } from '../../assets/icons/HelperDesk.svg'
import { ReactComponent as SendIcon } from '../../assets/icons/gpt-send.svg'
import { ReactComponent as AiPopOpen } from '../../assets/icons/ai-chat-pop.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as BotLogo } from '../../assets/icons/chatbot-logo.svg'
import { ReactComponent as ResetLogo } from '../../assets/icons/reset.svg'
import { ReactComponent as ExpandIcon } from '../../assets/icons/popupexpand.svg'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setGptThreadId, resetGptThreadId } from '../../features/chatGpt/ChatGptReducer'
import { reset, quesPersist, isEndPersist, minimize, maximize } from "../../features/chatGpt/minimizeReducer";
import useOnClickOutside from "../../utils/outsideClickHook";
import LoadMoreIcon from "../../assets/images/loadMoreIcon.svg";
import moment from 'moment';
import CloseMaximizeIcon from "../../assets/icons/closeMaximize.svg";
import MinimizeIcon from "../../assets/icons/minimizeIcon.svg";
import MaximizeSendIcon from "../../assets/icons/maximizeSend.svg";
import GenericGptIcon from "../../assets/icons/genericChatGPT.svg";
import socketContext from '../../contexts/SocketContext/SocketContext'
import {getNotificationCount} from "../../services/NotificationService"
import {resetConfettiNotification, setCallNotification, setConfettiNotification, setNotificationCount} from "../../features/notification/notificationReducer";
import { convertToDisplayDateTimeSecond } from '../../utils/date-utils';
const Popupwindow = () => {
    const ModalRef = useRef(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const MinimizeRedux = useAppSelector((state) => state.minimizeState)
    const LoaderRedux = useAppSelector((state) => state?.loaderState )
    const gptRedux = useAppSelector((state) => state.chatGPT)
    const [dailougeOpen, setDailougePpen] = useState<boolean>(false)
    const [chatData, setChatData] = useState<any[]>([])
    const [threadId, setThreadId] = useState<string>('')
    const [quesId, setQuesId] = useState<number>(0)
    const [textSearch, setTextSearch] = useState<string>("")
    const [isEndId, setIsEndId] = useState<number>(0)
    const [regeneratePayload, setRegeneratePayload] = useState<any>(gptRedux?.regenerate ? gptRedux?.regenerate : {})
    const [pageNo, setPageNo] = useState<number>(-1);
    const [totalPages, setTotalPages] = useState<number>(1)
    const [loadOld, setLoadOld] = useState(true);
    const [maximizeState, setMaximizeState] = useState(false);
    const [openChatId,setOpenChatId] = useState(0);
    const [historyId,setHistoryId] = useState("");
    const [previousLocation,setPreviousLocation] = useState("")
    const userState = useAppSelector((state) => state?.UserDetail?.userId)
    const stompClient:any = useContext(socketContext)
    const callNotificationTimer = useAppSelector((state) => state?.SystemConfig?.smartFlowIncomingData)


    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const pathVal = location?.pathname?.split('/')
    const isMandateView = (pathVal[1] === "mandates") && !!location.search

    const clickopen = () => {
        setDailougePpen(true)
        if(quesId===0 && chatData?.length<=0&&openChatId===0){
            getInitialApiCall();
        }
    }
    useEffect(()=>{
    if((pathVal[1] === "mandates") && !!location.search){
        setChatData([])
        setPreviousLocation("mandate_detail")
        setQuesId(0)
        setOpenChatId(0)
        setPageNo(-1)
        setTotalPages(1)
        setHistoryId("")
    }
    else if(location?.search==="" && previousLocation==="mandate_detail"){
        setChatData([])
        setPreviousLocation("mandates_list")
        setQuesId(0)
        setOpenChatId(0)
        setPageNo(-1)
        setTotalPages(1)
        setHistoryId("")
    }
    },[location])
    const clickclose = (type?:string) => {
        setDailougePpen(false)
        if(type===undefined){
            setChatData([])
        }
        setQuesId(0)
        setIsEndId(0);
        setMaximizeState(false)
    }
    const handleButtonClose = () =>{
        setDailougePpen(false)
    }
    const getChatHistory = async (page: number, scrollDown: boolean) => {
        const screen = location?.pathname?.split('/')
        let filtersReq = [
            {
                "field": "noOfDays",
                "operator": "equals",
                "type": "string",
                "value": 7
            },
            {
                "field": "moduleScreen",
                "operator": "equals",
                "type": "string",
                "value": screen[1] === "mandates" ? location.search ? "mandate_detail" : "mandates" : screen[1]
            },
            {
                "field": "id",
                "operator": "lt",
                "type": "number",
                "value": historyId
            }
        ]
        if(screen[1] != "mandates" && !location?.search){
            filtersReq = await filtersReq.filter((fil:any)=>fil?.field!="moduleScreen")
        }
        let finalArr:any;
        if(historyId===""){
            finalArr = filtersReq.filter((val:any)=>val?.field!="id")
        }
        else if(historyId){
            finalArr = filtersReq
        }
        const reqObj = {
            filters:finalArr?.length ?finalArr:filtersReq,
            page: {
                pageNo: page,
                pageSize: 10
            },
            sorting: {
                direction: "DESC",
                field: "createdAt"
            }
        }
        try {
            const res = await getAIChatHistory(reqObj);
            let resData;
            if (res?.status === 200) {
                resData = res?.data?.result?.data.map((ele: any) => {
                    return {
                        type: ele?.msgFrom === "Bot" ? "ChatBot" : "userText",
                        data: ele?.msgFrom === "Bot" ? [ele] : { text: ele?.contentText, sendAt: convertToDisplayDateTimeSecond(ele?.sendAt)}
                    }
                })
                setChatData([...resData,...chatData,])
            }
            setTotalPages(res?.data?.result?.totalPages)
        } catch (error) {
            console.log(error)
            chatAPIMessage()
        } finally {
            if (scrollDown) {
                const element: any = document.getElementById('chat-scroll')
                setTimeout(() => {
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: 'smooth',
                    })
                }, 100)
            }
        }
    }
    const CopyToClipboard = async (containerid: any) => {
        const text: any = document.getElementById(containerid)?.textContent
        try {
            await navigator.clipboard.writeText(text)
            console.log('Content copied to clipboard')
        } catch (err) {
            console.error('Failed to copy: ', err)
        }
    }

    const chatAPIMessage = async (searchText = "", userObj: any = null, qId = quesId, list: any = null) => {
        const screen = location?.pathname?.split('/')
        let reqObj: any = {
            questionId: qId ?? 0,
            moduleScreen: screen[1] === "mandates" ? location.search ? "mandate_detail" : "mandates" : screen[1],
            isEnd: MinimizeRedux?.isEnd === true ? 1 : list?.isEnd ? list?.isEnd : isEndId ?? 0,
            message: searchText,
            threadId:quesId===0?"":threadId,
            openChatEnabled:openChatId
        }
        if (list?.requiredData) {
            const data = JSON.parse(list?.requiredData)
            const answers: any = {};
            Object.keys(data).forEach(x => answers[x] = document.getElementById(data[x])?.textContent)
            reqObj = { ...reqObj, answers }
        }
        try {
            const res = await aiChatMessage(reqObj)
            if (res?.data?.result?.data[0]?.openChatEnabled > 0) {
                setRegeneratePayload(reqObj)
            } else {
                setRegeneratePayload({})
            }
            if(res?.data?.result?.historyId && historyId===""){
                setHistoryId(res?.data?.result?.historyId)
            }
            if (res?.status === 200) {
                const ansObj = {
                    type: "ChatBot",
                    data: res?.data?.result?.data
                }
                if (threadId === '') {
                    setThreadId(res?.data?.result?.threadId)
                }
                if (res?.data?.result?.data[0]?.contentType === "question") {
                    setQuesId(res?.data?.result?.data[0]?.id)
                }
                if(res?.data?.result?.data[0]?.openChatEnabled){
                    setOpenChatId(res?.data?.result?.data[0]?.openChatEnabled)
                }
                else{
                    setOpenChatId(0)
                }
                if (!MinimizeRedux?.isEnd) {
                    setIsEndId(res?.data?.result?.data[0]?.isEnd)
                }
                setTextSearch('')
                userObj ? setChatData([...chatData, userObj, ansObj]) : setChatData([...chatData, ansObj])
            }
        } catch (error) {
            console.log(error)
        } finally {
            const element: any = document.getElementById('chat-scroll')
            setTimeout(() => {
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100)
        }
    }

    const regenerateChatCall = async () => {
        const reqObj = { ...regeneratePayload }
        try {
            const res = await aiChatMessage(reqObj)
            if (res?.status === 200) {
                const ansObj = {
                    type: "ChatBot",
                    data: res?.data?.result?.data
                }
                if (threadId === '') {
                    setThreadId(res?.data?.result?.threadId)
                }
                setQuesId(res?.data?.result?.data[0]?.id)
                setIsEndId(res?.data?.result?.data[0]?.isEnd)
                setTextSearch('')
                setChatData([...chatData, ansObj])
            }
        } catch (error) {
            console.log(error)
        } finally {
            const element: any = document.getElementById('chat-scroll')
            setTimeout(() => {
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100)
        }
    }

    const getInitialApiCall = () => {
        if (MinimizeRedux?.questionId === 0) {
            chatAPIMessage()
        }
        else if (MinimizeRedux?.questionId != 0) {
            getChatHistory(MinimizeRedux?.pageNo != -1 ? MinimizeRedux?.pageNo : 0, true)
        }
    }

    const handleUserText = (searchText: any, qId: number, list: any) => {
        const obj: any = {
            type: "userText",
            data: { text: searchText, sendAt: convertToDisplayDateTimeSecond(list?.sendAt)}
        }
        chatAPIMessage(searchText, obj, qId, list)
    }

    const handleFetchMandate = () => {
        const mandateName = document.getElementById("ai-chatbot-manName")?.textContent
        const manId = document.getElementById("ai-chatbot-manId")?.textContent
        return (
            <Box>
                <Typography className="header-text header-text-maximize">{mandateName ? mandateName : "ChatGpt"}</Typography>
                <Typography className="mandateid-text" style={maximizeState ? { color: "#FFFFFF", fontSize: "10px", marginTop: "2px", marginBottom: "5px" } : {}}>{manId}</Typography>
            </Box>
        )
    }

    const handleExpandPopup = () => {

        setMaximizeState(true)

        // old code. Dont Delete

        // const screen = location?.pathname?.split('/');
        // const obj = {
        //     threadId: threadId,
        //     regenerate: regeneratePayload
        // }
        // const maximizeObj = {
        //     threadId: threadId,
        //     quesId: quesId,
        //     isEndId: isEndId === 1 ? 1 : 0,
        //     pageNo: pageNo,
        //     previousModule: screen[1] === "mandates" ? location.search ? "mandate_detail" : "mandates" : screen[1]
        // }
        // dispatch(setGptThreadId(obj))
        // dispatch(maximize(maximizeObj))
        // clickclose()
        // navigate("/chatgpt")
    }
    const handleMinimizePopup = () => {
        setMaximizeState(false)
        const element: any = document.getElementById('chat-scroll')
        setTimeout(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth',
            })
        }, 100)
    }
    const handleLoadOld = () => {
        setPageNo((prev) => prev + 1)
        getChatHistory(pageNo + 1, false)
    }
    const handleLoadNew = () => {
        if (pageNo >= 1) {
            setPageNo((prev) => prev - 1);
            getChatHistory(pageNo - 1, false)
        }
    }
    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        const isAtTop = scrollTop === 0;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
        if (isAtTop) {
            // Scrolled near the top, load more older messages
        } else if (isAtBottom) {
            // handleLoadNew()
        }

    };
    // useEffect(() => {
    //     old code will use if requirement changes dont delete

    //     if (location?.pathname != "/chatgpt" && location?.pathname != "/dashboard" && location?.pathname != `/${MinimizeRedux?.previousModule}`) {
    //         dispatch(reset())
    //         dispatch(resetGptThreadId())
    //     }

    //     setMaximizeState(false)
    //     clickclose()
    //     dispatch(reset())
    //     setTotalPages(1)
    //     setPageNo(-1)
    // }, [location])

    const renderAiContent = (data: any, indexID: number,dataIndex:any) => {
        return (
            <Box className="ai-chat-wrap">
               {
                data?.length > 0 &&
                <Avatar style={{ width: "30px", height: "30px" }} className={data[dataIndex]?.openChatEnabled===2?"addBorder":""}>
                    {
                        data[dataIndex]?.openChatEnabled ? (
                            <img src={GenericGptIcon} style={{ width: "30px", height: "30px" }} />
                        ) : (
                            <BotLogo style={{ width: "30px", height: "30px" }} />
                        )
                    }
                </Avatar>
               }
                
                <Box className="chat-text-wrap" >
                    {data?.map((list: any, index: number) => {
                        const dataType = list?.contentType
                        return (
                            <div key={index} className="msg-cont">
                                {dataType === "button" ?
                                    <div className={`chat-btn ${list?.contentText.split(" ").slice(0, 2).join("-")}`} onClick={() => { setQuesId(list?.id); handleUserText(list?.contentText, list?.id, list) }} >
                                        {list?.contentText}
                                    </div> : <div className="answer-conatiner">
                                        <div className='date-text-cls'>{convertToDisplayDateTimeSecond(list?.sendAt)}</div>
                                        <div style={{display:"flex",flexDirection:"row",alignItems:"flex-start"}}>
                                        <div className="chat-text" id={`copiyable${indexID}`} style={{ whiteSpace: 'pre-line' }}>{list?.contentText}</div>
                                        {list?.id === null && <div className="copy-icon-container" onClick={() => CopyToClipboard(`copiyable${indexID}`)} style={{marginLeft:"17px"}}><CopyIcon /></div>}
                                        </div>
                                    </div>}

                            </div>
                        )
                    })}
                </Box>
            </Box>
        )
    }
    useEffect(() => {
        if (MinimizeRedux?.minimize === true) {
            clickopen()
        }
        if (MinimizeRedux.questionId) {
            setQuesId(MinimizeRedux.questionId)
        }
        if (MinimizeRedux?.isEndId === 1) {
            setIsEndId(1)
        }
    }, [MinimizeRedux])
    const handleNotificationCount = async() =>{
        if(userState){
            const reqObj = {
                "userId":userState
            }
        const response  = await  getNotificationCount(reqObj)
        if(response && response?.result?.data){
            dispatch(setNotificationCount(response?.result?.data))
        }
        }
    }
    const handleClickOutside = (event: MouseEvent) => {
        if(LoaderRedux?.loading===false){
            setDailougePpen(false)
            setMaximizeState(false)
        } 
    };

    useOnClickOutside(ModalRef, handleClickOutside);
    useEffect(()=>{
        if(stompClient){
            const res =  stompClient.subscribe('/topic/notification/received', function(obj:any){
                const stompClientObj = JSON.parse(obj.body)
                if(stompClientObj.messageTo.includes(userState))handleNotificationCount()
            });
            return () => {
                res.unsubscribe()
              }
        }
    },[stompClient,userState])
    const smartFlowInfo:any = useAppSelector((state) => state?.smartflow.agentState)
    const checkNumberMatch = (userNumber:string, smartFlowNumber: any) => {
        const matchedNumber = smartFlowNumber != null ? smartFlowNumber.filter((item:any) => item?.did.replace("+","") == userNumber) : []
        return matchedNumber 
    }
    const agentReceiverCall = () => {        
        const res =  stompClient.subscribe('/topic/call/agent/received', function(data:any){
            if((checkNumberMatch(JSON.parse(data.body).call_to_number , smartFlowInfo?.callId)).length > 0){
                dispatch(setCallNotification({openState: true, userInfo: JSON.parse(data.body)}))
                setTimeout(() => {
                    dispatch(setCallNotification({openState: false, userInfo: ""}))
                },(callNotificationTimer && callNotificationTimer*1000))
            }
            
        });
        return () => {
            res.unsubscribe()
            dispatch(setCallNotification({openState: false, userInfo: ""}))
        }
    }
    const agentAnswerCall = () => {
        const res1 =  stompClient.subscribe('/topic/call/agent/answered', function(data:any){
            if((checkNumberMatch(JSON.parse(data.body).call_to_number , smartFlowInfo?.callId)).length > 0){
                dispatch(setCallNotification({openState: false, userInfo: ""}))
            }
            });
            return () => {
                res1.unsubscribe()
            }
    }
    const agentMissedCall = () => {
        const res1 =  stompClient.subscribe('/topic/call/agent/missed', function(data:any){
            if((checkNumberMatch(JSON.parse(data.body).call_to_number , smartFlowInfo?.callId)).length > 0){
                dispatch(setCallNotification({openState: false, userInfo: ""}))
            }
            });
            return () => {
                res1.unsubscribe()
            }
    }
    const candidateConfettiNotification = () => {   
        const res1 =  stompClient.subscribe('/topic/alert/notification/received', function(data:any){
            dispatch(setConfettiNotification({confettiOpen:true,confettiUserInfo:[JSON.parse(data?.body)],confettiAdditionStatus:false}))
            });
            return () => {
                res1.unsubscribe()
                dispatch(resetConfettiNotification())
            }
    }
    useEffect(()=>{
        if(stompClient){
            agentReceiverCall()
            agentAnswerCall()
            agentMissedCall()
            candidateConfettiNotification()
        }
    },[stompClient])
    useEffect(()=>{
        const element: any = document.getElementById('chat-scroll')
        if(element && dailougeOpen && quesId!=0){
            setTimeout(() => {
                element.scrollTo({
                    top: element.scrollHeight,
                    behavior: 'smooth',
                })
            }, 100)
        }
    },[dailougeOpen])
    return (
        <>
            <div className={`${maximizeState ? "overlay" : ""}`}>
                <Box className="ai-support-container" ref={ModalRef}>
                    <Box>
                        {
                            dailougeOpen &&
                            <Box className={` ${maximizeState ? "chat-container maximize-cls" : "chat-container"}`} style={{ height: isMandateView ? "590px" : "574px",maxHeight: "80vh" }}>
                                <Box className="header-wrapper header-cls-maximize">
                                    {isMandateView ? handleFetchMandate()
                                        : <Box>
                                            <Typography className="header-text header-text-maximize">ChatGPT</Typography>
                                        </Box>
                                    }
                                    {
                                        maximizeState ? <div style={{ display: "flex", alignItems: "flex-start" }}>
                                            <img src={CloseMaximizeIcon} style={{ marginRight: "13px", cursor: "pointer" }} onClick={()=>clickclose("minimize")} />
                                            <img src={MinimizeIcon} style={{ marginTop: "2px", cursor: "pointer" }} onClick={handleMinimizePopup} />
                                        </div> : <ExpandIcon onClick={handleExpandPopup} />
                                    }

                                </Box>
                                <Box id='chat-scroll' className="conversation-container"
                                    ref={containerRef} onScroll={handleScroll}>
                                    {
                                        loadOld && pageNo < (totalPages - 1) && <div onClick={handleLoadOld} className='load-text-cls'>
                                            <div className='font-cls'>Load More</div>
                                            <img src={LoadMoreIcon} />
                                        </div>
                                    }
                                    <Box className="chat-wrapper">
                                        {chatData?.map((chat: any, index: number) => {
                                            return (
                                                <Box key={index} style={{ width: "100%" }}>
                                                    {chat?.type === "ChatBot" && renderAiContent(chat?.data, index,chat?.data?.map((val:any,index:number)=>index))}
                                                    {chat?.type === "userText" && <div className="user-text-wrapper" >
                                                        <div className='chat-col-cls'>
                                                            <div className='date-text-cls'>{chat?.data?.sendAt}</div>
                                                            <div className="user-text" >{chat?.data?.text}</div>
                                                        </div>
                                                    </div>}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box className="input-wrapper">
                                    <div className={`regenerate-wrap ${_.isEmpty(regeneratePayload) ? "cont-hide" : ""}`} onClick={regenerateChatCall} style={maximizeState ? { justifyContent: "center", marginBottom: "0px", alignItems: "center" } : {}}>
                                        <ResetLogo />
                                        <div className="regen-text" style={maximizeState ? { display: "flex", justifyContent: "center", alignItems: "center" } : {}}>Regenerate Response</div>
                                    </div>
                                    <div style={maximizeState ? { display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" } : {}}>
                                        <TextField
                                            value={textSearch}
                                            onChange={(e: any) => setTextSearch(e.target.value)}
                                            className="text-field-wrap"
                                            placeholder="Send a message."
                                            multiline
                                            maxRows={maximizeState ? 4 : 2}
                                            autoComplete="off"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={() => {
                                                            const obj: any = {
                                                                type: "userText",
                                                                data: { text: textSearch, sendAt: moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss') }
                                                            }
                                                            chatAPIMessage(textSearch, obj)
                                                            setTextSearch('')
                                                        }} >
                                                            {
                                                                maximizeState ?
                                                                    <img src={MaximizeSendIcon} className="send-icon" /> :
                                                                    <SendIcon className="send-icon" />
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            // onKeyDown={(e) => {
                                            //     if (e.key === 'Enter' && textSearch) {
                                            //         chatAPIMessage(textSearch, {
                                            //             type: "userText",
                                            //             data: { text: textSearch, sendAt: moment().tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss') }
                                            //         } 
                                            //         )
                                            //         setTextSearch("")
                                            //     }
                                            // }}
                                        />
                                        {
                                            maximizeState &&
                                            <div className="chatgpt-text-maximize">ChatGPT can make mistakes. Consider checking important information.</div>
                                        }
                                    </div>
                                </Box>{
                                    !maximizeState &&
                                    <Stack spacing={0} direction="row" className="gridbox footer-nav">
                                        <Box className="Chatgptbox">
                                            <Stack style={{ justifyContent: "center" }} >
                                                <Box className="d-flex-align pb-4" >
                                                    <ChatGptIcon className="gpt-icon" />
                                                </Box>
                                                <Box style={{ color: "#002882", fontSize: "14px", fontWeight: 600 }} >ChatGPT</Box>
                                            </Stack>
                                        </Box>
                                        <Box className="boxline"></Box>
                                        <Box className="Smartflobox">
                                            <Stack style={{ justifyContent: "center" }}>
                                                <Box className="d-flex-align pb-4">
                                                    <SmartFlowIcon className="smtflo-icon" />
                                                </Box>
                                                <Box style={{ fontSize: "14px" }}>Smartflo</Box>
                                            </Stack>
                                        </Box>
                                        <Box className="boxline"></Box>
                                        <Box className="Helpdeskbox">
                                            <Stack style={{ justifyContent: "center" }}>
                                                <Box className="d-flex-align pb-4">
                                                    <HelperDeskIcon />
                                                </Box>
                                                <Box style={{ fontSize: "14px" }} >Help Desk</Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                }

                            </Box>
                        }

                        {
                            !maximizeState &&
                            <Box className="ai-btn-wrapper" >
                                {!dailougeOpen ?
                                    <AiPopOpen
                                        onClick={clickopen}
                                    /> :
                                    <Box className="down-drop-icon" onClick={handleButtonClose} >
                                        <KeyboardArrowDownIcon className="down-arrow" />
                                    </Box>
                                }
                            </Box>
                        }


                    </Box>
                </Box>
            </div>
        </>
    )
}
export default Popupwindow