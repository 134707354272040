/** @jsxImportSource @emotion/react */
import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { OtpScreen } from '../components/Login/OtpScreen/OtpScreen'
function ForgotPasswordOTP() {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className='login-page-container'>
        <OtpScreen />
      </div>
    </React.Fragment>
  )
}

export default ForgotPasswordOTP
