import { Box, Button, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as BackIcon } from '../../../../assets/icons/backarrowicon1.svg';
import { ReactComponent as ShareMandateIcons } from '../../../../assets/icons/shareMandateIcons.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./CollaboratorPopup.scss"
import DoneIcon from '@mui/icons-material/Done';
import CloseIconSvg from '@mui/icons-material/Close';
import { ReactComponent as CollEdit } from '../../../../assets/icons/collEdit.svg';
import { ReactComponent as CollRemoveUser } from '../../../../assets/icons/collRemoveUser.svg';
import { ReactComponent as CollAccessListIcon } from '../../../../assets/icons/collAccessListIcon.svg';
import { ReactComponent as CollRemoveedIcon } from '../../../../assets/icons/collRemoveedIcon.svg';
import { getCollabApproverDetailList, postApproverUpdateList, postCollabRemoveList } from '../../../../services/MandateService';
import { toast } from "react-toastify";
import { useAppSelector } from '../../../../store/hooks';
import { AppErrorHandlerModal } from '../../../AppComponents/AppErrorHandlerModal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    minWidth: "820px",
    height: 'auto',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    padding: "32px 0px 0px 0px",
}
const styleReason = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: 'auto',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    padding: "0px 0px 0px 0px",
}
const CollaboratorListPopup = ({ setCollaboratorListPopupStatus,setCollaboratorPopupStatus, collaboratorListPopupStatus, mandateId, setEditCollStatus, setEditCollData, collaboratorListData,mandateCode }: any) => {
    const [approverList, setApproverList] = useState<any>([])
    const [collaborateList, setCollaborateList] = useState(Object.keys(collaboratorListData).length > 0 ? collaboratorListData?.collaboratorList : [])
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    const [refreshStatus, setRefreshStatus] = useState(false)
    const [rejectCollaboratorData, setRejectCollaboratorData] = useState<any>({})
    const [rejectCollaboratorStatus, setRejectCollaboratorStatus] = useState<boolean>(false)
    const [rejectCollaboratorText, setRejectCollaboratorText] = useState<any>("");
    const [collabRejectStatus, setCollabRejectStatus] = useState(false)
    const [collabRejectMessage, setCollabRejectMessage] = useState(`Collaborator for mandate ${mandateCode} has been successfully rejected`)
    const getCollApprovalDetailListApi = async () => {
        try {
            const res: any = await getCollabApproverDetailList({
                "mandateId": mandateId
            })
            setApproverList(res.data.result.data.approvalRequestList)
            setCollaborateList(res.data.result.data.collaboratorList)
        }
        catch (error: any) {
            console.log(error)
        }
    }
    useEffect(() => {
        if(Object.keys(collaboratorListData).length == 0) getCollApprovalDetailListApi()
    }, [])

    const approverListApi = async (approveItem: any, type: string) => {
        const statusSlug:any = {
            143:"SRR",
            142:"SRA",
            141:"SRI",
            140:"SR",
            139:"SA",
            138:"SI"
        }
        let currentStatusId: any = 0;
        if (approveItem.status == 138) {
            currentStatusId = type == "approve" ? 139 : 140
        } else if (approveItem.status == 141) {
            currentStatusId = type == "approve" ? 142 : 139
        }        
        const postAproverObj:any = {
            "sharingId": approveItem.sharing_id,
            "status": currentStatusId
        }
        if(type == "reject" && approveItem.status == 138){
            postAproverObj["approveReason"] = rejectCollaboratorText
        }else if(type == "reject" && approveItem.status == 141){
            postAproverObj["rejectReason"] = rejectCollaboratorText
        }
        try {
            const res: any = await postApproverUpdateList(postAproverObj)
            toast.success('Successfully Updated')
            getCollApprovalDetailListApi();
            setRefreshStatus(true)
        }
        catch (error: any) {
            console.log(error)
        }

    }

    const getFirstLetterOfWord = (str: string) => {
        const matches: any = str.match(/\b(\w)/g);
        const acronym = matches.join('').substring(0, 2);
        return acronym
    }

    const approverRemoveListApi = async (currentId: number) => {

        try {
            const res: any = await postCollabRemoveList({
                "sharingId": currentId,
                "status": 141
            })
            toast.success('Successfully Updated')
            getCollApprovalDetailListApi()
        }
        catch (error: any) {
            console.log(error)
        }
    }

    const approverEditApi = async (collItem: number) => {
        setEditCollStatus(new Date().getTime())
        setEditCollData(collItem)
    }

    const closeListPopup = () => {
        setCollaboratorListPopupStatus(false);
        if(refreshStatus){
            location.reload();
        }
    }
    
    const handleClose = () => {
        setCollabRejectStatus(false);
      }

    return (
        <>
            <Modal
                open={collaboratorListPopupStatus}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className="collaboratorPopupSect">
                    <Box sx={style} className='popup-wrap'>
                        <div className='header-wrapper' style={{ paddingBottom: "5px" }}>
                            <IconButton
                                aria-label='close'
                                onClick={() => { setCollaboratorPopupStatus(true); setCollaboratorListPopupStatus(false);setEditCollData({}) }}
                            >
                                <BackIcon style={{ cursor: "pointer" }} />
                            </IconButton>
                            <IconButton
                                aria-label='close'
                                onClick={() => { closeListPopup() }}
                            >
                                <CloseIcon style={{ cursor: "pointer" }} />
                            </IconButton>
                        </div>
                        <Box className="collaboratorApprovalListScroll">
                            {(approverList.length > 0 && Object.keys(collaboratorListData).length == 0) && <Accordion className='collabortorListAccordion' defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Box className="collaboratorMandateListSect">
                                        <Box>
                                            <ShareMandateIcons />
                                            <p><span>{approverList[0]?.collaborator_name} {approverList?.length > 1 ? "and" : ""} {approverList.length > 1 ? `${approverList.length - 1} other` : ""} </span>Collaborators are added and removed. Approval is pending</p>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className="collaboratorApprovalList">
                                        {approverList.map((approverItem: any, index: number) => {
                                            return (
                                                <Box key={index} className="collaboratorApprovalListSect">
                                                    <Box className="approvalListLeft">
                                                        <span>{getFirstLetterOfWord(approverItem.collaborator_name)}</span>
                                                        <Box>
                                                            <h5>{approverItem.collaborator_name}</h5>
                                                            <p>{approverItem.collaborator_role_name}</p>
                                                        </Box>
                                                    </Box>
                                                    <Box className="approvalListMiddle">
                                                        {approverItem.status_name != "Sharing remove initiated" && <Box className="AccessList">
                                                            <p><CollAccessListIcon />{(JSON.parse(approverItem.sharing_permission).map((shareItem: any) => { return shareItem.value })).join(",")}</p>
                                                        </Box>}
                                                        {approverItem.status_name != "Sharing remove initiated" &&<Box className="AccessLocationList">
                                                            {(approverItem.share_locations != null ? JSON.parse(approverItem.share_locations) : []).map((locItem: any, locIndex: number) => {
                                                                return (
                                                                    <p key={locIndex}>{locItem.primary_location} <span>{locItem.job_orders}</span></p>
                                                                )
                                                            })}
                                                        </Box>}
                                                        {approverItem.status_name == "Sharing remove initiated" && 
                                                            <Box className="AccessList removeAccessSect">
                                                                <p><CollRemoveedIcon />{"Remove Collaborator"}</p>
                                                            </Box>
                                                        }
                                                    </Box>
                                                    <Box className="approvalListRight">
                                                        <Button className='approveBtn' disabled={userRole != "Admin" ? (userPermissions?.mandate_module?.mandate_sharing?.approve_or_reject?.selected && JSON.parse(approverItem.approvers).includes(userId)) ? false : true : false} startIcon={<DoneIcon />} onClick={() => approverListApi(approverItem, "approve")}>
                                                            Approve
                                                        </Button>
                                                        <Button className='rejectBtn' disabled={userRole != "Admin" ? (userPermissions?.mandate_module?.mandate_sharing?.approve_or_reject?.selected && JSON.parse(approverItem.approvers).includes(userId)) ? false : true : false} startIcon={<CloseIconSvg />} onClick={() => {setRejectCollaboratorStatus(true);setRejectCollaboratorData(approverItem)}}>
                                                            Reject
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            }

                            <Box className="collaboratorListSect collaboratorApprovalList">
                                <h3>{collaborateList.length} Collaborators</h3>
                                {collaborateList.length > 0 ?
                                    <>
                                        {collaborateList.map((collaborateListItem: any, index: number) => {
                                            const parseLocationList = collaborateListItem.share_locations != null ? JSON.parse(collaborateListItem.share_locations) : []
                                            return (
                                                <>
                                                    <Box className="collaboratorApprovalListSect">
                                                        <Box className="approvalListLeft">
                                                            <span>{getFirstLetterOfWord(collaborateListItem.collaborator_name)}</span>
                                                            <Box>
                                                                <h5>{collaborateListItem.collaborator_name}</h5>
                                                                <p>{collaborateListItem.collaborator_role_name}</p>
                                                            </Box>
                                                        </Box>
                                                        <Box className="approvalListMiddle">
                                                            <Box className="AccessList">
                                                                <p><CollAccessListIcon />{(JSON.parse(collaborateListItem.sharing_permission).map((shareItem: any) => { return shareItem.value })).join(",")}</p>
                                                            </Box>
                                                            <Box className="AccessLocationList">
                                                                {parseLocationList.map((locItem: any, locIndex: number) => {
                                                                    return (
                                                                        <p key={locIndex}>{locItem.primary_location} <span>{locItem.job_orders}</span></p>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </Box>
                                                        <Box className="approvalListRight">
                                                            <Tooltip title={"Remove Collaborator"}>
                                                                <IconButton aria-label="delete" disabled={userRole != "Admin" ? (userPermissions?.mandate_module?.mandate_sharing?.remove?.selected && (collaborateListItem.created_by == userId || JSON.parse(collaborateListItem.approvers).includes(userId))) ? false : true : false}>
                                                                    <CollRemoveUser onClick={() => approverRemoveListApi(collaborateListItem.sharing_id)} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={"Edit Collaborator"}>
                                                                <IconButton aria-label="Edit" disabled={userRole != "Admin" ? (userPermissions?.mandate_module?.mandate_sharing?.edit?.selected && (collaborateListItem.created_by == userId || JSON.parse(collaborateListItem.approvers).includes(userId))) ? false : true : false}>
                                                                    <CollEdit onClick={() => approverEditApi(collaborateListItem)}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )
                                        })}
                                    </> :
                                    <Box>
                                        <p style={{ fontSize: "14px", fontWeight: "600", marginBottom: "0px" }}>No Collaborators List</p>
                                    </Box>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={rejectCollaboratorStatus}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                className='collaboratorReasonModal'
            >
                <Box className="collaboratorPopupSect">
                    <Box sx={styleReason} className='popup-wrap'>
                        <div className='header-wrapper'>
                            <h1>Reason for Rejection</h1>
                            <IconButton
                                aria-label='close'
                                onClick={() => { setRejectCollaboratorStatus(false);setRejectCollaboratorData({}) }}
                            >
                                <CloseIcon style={{ cursor: "pointer" }} />
                            </IconButton>
                        </div>
                        <Box className="collaboratorApprovalListScroll">
                        <TextField
                            label="Add Comments"
                            multiline
                            minRows={4}
                            maxRows={6}
                            onChange={(e:any) => {
                                setRejectCollaboratorText(e.target.value)
                            }}
                        />
                        </Box>
                        <Box className='button-container'>
                            <Button
                                className='cancel-btn'
                                onClick={() => {setRejectCollaboratorStatus(false);setRejectCollaboratorData({}) }}
                            >Cancel</Button>
                            <Button
                                className='deactive-btn'
                                type='button'
                                disabled={rejectCollaboratorText.trim().length == 0}
                                onClick={() => {approverListApi(rejectCollaboratorData, "reject");setRejectCollaboratorStatus(false);setCollabRejectStatus(true)}}
                            // disabled={isSubmitting || endDateError}
                            >
                                {"Reject"}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <AppErrorHandlerModal
                open={collabRejectStatus}
                content={collabRejectMessage}
                handleClose={handleClose}
                factor='Success'
                ButtonText='Done'
            ><></></AppErrorHandlerModal>
        </>
    );
};

export default CollaboratorListPopup;