import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import FunnelFilter from './Filter/FunnelFilter';
import CommentFilter from '../../CommenFilter/CommentFilter';

const FunnelComponentdetails = () => {
  const { id } = useParams();
  const location = useLocation();
  console.log("Funnele params :",location)

  const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location);
  const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
  
  useEffect(() => {

}, [HirningDemandParamsDetails,location,reportSubmissionAPIData]);

  return (
    <div>
      <CommentFilter setReportSubmissionAPIData={setReportSubmissionAPIData} HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails}/>
    </div>
  )
}

export default FunnelComponentdetails
