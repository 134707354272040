import { useState, useEffect } from 'react';
import { Typography, Grid, Box, Button, Snackbar, Alert,Tooltip } from '@mui/material';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import { listSearchQuery, initiateRPA, MandateService } from '../../../services/MandateService';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { AppButton } from '../../AppComponents/AppButton';
import { ReactComponent as Ellipse } from '../../../assets/icons/Ellipse.svg';
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import location from "../../../assets/images/search_query_location.png"
import calendar from "../../../assets/images/search_query_calendar.png"
import user from "../../../assets/images/search_query_profile.png"
import {resetPersistFilter, setPersistFilter} from '../../../features/filters/PersistFilter'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { convertToDisplayDateTime } from '../../../utils/date-utils';
const ListQueryBuilder = ({id, queryLists, handleEdit, getSearchQueries, hideRPAbtn, handleBuildQueryView}:any) => {
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [hasError, setHasError] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const persistFilter = useAppSelector((state)=> state.PersistFilterReducer.persistFilter)

     const statusIcon = (key:any) => {
        switch (key){
            case "open":
                return { statusName: "Open", iconColor: "#835AA8", Icon: Ellipse, className: "open-btn" }
            case "inProgress":
                return { statusName: "In progress", iconColor: "#0E94F4", Icon: Ellipse, className: "inprogress-btn" }
            case "inprogress":
                return { statusName: "In progress", iconColor: "#0E94F4", Icon: Ellipse, className: "inprogress-btn" }
            case "failed":
                return { statusName: "Failed", iconColor: "#F34334", Icon: Ellipse, className: "failed-btn" }
            case "completed":
                return { statusName: "Completed", iconColor: "#2BAD63", Icon: Ellipse, className: "completed-btn" }
            case "queued":
                return { statusName: "In Queue", iconColor: "#f4a236", Icon: Ellipse, className: "queued-btn" }
            default:
                return { statusName: "Open", iconColor: "#835AA8", Icon: Ellipse, className: "open-btn" }
        }
    }

    const getInitiateRpa = async (id:any) => {
        if(hideRPAbtn){
            setHasError(true)
        }else{
            const res = await initiateRPA(id)
            if(res?.status === 200){
                if(res?.data?.result?.message === "Search query status updated successfully"){
                    setUploadsuccess(true);
                }
            }
        }
    }


    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return
        }
        setHasError(false)
      }

    const handleViewResult = async (list:any) =>{
        await MandateService.getSearchMandates(list?.mandateId).then(res => {
            if (res?.data?.result?.totalRecords > 0) {
              if(res?.data?.result?.list?.length > 0){
                const datafilter = res?.data?.result?.list?.filter((val:any)=>val?.id===list?.mandateId)
                const data = datafilter?.length >0 ? datafilter[0]:{}
                if(Object.keys(data)?.length){
                  const staticMandate = {
                    label: _.get(data, 'title')+' - '+_.get(data, 'mandateId'),
                    value: _.get(data, 'id'), clientName:_.get(data, 'clientName'),
                    createdByName:_.get(data, 'createdByName'),mandateId:_.get(data, 'mandateId'),
                    title:_.get(data, 'title')
                }
                const filters = {
                    // ...list,
                    searchQueryId: list?.id,
                    locationid:list?.mlocationId,
                    mandateId: staticMandate,
                    lastActivity: -1,
                    module:"rpa"
                }
                dispatch(setPersistFilter({...persistFilter,persistMandate:{...persistFilter?.persistMandate,[`manTab2`]:staticMandate},
                persistTab:2,persistLocation:{...persistFilter?.persistLocation,[`locTab2`]:filters?.locationid},persistSearchQuery:filters?.searchQueryId }))
                setTimeout(()=>{
                  navigate('/candidate-listing')
                },100)
                }
              }
            } 
        })
    }

    return (
        <Box className='list-query-container'>
            {queryLists?.length > 0 ? <Box fontStyle={{paddingRight:'4px'}}>
                {queryLists?.map((list:any, index: number)=> {
                    const {statusName, iconColor, Icon, className} :any = statusIcon(list?.status?.statusName == "inprogress" ? "queued" : list?.status?.statusName == "queued" ? "inprogress" : list?.status?.statusName )
                    const isEdit = ( list?.status?.statusName.toLowerCase() !== "inprogress" && list?.status?.statusName.toLowerCase() !== "queued"&& list?.status?.statusName !== "completed")
                    return (
                      <div
                        key={index}
                        className={`list-detail-wrapper ${
                          queryLists?.length - 1 !== index && 'list-divider'
                        }`}
                      >
                        <Box className='list-query-headbtn'>
                          <Box onClick={() => handleBuildQueryView(list?.id)} className='title-wrap'>
                            <Tooltip title="Search Strategy" placement="top-start" style={{cursor: "pointer"}}>
                              <Typography className='title'><img src={user}/>{list?.queryName}</Typography>
                            </Tooltip>
                            <Box>
                              {/* <Box>
                                <Typography className='description'>{list?.mandateLocation?.primaryLocation}</Typography>
                                <Typography className='description'>{list?.mandateLocation?.primaryLocation}</Typography>
                              </Box> */}
                              <Box>
                                <Typography className='searchQueryLocation'><img src={location} />{list?.mandateLocation?.primaryLocation}</Typography>
                              </Box>

                            </Box>
                          </Box>
                          <div style={{}}>
                            <AppButton
                              bordercolor='black'
                              bgcolor='white'
                              size='large'
                              type='button'
                              className={`edit-btn ${!isEdit ? 'btn-hidden' : ''}`}
                              sx={{ color: '#fff' }}
                              onClick={() => handleEdit(list?.id)}
                            >
                              <EditIcon />
                            </AppButton>
                          </div>
                        </Box>
                        {list?.status?.statusName === 'completed' && (
                          <Typography
                            variant='body1'
                            sx={{
                              textDecoration: 'underline',
                              color: '#0E94F4',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleViewResult(list)}
                          >
                            View results (<span style={{fontSize:"14px"}}>{list?.candidateCount != null ? list?.candidateCount : 0}</span>)
                          </Typography>
                        )}
                        <Tooltip title="Search Strategy Description" placement="top-start">
                          <Typography className='description'>{list?.queryDesc}</Typography>    
                        </Tooltip>
                        <Box className="queryUserDetail">
                        <Tooltip title="Created By"><Typography className='user'>{list?.createdUser?.firstName}</Typography></Tooltip>    
                        <Tooltip title="Create At"><Typography className='createdAt'>{convertToDisplayDateTime(list?.createdAt)}</Typography></Tooltip>   
                        </Box>
                        
                        <Box className='list-status-btn'>
                       
                          <AppButton
                            label={statusName}
                            bordercolor={iconColor}
                            bgcolor='white'
                            size='large'
                            type='button'
                            className={`status-btn ${className}`}
                            sx={{ color: iconColor }}
                            // onClick={}
                          >
                            <Icon />
                          </AppButton>
                          <AppButton
                            label='Initiate L1 RPA'
                            bordercolor='black'
                            bgcolor='white'
                            size='large'
                            type='button'
                            disabled={true}
                            className={`RPA-btn ${!isEdit ? 'btn-hidden' : ''}`}
                            sx={{ color: '#002882' }}
                            onClick={() => getInitiateRpa(list?.id)}
                          >
             
                          </AppButton>
                        </Box>
                        {/* {list?.status?.statusName.toLowerCase() === 'inprogress' && (
                          <Box className='initatedIndicator'>
                            <Typography className='notice-text'>
                              Your Search query added to the RPA queue : 5/30 and the time elapsed
                              to complete <span className='remaining-time'>{'00:23:00'}</span>
                            </Typography>
                          </Box>
                        )} */}
                      </div>
                    )
                    })}
            </Box> 
            : 
            <div className='no-data-container' >
                <div className='no-result-found'>
                <Grid container className='no-result-found'
                    spacing={2}
                    >
                    <Grid xs={12} className='no-result-text' >
                    <Typography >
                        <NoResultFound />
                    </Typography>
                    <Typography>  
                        {`No build search query found`}
                        </Typography>
                    </Grid>
                </Grid>
                </div>
            </div>}
            <AppErrorHandlerModal
                open={uploadsuccess}
                content={"L1 RPA  has been successfully initiated"}
                handleClose={()=> {setUploadsuccess(false); getSearchQueries();}}
                factor='Success'
                ButtonText='Done'
            ><></></AppErrorHandlerModal>
            <Snackbar
                open={hasError}
                autoHideDuration={5000}
                onClose={handleAlertClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                >
                <Alert onClose={handleAlertClose} variant="filled" severity='error' sx={{ width: '100%' }}>
                    {"Another search query rpa is in progress"}
                </Alert>
            </Snackbar>
        </Box>
    )

}

export default ListQueryBuilder;