import { useEffect, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import './KanbanView.scss'
import { ReactComponent as ThreeDotIcon } from '../../../../assets/icons/three-dot.svg'

import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg'
import { ReactComponent as CommentIcon } from '../../../../assets/icons/comment.svg'
import { getMandateStagesbyOrder } from "../../../../services/MandateService";
import { Popover, Box, Typography,Button ,Tooltip} from "@mui/material";

import RemarkPopup from "./RemarkPopup";
import ActionPopup from "./ActionPopup";

function TaskCard({ id,
  statusName,
  stageName,
  name,
  currentLocation,
  card,
  mandateData,
  mandatelocationLists,
  setUpdateHireProp,
  setSelectedCandidate,
  setIsOpen,
  setFilteredStageList,
  setDefaultRollback,
  setPopActive,
  myclass ='',
  mandatelocationIndex
}: any) {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [remarkOpen, setRemarkOpen] = useState<boolean>(false)

  const { attributes, listeners, setNodeRef, transform,transition } = useSortable({
    id: id
  });


  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };
  const handleRemarkClick =()=>{
    setRemarkOpen(!remarkOpen)
    setPopActive(!remarkOpen) 
  }
  const handleClick = async(event: React.MouseEvent<HTMLButtonElement>,canData:any) => {
    setAnchorEl(event.currentTarget)
    setPopActive(true)
  }
  

  const handleUpdateHirePopup = async (canData: any,stage:any =false) => {
    if(!stage){
      setDefaultRollback(false)
    }
    const obj = {
      id: mandateData?.id,
      mlocationid: mandatelocationLists[mandatelocationIndex]?.id,
      mpctrackstatus: canData?.statusId,
      stageid: stage?stage?.stageid:canData?.stageId,
      stageorder: stage?stage?.stageorder:canData?.stageOrder,
      statusid: canData?.statusId,
      statusorder: canData?.statusOrder,
      uniqueid: mandateData?.xphenoId,
      isend: canData?.isEnd,
    }
    setUpdateHireProp(obj)
    setSelectedCandidate(canData)
    

    const manId = mandateData?.id
    const posId = mandatelocationLists[mandatelocationIndex]?.id
    const stageid = stage?stage?.stageid:canData?.stageId
    const stageorder = stage?stage?.stageorder:canData?.stageOrder
    const isend = canData?.isEnd

    try {
      const res = await getMandateStagesbyOrder(manId, posId,id, stageid, stageorder, isend)
      if (res?.status === 200) {
        const listData = res?.data?.result?.data ?? []
        setFilteredStageList(listData)
      }
    } catch (error) {
      console.log(error)
    }
    setIsOpen(true)
  }

  return (

    <div
      ref={setNodeRef}
      className={`KanbanCard ${myclass}`}
      {...attributes}
      {...listeners}
      style={style}>
      <div id={String(id)}>
        <div className="row1">    
            <Tooltip title={name ?? "-"} placement='bottom' >
            <span className="name">{name}</span>
            </Tooltip>
          <ThreeDotIcon  onClick={(e:any)=>handleClick(e,card)}  />
          <ActionPopup
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setPopActive={setPopActive}
  
          id={id}
          moveAction ={(stage:any) => {
            handleUpdateHirePopup(card,stage)
          }}
          card={card}
          mandateData={mandateData}
          setDefaultRollback={setDefaultRollback}
          posId = {mandatelocationLists[mandatelocationIndex]?.id}
          />

        </div>
        <div className="rowlocation">
          <LocationIcon />
          <span className="location">{currentLocation}</span>
        </div>
        <div className="rowStatus">
        <Tooltip title={statusName ?? "-"} placement='bottom' >
          <Typography onClick={(e) => {
            handleUpdateHirePopup(card)
          }} className="status">{statusName ?? "-"}</Typography>
          </Tooltip>
          <CommentIcon onClick={handleRemarkClick}/>
          <RemarkPopup
          open={remarkOpen}
          onclose ={handleRemarkClick}
          card ={card}
          statusName ={card?.statusName}
          stageName ={card?.stageName}
          />

        </div>
      </div>

    </div>




  );
}

export default TaskCard;



