import SoraFont from '../../src/assets/fonts/fonts/variable/Sora.ttf'

export const themeOptions = {
  typography: {
    fontFamily: 'Sora',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Sora',
          src: `url(${SoraFont}) format("truetype")`,
        },
      },
    },
  },
  // palette: {
  //   primary: {
  //     main: '#FFFFFF',
  //   },
  // },
}
