/** @jsxImportSource @emotion/react */
import * as React from 'react'
import { useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { LoginForm } from '../components/Login/LoginForm'
import { useNavigate } from 'react-router-dom'
import { useAppSelector,useAppDispatch } from '../store/hooks'
import {resetPersistFilter} from '../features/filters/PersistFilter'
import {
  resetFilters,
  resetFilterPreloadData
} from '../features/filters/FilterReducer'
import { hideMonthlyCard } from '../features/systemConfig/SystemConfig'
import LoginService from '../services/LoginService'
import nightScheduleOff from "../assets/images/nightScheduleOff.svg"
import weekendScheduleOff from "../assets/images/weekendScheduleOff.svg"
import scheduleMoon from "../assets/images/scheduleMoon.png"
import scheduleRocket from "../assets/images/scheduleRocket.png"
import scheduleStar from "../assets/images/scheduleStar.png"
import { Box } from '@mui/material'
import moment from 'moment'
function Login() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const authUser = useAppSelector((state) => state.auth.token)
  const [underMaintenanceStatus,setUnderMaintenanceStatus] = useState(false)
  const [underMaintenanceStatusMessage, setUnderMaintenanceStatusMessage] = useState("")
  const [scheduleManintenance, setScheduleManintenance] = useState(false)
  const [apiCloadCompleted, setApiCloadCompleted] = useState(false)
  const weekDays = ["Monday","Tuesday","Wednesday","Thursday","Friday"]
  const todayString = moment().format('dddd')
  useEffect(() => {
    // redirect to dashboard if already logged in
    if (authUser) navigate('/my-tasks')
  }, [authUser])
  useEffect(()=>{
    
    dispatch(resetPersistFilter())
    dispatch(resetFilters())
    dispatch(resetFilterPreloadData({}))
    dispatch(hideMonthlyCard())
    localStorage.clear();
  },[])
  useEffect(() => {
    underMaintenanceAPI()
  },[])

  const underMaintenanceAPI = (async () => {
    try{
      const checkUnderMaintenaceStatus = await LoginService.underManintenanceAPI()
      if(checkUnderMaintenaceStatus?.status == 200){
        const maintenanceObj = checkUnderMaintenaceStatus?.data.filter((item:any) => (item?.config_variable == "maintanance_flag" && item?.config_value == "true"))
        const maintenanceObjMessage = checkUnderMaintenaceStatus?.data.filter((item:any) => (item?.config_variable == "maintanance_message"))
        if(maintenanceObj[0]?.config_value == "true"){
          setUnderMaintenanceStatus(true)
          setUnderMaintenanceStatusMessage(maintenanceObjMessage[0]?.config_value)
        }
        setApiCloadCompleted(true)
      }
    }catch(error:any){
      error?.code == "ERR_NETWORK" ? setScheduleManintenance(true) : setScheduleManintenance(false)
      console.log(error)
      setUnderMaintenanceStatus(false)
      setApiCloadCompleted(true)
    }    
  })
  return (
    <React.Fragment>
      <CssBaseline />
      {!scheduleManintenance ? 
        <>
        {apiCloadCompleted && <div className='login-page-container'>
        <LoginForm underMaintenanceStatus={underMaintenanceStatus} underMaintenanceStatusMessage={underMaintenanceStatusMessage}/>
      </div>}</>:
      <Box className="scheduleOffSection">
        <Box className="scheduleOffScreen">
          <img src={weekDays.includes(todayString) ? nightScheduleOff : weekendScheduleOff} />
          {weekDays.includes(todayString) ? 
          <>
            <h4><img src={scheduleMoon} width={"20px"}/> Lights out for the night! </h4>
            <p>{"We'll catch up with you first thing in the morning"}</p>
          </>:
          <>
            <h4><img src={scheduleRocket} width={"20px"}/> Artemis is off for the weekend! <img src={scheduleStar} width={"20px"}/> </h4>
            <p>{"See you bright and early on Monday!"}</p>
          </>
          }
        </Box>
      </Box>}
    </React.Fragment>
  )
}

export default Login