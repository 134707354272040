/** @jsxImportSource @emotion/react */
import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ResetPasswordScreen } from '../components/Login/ResetPassword/ResetPassword'
function ResetPassword() {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className='login-page-container'>
        <ResetPasswordScreen />
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
