import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import './style.scss'
import { useState, useEffect } from 'react'
import {currentLoadStatus} from "../../features/loadDetector/loadDetectorReducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
const APIProgressLoader = () => {
  const [isLoaderVisible, setIsLoaderVisisble] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(()=>{
  dispatch(currentLoadStatus(isLoaderVisible))
  },[isLoaderVisible])
  useEffect(() => {
    const getAPICallPending = () => {
      const apiPending = Number(localStorage.getItem('apiCallPending'))
      const element = document.getElementById('root')
      const bodyelem = document.getElementsByTagName("body")[0]
      if (apiPending > 0 && !isLoaderVisible ) {
        element?.classList.add("disable-pointer-events")
        bodyelem?.classList.add("disableBody")
        setIsLoaderVisisble(true)
      }
      if (apiPending === 0) {
        element?.classList.remove("disable-pointer-events")
        bodyelem?.classList.remove("disableBody")
        setIsLoaderVisisble(false)
      }
    }

    window.addEventListener('storage', getAPICallPending)

    return () => {
      window.removeEventListener('storage', getAPICallPending)
    }
  }, [])

  return <Box className='progress-container'>{isLoaderVisible && <LinearProgress />}</Box>
}

export default APIProgressLoader
