/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from 'react'
import { Breadcrumb } from '../components/Breadcrumb'
import { TaskFilter } from '../components/MyTasks/Filter'
import { MyTaskList } from '../components/MyTasks/TaskList'
import { useForm, FormProvider } from 'react-hook-form'
import { TaskManagementService, UpdateTaskInfo } from '../services/TaskService'
import { AppErrorHandlerModal } from '../components/AppComponents/AppErrorHandlerModal/AppErrorHandlerModal'
import _ from 'lodash'
import { Loading } from './Loading'
import { Box } from '@mui/system'
import { getLoggedInUserId } from '../utils/user-utils'
import { resetFilters } from '../features/filters/FilterReducer'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { MandateService } from '../services/MandateService'
export interface Taskdetail {
  isHorizontal: boolean | undefined
  taskId: number
  taskName: string
  status: number
  description: string
  assignedTo: string | null
  updatedBy: string | null
  createdAt: string | null
  updatedAt: string | null
  createdByName: string | null
  isEditAllowed: boolean
  isDeleteAllowed: boolean
  mandates:any
  dueDate:string
  topost:boolean
}

export interface ListState {
  page: number
  size: number
}

interface ISelectForm {
  name: { value: string; label: string }
  role: string | string[]
  commonFilterText: string
}
const defaultValues: ISelectForm = {
  name: { value: '', label: '' },
  role: [''],
  commonFilterText: '',
}

let previousSearchedFilter = "";

function MyTasks() {
  const methods = useForm()
  const [listState, setListState] = useState<ListState>({
    page: 0,
    size: 10,
  })
  const [taskList, setTaskList] = useState<Taskdetail[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [assignedToUsers, setAssignedToUsers] = useState<[]>([])
  const [taskCount, setTaskCount] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingNextPage, setLoadingNextPage] = useState(false)
  const [allRoles, setallRoles] = useState<any>([])
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const showLoadingNextPage = () => setLoadingNextPage(true)
  const hideLoadingNextPage = () => setLoadingNextPage(false)
  const dispatch = useAppDispatch()
  const [mandateOptions, setMandateOptions] = useState([])
  const hidelayout = useAppSelector((state) => state.taskdrawer.hideGridlayout)
  // const name = Watch({ methods.control, name: 'role' })
  // Get Task List
  const size = 10


  const filters = useAppSelector((state) => state.filtersReducer.filters)

  const getTaskListFromResponse = (res: any) => {
    let tasks = _.get(res, 'data.taskList', []) || []
    tasks = _.map(tasks, (task: any) => {
      const createdById = _.get(task, 'createdById')
      const loggedInUserId = getLoggedInUserId()
      const isTaskSameCreatedByUser = createdById === loggedInUserId
      task.isEditAllowed = isTaskSameCreatedByUser
      task.isDeleteAllowed = isTaskSameCreatedByUser
      return task
    })
    return tasks
  }

  useEffect(() => {
    previousSearchedFilter = "";
    TaskManagementService.getAssignedToUsers()
      .then((res: any) => {
        const list = _.get(res, 'data', [])
        setAssignedToUsers(list)
      })
      .catch((error: any) => {
        console.log(error.message)
      })
  }, [])

  const loadFirstPage = async () => {
    showLoading()
    await TaskManagementService.getTaskList({
      page: 0,
      size: listState.size,
      filters: {},
    })
      .then((res) => {
        const tasks = getTaskListFromResponse(res)
        setTaskCount(_.get(res, 'data.taskCount', 0))
        const existingTaskIds = _.map(taskList, 'taskId')
        const newTaskList = _.filter(tasks, (task: any) => {
          return !_.includes(existingTaskIds, task.taskId)
        })
        setTaskList([...newTaskList, ...taskList])
        hideLoading()
      })
      .catch(() => {
        hideLoading()
      })
  }
  const reloadAllPages = async () => {
    showLoading()
    await TaskManagementService.getTaskList({
      page: 0,
      size: listState.page + listState.size + listState.size,
      filters,
    })
      .then((res) => {
        const tasks = getTaskListFromResponse(res)
        setTaskCount(_.get(res, 'data.taskCount', 0))
        setTaskList(tasks)
        hideLoading()
      })
      .catch(() => {
        hideLoading()
      })
  }
  const onCreateSuccess = useCallback(() => {
    loadFirstPage()
  }, [taskList])
  const onUpdateSuccess = (taskUpdateInfo: UpdateTaskInfo) => {
    reloadAllPages()
  }
  const removeTaskById = (id: number) => {
    let tasksCopy = _.cloneDeep(taskList)
    tasksCopy = _.filter(tasksCopy, (task: any) => {
      return task.taskId !== id
    })
    if (tasksCopy.length < taskList.length) {
      setTaskCount((count) => count - 1)
      setTaskList(tasksCopy)
    }
  }
  useEffect(() => {
    const searchString = JSON.stringify({listState, filters});
    if(searchString === previousSearchedFilter) {
      return;
    }
    previousSearchedFilter = searchString;
    const { page } = listState
    showLoadingNextPage()
    const gettasklist = async () => {
      await TaskManagementService.getTaskList({
        ...listState,
        filters,
      })
        .then((res) => {
          const tasks = getTaskListFromResponse(res)
          setTaskCount(_.get(res, 'data.taskCount', 0))
          if (page === 0) {
            setTaskList(tasks)
          } else {
            setTaskList([...taskList, ...tasks])
          }
          hideLoadingNextPage()
          hideLoading()
        })
        .catch(() => {
          hideLoadingNextPage()
          hideLoading()
        })
    }
    gettasklist()
  }, [listState, filters])

  const loadNextPage = () => {
    setListState((listState) => {
      return {
        ...listState,
        page: listState.page + 1,
      }
    })
  }

  const handleDelete = async (id: number | undefined = 0) => {
    if (!id) return
    TaskManagementService.deletetask(id)
      .then(() => {
        setOpenModal(true)
        removeTaskById(id)
      })
      .catch((error: any) => {
        console.log(error.message)
      })
  }
  const handleClose = () => setOpenModal(false)

  const getAllRoles = async () => {
    const response = await TaskManagementService.getRoles()
    if (response?.status === 200) {
      setallRoles(response?.data)
    } else {
      console.log('error')
    }
  }

  useEffect(() => {
    getAllRoles()
    getData()
    return () => {
      dispatch(resetFilters())
    };
  }, []);

  const getData = () => {
    MandateService.getMandateList({ page: 0, size: 100, filters: {} }).then((res: any) => {
      const dataItems = _.cloneDeep(_.get(res, 'data.result.list', []))
      const filterData = dataItems ? dataItems?.filter((item: any) => item?.status !== 'draft') : []
      const options = _.map(filterData, (data: any) => {
        return {
          label: _.get(data, 'title') + ' _ ' + _.get(data, 'mandateId'),
          value: _.get(data, 'id'),
        }
      })
      setMandateOptions(options)
    })
  }

  return (
    <>
    {!hidelayout && 
    <>
      <Breadcrumb title='My Tasks' />
      <FormProvider {...methods}>
        <TaskFilter
          onCreateSuccess={onCreateSuccess}
          totalRecords={taskCount}
          assignedToUsers={assignedToUsers}
          allRoles={allRoles}
          mandateOptions ={mandateOptions}
        />
      </FormProvider>
      </>}
      {/* {
        tasklist.map(task=><MyTaskList tasklist={task}/>)
      } */}
      {isLoading && (
        <Box className='task-loading-container'>
          <Loading />
        </Box>
      )}
      {!isLoading && (
        <MyTaskList
          isLoadingNextPage={isLoadingNextPage}
          onUpdateSuccess={onUpdateSuccess}
          totalTaskCount={taskCount}
          onCreateSuccess={onCreateSuccess}
          assignedToUsers={assignedToUsers}
          data={taskList}
          handlePage={loadNextPage}
          handleDelete={handleDelete}
          mandateOptions ={mandateOptions}
        />
      )}
      <div>
        <AppErrorHandlerModal
          open={openModal}
          content='successfully Deleted'
          handleClose={handleClose}
          factor='Success'
          ButtonText='Done'
        >
          <></>
        </AppErrorHandlerModal>
      </div>
    </>
  )
}

export default MyTasks
