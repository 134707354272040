import { createSlice } from '@reduxjs/toolkit'

interface userState {
    userId: null | string
    username: null | string
    userRole: null | string
    firstName: null | string,
    smartCallEmail: null | string,
    smartCallPassword: null | string,
    businessUnitCode: null | string,
    userPermissions: any,
    businessUnitId: null | number,
    roleId: null | number,
    tourToggle: any
}

const initialUserState: userState = {
    userId: null,
    username: null,
    userRole: null,
    firstName: null,
    smartCallEmail: null,
    smartCallPassword: null,
    businessUnitCode: null,
    userPermissions: {},
    businessUnitId: null,
    roleId: null,
    tourToggle: false,
}

const UserDetailSlice = createSlice({
  name: 'user detail',
  initialState: initialUserState,
  reducers: {
    authUserDetails: (state, action)=>{
        return {
          ...state,
          id: action.payload.id,
          userId: action.payload.userId,
          username: action.payload.username,
          userRole: action.payload.userRole,
          firstName: action.payload.firstName,
          smartCallEmail: action.payload.smartCallEmail,
          smartCallPassword: action.payload.smartCallPassword,
          businessUnitCode: action.payload.businessUnitCode,
          userPermissions: action.payload.userPermissions,
          businessUnitId: action.payload.businessUnitId,
          roleId: action.payload.roleId,
          tourToggle: false
        }
      },
      authSmartFlowDetails: (state, action)=>{
        return {
          ...state,
          smartCallEmail: action.payload.smartCallEmail,
          smartCallPassword: action.payload.smartCallPassword
        }
      },
    authLogoutUser: (state)=> {
        return{
            userId: null,
            username: null,
            userRole: null,
            firstName: null,
            smartCallEmail: null,
            smartCallPassword: null,
            businessUnitCode: null,
            userPermissions: {},
            businessUnitId: null,
            roleId: null,
            tourToggle: false
        }
    },
    updatePermission: (state,action) => {
      state.businessUnitId = action.payload
    },
    toggleTour: (state) => {
      state.tourToggle = !state.tourToggle
    }
  }
})

export const {authUserDetails, authLogoutUser, authSmartFlowDetails,updatePermission,toggleTour} = UserDetailSlice.actions;
export default UserDetailSlice.reducer;