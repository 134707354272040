import React, {useEffect, useRef, useState } from 'react'
import { Paper, Box, Divider, Typography, Grid, Stack, Button, Checkbox, FormControl, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar  } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import { AppButton } from '../../../../AppComponents/AppButton'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../../../utils/constants'
import { ReportSearch } from '../Search/Search'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/icons/arrow-up.svg'
import { ReactComponent as SortIcon } from '../../../../../assets/icons/sort-arrow.svg'
import { ReactComponent as CalenderIcon } from '../../../../../assets/icons/input-calendar.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs';
import { AppAutoComplete } from '../../../../AppComponents/AppAutoComplete'
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { ReactComponent as RotateIcon } from '../../../../../assets/icons/reset.svg'
import Popover from '@mui/material/Popover';
import { MandateService, dropDownData, getDepartmentRoles, getMandateLocation } from '../../../../../services/MandateService'
import _ from 'lodash'
import { ReportkManagementService } from '../../../../../services/ReportListService'
import DepartmentDropDown from '../../../../Mandates/AddMandate/DepartmentDropDown'
import { useAppDispatch,useAppSelector } from "../../../../../store/hooks";
import { convertToDisplayDate } from '../../../../../utils/date-utils'
import moment from 'moment'
import IndustryDropdown from '../../../../Mandates/AddMandate/IndustryDropdown'
import MuiAlert from '@mui/material/Alert';
import { TaskManagementService } from '../../../../../services/TaskService'
import { reportFilterLoadData } from '../../../../../features/filters/FilterReducer'
import { Breadcrumb } from '../../../../Breadcrumb'
import { ReactComponent as WidgetIcon } from '../../../../../assets/icons/Widget-icon.svg'
import { DateRangePicker, DateRange } from '@mui/lab';
import '../../../../../components/Mandates/AddMandate/DepartmentDropDown.scss'
import '../DashboardFilter.scss'
import { useLocation , useNavigate} from "react-router-dom";
import { getJobTitleapi, getJoLocationsapicall, MandateSPOCapi } from '../../../../../services/DashboardService'
import { getLoggedInUserId } from '../../../../../utils/user-utils'
import { objectToQueryString } from '../../../../../utils/common-utils'


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const DatePrickerStyle = { 
  // '& .MuiInputBase-root': { height: '40px' }, // Adjust height as needed
  // '& .MuiInputLabel-root': { top: '-5px', }   // Adjust label position as needed

  '& .MuiInputBase-root': {
    height: '38px', // Adjust height as needed
    top: '0.2px',
    '& fieldset': {
      borderColor: '#CFD1D2', // Default border color
    },
    '&:hover fieldset': {
      borderColor: '#CFD1D2', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#CFD1D2', // Focus border color
      borderWidth: '1px', // Optional: Adjust the border width
    },
    '&.Mui-focused': {
      borderColor: '#CFD1D2', // Focus border color
    },
    '&:hover': {
      borderColor: '#CFD1D2', // Change this to your preferred hover border color
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#CFD1D2', // Focus border color
      borderWidth: '1px', // Optional: Adjust the border width
    },
    '&:hover fieldset': {
      borderColor: '#CFD1D2', // Hover border color
    },
  },
  '& .MuiInputLabel-root': {
    top: '-5px', // Adjust label position as needed
    '&.Mui-focused': {
      color: '#CFD1D2', // Focus label color
    },
    '&:hover': {
      color: '#CFD1D2', // Change this to your preferred hover label color
    },
  },
}

const FilterComponent = ({HirningDemandParamsDetails,setHirningDemandParamsDetails,Coelist,reportClientAPIList,reportBussinessAPIList,jobModeList,mandateTypeList,businessUnitList,rolesdataList,primaryskillList,setReportSubmissionAPIData,reportSubmissionAPIData,DashboardFilterDatas,setDashboardFilterDatas,reportSubmitdatalabels,setreportSubmitdatalabels,setMLocationList, setResetFilter, hideFields, hideDashHeader,setApplyBtnActive,candidates}:any) => {
  // const coeRemoveList = ["Assigned","Participated"] --
  const [filterChecked, setfilterChecked] = useState<boolean>(false)
  // const [columnFilter, setColumnFilter] = useState<boolean>(false) --
  // const [keyWordSearch, setKeyWordSearch] = useState("") --
  let userRoleState = useAppSelector((state:any) => state?.UserDetail?.userRole);
  const userStateId = useAppSelector((state) => state.UserDetail.userId)
  userRoleState = userRoleState ? userRoleState.replace("/","").replace(/ /g,"").trim() : "";
  const dispatch = useAppDispatch()
  // const [filterTabCloseStatus, setfilterTabCloseStatus] = useState<string>() --
  const handleFilter = () => {
    setfilterChecked((prev) => !prev)
  }
  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
  const [selectAllColumnsState, setSelectAllColumnsState] = useState<any>(false)  
  // const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>(); --
  const location = useLocation();
  const navigate = useNavigate();
  const buElementAccessRoles = useAppSelector((state) => state?.SystemConfig?.buElementAccessRoles);

  const userbasedReportFilterCase:any = {
    Admin:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],  
    Management:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],
    BUHead:["Delivery/Account Manager","Team Lead","Recruiter"],
    DeliveryAccountManager: ["Team Lead","Recruiter"],
    TeamLead:["Recruiter"],
    Recruiter:[]
  }
  // const bussiUnitKeyRefer:any = {
  //   "BUHead": "bUHead",
  //   "Delivery/Account Manager": "deliveryManager",
  //   "Team Lead": "teamLead",
  //   "Recruiter":"recruiter"
  // } 
  const [bussinessUnitOveallObj, setBussinessUnitOveallObj] = useState<any>({})
  const [bussinessUnitOveallObjInitial, setBussinessUnitOveallObjInitial] = useState<any>({})
  const[bussinessUnitObjData, setBussinessUnitObjData] = useState<any>({})
  
  const currentRoleOrder = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
  const [currentRoleSelected, setCurrentRoleSelected] = useState(userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] ? userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState][0]: "")
  const [heirarchyRoleList, setHeirarchyRoleList] = useState<any>()
  const [userIdSelected, setUserIdSelected] = useState<any>(userId) 
  const [currentRoleStr, setCurrentRoleStr] = useState<any>([])

  const [mandateSpocList, setMandateSpocList] = useState<any>([]);
    const [JobtitleList, setJobtitleList] = useState<any>([])
    const [JobLocationList, setJobLocationList] = useState<any>([])
    
    const [clientfieldrequestdata, setClientFieldRequestData] = useState({
      filterkey: [] as any[],
      clientid: [] as any[],
      search: "" as string,
      jobtitlereq: [] as any[]
    });



    const [clientsearchTerm, setclientSearchTerm] = useState('');

    const handlemandateSpocSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      setClientFieldRequestData(prevState => ({
        ...prevState,
        search: searchValue,  // Update search value in state
      }));
    };

    const mandatespocapicall = async () =>{
      const reqObj = {
        filter: clientfieldrequestdata.search,
        pageNumber: 1,
        pageSize: Number.MAX_SAFE_INTEGER,
        clientId: clientfieldrequestdata.clientid.length > 0 ? clientfieldrequestdata.clientid : [] ,
      };
    
      try {
        const res = await MandateSPOCapi(reqObj);
        console.log("mandateiiiing",reqObj)
    
        const Mandatespocoptions = _.map(res?.data, (data: any,index:number) => {
          return {
            label: data,
            value: index,
          }
        })
    
        // Mandatespocoptions = prepend({label: "All",value: "All"}, Mandatespocoptions)
        setMandateSpocList(Mandatespocoptions)
      } catch (error) {
        console.log(error, "config api error");
      }
    }

    const jobtitlecallapi = async () =>{
      const queryString = objectToQueryString({
        page: 1 ,
        // size:Number.MAX_SAFE_INTEGER,
        userId: getLoggedInUserId(),
        IsApproved: 1,
        screen:"mandates",
        // clientId:[]
    })
    console.log("clientfieldrequestdataClient",clientfieldrequestdata.clientid)
    const reqObj = {
        jobTitleFilterList: clientfieldrequestdata.clientid.length > 0 ? clientfieldrequestdata.clientid : []
  }

  console.log("JobTitleapipayload : ", reqObj)

  try {
    const res = await getJobTitleapi(queryString,reqObj);
    console.log("JobTitleOptions",res)

    const JobtitleOptions = _.map(res?.data?.result.list, (data: any,index:number) => {
      return {
        label: data.title,
        value: data?.id,
      }
    })
    setJobtitleList(JobtitleOptions)
  } catch (error) {
    console.log(error, "config api error");
  }

  }


  const joLocationscallapi = async () =>{
  //   const queryString = objectToQueryString({
  //     page: 1 ,
  //     size:Number.MAX_SAFE_INTEGER,
  //     userId: getLoggedInUserId(),
  //     IsApproved: 1,
  //     screen:"mandates",
  //     // clientId:[]
  // })

  const reqObj = {
    "mandateIds": clientfieldrequestdata.jobtitlereq.length > 0 ? clientfieldrequestdata.jobtitlereq : [],
    "clientId": clientfieldrequestdata.clientid.length > 0 ? clientfieldrequestdata.clientid : []
}

console.log("joLocationscallapi : ", reqObj)

try {
  const res = await getJoLocationsapicall(reqObj);
  console.log("JobTitleOptions",res)

  const JobtitleOptions = _.map(res?.data, (data: any,index:number) => {
    return {
      label: data?.primary_locations,
      value: data?.primary_locations,
    }
  })
  setJobLocationList(JobtitleOptions)
} catch (error) {
  console.log(error, "config api error");
}

}

    useEffect(()=>{
      mandatespocapicall()
      console.log("clientfieldrequestdata",clientfieldrequestdata)
      jobtitlecallapi()
      joLocationscallapi()
    },[clientsearchTerm,clientfieldrequestdata])
    

  const getBussinessUnitObj = () => {
    if(Object.keys(reportBussinessAPIList).length > 0){
      const bussObjArray:any = {};
        const parentRole = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
        const disableRoleObj:any = {}
       
        // const roleBaseObj = reportBussinessAPIList[parentRole[0] == "Recruiter" ? "recuriterDropDown" : parentRole[0] == "Team Lead" ? "teamLeadDropDown": parentRole[0] == "Delivery/Account Manager" ? "accountManagerDropDown" : parentRole[0] == "BUHead" ? "buHeadDropDown": ""]  ---
        // bussObjArray[parentRole[0]] = roleBaseObj
        parentRole.map((item:any, index:number) => {
          let userRoleOption = _.map(reportBussinessAPIList[item == "Recruiter" ? "recuriterDropDown" : item == "Team Lead" ? "teamLeadDropDown": item == "Delivery/Account Manager" ? "accountManagerDropDown" : item == "BUHead" ? "buHeadDropDown": ""], (data: any,index:any) => {
            return {
              label: _.get(data, 'firstName'),
              value: _.get(data, 'id'),
              index: index + 1,
              reporting: _.get(data, 'reporting')
            }
          })
          userRoleOption = prepend({label: "All",value: "All", index: 0}, userRoleOption)
          disableRoleObj[item] = {
            disabled : index > 0 ? true : false,
            options: userRoleOption,
            selectedValue: 0
          }
          })
          setBussinessUnitOveallObj(disableRoleObj)
          if(Object.keys(bussinessUnitOveallObjInitial).length == 0)setBussinessUnitOveallObjInitial(JSON.parse(JSON.stringify(disableRoleObj)))
          setBussinessUnitObjData(bussObjArray)
    }    
  }

  function prepend(value:any, array:any) {
    const newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  // const getUserBaseData = (userRole:string) => {    ----
  //   let userRoleOption = []
  //   userRoleOption = _.map(bussinessUnitObjData[userRole], (data: any) => {
  //     return {
  //       label: _.get(data, 'firstName'),
  //       value: _.get(data, 'id'),
  //     }
  //   })
  //   return prepend({
  //     label: "All",
  //     value: "All",
  //   }, userRoleOption)
  // }

  useEffect(() => {    
    getBussinessUnitObj()
  },[reportBussinessAPIList])

  console.log("DashBoard Object ffel : ",HirningDemandParamsDetails?.state?.dashboardfilterObj)
  // console.log("DashBoard Object ffel coe: ",HirningDemandParamsDetails?.state?.dashboardfilterObj["coe"]?.map((item:any)=>item))
  console.log("DashBoard Filt : ",HirningDemandParamsDetails?.state?.filterListLabels)
  const HiringListInitial = HirningDemandParamsDetails?.state

  console.log("DeeshboardParams :",HiringListInitial)
  const initValues:any = 
  {
    submissionEndDate: HiringListInitial?.filterListLabels?.["submissionEndDate"],
    submissionDate: HiringListInitial?.filterListLabels?.["submissionDate"],
    coe: HiringListInitial?.dashboardfilterObj?.["coe"]?.map((item: any) => item) || [],
    businessunit: HiringListInitial?.dashboardfilterObj?.["businessunit"]?.map((item: any) => item) || [],
    mandatespoc: HiringListInitial?.dashboardfilterObj?.["mandatespoc"]?.map((item: any) => item) || [],
    client: HiringListInitial?.dashboardfilterObj?.["client"]?.map((item: any) => item) || [],
    jobtitle: HiringListInitial?.dashboardfilterObj?.["jobtitle"]?.map((item: any) => item) || [],
    jolocation: HiringListInitial?.dashboardfilterObj?.["jolocation"]?.map((item: any) => item) || [],
    primaryskill: HiringListInitial?.dashboardfilterObj?.["primaryskill"]?.map((item: any) => item) || [],
    mandatetype: HiringListInitial?.dashboardfilterObj?.["mandatetype"]?.map((item: any) => item) || [],
    industryData: HiringListInitial?.dashboardfilterObj?.["industry"]?.map((item: any) => item) || [],
    role: HiringListInitial?.dashboardfilterObj?.["role"]?.map((item: any) => item) || [],
    jobmode: HiringListInitial?.dashboardfilterObj?.["jobmode"]?.map((item: any) => item) || [],
    BUHead: HiringListInitial?.filterListLabels?.["BUHeadUserID"],
    RecruiterUserID: HiringListInitial?.filterListLabels?.["RecruiterUserID"],
  }

  const { handleSubmit, control, reset, watch, getValues,setValue, formState: { errors } }:any = useForm({defaultValues: initValues})

  const [preloadDataObj , setPreloadDataObj] = useState<any>()
  // const [submissionStartDate, setSubmissionStartDate] = React.useState<Dayjs | null>(null); ----
  // const [submissionEndDate, setSubmissionEndDate] = React.useState<Dayjs | null>(null);   ----


  
  

  const FilterForms = () => {    
    const { userId, userRole, roleId }:any = useAppSelector((state) => state?.UserDetail)
    // const [searchMandateListFilter, setSearchMandateListFilter] = useState<any>([])  ---
    const [deptData, setDeptData] =useState([])
    // const [snackStatus, setSnackStatus] = useState(false)  ---
    // const [snackStatusMessage, setSnackStatusMessage] = useState("")  ---
    // const [snackStatusType, setSnackStatusType] = useState<any>("")   ---
    const autoC = useRef<any>(null);  
    const autoMandate = useRef<any>(null);   

    
    
    const getSelectedObj = (optionItem:any,role:string) => {
      let userRoleOption = _.filter(optionItem, (data: any) => {
        return (data?.roleName == "BU Head" ? "BUHead" : data?.roleName) == role
      })
      userRoleOption = _.map(userRoleOption, (data: any, index:any) => {
          return {
            label: _.get(data, 'firstName'),
            value: _.get(data, 'id'),
            index: index + 1,
            reporting: _.get(data, 'reportingTo'),
          }           
      })
      userRoleOption = prepend({label: "All",value: "All", index: 0}, userRoleOption) 
      return userRoleOption
    }
    const getUserListAPI = async (role:string,value:any) => {
      const overAllObj = bussinessUnitOveallObj  
      const RoleStr:any = []     
      const parentRole = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
        const nextRole = (value == "All" && parentRole[0] == role) ? currentRoleOrder : currentRoleOrder.filter((list:any,index:number) => (index) > (value != "All" ? currentRoleOrder?.indexOf(role) : (currentRoleOrder?.indexOf(role) - 1)))
          let userRoleId:any = ""
          if(value == "All" || value == null){
            if(parentRole[0] == role){   
              setUserIdSelected(userId)           
              userRoleId = userId
            }else{
              let PreviousRoleID:any = ""
              heirarchyRoleList?.backwardHierarchy.map((heriList:any,heirIndex:number) => {
                if((heriList.roleName == "BU Head" ? "BUHead" : heriList.roleName) == currentRoleStr[(currentRoleStr.indexOf(role) - 1)]){
                  PreviousRoleID = heirarchyRoleList?.backwardHierarchy[heirIndex].id
                }
              })
              userRoleId = parentRole[0] == role ? userId : PreviousRoleID
              setUserIdSelected(PreviousRoleID)
            }
          }else{
            setUserIdSelected(value)
            userRoleId = value
          }
          const response = await TaskManagementService.getReportAssignedToUsersHierarchy(userRoleId);  
          setHeirarchyRoleList(response.data)     
          if(response.data.forwardHierarchy.length > 0){            
            nextRole.map((item:any) => {              
              const filterObject = getSelectedObj(response.data.forwardHierarchy,item)
              overAllObj[item] = {
                  options: filterObject,
                  selectedValue: 0
                }
                if(filterObject.length > 1)RoleStr.push(item)
            })
            
            if(value != "All" && parentRole[0] != role){
              RoleStr.unshift(role)
              let currentRoleOption = overAllObj[role].options.filter((list:any,childIndex:any) => list?.reporting == response.data.backwardHierarchy[0]?.id)
              currentRoleOption = prepend({label: "All",value: "All", index: 0}, currentRoleOption)
              currentRoleOption.map((curRole:any,curRoleIndex:number) => curRole.index = curRoleIndex)
              const selectedObj = currentRoleOption.filter((list:any,index:any) => list?.value == value); 
              overAllObj[role] = {
                options: currentRoleOption,
                selectedValue: selectedObj[0]?.index
              } 
            }else{
              const selectedObj = overAllObj[role].options.filter((list:any,index:any) => list?.value == value);
              overAllObj[role] = {
                options: overAllObj[role].options,
                selectedValue: selectedObj[0]?.index
              }
              RoleStr.unshift(currentRoleStr[(currentRoleStr.indexOf(role) - 1)])
              // overAllObj[currentRoleOrder[currentRoleOrder?.indexOf(role)]].selectedValue = selectedObj[0]?.index 
            }
            if(response.data.backwardHierarchy.length > 0){
              let hideRoleList = currentRoleOrder.slice(0, currentRoleOrder.indexOf(role))     
              response.data.backwardHierarchy.map((item:any,index:number) => {
                const roleNameKey = item.roleName == "BU Head" ? "BUHead" : item.roleName                
                if(currentRoleOrder.includes(roleNameKey)){
                  hideRoleList = hideRoleList.filter((e: any) => e !== roleNameKey)
                  RoleStr.unshift(roleNameKey)
                  if(parentRole[0] == (roleNameKey)){                  
                    const parentRoleOption = overAllObj[roleNameKey].options;
                    const hierarchyParentSelection = response.data.backwardHierarchy.filter((heirarchyList:any) => (heirarchyList?.roleName == "BU Head" ? "BUHead" : heirarchyList?.roleName) == parentRole[0])
                    overAllObj[roleNameKey] = {    
                      options: parentRoleOption,          
                      selectedValue: parentRoleOption.filter((list:any,childIndex:any) => list?.value == hierarchyParentSelection[0]?.id)[0]?.index 
                    }
                  }else{
                    let updatedOption = reportBussinessAPIList[roleNameKey == "Recruiter" ? "recuriterDropDown" : roleNameKey == "Team Lead" ? "teamLeadDropDown": roleNameKey == "Delivery/Account Manager" ? "accountManagerDropDown" : roleNameKey == "BUHead" ? "buHeadDropDown": ""].filter((roleObj:any,roleIndex:number) => roleObj?.reporting == response.data.backwardHierarchy[index + 1]?.id)
                    updatedOption = _.map(updatedOption, (data: any, index:any) => {
                      return {
                        label: _.get(data, 'firstName'),
                        value: _.get(data, 'id'),
                        reporting: _.get(data, 'reporting'),
                        index: index + 1
                      }           
                    })
                      updatedOption = prepend({label: "All",value: "All", index: 0}, updatedOption)
                      const selectedValue = updatedOption.filter((list:any,childIndex:any) => list?.value == item?.id)
                      overAllObj[roleNameKey] = {
                        options: updatedOption,
                        selectedValue: selectedValue[0]?.index 
                      }
                    }
                  }                  
              }) 
              if(value == "All") hideRoleList = hideRoleList.filter((e: any) => e !== RoleStr[RoleStr.indexOf(role) -1])
              hideRoleList.map((itemRole:any) => {
                overAllObj[itemRole] = {
                  options: [],
                  selectedValue: 0 
                }
              })
            }         
            // const selectedObj = overAllObj[role].options.filter((list:any,index:any) => list?.value == value);
            // overAllObj[currentRoleOrder[currentRoleOrder?.indexOf(role)]].selectedValue = selectedObj[0]?.index  
        }else{
          const selectedObj = overAllObj[role].options.filter((list:any,index:any) => list?.value == value);
          overAllObj[(currentRoleOrder?.indexOf(role) + 1 == currentRoleOrder.length)? currentRoleOrder[currentRoleOrder?.indexOf(role)] : currentRoleOrder[currentRoleOrder?.indexOf(role) + 1]] = {
            options: [],
            selectedValue: 0
          }
          overAllObj[currentRoleOrder[currentRoleOrder?.indexOf(role)]].selectedValue = selectedObj[0]?.index 
        }
        setCurrentRoleStr(RoleStr)
        setBussinessUnitOveallObj(overAllObj)
    }


    const industryConvertObj = (industryObj:any) => {
      const arrayObj = industryObj.map((item:any) => {
        return item.industryName
      })
      return arrayObj
    }

    const filterSubmitHandler = (Data:any) => {
      console.log("submitformData", Data)
      console.log("submitformData coe", Data?.coe?.map((item:any)=>item))
      const dashboardFilterObj:any = {}
      if(Data?.client.length > 0){dashboardFilterObj["client"] = Data?.client?.map((item:any)=>item.value)}
      if(Data?.jobtitle.length > 0) {dashboardFilterObj["jobTitle"] = Data?.jobtitle?.map((item:any)=>item.label)}
      if(Data?.primaryskill.length > 0) {dashboardFilterObj["primarySkills"] = Data?.primaryskill?.map((item:any)=>item.value)}else{dashboardFilterObj["primarySkills"] = []}
      if(Data?.mandatetype.length > 0) {dashboardFilterObj["mandateType"] = Data?.mandatetype?.map((item:any)=>item.label)}else{dashboardFilterObj["mandateType"] = []}
      if(Data?.jobmode.length > 0) {dashboardFilterObj["jobMode"] = Data?.jobmode?.map((item:any)=>item.label)}else{dashboardFilterObj["jobMode"] = []}
      if(Data?.mandatespoc.length > 0) {dashboardFilterObj["mandateSPOC"] = Data?.mandatespoc?.map((item:any)=>item.label)}else{dashboardFilterObj["mandateSPOC"] = []}
      if(Data?.jolocation.length > 0) {dashboardFilterObj["joLocation"] = Data?.jolocation?.map((item:any)=>item.value)}
      if(Data?.coe.length > 0) {dashboardFilterObj["coe"] = Data?.coe?.map((item:any)=>item.value)}
      if (Data?.businessunit.length > 0) { dashboardFilterObj["businessUnit"] = Data?.businessunit?.map((item:any)=>item.value); }
      if (Data?.BUHead) { dashboardFilterObj["BUHeadUserID"] = Data?.BUHead?.value; }
      if (Data?.["Delivery/Account Manager"]) { dashboardFilterObj["deliveryManagerUserID"] = Data?.["Delivery/Account Manager"]?.value; }
      if (Data?.["Team Lead"]) { dashboardFilterObj["teamLeadUserID"] = Data?.["Team Lead"]?.value }
      if (Data?.Recruiter) { dashboardFilterObj["RecruiterUserID"] = Data?.Recruiter?.value }
      if (Data?.submissionDate) { dashboardFilterObj["customStartDate"] = moment(new Date(Data.submissionDate)).format('YYYY-MM-DD') }
      if (Data?.submissionEndDate) { dashboardFilterObj["customEndDate"] = moment(new Date(Data.submissionEndDate)).format('YYYY-MM-DD') }
      if (Data?.industryData.length > 0) { dashboardFilterObj["industry"] = industryConvertObj(Data.industryData) }
      if (Data?.role.length > 0) { dashboardFilterObj["roles"] = Data?.role?.map((item:any)=>item.label); }else{dashboardFilterObj["roles"] = []}
      console.log("Obj sub : ", dashboardFilterObj)

      setReportSubmissionAPIData(dashboardFilterObj)
      if(location.pathname == "/dashboard"){
        setDashboardFilterDatas(Data)
        const dashboardFilterObjlabel:any = {}
        if(Data?.client ){dashboardFilterObjlabel["client"] = Data?.client?.map((item:any)=>item.label)}
        if(Data?.jobtitle) {dashboardFilterObjlabel["jobTitle"] = Data?.jobtitle?.map((item:any)=>item.label)}
        if(Data?.primaryskill) {dashboardFilterObjlabel["primarySkills"] = Data?.primaryskill?.map((item:any)=>item.label)}
        if(Data?.mandatetype) {dashboardFilterObjlabel["mandateType"] = Data?.mandatetype?.map((item:any)=>item.label)}
        if(Data?.jobmode) {dashboardFilterObjlabel["jobMode"] = Data?.jobmode?.map((item:any)=>item.label)}
        if(Data?.mandatespoc) {dashboardFilterObjlabel["mandateSPOC"] = Data?.mandatespoc?.map((item:any)=>item.label)}
        if(Data?.jolocation) {dashboardFilterObjlabel["joLocation"] = Data?.jolocation?.map((item:any)=>item.label)}
        if(Data?.coe) {dashboardFilterObjlabel["coe"] = Data?.coe?.map((item:any)=>item.label)}
        if (Data?.businessunit) { dashboardFilterObjlabel["businessUnit"] = Data?.businessunit?.map((item:any)=>item.label); }
        if (Data?.BUHead) { dashboardFilterObjlabel["BUHeadUserID"] = Data?.BUHead?.label; }
        if (Data?.["Delivery/Account Manager"]) { dashboardFilterObjlabel["deliveryManagerUserID"] = Data?.["Delivery/Account Manager"]?.label; }
        if (Data?.["Team Lead"]) { dashboardFilterObjlabel["teamLeadUserID"] = Data?.["Team Lead"]?.label }
        if (Data?.Recruiter) { dashboardFilterObjlabel["RecruiterUserID"] = Data?.Recruiter?.label }
        if (Data?.submissionDate) { dashboardFilterObjlabel["customStartDate"] = moment(new Date(Data.submissionDate)).format('YYYY-MM-DD') }
        if (Data?.submissionEndDate) { dashboardFilterObjlabel["customEndDate"] = moment(new Date(Data.submissionEndDate)).format('YYYY-MM-DD') }
        if (Data?.industryData.length > 0) { dashboardFilterObjlabel["industry"] = industryConvertObj(Data.industryData) }
        if (Data?.role) { dashboardFilterObjlabel["roles"] = Data?.role?.map((item:any)=>item.label) }
        console.log("hiring",Data)
        setreportSubmitdatalabels(dashboardFilterObjlabel)
      }
      
      // setApplyBtnActive("acyive")
    }


    


    

    const resetFilterData = () => {      
      reset()
      setReportSubmissionAPIData({})
      // setreportSubmitdatalabels({})
      // setDashboardFilterDatas({})
      setHirningDemandParamsDetails({})
      navigate(location.pathname, { replace: true, state: {} })
      reset({
        submissionEndDate: null,
        submissionDate: null,
        coe: [],
        businessunit : [],
        mandatespoc: [],
        client : [],
        jobtitle: [],
        jolocation: [],
        primaryskill: [],
        mandatetype: [],
        industryData: null,
        role: [],
        jobmode: [],
        BUHead: "",
        "Team Lead":"",
        "Delivery/Account Manager":"",
        Recruiter:"",
        RecruiterUserID: ""
      })
      const bussinessUnitState:any = JSON.parse(JSON.stringify(bussinessUnitOveallObjInitial))
      setBussinessUnitOveallObj((prevOverallUnit: any) => ({...prevOverallUnit, ...bussinessUnitState}))      
    }
    const clearLocationField = () => {
      const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
      setMLocationList([])
    } 
    const clearLocationMandateField = () => {
      const ele = autoMandate.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 
    
    function dateDiffInDays(a:any, b:any) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    
    
    return (
      <>
      <Box sx={{backgroundColor:'white',borderRadius:'10px',padding:'2px',paddingX:'15px',paddingBottom:'13px'}} component={'form'} onSubmit={handleSubmit((data:any) => filterSubmitHandler(data))}>
        {/* <Divider /> */}
        {/* <Snackbar open={snackStatus} autoHideDuration={6000} onClose={() => {setSnackStatus(false)}}>
            <Alert onClose={() => {setSnackStatus(false)}} severity={snackStatusType} sx={{ width: '100%' }}>
              {snackStatusMessage}
            </Alert>
          </Snackbar> */}
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{display:'flex', justifyContent:'space-between',marginBottom:'2px'}}>
            <Box><Typography className='filter-title' sx={{fontWeight:'bold',paddingTop:'10px'}}>Filter</Typography></Box>
            </Box>
          
          <Box>
            <div className='customefilterGrid reportFilters' style={{marginTop:'1px'}}>
          <Box className="startEndField">
            {!hideFields.includes("Start Date") && <><Controller
                name='submissionDate'
                rules={{
                  required: {
                      value: false,
                      message: "This field is required",
                  },
                  validate: (value) => {
                    if(dateDiffInDays(new Date(value),new Date(getValues("submissionEndDate"))) >= 0){
                      return true;
                    }else{
                      return 'Start Date should be lesser than End Date';
                    }                    
                  },
                  }}
                // defaultValue={submissionStartDate}
                defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                control={control}
                render={({ field: { onChange, ...restField } }:any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                      onChange={onChange}
                      inputFormat='DD/MM/YYYY'  
                      components={{
                        OpenPickerIcon: CalenderIcon,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Date" label={"Start Date"}
                        sx={DatePrickerStyle}
                        />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors?.submissionDate && (
                <span className="reminder_text-danger select-danger-text" role="alert">
                    {errors?.submissionDate?.message}
                </span>
            )}</>}
            </Box>
              <Controller
                name='submissionEndDate'
                // defaultValue={submissionEndDate}
                defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                control={control}
                render={({ field: { onChange, ...restField } }:any) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    // defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                      onChange={onChange}
                      inputFormat='DD/MM/YYYY'                      
                      components={{
                        OpenPickerIcon: CalenderIcon,
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Date" label={"End Date"} 
                        sx={DatePrickerStyle}
                        />
                      )}
                      {...restField}
                    />
                  </LocalizationProvider>
                )}
            />


                  {!hideFields.includes("COE") && <Controller
                    control={control}
                    name='coe'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={Coelist}
                        disabled={true}
                        // defaultValue={Coelist[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event:any, value:any) => {
                          onChange(value)
                          setValue("coe", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Delivery centre" label={`Delivery centre`} />}
                      />
                    )}
                  />}


                  {(!hideFields.includes("Bussiness Unit") && buElementAccessRoles.includes(roleId.toString())) && <Controller
                    control={control}
                    name='businessunit'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={businessUnitList}
                        // defaultValue={businessUnitList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("businessunit", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Bussiness Unit" label={`Bussiness Unit`} />}
                      />
                    )}
                  />}
              
              {userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] && userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState].map((item:string,index:number) => {  
                console.log("Capture field",item)
                return (
                  <>
                  {bussinessUnitOveallObj[item]?.options.length > 1 && <Box key={index} className={"submissionFilterEnableField"}>
                  <Controller                    
                    key={index}
                    control={control}
                    name={item}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        value={bussinessUnitOveallObj[item]?.options[bussinessUnitOveallObj[item]?.selectedValue]}
                        options={bussinessUnitOveallObj[item]?.options}
                        // disabled={bussinessUnitOveallObj[item]?.disabled}
                        // disablePortal
                        // defaultValue={!bussinessUnitOveallObj[item].disabled ? bussinessUnitOveallObj[item].options[0]: ""}
                        sx={{ width: 300 }}
                        onChange={(event, value:any) => {
                            onChange(value)  
                            setValue(item, value)
                            getUserListAPI(item, value == null ? null : value?.value)
                            setCurrentRoleSelected(item)
                        }}
                        renderInput={(params) => <TextField  {...params} InputLabelProps={{ shrink: false }} placeholder={item} label={item} />}
                      />
                    )}
                  />
                </Box>
              }</>
                )
              }) }

                  {!hideFields.includes("Client") && <Controller
                    control={control}
                    name='client'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={reportClientAPIList}
                        // defaultValue={reportClientAPIList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("client", value)   
                          console.log("Client Requested obj",event)
                          const labels = value.map((item: any) => item.label);
                          const values = value.map((item: any) => item.value.toString());
                          // setClientFieldRequestData({
                          //   filterkey: labels,
                          //   clientid: values,
                          //   search: clientsearchTerm,
                          // });
                          setClientFieldRequestData((prevState: any) => ({
                            ...prevState,
                            filterkey: labels,
                            clientid: values,
                            search: clientsearchTerm,  // Update search value in state
                          }));
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Client" label={`Client`}/>}
                      />
                    )}
                  />}

                  {!hideFields.includes("Mandate SPOC") && <Controller
                    control={control}
                    name='mandatespoc'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={mandateSpocList}
                        // defaultValue={mandateSpocList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("mandatespoc", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Mandate SPOC" label={`Mandate SPOC`} onChange={handlemandateSpocSearchChange}/>}
                      />
                    )}
                  />}


                  {!hideFields.includes("Job Title") && <Controller
                    control={control}
                    name='jobtitle'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={JobtitleList}
                        // defaultValue={JobtitleList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("jobtitle", value)  
                          const labels = value.map((item: any) => item.label);
                          const values = value.map((item: any) => item.value);
                          // setClientFieldRequestData({
                          //   filterkey: labels,
                          //   search: "",
                          //   jobtitlereq:values
                          // });
                          
                          setClientFieldRequestData((prevState: any) => ({
                            ...prevState,
                            jobtitlereq:values // Update search value in state
                          }));
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Job Title" label={`Job Title`} />}
                      />
                    )}
                  />}


                {!hideFields.includes("JO Location") && <Controller
                    control={control}
                    name='jolocation'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={JobLocationList}
                        // defaultValue={JobLocationList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("jolocation", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Jo Location" label={`JO Location`} />}
                      />
                    )}
                  />}


                {!hideFields.includes("Primary Skill") && <Controller
                    control={control}
                    name='primaryskill'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={primaryskillList}
                        // defaultValue={primaryskillList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("primaryskill", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Primary Skill" label={`Primary Skill`} />}
                      />
                    )}
                  />}

                  {!hideFields.includes("Mandate Type") && <Controller
                    control={control}
                    name='mandatetype'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={mandateTypeList}
                        // defaultValue={mandateTypeList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("mandatetype", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Mandate Type" label={`Mandate Type`} />}
                      />
                    )}
                  />}

                {!hideFields.includes("industryData") && <div className="customeindustry">
                <Controller
                  name="industryData"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <>
                  <IndustryDropdown value={value} onChange={onChange} preSelectedItems={getValues("industryData") ?? []} enableLabel={true}/>
                    </>
                )}
                />
                </div>}

                {!hideFields.includes("Role") &&  <Controller
                    control={control}
                    name='role'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        disabled={true}
                        options={rolesdataList}
                        // defaultValue={rolesdataList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("role", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Role" label={`Role`} />}
                      />
                    )}
                  />}

                  {!hideFields.includes("Job Mode") && <Controller
                    control={control}
                    name='jobmode'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={jobModeList}
                        // defaultValue={jobModeList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("jobmode", value)   
                          if(value?.label == "All"){
                            clearLocationField();
                            clearLocationMandateField();
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Job Mode" label={`Job Mode`} />}
                      />
                    )}
                  />}
              
            </div>
            
          </Box>


          <Box>
            <Grid >
            <Grid item xs={12} sm={6} sx={{display:'flex', justifyContent:'end', borderTop:'1px solid #E5E8EB',paddingTop:'10px'}}>
              <Stack gap={1} direction='row'>
              <Button
                    sx={{
                      color: '#002882',
                      textTransform: 'none',
                    }}
                    variant='text'
                    // onClick={resetAllFilters}
                  >
                    <RotateIcon />
                    <Typography className='reset-label' onClick={resetFilterData}>Reset All</Typography>
                  </Button>
                  <Box className='filter-search-btn'>
                    <AppButton
                      label='Apply'
                      bordercolor='black'
                      bgcolor='black'
                      size='large'
                      type='submit'
                      className='search-bth'
                      sx={{ color: '#fff' }}
                    />
                  </Box>
                  
                </Stack>
            </Grid>
          </Grid>
            </Box>


        </Box>
        </Box>
      </>
    )
  }

    return (
        <>


<Box sx={{marginBottom:'5px'}}>
            <div>
                <Paper className='box-style' elevation={1} sx={{ backgroundColor: '#f3f3f7' }}>
                    {!hideDashHeader && <Box display='flex' justifyContent='space-between' className='filter-parent-box' sx={{ backgroundColor: '#f3f3f7' }}>
                        <Box className='tasklist'>
                            <Typography display='flex' gap={1} className='tasklist-title' style={{ paddingTop: '15px' }}>
                                <Breadcrumb title='Dashboard' />
                            </Typography>
                        </Box>
                        <Box display='flex' justifyContent='space-between'>
                            {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                            <Box>

                                <AppButton
                                    variant='contained'
                                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                                    margin={5}
                                    minwidth={40}
                                    onClick={handleFilter}
                                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                                >
                                    {' '}
                                    <FunnelIcon className='add-new-icon' />{' '}
                                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                </AppButton>

                                <Button
                                    sx={{
                                    color: '#002882',
                                    textTransform: 'none',
                                    }}
                                    variant='text'
                                    // onClick={resetAllFilters}
                                    >
                                    <WidgetIcon />
                                    <Typography className='reset-label'>Widget</Typography>
                                </Button>
                                
                            </Box>
                        </Box>
                    </Box>}


                    {filterChecked && (
                        <Box>
                        <FilterForms />
                      </Box>
                    )}
                    {hideDashHeader && (
                        <Box>
                        <FilterForms />
                      </Box>
                    )}
                </Paper>
            </div>
        </Box>
          
        </>
    )
}
export default FilterComponent