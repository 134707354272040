import React from 'react'
import {
  Mail as MailIcon,
  Notifications as NotificationsIcon,
  Fingerprint as FingerprintIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
  List as PreferencesIcon,
} from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { ReactComponent as ToogleOpenIcon } from '../../assets/icons/right-toggle-open.svg'
import { ReactComponent as ToogleCloseIcon } from '../../assets/icons/right-toggle-close.svg'
import { ActionItem } from './ActionItem'

interface ActionProps {
  total?: number
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  disableTooltip?: boolean
  open?: boolean
}

export const Messages = ({ total, onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='My Messages'
    icon={MailIcon}
    onClick={onClick}
    badgeContent={total}
    disableTooltip={disableTooltip}
  />
)

export const More = ({ onClick, disableTooltip = false, open }: ActionProps) => (
  <ActionItem
    title='More'
    icon={open ? ToogleCloseIcon : ToogleOpenIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)

export const NavigationMenus = ({ onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='NavigationMenus'
    icon={MenuIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)
export const Notifications = ({ total, onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='Notifications'
    icon={NotificationsIcon}
    onClick={onClick}
    badgeContent={total}
    disableTooltip={disableTooltip}
  />
)

export const CallLogs = ({ total, onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='Call Logs'
    icon={FingerprintIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)

export const UserAccount = ({ onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='My Account'
    icon={FingerprintIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)

export const SignOut = ({ onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='Sign Out'
    icon={LogoutIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)

export const Settings = ({ onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='Settings'
    icon={SettingsIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)

export const Preferences = ({ onClick, disableTooltip = false }: ActionProps) => (
  <ActionItem
    title='Preferences'
    icon={PreferencesIcon}
    onClick={onClick}
    disableTooltip={disableTooltip}
  />
)
