import classNames from 'classnames';
import "../Profile.scss"

const Modal = ({ open, children }:any) => {
  return (
    <div
      className={`${open ? "profileModalDisplayEnable" : "profileModalDisplaydisable"}`}
      id="profileModal"
    >
      <div className="profileModalSect">
        <div className="fixed-transition-opacity">
          {/* <div className=""></div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" /> */}
          <div
            className="profileUploadDialogBox"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="profileUploadDialogBoxBlk">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
