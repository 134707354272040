import './styles.scss'
import { Avatar, IconButton, Box, Alert, Snackbar } from '@mui/material'
import { ReactComponent as CallIcon } from '../../../../assets/icons/missed-call.svg'
import { smartflowCallLog } from '../../../../services/CandidateService'
import { useEffect, useState } from 'react'
import { ReactComponent as ViewNotes } from '../../../../assets/icons/ViewNotes.svg';
import WaveFormUI from './WaveForms/WaveForm'
import { ReactComponent as HangCall } from '../../../../assets/icons/hang.svg'
import moment from 'moment'
import { Loading } from "../../../../pages/Loading";
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'




const CallLogs = ({candidateDetailsData}:any) => {

  const renderEmptySearchBox = () => {
    const emptyMessage = `No data found`;
    return (
        <Box className="candidate-empty-search-box candidateDetailWraper">
            <Box>
                <NoResultFound />
            </Box>
            <Box className="empty-search-message">
                {emptyMessage}
            </Box>
        </Box>
    )
}

  const [callRecords, setCallRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [serverError, setServerError] = useState<boolean>(false)
  const [smartfloErrMsg,setSmartfloErrmsg] = useState<string>("")
  const [inOutBound, setInOutBound] = useState(false)

  const callDetails = async () => {
    setIsLoading(true)
    try{
      const res = await smartflowCallLog(candidateDetailsData?.phoneNumber,inOutBound)
      console.log(res)
      if(res?.data?.length>0){
        if(res?.data[0]?.error && res?.data[0]?.error === "Smartflo Cred not Found" ){
          setSmartfloErrmsg(res?.data[0]?.message)
          setServerError(true)
        }
        else{
          setSmartfloErrmsg("")
          setServerError(false)
          setCallRecords(res?.data)
        }
        
      }else{
        setCallRecords([])
    }
    }catch(e){
      setCallRecords([])
      setServerError(true)
    }
    setIsLoading(false)

    // setTimeout(()=>{
    //   setCallRecords(mockData)
    //   setIsLoading(false)
    // }, 1000)
  }
  
  useEffect(()=>{
    callDetails()
  },[inOutBound])

  const stringAvatar = (name:string) => {
    return {
      children: `${name?.split(' ')[0][0]}${name?.split(' ')[1] ? name?.split(' ')[1][0] : ""}`
    }
  }

  const formatTime = function (time:any) {
    const arr = [
        Math.floor((time % 3600) / 60) || 0 , // minutes
        ('00' + (Math.floor(time % 60) || 0)).slice(-2)   // seconds
    ]
    
    return `${arr[0]??0} min ${arr[1]??0} sec`
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setServerError(false)
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  return (
    <div className='call-logs-wrap'>
      {isLoading ? 
        <div className='loader-container'>
          <Loading/>
        </div> :
      <div className='rec-container'>
        <Box className='callLogToggle'>
          <Stack direction="row" spacing={1} alignItems="center" >
            <Typography>Outbound</Typography>
            <AntSwitch checked={inOutBound} onClick={(e:any) => {setInOutBound(e.target.checked)}} inputProps={{ 'aria-label': 'ant design' }} />
            <Typography>Inbound</Typography>
          </Stack>
        </Box>
        {callRecords?.length > 0 && callRecords?.map((list:any, index:number)=>{
          return(
            <div key={index} className='record-row'>
              <div className='log-details flex-row'>
                <div>
                  <IconButton>
                    {list?.status === "missed" ? <HangCall/> : <CallIcon/>}
                  </IconButton>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    className='primary-text'
                  >
                    {moment(`${list?.date} ${list?.time}`).format('llll')}
                  </span>
                  <span
                    className='secondary-text'
                  >
                    {formatTime(list?.call_duration)}
                  </span>
                </div>
              </div>
              {/* <div className='log-details flex-row' style={{gap:"unset"}}>
                <IconButton>
                  <ViewNotes/>
                </IconButton>
                <span className='view-notes' >View Notes</span>
              </div> */}
              <div className='log-details'>
                {(list?.recording_url) && (list?.status !== "missed") ? 
                  <WaveFormUI audio={list?.recording_url} duration={list?.call_duration}/> 
                  : <div className='no-record-url'>
                      <span className='no-record'>No recordings</span>
                  </div> 
                }
              </div>
              <div className='log-details flex-row'>
                <div>
                  {list?.agent_name != null &&
                  <Avatar style={{ backgroundColor: '#DFF3E8', color: '#2BAD63', fontSize:"14px" }}
                    {...stringAvatar(list?.agent_name)}
                  />
                  }
                </div>
                <div style={{
                  display:"flex", 
                  flexDirection:"column"
                }}>
                  {list?.xpheno_user_name != null && <span className='primary-text'>{list?.xpheno_user_name}</span>}
                  {list?.xpheno_user_role != null && <span className='secondary-text'>{list?.xpheno_user_role}</span>}
                </div>
              </div>
            </div>
          )
        })}
        {callRecords?.length === 0 && <div style={{display:"flex", justifyContent:"center"}}>
            {renderEmptySearchBox()}
          </div>}
      </div>}
      <Box>
        <Snackbar
          open={serverError}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          >
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} variant='filled'>
              {smartfloErrMsg && smartfloErrMsg !=""?smartfloErrMsg:"Server Error"}
          </Alert>
          </Snackbar>
        </Box>
    </div>
  )
}

export default CallLogs
