/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Breadcrumb } from '../components/Breadcrumb'
import Calendar from '../components/Calendar/calendar'
import { AppButton } from '../components/AppComponents/AppButton'
import { ReactComponent as DateIcon } from '../assets/icons/date .svg'
import { Box, Button } from '@mui/material'
import SchedulerPopup from '../components/Calendar/SchedulerPopup'
import { useState, useEffect } from 'react'
import { getScheduleEvents } from '../services/SchedulerServive'
import googleIcon from "../assets/images/googleIcon.svg"

function Scheduler() {
  const [showAddEventpopup, setShowAddEventpopup]= useState(false)
  const [eventsList, setEventsList]= useState<any>([])
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [editEventData, setEditEventData] = useState<any>(null)
  const [calendarIds, setCalendarIds] = useState<any>([]);
  const [logStatus, setLogStatus] = useState({
    label:"Sign in with Google",
    status: false,
    buttonStatus: false
  });
  const getEventList = async () => {
    const res = await getScheduleEvents();
    if(res?.status == 200 && res?.data?.length>0){
      setEventsList(res?.data)
    }
  }

  const handleEventClick = (e:any) => {
    const editData = eventsList.find((data:any) => data?.id === e?.event?.id)
    setEditEventData(editData)
    setIsEdit(true)
    setShowAddEventpopup(true)
  }

  const handleAddEvent = () => {
    setIsEdit(false)
    setShowAddEventpopup(true)
  }


  useEffect(()=>{
    getEventList() // get event list from API call
  },[])


  return (
    <Box>
      <Box sx={{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between'
    }}>
      <Breadcrumb title='Scheduler' />
      <Box sx={{display:'flex',alignItems:'center',gap:'10px'}}>
      <div className='syncGoogleBtn' style={{pointerEvents: logStatus?.buttonStatus ? "none" : "auto"}}>
        <AppButton 
        className='syncGoogleBtnHandler'
      bgcolor={logStatus?.buttonStatus ? "transparent" : "#4f86ec"}
      textColor={logStatus?.buttonStatus ? "#1a85d6" : "#fff"}
      onClick={() => {setLogStatus({...logStatus, status: true,buttonStatus: false})}}
      >
        {!logStatus?.buttonStatus && <img src={googleIcon}/>}<span style={{color: "#000",marginRight:"5px"}}>{logStatus?.buttonStatus ? "Logged in as " : ""}</span><span style={{textDecoration: logStatus?.buttonStatus ? "underline": "none"}}>{logStatus?.label}</span>
      </AppButton>
      </div>
      <AppButton 
      bgcolor='#002882'
      textColor='#fff'
      onClick={handleAddEvent}
      className='candidateInterviewHandler'
      >
      <Box sx={{display:'flex',alignItems:'center',gap:'10px'}}>
        <DateIcon/><span>Candidate Interview</span>
      </Box>

     </AppButton>
     </Box>
      </Box>
     
      <div
        css={css`
          text-align: center;
          margin-top: 1rem;
        `}
      >
        <Calendar calendarIds={calendarIds} setCalendarIds={setCalendarIds} eventsList={eventsList} handleEventClick={handleEventClick} logStatus={logStatus} setLogStatus={setLogStatus}/>
      </div>
      {showAddEventpopup && <SchedulerPopup calendarIds={calendarIds} showPopup={setShowAddEventpopup} getEventList={getEventList} editEventData={editEventData} isEdit={isEdit} />}
    </Box>
  )
}

export default Scheduler
