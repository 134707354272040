import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../Breadcrumb';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Checkbox, FormControl, InputLabel, Input, InputAdornment, OutlinedInput, MenuItem, Select, Pagination, Tooltip, capitalize } from "@mui/material"
import { ReactComponent as SortUpDown } from '../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../assets/icons/sort-down.svg'
import { ReactComponent as NoResultFound } from '../../assets/icons/no-result-found.svg'
import UserListEdit from "../../assets/icons/userListEdit.svg"
import UserFilter from './UserFilter';
import { useNavigate } from 'react-router-dom';
import { getAllUserList, getUserDetail } from '../../services/UserService';
import { convertToDisplayDateTime } from '../../utils/date-utils';

const UserList = () => {
    const [sortColumn, setSortColumn] = useState<any>()
    const [paginationCountSize, setPaginationCountSize] = useState({
        pageSize: 10,
        pageCount: 1
      })
    const [sortingTableHead,setSortingTableHead] = useState<any>({
        "employee_code": "",
        "username": "",
        "user_email": "",
        "bu_name": "",
        "updated_at": "",
        "updated_by_username": "",
        "status": ""
    })
    const [userList, setUserList] = useState([])
    const [userListInfo, setUserListInfo] = useState<any>({})
    const navigate =useNavigate()
	const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [userListFilter,setUserListFilter] = useState<any>({})
    const [pageSize, setPageSize] = useState(10)
    const [pageCount, setPageCount] = useState(1);

    const getUserDetailInfo = async (userId:string) => {
        const res:any = await getUserDetail(userId)
        if(res.status == 200){
            navigate(`/viewuserdetails`,{state:{userDetail: res.data,status:"create"}})
        }
    }
    const userEditList = async (userId:string) => {
        const res:any = await getUserDetail(userId)
        if(res.status == 200){
            navigate(`/createuser`,{state:{userDetail: res.data,status:"edit"}})
        }
    }
    
    const getAllUserListApi = async () => {
        const sortingTableHeadObj = JSON.parse(JSON.stringify(sortingTableHead));
        const selectedSort:any[] = []
        Object.keys(sortingTableHeadObj).map((item:any) => {
            if(sortingTableHeadObj[item].length > 0){
                selectedSort.push({
                    key: item,
                    value: sortingTableHeadObj[item]
                })
            }
        })
        const userObj = {
            "keyword":userListFilter.keyword,
            "businessUnit":userListFilter?.businessUnit,
            "reportingTo":userListFilter?.reportingTo,
            "status":userListFilter?.status,
            "sortDto":{
                "sortColumn":selectedSort.length > 0 ? selectedSort[0]?.key:"",
                "sortDirection":selectedSort.length > 0 ? selectedSort[0]?.value:""
            },
            "pageSize":paginationCountSize.pageSize,
            "pageNo":paginationCountSize.pageCount
        }
        try{
          const res:any = await getAllUserList(userObj)
          setUserList(res.data.result?.list)
          setUserListInfo(res.data.result)
          setPageSize(res?.data?.result.pageSize)
          setPageCount(res?.data?.result.totalPages)
        }
        catch(error:any){
            console.log(error)        
          }
    }

    const sortTableHeadHandler = (tableHead:string) => {
        const sortTableObj = JSON.parse(JSON.stringify(sortingTableHead));
        const selectedTableHead = sortTableObj[tableHead]
        Object.keys(sortTableObj).map((item:any) => {
            sortTableObj[item] = "" 
        })
        sortTableObj[tableHead] = selectedTableHead == "" ? "Asc" : selectedTableHead == "Asc" ? "Desc" : "";
        setSortingTableHead(sortTableObj)
    }

    useEffect(() => {
        getAllUserListApi()
    },[sortingTableHead,userListFilter,paginationCountSize])

    const updatePagination = (paginationKey:any, changeValue:any) => {
        setPaginationCountSize(prevState => ({...prevState,[paginationKey]: changeValue}))
    }
    
    const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }
    
    return (
        <div>
            <Breadcrumb title='Artemis Users' />
            <Box>
				<UserFilter totalList={userListInfo?.totalRecords} setUserListFilter={setUserListFilter}/>
				<Box className="reportTableContSect">
					<TableContainer className="submissionReportContainer userListTableSect" component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									{/* <TableCell>
										<Checkbox
											// onChange={}
											checked={false}
											className='search-result-item-checkbox'
										/>
									</TableCell> */}
									
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("employee_code")}>
                                        {"employee code"}
                                        {sortingTableHead && sortingTableHead["employee_code"].length > 0 ? (sortingTableHead["employee_code"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("username")}>
                                        {"username"}
                                        {sortingTableHead && sortingTableHead["username"].length > 0 ? (sortingTableHead["username"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("user_email")}>
                                        {"user email"}
                                        {sortingTableHead && sortingTableHead["user_email"].length > 0 ? (sortingTableHead["user_email"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("bu_name")}>
                                        {"Bussiness Unit"}
                                        {sortingTableHead && sortingTableHead["bu_name"].length > 0 ? (sortingTableHead["bu_name"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell style={{minWidth:"200px"}}>
                                    <div onClick={() => sortTableHeadHandler("updated_at")}>
                                        {"Last Modified Date"}
                                        {sortingTableHead && sortingTableHead["updated_at"].length > 0 ? (sortingTableHead["updated_at"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("updated_by_username")}>
                                        {"Last Modified By"}
                                        {sortingTableHead && sortingTableHead["updated_by_username"].length > 0 ? (sortingTableHead["updated_by_username"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => sortTableHeadHandler("status")}>
                                        {"User Status"}
                                        {sortingTableHead && sortingTableHead["status"].length > 0 ? (sortingTableHead["status"] == "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div>
                                        {"ACTION"}
                                    </div>
                                </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
                                <>
                                {userList.length > 0 ? <>
                                {userList.map((userList:any) => {
                                    return (
                                        <>
                                        <TableRow>
                                            <TableCell><Tooltip title={userList?.employee_code}><div>{userList?.employee_code}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={userList?.firstname}><div onClick={() => getUserDetailInfo(userList?.keycloak_id)}>{userList?.firstname}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={userList?.user_email}><div>{userList?.user_email}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={userList?.bu_name}><div>{userList?.bu_name}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={userList?.updated_at != null ? convertToDisplayDateTime(userList?.updated_at) : "-"}><div>{userList?.updated_at != null ? convertToDisplayDateTime(userList?.updated_at) : "-"}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={userList?.updated_by_username != null ? userList?.updated_by_username : "-"}><div>{userList?.updated_by_username != null ? userList?.updated_by_username : "-"}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={userList?.status_name != null ? capitalize(userList?.status_name) : "-"}><div>{userList?.status_name != null ? capitalize(userList?.status_name) : "-"}</div></Tooltip></TableCell>
                                            <TableCell><Tooltip title={"Edit User"}><img style={{cursor:"pointer"}} onClick={() => userEditList(userList?.keycloak_id)} src={UserListEdit} /></Tooltip></TableCell>
                                        </TableRow>
                                        </>
                                    )
                                })}</>:renderEmptySearchBox()}
                                </>
							</TableBody>
						</Table>
					</TableContainer>
					<Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={pageSize}
									label="Age"
									onChange={(event:any) => {setDefaultPageCount(1);setPageSize(event.target.value);updatePagination("pageSize",event.target.value)}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={pageCount} page={defaultPageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setDefaultPageCount(pageNumber);updatePagination("pageCount",pageNumber)}}/>
					</Box>
					</Box>
				</Box>
        </div>
    );
};

export default UserList;