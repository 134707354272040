import React from 'react'
import './RecruiterCard.scss'
import { Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';

const TargetContainer = ({ acheived, target, status, statusType }: any) => {
    return (
        <div className='Target-container'>
            <Tooltip title={statusType}>
                <div className={`box ${status}`}>
                    {/* <div className={`side-line ${status}`}></div> */}
                    <Typography className= "job-count">
                        {/* <span>{acheived ? acheived : 0}</span>/ 32 */}
                        <span>{acheived ? acheived : 0}</span>
                    </Typography>
                </div>
            </Tooltip>
            {/* <Tooltip title={statusType}>
                <div className='box target-box'>
                <div className={`side-line target`}></div>

                    <Typography className='job-count-target' >
                        {target ? target : 0}

                    </Typography>
                </div>
            </Tooltip> */}
        </div>
    )
}

export default TargetContainer