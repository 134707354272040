import { 
  Autocomplete, 
  Box, 
  Button, 
  Checkbox, 
  Divider, 
  FormControl, 
  Grid, 
  IconButton, 
  InputLabel, 
  MenuItem, 
  Modal, 
  Select, 
  TextField, 
  Typography ,
  ListItemText,
  OutlinedInput,
  FormHelperText
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close'
import { AppButton } from "../../../AppComponents/AppButton";
import { useForm, Controller,useFieldArray ,useFormContext} from "react-hook-form";
import RangeSlider from "../../../Common/RangeSlider/RangeSlider";
import potentialRevenue from "../../../../assets/icons/potential-revenue.svg"
import { dropDownLocation, locationScrapper } from '../../../../services/MandateService'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/modalcloseicon.svg';
import { useAppSelector } from "../../../../store/hooks";
import { Textarea } from "@mobiscroll/react";
import "./MandateLocationFieldForm.scss"
import CommonFormField from "../../CommonFormField/CommonFormField";
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg'
import * as _ from "lodash";
import { emailRegex } from "../../../../utils/constants";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 3,
}

const preLocations = [
  {
    children: [],
    id: 0,
    locationDisplay: "Remote",
    locationName: "Remote",
    status: 1
  },
  {
    id: 1,
    locationDisplay: "Hybrid",
    locationName: "Hybrid",
    status: 1
  },
  {
    id: 2,
    locationDisplay: "Location not specified",
    locationName: "Location not specified",
    status: 1
  },
]
const secLocations = [
  {
    children: [],
    id: 0,
    locationDisplay: "Not Applicable",
    locationName: "Not Applicable",
    status: 1
  }
]

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const MandateLocationFieldForm = ({
  mandatelocationList, setMandateLocationList,
  setDeletedMandateLocation,deletedMandateLocation,editedMandateLocation,
  setEditedMandateLocation, setAddMandateLocation,addMandateLocation,dropdownOption
}:any) => {

  const userId = useAppSelector((state:any) => state?.auth?.userId)
  const configTemplate = useAppSelector((state:any) => state.SystemConfig.businessUnitTemplate)
  const buTemplate = configTemplate ? configTemplate : []

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [locationlist2, setLocationsList2] = useState<any>([])
  const [primaryList, setPrimaryList] =useState<any>([])
  const [secondaryList,setSecondaryList] = useState<any>([])
  const [editValue, setEditValue] = useState<any>({})

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [remarksRequired, setRemarksRequired] = useState(false)
  const phoneNumberRegex = /^([+\d].*)?\d$/gm
  useEffect(() => {
    if(!isEdit) setRemarksRequired(false)
  },[isEdit])

  const initValues = {
    // region: "",
    primaryLocation: "",
    // mandatelocations: null,
    jobOrderCount:null,
    secondaryLocations: [],
    // clientSpoc: "",
    currency: "INR",
    salaryRange: [1, 100],
    remarks: "",
    mandateLocationSpocs:[{spocName:"", spocEmail:"",spocType:"",spocPhone:""}],
    pricingModel:"Rate card",
    monthlyBillRate:"500000",
    payRate: "500000"
  }

  const {
    handleSubmit:childHandleSubmit,
    reset,
    watch,
    setValue,
    control:childControl,
    formState:{errors:childErrors} ,
    clearErrors,
    getValues
  } = useForm<any>({mode: "onChange", defaultValues: initValues});

  const fetchLocations = async () => {
    const reqObj = {
      "filters": [
          {
              "field": "parentId",
              "operator": "equals",
              "type": "string",
              "value": "0"
          }
      ],
      "page": {
          "pageNo": -1,
          "pageSize": 10
      },
      "sorting": {
          "direction": "ASC",
          "field": "createdAt"
      }
  }
    try{
      const res:any = await dropDownLocation(reqObj)

      setLocationsList2(res.data.result.list);
      setPrimaryList([...preLocations, ...res.data.result.list]);
      setSecondaryList([...secLocations, ...res.data.result.list]);

    } catch {
      setLocationsList2([]);
      setPrimaryList([]);
      setSecondaryList([]);
    }
  }


  useEffect(()=>{
    const updatedOptions = locationlist2.filter((option:any) => watch('primaryLocation') !== option.locationName);
    setSecondaryList([...secLocations,...updatedOptions]);
  },[watch('primaryLocation')])


  useEffect(()=>{
    const temp = locationlist2?.filter((option:any) => {
       return !watch("secondaryLocations")?.includes(option.locationName)
     });
     setPrimaryList([...preLocations,...temp])
    },[watch("secondaryLocations")])

  useEffect(()=>{
    fetchLocations()
  },[])

  const calculationFun = () =>{
    const ptChatField = buTemplate.find((temp:any) => temp?.field_label.includes("Potential"));
    let newForm = ptChatField?.formula;
    const setFieldId = ptChatField?.field_id;

    [...buTemplate.map((x:any) => x.field_id), "txt_job_orders"].forEach((x :any ,index:number)=> {
      if(newForm?.includes(x)) {
        if(x === "txt_job_orders"){
          newForm = newForm.replace("txt_job_orders" , watch("jobOrderCount") || "0")
        }else{
          newForm = newForm.replace(x , watch(x) || "0")
        }
      }
    })
    setValue(setFieldId, String(eval(newForm)))
  }
  const watchFields = watch([...buTemplate.map((x:any) => x.field_id)].filter((y:any) => (y !=="lbl_pr" && y !== "lbl_gp")))
  useEffect(()=>{
    if(buTemplate.length){
      calculationFun()
    }
  },[watchFields, watch("jobOrderCount")])

  const mandateSubmitHandler = (data:any) => {
    const formData:any = {...data}
      const obj:any = {
        primaryLocation: data?.primaryLocation,
        // position: Number(data?.mandatelocations),
        jobOrderCount : Number(data?.jobOrderCount),
        secondaryLocations : Array.isArray(data?.secondaryLocations) ? data?.secondaryLocations : JSON.parse(data?.secondaryLocations)  ?? [],
        // clientSpoc: data?.clientSpoc,
        mandateLocationSpocs:data?.mandateLocationSpocs,
        remarks: data?.remarks,
        // region: data?.region,
        ctcRangeStart: Number(data?.salaryRange[0]),
        ctcRangeEnd: Number(data?.salaryRange[1]),
        updatedBy: userId,
      }
      const priceArr = buTemplate.map((temp:any) => {
        return {
          ...temp,
          value: formData[temp?.field_id]
        }
      })
      obj['priceData'] = priceArr
      if(data?.id) obj["id"] = data?.id
      const dataList = data?.index ? mandatelocationList.map((x:any,index:number)=>  index + 1 == data?.index ? obj : x) : [...mandatelocationList,obj]
      if(obj?.id){
        const editedList = obj
        const distinctObj = editedMandateLocation.filter((manLoc:any) => manLoc.id !== obj.id)
        setEditedMandateLocation([editedList,...distinctObj])
      }
      else{
        setAddMandateLocation([obj, ...addMandateLocation])
      }
      setMandateLocationList(dataList)
      setIsOpen(false)
      setIsEdit(false)
  }

  const handleEditMandateLocation = (editData:any,index:number) => {    
    const editValues:any = {
      // region: editData?.region,
      primaryLocation: editData?.primaryLocation,
      jobOrderCount : editData?.jobOrderCount || editData?.jobOrders,
      secondaryLocations : Array.isArray(editData?.secondaryLocations) ?  editData?.secondaryLocations :  editData?.secondaryLocations ? JSON.parse(editData?.secondaryLocations) : [],
      // positions: editData?.position,
      // clientSpoc: editData?.clientSpoc,
      mandateLocationSpocs:editData?.mandateLocationSpocs || editData?.locationsSpoc,

      salaryRange: [editData?.ctcRangeStart, editData?.ctcRangeEnd],
      remarks: editData?.remarks,
  
      currency: "INR",
      pricingModel:"Rate card",
      monthlyBillRate:"500000",
      payRate: "500000"
    }

    setEditValue(editValues)
    setRemarksRequired(false)

    if(editData?.priceData?.length){
      let priceList = [];
      if(editData?.id){
        priceList = editData?.priceData ?  Array.isArray(editData?.priceData) ? editData?.priceData : JSON.parse(editData?.priceData) : [];
      }else{
        priceList = editData?.priceData;
      }
      priceList?.forEach((data:any)=>{
        editValues[data?.field_id] = data?.value
      })
    }

    if(editData?.id){
       editValues["id"] = editData.id
       editValues["index"] = index +1
    } else {
      editValues["index"] = index + 1
    }
    setIsEdit(true)
    reset(editValues)
    setIsOpen(true)
  }
  const handleDeleteMandateLocation = (deleteIndex:any) => {
    const deletedpos = mandatelocationList.splice(deleteIndex,1)
    if(deletedpos[0].id){
      setDeletedMandateLocation([deletedpos[0], ...deletedMandateLocation])
    }
    const updatedList = [...mandatelocationList]
    setMandateLocationList(updatedList)

  }

  const handlePopupClose = () => {
    setIsEdit(false)
    setIsOpen(false)
  }

  const SelectUnitLabel = () => {
    return(
      <div className='select-unit-wrapper'>
        <span className='lable-text-select' style={{paddingBottom:"unset"}}>Annual CTC  (in Lakhs)</span>
        <div className='unit-container'>
          <span>Select Unit:</span>
          <FormControl size="small">
          <Controller
            name='currency'
            control={childControl}
            render={({field : {onChange, value, onBlur}})=>(
              <Select
                value={value}
                onChange={onChange}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem value={"INR"}>INR</MenuItem>
                {/* <MenuItem value={"USD"}>USD</MenuItem> */}
              </Select>
            )}
          />
          </FormControl>
        </div>
      </div>
    );
  }

  // const { control, formState: { errors }, getValues } : any = useFormContext()

  const { fields: spocFields, append : spocAppend, remove: spocRemove } = useFieldArray<any>(
    {
      control:childControl,
      name: "mandateLocationSpocs"
    }
  );
  const watchFieldArray = watch("mandateLocationSpocs");
  const checkLocationObj = () => {
   const editValueObj = editValue;
   editValueObj.salaryRange[0] = 1; 
   const currentObj = getValues()
    if(JSON.stringify(editValueObj) == JSON.stringify(currentObj)) {
      setRemarksRequired(false)
      clearErrors("remarks")
    }else{
      setRemarksRequired(true)
    }  
  }
  
  return (
    <Grid className='container-wrapper mandate-location-wrapper'>
      <Box>
        {mandatelocationList?.length === 0 && <Typography className="no-mandate-location-text" >Add Minimum 1  location to proceed</Typography>}
        {mandatelocationList?.length > 0 && (
          <Box style={{display:"flex", gap:"10px", flexWrap:"wrap"}}>
          {mandatelocationList?.map((item:any, index:any)=> (
            <Button
            key={index}
            className="mandate-location-tag"
            >
              {`${item?.location || item?.primaryLocation} (${item?.jobOrderCount || item?.jobOrders})`}
              <EditIcon onClick={()=>handleEditMandateLocation(item,index)} />
              <DeleteIcon onClick={()=> handleDeleteMandateLocation(index)} />
            </Button>
          ))}
        </Box>)}
      </Box>
      <Box className='button-container' 
        style={{justifyContent:"flex-start", padding: "1rem 0rem"}}
      >
        <Button 
          className='draft-btn'
          onClick={() => {
            reset(initValues)
            setIsOpen(true)
          }}
        >
          + Add Location
        </Button>
      </Box>
      <Modal
        open={isOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='mandate-location-field-model'
      >
        <Box 
          sx={style}
          component="form"
          noValidate
          autoComplete="off"
          className="form-modal"
        >
          <div className='assign-mandates-header'>
            <Typography variant='h6' component='h3'>
              {isEdit ? "Edit" : "Add"} Location
            </Typography>
            <IconButton
              aria-label='close'
              onClick={handlePopupClose}
              sx={{
                position: 'inherit !important',
                color: '#000000',
              }}
              className='app-dialog-title-icon'
            >
              <CloseIcon style={{cursor:"pointer"}} fontSize='medium' />
            </IconButton>
          </div>
          <Box style={{padding:"20px 0px"}}>
            <Grid className='row-grid'>
              {/* <FormControl fullWidth className="gray-out" >
                <InputLabel>Region</InputLabel>
                <Controller
                  name="region"
                  control={childControl}
                  // rules={{
                  //   required: {
                  //       value: true,
                  //       message: "Region is required",
                  //   }
                  // }}
                  render={({field : {onChange, value, onBlur}})=>(
                    <Select
                      label='Region'
                      value={value}
                      onChange={onChange}
                      disabled
                    >
                      <MenuItem value="North" >North</MenuItem>
                      <MenuItem value="South">South</MenuItem>
                      <MenuItem value="West">West</MenuItem>
                    </Select>
                  )}
                />
                {childErrors?.region && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      {childErrors?.region?.message as string}
                  </span>
                )}
              </FormControl> */}
          <FormControl fullWidth required >
              <Controller
                  name="primaryLocation"
                  control={childControl}
                  rules={{
                    required: {
                        value: true,
                        message: "Primary Location is required",
                    }
                  }}
                  render={({field : {onChange, value, onBlur}})=>{
                    return(
                      <Autocomplete
                        id="tags-standard"
                        options={primaryList}
                        getOptionLabel={(option:any) => option?.locationName}
                        disableCloseOnSelect
                        disableListWrap
                        value={primaryList.find((loc:any) => loc.locationName === value)}
                        onChange={(event, value) => {
                          onChange(value?.locationName)
                          if(isEdit)checkLocationObj()
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Primary Location"
                            required
                          />
                        )}
                        renderOption={(props, option, { selected }) => {
                          return(
                          <li {...props}>
                              <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                  sx={{
                                      '&.Mui-checked': {
                                          color: "#FF6E00",
                                      },
                                  }}
                              />
                              {option?.locationName}
                          </li>
                        )}
                        }
                      />
                    )
                  }}
                />
                {childErrors?.primaryLocation && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      {childErrors?.primaryLocation?.message as string}
                  </span>
                )}
              </FormControl>
              <FormControl fullWidth required >
                <Controller
                  name="secondaryLocations"
                  control={childControl}
                  rules={{
                    required: {
                        value: true,
                        message: "Secondary Location is required",
                    }
                  }}
                  render={({field : {onChange, value, onBlur}})=>{
                    return(
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      className="multiselect-limited-height"
                      options={secondaryList}
                      getOptionLabel={(option:any) => option?.locationName}
                      limitTags={1}
                      disableCloseOnSelect
                      disableListWrap
                      value={secondaryList.filter((list:any) => value?.includes(list?.locationName))}
                      onChange={(event, value) => {
                        const temp = value.map((loc:any) => loc?.locationName)
                        onChange(temp)
                        if(isEdit)checkLocationObj()
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Secondary Location"
                          required
                        />
                      )}
                      renderOption={(props, option, { selected }) => {
                        return(
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                sx={{
                                    '&.Mui-checked': {
                                        color: "#FF6E00",
                                    },
                                }}
                            />
                            {option?.locationName}
                        </li>
                      )}
                    }
                    />
                    )}}
                />
                {childErrors?.secondaryLocations && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      {childErrors?.secondaryLocations?.message as string}
                  </span>
                )}
              </FormControl>
              <FormControl fullWidth required className="manLocPhoneNumberField">
                <Controller
                  name="jobOrderCount"
                  control={childControl}
                  rules={{
                    required: {
                        value: true,
                        message: "Job Order is required",
                    }
                  }}
                  render={({field : {onChange, value, onBlur}})=>(
                    <TextField
                      type="number"
                      label='Job Orders'
                      value={value}
                      disabled={isEdit}
                      required
                      onChange={(e:any) => {
                        if(e?.target?.value.length == 1 && e?.target?.value == 0){
                          setValue("jobOrderCount","")
                        }else{
                          onChange(e?.target?.value)
                        }                        
                      }}
                      onBlur={onBlur}
                      inputProps={{
                        min: 0,
                        step: 1,
                      }}
                    />
                  )}
                />
                {childErrors?.jobOrderCount && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      {childErrors?.jobOrderCount?.message as string}
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid className='row-grid' style={{paddingTop:"24px"}}>
             
              <Grid className='res-grid manadateLocationPopup' item style={{width:"75%"}}>
                <SelectUnitLabel/>
                <FormControl fullWidth >
                <Controller
                    name='salaryRange'
                    control={childControl}
                    rules={{ validate: (value) => {
                      const val1:any = Number(value[0])
                      const val2:any = Number(value[1])
                      const val = (val1 <= val2) && (val2 >= val1)
                     return val}
                    }}
                    render={({ field: { onChange, value },fieldState }) => (
                      <>
                      <RangeSlider 
                          rangeValue={value}
                          setRangeValue={(e)=> {
                            onChange(e)
                            if(isEdit)checkLocationObj()
                          }}
                          minValue={0} 
                          maxValue={100}
                          rangeStep={1}
                          markStep={20}
                          onChangeHandler={(e)=> {
                            e.target.value[0] != 0 ? onChange(e.target.value) : ""
                            if(isEdit)checkLocationObj()
                          }}
                          labelText={"lakh"}
                          className={"mandate-slider"}
                      />
                      {fieldState.invalid ? (
                          <FormHelperText error>End value should be greater than start value</FormHelperText>
                      ) : null}
                      </>
                    )}
                />
                </FormControl>
              </Grid>
            </Grid>
            <Grid className='row-grid' style={{paddingTop:"24px"}}>
              <FormControl fullWidth>
                <Controller
                  name="remarks"
                  control={childControl}
                  rules={{
                    required: {
                        value: remarksRequired,
                        message: "Remarks is required",
                    }
                  }}
                  render={({field : {onChange, value, onBlur}})=>(
                    <TextField
                    className="textarea"
                      required = {remarksRequired}
                      label='Remarks'
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiline={true}
                      minRows={3}                
                    />
                  )}
                />
                {childErrors?.remarks && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      {childErrors?.remarks?.message as string}
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid className='row-grid pricing-group' style={{ gap: "10px", alignItems: "center", paddingTop: "24px" }}>
              <div className="clientspoc"
              >{"Hiring Manager SPOC"}</div>
              <Divider className='ruler-hr-spoc' />
            </Grid>
            {spocFields.map((item: any, index: any) => {
              return (
                <Grid key={item.id} className='row-grid' style={{ paddingTop: "24px" }}>
                    <FormControl fullWidth required className="clientspoc-field"  >
                      <Controller
                        name={`mandateLocationSpocs.${index}.spocName`}
                        control={childControl}
                        rules={{
                          required: {
                            value: true,
                            message: "Hiring Manager SPOC is required",
                          }
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <TextField
                            label='Hiring Manager SPOC'
                            required
                            value={value}
                            onChange={(e:any) => {
                              onChange(e.target.value)
                              if(isEdit)checkLocationObj()
                            }}
                            onBlur={onBlur}
                          />
                        )}
                      />
                     

                    {
                      _.get(childErrors, `mandateLocationSpocs[${index}].spocName.message`) &&
                      <span className="reminder_text-danger select-danger-text" role="alert">
                        {_.get(childErrors, `mandateLocationSpocs[${index}].spocName.message`) as string}
                      </span>
                    }
                      
                 
                    </FormControl>
                    <FormControl fullWidth required className="clientspoc-field"  >
                      <InputLabel id="demo-simple-select-label">SPOC Type</InputLabel>
                      <Controller
                        name={`mandateLocationSpocs.${index}.spocType`}
                        control={childControl}
                        rules={{
                          required: {
                            value: true,
                            message: "SPOC Type is required",
                          }
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="SPOC Type"
                          onChange={(e:any) => {
                            onChange(e.target.value)
                            if(isEdit)checkLocationObj()
                          }}
                        >
                          {dropdownOption["SPOC_Type"].length > 0 && dropdownOption["SPOC_Type"].map((item:any,index:number) => (
                              <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {
                      _.get(childErrors, `mandateLocationSpocs[${index}].spocType.message`) &&
                      <span className="reminder_text-danger select-danger-text" role="alert">
                        {_.get(childErrors, `mandateLocationSpocs[${index}].spocType.message`) as string}
                      </span>
                    }
                    </FormControl>
                    <FormControl className="manLocPhoneNumberField" fullWidth required >
                      <Controller
                        name={`mandateLocationSpocs.${index}.spocMobile`}
                        control={childControl}
                        rules={{
                          required: {
                            value: true,
                            message: "Phone Number field is required",
                          },
                          pattern: {
                            value: phoneNumberRegex,
                            message: `Please enter valid Phone`,
                          },
                          maxLength: {
                            value: 15,
                            message: "This input accept only max length of 15.",
                          },
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <TextField
                            label='Phone Number'
                            type="number"
                            value={value}
                            onChange={(e:any) => {
                              onChange(e.target.value)
                              if(isEdit)checkLocationObj()
                            }}
                            required={true}
                            onBlur={onBlur}
                          />
                        )}
                      />
               
                    {
                      _.get(childErrors, `mandateLocationSpocs[${index}].spocMobile.message`) &&
                      <span className="reminder_text-danger select-danger-text" role="alert">
                        {_.get(childErrors, `mandateLocationSpocs[${index}].spocMobile.message`) as string}
                      </span>
                    }
                    </FormControl>
                    <FormControl fullWidth required >
                      <Controller
                        name={`mandateLocationSpocs.${index}.spocEmail`}
                        control={childControl}
                        rules={{
                          required: {
                            value: true,
                            message: "Email field is required",
                          },
                          pattern: {
                            value: emailRegex,
                            message: `Please enter valid Email`,
                          },
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                          <TextField
                            label='Email'
                            type="email"
                            value={value}
                            onChange={(e:any) => {
                              onChange(e.target.value)
                              if(isEdit)checkLocationObj()
                            }}
                            required={true}
                            onBlur={onBlur}
                          />
                        )}
                      />
               
                    {
                      _.get(childErrors, `mandateLocationSpocs[${index}].spocEmail.message`) &&
                      <span className="reminder_text-danger select-danger-text" role="alert">
                        {_.get(childErrors, `mandateLocationSpocs[${index}].spocEmail.message`) as string}
                      </span>
                    }
                    </FormControl>
                    {spocFields.length >1 &&  <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => spocRemove(index)}
                      className='delete-icon'
                    >
                      <TrashIcon />
                    </div>}
                   


                </Grid>
              )
            })}

            {spocFields.length < 5 && <Box className='button-container'
              style={{ justifyContent: "flex-start", padding: "1rem 0rem" }}
            >
              <Button
                className='draft-btn'
                onClick={() => {
                  spocAppend({ key: '', value: null });
                }}
              >+ Add Hiring Manager SPOC</Button>
            </Box>}

           
            <Grid className='row-grid pricing-group' style={{gap: "10px", alignItems:"center", paddingTop:"24px"}}>
              <div style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#000",
                }}
                >{"Pricing"}</div>
                <Divider className='ruler-hr'/>
            </Grid>
            <Grid className="grid-layout" >
              {buTemplate.length > 0 && buTemplate.map((list:any, index:number) => (
                <CommonFormField
                  key={index}
                  list={list}
                  control={childControl}
                  error={childErrors}
                  fieldType='number'
                  watch={watch}
                />
              ))}
            </Grid>
          </Box>
          <Box className='bottom-buttons'>
            <AppButton 
              className='bottom-cancel-button' 
              onClick={handlePopupClose}
            >
              Cancel
            </AppButton>
            <AppButton 
              className='bottom-assign-button' 
              onClick={childHandleSubmit(mandateSubmitHandler)}
            >
              {isEdit ? "Update" : "Add"}
            </AppButton>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
}

export default MandateLocationFieldForm;