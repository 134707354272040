import { Container, Grid, Box, Typography, IconButton, Divider, Stack, CircularProgress } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useState, FC, useEffect } from 'react'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormInput } from '../FormInput'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import './ResetPassword.css'
import { ReactComponent as UserPassword } from '../../../assets/icons/login-password.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg'
import MainLogo from '../../../assets/images/xpheno.svg'
import ArtemisLogo from '../../../assets/images/Artemis.svg'
import { Slider } from '../SliderComponent/Slider'
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal'
import { getResetPasswordOtp, getResetPasswordUserId, isResetPasswordOtpEnabled, isResetPasswordSessionEnabled, removeResetPasswordOtp, removeResetPasswordUserId } from '../../../utils/user-utils'
import { ForgotPasswordService } from '../../../services/ForgotPasswordService'
import { useNavigate } from 'react-router'
import * as _ from "lodash";
import { ReactComponent as ForgotArrowIcon } from '../../../assets/icons/arrow-left.svg'

//  Login Schema with Zod
const userSchema = object({
  newpassword: string()
    .min(1, 'New Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters').refine((password) => { 
      const passwordRuleRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!%*?&])[A-Za-z\d@$!%*?#&]{8,}$/
      return passwordRuleRegex.test(password);
    }, {
      message: "Password is weak"
    }),
  confirmpassword: string()
    .min(1, 'Confirm Password is required')
    .min(8, 'Confirm Password must be more than 8 characters')
    .max(32, 'Confirm Password must be less than 32 characters'),
}).refine((data) => data.newpassword === data.confirmpassword, {
  path: ['confirmpassword'],
  message: 'Passwords do not match',
})

//  Infer the Schema to get the TS Type
type ResetPassword = TypeOf<typeof userSchema>

export const ResetPasswordScreen: FC = () => {
  // Default Values
  const defaultValues: ResetPassword = {
    newpassword: '',
    confirmpassword: '',
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showPassword)
  const [openModal, setOpenModal] = useState(false)

  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false);
  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);

  //  The object returned from useForm Hook
  const methods = useForm<ResetPassword>({
    resolver: zodResolver(userSchema),
    defaultValues,
  })

  const { setError, formState } = methods;

  //  Submit Handler
  const onSubmitHandler: SubmitHandler<ResetPassword> = (values: ResetPassword) => {
    showLoading();
    ForgotPasswordService.resetPassword({userId: getResetPasswordUserId(), otp: getResetPasswordOtp(), password: values.newpassword}).then((res) => {
      const message = _.get(res, 'data');
      if(message !== "password reset has completed") throw new Error(message)
      removeResetPasswordUserId();
      removeResetPasswordOtp();
      setOpenModal(true);
      hideLoading();
    }).catch((e) => {
      setError('confirmpassword', { type: 'custom', message: 'Reset password request failed, try again' });
      hideLoading();
    })
    // navigate('/login')
  }
  const handleClose = () => { 
    setOpenModal(false)
    navigate('/login')
  }

  const goBack = () => {
    navigate('/forgot-password')
  }

  const goBackToLogin = () => {
    navigate('/login')
  }

  useEffect(() => {
    if(!isResetPasswordSessionEnabled() || !isResetPasswordOtpEnabled()) {
      goBack();
    }
  }, [])


  return (
    <Container maxWidth={false}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className='login-screens-container-grid'
      >
        <Grid item className='reset-password-form-container-grid'>
          <FormProvider {...methods}>
            <Grid container className='login-screens-container-sub-grid'>
              <Grid
                item
                container
                sx={{
                  marginInline: 'auto',
                }}
              >
                {/* slidercomponent wrapper */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    paddingLeft: { sm: '3rem' },
                    paddingRight: { sm: '3rem' },
                    display: { sm: 'block', xs: 'none', md: 'block' },
                  }}
                >
                  <Slider />
                </Grid>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ mr: '-1px', display: { xs: 'none', md: 'block' } }}
                />
                {/* Formcomponent wrapper */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ paddingLeft: { sm: '3rem' }, paddingRight: { sm: '3rem' } }}
                >
                  <Box></Box>
                  <Box sx={{ paddingLeft: { lg: '3rem' }, mb: '1rem' }}>
                  <Box className="loginRightTopLogoSect">
                      <Box component='img' alt='Xpheno' src={MainLogo} />
                      <Box component='img' alt='Xpheno' src={ArtemisLogo} />                    
                    </Box>
                    <Stack direction='row' spacing={1}>
                      <ForgotArrowIcon onClick={goBackToLogin} className='back-button' />
                      <Typography className='login-screen-welcome-text'>Change Password</Typography>
                    </Stack>
                    <Stack
                      direction='row'
                      spacing={1}
                      className='login-screen-verified-text-container'
                    >
                      <CheckIcon />
                      <Typography className='login-screen-verified-text'>
                        OTP Verification Successful.
                      </Typography>
                    </Stack>

                    <Typography className='login-screen-welcome-note'>
                      Your password must contain minimum of 8 characters using ( symbol, lowercase,
                      uppercase characters, etc. like for e.g. Aj7F#!9m )
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{
                      paddingLeft: { md: '0', lg: '3rem' },
                      paddingRight: { md: '0', lg: '3rem' },
                      marginTop: 3,
                    }}
                    onSubmit={methods.handleSubmit(onSubmitHandler)}
                  >
                    <Typography
                      variant='h6'
                      component='p'
                      sx={{
                        paddingLeft: { lg: '3rem' },
                        textAlign: 'center',
                      }}
                    ></Typography>

                    <FormInput
                      label='New Password'
                      type={showPassword ? 'text' : 'password'}
                      name='newpassword'
                      focused
                      required
                      disabled={isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <UserPassword />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              className='eye-icon'
                            >
                              {showPassword ? (
                                <Visibility className='eye-icon' />
                              ) : (
                                <VisibilityOff className='eye-icon' />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormInput
                      type={showConfirmPassword ? 'text' : 'password'}
                      label='Confirm Password'
                      name='confirmpassword'
                      required
                      focused
                      disabled={isLoading}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <UserPassword />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              className='eye-icon'
                            >
                              {showConfirmPassword ? (
                                <Visibility className='eye-icon' />
                              ) : (
                                <VisibilityOff className='eye-icon' />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <LoadingButton
                      loading={isLoading}
                      type='submit'
                      variant='contained'
                      className='login-button'
                      loadingIndicator={<CircularProgress color="primary" size={30} />}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      <AppErrorHandlerModal
        open={openModal}
        content='Your password has been updated successfully'
        handleClose={handleClose}
        factor='Success'
        ButtonText='Done'
      >
        <></>
      </AppErrorHandlerModal>
    </Container>
  )
}
