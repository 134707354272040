import { useImageCropContext } from '../ImageCropProvider';
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  MinusIcon,
  PlusIcon
} from '@heroicons/react/24/solid';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import classNames from 'classnames';

export const ZoomSlider = ({ className }:any) => {
  const { zoom, setZoom, handleZoomIn, handleZoomOut, maxZoom, minZoom, zoomStep } =
    useImageCropContext();

  return (
    <div className={classNames(className, 'flex items-center justify-center gap-2')}>
      <button type='button' className="p-1" onClick={handleZoomOut}>
        <RemoveIcon />
      </button>
      <input
        type="range"
        name="volju"
        min={minZoom}
        max={maxZoom}
        step={zoomStep}
        value={zoom}
        onChange={e => {
          setZoom(Number(e.target.value));
        }}
      />
      <button type='button' className="p-1" onClick={handleZoomIn}>
        <AddIcon />
      </button>
    </div>
  );
};

export const RotationSlider = ({ className }:any) => {
  const {
    rotation,
    setRotation,
    maxRotation,
    minRotation,
    rotationStep,
    handleRotateAntiCw,
    handleRotateCw
  } = useImageCropContext();

  return (
    <div className={classNames(className, 'flex items-center justify-center gap-2')}>
      <button type='button' className="p-1" onClick={handleRotateAntiCw}>
        <RotateLeftIcon />
      </button>
      <input
        type="range"
        name="volju"
        min={minRotation}
        max={maxRotation}
        step={rotationStep}
        value={rotation}
        onChange={e => {
          setRotation(Number(e.target.value));
        }}
      />
      <button type='button' className="p-1" onClick={handleRotateCw}>
        <RotateRightIcon />
      </button>
    </div>
  );
};
