/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Breadcrumb } from '../components/Breadcrumb'
function Calendar() {
  return (
    <>
      <Breadcrumb title='Calendar' />
      <div
        css={css`
          text-align: center;
          margin-top: 6rem;
        `}
      ></div>
    </>
  )
}

export default Calendar
