import { MenuItem } from "@mui/material";
import * as _ from "lodash";

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;
    
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    let color = '#';
    
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    
    return color;
}
      
export const stringAvatar = (name: string) => {
    const nameArray = name?.split(' ');
    if(nameArray){
        const firstLetter = (nameArray?.length > 0 && nameArray[0]?.length > 0) ? nameArray[0][0] : '';
        const secondLetter = (nameArray?.length > 1 && nameArray[1]?.length > 0) ? nameArray[1][0] : '';
        return {
            sx: {
            bgcolor: stringToColor(name),
            },
            children: `${firstLetter}${secondLetter}`,
        };
    } 
}

export const renderSelectOptions = (options:any[]) => {
    return _.map(options, (option:any) => {
        return (
            <MenuItem className="responsive-list-menu" value={_.get(option, 'value')}>{_.get(option, 'label')}</MenuItem>
        )
    });
}