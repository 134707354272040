import { createSlice } from '@reduxjs/toolkit'
// model has interface for redux

export interface ToggleState {
    drawer: boolean,
    // expanddrawer: boolean,
    hideGridlayout :boolean
    hideExpandicon:boolean
    FullPage :boolean
    taskData:any
}
const initialDrawerState: ToggleState = {
    drawer: false,
    // expanddrawer: false,
    hideGridlayout:false,
    hideExpandicon:false,
    FullPage :false,
    taskData:{}
}

const TaskDrawerSlice = createSlice({
    name: 'taskDrawer',
    initialState: initialDrawerState,
    reducers: {
        taskdraweropen: (state) => {
            state.drawer = true
            state.hideExpandicon=false
            state.FullPage=false
        },
       taskdrawerclose: (state) => {
            state.drawer = false
            state.hideGridlayout =false
            state.FullPage=false
        },
        taskexpanddrawer:(state)=>{
            state.drawer= false
            state.hideExpandicon=true
            state.hideGridlayout =true
            state.FullPage=true
        },
         taskminimizedrawer:(state)=>{
            state.drawer= true
            state.hideExpandicon=false
            state.hideGridlayout =false
            state.FullPage=false
        },
        selectedTask:(state, action)=>{
            state.taskData= action.payload
        }
    },
})

export const { taskdraweropen, taskdrawerclose,taskexpanddrawer,taskminimizedrawer,selectedTask } = TaskDrawerSlice.actions
export default TaskDrawerSlice.reducer