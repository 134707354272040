import { Divider, FormControl, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const SuccessFactorsForm = () => {

  const { control, formState: { errors }, getValues } : any = useFormContext()
  
  return (
    <Grid className='container-wrapper'>
      {/* <Grid className='row-grid res-grid' style={{width: "50%", paddingRight: "10px"}}>
        <FormControl fullWidth required>
          <Controller
            name="targetSubmissions"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Target submissions'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.targetSubmissions && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.targetSubmissions?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required>
          <Controller
            name="tatDays"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='TAT (Days)'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
                inputProps={{ maxLength: 5 }}
              />
            )}
          />
          {errors?.tatDays && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.tatDays?.message}
            </span>
          )}
        </FormControl>
      </Grid> */}
      <Grid className='row-grid pt-1'>
        <FormControl fullWidth required >
          <Controller
            name="successFactorsInstructions"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                multiline={true}
                label='Success Factors Instructions'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                minRows={3}
                inputProps={{ maxLength: 256 }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth required >
          <Controller
            name="successFactorsRemarks"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                multiline={true}
                label='Success Factors Remarks'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                minRows={3}
                inputProps={{ maxLength: 256 }}
              />
            )}
          />
        </FormControl>
      </Grid>
      {/* <Grid className='row-grid pt-1' style={{gap: "10px", alignItems:"center"}}>
        <div style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#000",
          width: "40px"
          }}
          >{"KPI’s"}</div>
          <Divider className='ruler-hr'/>
      </Grid> */}
      {/* <Grid className='row-grid pt-1'>
        <FormControl fullWidth required >
          <Controller
            name="submissions"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Submissions'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.submissions && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.submissions?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required >
          <Controller
            name="ccmOne"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='CCM 1'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.ccmOne && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.ccmOne?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required >
          <Controller
            name="ccmTwo"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='CCM 2'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.ccmTwo && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.ccmTwo?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required >
          <Controller
            name="offers"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Offers'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.offers && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.offers?.message}
            </span>
          )}
        </FormControl>
      </Grid> */}
      {/* <Grid className='row-grid pt-1 res-grid' style={{width: "50%", paddingRight: "10px"}}>
      <FormControl fullWidth required >
          <Controller
            name="selects"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Selects'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.selects && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.selects?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required >
          <Controller
            name="joinees"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Joinees'
                type='number'
                value={value}
                onChange={(e)=>{
                  if(e.target.value.length <= 5 && Number(e.target.value) >= 0){
                    onChange(e.target.value)
                  }
                }}
                onBlur={onBlur}
                required={true}
              />
            )}
          />
          {errors?.joinees && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.joinees?.message}
            </span>
          )}
        </FormControl>
      </Grid> */}
    </Grid>
  );
}

export default SuccessFactorsForm;