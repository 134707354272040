import React,{useState,useEffect} from 'react'
import { Drawer } from '@mui/material'
import { Mandatedetail } from '../../../pages/Mandates'
import './MandateDetail.css'
// for dispatching action and to getting sate
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { drawerclose } from '../../../features/mandateDetail/DrawerReducer'
import DrawerData from './DrawerData/DrawerData'
import { getUserDetails } from '../../../services/UserService'
export interface DrawerProps {
  // draweropen: boolean
  drawerdata: Partial<Mandatedetail>
  id: number
  noParse: boolean
  parsemsg:string
  isApprovalList?:boolean
  horizontalMemberList: any[];
  verticalMemberList: any[];
  handledetial:any;
  loadFirstPage:any;
}

const data: any = []

export const MandateDetail: React.FC<DrawerProps> = ({ id, drawerdata, isApprovalList, noParse,parsemsg, horizontalMemberList, verticalMemberList, handledetial, loadFirstPage }) => {
  const opendrawer = useAppSelector((state) => state.mandatedrawer.drawer)
  const recruiter = useAppSelector((state) => state.mandatedrawer.isRecruiter)
  
  const dispatch = useAppDispatch()
  const handledrawerClose = () => {
    dispatch(drawerclose())
  }
  
  return (
    <Drawer
      hideBackdrop
      open={opendrawer}
      anchor={'right'}
      onClose={handledrawerClose}
      className='mandate-detail-drawer'
    >
      <DrawerData 
        data={drawerdata} 
        isApprovalList={isApprovalList} 
        noParse={noParse}  
        parsemsg={parsemsg} 
        isRecruiter={recruiter ?? false}
        horizontalMemberList={horizontalMemberList}  
        verticalMemberList={verticalMemberList}
        handledetial={handledetial}
        loadFirstPage={loadFirstPage}
      />
    </Drawer>
  )
}
