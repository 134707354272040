import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg'
import NumberWithIncDec from './NumberWithIncDec';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { dropDownData, getMandateLocation } from '../../../../services/MandateService';
import { toast } from "react-toastify";

const CollaboratorLocationArray = ({mandateId,mandateData}:any) => {
  const { control, formState: { errors }, getValues, setValue } : any = useFormContext();
  const [locationLists, setLocationLists] = useState([])
  const [changeLocation, setChangeLocation] = useState("")
  const { fields: attachmentFields, append : attachmentAppend, remove: attachmentRemove, replace: attachmentReplace } = useFieldArray(
    {
      control,
      name: "collaboratorLocations"
    }
  );
  const getMandateLocationList = async ()=> {
    const response = await getMandateLocation(mandateId,`?status=133,134`)
    if(response?.status === 200){
      setLocationLists(response?.data?.result?.mandateLocations)
    }else{
      setLocationLists([])
    }
  }
  useEffect(() => {
    getMandateLocationList()
  },[])

    return (
        <Box className="collaboratorLocationArray">
            {attachmentFields.map((item:any, index:any)=>{
            return (
            <div key={item.id}>
                <Grid key={item.id} style={{display: "flex", justifyContent: "space-between", gap: "5px"}}>
                <div style={{width: "100%",marginBottom:"15px"}}>
                    <Grid className={`row-grid ${index !==0 && "pt-1"} res-grid`} style={{display:"flex",gap:"30px"}}>
                    <FormControl className='res-grid' fullWidth required >
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Controller
                        name={`collaboratorLocations.${index}.location`}
                        control={control}
                        rules={{
                          required: {
                              value: true,
                              message: "This field is required",
                          }
                          }}
                        render={({field : {onChange, value, onBlur}})=>(
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Location"

                            onChange={(e:any) => {
                                const currentObj = getValues(`collaboratorLocations`)
                                const valueExist = currentObj.filter((obj:any) => obj.location == e.target.value)
                                if(valueExist.length == 0){
                                  onChange(e.target.value);                                
                                  setChangeLocation(e.target.value)  
                                  const filterLoc:any = locationLists.filter((item:any) => e.target.value == item.id);
                                  attachmentReplace(currentObj.map((item:any,childIndex:number) => {
                                    const currentFields:any = item
                                    if(index === childIndex){
                                      currentFields["maxValue"] = filterLoc[0].openJobOrders
                                      currentFields["location"] = e.target.value
                                    }
                                    return currentFields
                                  }))   
                                }else{
                                  const selectedLocation:any = locationLists?.filter((locObj:any) => locObj.id === e.target.value)
                                  toast.error(`selected ${selectedLocation[0].location} location is already selected`) 
                                }
                                                           
                            }}
                            >
                            {locationLists.map((item:any,index:number) => (
                                <MenuItem key={item?.id} value={item?.id}>{item?.location}</MenuItem>
                                ))}
                          </Select>
                        )}
                        />
                        {_.get(errors, `collaboratorLocations.${index}.location`) && (
                          <span className="reminder_text-danger select-danger-text" style={{marginTop:"20px"}} role="alert">
                              {_.get(errors, `collaboratorLocations.${index}.location`)?.message}
                          </span>
                        )}
                    </FormControl>
                    <FormControl className='numberFieldSect' fullWidth required >
                        {/* <InputLabel id="demo-simple-select-label">Job Orders</InputLabel> */}
                        <Controller
                        name={`collaboratorLocations.${index}.jobOrder`}
                        control={control}
                        rules={{
                          required: {
                              value: true,
                              message: "This field is required",
                          }
                          }}
                        render={({field : {onChange, value, onBlur}})=>(
                          <NumberWithIncDec onChange={onChange} value={value} min={1} max={item.maxValue} required={true}/>
                        )}
                        />
                        {_.get(errors, `collaboratorLocations.${index}.jobOrder`) && (
                          <span className="reminder_text-danger select-danger-text" role="alert">
                              {_.get(errors, `collaboratorLocations.${index}.jobOrder`)?.message}
                          </span>
                        )}
                    </FormControl>
                    {attachmentFields.length > 1 && <div
                        style={{display:"flex", marginTop:"15px", cursor: "pointer"}} 
                        onClick={() => attachmentRemove(index)}
                        className='delete-icon'
                    >
                        <DeleteIcon />
                    </div>}
                    </Grid>
                </div>
            </Grid>
        </div>
        )
        })}
        <Box className='button-container' 
        style={{justifyContent:"flex-start", padding: "0rem 0rem 1rem 0rem"}}
      >
        <Button 
          className='draft-btn'
          disabled={locationLists.length == attachmentFields.length}
          onClick={() => {
            attachmentAppend({ location: '',jobOrder:'',maxValue: 0});
          }}
        ><AddIcon /> Add Location</Button>
      </Box>
        </Box>
    );
};

export default CollaboratorLocationArray;