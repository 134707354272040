import { createSlice } from '@reduxjs/toolkit'

export interface LoadState {
  loading: boolean;
}

const initialLoadingState: LoadState = {
  loading:false
}

const LoaderSlice = createSlice({
    name: 'Loader',
    initialState: initialLoadingState,
    reducers: {
      currentLoadStatus: (state,action) => {
        return{
          ...state,
          loading:action?.payload
        }
      },
    }
})

export const { currentLoadStatus } = LoaderSlice.actions
export default LoaderSlice.reducer