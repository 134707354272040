import { Paper, Box, Typography, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material'
import './ViewHeader.scss'
import { AppButton } from '../../AppComponents/AppButton'
import { useState } from 'react'
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/date-arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/date-arrow-right.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/mandates-list-calendar.svg'
import moment from 'moment-timezone'


const ViewHeader = ({ selectedMonth, setSelectedMonth, selectedDate, setSelectedDate, viewType, setViewType }: any) => {
    const dateText = selectedDate.format('MMM DD, YYYY');

    const startOfWeek = selectedDate.clone().startOf('isoWeek');
    const endOfWeek = selectedDate.clone().startOf('isoWeek').add(6, 'days');
    const weekText = `${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')}`;

    const startDate = moment().month(selectedMonth).date(1);
    const endDate = startDate.clone().endOf('month');
    const MonthText = `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`;

    const handleDateChange = (day: any) => {
        viewType == 'D' && setSelectedDate(selectedDate.clone().add(day, 'days'))
        viewType == 'W' && setSelectedDate(selectedDate.clone().add(day * 7, 'days'))
        viewType == 'CW' && setSelectedDate(selectedDate.clone().add(day * 7, 'days'))
        viewType == 'CM' && setSelectedMonth(selectedMonth + day)
    }


    return (
        <>
            <Paper className='work-box-style' elevation={1}>
                <Box display='flex' justifyContent='space-between' className='work-parent-box'>
                    <Typography className='allocation-title'>
                        Allocation
                    </Typography>
                    <Box className='work-filter-container'>
                        {/* <AppButton className='work-toggle-container' > */}
                        <ToggleButtonGroup
                            // color='primary'
                            value={viewType}
                            exclusive
                            onChange={(e, newAlignment: string) => {
                                if (newAlignment) {
                                    setViewType(newAlignment)
                                }
                            }}
                            aria-label='Platform'
                            className='work-toggle-btn'
                        >
                            <ToggleButton value='D'>
                                <span>D</span>
                            </ToggleButton>
                            <ToggleButton value='W'>
                                <span>W</span>
                            </ToggleButton>
                            <ToggleButton value='CW'>
                                <span>CW</span>
                            </ToggleButton>
                            <ToggleButton value='CM'>
                                <span>CM</span>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {/* </AppButton > */}
                        <Box className='date-selection-container' >
                            {/* <AppButton className='arrow-button'> */}
                            <ArrowLeftIcon style={{cursor: "pointer"}} onClick={() => handleDateChange(-1)} />
                            <CalendarIcon />
                            <div id="allocation-date-format" className='date-text'>
                                {viewType == 'D' && dateText}
                                {viewType == 'W' && weekText}
                                {viewType == 'CW' && weekText}
                                {viewType == 'CM' && MonthText}          

                            </div>
                            <ArrowRightIcon style={{cursor: "pointer"}} onClick={() => handleDateChange(1)} />
                            {/* </AppButton> */}

                        </Box>

                    </Box>

                </Box>

            </Paper>
        </>
    )
}

export default ViewHeader
