/** @jsxImportSource @emotion/react */
import { Breadcrumb } from '../components/Breadcrumb'
import React,{useState, useEffect} from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getPowerBiToken } from '../services/CandidateService';
import Report from '../components/Report';

function Reports() {

    return(
        <div >
		             <Breadcrumb title='Candidate Pipeline' />
					<Report/>

				
        </div>
    )
}

export default Reports
