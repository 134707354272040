import { instance } from './http-common'


export const getScheduleEvents = async () => {
    try{
        const response = await instance.get(`notification/scheduler/getEvents`);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 

export const createScheduleEvent = async (req:any) => {
    try{
        const response = await instance.post(`notification/scheduler/create`, req);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 

export const deleteScheduleEvent = async (id:number) => {
    try{
        const response = await instance.delete(`notification/scheduler/${id}`);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 

export const updateScheduleEvent = async (obj:any) => {
    try{
        const response = await instance.put(`notification/scheduler/update/${obj.id}`, obj);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 

export const getScheduleAttendees = async (mandateId:any,mandateLocation:any) => {
    try{
        const response = await instance.get(`user/getUserEmail?mandateId=${mandateId.value}&locationId=${mandateLocation}`);
        return response;
    }catch(error){
        console.log(error)
        return null;
    }
} 