import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import './UserProfile.css';
import { ReactComponent as BackIcon } from '../../assets/icons/backarrowicon.svg';
import { useForm } from 'react-hook-form';
import { getUserConfig } from '../../services/UserService';
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { authSmartFlowDetails } from '../../features/userDetail/UserDetailReducer';


const UserProfile = () => {
  const { userId } = useAppSelector((state) => state?.UserDetail);
  const [showPassword, setShowPassword] = React.useState(false);
  const { smartCallEmail, smartCallPassword } = useAppSelector((state) => state?.UserDetail)
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {  
    if (!data.username || !data.password) {
      return;
    }else if( smartCallEmail != data.username || smartCallPassword != data.password){
      clearErrors("username")
      const userConfigData = {
        username: data.username,
        password: data.password,
        keycloakId: userId,
      };
    
      try {
        const res = await getUserConfig(userConfigData);
          if (res.status === 200) {
            toast.success('SmartFlo Saved successfully!')
            dispatch(authSmartFlowDetails({smartCallEmail:data.username,smartCallPassword:data.password}))
          } 
      } catch (error) {
        console.log("Error:", error);
      }
    }else{
      setError("username", {
        type: "manual",
        message: "Please change username or password to update",
      })
      return
    }
    
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  

  return (
    <Box className='mandate-wrapper userProfileSect'>
      <div className='nav-header'>
        <IconButton onClick={()=> window.history.back()} aria-label='back' disableRipple disableFocusRipple>
          <BackIcon />
        </IconButton>
      </div>
      <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
        <Grid item xs={12} md={12} sm={3} lg={12}>
          <Typography className='filter-title'> Smartflow credentials Update</Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)} style={{marginTop:"20px"}}>
          <Box>
            <Grid className='container-wrapper'>
              <Grid className='row-grid'>
                <div>
                  <TextField 
                    {...register('username', { required: 'Username is required' })}
                    label='Username'
                    inputProps={{ maxLength: 50 }}
                    style={{width: "300px"}}
                    defaultValue={smartCallEmail}
                  />
                  {errors?.username && (
                    <p className='reminder_text-danger select-danger-text' role='alert'>
                      {errors.username.message as string}
                    </p>
                  )}
                </div>
                {/* <div>
                  <TextField 
                    {...register('password', { required: 'Password is required' })}
                    label='Password'
                    type='password'
                    id="yyyy"
                    autoComplete="new-password"
                    inputProps={{ maxLength: 50 }}
                  />
                  {errors?.password && (
                    <p className='reminder_text-danger select-danger-text' role='alert'>
                      {errors.password.message as string}
                    </p>
                  )}
                </div> */}
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    {...register('password', { required: 'Password is required' })}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    defaultValue={smartCallPassword}
                    style={{width: "300px"}}
                    autoComplete='new-password'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{cursor:"pointer"}}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {errors?.password && (
                    <p className='reminder_text-danger select-danger-text' role='alert'>
                      {errors.password.message as string}
                    </p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box className='button-container'>
            <Button className='cancel-btn' onClick={() => window.history.back()}>
              Cancel
            </Button>
            <Button className='save-btn' type='submit'>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default UserProfile;
