// import http from './http-common'
import { instance } from './http-common'
import _ from 'lodash';
import { delay, objectToQueryString } from '../utils/common-utils';
export interface CreateTaskInfo {
    description: string;
    name: string;
    assignedTo: string[];
}
export interface UpdateTaskInfo {
    taskId: number;
    description: string;
    name: string;
    assignedTo: string[]
}
class ForgotPasswordServiceClass {
    async verifyEmail({email}: {email: string}) {
        const queryString = objectToQueryString({
            email,
        })
        // await delay(1000);
        return instance.get(`user/verifyEmail?${queryString}`);
    }
    async verifyOtp({userId, otp}: {otp: string, userId: string | null}) {
        const queryString = objectToQueryString({
            otp,
            userId
        })
        // await delay(1000);
        return instance.get(`user/verifyOtp/?${queryString}`);
    }
    async resetPassword({userId, password, otp}: {password: string, userId: string | null, otp: string | null}) {
        const requestBody = {
            value: password,
            otp,
        }
        // await delay(1000);
        return instance.put(`user/resetPassword/${userId}`, requestBody);
    }
}

export const ForgotPasswordService = new ForgotPasswordServiceClass();