import {    
  Grid,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { MandateService, getClientBasedSpoc, getReceviedEmail } from '../../../../services/MandateService'
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/input-calendar.svg'
import _ from 'lodash'
import {mandateEdit} from '../../../../features/mandateDetail/DrawerReducer'
import { useAppSelector } from '../../../../store/hooks';
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import { emailRegex } from '../../../../utils/constants';
import MandateSpocSuggestion from './MandateSpocSuggestion';

const ClientDetailsForm = ({dropdownOption}:any) => {
  const { control, formState: { errors },getValues,setValue,clearErrors,trigger } : any = useFormContext()
  const [clientOptions, setClientOptions] = useState<any[]>([])
  const [clientDetails , setClientDetails] = useState({})
  const mandateEditState = useAppSelector((state) => state.mandatedrawer.isEdit)
  const [recevierData, setRecevierData] = useState<any>([])
  const phoneNumberRegex = /^([+\d].*)?[\de]+$/gm
  const [mandateOptions, setMandateOptions] = useState([])
  const [mandateSearch, setMandateSearch] = useState("")
  // console.log(getValues(),'check value')

  useEffect(() => {
    MandateService.getClientname()
      .then((res: any) => {
        const options = _.map(res.data.result.list[0], (data: any) => {
          return {
            label: _.get(data, 'clientName'),
            value: _.get(data, 'id'),
            clientDetails: _.get(data, 'clientDetails')
          }})
        setClientOptions(options)
        _.map(res.data.result.list[0], (client:any)=>{
          if(client?.id ==getValues().clientId){
            setClientDetails({"id": client.value,"value":client.clientDetails});
            setValue("clientId", {label: client?.clientName,value: client?.id})
          }
        })
      })
      .catch((error: any) => {
          console.log(error.message)
      })
     
  },[mandateEditState])

  const getClientBasedSpocApi = async (searchKey:string) => {
    const obj:any = {
      clientId: getValues("clientId").value,
      searchKey: searchKey
  }
    try{
      const response = await getClientBasedSpoc(obj);
      setMandateOptions(response)
    }
      catch(error:any){
        console.log(error)        
      }
  }

  useEffect(() => {
    getReceviedEmail()
    .then((res: any) => {
      const options = _.map(res, (data: any) => {
        return {
          label: _.get(data, 'firstName'),
          value: _.get(data, 'id')
        }})
      setRecevierData(options)
    })
    .catch((error: any) => {
        console.log(error.message)
    })
  },[])

  const onSelectSuggestion = (value: any) => {
    setValue("clientSpoc", value?.client_poc)
    setValue("clientSpocEmail", value?.spoc_email != null ? value?.spoc_email : "")
    setValue("clientSpocMobile", value?.spoc_mobile != null ? value?.spoc_mobile : "")
    clearErrors("clientSpocEmail")
    clearErrors("clientSpocMobile")
    setMandateSearch("")
    setMandateOptions([])
  }

  const ShowMoreLess = ({clientDetails,expand}:any) => {
    return (
      <>
      <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={expand}
                // width={280}
                truncatedEndingComponent={"... "}
            >{clientDetails == "" ? "About client" : clientDetails.value}
           </ShowMoreText>
      </>
    )

  }

  const onInputChange = _.debounce((value: string) => {
    if (_.trim(value) !== '' && (_.trim(value).length > 2) && Object.keys(getValues("clientId")).length > 0) {
      getClientBasedSpocApi(_.trim(value))
    }else{
      setMandateOptions([])
    }
  }, 500)

  return (
    <Grid className='container-wrapper'>
      <Grid className='row-grid'>
        <FormControl fullWidth required >
          <Controller
            name="clientId"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <Autocomplete
                    disablePortal
                    options={clientOptions}
                    defaultValue={getValues("clientId")}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                      // _.map(clientOptions, (client:any)=>{
                      //   if(client?.value == value?.value){
                        if(value != null){
                          setClientDetails({"id": value.value,"value":value.clientDetails})   
                        }else{
                          setClientDetails({})    
                        }                           
                      //   }
                      // })
                    }}
                    renderInput={(params) => <TextField required={true} {...params} label={`Client Name`} />}
                  />
              
            )}
          />
          {errors?.clientId && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.clientId?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="contractReference"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Contract Reference'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth required >
          <Controller
            name="clientRequestNo"
            control={control}
            rules={{
              required: {
                  value: false,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Client Request No'
                required={false}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
          {errors?.clientRequestNo && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.clientRequestNo?.message}
            </span>
          )}
        </FormControl> 
        <FormControl fullWidth ></FormControl>        
      </Grid>
      <Grid className='row-grid pt-1'>
        <FormControl fullWidth required className='mandateSpocBox'>
          <Controller
            name="clientSpoc"
            control={control}
            rules={{
              required: {
                  value: true,
                  message: "This field is required",
              }
            }}
            render={({field : {onChange, value, onBlur}})=>{
              const onBlurred = () => {
                onBlur()
                setTimeout(() => setMandateOptions([]), 200);
              }
                return (
              <TextField
                label='Mandate SPOC'
                required={true}
                value={value}
                onChange={(e:any) => {
                  onChange(e.target.value)
                  onInputChange(e.target.value)                  
                }}                
                onBlur={onBlurred}
              />
            )
          }}
          />
          {errors?.clientSpoc && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.clientSpoc?.message}
            </span>
          )}
          {mandateOptions && mandateOptions?.length > 0 ? <MandateSpocSuggestion mandateOptions={mandateOptions} onSelect={onSelectSuggestion} /> : <></>}
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">SPOC Type</InputLabel>
          <Controller
            name={"clientSpocType"}
            control={control}
            rules={{
              required: {
                value: true,
                message: "SPOC Type is required",
              }
            }}
            render={({ field: { onChange, value, onBlur } }) => (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              label="SPOC Type"
              onChange={(e:any) => {
                onChange(e.target.value)
              }}
            >
              {dropdownOption["SPOC_Type"].length > 0 && dropdownOption["SPOC_Type"].map((item:any,index:number) => (
                  <MenuItem key={item?.id} value={item?.domValue}>{item?.domValue}</MenuItem>
                ))}
            </Select>
            )}
            />
            {errors?.clientSpocType && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.clientSpocType?.message}
            </span>
          )}
        </FormControl>
        <FormControl className='manLocPhoneNumberField' fullWidth required>
          <Controller
            name="clientSpocMobile"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Phone number is required",
              },
              pattern: {
                value: phoneNumberRegex,
                message: `Please enter valid Phone`,
              },
              maxLength: {
                value: 15,
                message: "This input accept only max length of 15.",
              },
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Phone Number'
                value={value}
                type='number'
                onChange={onChange}
                onBlur={onBlur}
                required
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
          {errors?.clientSpocMobile && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.clientSpocMobile?.message}
            </span>
          )}
        </FormControl>
        <FormControl fullWidth required>
          <Controller
            name="clientSpocEmail"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Email ID is required",
              },
              pattern: {
                value: emailRegex,
                message: `Please enter valid Email`,
              },
            }}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Email ID'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                required
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
          {errors?.clientSpocEmail && (
            <span className="reminder_text-danger select-danger-text" role="alert">
                {errors?.clientSpocEmail?.message}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid className='row-grid pt-1'>
        <Grid className='res-grid' style={{width:"50%", display:"flex", gap: "20px"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <Controller
              name="receivedOn"
              control={control}
              rules={{
                required: {
                    value: true,
                    message: "This field is required",
                },
                // validate: (value) => {
                  
                //   if (!value || !value.isValid || !value.isValid()) {
                //     return;
                //   }
                //   const minDate = moment().subtract(1,'years').toDate(); // Replace with your minDate
                //   const maxDate = moment().toDate(); // Replace with your maxDate
                //   if ( value.toDate() < minDate || value.toDate() > maxDate) {
                //     return 'Date must between last one year';
                //   }
    
                //   return true;
                //   },
              }}
              render={({field : {onChange, ...restField}})=>(
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',width:"100%"}}>
                <DatePicker
                  label='Received On'
                  className='datePicker'
                  inputFormat="DD/MM/YYYY"
                  onChange={(e:any)=>{onChange(e)}}
                  components={{
                      OpenPickerIcon: CalenderIcon,
                  }}
                  // minDate={moment().subtract(1,'years').toDate()}
                  // maxDate={moment().toDate()}
                  renderInput={(params:any) => (
                      <TextField
                          name='receivedOn'
                          style={{ width: "100%" }}
                          required={true}
                          {...params}
                      />
                  )}
                  {...restField}
              />
              {errors?.receivedOn && (
              <span className="reminder_text-danger select-danger-text" role="alert">
                  {errors?.receivedOn?.message}
              </span>
            )}
              </div>
            )}
            />
          </LocalizationProvider>
          <FormControl fullWidth required >
            <Controller
              name="receivedBy"
              control={control}
              rules={{
                required: {
                    value: true,
                    message: "This field is required",
                }
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <Autocomplete
                    disablePortal
                    options={recevierData}
                    // defaultValue={getValues("receivedBy")}
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)
                      // setValue("receivedBy", value.id)
                    }}
                    renderInput={(params) => <TextField required={true} {...params} label={`Received By`} />}
                  />
              )}
            />
            {errors?.receivedBy && (
              <span className="reminder_text-danger select-danger-text" role="alert">
                  {errors?.receivedBy?.message}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid className={`res-grid aboutClientSect ${Object.keys(clientDetails).length > 0 ? "aboutClientSectAdded" : ""}`} style={{width:"50%"}}>
          {/* <FormControl fullWidth >
            <Controller
              name="clientDetails"
              control={control}
              render={({field : {onChange, value, onBlur}})=>(
                <TextField
                  multiline={true}
                  label='About client'
                  value={clientDetails}
                  aria-readonly ={true}
                  onChange={onChange}
                  onBlur={onBlur}
                  minRows={3}
                  inputProps={{ maxLength: 256 }}
                />
              )}
            />
          </FormControl> */}
          <p>About client</p>
          <div>
            <ShowMoreLess clientDetails={Object.keys(clientDetails).length > 0 ? clientDetails : ""} expand={false}/>
           </div>
           
        </Grid>        
      </Grid>
    </Grid>
  )
}

export default ClientDetailsForm;