import React from 'react'
import styled from '@emotion/styled'
import { Button, ButtonProps, Tooltip } from '@mui/material'

const CustomButton = styled(Button)<CustomButtonProps>(
  ({
    bordercolor,
    minwidth,
    margin,
    bgcolor,
    width,
    textColor,
    fontSize,
    fontWeight,
    variant,
  }) => ({
    minWidth: minwidth,
    margin: margin,
    backgroundColor: bgcolor,
    borderColor: bordercolor,
    color: textColor,
    border: variant === 'outlined' ? '1px solid #D9D9D9' : '',
    whiteSpace: 'nowrap',
    '& svg': {
      fill: textColor,
    },
    '&:hover': {
      backgroundColor: bgcolor,
      boxShadow: 'none',
    },
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: '10px',
    width: width,
    padding: '10px',
    height: '40px',
    fontSize: fontSize,
    fontWeight: fontWeight,
  }),
)

type CustomButtonProps = {
  label?: string
  bordercolor?: string
  minwidth?: number
  margin?: number
  bgcolor?: string
  svgColor?: string
  width?: string
  textColor?: string
  fontSize?: string
  fontWeight?: number
  tooltip?:string
} & ButtonProps

export const AppButton: React.FC<CustomButtonProps> = (props): React.ReactElement => {
  const { label, color, type, children, tooltip } = props

  return (
    <Tooltip title={tooltip ? tooltip : ""}>
    <CustomButton color={color} type={type} {...props}>
      {children}
      {label}
    </CustomButton>
    </Tooltip>
  )
}
