import { 
    Box,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,    
    TextField,
    Button,
    Snackbar,
    Alert,
    Checkbox,
    Modal,
    Autocomplete,
    Avatar,
    Typography
  } from '@mui/material'
  import './AddMandate.scss';
  import React, { useEffect, useState } from 'react';
  import { ReactComponent as BackIcon } from '../../../assets/icons/backarrowicon.svg';
  import { ReactComponent as MandatesIcon } from '../../../assets/icons//mandates.svg'
  import { ReactComponent as OtherInformation } from '../../../assets/icons//otherInformation.svg'
  import { ReactComponent as FactorIcon } from '../../../assets/icons/task.svg'
  import { ReactComponent as ReceiptIcon } from '../../../assets/icons/receipt-text.svg'
  import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
  import { useNavigate } from 'react-router-dom';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import { useForm, FormProvider } from "react-hook-form";
  import ClientProfile from '../../../assets/icons//clientDetails.png'
  import 'react-quill/dist/quill.snow.css'
  import GroupStrick from '../../../assets/images/default-avatar.png'
  import CloseIcon from '@mui/icons-material/Close'
  import { getApprovalLists } from '../../../services/ApprovalListService';
  import chatEnhanceClose from "../../../assets/icons/chatEnhanceClose.svg";
  import { ReactComponent as ChatBotEnhanceIcon } from "../../../assets/icons/chatBotEnhanceIcon.svg";  
  import chatEnhanceExpand from "../../../assets/icons/chatEnhanceExpand.svg";
  import { 
    createMandateService, 
    updateMandateService, 
    draftMandateService, 
    editDraftMandateService,
    dropDownData, 
  } from '../../../services/MandateService';
  import _ from 'lodash'
  import moment from 'moment';
  import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
  import { useAppDispatch, useAppSelector } from '../../../store/hooks';
  import { drawerclose,mandateEdit } from '../../../features/mandateDetail/DrawerReducer';
  import { leftdrawertoggleclose } from '../../../features/toggle/ToggleReducer';
import ClientDetailsForm from './MandateForm/ClientDetailsForm';
import MandateDetailsForm from './MandateForm/MandateDetailsForm';
import MandateSpecsForm from './MandateForm/MandateSpecsForm';
import LocationDetailsForm from './MandateForm/LocationDetailsForm';
import SuccessFactorsForm from './MandateForm/SuccessFactorsForm';
import AttachmentFieldsForm from './MandateForm/AttachmentFieldsForm';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { AppButton } from '../../AppComponents/AppButton';
import MandateLocationFieldForm from './MandateForm/MandateLocationFieldForm';
import EnahanceChatBot from '../../Common/EnahanceChatBot/EnahanceChatBot';
  interface IFormInput {
    clientId: string;
    contractReference: string;
    clientRequestNo: string;
    clientSpoc: string;
    receivedOn: any;
    receivedBy: string;
    clientRemarks: string;
    jobTitle: string;
    totalMandateLocations: string;
    jobType: string;
    jobOrderCode: string;
    priority: string;
    startDate: any;
    endDate: any;
    mandateRoleCode: string;
    jobMode: string;
    shift: string;
    qualification: string;
    experienceRange: number[];
    ctcRange: number[];
    currency: string;
    jobDescription: string;
    primarySkills: string;
    secondarySkills: string;
    secondaryMatch: string;
    onsite: string;
    targetSubmissions: string;
    tatDays: string;
    successFactorsInstructions: string;
    successFactorsRemarks: string;
    submissions: string;
    ccmOne: string;
    ccmTwo: string;
    offers: string;
    selects: string;
    joinees: string;
    locations: any[];
    positons: any[];
    attachments: any[];
    jobDescriptionAi: string;
  }
  
  type Props = {
    isEdit?: any;
    mandateData?: any;
    isClone?:boolean
  }
  interface TProps {
    open: boolean
    mandateIds: any
   
  }
  const AddMandate: React.FC<Props> = (props) => {

    const manLocationParser = (data:any) => {
      const locArr  = data.map((pos:any)=> {
        const parsObj = {...pos,
          priceData: JSON.parse(pos?.priceData),
          secondaryLocations: JSON.parse(pos?.secondaryLocations),
          jobOrderCount: pos?.jobOrders

        }
        return parsObj
      })
      return locArr
    }
    
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const isRecruiter = useAppSelector((state) => state.mandatedrawer.isRecruiter)
    const draftState = useAppSelector((state) => state.mandatedrawer.isDraft)
    const { isEdit, mandateData, isClone } = props;
    const draftButton = isEdit ? draftState : true;
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const [hasError, setHasError] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [endDateError, setEndDateError] = useState<boolean>(false)
    const [openAssignPopup,setOpenAssignPopup]= useState<boolean>(false)
    const [apiPayload, setApiPayload]= useState({})
    const [approverError, setApproverError]= useState(false)
    const [members, setMembers] = useState([])
    const [mandatelocationList, setMandateLocationList] = useState<any[]>((isEdit || isClone) ? mandateData?.mandateLocation ? manLocationParser(mandateData?.mandateLocation) : [] : [])
    const [mandatelocationError, setMandateLocationError] = useState<boolean>(false)
    const [mandatelocationCount,setMandateLocationCount] = useState<any>();
    const [deletedMandateLocation, setDeletedMandateLocation]= useState<any[]>([])
    const [editedMandateLocation, setEditedMandateLocation]= useState<any[]>([])
    const [addMandateLocation, setAddMandateLocation]= useState<any[]>([])
    const [failurePopupMsg, setFailurePopupMsg] = useState<string>("Server Error")
    const [dropdownOption, setDropdownOption] =useState<any>({})
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const [enhanceChatbotStatus, setEnhanceChatbotStatus] = useState(false)
    const [enhanceChatbotMinize, setEnhanceChatbotMinize] = useState(false);
    const [chatbotMinizeEnableStatus, setChatbotMinizeEnableStatus] = useState(false)
    const [highlightJoAiStatus, setHighlightJoAiStatus] = useState(false)
    const [enhanceReqObj, setEnhanceReqObj] = useState({})
    const [enhanceReqResponse, setEnhanceReqResponse] = useState([])

    const locationParser = (locData:any) => {
      try{
        const val = JSON.parse(locData)
        if(val?.length>0){
          return val
        }else{
          return [{location:null, mandatelocations:"", clientSPOC: "", remarks:""}]
        }
      }catch(e){
        console.error(e)
        return [{location:null, mandatelocations:"", clientSPOC: "", remarks:""}]

      }
    }
  
    const initValues = {
      clientId: (isEdit || isClone) ? mandateData?.clientId : "",
      contractReference: (isEdit || isClone) ? mandateData?.contractReference : "",
      clientRequestNo: (isEdit || isClone) ? mandateData?.clientRequestNo : "",
      clientSpoc: (isEdit || isClone) ? mandateData?.clientSpoc : "",
      clientSpocType: (isEdit || isClone) ? mandateData?.clientSpocType : "",
      clientSpocMobile: (isEdit || isClone) ? mandateData?.clientSpocMobile : "",
      clientSpocEmail: (isEdit || isClone) ? mandateData?.clientSpocEmail : "",      
      receivedOn: (isEdit || isClone) ? mandateData?.receivedOn ? new Date(mandateData?.receivedOn) : null : null,
      receivedBy: (isEdit || isClone) ? mandateData?.receivedBy : "",
      clientRemarks: (isEdit || isClone) ? mandateData?.clientRemarks : "",
      jobTitle: (isEdit || isClone) ? mandateData?.jobTitle : "",
      totalMandateLocations: (isEdit || isClone) ? mandateData?.jobOrders : "", 
      jobType:  (isEdit || isClone) ? mandateData?.jobType : "",
      jobOrderCode: (isEdit || isClone) ? mandateData?.jobOrderCode : "",
      priority: (isEdit || isClone) ? mandateData?.jobPriority : "",
      startDate: (isEdit || isClone) ? mandateData?.requestStartDate ? new Date(mandateData?.requestStartDate) : null : null,
      endDate: (isEdit || isClone) ? mandateData?.requestEndDate  ? new Date(mandateData?.requestEndDate) : null : null,
      mandateRoleCode: (isEdit || isClone) ? mandateData?.mandateRoleCode : "",
      jobMode: (isEdit || isClone) ? mandateData?.jobMode : "",
      shift: (isEdit || isClone) ? mandateData?.shift : "",
      qualification: (isEdit || isClone) ? mandateData?.qualification : "",
      experienceRange: (isEdit || isClone) ? [mandateData?.expRangeStart??1, mandateData?.expRangeEnd??30] : [1, 30],
      ctcRange: (isEdit || isClone) ? [mandateData?.ctcRangeStart ?? 1, mandateData?.ctcRangeEnd??100] : [1, 100],
      currency: (isEdit || isClone) ? mandateData?.currency : 'INR',
      jobDescription: (isEdit || isClone) ? mandateData?.jobDescription : "",
      primarySkills: (isEdit || isClone) ? mandateData?.primarySkills : "",
      secondarySkills: (isEdit || isClone) ? mandateData?.secondarySkills : "",
      onsite: (isEdit || isClone) ? mandateData?.onsite : "",
      secondaryMatch: (isEdit || isClone) ? mandateData?.secondaryMatch : "",
      targetSubmissions: (isEdit || isClone) ? mandateData?.targetSubmission : "", 
      tatDays: (isEdit || isClone) ? mandateData?.tat : "",
      successFactorsInstructions: (isEdit || isClone) ? mandateData?.instructions : "",
      successFactorsRemarks: (isEdit || isClone) ? mandateData?.successFactorRemarks : "",
      submissions: (isEdit || isClone) ? mandateData?.submissions : "",
      ccmOne: (isEdit || isClone) ? mandateData?.ccm1 : "",
      ccmTwo: (isEdit || isClone) ? mandateData?.ccm2 : "",
      offers: (isEdit || isClone) ? mandateData?.offers : "",
      selects: (isEdit || isClone) ? mandateData?.selects : "",
      joinees: (isEdit || isClone) ? mandateData?.joinees : "",
      // locations: ((isEdit || isClone) && mandateData?.locations) ? locationParser(mandateData?.locations) : [{location:null, mandatelocations:"", clientSPOC: "", remarks:""}],
      attachments: [{ key: '', value: null }],
      mandateLocation: ((isEdit || isClone) && mandateData?.mandateLocation) ? mandateData?.mandateLocation: [{location:null, mandatelocations:"", clientSPOC: "", remarks:""}],

      departmentData:(isEdit || isClone) ? JSON.parse(mandateData?.departmentData) :  [],
      industryData:(isEdit || isClone) ? JSON.parse(mandateData?.industryData) : [],
      mandateType:(isEdit || isClone) ? mandateData?.mandateType : "",
      jobDescriptionAi: (isEdit || isClone) ? mandateData?.jobDescriptionAi : "" 
    }
    const [enahanceJoDescription, setEnahanceJoDescription] = useState({
      value: "",
      timeStamp: new Date().getTime()
    })

    

    const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });

    const {
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      clearErrors,
      setValue
    } = methods

    const scrollToError = () => {
      setTimeout(() => {
        const container = document.querySelector(`.reminder_text-danger`);
        container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },100)      
    }

    const handleInsert = (enahanceJoDescription:any) => {
      setEnhanceChatbotStatus(false)
      setHighlightJoAiStatus(true)
      setValue("jobDescriptionAi", enahanceJoDescription.value)
      setTimeout(() => {
        setHighlightJoAiStatus(false)
      },5000)
    }

    const minimizeChatBot = () => {
      setEnhanceChatbotStatus(false);
      setEnhanceChatbotMinize(true);
    }
   
    async function ApproverList(){
    const response = await getApprovalLists()
    setMembers(response)
   }
  
    useEffect(()=>{
      if(isRecruiter){
        ApproverList()
      }
      return ()=>{
        setAddMandateLocation([])    
        setEditedMandateLocation([])   
        setDeletedMandateLocation([]) 
      }
    },[])
    const dataManipulation = (data:any) => {
      
      const NumFields = ["jobOrders", "targetSubmission", "submissions", "offers", "offers", "joinees", "ccmOne", "ccmTwo", "selects", "tat"]
      const obj:any = {
        clientId: data?.clientId,
        contractReference: data?.contractReference,
        clientRequestNo: data?.clientRequestNo,
        clientSpoc: data?.clientSpoc,
        clientSpocType: data?.clientSpocType,
        clientSpocMobile: data?.clientSpocMobile,
        clientSpocEmail: data?.clientSpocEmail,
        receivedOn: data?.receivedOn != null ? moment(new Date(data?.receivedOn)).format('YYYY-MM-DD') : "",
        receivedBy: data?.receivedBy,
        clientRemarks: data?.clientRemarks,
        
        jobTitle: data?.jobTitle,
        jobOrders: data?.totalMandateLocations || 0, // grayout
        jobType: data?.jobType,
        // jobOrderCode: data?.jobOrderCode,
        jobPriority: data?.priority,
        mandateType: data?.mandateType,
        requestStartDate: data?.startDate != null ? moment(new Date(data?.startDate)).format('YYYY-MM-DD') : "",
        requestEndDate: data?.endDate != null ? moment(new Date(data?.endDate)).format('YYYY-MM-DD') : "",
  
        // mandateRoleCode: data?.mandateRoleCode,
        jobMode: data?.jobMode,
        shift: data?.shift,
        qualification: data?.qualification,
        onsite: data?.onsite,
        expRangeStart: data?.experienceRange?.[0] == 0 ? 1 : Number(data?.experienceRange?.[0]),
        expRangeEnd: Number(data?.experienceRange?.[1]),
        ctcRangeStart: data?.ctcRange?.[0] == 0 ? 1 : Number(data?.ctcRange?.[0]),
        ctcRangeEnd: Number(data?.ctcRange?.[1]),
        jobDescription: data?.jobDescription,
        jobDescriptionAi: data?.jobDescriptionAi,
        primarySkills: data?.primarySkills,
        secondarySkills: data?.secondarySkills,
        secondaryMatch: data?.secondaryMatch,
        currency: data?.currency,
  
        // locations: data?.locations, // change as position
        mandateLocation: mandatelocationList.map((x:any)=> {
          const data =x
          if(x?.ctcRangeStart == 0) x.ctcRangeStart = 1
          if (x?.index) delete data.index;
          if (x?.mandateId) delete data.mandateId;
          return data
        }),
        mandateLocationToDelete : deletedMandateLocation.map((x:any)=> {
          const data = x
          if (x?.mandateId) delete data.mandateId;
          return data
        }),
        targetSubmission: data?.targetSubmissions,
        tat: data?.tatDays,
        instructions: data?.successFactorsInstructions,
        successFactorRemarks: data?.successFactorsRemarks,
        submissions: data?.submissions,
        ccm1: String(data?.ccmOne),
        ccm2: String(data?.ccmTwo),
        offers: data?.offers,
        selects: data?.selects,
        joinees: data?.joinees,
        approvers: JSON.stringify(data?.approver),
        departmentData :data.departmentData,
        industryData:data.industryData
      }
  
      Object.keys(obj).map((key) => {
        if (NumFields?.includes(key)) {
            if(!obj[key] && obj[key] != 0){
              obj[key] = null
            }else{
              obj[key] = Number(obj[key])
            }
        }
        if(key === "locations"){
          const val = obj?.[key]?.map((item:any)=> JSON.stringify(item))
          obj[key] = `[${val}]`
        }
      })
      obj["mandateLocationToAdd"] =_.intersection(addMandateLocation, obj.mandateLocation);
      if (isEdit) {
        obj["id"] = mandateData?.id;
       const mandatelocationUpdate = editedMandateLocation.filter(elementA => obj?.mandateLocation?.some((elementB:any) => elementB.id === elementA.id));
        obj["mandateLocationToUpdate"] = mandatelocationUpdate
   
      }
  
      const result = data?.attachments?.reduce(function (r: any, e: any) {
        if(e?.value?.name){
          r[e?.value?.name] = e?.key;
        }
        return r;
      }, {});
    
      obj['files'] = result
  
      return obj;
    }
    const apiCall = async (data:any) =>{
      setIsSubmitting(true);
      const mandateData = new FormData()
      const payload = dataManipulation(data)
      
      mandateData.append('mandate', JSON.stringify({...payload}))
      
      data?.attachments?.forEach((file: any) => {
        mandateData.append('files', file.value);
      });
      try{
      const response = isEdit ? await updateMandateService(mandateData) : await createMandateService(mandateData)
      if (response?.status == 200 || response?.status == 201) {
        if (response?.data?.result?.message?.includes("successfully")) {
            setUploadsuccess(true)
            setSuccessMessage(isEdit ? `Mandate has been successfully updated ${isRecruiter ? "and send for approval": ""}` : `Mandate has been successfully created ${isRecruiter ? "and send for approval" : ""}`)
            setAddMandateLocation([])    
            setEditedMandateLocation([])   
            setDeletedMandateLocation([]) 
          }else{
            setHasError(true)
        }
      }}catch(error:any){
        console.log(error)
        setFailurePopupMsg(error?.response?.data?.error?.message || "Server Error")
        setHasError(true)
      }
      setIsSubmitting(false);
    }


    const mandateSubmitHandler = async (data:any, event:any) => {
      event.preventDefault();
      data["primarySkills"] = data["primarySkills"].trim().charAt(data["primarySkills"].trim().length - 1) == "," ?  data["primarySkills"].trim().substring(0, data["primarySkills"].trim().length - 1): data["primarySkills"].trim();
      data["secondarySkills"] = data["secondarySkills"].trim().charAt(data["secondarySkills"].trim().length - 1) == "," ?  data["secondarySkills"].trim().substring(0, data["secondarySkills"].trim().length - 1): data["secondarySkills"].trim();
      data["clientId"] = data.clientId.value
      data["receivedBy"] = typeof data.receivedBy == 'string' ? data.receivedBy : data.receivedBy.label 
      if(mandatelocationList?.length === 0){
        setMandateLocationError(true)
        setHasError(true)
        return;
      }
      
      if(userPermissions?.mandate_module?.create?.show_approver_popup?.selected){
        setOpenAssignPopup(true)
        setApiPayload(data)
      }else{
        apiCall({...data, approvers:[]})
      }
    }

    const draftMandateSubmit = async (data:any) => {
      trigger(["clientId", "jobTitle"]);
      scrollToError();
      if(getValues("clientId") == "" && getValues("jobTitle") == "" ) return
      const draftedData = data;
      draftedData["clientId"] = data["clientId"].value
      draftedData["receivedBy"] = typeof data.receivedBy == 'string' ? data.receivedBy : data.receivedBy.label 
      if(getValues("jobTitle")===""){
        return trigger("jobTitle")
      }else{
          clearErrors("jobTitle")
      }
      if( getValues("clientId") === ""){
          return trigger("clientId")
      }else{
          clearErrors("clientId")
      }
  
      setIsSubmitting(true);
      const draftMandate = new FormData()
      const payload = dataManipulation(draftedData)
      draftMandate.append('mandate', JSON.stringify({...payload}))
  
      draftedData?.attachments?.forEach((file: any) => {
        draftMandate.append('files', file.value);
      });
      try{

        const response = isEdit ? await editDraftMandateService(draftMandate) : await draftMandateService(draftMandate)
        if (response?.status == 200 || response?.status == 201) {
          if (response?.data?.result?.message?.includes("successfully")) {
            setUploadsuccess(true)
            setSuccessMessage(isEdit ? 'Draft Updated successfully' : 'Draft saved successfully')
          }else{
            setHasError(true)
          }
        }

      }catch(error:any){
        console.log(error)
        setFailurePopupMsg(error?.response?.data?.error?.message || "Server Error")
        setHasError(true)
      }
  
      setIsSubmitting(false);
    }
  
    const handleClose = () => {
      dispatch(drawerclose())
      dispatch(leftdrawertoggleclose())
      setUploadsuccess(false);
      setSuccessMessage('');
      navigate('/mandates')
    }
  
    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return
      }
      setHasError(false)
      setMandateLocationError(false)
    }
   
    const CreatModal: React.FC<TProps> = ({ mandateIds, open }) => {
    
      const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
      const checkedIcon = <CheckBoxIcon fontSize='small' />
      const [selectedMembers, setSelectedMembers] = useState<any[]>(((isEdit || isClone) && mandateData?.approvers) ? JSON.parse(mandateData?.approvers) :[])

      const handleAssign = () => {
        if(selectedMembers?.length !== 0){
          apiCall({ ...apiPayload, approver: selectedMembers })
          setOpenAssignPopup(false)
        }else{
          setApproverError(true)
        }
        
      }
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 300,
        borderRadius: '10px',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
      }
      return (
        <div>
          <Modal
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className='assign-mandate-model'
          >
            <Box sx={style}>
              <div className='assign-mandates-header'>
                <Typography variant='h6' component='h3'>
                  Select Approver and {isEdit ?'update':'create'}
                </Typography>
                <IconButton
                  aria-label='close'
                  onClick={()=> setOpenAssignPopup(false)}
                  sx={{
                    position: 'inherit !important',
                    color: '#000000',
                  }}
                  className='app-dialog-title-icon'
                >
                  <CloseIcon style={{cursor:"pointer"}} fontSize='medium' />
                </IconButton>
              </div>
              <div className='inside-modal-popup' style={{ width: '100%', height: '100%' }}>
                <Autocomplete
                  multiple
                  id='checkboxes-tags'
                  options={members ?? []}
                  limitTags={2}
                  sx={{ borderRadius: '10px' }}
                  disableCloseOnSelect
                  getOptionLabel={(option:any) => option?.firstName}
                  className={`inner-textfield ${approverError && 'autofield-error'}`}
                  value={members.filter((item:any)=> selectedMembers?.includes(item?.id))}
                  onChange={(e, value:any) => {
                    setSelectedMembers(value?.map((ele:any)=>ele?.id))}}
                  renderOption={(props, option:any, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                      <Avatar
                        alt='no-group'
                        src={GroupStrick}
                        sx={{ width: 30, height: 30, marginRight: '5px' }}
                      />
                      {option?.firstName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Approver'
                    />
                  )}
                />
                {approverError && (
                  <div className='reminder_text-danger  error-span' role='alert'>
                    {'Select atleast one approver'}
                  </div>
                )}
                <Box className='bottom-buttons'>
                  <AppButton className='bottom-cancel-button' onClick={()=> setOpenAssignPopup(false)}>
                    Cancel
                  </AppButton>
                  <AppButton className='bottom-assign-button' onClick={handleAssign}>
                    { isEdit ?'Save' :'Create' }
                  </AppButton>
                </Box>
              </div>
            </Box>
          </Modal>
        </div>
      )
    }

    const getMandateType = async () => {
      const reqObj = {
        "businessUnit": false,
        "appConfiguration":false,
        "dropdownRequest": [
          {
            "drpCode": "XPN0001",
            "drpName": "MANDATE_TYPE"
          },
          {
            "drpCode": "XPN0002",
            "drpName": "SHIFT_TYPE"
          },
          {
            "drpCode": "XPN0003",
            "drpName": "Job_Type"
          },
          {
            "drpCode": "XPN0004",
            "drpName": "Job_Mode"
          },
          {
            "drpCode": "XPN0005",
            "drpName": "SPOC_Type"
          },
          {
            "drpCode": "XPN0006",
            "drpName": "Permissions"
          }
        ]
    }
      try{
        const res:any = await dropDownData(reqObj)
  
        if(res?.status === 200){
          setDropdownOption(res?.data?.result?.dropdownData ?? [])
       
        }
      }catch(error){
        console.log(error,"error")
      }
    }

    useEffect(()=>{
      getMandateType()
    },[])
    
  
    return (
      <Box className='mandate-wrapper'>
        <div className='nav-header'>
          <IconButton
              onClick={() =>  {dispatch(mandateEdit({ isEdit: false }));(isEdit ? navigate(`/mandates?id=${mandateData?.id}`) : navigate('/mandates'))}}
              aria-label='back'
              disableRipple
              disableFocusRipple
          >
              <BackIcon />
          </IconButton>
          <div className='header-text'>
            {`${isEdit ? "Edit" : isClone ? "Clone" : "New "} Mandate`}
          </div>
        </div>
        <FormProvider {...methods}>
          <Box
            component="form"
            data-testid="formHandle"
            id="mandateForm"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(mandateSubmitHandler)}
          >
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              >
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <img src={ClientProfile} alt="otherInfo" />
                  <div>Client Details</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
              {Object.keys(dropdownOption).length > 0 && <ClientDetailsForm dropdownOption={dropdownOption}/>}  {/* client details fields */}
              </AccordionDetails>
            </Accordion>
            <Accordion  defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              > 
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <MandatesIcon style={{fill:"#002882"}}/>
                  <div>Mandate Details</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
                {Object.keys(dropdownOption).length > 0 && <MandateDetailsForm endDateError={endDateError} setEndDateError={setEndDateError} mandatelocationListuse = {mandatelocationList} dropdownOption={dropdownOption}/>} {/* mandate details fields */}
              </AccordionDetails>
            </Accordion>
            <Accordion  defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              >
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <OtherInformation />
                  <div>Mandate specs</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
                {Object.keys(dropdownOption).length > 0 && <MandateSpecsForm dropdownOption={dropdownOption} setEnhanceReqObj={setEnhanceReqObj} setEnhanceChatbotStatus={setEnhanceChatbotStatus} highlightJoAiStatus={highlightJoAiStatus}/>}  {/* mandate specs fields */}
              </AccordionDetails>
            </Accordion>
            {/* <Accordion  defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              >
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <LocationIcon />
                  <div>Location</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
                <LocationDetailsForm/> 
              </AccordionDetails>
            </Accordion> */}
            <Accordion  defaultExpanded={true} >  
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              >
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <MandatesIcon style={{fill:"#002882"}}/>
                  <div>Location</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
              {Object.keys(dropdownOption).length > 0 && <MandateLocationFieldForm 
                  editedMandateLocation={editedMandateLocation} 
                  setEditedMandateLocation={setEditedMandateLocation} 
                  deletedMandateLocation={deletedMandateLocation} 
                  setDeletedMandateLocation={setDeletedMandateLocation} 
                  mandatelocationList={mandatelocationList} 
                  setMandateLocationList={setMandateLocationList} 
                  setAddMandateLocation={setAddMandateLocation}
                  addMandateLocation ={addMandateLocation}
                  dropdownOption={dropdownOption}
                /> }
              </AccordionDetails>
            </Accordion>
            <Accordion  defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              >
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <FactorIcon className='accord-icon' />
                  <div>Success Factors</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
                <SuccessFactorsForm/> {/* success factors fields */}
              </AccordionDetails>
            </Accordion>
            <Accordion  defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='accordian-header'
              >
                <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                  <ReceiptIcon />
                  <div>Attachments</div>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordian-details'>
                <AttachmentFieldsForm isEdit={(isEdit || isClone)} mandateData={mandateData} />  {/* Attachments fields */}
              </AccordionDetails>
            </Accordion>
            <Box className='button-container'>
              <Button 
                className='cancel-btn'
                onClick={()=> window.history.back()}
              >Cancel</Button>
              {(userPermissions?.mandate_module?.create?.draft?.selected && userRole != "Admin") ? 
                <Button className='draft-btn add-mandate-save-draft-btn' onClick={()=> draftMandateSubmit(getValues())} >
                  Save as Draft
                </Button>
                :
                (userRole == "Admin") ? 
                <Button className='draft-btn add-mandate-save-draft-btn' onClick={()=> draftMandateSubmit(getValues())} >
                  Save as Draft
                </Button>
                :""
              }
              <Button 
                className='save-btn add-mandate-save-btn'
                type='submit'
                disabled={isSubmitting || endDateError}
                onClick={() => {scrollToError()}}
              >
                Save
              </Button>
            </Box>
          </Box>
        </FormProvider>
        <AppErrorHandlerModal
          open={uploadsuccess}
          content={SuccessMessage}
          handleClose={handleClose}
          factor='Success'
          ButtonText='Done'
        ><></></AppErrorHandlerModal>
        <Box>
          <Snackbar
          open={hasError}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          >
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} variant='filled'>
              {mandatelocationError ? "Please add minimum one Location to create the mandate" : failurePopupMsg}
          </Alert>
          </Snackbar>
        </Box>
          <CreatModal mandateIds ={[]}open={openAssignPopup} />
        {enhanceChatbotStatus && <EnahanceChatBot enhanceReqObj={enhanceReqObj} enhanceReqResponse={enhanceReqResponse} setEnhanceReqResponse={setEnhanceReqResponse} setEnhanceChatbotStatus={setEnhanceChatbotStatus} setEnahanceJoDescription={setEnahanceJoDescription} handleInsert={handleInsert} minimizeHandler={minimizeChatBot} chatbotMinizeEnableStatus={chatbotMinizeEnableStatus} setChatbotMinizeEnableStatus={setChatbotMinizeEnableStatus}/>}
        {enhanceChatbotMinize && 
          <div className='minimizeChatSect'>
            <div className='minimizeChatSectLeft'>
              <ChatBotEnhanceIcon />
              <p>Elevate your JD with AI</p>
            </div>
            <div className='minimizeChatSectRight'>
              <img src={chatEnhanceClose} style={{ marginRight: "13px", cursor: "pointer" }} onClick={()=>{setEnhanceReqResponse([]);setEnhanceChatbotMinize(false);setEnhanceChatbotStatus(false);setChatbotMinizeEnableStatus(false)}} />
              <img src={chatEnhanceExpand} style={{ marginTop: "2px", cursor: "pointer" }} onClick={() => {setEnhanceChatbotMinize(false);setChatbotMinizeEnableStatus(true);setEnhanceChatbotStatus(true)}} />
            </div>
          </div>
          }
      </Box>
    );
  }
  
  export default AddMandate;