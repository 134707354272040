import { createSlice } from '@reduxjs/toolkit'

interface INotificationCount {
  count: number,
  mandateOpen: boolean,
  mandateId: number,
  candidateOpen: boolean
  navigationId:number
  callNotificationOpen: boolean,
  callNotificationInfo: any
  callLogNotificationOpen: boolean
  confettiNotificationOpen: boolean
  confettiNotificationInfo:any
  confettiNotificationListAddStatus:boolean,
  enableNotificationState:boolean
}

const countInitialState: INotificationCount = {
  count: 0,
  mandateOpen: false,
  mandateId: 0,
  candidateOpen: false,
  navigationId:0,
  callNotificationOpen: false,
  callNotificationInfo: {},
  callLogNotificationOpen: true,
  confettiNotificationInfo: [],
  confettiNotificationOpen: false,
  confettiNotificationListAddStatus:false,
  enableNotificationState:false
}

const NotificationSlice = createSlice({
  name: 'notification',
  initialState: countInitialState,
  reducers: {
    setNotificationCount: (state, action) => {
      return {
        ...state,
        count: action?.payload?action?.payload:0
      }
    },
    openNotification: (state, action) => {
      if (action?.payload?.module === "mandate_detail" || action?.payload?.module ==="mandate_approval") {
        return {
          ...state,
          mandateOpen: true,
          mandateId: action?.payload?.id
        }
      }
      else if (action?.payload?.module === "candidate_tracking") {
        return {
          ...state,
          candidateOpen: true,
          navigationId:action?.payload?.id
        }
      }else if(action?.payload?.module ==="smart_call"){
        return {
          ...state,
          candidateOpen: false
        }
      }
    },
    resetNotification: (state) => {
      return {
        ...state,
        mandateOpen: false,
        mandateId: 0,
        candidateOpen: false,
        navigationId:0
      }
    },
    resetCallNotification: (state) => {
      state.callLogNotificationOpen = !(state.callLogNotificationOpen)
    },
    setCallNotification: (state, action) => {
      return {
        ...state,
        callNotificationOpen: action?.payload?.openState,
        callNotificationInfo: action?.payload?.userInfo
      }
    },
    setConfettiNotification: (state, action) => {
      const newArray = !action?.payload?.confettiAdditionStatus ? [...state.confettiNotificationInfo, ...action.payload.confettiUserInfo]: action.payload.confettiUserInfo;
      return {
        ...state,
        confettiNotificationOpen: action?.payload?.confettiOpen,
        confettiNotificationInfo: newArray,
        confettiNotificationListAddStatus: action?.payload?.confettiAdditionStatus
      }
    },
    resetConfettiNotification: (state) => {
      return {
        ...state,
        confettiNotificationOpen: false,
        confettiNotificationInfo: [],
        confettiNotificationListAddStatus: false
      }
    },
    resetNotificationAll: (state) => {
      return {
        ...state,
        ...countInitialState,
      }
    },
    updateNotificationToggleState: (state, action) => {
      return {
        ...state,
        enableNotificationState: action?.payload
      }
    },    
  }
})

export const { setNotificationCount, openNotification, resetNotification, setCallNotification, resetCallNotification,setConfettiNotification, resetConfettiNotification, resetNotificationAll,updateNotificationToggleState } = NotificationSlice.actions
export default NotificationSlice.reducer