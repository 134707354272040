import { instance } from './http-common'

class WorkManagementServiceClass {
  getAllocatedMandateCount(body: any) {
    return instance.post('mandate/getAllocatedMandateCount', body)
  }
}
export const WorkManagementService = new WorkManagementServiceClass()

export const getAllocatedMandateCount = async (body: any) => {
  try {
    const response = await instance.post('mandate/getAllocatedMandateCount', body)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getdetailsOnRecruiter = async (reqObj:any) => {
  try {
    const response = await instance.post(`mandate/getAllocatedMandate`, reqObj)
    return response
  } catch (error) {
    console.log(error)
  }
}

export const mandateAllocate = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation`, reqObj)
  return response
}

export const UpdateMandateAllocation = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/modify`, reqObj)
  return response
}

export const unassignMandateAllocation = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/remove`, reqObj)
  return response
}

export const getAllocationDetails = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/get`, reqObj)
  return response
}

export const getAssigneeList = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/assignees`, reqObj)
  return response
}

export const getMandateAlloctionList = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/list`, reqObj)
  return response
}

export const getMandateByDetails = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/details/by/mandate`, reqObj)
  return response
}

export const postBulkAllocationList = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/bulk`, reqObj)
  return response
}

export const postBulkReallocation = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/bulk/re-allocation`, reqObj)
  return response
}

export const postBulkDeallocation = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/bulk/de-allocation`, reqObj)
  return response
}

export const postBulkUpdate = async (reqObj:any) => {
  const response = await instance.post(`mandate/allocation/bulk/update`, reqObj)
  return response
}
