import { alpha, InputBase, styled, Box, SvgIcon } from '@mui/material'
import { ICON_COLOR } from '../../../utils/constants'
import { ReactComponent as SearchIcon } from '../../../../src/assets/icons/search.svg'

export const MandatesSearch = ({value, onChange}: {value:string, onChange: (e:any) => void}) => (
  <Box sx={{ display: { sm: 'flex',justifyContent:"center",alignItems:"center",minWidth:"200px" } }}>
    <SearchWrapper>
      <SearchIconWrapper>
        <SvgIcon sx={{ color: `${ICON_COLOR}` }}>
          {' '}
          <SearchIcon />
        </SvgIcon>
      </SearchIconWrapper>
      <StyledInputBase placeholder='Search Keyword ...' inputProps={{ 'aria-label': 'search' }} value={value} onChange={onChange}/>
    </SearchWrapper>
  </Box>
)

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
    width: 'auto',
    marginTop: '5px',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  fontSize: '14px',
  padding: '0px 0px 8px 0px',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '13ch',
    },
  },
}))
