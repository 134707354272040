import { FormControl, InputLabel, Select, OutlinedInput, Box, Chip, Checkbox, InputAdornment } from "@mui/material"
import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './DepartmentDropDown.scss'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { getIndustryData } from "../../../services/MandateService";
import * as _ from 'lodash'
import IndustryChildDropdown from "./IndustryChildDropdown";
import Popover from '@mui/material/Popover';


const IndustryDropdown = ({ onChange, value, placeholder = "Add Industry",reportDropdownAPIData, preSelectedItems, enableLabel }: any) => {




  const [inputValue, setInputValue] = useState<any>({ label: '', count: "", placeholder: placeholder })
  const [selectedItems, setSelectedItems] = useState<any>({ value: preSelectedItems.length == 0 ? value=[] : preSelectedItems, labels: [] })
  const [industryData, setIndustryData] = useState<any>([])
  const [industrySearch, setIndustrySearch] = useState<string>('')
  const [popoverWidth, setpopoverWidth] = useState<any>()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const openMultiDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setpopoverWidth(event.currentTarget.getBoundingClientRect().width)
  }
  const closeMultiDropdown = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const fetchData = async () => {
    const reqObj = {
      "filters": [],
      "page": {
        "pageNo": 0,
        "pageSize": 10
      },
      "sorting": {
        "direction": "ASC",
        "field": "createdAt"
      }
    }
    try {
      if(reportDropdownAPIData){
        setIndustryData(reportDropdownAPIData);
      }else{
        const res: any = await getIndustryData(reqObj)
        setIndustryData(res.data.result.list);
      }
      


    } catch {
      setIndustryData([]);

    }
  }



  useEffect(() => {

    if (selectedItems?.value?.length) {
      const industryCategoryId = selectedItems?.value[0].industryCategoryId
      const industryObj = industryData.filter((ind:any)=> ind.id == industryCategoryId)
      const Name = industryObj[0]?.industryCategoryName
      let counttext = ''
      const itemsWithId = _.filter(selectedItems.value, { industryCategoryId: industryCategoryId });
      counttext = itemsWithId.length > 0 ? `(${itemsWithId.length}) \u00A0 \u00A0` : ""
      const distinctInd = _.uniqBy(selectedItems.value, "industryCategoryId")
      counttext += distinctInd.length > 1 ? `+${distinctInd.length - 1}` : ""
      setInputValue({ label: Name, count: counttext, placeholder: placeholder })
    }
    else {
      setInputValue({ label: '', count: '', placeholder: placeholder })
    }
    onChange(selectedItems.value)

  }, [selectedItems,industryData])


  useEffect(() => {
    fetchData()
  }, [])



  return (


    <div className={`multilevel-dropdown ${inputValue.label ? "enableLabel" : ""}`}>
      {enableLabel && <label>Add Industry</label>}
      <div className="input-box" onClick={(event:any) => openMultiDropdown(event)}>
        {inputValue.label ?
          <div className="input-ind-text">
            {inputValue.label} {inputValue.count}
          </div> :
          <div className="input-placeholder-text">
            {inputValue.placeholder}
          </div>
        }
 






        <div className="custom-arrow-icon">
          {
            anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
          }
        </div>

      </div>
      {/* {openMultiDrop && <div className="overlay-multiselect" onClick={() => setOpenMultiDrop(!openMultiDrop)}>
      </div>} */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeMultiDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: `${popoverWidth}px` },
        }}
      >
        <div className="input-list">
          <FormControl variant="standard">
            <OutlinedInput
                className="depIndSearch"
                placeholder="Search Industry"
                value={industrySearch}
                onChange={(e) => setIndustrySearch(e.target.value)}
                startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
                }
            />
          </FormControl>
          {industryData.map((indList: any, index: number) => {
            const isIndSearchParentExist = industrySearch == "" ? true : indList.industries.find((indSrc:any) => indSrc.industryName?.toLowerCase().includes(industrySearch?.toLowerCase()) || indSrc.children.find((indSrc:any) => indSrc.industryName?.toLowerCase().includes(industrySearch?.toLowerCase())))
            return (
              <>
              {indList.industryCategoryName?.toLowerCase().includes(industrySearch?.toLowerCase()) || isIndSearchParentExist ? <div key={index}>
                <IndustryChildDropdown
                  index={index}
                  indList={indList}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  industrySearch={industrySearch}
                />
              </div> : <></>}
              </>
            )
          })}
        </div>
        </Popover>
    </div>
  )
}

export default IndustryDropdown