import { 
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Alert,  
  Avatar, 
  Box, 
  Button, 
  Chip, 
  Divider, 
  Grid,
  IconButton,
  Modal,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import './MandateView.scss'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as ReasonIcon } from '../../../../assets/icons/reject-reason.svg'
import GroupStrick from '../../../../assets/images/default-avatar.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ClientProfile from '../../../../assets/icons/clientDetails.png'
import { ReactComponent as LinkPath } from '../../../../assets/icons/linkpath.svg'
import { ReactComponent as MandatesIcon } from '../../../../assets/icons/mandates.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/waring-icon.svg'
import { ReactComponent as OtherInformation } from '../../../../assets/icons/otherInformation.svg'
import { ReactComponent as FactorIcon } from '../../../../assets/icons/task.svg'
import { ReactComponent as ReceiptIcon } from '../../../../assets/icons/receipt-text.svg'
import { ReactComponent as PostJobsButtonIcon } from "../../../../assets/icons/postjobs-button.svg"
import potentialRevenue from "../../../../assets/icons/potential-revenue.svg"
import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg'
import ClientLogo from '../../../../assets/ClientLogo.png'
import { AppButton } from '../../../AppComponents/AppButton'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ProfileIcon } from '../../../../assets/icons/profile-icon.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar-2.svg'
import { convertToDisplayDate, convertToDisplayDateTime } from '../../../../utils/date-utils'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import _ from 'lodash'
import { AppErrorHandlerModal } from '../../../AppComponents/AppErrorHandlerModal'
import { approveOrRejectMandate,auditLogs } from '../../../../services/ApprovalListService'
import { getLoggedInUserId } from '../../../../utils/user-utils'
import { downloadMandateFile, getJobPostData, getMandateAuditLogs,MandateService } from '../../../../services/MandateService'
import { baseUrl } from '../../../../services/http-common'
import docIcon from '../../../../assets/icons/Doc.svg'
import pdfIcon from '../../../../assets/icons/pdf.svg'
import txtIcon from '../../../../assets/icons/txt.svg'
import excelIcon from '../../../../assets/icons/excelIcon.svg'
import defaultClientLogo from '../../../../assets/images/defaultUserIcon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpdateMandateStatus from "../../UpdateMandateStatus/UpdateMandateStatus";
import { ReactComponent as RevenueIcon } from '../../../../assets/icons/Revenue_chart.svg'
import JobOrderPopUp from "./JobOrderPopUp";
import { mandateEdit, mandateApprovalStatus, drawerclose, mandateLocationPosition } from '../../../../features/mandateDetail/DrawerReducer'
import ShowMoreText from "react-show-more-text";
import { useLocation } from 'react-router'
import { leftdrawertoggleclose } from "../../../../features/toggle/ToggleReducer";
import CollaboratorPopup from "../Collaborator/CollaboratorPopup";
import { ReactComponent as ShareMandateIcons } from '../../../../assets/icons/shareMandateIcons.svg';
import { ReactComponent as ShareMandateInfo } from '../../../../assets/icons/shareMandateInfo.svg'
import CollaboratorListPopup from "../Collaborator/CollaboratorListPopup";
import CloseIconPop from '@mui/icons-material/Close';

const MandateView = ({
  data,
  isRecruiter,
  isUserEditable,
  isApprovalList,
  handleMandateDownload,
  handleAssingClick,
  posIndex,
  setPosIndex,
  handledetial,
  loadFirstPage,
  departmentData,
  industryData
}: any) => {
  const navigate = useNavigate()
  const [locIndex, setLocIndex] = useState<number>(0)
  const ShowFullPage = useAppSelector((state) => state.mandatedrawer.FullPage)
  const mandateLocPos = useAppSelector((state) => state.mandatedrawer.locationPosition)
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [showSuccessdialog, setShowSuccessdialog] = useState(0)
  const [rejectionReason, setRejectiontReason] = useState('')
  const [hasError, setHasError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [showRejectReasonPopup, setShowRejectReasonPopup]= useState(false)
  const [auditLogsList, setAuditLogsList] = useState<any>([])
  const [rejectReason, setRejectReason]= useState('')
  const [updateManStatus, setUpdateManStatus]= useState<boolean>(false)
  const [manAuditHistory, setManAuditHistory] = useState<any[]>([])
  const [clientDetails , setClientDetails] = useState('')
  const [clientName , setClientName] = useState('')
  const [mandateDepartment] = useState<any>(data?.departmentData ? JSON.parse(data?.departmentData) : [])
  const [mandateIndustry] = useState<any>(data?.industryData ? JSON.parse(data?.industryData) : [])
  const aboutClientSect = useRef<any>(null);
  const search = useLocation()?.search
  const mId:any = new URLSearchParams(search).get("id");
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { roleId } = useAppSelector((state) => state.UserDetail)
  const [collaboratorPopupStatus, setCollaboratorPopupStatus] = useState(false);
  const [collaboratorListPopupStatus, setCollaboratorListPopupStatus] = useState(false)
  const [collaboratorListData, setCollaboratorListData] = useState<any>({})
  const [editCollStatus, setEditCollStatus] = useState("")
  const [editCollData, setEditCollData] = useState<any>({})
  const [approverAlertStatus,setApproverAlertStatus] = useState(data?.sharingRequests.length > 0 ? true : false)

  useEffect(() => {
    document.getElementsByClassName("mandate-detail-wrapper")[0].scrollTo({ "top": parseInt(mandateLocPos)});
    dispatch(mandateLocationPosition({locationPosition: "0"}));
  },[])

  useEffect(() => {
    if(editCollStatus){
      setCollaboratorPopupStatus(true)
      setCollaboratorListPopupStatus(false)
    }
  },[editCollStatus])

  const dispatch = useAppDispatch()
  const mandateEditState = useAppSelector((state) => state.mandatedrawer.isEdit)
  const mandateDrawerOpenStatus = useAppSelector((state) => state.mandatedrawer.drawer)  
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)  
  const isManEditable = (data?.restrictions ? (JSON.parse(data?.restrictions).includes("allowEdit")) : true)
  const isApprovalState = useAppSelector((state)=>state?.mandatedrawer?.approvalMandate)
  const assignAccess = (data:any)=>{
    if(data?.approvalStatus ==2) return false
    if(data?.status == 10) return false
    if(userRole == "Recruiter"){
      return false;
    } else {
      if(data?.approvers){
        if(data?.approvedBy){
          return data?.approvedBy == userId
        }
        return JSON.parse(data?.approvers).includes(userId)
      }
      return data?.createdBy == userId
    }
  }
  
  const handleDialogClose = () => {
    dispatch(drawerclose())
    dispatch(leftdrawertoggleclose())
    dispatch(mandateApprovalStatus({approvalMandate: !isApprovalState}))
    setShowSuccessdialog(0)
    navigate('/mandates')
  }
  const getAuditLogs =async()=>{
    const response = await auditLogs({mandateId:data?.id,createdBy:data?.createdBy})
    if(response){
      const auditList = response?.data?.sort((a:any, b:any) => new Date(a?.created_at) < new Date(b?.created_at) ? 1 : -1)
      setAuditLogsList(auditList)
    }
  }

  const getStatusLog = async () => {
    const reqObj = {
      filters: [
        {
          field: "eventId",
          operator: "in",
          type: "string",
          value: ["M01","M02"]
        },
        {
          field: "entityId",
          operator: "equals",
          type: "string",
          value: String(data?.id)
        }
      ],
      page: {
        pageNo: -1,
        pageSize: 10
      },
      sorting: {
          direction: "DESC",
          field: "createdAt"
      }
    }
    try{
      const res = await getMandateAuditLogs(reqObj)
      if(res?.status === 200){
        setManAuditHistory(res?.data?.result?.list[0] ?? [])
      }
    }catch(error){
      console.log(error,"error")
    }
  }

  useEffect(() => {
    if(data?.id){
      // if(!mandateEditState && mandateDrawerOpenStatus) getStatusLog()
      if(!mandateEditState) getStatusLog()
      // MandateService.getClientname()
      // .then((res: any) => {    
      //   _.map(res.data.result.list[0], (client:any)=>{
      //     if(client?.id ==data.clientId){
      //       setClientDetails(client.clientDetails)
      //       setClientName(client.clientName)
  
      //     }
      //   })
      // })
      // .catch((error: any) => {
      //     console.log(error.message)
      // })
     
    }
  }, [data])

  const handleShareMandatePopup = (() => {
    setCollaboratorPopupStatus(true);
    setEditCollData({})
  })


  const primarySkilsValue = useMemo(() => {
    let value = _.get(data, 'primarySkills')
    value = value?.replace('  ', '')
    if (value?.length > 0) {
      value = value?.split(',')
    } else {
      value = []
    }
    return value
  }, [data])

  const secondarySkilsValue = useMemo(() => {
    let value = _.get(data, 'secondarySkills')
    value = value?.replace('  ', '')
    if (value?.length > 0) {
      value = value?.split(',')
    } else {
      value = []
    }
    return value
  }, [data])
  const handleReject = async () => {
    const action = {
      action: 'reject',
      reason: rejectionReason,
      approver: getLoggedInUserId(),
    }
    try {
      const response = await approveOrRejectMandate(action, data.id)
      if (response.status == 200) {
        setShowRejectPopup(false)
        setShowSuccessdialog(2)
      }
    } catch (error) {
      setShowRejectPopup(false)
      setErrorMsg('Error while Rejecting')
      setHasError(true)
    }
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setHasError(false)
  }
  const handleApprove = async (e: any) => {
    const action = {
      action: 'approve',
      reason: null,
      approver: getLoggedInUserId(),
    }
    try {
      const response = await approveOrRejectMandate(action, data.id)
      if (response.status == 200) {
        setShowRejectPopup(false)
        setShowSuccessdialog(1)
      }
    } catch (error) {
      setErrorMsg('Error while Approving')
      setHasError(true)
    }
  }
  const resubmit = async (e: any) => {
    if (isUserEditable) {
      const id = data?.id
      navigate(`/edit-mandate/${id}`)
    }
  }

  const handleAttachmentDownload = async (filePath:any) => {
    const excelLinkSource = `filename=${filePath}`
    try {
      const response = await downloadMandateFile(excelLinkSource)
      if (response?.data) {
        window.location.href = (`${baseUrl}mandate/download?${excelLinkSource}`)
      } else {
        console.error("No Such File")
      }
    } catch (error) {
      console.error("Internal Server Error", error)
    }
  };

  const getFileIcon = (fileName:string) => {
    const fileType = fileName ? fileName.split('.').at(-1) : ""
    switch(fileType){
      case "docx":
      case "doc":
        return docIcon
      case "pdf":
        return pdfIcon
      case "txt":
        return txtIcon
      case "xlsx":
        return excelIcon
      default:
        return txtIcon
    }
  }

  const tagStyle:any = data?.styles ? JSON.parse(data?.styles) : {}

  console.log("-------------", showRejectPopup)
  const getCollaboratorPermission = (mandateData:any, permissionType:string) => {
    const selectedCollPermission =  JSON.parse(mandateData?.collaborators).filter((item:any) => item.collobatorId == userId)
    return (selectedCollPermission.length > 0 && permissionType == "Edit" && userRole != "Admin") ? JSON.parse(selectedCollPermission[0]?.permissions).includes(23) : true;
}
  const ClientDetails = () => {
    return (
      <Grid className='container-wrapper'>
        <Grid className={ShowFullPage ? 'grid-container' : 'grid-quick-view'}>
          <div>
            <div className='header-text'>Client Name</div>
            <div className='data-text' id="clientName">{data?.clientName ? data?.clientName : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Contract Reference</div>
            <div className='data-text' id="contractReference">
              {data?.contractReference ? data?.contractReference : '-'}
            </div>
          </div>
          <div>
            <div className='header-text'>Client Request No</div>
            <div className='data-text' id="clientRequestNo">{data?.clientRequestNo ? data?.clientRequestNo : '-'}</div>
          </div>
          <div>
            
          </div>
          <div>
            <div className='header-text'>Mandate SPOC</div>
            <div className='data-text' id="clientSpoc">{data?.clientSpoc ? data?.clientSpoc : '-'}</div>
          </div>
          <div>
            <div className='header-text'>SPOC Type</div>
            <div className='data-text' id="clientSpoc">{data?.clientSpocType ? data?.clientSpocType : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Phone Number</div>
            <div className='data-text' id="clientSpoc">{data?.clientSpocMobile ? data?.clientSpocMobile : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Email</div>
            <div className='data-text' id="clientSpoc">{data?.clientSpocEmail ? data?.clientSpocEmail : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Received On</div>
            <div className='data-text' id="receivedOn">
              {data?.receivedOn ? moment(new Date(data?.receivedOn)).format('DD/MM/YYYY') : '-'}
            </div>
          </div>
          <div>
            <div className='header-text'>Received By</div>
            <div className='data-text' id="receivedBy">{data?.receivedBy ? data?.receivedBy : '-'}</div>
          </div>
          <div>
            <div className='header-text'>About client</div>
            <div className='data-text' id="clientRemarks">
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            >{data?.clientDetails ? data?.clientDetails : '-'}
           </ShowMoreText></div>
          </div>
        </Grid>
      </Grid>
    )
  }

  const MandateDetails = () => {
    return (
      <Grid className='container-wrapper'>
        <Grid className={ShowFullPage ? 'grid-container' : 'grid-quick-view'}>
          <div>
            <div className='header-text'>Job Title</div>
            <div className='data-text' id="jobTitle">{data?.jobTitle}</div>
          </div>

          <div>
            <div className='header-text'>Job Type</div>
            <div className='data-text' id="jobType">{data?.jobType ? data?.jobType : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Priority</div>
            <div className='data-text' id="jobPriority">{data?.jobPriority ? data?.jobPriority : '-'}</div>
          </div>
         
          <div>
            <div className='header-text'>Mandate Type</div>
            <div className='data-text' id="mandateType">{data?.mandateType ? data?.mandateType : '-'}</div>
          </div>
         
          <div>
            <div className='header-text'>Start Date</div>
            <div className='data-text' id="requestStartDate">
              {data?.requestStartDate
                ? moment(new Date(data?.requestStartDate)).format('DD/MM/YYYY')
                : '-'}
            </div>
          </div>
          <div>
            <div className='header-text'>End Date</div>
            <div className='data-text' id="requestEndDate">
              {data?.requestEndDate
                ? moment(new Date(data?.requestEndDate)).format('DD/MM/YYYY')
                : '-'}
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }

  const MandateSpecs = () => {
    return (
      <Grid className='container-wrapper'>
        <Grid className={ShowFullPage ? 'grid-container' : 'grid-quick-view'}>
          
          <div>
            <div className='header-text'>Job Mode</div>
            <div className='data-text'>{data?.jobMode ? data?.jobMode : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Shift</div>
            <div className='data-text'>{data?.shift ? data?.shift : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Qualification</div>
            <div className='data-text'>{data?.qualification ? data?.qualification : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Experience</div>
            <div className='data-text' id="expRange">
              {data?.expRangeStart || data?.expRangeEnd 
                ? `${data?.expRangeStart} Years - ${data?.expRangeEnd} Years`
                : '-'}
            </div>
          </div>
          <div className={mandateDepartment.length && "depIndFull"}>
            <div className='header-text'>Department</div>
            {mandateDepartment.length && departmentData.length ? 
            <div className='depIndContainer' id="expRange">
              {departmentData && departmentData.map((dep:any, index:number) => {
                const isDepExist = mandateDepartment.find((manDep:any) => manDep.departmentId == dep.id);
                return <>
                  {isDepExist && 
                    <div key={index} className="depIndContain">
                      <h4>{dep.depName}</h4>
                      {dep.departmentRoles.map((depRoles:any, index:number) => {
                        const isRoleExist = mandateDepartment.find((manDep:any) => manDep.parentId == depRoles.id || manDep.id == depRoles.id);
                        return <>
                          {isRoleExist && 
                            <div key={index} className="depIndWrap">
                              <h5>{depRoles.roleName}</h5>
                              <div className="depIndBadges">
                                {depRoles.children.map((depchild:any, index:number) => {
                                  const isChildExist = mandateDepartment.find((manDep:any) => manDep.id == depchild.id);
                                  return <>
                                    {isChildExist && <div key={index}>{depchild.roleName}</div>}
                                  </>
                                })}
                              </div>
                            </div>
                          }
                        </>
                      })}
                    </div>
                  }
                </>
              })}
            </div>
            : 
            <div className='data-text'>-</div>
            }
          </div>
          <div className={mandateIndustry.length && "depIndFull"}>
            <div className='header-text'>Industry</div>
            {mandateIndustry.length && industryData.length ? 
            <div className='depIndContainer' id="expRange">
              {industryData && industryData.map((ind:any, index:number) => {
                const isIndExist = mandateIndustry.find((manInd:any) => manInd.industryCategoryId == ind.id);
                return <>
                  {isIndExist && 
                    <div key={index} className="depIndContain">
                      <h4>{ind.industryCategoryName}</h4>
                      {ind.industries.map((indCategory:any, index:number) => {
                        const isRoleExist = mandateIndustry.find((manInd:any) => manInd.parentId == indCategory.id || manInd.id == indCategory.id);
                        return <>
                          {isRoleExist && 
                            <div key={index} className="depIndWrap">
                              <h5>{indCategory.industryName}</h5>
                              <div className="depIndBadges">
                                {indCategory.children.map((indchild:any, index:number) => {
                                  const isChildExist = mandateIndustry.find((manInd:any) => manInd.id == indchild.id);
                                  return <>
                                    {isChildExist && <div key={index}>{indchild.industryName}</div>}
                                  </>
                                })}
                              </div>
                            </div>
                          }
                        </>
                      })}
                    </div>
                  }
                </>
              })}
            </div>
            : 
            <div className='data-text'>-</div>
            }
          </div>
          <div>
            <div className='header-text'>CTC Range</div>
            <div className='data-text' id="ctcRange">
              {data?.ctcRangeStart ? `${data?.ctcRangeStart}L - ${data?.ctcRangeEnd}L` : '-'}
            </div>
          </div>
          <div>
            <div className='header-text'>Onsite (If any)</div>
            <div className='data-text'>{data?.onsite ? data?.onsite : '-'}</div>
          </div>
        </Grid>
        <Grid className='row-grid pt-1'>
          <Grid style={{ width: '50%', overflow: 'hidden' }}>
            <div className='header-text'>Job Description</div>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            ><div
            id="jobDescription"
            dangerouslySetInnerHTML={{ __html: data?.jobDescription }}
          ></div>
           </ShowMoreText>
            
          </Grid>
          <Grid style={{ width: '50%', overflow: 'hidden' }}>
            <div className='header-text'>Job Description Ai</div>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            ><div
            id="jobDescription"
            dangerouslySetInnerHTML={{ __html: data?.jobDescriptionAi }}
          ></div>
           </ShowMoreText>
            
          </Grid>
        </Grid>
        {/* <Grid className="pt-1">
          <div style={{fontWeight:600, fontSize:"16px", textDecoration:"underline"}}>Roles and Responsibilities</div>
          <ul style={{fontWeight:400, fontSize:"14px"}}>
            <li>Customer Service Support Advicor(International voice support)</li>
            <li>Excellent communication required</li>
            <li>Experience 0-6 Months(Including Freshers) and 6 Months to one year.</li>
            <li>Location: Kolkata / Gurugram</li>
            <li>(Work From Office)</li>
            <li>Duration 12 Months and Extendable</li>
            <li>Shift: Will be rotational shift including Night shift (rooster week off)</li>
            <li>Below the Location transport provided</li>
          </ul>
        </Grid>
        <Grid className="pt-1">
          <div style={{fontWeight:600, fontSize:"16px", textDecoration:"underline"}}>Job Role and Responsibility</div>
          <ul style={{fontWeight:400, fontSize:"14px"}}>
            <li>Role involves working voice (inbound and outbound)queues basis the alignment. Need to understand the customer query.</li>
            <li>Concern and take appropriate action accordingly. This can involve a certain amount of investigative work.</li>
            <li>Checking for process via different tools. Working as part of team to consistently deliver good customer experience.</li>
            <li>Satisfaction and operational targets including quality measures.</li>
          </ul>
        </Grid> */}
        <Grid className='row-grid pt-1'>
          <Grid style={{ width: '50%', overflow: 'hidden' }}>
            <Box>
              <Typography sx={{ marginTop: '10px' }} className='header-text'>
                Primary skills
              </Typography>
            </Box>
            {/* <Box sx={{marginTop:"10px", maxHeight:"240px", overflowY:"auto"}}>
              <span style={{ paddingRight: "10px", marginTop:"10px"}}> */}
            {/* <Tooltip title={primarySkilsValue}>
                  <Chip className="secondary-text-font-with-black" sx={{ m: 0.5, borderRadius: "5px", }} key={data} label={primarySkilsValue} />
                </Tooltip> */}
            {/* <div className="rich-text-editor" dangerouslySetInnerHTML={{__html: data?.primarySkills}}></div>
              </span>
              </Box> */}
            <Box sx={{ marginTop: '10px' }} id="primarySkills">
              {primarySkilsValue?.map((data: any, index: number) => {
                const chipLabel = data?.replaceAll("'", '')
                return (
                  <>
                    <span key={index} style={{ paddingRight: '10px', marginTop: '10px' }}>
                      <Tooltip title={chipLabel}>
                        <Chip
                          className='secondary-text-font-with-black'
                          sx={{ m: 0.5, borderRadius: '5px' }}
                          key={data}
                          label={data.replaceAll("'", '')}
                        />
                      </Tooltip>
                    </span>
                  </>
                )
              })}
            </Box>
          </Grid>
          <Grid style={{ width: '50%', overflow: 'hidden' }}>
            <Box>
              <Typography sx={{ marginTop: '10px' }} className='header-text'>
                Secondary Skills
              </Typography>
            </Box>
            {/* <Box  sx={{marginTop:"10px"  }}>
              <span style={{ paddingRight: "10px", marginTop:"10px", maxHeight:"240px", overflowY:"auto"}}> */}
            {/* <Tooltip title={secondarySkilsValue}>
                  <Chip className="secondary-text-font-with-black" sx={{ m: 0.5, borderRadius: "5px", }} key={data} label={secondarySkilsValue} />
                </Tooltip> */}
            {/* <div className="rich-text-editor" dangerouslySetInnerHTML={{__html: data?.secondarySkills ? data?.secondarySkills : "-"}}></div>
              </span>
            </Box> */}
            <Box sx={{ marginTop: '10px' }} id="secondarySkills">
              {secondarySkilsValue?.map((data: any) => {
                const chipLabel = data?.replaceAll("'", '')
                return (
                  <>
                    <span style={{ paddingRight: '10px', marginTop: '10px' }}>
                      <Tooltip title={chipLabel}>
                        <Chip
                          className='secondary-text-font-with-black'
                          sx={{ m: 0.5, borderRadius: '5px' }}
                          key={data}
                          label={data.replaceAll("'", '')}
                        />
                      </Tooltip>
                    </span>
                  </>
                )
              })}
            </Box>
          </Grid>
        </Grid>
        <Grid
          className='pt-1'
        >
          <div>
            <div className='header-text'>Sourcing Notes/Remarks</div>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            >
            <div
              className=''
              dangerouslySetInnerHTML={{
                __html: data?.secondaryMatch ? data?.secondaryMatch : '-',
              }}
            ></div>
            </ShowMoreText>
          </div>
        </Grid>
      </Grid>
    )
  }

  const MandateLocationDetails = () => {
    const mandatelocationData = data?.mandateLocation ? data?.mandateLocation : []
    const priceData = mandatelocationData[posIndex]?.priceData ? JSON.parse(mandatelocationData[posIndex]?.priceData) : []
    const priceFieldVal = priceData.filter((data:any) => !(data?.field_label.includes("Potential")))
    const potentialVal = priceData.find((data:any) => data?.field_label.includes("Potential"))
    const [joOpen, setJoOpen] = useState<boolean>(false)
        return (
      <>
      <Grid className='container-wrapper'>
        <Grid className='row-grid' style={{ gap: '10px', flexWrap: 'wrap' }}>
          {mandatelocationData.length > 0 && mandatelocationData?.map((mll:any, index:any)=>(
            <Button
              key={index}
              style={{
                textTransform: 'none',
                backgroundColor: posIndex==index ? '#002882' :"#F0F6FB",
                borderRadius: '8px',
                color: posIndex==index ? '#fff': "#002882",
                fontWeight: 400,
                display:"flex",
                flexDirection:"column",
                alignItems:"start"
              }}
              onClick={() =>{
                const elem = document.getElementsByClassName("mandate-detail-wrapper")[0]
                dispatch(mandateLocationPosition({locationPosition: elem.scrollTop}));
                setPosIndex(index)
              }}
            >
              <span>{`${mll?.primaryLocation} (${mll?.jobOrders})`}</span>
              {/* <span
                style={{
                  fontSize: "10px",
                  color: posIndex==index ? '#9EADCF': "#727376"
                }}
              >{`XRDMAN${mll?.mandateId}LOC${mll?.id}`}</span> */}
            </Button>
          ))}
        </Grid>
        <Grid className={ShowFullPage ? 'grid-container pt-1' : 'grid-quick-view pt-1'}>
        <div>
            <div className='header-text'>Salary Range</div>
            <div className='data-text'>
              {(mandatelocationData[posIndex]?.ctcRangeStart >= 0)
                ? `${mandatelocationData[posIndex]?.ctcRangeStart}L - ${mandatelocationData[posIndex]?.ctcRangeEnd}L`
                : '-'
              }
            </div>
          </div>
          <div>
            <div className='header-text'>Primary Location</div>
            <div className='data-text'>{mandatelocationData[posIndex]?.primaryLocation ? mandatelocationData[posIndex]?.primaryLocation : '-'}</div>
          </div>
         
          <div>
            <div className='header-text'>Secondary Location</div>
            <div className='data-text'>{mandatelocationData[posIndex]?.secondaryLocations ? JSON.parse(mandatelocationData[posIndex]?.secondaryLocations).join(', ') : '-'}</div>
          </div>

          <div>
            <div className='header-text'>Job Orders</div>
            <div className="JO-wrapper" >
            <span className='data-text'>{mandatelocationData.length > 0 ? mandatelocationData[posIndex]?.jobOrders : '-'}</span>
              {mandatelocationData.length > 0 && <LinkPath onClick={()=> {navigate(`/jobOderDetailView?id=${mId}&locId=${data?.mandateLocation[posIndex]?.id}`,{state:{timeStamp: new Date().getTime(),mandateData: data}})}} />}
            </div>
          </div>
        
          <div className="remark-container">
            <div className='header-text'>Location Remarks</div>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            >
              <div className=''>{mandatelocationData[posIndex]?.remarks ? mandatelocationData[posIndex]?.remarks :
             '-'}</div>
             </ShowMoreText>
          </div>
          <div>
             <div className='header-text'>Status</div>
             <div className='data-text'>{mandatelocationData[posIndex]?.statusName  ? mandatelocationData[posIndex]?.statusName : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Ageing</div>
            <div className='data-text'>{mandatelocationData[posIndex]?.createdAt ? convertToDisplayDateTime(mandatelocationData[posIndex]?.createdAt) : '-'}</div>
          </div>
         
          <div>
            <div className='header-text'>Last Modified Date</div>
            <div className='data-text'>{mandatelocationData[posIndex]?.updatedAt ? convertToDisplayDate(mandatelocationData[posIndex]?.updatedAt) : '-'}</div>

          </div>
        </Grid>
        <Grid className='row-grid pt-1' style={{ gap: '10px', alignItems: 'center' }}>
          <div style={{ fontSize: '16px', fontWeight: 500, color: "#000" }}>{'Hiring Manager SPOC'}</div>
          <Divider style={{width: "calc(100% - 210px)"}} />
        </Grid>
        <Grid >
          {!!mandatelocationData[posIndex]?.mandateLocationSpocs && mandatelocationData[posIndex]?.mandateLocationSpocs.map((spoc:any, index:number) => (
            <Grid key={index} className={ShowFullPage ? 'grid-container pt-1' : 'grid-quick-view pt-1'}>
              <div key={index}>
                <div className='header-text'>{`Hiring Manager SPOC ${index + 1}`}</div>
                <div className='data-text'>{spoc?.spocName || "-"}</div>
              </div>
              <div key={index}>
                <div className='header-text'>Type</div>
                <div className='data-text'>{spoc?.spocType || "-"}</div>
              </div>
              <div key={index}>
                <div className='header-text'>Phone Number</div>
                <div className='data-text'>{spoc?.spocMobile || "-"}</div>
              </div>
              <div key={index}>
                <div className='header-text'>Email</div>
                <div className='data-text'>{spoc?.spocEmail || "-"}</div>
              </div>
              <div key={index}></div>
              {ShowFullPage && <div key={index}></div>}
            </Grid>
          ))}
        </Grid>
        <Grid className='row-grid pt-1' style={{ gap: '10px', alignItems: 'center' }}>
          <div style={{ fontSize: '16px', fontWeight: 500, color: "#000" }}>{'Pricing'}</div>
          <Divider className='ruler-hr' />
        </Grid>
        <Grid className={ShowFullPage ? 'grid-container pt-1' : 'grid-quick-view pt-1'}>
          {priceFieldVal.map((price:any, index:number) => (
            <div key={index}>
              <div className='header-text'>{price?.field_label}</div>
              <div className='data-text'>{price?.value}</div>
            </div>
          ))}
        </Grid>
        {potentialVal && 
          <Grid className='row-grid' style={{paddingTop:"24px"}}>
            <div className="potential-wrap" style={{display:"flex", gap:"10px"}} >
              <RevenueIcon/>
              <div>
                <div className='potential-header'>{potentialVal?.field_label}</div>
                <div className='potential-val'>{potentialVal?.value ? potentialVal?.value : "-"}</div>
              </div>
            </div>
          </Grid>
        }
      </Grid>
      {joOpen && (
        <JobOrderPopUp
          open={joOpen}
          handleClose={()=>setJoOpen(false)}
          location ={mandatelocationData[posIndex]}
          manID={data?.id}
        />
      )}
      </>
    )
  }

  const SuccessFactors = () => {
    return (
      <Grid className='container-wrapper'>
        <Grid className='row-grid'>
          {/* <div style={{ width: '25%' }}>
            <div className='header-text'>Target submissions</div>
            <div className='data-text'>{data?.targetSubmission ? data?.targetSubmission : '-'}</div>
          </div> */}
          <div style={{ width: '25%' }}>
            <div className='header-text'>TAT (Days)</div>
            <div className='data-text'>{data?.tat ? data?.tat : '-'}</div>
          </div>
          <div style={{ width: '50%' }}>
            <div className='header-text'>Success Factors Instructions</div>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            ><div className=''>{data?.instructions ? data?.instructions : '-'}</div></ShowMoreText>
          </div>
        </Grid>
        <Grid className='row-grid pt-1'>
          <div style={{ width: '50%' }}>
            <div className='header-text'>Success Factors Remarks</div>
            <ShowMoreText
                lines={3}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                // onClick={this.executeOnClick}
                expanded={false}
                // width={280}
                truncatedEndingComponent={"... "}
            ><div className=''>
              {data?.successFactorRemarks ? data?.successFactorRemarks : '-'}
            </div>
            </ShowMoreText>
          </div>
        </Grid>
        <Grid className='row-grid pt-1' style={{ gap: '10px', alignItems: 'center' }}>
          <div style={{ fontSize: '16px', fontWeight: 400 }}>{'KPI’s'}</div>
          <Divider className='ruler-hr' />
        </Grid>
        <Grid className={ShowFullPage ? 'grid-container pt-1' : 'grid-quick-view pt-1'}>
          <div>
            <div className='header-text'>Submissions</div>
            <div className='data-text'>{data?.submissions ? data?.submissions : '-'}</div>
          </div>
          <div>
            <div className='header-text'>CCM 1</div>
            <div className='data-text'>{data?.ccm1 ? data?.ccm1 : '-'}</div>
          </div>
          <div>
            <div className='header-text'>CCM 2</div>
            <div className='data-text'>{data?.ccm2 ? data?.ccm2 : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Offers</div>
            <div className='data-text'>{data?.offers ? data?.offers : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Selects</div>
            <div className='data-text'>{data?.selects ? data?.selects : '-'}</div>
          </div>
          <div>
            <div className='header-text'>Joinees</div>
            <div className='data-text'>{data?.joinees ? data?.joinees : '-'}</div>
          </div>
        </Grid>
      </Grid>
    )
  }

  const AttachmentFields = () => {
    return (
      <Grid className='container-wrapper'>
        <Grid className={ShowFullPage ? 'grid-container' : 'grid-quick-view'} style={{columnGap:"30px"}}>
          {data?.file?.map((file: any, index: number) => {
            const show = file?.filename
            const fileIcon = getFileIcon(file?.filename)
            return (
              <>
                {!!show && (
                  <div key={index} style={{ overflow: 'hidden' }}>
                    <div className='header-text'>
                      {file?.title ? file?.title : `Attachment${index + 1}`}
                    </div>
                    <div
                      className='data-text'
                      style={{ display: 'flex', alignItems: 'start', gap: '4px' }}
                    >
                      <img src={fileIcon} alt='icon' />
                      <span className='mandate-file-name'>{file?.filename}</span>
                      <DownloadIcon 
                        style={{minWidth:"20px", cursor:"pointer"}}
                        onClick={()=> handleAttachmentDownload(file?.path)}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          })}
        </Grid>
      </Grid>
    )
  }
  const Logs = () => {
    return (
      <Table className="audit-log-table">
        <TableHead>
          <TableRow>
            <TableCell style={{width:"40%"}} >Action By</TableCell>
            <TableCell style={{width:"20%"}}>Action</TableCell>
            <TableCell style={{width:"15%"}}>Reason</TableCell>
            <TableCell style={{width:"25%"}}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {manAuditHistory?.map((item:any, index:number) => (
            <TableRow key={index} >
              <TableCell style={{width:"40%"}}>
                <Box className="d-flex" >
                  <Box>
                    <Avatar
                      alt='no-group'
                      src={GroupStrick}
                      sx={{ width: 30, height: 30 }}
                    />
                  </Box>
                  <Box className='user-detail' >
                    <Tooltip title={item?.userEntity?.firstName} placement="bottom-start">
                      <span className='primary-text ellipsisText1'>{item?.userEntity?.firstName}</span>
                    </Tooltip>
                    <span className='secondary-text'>{item?.userEntity?.roleName || "-"}</span>
                  </Box>
                </Box>
              </TableCell>
              <TableCell style={{width:"20%"}}>
                <Tooltip title={item?.eventData?.statusEntity?.statusName} placement="bottom-start" >
                  <span className="primary-text text-ellips" >{item?.eventData?.statusEntity?.statusName || "-"}</span>
                </Tooltip>
              </TableCell>
              <TableCell style={{width:"15%"}}>
                <Box style={{paddingLeft:"15px", cursor: "pointer"}} >
                  {item?.auditReason ? (
                    <ReasonIcon
                      onClick={() => {
                        setShowRejectReasonPopup(true)
                        setRejectReason(item?.auditReason)
                      }}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </Box>
              </TableCell>
              <TableCell style={{width:"25%"}}>
                <div className="primary-text" >{convertToDisplayDateTime(item?.createdAt)}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '506px',
    // height: '280px',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: "32px 32px 20px"
  }
  const postJobPage = async (id:any, careerId:any) => {
    if(careerId != undefined){
      getJobPostData(careerId)
      .then((res) => {
        const mandateDetails = res[0];
        mandateDetails["mandateCode"] = data?.mandateCode
        navigate(`/post-jobs/${id}`,{state:{mandateView: mandateDetails,type:"update"}})
      })
      .catch((error) => {
        console.log('error', error.message)
      })  
    }else{
      navigate(`/post-jobs/${id}`,{state:{mandateView: data}}) 
    }
  }
  return (
    <Box className='mandate-detail-wrapper'>
      <Box style={{paddingLeft: "20px",paddingRight: "15px"}}>
        <Box className='header-container'>
          <Box className='mandate-profile'>
            <Avatar sx={{ width: 45, height: 45 }} className='mandate-image' src={defaultClientLogo} />
            <Box className='profile-text'>
              <Typography id='ai-chatbot-manName' className='primary-text-font'>{data.jobTitle}</Typography>
              <Typography id='ai-chatbot-manId' className='secondary-text-font'>{data?.mandateCode}</Typography>
            </Box>
          </Box>
          <Box className='btn-container'>
            {!isApprovalList ? (
              <>
                {((userPermissions?.mandate_module?.list?.postjobs?.selected && userRole != "Admin") || userRole == "Admin") ? (
                  <Box className='mandate-postjob-btn'>
                    <Button className='postjobs' startIcon={<PostJobsButtonIcon />} onClick={() => postJobPage(data?.id,data.xpCareerId)}>{data.xpCareerId !=  undefined ? "Update Jobs" : "Post Jobs"}</Button>
                  </Box>
                ) : (
                  <></>
                )}
                
                  {(userPermissions?.mandate_module?.edit?.edit?.selected && userRole != "Admin" && getCollaboratorPermission(data, "Edit")) &&
                  <Box className='mandate-edit-btn'>
                      <AppButton
                      label='Edit'
                      bordercolor='black'
                      bgcolor='black'
                      size='large'
                      type='button'
                      className={`edit-btn ${(!isUserEditable || !isManEditable) ? 'edit-read-only-icon' : ''}`}
                      sx={{ color: '#fff' }}
                      title={
                        !isUserEditable
                          ? 'Edit option allowed only for user who created the mandate'
                          : !isManEditable ? 'Edit disable for current mandate status' : ''
                      }
                      onClick={() => {
                        if (isUserEditable && isManEditable) {
                          const id = data?.id
                          dispatch(mandateEdit({ isEdit: true }))
                          
                          navigate(`/edit-mandate/${id}`)
                        }
                      }}
                    >
                      <EditIcon />
                    </AppButton>
                  </Box>
                  }
                  {(userRole == "Admin" && getCollaboratorPermission(data, "Edit")) &&
                  <Box className='mandate-edit-btn'>
                     <AppButton
                    label='Edit'
                    bordercolor='black'
                    bgcolor='black'
                    size='large'
                    type='button'
                    className={`edit-btn ${(!isUserEditable || !isManEditable) ? 'edit-read-only-icon' : ''}`}
                    sx={{ color: '#fff' }}
                    title={
                      !isUserEditable
                        ? 'Edit option allowed only for user who created the mandate'
                        : !isManEditable ? 'Edit disable for current mandate status' : ''
                    }
                    onClick={() => {
                      if (isUserEditable && isManEditable) {
                        const id = data?.id
                        dispatch(mandateEdit({ isEdit: true }))
                        
                        navigate(`/edit-mandate/${id}`)
                      }
                    }}
                  >
                    <EditIcon />
                  </AppButton>
                  </Box>
                  }
                {data?.fileName && (
                  <Box className='download-btn'>
                    <AppButton
                      label=''
                      bordercolor='#FF6E00'
                      bgcolor='#FF6E00'
                      size='large'
                      type='button'
                      className={`download-btn `}
                      sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                      onClick={handleMandateDownload}
                    >
                      <DownloadIcon />
                    </AppButton>
                  </Box>
                )}
              </>
            ) : (
              <>
                {(userPermissions?.mandate_module?.view?.show_approve_reject_button?.selected) &&
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <Box className='mandate-assign-btn'>
                      <AppButton
                        label='Approve'
                        bordercolor={data?.approvalStatus == 2 ? '#b3b5b7' : '#319933'}
                        bgcolor={data?.approvalStatus == 2 ? '#b3b5b7' : '#319933'}
                        size='large'
                        type='button'
                        disabled={data?.approvalStatus == 2}
                        onClick={handleApprove}
                        sx={{ color: '#fff' }}
                      ></AppButton>
                    </Box>
                    <Box className='mandate-assign-btn'>
                      <AppButton
                        label='Reject'
                        bordercolor={data?.approvalStatus == 2 ? '#b3b5b7' : '#F34334'}
                        bgcolor={data?.approvalStatus == 2 ? '#b3b5b7' : '#F34334'}
                        size='large'
                        type='button'
                        sx={{ color: '#fff' }}
                        disabled={data?.approvalStatus == 2}
                        onClick={() => setShowRejectPopup(true)}
                      ></AppButton>
                    </Box>
                    <Box className={`mandate-edit-btn mandate-assign-btn ${data?.approvalStatus == 2 ? "disableEdit" : ""}`}>
                    <AppButton
                    label='Edit'
                    bordercolor={data?.approvalStatus == 2 ? '#b3b5b7' : 'black'}
                    bgcolor={data?.approvalStatus == 2 ? '#b3b5b7' : 'black'}
                    size='large'
                    type='button'
                    className={`edit-btn`}
                    sx={{ color: '#fff' }}
                    disabled={data?.approvalStatus == 2}
                    title={
                      !isUserEditable
                        ? 'Edit option allowed only for user who created the mandate'
                        : !isManEditable ? 'Edit disable for current mandate status' : ''
                    }
                    onClick={() => {
                      if (isUserEditable && isManEditable) {
                        const id = data?.id
                        navigate(`/edit-mandate/${id}`)
                      }
                    }}
                  >
                    <EditIcon />
                  </AppButton>
                    </Box>
                  </Box>
                }
                {(userPermissions?.mandate_module?.edit?.show_resubmit_button?.selected) &&
                  <Box className='mandate-edit-btn'>
                    {isUserEditable && data?.approvalStatus == 2 && (
                      <AppButton
                        label='Resubmit'
                        bordercolor='#002882'
                        bgcolor='#002882'
                        size='large'
                        type='button'
                        // className={`edit-btn ${isUserEditable && data?.approvalStatus == 2 ? '': 'edit-read-only-icon' }`}
                        className={`edit-btn`}
                        sx={{ color: '#fff' }}
                        title={
                          !isUserEditable
                            ? 'Edit option allowed only for user who created the mandate'
                            : ''
                        }
                        onClick={() => {
                          if (isUserEditable && data?.approvalStatus == 2) {
                            const id = data?.id
                            navigate(`/edit-mandate/${id}`)
                          }
                        }}
                      >
                        {/* <EditIcon /> */}
                      </AppButton>
                    )}
                    {isUserEditable && data?.status == 10 && (
                      <>
                      {(userPermissions?.mandate_module?.edit?.edit?.selected && userRole != "Admin") &&
                      <Box className='mandate-edit-btn'>
                        <AppButton
                          label='Edit'
                          bordercolor='black'
                          bgcolor='black'
                          size='large'
                          type='button'
                          className={`edit-btn ${(!isUserEditable || !isManEditable) ? 'edit-read-only-icon' : ''}`}
                          sx={{ color: '#fff' }}
                          title={
                            !isUserEditable
                              ? 'Edit option allowed only for user who created the mandate'
                              : !isManEditable ? 'Edit disable for current mandate status' : ''
                          }
                          onClick={() => {
                            if (isUserEditable && isManEditable) {
                              const id = data?.id
                              navigate(`/edit-mandate/${id}`)
                            }
                          }}
                        >
                          <EditIcon />
                        </AppButton>
                      </Box>}
                      {(userRole == "Admin") &&
                      <Box className='mandate-edit-btn'>
                        <AppButton
                          label='Edit'
                          bordercolor='black'
                          bgcolor='black'
                          size='large'
                          type='button'
                          className={`edit-btn ${(!isUserEditable || !isManEditable) ? 'edit-read-only-icon' : ''}`}
                          sx={{ color: '#fff' }}
                          title={
                            !isUserEditable
                              ? 'Edit option allowed only for user who created the mandate'
                              : !isManEditable ? 'Edit disable for current mandate status' : ''
                          }
                          onClick={() => {
                            if (isUserEditable && isManEditable) {
                              const id = data?.id
                              navigate(`/edit-mandate/${id}`)
                            }
                          }}
                        >
                          <EditIcon />
                        </AppButton>
                      </Box>}
                      </>
                    )}
                  </Box>
                }
                {data?.fileName && (
                  <Box className='download-btn'>
                    <AppButton
                      label=''
                      bordercolor='#FF6E00'
                      bgcolor='#FF6E00'
                      size='large'
                      type='button'
                      className={`download-btn `}
                      sx={{ color: '#fff', backgroundColor: '#fff', p: 1 }}
                      onClick={handleMandateDownload}
                    >
                      <DownloadIcon />
                    </AppButton>
                  </Box>
                )}
              </>
            )}
            <Box className="collabApproveSectBlk">
                <AppButton
                  tooltip = {"Collaborators"}
                  label=''
                  bordercolor='#002882'
                  bgcolor='#e6edf4'
                  size='large'
                  type='button'
                  className={`shareMandteBtn`}
                  sx={{ color: '#fff', backgroundColor: '#fff', p: 1,border:"2px solid #002882" }}
                  onClick={handleShareMandatePopup}
                >
                  <ShareMandateIcons /><p>{data?.noOfCollaborators}</p>
                  {(data?.sharingRequests.length > 0 && approverAlertStatus) && <ShareMandateInfo className="infoIcon"/>}
                </AppButton>
              {approverAlertStatus && <Box className="collabApproveNoti">
                <div className="arrow-up"></div>
                <p><span style={{fontWeight:"600",marginRight:"5px"}}>{data?.sharingRequests[0]?.collaborator_name} {data?.sharingRequests?.length > 1 ? "and" : ""} {data.sharingRequests.length > 1 ? `${data.sharingRequests.length - 1} other` : ""}</span>  collaborators are added and removed. Approval is pending <span className="viewDetail" onClick={() => {setCollaboratorListData({});setCollaboratorListPopupStatus(true)}}>View Details</span> <CloseIconPop onClick={() => {setApproverAlertStatus(false)}}/></p>
              </Box>}
            </Box>
          </Box>
        </Box>
        <Box className='header-container' style={{marginBottom: "20px"}}>
          <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <ProfileIcon />
              <span style={{ fontSize: '14px', fontWeight: 400 }}>{data.createdByName}</span>
            </div>
            <span style={{ width: '2px', backgroundColor: '#B3B5B7', height: "18px" }}></span>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <CalendarIcon />
              <span style={{ fontSize: '14px', fontWeight: 400 }}>
                {convertToDisplayDateTime(data.createdAt)}
              </span>
            </div>
          </Box>

          {data?.approvalStatus == 2 && (
            <Box className='reject-info'>
              <WarningIcon className='reject-icon' />
              <span className='reject-text'>Rejected</span>
            </Box>
          )}
          {(!isApprovalList && data?.status !== 10) ? (
            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div style={{ fontSize: '14px', color: '#727376', fontWeight: 400 }}>Status</div>
              <div>
                <Button
                  style={{
                    padding: '4px 10px',
                    backgroundColor: tagStyle?.background ? tagStyle?.background : '#EFF6FC',
                    color: tagStyle?.color ? tagStyle?.color : '#0E94F4',
                    textTransform: 'none',
                    fontWeight:400,
                    cursor:"auto"
                  }}
                  // onClick={()=> setUpdateManStatus(true)}
                  // disabled={data?.restrictions ? !(JSON.parse(data?.restrictions).includes(userRole)) : false}
                >
                  {data?.status == 57 ? "Closed: By competitor" : data?.status == 53 ? "Open" : data?.status == 58 ? "Hold: By client" : data?.status == 59 ? "Hold:  By Xpheno" : data?.status == 56 ? "Closed : By client internally" : data?.status == 60 ? "Archive" : data?.status == 54 ? "In progress" : data?.status == 55 ? "Closed" : "-"}
                  {/* <KeyboardArrowDownIcon /> */}
                </Button>
              </div>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <img src={ClientProfile} alt='otherInfo' />
            <div>Client Details</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <ClientDetails /> {/* client details fields */}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <MandatesIcon style={{ fill: '#002882' }} />
            <div>Mandate Details</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <MandateDetails /> {/* mandate details fields */}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <OtherInformation />
            <div>Mandate specs</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <MandateSpecs /> {/* mandate specs fields */}
        </AccordionDetails>
      </Accordion>
      {/* <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <LocationIcon />
            <div>Location</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <LocationDetails /> 
        </AccordionDetails>
      </Accordion> */}
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <MandatesIcon style={{ fill: '#002882' }} />
            <div>Location</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <MandateLocationDetails /> {/* location fields */}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <FactorIcon className='accord-icon' />
            <div>Success Factors</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <SuccessFactors /> {/* success factors fields */}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <ReceiptIcon />
            <div>Attachments</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          <AttachmentFields /> {/* Attachments fields */}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordian-header'>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <FactorIcon className='accord-icon' />
            <div>Logs</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className='accordian-details'>
          {manAuditHistory?.length ? <Logs /> : <></>}
        </AccordionDetails>
      </Accordion>
      {showRejectPopup && (
        <Modal
          open={true}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000', pb: '16px' }}>
              Reason For Rejection
            </Typography>
            <IconButton
              aria-label='close'
              onClick={() => setShowRejectPopup(false)}
              sx={{
                position: 'absolute',
                right: 25,
                top: 27,
              }}
            >
              <CloseIcon style={{cursor:"pointer"}}/>
            </IconButton>
            <TextField
              // label='Reason'
              placeholder='Add Comments...'
              inputProps={{ style: { fontSize: '14px' }, maxLength: 310 }}
              onChange={(e: any) => {
                setRejectiontReason(e.target.value)
              }}
              value={rejectionReason}
              rows={4}
              className='reject-model-textbox'
              multiline
            ></TextField>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: '20px',
                gap: '15px',
              }}
            >
              <AppButton
                bgcolor='#E5E8EB'
                onClick={() => setShowRejectPopup(false)}
                // margin={15}
                textColor='#000'
                fontSize='14px'
                fontWeight={400}
                label='Cancel'
                width='96px'
              ></AppButton>
              <AppButton
                bgcolor='#F34334'
                onClick={handleReject}
                // margin={15}
                textColor='#ffffff'
                fontSize='14px'
                fontWeight={400}
                label='Reject'
                width='96px'
              ></AppButton>
            </Box>
          </Box>
        </Modal>
      )}
      {
        <Modal
          open={showRejectReasonPopup}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000', pb: '16px' }}>
              Update Mandate Status Reason
            </Typography>
            <IconButton
              aria-label='close'
              onClick={() => setShowRejectReasonPopup(false)}
              sx={{
                position: 'absolute',
                right: 25,
                top: 32,
              }}
            >
              <CloseIcon style={{cursor:"pointer"}}/>
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: '',
                // margin: '',
                gap: '15px',
              }}
            >
              <Typography>{rejectReason}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  margin: '20px 0 20px 20px',
                  gap: '15px',
                }}
              >
                <AppButton
                  bgcolor='#E5E8EB'
                  onClick={() => setShowRejectReasonPopup(false)}
                  // margin={15}
                  textColor='#000'
                  fontSize='14px'
                  fontWeight={400}
                  label='Cancel'
                  width='96px'
                ></AppButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      }
      <AppErrorHandlerModal
        show={showSuccessdialog === 1}
        open={showSuccessdialog !== 0}
        content={
          data.mandateCode +
          ` Mandate has been successfully ` +
          (showSuccessdialog === 2 ? 'rejected' : 'approved')
        }
        handleClose={handleDialogClose}
        // factor='Success'
        ButtonText='Done'
      >
        <></>
      </AppErrorHandlerModal>
      <Snackbar
        open={hasError}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
      {updateManStatus && 
        <UpdateMandateStatus
          manData={data}
          onClose={()=> setUpdateManStatus(false)}
          apiCallAfter={()=> {handledetial(data?.id); loadFirstPage('mandate-detail')}}
        />
      }
      {collaboratorPopupStatus && <CollaboratorPopup collaboratorPopupStatus={collaboratorPopupStatus} setCollaboratorPopupStatus={setCollaboratorPopupStatus} setCollaboratorListPopupStatus={setCollaboratorListPopupStatus} mandateId={mId} mandateCode={data.mandateCode} editCollData={editCollData} setCollaboratorListData={setCollaboratorListData} setEditCollData={setEditCollData} mandateData={data}/>}
      {collaboratorListPopupStatus && <CollaboratorListPopup collaboratorListPopupStatus={collaboratorListPopupStatus} setCollaboratorListPopupStatus={setCollaboratorListPopupStatus} mandateId={mId} setEditCollStatus={setEditCollStatus} setEditCollData={setEditCollData} collaboratorListData={collaboratorListData} setCollaboratorPopupStatus={setCollaboratorPopupStatus} mandateCode={data?.mandateCode}/>}
    </Box>
  )
}

export default MandateView