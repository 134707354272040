import './DepartmentDropDown.scss'
import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Checkbox, Popover,Typography ,Accordion,AccordionDetails,AccordionSummary,Tooltip} from "@mui/material"
import * as _ from 'lodash'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DepartmentPopOver = ({
    // depIndex,
    // department, 
    dep,
    index,
    selectedItems,
    setSelectedItems,
    departmentSearch

}: any) => {

    const [anchorEl, setAnchorEl] = useState<any>(null)
    const deptSelect = _.some(selectedItems.value, (item: any) => {
        return item.departmentId === dep.id
    })
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const updateDepListClick = (data: any) => {
        // console.log("list check")

        setSelectedItems((prev: any) => {

            const listSelected = _.some(selectedItems.value, (item: any) => {
                return item.departmentId === data.id
            })
            if (listSelected) {
                _.remove(prev.value, (item: any) => item.departmentId == data.id);

                return { ...prev }
            }
            else {
                const anySelect = _.find(data.departmentRoles, { isAllowAssociate: 1 });

                if(anySelect) {
                    prev.value.push(anySelect)
                } else {
                    data.departmentRoles.forEach((depChild:any) => {
                        if(depChild.children.length > 0){
                            prev.value.push(...depChild.children)
                        } else {
                            prev.value.push(depChild)
                        }
                    })
                }
                return { ...prev }

            }


        })

    }

    const updateDepSelect = (data: any) => {

        setSelectedItems((prev: any) => {
            const itemsWithId = _.filter(prev.value, { parentId: data.id });

            const count = itemsWithId.length;
            if (!data.children.length) {
                _.remove(prev.value, (item: any) => item.departmentId == data.departmentId && item.isAllowAssociate == 1);
                const existingObject = _.find(prev.value, data);
                if (existingObject) {
                    _.remove(prev.value, data);
                    return { ...prev }
                } else {
                    prev.value.push(data)
                    return { ...prev }
                }
            }
            else if (count == data.children.length) {
                _.remove(prev.value, (item: any) => item.parentId == data.id);
                return { ...prev }
            }
            else {
                _.remove(prev.value, (item: any) => item.departmentId == data.departmentId && item.isAllowAssociate == 1);
                _.remove(prev.value, (item: any) => item.parentId == data.id);
                prev.value.push(...data.children)
                return { ...prev }
            }


        })

    }

    const handleAnyClick = (data: any) => {

        setSelectedItems((prev: any) => {
            const existingObject = _.find(prev.value, data);


            if (existingObject) {
                _.remove(prev.value, data);

                return { ...prev }

            }
            else {

                _.remove(prev.value, (item: any) => item.departmentId == data.departmentId);
                prev.value.push(data)
                return { ...prev }
            }


        })


    }


    const updateRemoveSelect = (id: any, subOptions: any) => {
        setSelectedItems((prev: any) => {
            const existingObject = _.find(prev.value, id);

            if (existingObject) {
                _.remove(prev.value, id);

                return { ...prev }

            } else {
                const anySelect = _.find(prev.value, { departmentId: id.departmentId });
                if (anySelect) {
                    _.remove(prev.value, (item: any) => item.departmentId == id.departmentId && item.isAllowAssociate == 1);
                }
                prev.value.push(id);
                return { ...prev }
            }
        })
    }

    // const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget)
    // }
    useEffect(() => {
        const handleScroll = () => {
            if (anchorEl) {
                setAnchorEl(null)
            }
        };


        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);

        };
    }, [anchorEl]);
    return (

        <Accordion 
        // className='dept-accordian'
         key={index} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >

                <div className="list-item"
                >
                    <input
                        type="checkbox"
                        onClick={() => { updateDepListClick(dep) }}

                        checked={
                            _.some(selectedItems.value, (item: any) => {
                                return item.departmentId === dep.id
                            })}
                    />
                    <Tooltip title={dep?.depName} placement="top-start">
                    <span className='ellipsis-drop-cls-list'>{dep?.depName}</span>
                    </Tooltip>
                    </div>
            </AccordionSummary>
            <AccordionDetails>

                {dep.departmentRoles &&
                    <>
                        {dep.departmentRoles.map((department: any, depIndex: number) => {
                            // const isDepSearchChildExist = departmentSearch == "" ? true : department.children.find((depSrc:any) => depSrc.roleName?.toLowerCase().includes(departmentSearch?.toLowerCase()))
                            // department.roleName?.toLowerCase().includes(departmentSearch?.toLowerCase()) || isDepSearchChildExist ? 
                            return (
                                <>
                                    {<div className="multilevel-dropdown multi-accordian" key={depIndex}>
                                        <Typography onClick={department.isAllowAssociate ? () => { handleAnyClick(department) } : () => { updateDepSelect(department) }} className="list active" >
                                                <input
                                                    type="checkbox"
                                                    checked={_.some(selectedItems.value, (item: any) => {
                                                        if (department.isAllowAssociate) {
                                                            return item.departmentId === department.departmentId && item.roleName === department.roleName;
                                                        }
                                                        else {
                                                            return item.parentId === department.id || item.roleName === department.roleName
                                                        }
                                                    })}
                                                />
                                                <Tooltip title={department?.roleName} placement="top-start">
                                               <span className='ellipsis-drop-cls-list'> {department?.roleName} </span>
                                               </Tooltip>
                                        </Typography>

                                        <div className="sub-list">
    
                                            {department.children.map((roles: any, subIndex: number) => {
                                                // roles.roleName?.toLowerCase().includes(departmentSearch?.toLowerCase()) && // previous code commented
                                                return <>
                                                    { <div key={subIndex} className={`list active`} onClick={() => updateRemoveSelect(roles, department.roleNames)}>
                                                        <input
                                                            type="checkbox"
                                                            checked={_.some(selectedItems.value, (item: any) => {
                                                                return item.departmentId === roles.departmentId && item.roleName === roles.roleName && item.parentId === roles.parentId  ;
                                                            })}
                                                        />
                                                <Tooltip title={roles.roleName} placement="top-start">
                                                       <span className='ellipsis-drop-cls-list'>{roles.roleName}</span>
                                                       </Tooltip>
                                                       </div>}
                                                </>
                                            })}
                                        </div>
                                    </div>}
                                </>

                            )
                        })
                        }
                    </>


                }
            </AccordionDetails>
        </Accordion>

    )
}

export default DepartmentPopOver