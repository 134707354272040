import { Box, Typography, Snackbar, Alert, Chip, Divider, Slider,Switch, IconButton, Menu, MenuItem } from '@mui/material'
import React,{ useState, useEffect } from 'react'
import { ReactComponent as Ellipse } from '../../../assets/icons/Ellipse.svg'
import { AppButton } from '../../AppComponents/AppButton'
import './ViewQueryBuilder.scss'
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal'
import { initiateRPA } from '../../../services/MandateService'
import { Label } from '@mui/icons-material'
import AntSwitch from '../../AppComponents/RadioButton/CustomRadioButton';
import { split } from 'lodash'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as CloneIcon } from '../../../assets/icons/clone.svg'
import { ReactComponent as RpaResultsIcon } from '../../../assets/icons/clippad.svg'
import { ReactComponent as InternalResultsIcon } from '../../../assets/icons/clipboard.svg'

import { useNavigate } from 'react-router-dom'

export const ViewQueryBuilder = ({handleEdit,handleClone, data, hideRPAbtn, getSearchQueries }: any) => {
  const [hasError, setHasError] = useState(false)
  const [uploadsuccess, setUploadsuccess] = useState(false)
  const isEdit = ( data?.status?.statusName.toLowerCase() !== "inprogress" && data?.status?.statusName.toLowerCase() !== "queued"&& data?.status?.statusName !== "completed")

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setHasError(false)
  }
  const statusIcon = (key: any) => {
    switch (key) {
      case 'open':
        return { statusName: 'Open', iconColor: '#835AA8', Icon: Ellipse, className: 'open-btn' }
      case 'inProgress':
        return {
          statusName: 'In progress',
          iconColor: '#0E94F4',
          Icon: Ellipse,
          className: 'inprogress-btn',
        }
      case 'inprogress':
        return {
          statusName: 'In progress',
          iconColor: '#0E94F4',
          Icon: Ellipse,
          className: 'inprogress-btn',
        }
      case 'failed':
        return {
          statusName: 'Failed',
          iconColor: '#F34334',
          Icon: Ellipse,
          className: 'failed-btn',
        }
      case 'completed':
        return {
          statusName: 'Completed',
          iconColor: '#2BAD63',
          Icon: Ellipse,
          className: 'completed-btn',
        }
      case 'queued':
        return {
          statusName: 'In Queue',
          iconColor: '#f4a236',
          Icon: Ellipse,
          className: 'queued-btn',
        }
      default:
        return { statusName: 'Open', iconColor: '#835AA8', Icon: Ellipse, className: 'open-btn' }
    }
  }
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const handledrawerdata = (id: number, draft: boolean) => {
    const obj = { isDraft: draft }
    // setdrawerid(id)
    // handledetial(id)
    // dispatch(draweropen(obj))
    navigate(`/mandates?id=${id}`)
  }
  const [openModel, setOpenModel] = useState(false)
  const options = [
    { id: 1, label: 'Internal Results', icon: <InternalResultsIcon /> },
    { id: 2, label: 'RPA Results', icon: <RpaResultsIcon /> },
    { id: 3, label: 'Edit', icon: <EditIcon /> },
    { id: 4, label: 'Clone', icon: <CloneIcon /> },
    
  ]
  function LongMenu(id:any) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: any) => {
      // disableScroll()
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }
    const handleClose = (event: any) => {
      event.stopPropagation()
      // enableScroll()
      setAnchorEl(null)
    }
    const handleClosePopup = (event: any, option: any) => {
      event.stopPropagation()
      // enableScroll()
      setAnchorEl(null)
      setOpenModel(option.id === 1 ? true : false)
      if(option.id === 4){
        handleClone(data.id)
      }
      if(option.id === 3){
        handleEdit(data.id)
      }
     
    }
    const ITEM_HEIGHT=150
    return (
      <div>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => handleClose(e)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '13ch',
              overflow: 'hidden',
              borderRadius: '10px',
            },
          }}
        >
          {options.map((option) => {
            let disable = false
            if([1,2].includes(option.id)){
              disable = true
            }
            if(option?.id===3){
              disable = !isEdit
            }
            return (
              <MenuItem
                className='menuuuu-item'
                key={option.id}
                disabled={disable}
                selected={option==null}
                onClick={(e) => handleClosePopup(e, option)}
                
              ><Box sx={{display:'flex',gap:"10px", flexDirection:'row',justifyContent:'center', alignItems:'center'}}>{option.icon} <span style={{fontSize:'12px'}}>{option.label}</span></Box>
              </MenuItem>
            )})}
        </Menu>
      </div>
    )
  }
  const getInitiateRpa = async (id: any) => {
    if (hideRPAbtn) {
      setHasError(true)
    } else {
      const res = await initiateRPA(id)
      if (res?.status === 200) {
        if (res?.data?.result?.message === 'Search query status updated successfully') {
          setUploadsuccess(true)
        }
      }
    }
  }

  const Card = () => {
    const { statusName, iconColor, Icon, className }: any = statusIcon(data?.status?.statusName == "inprogress" ? "queued" : data?.status?.statusName == "queued" ? "inprogress" : data?.status?.statusName)
    const isEdit =
      data?.status?.statusName.toLowerCase() !== 'inprogress' &&
      data?.status?.statusName.toLowerCase() !== 'queued' &&
      data?.status?.statusName !== 'completed'
    return (
      <div className={`list-detail-wrapper`}>
        <Box className='list-query-headbtn'>
          <Box className='title-wrap'>
            <Typography className='title'>{data?.queryName}</Typography>
          </Box>
        
        <LongMenu/>
        </Box>
       
        <Typography className='description'>{data?.queryDesc}</Typography>
        <Box className='list-status-btn'>
          <AppButton
            label={statusName}
            bordercolor={iconColor}
            bgcolor='white'
            size='large'
            type='button'
            className={`status-btn ${className}`}
            sx={{ color: iconColor }}
            // onClick={}
          >
            <Icon />
          </AppButton>
          <AppButton
            label='Initiate L1 RPA'
            bordercolor='black'
            bgcolor='white'
            size='large'
            type='button'
            className={`RPA-btn ${!isEdit ? 'btn-hidden' : ''}`}
            sx={{ color: '#002882' }}
            onClick={() => getInitiateRpa(data?.id)}
          ></AppButton>
        </Box>
        <AppErrorHandlerModal
          open={uploadsuccess}
          content={'L1 RPA  has been successfully initiated'}
          handleClose={() => {
            setUploadsuccess(false)
            getSearchQueries()
          }}
          factor='Success'
          ButtonText='Done'
        >
          <></>
        </AppErrorHandlerModal>
        <Snackbar
          open={hasError}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert
            onClose={handleAlertClose}
            variant='filled'
            severity='error'
            sx={{ width: '100%' }}
          >
            {'Another search query rpa is in progress'}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  return (
    <Box className='view-wrap'>
      <Card />
      {/* {data?.status?.statusName.toLowerCase() === 'inprogress' && (
        <Box className='initatedIndicator'>
          <Typography className='notice-text'>
            Your Search query added to the RPA queue : 5/30 and the time elapsed to complete{' '}
            <span className='remaining-time'>{'00:23:00'}</span>
          </Typography>
        </Box>
      )} */}
      <Box className='keyword-section'>
        <Typography className='section-heading'>Keywords</Typography>
        <Box>
          {data?.keywords ? (
            data?.keywords
              ?.split(',')
              ?.map((value: any, index: any) => (
                <Chip
                  sx={{ margin: '5px 5px 0 0', borderRadius: '5px' }}
                  key={index}
                  label={value}
                />
              ))
          ) : (
            <span>&nbsp; -</span>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: '5px' }}>
        <Typography className='section-heading'>Exclude Keywords</Typography>
        <Box>
          {data?.excludekeys ? (
            data?.excludekeys
              ?.split(',')
              ?.map((value: any, index: any) => (
                <Chip
                  sx={{ margin: '5px 5px 0 0', borderRadius: '5px' }}
                  key={index}
                  label={value}
                />
              ))
          ) : (
            <span>&nbsp; -</span>
          )}
        </Box>
        <Box className='keyword-boolen'>
          <AntSwitch checked={data?.keywordsToggle} inputProps={{ 'aria-label': 'ant design' }} />
          <Typography className='section-heading' sx={{ marginLeft: '5px' }}>
            {'Boolen'}
          </Typography>
        </Box>
      </Box>
      <Box className='location-wrap'>
        <Box sx={{ marginTop: '5px' }}>
          <Typography className='section-heading'>Current Location </Typography>
          <Typography className='subtext'>{data?.currentLocation || '-'} </Typography>
        </Box>
        <Box sx={{ marginTop: '5px', width: '150px' }}>
          <Typography className='section-heading'>Preffered Location</Typography>
          <Typography className='subtext'>{data?.preferredLocations || '-'} </Typography>
        </Box>
      </Box>
      <Box className='exp-wrap'>
        <Box sx={{ marginTop: '5px' }}>
          <Typography className='section-heading'>Experience </Typography>
          <Typography className='subtext'>{`${data?.minExp} - ${data?.maxExp} Years`} </Typography>
        </Box>
        <Box sx={{ marginTop: '5px', width: '150px' }}>
          <Typography className='section-heading'>Annual Salary</Typography>
          <Typography className='subtext'>
            {`${data?.minSalary} L - ${data?.maxSalary} L /Annual`}{' '}
          </Typography>
          <Chip
            sx={{
              margin: '5px 5px 0 0',
              borderRadius: '5px',
              backgroundColor: '#F0F6FB',
              color: '#002882',
            }}
            label={data?.currency}
          />
        </Box>
      </Box>
      <Box className='employDetails-wrap'>
        <Divider textAlign='left' className='blue-heading'>
          Employment Details
        </Divider>
        <Box sx={{ marginTop: '5px' }}>
          <Typography className='section-heading'>Designation</Typography>
          <Typography className='subtext'>{data?.designation || '-'} </Typography>
          <Box
            className='current-company'
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <Chip
              sx={{
                margin: '5px 5px 0 0',
                borderRadius: '5px',
                backgroundColor: '#F0F6FB',
                color: '#002882',
              }}
              label='Current Company'
            />
            <AntSwitch
              checked={data?.designationToggle}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography className='section-heading' sx={{ marginLeft: '5px' }}>
              {'Boolen'}{' '}
            </Typography>
          </Box>
          <Typography className='section-heading'>Notice Period</Typography>
          <Typography className='subtext'>{data?.noticePeriod || '-'} </Typography>
        </Box>
      </Box>
      <Box className='employDetails-wrap'>
        <Divider textAlign='left' className='blue-heading'>
          Additional Details
        </Divider>
        <Box className='location-wrap'>
          <Box sx={{ marginTop: '5px' }}>
            <Typography className='section-heading'>Active in </Typography>
            <Typography className='subtext'>{data?.activeIn || '-'} </Typography>
          </Box>
          <Box sx={{ marginTop: '5px', width: '150px' }}>
            <Typography className='section-heading'>Maximum Count allowed</Typography>
            <Typography className='subtext'>{data?.maxCount || '-'} </Typography>
          </Box>
        </Box>
      </Box>
      <Box className='employDetails-wrap'>
        <Divider textAlign='left' className='blue-heading'>
          Diversity Details
        </Divider>
        <Box sx={{ marginTop: '5px' }}>
          <Typography className='section-heading'>Gender </Typography>
          {data?.gender?.split(',')?.map((tag: string, index: number) => (
            <Chip key={index} label={tag} style={{ color: '#000000' }} variant='outlined' />
          ))}
        </Box>
        <Box className='keyword-boolen'>
          <Chip
            sx={{
              margin: '5px 5px 0 0',
              borderRadius: '5px',
              backgroundColor: '#F0F6FB',
              color: '#002882',
            }}
            label='Person with Disabilities'
          />
          <AntSwitch
            checked={data?.personDisabilities}
            inputProps={{ 'aria-label': 'ant design' }}
          />
          <Typography className='section-heading' sx={{ marginLeft: '5px' }}>
            {'Boolen'}
          </Typography>
        </Box>
      </Box>
      <Box className='employDetails-wrap'>
        <Divider textAlign='left' className='blue-heading'>
          Display Details
        </Divider>
        <Box sx={{ marginTop: '5px' }}>
          <Typography className='section-heading'>show </Typography>
          {data?.showCandidates?.split(',')?.map((tag: string, index: number) => (
            <Chip
              key={index}
              label={tag}
              style={{ color: '#000000', margin: '5px 5px 0 0' }}
              variant='outlined'
            />
          ))|| '-'}
        </Box>
        <Box sx={{ marginTop: '5px' }}>
          <Typography className='section-heading'>Show only candidates with </Typography>
          {data?.showOnlyCandidates && data?.showOnlyCandidates?.split(',')?.map((tag: string, index: number) => (
            <Chip
              key={index}
              label={tag}
              style={{ color: '#000000', margin: '5px 5px 0 0' }}
              variant='outlined'
            />
          ))|| '-'}
        </Box>
      </Box>
    </Box>
  )
}
