import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { themeOptions } from './theme'
// import projectRoute
import { ProtectRoute } from './ProtectRoute/ProtectedRoute.'
import './App.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppLayout from './hoc/Applayout'
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { PrivateRoutes } from "./routing/privateRoutes";
import { PublicRoutes } from "./routing/publicRoutes";
import APIProgressLoader from './components/APIProgressLoader/APIProgressLoader'
import AppNotificationAlert from './components/AppComponents/AppNotificationAlert/AppNotificationAlert'
import AppConfettiEffect from './components/AppComponents/AppConfettiEffect/AppConfettiEffect'
import { resetNotificationAll } from './features/notification/notificationReducer'
import { hideMonthlyCard } from './features/systemConfig/SystemConfig'

const theme = createTheme(themeOptions)
function App() {
  const authUser = useAppSelector((state) => state?.auth.token)
  const isCallPop = useAppSelector((state) => state?.smartflow.isCallPop)
  const dispatch = useAppDispatch()
  // routes map based on authorization 
  const routes = !authUser ? <PublicRoutes /> : <PrivateRoutes />
  dispatch(resetNotificationAll())
  dispatch(hideMonthlyCard())
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <APIProgressLoader/>
      <AppNotificationAlert />
      <AppConfettiEffect />
      <Router>
        <AppLayout>{routes}</AppLayout>
      </Router>
    </ThemeProvider>
  )
}

export default App
