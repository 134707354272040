/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from 'react';
import './styles.scss';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import businessSuitcase from "../../../../assets/icons/suitcase.svg"
import businessSuitcaseBlack from "../../../../assets/icons/businesssuitcase.svg"
import certifiedLogo from "../../../../assets/icons/certifiedlogo.svg"
import linkPath from "../../../../assets/icons/linkpath.svg"
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'
import { Box } from '@mui/material';

type Tprop = {
    candidateDetailsData: any;
}


const LinkedInProfile: React.FC<Tprop> = ({ candidateDetailsData }) => {

    const renderEmptySearchBox = () => {
        const emptyMessage = `No data found`;
        return (
            <Box className="candidate-empty-search-box candidateDetailWraper">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    {emptyMessage}
                </Box>
            </Box>
        )
    }

    const [profileData, setProfileData] = useState<any>()


    const formatedExperiences = useMemo(() => {
        if(profileData != undefined){
            const cloned = profileData?.experiences ? JSON.parse(JSON.stringify(profileData?.experiences)) : []

            const filtered = cloned?.findIndex((element: any) => {
                return element?.workingYears?.includes("Present") || false
            })
            const temp = cloned[filtered]
            cloned?.splice(filtered, 1)
            cloned?.unshift(temp)
            return cloned
        }        
    }, [profileData]);

    useEffect(() => {
        if (candidateDetailsData?.linkedinData) setProfileData(candidateDetailsData?.linkedinData)
    }, [candidateDetailsData])
    
    if (!profileData) {
        return renderEmptySearchBox();
    }

    return (
        <div className='linkedin-profie-wrap'>
            {candidateDetailsData?.linkedinData &&
                <>
                    {/* <div>
                <span className="head">Saved mandates</span>
                <div className='card-boxes-wrap'>
                    {mandates.length && mandates.map((mandate: any, index: number) => {
                        return (
                            <div key={index} className='card-box'>
                                <div className="upper">
                                    <img src={customersupport} />
                                    <div className="content">
                                        Customer Support L1
                                        <span >XPH13553</span>
                                    </div>
                                </div>
                                <div className="lower">
                                    <span> Saved on:</span> Fri, Oct 26,2022, 8:00 PM
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}
                    <div className='personal-details'>
                        <span className='candidate-name'>{profileData?.name ? profileData?.name : '-'}</span>
                        <div className='detail-wrap'>
                            <div className='each-details'><PlaceOutlinedIcon /><span>{profileData?.location ? profileData?.location : '-'}</span></div>
                            <div className='each-details'><BusinessCenterOutlinedIcon /><span>SEO Analyst</span></div>
                        </div>
                    </div>
                    <div className='basic-info'>
                        <span className="head">Basic info</span>
                        <div className='details'>
                            <div className='details-every'>
                                <span className='label'>Email</span>
                                <span className='value'>{profileData?.contactEmail ? profileData?.contactEmail : '-'}</span>
                            </div>
                            <div className='details-every'>
                                <span className='label'>Contact</span>
                                <span className='value'>{(profileData?.contactPhone && profileData?.contactPhone !== "-") ? `+91 ${profileData?.contactPhone}` : '-'}</span>
                            </div>
                            <div className='details-every'>
                                <span className='label'>Linkedin URL</span>
                                <span className='value urls'>{profileData?.profileLink ?? '-'}</span>
                            </div>
                            <div className='details-every'>
                                <span className='label'>Birthday</span>
                                <span className='value'>{candidateDetailsData?.dateOfBirth ? candidateDetailsData?.dateOfBirth : '-'}</span>
                            </div>
                            <div className='details-every'>
                                <span className='label'>Website URL</span>
                                <span className='value urls'>{profileData?.profileLink ?? '-'}</span>

                            </div>
                        </div>
                    </div>
                    <div className='description'>
                        <span className="head">About</span>
                        <p className="content">{profileData?.about}</p>
                    </div>
                    <div className='experience'>
                        <span className="head">Experience</span>
                        {formatedExperiences?.map((experinces: any, index: number) => {
                            return (
                                <div key={index} className='each-experience-card'>
                                    <div className={`svgcontainer${index === 0 ? "-active" : ""}`}>
                                        <img src={index === 0 ? businessSuitcase : businessSuitcaseBlack} />
                                    </div>
                                    <div className={`experience-content${index === formatedExperiences?.length - 1 ? "-last" : ""}`}>
                                        <div className='exp-title'>
                                            <span className='head'> {`${experinces?.position} At ${experinces?.companyName}`}</span>
                                            <span className='date'> {`${experinces?.workingYears?.split('-')?.[0]}`} {experinces?.workingDuration ? <span>( {experinces?.workingDuration} )</span> : ''}</span>
                                        </div>
                                        <div className='description'>Providing technical assistance to the people from world-wide by giving technical support and guidance to the printer related issues they are facing.</div>
                                        {/* {index === 0 && <>
                                    <div className='rolesandresp'>
                                        <span className='head'>Roles & Responsibilities</span>
                                        <ul>
                                            <li>Keyword and Competitor Analysis.</li>
                                            <li>ON-Page and OFF-Page SEO optimization.</li>
                                        </ul>
                                    </div>
                                    <div className="on-page">
                                        <span className="head">ON-Page</span>
                                        <ul>
                                            <li>Develop efficient SEO strategies to improve organic traffic.</li>
                                            <li>Worked with google webmaster and google analytics.</li>
                                            <li>Develop link-building tactics to improve ranking of keywords in Google Organic Search</li>
                                            <li>ON-Page and OFF-Page SEO optimization.</li>
                                        </ul>
                                    </div>
                                    <div className="off-page">
                                        <span className="head">OFF-Page</span>
                                        <ul>
                                            <li>Keyword and Competitor Analysis.</li>
                                            <li>ON-Page and OFF-Page SEO optimization.</li>
                                        </ul>
                                    </div>
                                </>} */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='education'>
                        <span className="head">Educations</span>
                        {profileData?.education?.map((education: any, index: number) => {
                            return (
                                <div className='academics' key={index}>
                                    <p className='degree'>{`${education?.degree}`}</p>
                                    <p className='clg'>{education?.institute}</p>
                                    {education?.years && <p className='year'>{`Year of passing - ${education?.years}`}</p>}
                                </div>
                            )
                        })
                        }

                    </div>
                    <div className='certifications'>
                        <span className="head">Certifications</span>
                        {
                           candidateDetailsData?.certification?.map((cerficate:{certification_vendor:string,certification_name:string},index:number)=>{
                            return(
                                <div className="certificate-container" key={index}>
                            <div className="certification-box">
                                <img src={certifiedLogo} />
                            </div>
                            <div className='certification-details'>
                                <span className='course'>{cerficate?.certification_name}<img src={linkPath} /><a href=''>view credentials</a></span>
                                <span className='date'>Dec 3, 2022</span>
                            </div>
                        </div>
                            )
                           })
                        }
                        
                    </div>
                    <div className='skills'>
                        <span className="head">Skills</span>
                        <div className='skill-wrap'>
                            {profileData?.skills?.map((skill: { name: string, endorsements: number }, index: number) => <p key={index} className='content'>{skill?.name}{profileData?.skills.length !== index + 1 ? <span>, </span> : ''} </p>)}
                        </div>
                    </div>
                    {candidateDetailsData?.knownLanguages?.length > 0 &&
                        <div className='skills'>
                            <span className="head">Language</span>
                            <div className='skill-wrap'>
                                {candidateDetailsData?.knownLanguages?.map((language: { language: string, proficiency: string }, index: number) => <p key={index} className='content'>{language?.language}{candidateDetailsData?.knownLanguages?.length !== index + 1 ? <span>, </span> : ''} </p>)}
                            </div>
                        </div>
                    }
                </>
            }

        </div>
    )
}

export default LinkedInProfile;
