import { Slider, Select, MenuItem, Box, InputLabel, FormControl, Autocomplete, TextField } from '@mui/material';
import _ from 'lodash'

type Props = {
  rangeValue : any;              // range value for slider
  setRangeValue : (val:any) => void;  // setRangevalue function
  minValue : number;                  // min value for slider
  maxValue : number;                  // max value for slider
  rangeStep : number;                 // slider range step increament
  markStep : number;                  // slider mark value increament
  onChangeHandler : (event:any, val:any) => void;  // On change function
  labelText ?: string;
  className ?: string;                 // label text for tooltip
  dontShowLabel ? :boolean
  disabled?:boolean
}

const RangeSlider : React.FC<Props> = ({
  rangeValue,
  setRangeValue,
  minValue,
  maxValue,
  rangeStep,
  markStep,
  onChangeHandler,
  labelText,
  className,
  dontShowLabel,
  disabled,
  ...rest
}) => {
  // slider marks value configuration
  const marks = _.range(minValue, maxValue+1, markStep).map((ele:any,index:number)=>{
    return {value: index == 0 ? 1 : ele, label: index == 0 ? 1 : ele}
  })
  // slider tooltip text configuration
  function rangeValueText(value: number) {
    if (!dontShowLabel) {
      if (value === 0 || value === 1) {
        return `${value} ${labelText}`
      } else {
        return `${value} ${labelText}s`
      }
    }else{
      return `${value}`
    }
  }

  // select dropdown list configuration
  const renderMenu = _.range(minValue+1, maxValue+rangeStep, rangeStep).map((item:any,index:number)=>{
    const label = dontShowLabel?`${labelText}` : (item !== 0 && item !== 0.5 && item !== 1) ? `${labelText}${labelText && 's'}` : labelText;
    return {value: item, label: item}
  })
  return (
    <div className={className}>
      <Slider 
        marks={marks}
        getAriaLabel={() => 'Temperature range'}
        value={rangeValue}
        min={minValue}
        max={maxValue}
        step={rangeStep}
        disabled={disabled? true:false}
        onChange={onChangeHandler}
        valueLabelDisplay="auto"
        getAriaValueText={rangeValueText}
        valueLabelFormat={rangeValueText}
        {...rest}
      />
      <div className='select-container' style={{display:"flex", gap:"10px", marginTop:"1rem"}}>
        <FormControl fullWidth>
          <Autocomplete
            value={rangeValue[0] == 0 ? 1 : rangeValue[0]}
            defaultValue={renderMenu[0]}
            disablePortal
            freeSolo
            id="combo-box-demo"
            options={renderMenu}
            onChange={(event: any, newValue: any) => {
              const clone = [...rangeValue]
              clone[0] = newValue != null ? newValue?.value : null;
              // if (clone[0] > rangeValue[1]) {
              //   clone[1] = clone[0];
              // }
              setTimeout(() => {
                setRangeValue(clone)
              },200)              
            }}
            onKeyPress={(e:any) => {
              const regexp = /^[0-9]*(\.[0-9]{0,1})?$/;
              const targetValue = e.target.value;
              const currentValue = e.target.value.toString() + e.key
              if(!regexp.test(targetValue) || parseInt(currentValue) > maxValue){
                e.preventDefault();
              }
            }}
            onKeyUp={(e:any) => {
              const valueEnter = e.target.value
              const clone = [...rangeValue]  
              if(valueEnter % 1 != 0){
                clone[0] = valueEnter != null ? valueEnter : maxValue;
              }else{
                clone[0] = ((rangeValue[1] != null && rangeValue[1] != "") && (valueEnter != "" && valueEnter != null)) ? parseInt(valueEnter) : (rangeValue[1] == null && valueEnter != null && valueEnter != "")  ? parseInt(valueEnter) : (valueEnter == "") ? null : null
              }
              // if (((rangeValue[1] != null && rangeValue[1] != "") && (valueEnter != "" && valueEnter != null)) && clone[0] > rangeValue[1]) {
              //   clone[1] = clone[0];
              // }else if((valueEnter != "" && valueEnter != null) && rangeValue[1] == null){
              //   clone[1] = clone[0]
              // } 
              setRangeValue(clone)
              }}
            renderInput={(params) => <TextField {...params} label="Start Value" />}
          />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            value={rangeValue[1]}
            disablePortal
            freeSolo
            id="combo-box-demo"
            options={renderMenu}
            onChange={(event: any, newValue: any) => {
              const clone = [...rangeValue]
              clone[1] = newValue != null ? newValue?.value : null;
              // if (clone[1] != null && clone[1] < rangeValue[0]) {
              //   clone[0] = clone[1];
              // }        
              setRangeValue(clone)
            }}
            onKeyPress={(e:any) => {
              const regexp = /^[0-9]*(\.[0-9]{0,1})?$/;
              const targetValue = e.target.value;
              const currentValue = e.target.value.toString() + e.key
              if(!regexp.test(targetValue) || parseInt(currentValue) > maxValue){
                e.preventDefault();
              }
              if((parseInt(currentValue) == maxValue && e.key == ".")){
                e.preventDefault();
              }
              if(targetValue.indexOf(".") > 0 && e.key == "."){
                e.preventDefault();
              }
            }}
            onKeyUp={(e:any) => {
              const valueEnter = e.target.value
              const clone = [...rangeValue]  
              if(valueEnter % 1 != 0){
                clone[1] = valueEnter != null ? valueEnter : maxValue;          
              }else{
                clone[1] = ((rangeValue[0] != null && rangeValue[0] != "") && (valueEnter != "" && valueEnter != null)) ? parseInt(valueEnter) : null              
              }
              // if (clone[1] != null && clone[1] < rangeValue[0]) {
              //     clone[0] = clone[1];
              //   } 
              setRangeValue(clone)
              }}
            renderInput={(params) => <TextField {...params}  label="End Value"  />}
          />
        </FormControl>
      </div>
    </div>
  )
}

export default RangeSlider;