import { useEffect, RefObject, useCallback, useMemo } from 'react';

type EventListener = (event: MouseEvent) => void;

export default function useOutsideAlerter(ref: RefObject<HTMLElement>, callback: EventListener) {
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback(event);
    }
  }, [ref, callback]);

  const eventListener = useMemo(() => handleClickOutside, [handleClickOutside]);

  useEffect(() => {
    document.addEventListener("mousedown", eventListener);

    return () => {
      document.removeEventListener("mousedown", eventListener);
    };
  }, [eventListener]);
}
