import { FC } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'

// 👇 Styled Material UI TextField Component
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#5e5b5d',
  },
  '& .MuiInputBase-input': {
    borderColor: '#CFD1D2',
  },
  '& .MuiInput-underline:after': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d32f2f',
      },
    },
    '& fieldset': {
      borderColor: '#CFD1D2',
      border: '1px solid #CFD1D2',
      borderRadius: '8px',
      height: '60px',
    },
    '&:hover fieldset': {
      border: '1px solid #CFD1D2',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #CFD1D2',
    },
  },
})

// 👇 Type of Props the FormInput will receive
type FormInputProps = {
  name: string
} & TextFieldProps

export const FormInput: FC<FormInputProps> = ({ name, ...otherProps }) => {
  // 👇 Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=''
      render={({ field }) => (
        <CssTextField
          {...field}
          {...otherProps}
          variant='outlined'
          sx={{ mb: '1.5rem' }}
          error={!!errors[name]}
          helperText={errors[name] ? (errors[name]?.message as unknown as string) : ''}
        />
      )}
    />
  )
}
