import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../../../../Breadcrumb';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../../../assets/icons/no-result-found.svg';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { getAllRoleList } from '../../../../../services/UserService';
import { useAppSelector } from '../../../../../store/hooks';
import { HiringDemandListServiceApi } from '../../../../../services/WidgetListservice/HiringDemandListService';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import { ReactComponent as DefaultClientLogo } from '../../../../../assets/images/defaultUserIcon.svg'
import { ReactComponent as ToolfilterIcon } from '../../../../../assets/icons/ToolfilterIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/lightcloseIcon.svg'
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardFilter from '../../../CustomeDasboardFilter/DashboardFilter';
import HiringDemandFilterApp from '../ViewDetails/HiringDemandFilter/index';
import '../ViewDetails/HiringDemand.scss'
import CommentFilter from '../../CommenFilter/CommentFilter';
import { IMaskInput } from 'react-imask';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
  }


const ScrollableBox = styled(Box)({
  height: '300px',  // Set the desired height
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0px',  // Hide scrollbar for WebKit browsers
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  scrollbarWidth: 'none',  // Hide scrollbar for Firefox
  msOverflowStyle: 'none',  // Hide scrollbar for IE 10+
});

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
	function TextMaskCustom(props, ref) {
	  const { onChange, ...other } = props;
	  return (
		<IMaskInput
		  {...other}
		  mask="0000-00-00"
		  definitions={{
			'#': /[1-9]/,
		  }}
		  inputRef={ref}
		  onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
		  overwrite
		/>
	  );
	},
  );
  


const HiringDemandComponentdetails = () => {

    
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    console.log("view Hiring : ",id,location)
    // const item = data.find(item => item.id === id);
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [exportdatareqest,setexportdatareqest] = useState<any>({})
    const [candidates, setCandidates] = useState<any[]>([]);
    const [candidateData, setCandidateData] = useState<any[]>([]);

    // const [sortColumn, setSortColumn] = useState<string>('candidateName');
    // const [sortDirection, setSortDirection] = useState<string>('');
    // const [columnsort,setcolumnsort] = useState(false)
    const [sortColumn, setSortColumn] = useState<string>('candidateName');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [columnsort, setcolumnsort] = useState<string>('asc');

    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const [paginationCountSize, setPaginationCountSize] = useState({
        inputPageSize: 10,
        pageSize: 1,
        inputPageNo: 1,
        totalRecords: 0,
      });

    const [textFieldValues, setTextFieldValues] = useState<{ [key: string]: any }>({});

    const handleTextFieldChange = (fieldKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValues(prevState => {
          const newState = { ...prevState, [fieldKey]: event.target.value };
          console.log("Updated textFieldValues: ", newState);
          return newState;
        });
      };

      const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
      

      console.log("DatasSinek",reportSubmissionAPIData)
      const [applyBtnActive, setApplyBtnActive] = useState("")
      


    const hiringListApi = async () => {
      const reqObj:any = {
        // "mandateID": "",
        // "mlocation_id": "",
        "coe": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.coe : HirningDemandParamsDetails?.state?.filterList?.coe,
        "deliverymanager": Object.keys(reportSubmissionAPIData).length > 0 ? "deliveryManagerUserID" in reportSubmissionAPIData ? reportSubmissionAPIData?.deliveryManagerUserID : "" : HirningDemandParamsDetails?.state?.filterList?.deliveryManagerUserID || "",
        "candidateName": textFieldValues.candidateName || "",
        "mandateName": textFieldValues.mandateName || "",
        "teamLead": Object.keys(reportSubmissionAPIData).length > 0 ? "teamLeadUserID" in reportSubmissionAPIData ? reportSubmissionAPIData?.teamLeadUserID : "" : HirningDemandParamsDetails?.state?.filterList?.teamLeadUserID || "",
        "clientName": textFieldValues.clientName || "",
        "createdAt": "",
        "createdBy": "",
        "currentCTC": textFieldValues.currentCTC || "",
        "currentLocation": textFieldValues.currentLocation || "",
        "currentOrganization": textFieldValues.currentOrganization || "",
        "cvSentDate": textFieldValues.cvSentDate || "",
        "email": textFieldValues.email || "",
        "expectedCTC": textFieldValues.expectedCTC || "",
        // "jobTitle":  Object.keys(reportSubmissionAPIData).length > 0 ? (textFieldValues.jobTitle ? [textFieldValues.jobTitle] : (reportSubmissionAPIData?.jobTitle != undefined ? reportSubmissionAPIData?.jobTitle : [""])) : (textFieldValues.jobTitle ? [textFieldValues.jobTitle] : HirningDemandParamsDetails?.state?.filterList?.jobTitle) || [],
        "jobTitle":  Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobTitle : HirningDemandParamsDetails?.state?.filterList?.jobTitle,
        "jobTitleSearch":textFieldValues.jobTitle || "",
        "noticePeriod": textFieldValues.noticePeriod || "",
        "phoneNumber": textFieldValues.phoneNumber || "",
        "preferredLocation": textFieldValues.preferredLocation || "",
        "profileEntryDate": textFieldValues.profileEntryDate || "",
        "profileLastActiveDate": "",
        "profileLastModifiedDate": textFieldValues.profileLastModifiedDate || "",
        "recruiterName": textFieldValues.recruiterName || "",
        "relevantExperience": textFieldValues.relevantExperience || "",
        "sortColumn": sortColumn,
        "sortDirection": sortDirection,
        "source": textFieldValues.source || "",
        "stage": textFieldValues.stage || "",
        "status": textFieldValues.status || "",
        "Recruiter": Object.keys(reportSubmissionAPIData).length > 0 ? "RecruiterUserID" in reportSubmissionAPIData ? reportSubmissionAPIData?.RecruiterUserID : "": HirningDemandParamsDetails?.state?.filterList?.RecruiterUserID || "",
        "candidateProcessingStage": textFieldValues.candidateProcessingStage || "",
        "totalExperience": textFieldValues.totalExperience || "",
        "userId": userId,
        // "pageSize": paginationCountSize.pageSize,
        // "pageNo": paginationCountSize.currentPage,
        "pageSize": paginationCountSize.inputPageSize,
        "pageNo": paginationCountSize.inputPageNo,
        "clientId": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.client : HirningDemandParamsDetails?.state?.filterList?.client || undefined,
        "joLocation": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.joLocation : HirningDemandParamsDetails?.state?.filterList?.joLocation || undefined,
        "businessUnit": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.businessUnit : HirningDemandParamsDetails?.state?.filterList?.businessUnit || undefined,
        "mandateType": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateType : HirningDemandParamsDetails?.state?.filterList?.mandateType || [],
        "jobMode": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobMode : HirningDemandParamsDetails?.state?.filterList?.jobMode || [],
        "mandateSPOC": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateSPOC : HirningDemandParamsDetails?.state?.filterList?.mandateSPOC || [],
        "roles":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.roles : HirningDemandParamsDetails?.state?.filterList?.roles || [],
        "primarySkills":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.primarySkills : HirningDemandParamsDetails?.state?.filterList?.primarySkills || [],
        "resultType":location?.state?.datahandle?.resultType === "Job Orders"
                            ? "joborders"
                            : location?.state?.datahandle?.resultType === "Client"
                            ? "client"
                            : location?.state?.datahandle?.resultType === "Mandate"
                            ? "mandate"
                            : location?.state?.datahandle?.resultType,
        "resultStage":location?.state?.datahandle?.resultStage === "active"
                            ? "active"
                            : location?.state?.datahandle?.resultStage === "inservice"
                            ? "inservice"
                            : location?.state?.datahandle?.resultStage === "unservice"
                            ? "unserved"
                            : location?.state?.datahandle?.resultStage === "onHoldXpheno"
                            ? "onholdbyxpheno"
                            : location?.state?.datahandle?.resultStage === "onHoldClient"
                            ? "onholdbyclients"
                            : location?.state?.datahandle?.resultStage === "onBoard"
                            ? "onboards"
                            : location?.state?.datahandle?.resultStage === "lostOpportunity"
                            ? "lostoppertunity"
                            : location?.state?.datahandle?.resultStage
      };

        try {
            const res: any = await HiringDemandListServiceApi.gethiringdemandlistapi(reqObj);
            console.log("HiringDemandListServiceApi",res?.data?.data)
            setCandidates(res?.data?.data);
            setCandidateData(res?.data)
            setPaginationCountSize(prev => ({
                ...prev,
                totalRecords: res.data.totalRecords,
                pageSize: res.data.pageSize, // page Size
                inputPageNo: res.data.inputPageNo, // Page Current
                inputPageSize: res.data.inputPageSize // current Page Size
              }));

              setexportdatareqest(reqObj)

        } catch (error: any) {
            console.log(error);
        }
    };


    
    const { handleSubmit, control, reset, watch, getValues, setValue } = useForm({});

    const handleSort = (column: string) => {
        const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        setPaginationCountSize(prev => ({
            ...prev,
            inputPageSize: event.target.value as number,
            inputPageNo: 1 // reset to first page
          }));
          console.log("Pagination Size Change: ", paginationCountSize.inputPageNo, paginationCountSize.inputPageSize);

    };

    const { roleId } = useAppSelector((state) => state.UserDetail);

    const paginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setPaginationCountSize(prev => ({ ...prev, inputPageNo: page }));
        console.log("Pagination Change: ", paginationCountSize.inputPageNo, paginationCountSize.inputPageSize);
    };

    const stringToColorbg = (str: string): string => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; ++i) {
          const value = (hash >> (i * 1.5)) & 0xff;
          color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
      };

      const stringToColortext = (str: string): string => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 18)) & 0xff;
          color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
      };

      function truncateString(str:any, num:number) {
        if (str && str.length > num) {
          return str.slice(0, num) + '...';
        } else {
          return str;
        }
      }


      function formatDate(dateString: any): any {
        const date = new Date(dateString);
        // Get day
        const day = date.getDate();
        // Get month (0-based index, so we add 1)
        const month = date.toLocaleString('default', { month: 'short' });
        // Get year
        const year = date.getFullYear();
        // Get hours and minutes
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // Format as '14 Dec 2024, 15:00'
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
      }
      


    const initialColumnFields = [
        { id: "1", objectkey: "mandate_name", label: "Mandate", checkStatus: true, reqObjkey: "mandateName" },
        { id: "2", objectkey: "candidate_name", label: "Candidate", checkStatus: true, reqObjkey: "candidateName" },
        { id: "3", objectkey: "job_title", label: "Job Title", checkStatus: true, reqObjkey: "jobTitle" },
        { id: "4", objectkey: "client_name", label: "Client", checkStatus: true, reqObjkey: "clientName" },
        { id: "5", objectkey: "current_company", label: "Current Org", checkStatus: true, reqObjkey: "currentOrganization" },
        { id: "6", objectkey: "experience", label: "Total Exp", checkStatus: true, reqObjkey: "totalExperience" },
        { id: "7", objectkey: "relevant_experience", label: "Relevant Exp", checkStatus: true, reqObjkey: "relevantExperience" },
        { id: "8", objectkey: "current_location", label: "Current Loc", checkStatus: true, reqObjkey: "currentLocation" },
        { id: "9", objectkey: "preferred_locations", label: "Preferred Loc", checkStatus: true, reqObjkey: "preferredLocation" },
        { id: "10", objectkey: "notice_period", label: "Notice Period", checkStatus: true, reqObjkey: "noticePeriod" },
        { id: "11", objectkey: "phone_number", label: "Contact No", checkStatus: true, reqObjkey: "phoneNumber" },
        { id: "12", objectkey: "e_mail", label: "Email", checkStatus: true, reqObjkey: "email" },
        { id: "13", objectkey: "current_ctc", label: "Current CTC", checkStatus: true, reqObjkey: "currentCTC" },
        { id: "14", objectkey: "exptected_ctc", label: "Expected CTC", checkStatus: true, reqObjkey: "expectedCTC" },
        { id: "15", objectkey: "source", label: "Source", checkStatus: true, reqObjkey: "source" },
        { id: "16", objectkey: "cv_sent_date", label: "CV Submitted Date", checkStatus: true, reqObjkey: "cvSentDate" },
        { id: "17", objectkey: "stage_name", label: "Stage", checkStatus: true, reqObjkey: "stage" },
        { id: "18", objectkey: "candidate_processing_stage", label: "Candidate Processing Stage", checkStatus: true, reqObjkey: "candidateProcessingStage" },
        { id: "19", objectkey: "status_name", label: "Status", checkStatus: true, reqObjkey: "status" },
        { id: "20", objectkey: "recruiter_name", label: "Recruiter Name", checkStatus: true, reqObjkey: "recruiterName" },
        { id: "21", objectkey: "created_at", label: "Profile Artemis Entry Date", checkStatus: false, reqObjkey: "profileEntryDate" },
        { id: "22", objectkey: "modified_on", label: "Profile Artemis Last Modified Date", checkStatus: false, reqObjkey: "profileLastModifiedDate" },
      ];
      



      const [columnFields, setColumnFields] = useState(initialColumnFields);
      const [tempColumnFields, setTempColumnFields] = useState(initialColumnFields);
        const [sortingTableHead, setSortingTableHead] = useState<any>({});
        const [searchTerm, setSearchTerm] = useState<string>('');
        const [opentfiltercolumn,setopentfiltercolumn] = useState(false);
        const [displaycolumncontrol, setdisplaycolumncontrol] = useState(false);
        

        const sortTableHeadHandler = (column:any) => {
            console.log("TesttingAscdec ",column.reqObjkey)
            // const newDirection = columnsort == true ? 'desc' : 'asc';
            // setSortDirection(newDirection)
            // setcolumnsort(!columnsort)
            // setSortColumn(column.reqObjkey)
            // setSortingTableHead((prevState:any) => ({
            // ...prevState,
            // [column.id]: prevState[column.id] === 'Asc' ? 'Desc' : 'Asc',
            // }));

            let newDirection = '';
            if (columnsort === 'asc') {
                newDirection = 'desc';
            } else if (columnsort === 'desc') {
                newDirection = '';
            } else {
                newDirection = 'asc';
            }

            setSortDirection(newDirection);
            setcolumnsort(newDirection);
            setSortColumn(column.reqObjkey);
            setSortingTableHead((prevState: any) => ({
                ...prevState,
                [column.id]: newDirection === 'asc' ? 'Asc' : newDirection === 'desc' ? 'Desc' : '',
            }));
            
            
        };

        const handleCheckboxChange = (columnId:any) => {
            // setColumnFields((prevFields) =>
            // prevFields.map((field) =>
            //     field.id === columnId ? { ...field, checkStatus: !field.checkStatus } : field
            // )
            // )

            setTempColumnFields((prevFields) =>
              prevFields.map((field) =>
                  field.id === columnId ? { ...field, checkStatus: !field.checkStatus } : field
              )
          );
          console.log("checkstatus dare",tempColumnFields)
        };

        const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(event.target.value);
          };
        
          // const handleReset = () => {
          //   setColumnFields(initialColumnFields);
          //   setSearchTerm('');
          // };

          const handleReset = () => {
            // setColumnFields(prevFields =>
            //   prevFields.map(field => ({ ...field, checkStatus: true }))
            // );

            setTempColumnFields(prevFields =>
              prevFields.map(field => ({ ...field, checkStatus: true }))
          );
          };
        
          const handleApply = () => {
            // setColumnFields(prevFields => prevFields.filter(field => field.checkStatus));
            setColumnFields(tempColumnFields);
            setdisplaycolumncontrol(true)
            opentfiltercolumnclick(); // Close the filter dialog
          };
        
          const filteredFields = columnFields.filter((field) =>
            field.label.toLowerCase().includes(searchTerm.toLowerCase())
          );

          const opentfiltercolumnclick = () =>{
            setopentfiltercolumn(!opentfiltercolumn)
          }
          
          

          useEffect(() => {
            hiringListApi();
            sortTableHeadHandler;
            handlePageSizeChange;
            paginationChange;
        }, [HirningDemandParamsDetails,location,sortColumn, sortDirection, paginationCountSize.inputPageSize, paginationCountSize.inputPageNo,textFieldValues]);
        
        useEffect(() => {          
          sortTableHeadHandler;
          handlePageSizeChange;
          paginationChange;
          setTextFieldValues({})
          setTimeout(() => {
            hiringListApi();
          },1000)
          console.log("setTextFieldValues",reportSubmissionAPIData)
      }, [reportSubmissionAPIData])
        const renderEmptySearchBox = () => {;
          return (
              <Box className="empty-data-card">
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }

  return (
    <div>
      <div style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px' }}>
            <Box>
            <KeyboardBackspaceIcon style={{color:'#002882',cursor:'pointer'}} onClick={()=>{navigate("/dashboard",{ state: {dashboardfilterObj:HirningDemandParamsDetails?.state?.dashboardfilterObj, filterList: HirningDemandParamsDetails?.state?.filterList ,filterListLabels: HirningDemandParamsDetails?.state?.filterListLabels } })}}/>
                {/* <CommentFilter setReportSubmissionAPIData={setReportSubmissionAPIData} HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} candidates={candidateData}/> */}
                <HiringDemandFilterApp exportdatareqest={exportdatareqest} setReportSubmissionAPIData={setReportSubmissionAPIData} HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setApplyBtnActive={setApplyBtnActive} candidates={candidateData}/>

                <Box style={{paddingTop:"40px",display:'flex',flexDirection:'column'}}>
                <Box style={{display:'flex',justifyContent:'flex-end'}}>
                    <Box onClick={opentfiltercolumnclick} style={{cursor:'pointer'}}><ToolfilterIcon style={{position:'relative',zIndex:'4'}}/></Box>
                </Box>
                {
                    opentfiltercolumn && (<div style={{display:'flex',justifyContent:'flex-end'}}>
                        <Paper elevation={3} sx={{ padding: '5px', maxWidth: 250 ,position:'absolute',zIndex:'3'}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" sx={{fontSize:'16px'}}>Column Filter</Typography>
                                <IconButton aria-label="close" onClick={opentfiltercolumnclick}>
                                <CloseIcon/>
                                </IconButton>
                            </Box>
                            <Box sx={{ mb: 2, mt: 1 }}>
                                <TextField size="small"
                                fullWidth
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                    ),
                                }}
                                />
                            </Box>
                            <FormGroup sx={{}}>
                            <ScrollableBox>
                            <Box sx={{display:"flex",flexDirection:'column'}}>
                                {filteredFields.map((column) => (
                                <FormControlLabel sx={{fontSize:'12px'}}
                                    key={column.id}
                                    control={
                                    <Checkbox
                                        checked={column.checkStatus}
                                        onChange={() => handleCheckboxChange(column.id)}
                                    />
                                    }
                                    label={column.label}
                                />
                                ))}
                                </Box>
                            </ScrollableBox>
                            </FormGroup>
                            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 , columnGap:'10px'}}>
                                <Button onClick={handleReset} sx={{ bgcolor: '#E5E8EB', color: '#000', borderRadius:'6px' }}>
                                Reset
                                </Button>
                                <Button onClick={handleApply} sx={{ bgcolor: '#002882', color: '#fff', borderRadius:'6px' }}>
                                Apply
                                </Button>
                            </Box>
                            </Paper>
                        </div>)
                }
                
                </Box>
                <Box className="reportTableContSect hiringTableSect" style={{marginTop:'-50px'}}>
                    <TableContainer className="submissionReportContainer roleListTableSect" component={Paper} sx={{border:'1px solid #E5E8EB !important'}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>

                            <TableRow>
                                {columnFields.filter(column => column.checkStatus).map((column) => (
                                    <TableCell key={column.id} style={{ whiteSpace: 'nowrap', cursor:'pointer'}}>
                                    <div onClick={() => sortTableHeadHandler(column)}>
                                        {column.label}
                                        {
                                        sortingTableHead && sortingTableHead[column.id] ? 
                                            (sortingTableHead[column.id] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />
                                        }
                                    </div>
                                    </TableCell>
                                ))}
                            </TableRow>

                            <TableRow>
                                    {columnFields.length > 0 ? columnFields.filter(column => column.checkStatus).map((item, index) => {
                                        const key = `${index}`; // Unique key for each text field
                                        console.log("column", item?.reqObjkey)
                                        const dateField = ["cvSentDate","profileEntryDate","profileLastModifiedDate","rofileLastModifiedDate"]
                                        return (
                                            <TableCell key={key}>
                                                {!dateField.includes(item?.reqObjkey) ? <div className="customehiringdemand">
                                                    <TextField
                                                        type="text"
                                                        // size="small"
                                                        value={textFieldValues[item.reqObjkey] || ''}
                                                        onChange={handleTextFieldChange(item.reqObjkey)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <SearchIcon />
                                                            )
                                                        }}
                                                    />
                                                </div>: 
                                                <FormControl className={`reportTableSearchFieldMask`} variant="standard">
                                                <Input
                                                value={textFieldValues[item.reqObjkey] || ''}
                                                onChange={handleTextFieldChange(item.reqObjkey)}
                                                name="textmask"
                                                id="formatted-text-mask-input"
                                                inputComponent={TextMaskCustom as any}
                                                startAdornment={
                                                  <InputAdornment position="start">
                                                    <SearchIcon />
                                                  </InputAdornment>
                                                }
                                                />
                                              </FormControl>}
                                            </TableCell>
                                        );
                                    }) : null}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {candidates && candidates.length > 0 ? candidates.map((candidate,index) => {
                                  console.log("candidateLoop",candidate)
                                    const avatar = candidate.candidate_name != null ? candidate.candidate_name.substring(0, 2).toUpperCase(): ""
                                    const avatarBgColor = candidate.candidate_name != null ? stringToColorbg(candidate.candidate_name): "";
                                    const colorhash = candidate.candidate_name != null ? stringToColortext(candidate.candidate_name): "";
                                    
                                    return (
                                    <TableRow key={index} style={{ minHeight: 'fit-content'}}>
                                        {columnFields.find(column => column.id === '1' && column.checkStatus) && (
                                        <TableCell>
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", minWidth: "fit-content", columnGap: '10px' }}>
                                            <div style={{ minWidth: "fit-content" }}><DefaultClientLogo /></div>
                                            <div style={{ display: 'flex', minWidth: "fit-content", flexDirection: "column", fontFamily: 'sora', rowGap: '5px' }}>
                                                <div style={{ fontWeight: "600" }}>{candidate.mandate_name}</div><div>{candidate.mandate_code}</div>
                                            </div>
                                            </div>
                                        </TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '2' && column.checkStatus) && (
                                        <TableCell>
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", minWidth: "fit-content", columnGap: '10px' }}>
                                            <div style={{ minWidth: "fit-content" }}>
                                                <div style={{ minWidth: "fit-content", backgroundColor: avatarBgColor, display:'flex',alignContent:'center', alignItems:'center',justifyContent:'center', height:"36px", width:'36px', fontWeight: 'bold', fontSize: '12px', color: `${colorhash}`, borderRadius: '50px' }}>
                                                {avatar}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', minWidth: "fit-content", flexDirection: "column", fontFamily: 'sora', rowGap: '4px', paddingTop: '3px' }}>
                                                <div style={{ fontWeight: "600", color: '#000', fontSize: '12px' }}>{candidate.candidate_name}</div><div>{candidate.candidate_id}</div>
                                            </div>
                                            </div>
                                        </TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '3' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.mandate_name}>{candidate.mandate_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '4' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.client_name}>{candidate.client_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '5' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.current_company}>{candidate.current_company || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '6' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.experience}>{candidate.experience || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '7' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.relevant_experience}>{candidate.relevant_experience || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '8' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.current_location}>{candidate.current_location || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '9' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.preferred_locations}>{candidate.preferred_locations ? truncateString(candidate.preferred_locations, 12) : '-'}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '10' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.notice_period}>{candidate.notice_period || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '11' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.phone_number}>{candidate.phone_number || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '12' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.e_mail}>{candidate.e_mail || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '13' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.current_ctc}>{candidate.current_ctc || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '14' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.exptected_ctc}>{candidate.exptected_ctc || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '15' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.source}>{candidate.source || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '16' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={formatDate(candidate.cv_sent_date)}>{candidate.cv_sent_date ? formatDate(candidate.cv_sent_date) : '-' }</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '17' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.stage_name}>{candidate.stage_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '18' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.stage_name}>{candidate.stage_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '19' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.status_name}>{candidate.status_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '20' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candidate.recruiter_name}>{candidate.recruiter_name || "-"}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '21' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={formatDate(candidate.created_at)}>{ candidate.created_at ? formatDate(candidate.created_at) : '-'}</Tooltip></TableCell>
                                        )}
                                        {columnFields.find(column => column.id === '22' && column.checkStatus) && (
                                        <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={formatDate(candidate.modified_on)}>{candidate.modified_on ? formatDate(candidate.modified_on) : '-'}</Tooltip></TableCell>
                                        )}
                                    </TableRow>                                    
                                    );
                                }) : (
                                    <TableRow>
                                    <TableCell colSpan={columnFields.length} align="center">
                                        {renderEmptySearchBox()}
                                    </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>
                    <Box className="hiringTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Age"
									value={paginationCountSize.inputPageSize}
                                    onChange={handlePageSizeChange}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={paginationCountSize.pageSize}
                            page={paginationCountSize.inputPageNo}
                            onChange={paginationChange} variant="outlined" shape="rounded"/>
					</Box>
            </Box>
        </div>
    </div>
  )
}

export default HiringDemandComponentdetails