import React, {useEffect, useRef, useState } from 'react'
import { Paper, Box, Typography, Grid, Stack, Button, Checkbox, FormControl, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar, Chip, InputLabel, Select, MenuItem  } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.svg'
import { AppButton } from '../../../../../../AppComponents/AppButton'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../../../../../utils/constants'
import { ReportSearch } from '../Search/Search'
import { ReactComponent as DownloadIcon } from '../../../../../../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../../../../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../../../../assets/icons/arrow-up.svg'
import { ReactComponent as SortIcon } from '../../../../../../../assets/icons/sort-arrow.svg'
import { ReactComponent as CalenderIcon } from '../../../../../../../assets/icons/input-calendar.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs';
import { AppAutoComplete } from '../../../../../../AppComponents/AppAutoComplete'
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { ReactComponent as RotateIcon } from '../../../../../../../assets/icons/reset.svg'
import Popover from '@mui/material/Popover';
import { MandateService, dropDownData, getDepartmentRoles, getMandateLocation, getMandateStatusDropdown } from '../../../../../../../services/MandateService'
import _ from 'lodash'
import { ReportkManagementService } from '../../../../../../../services/ReportListService'
import DepartmentDropDown from '../../../../../../Mandates/AddMandate/DepartmentDropDown'
import { useAppDispatch,useAppSelector } from "../../../../../../../store/hooks";
import { convertToDisplayDate } from '../../../../../../../utils/date-utils'
import moment from 'moment'
import IndustryDropdown from '../../../../../../Mandates/AddMandate/IndustryDropdown'
import MuiAlert from '@mui/material/Alert';
import { TaskManagementService } from '../../../../../../../services/TaskService'
import { reportFilterLoadData } from '../../../../../../../features/filters/FilterReducer'
import { Breadcrumb } from '../../../../../../Breadcrumb'
import { ReactComponent as WidgetIcon } from '../../../../../../../assets/icons/Widget-icon.svg'
import { DateRangePicker, DateRange } from '@mui/lab';
import '../../../../../../../components/Mandates/AddMandate/DepartmentDropDown.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { styled } from '@mui/system';
import FilterComponent from '../../../../../CustomeDasboardFilter/DashboardFilter/Filters/Filter'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom'
import LaunchIcon from '@mui/icons-material/Launch';


// const DrillDownContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   backgroundColor: '#f4f9ff',
//   borderRadius: '8px',
//   padding: '8px 16px',
//   flexWrap:'wrap',
//   width:'100%',
// }));

const DrillDownContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: '#f4f9ff',
  borderRadius: '8px',
  padding: '8px 16px',
  alignContent:'center',
  position: 'relative',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '8px',
    height: '100%',
    backgroundColor: '#0033a0',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
}));

const Divider = styled('div')(({ theme }) => ({
  width: '4px',
  height: '100%',
  backgroundColor: '#0033a0',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  marginRight: '8px',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '16px',
  border: '1px solid #0033a0',
  margin: '0 0px',
}));


const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f4f9ff',
  color: '#0033a0',
  border: '1px solid #0033a0',
  borderRadius: '10px',
  textTransform: 'none',
  padding: '7px',
  '&:hover': {
    backgroundColor: '#e0f3ff',
  },
}));


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const HiringFilterComponent = ({exportdatareqest, HirningDemandParamsDetails,setHirningDemandParamsDetails,setreportSubmitdatalabels,Coelist,reportClientAPIList,reportBussinessAPIList,jobModeList,mandateSpocList,mandateTypeList,businessUnitList,JobtitleList,JobLocationList,industryList,rolesdataList,primaryskillList,setReportSubmissionAPIData, setResetFilter,setApplyBtnActive, candidates}:any) => {
  
  console.log("HirningDemandParamsDetails : ",HirningDemandParamsDetails)

  const [dashboradfilterList,setdashboradfilterList] = useState<any>(HirningDemandParamsDetails?.state?.filterListLabels)
  const [filterChecked, setfilterChecked] = useState<boolean>(false)
  const [columnFilter, setColumnFilter] = useState<boolean>(false)
  const [keyWordSearch, setKeyWordSearch] = useState("")
  let userRoleState = useAppSelector((state:any) => state?.UserDetail?.userRole);
  const userStateId = useAppSelector((state) => state.UserDetail.userId)
  userRoleState = userRoleState ? userRoleState.replace("/","").replace(/ /g,"").trim() : "";
  const dispatch = useAppDispatch()
  const [filterTabCloseStatus, setfilterTabCloseStatus] = useState<string>()
 
  const [isOpendrilldownfilter, setIsOpendrilldownfilter] = useState(false);

  const navigate = useNavigate()
  const handleFilter = () => {
    setIsOpendrilldownfilter(false)
    setfilterChecked((prev) => !prev)
  }

  const handleClick = () => {
    setfilterChecked(false)
    setIsOpendrilldownfilter(!isOpendrilldownfilter);
  };

  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
  const [selectAllColumnsState, setSelectAllColumnsState] = useState<any>(false)  


  const userbasedReportFilterCase:any = {
    Admin:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],  
    Management:["BUHead","Delivery/Account Manager","Team Lead","Recruiter"],
    BUHead:["Delivery/Account Manager","Team Lead","Recruiter"],
    DeliveryAccountManager: ["Team Lead","Recruiter"],
    TeamLead:["Recruiter"],
    Recruiter:[]
  }
  const bussiUnitKeyRefer:any = {
    "BUHead": "bUHead",
    "Delivery/Account Manager": "deliveryManager",
    "Team Lead": "teamLead",
    "Recruiter":"recruiter"
  } 
  const [bussinessUnitOveallObj, setBussinessUnitOveallObj] = useState<any>({})
  const [bussinessUnitOveallObjInitial, setBussinessUnitOveallObjInitial] = useState<any>({})
  const[bussinessUnitObjData, setBussinessUnitObjData] = useState<any>({})
  
  const currentRoleOrder = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
  const [currentRoleSelected, setCurrentRoleSelected] = useState(userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState] ? userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState][0]: "")
  const [heirarchyRoleList, setHeirarchyRoleList] = useState<any>()
  const [userIdSelected, setUserIdSelected] = useState<any>(userId)    
  const [currentRoleStr, setCurrentRoleStr] = useState<any>([])

  const getBussinessUnitObj = () => {
    if(Object.keys(reportBussinessAPIList).length > 0){
      const bussObjArray:any = {};
        const parentRole = userbasedReportFilterCase[userRoleState == "DeliveryManager" ? "DeliveryAccountManager" : userRoleState];
        const disableRoleObj:any = {}
       
        const roleBaseObj = reportBussinessAPIList[parentRole[0] == "Recruiter" ? "recuriterDropDown" : parentRole[0] == "Team Lead" ? "teamLeadDropDown": parentRole[0] == "Delivery/Account Manager" ? "accountManagerDropDown" : parentRole[0] == "BUHead" ? "buHeadDropDown": ""]
        // bussObjArray[parentRole[0]] = roleBaseObj
        parentRole.map((item:any, index:number) => {
          let userRoleOption = _.map(reportBussinessAPIList[item == "Recruiter" ? "recuriterDropDown" : item == "Team Lead" ? "teamLeadDropDown": item == "Delivery/Account Manager" ? "accountManagerDropDown" : item == "BUHead" ? "buHeadDropDown": ""], (data: any,index:any) => {
            return {
              label: _.get(data, 'firstName'),
              value: _.get(data, 'id'),
              index: index + 1,
              reporting: _.get(data, 'reporting')
            }
          })
          userRoleOption = prepend({label: "All",value: "All", index: 0}, userRoleOption)
          disableRoleObj[item] = {
            disabled : index > 0 ? true : false,
            options: userRoleOption,
            selectedValue: 0
          }
          })
          setBussinessUnitOveallObj(disableRoleObj)
          if(Object.keys(bussinessUnitOveallObjInitial).length == 0)setBussinessUnitOveallObjInitial(JSON.parse(JSON.stringify(disableRoleObj)))
          setBussinessUnitObjData(bussObjArray)
    }    
  }

  function prepend(value:any, array:any) {
    const newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }

  const getUserBaseData = (userRole:string) => {
    let userRoleOption = []
    userRoleOption = _.map(bussinessUnitObjData[userRole], (data: any) => {
      return {
        label: _.get(data, 'firstName'),
        value: _.get(data, 'id'),
      }
    })
    return prepend({
      label: "All",
      value: "All",
    }, userRoleOption)
  }

  useEffect(() => {    
    getBussinessUnitObj()
  },[reportBussinessAPIList])
  
  const statusdList = [
    {
      label: "All",
      value : "All"
    },
    {
      label: "Active",
      value : "1"
    },
    {
      label: "Inactive",
      value : "2"
    },
    {
      label: "Re Joined",
      value : "137"
    },
    {
      label: "De Activated",
      value : "136"
    }
] 

const StageList = [
  {
    label: "All",
    value : "All"
  },
  {
    label: "CV Submission",
    value : "1"
  },
  {
    label: "Selection Stage",
    value : "2"
  },
  {
    label: "Interview Stage",
    value : "137"
  },
  {
    label: "Joining Stage",
    value : "136"
  }
] 

  const { handleSubmit, control, reset, watch, getValues,setValue } = useForm({})

  const [preloadDataObj , setPreloadDataObj] = useState<any>()
  const [submissionStartDate, setSubmissionStartDate] = React.useState<Dayjs | null>(null);
  const [submissionEndDate, setSubmissionEndDate] = React.useState<Dayjs | null>(null);

  const [mandateLocationPreloadLists, setMandateLocationPreloadLists] = useState([])

  const getMandateLocationPreloadList = async (manId:number) => {
    setMandateLocationPreloadLists([])
    // const apiQuery:string = userRole === "Recruiter" ? `?userId=${userId}` : "" 
    const apiQuery = "" 
    const response = await getMandateLocation(manId, apiQuery)
    if(response?.status === 200){
      const locationOptions =_.map(response?.data?.result?.mandateLocations, (data: any) => {
        return {
          label: _.get(data, 'location'),
          value: _.get(data, 'id'),
        }
      })
      setMandateLocationPreloadLists(locationOptions)
      setValue("mandateLocation", preloadDataObj?.mandateLocation)
    }
  }


  const keyToLabel: { [key: string]: string } = {
    client: "Client",
    jobTitle: "Job Title",
    primarySkills: "Primary Skills",
    mandateType: "Mandate Type",
    jobMode: "Job Mode",
    mandateSPOC: "Mandate SPOC",
    joLocation: "Job Location",
    businessUnit: "Business Unit",
    BUHeadUserID: "BU Head User ID",
    RecruiterUserID: "Recruiter User ID",
    customStartDate: "Date",
    customEndDate: "Date",
    industry: "Industry",
    roles: "Roles"
  };

  const getReportExport = async () => {
    // const reportSubmissionAPIDataObj = JSON.parse(JSON.stringify(reportSubmissionAPIData))
    // const selColObj = JSON.parse(JSON.stringify(selectColumnsState))
    // let columnSelect = _.filter(Object.keys(selColObj), (data: any) => {
    //   return selColObj[data] && referAPIObjKeys[data]
    // })
    // columnSelect = _.map(columnSelect , (columItem:any) => {
    //   return referAPIObjKeys[columItem]
    // })
    // reportSubmissionAPIDataObj["clientName"] = reportSubmissionAPIDataObj["client"]
    // reportSubmissionAPIDataObj["client"] = null
    // reportSubmissionAPIDataObj["recruiterName"] = reportSubmissionAPIDataObj["recruiter"]
    // reportSubmissionAPIDataObj["mandateId"] = reportSubmissionAPIDataObj["Mandate"]
    // reportSubmissionAPIDataObj["recruiter"] = ""
    // reportSubmissionAPIDataObj["locationId"] = reportSubmissionAPIDataObj["mandateLocation"]
    // reportSubmissionAPIDataObj["mandateLocation"] = ""
    // const requestBody = {...reportSubmissionAPIDataObj,...{selectedColumn:[...["candidateName"],...columnSelect]}}
    try{
     const res = await ReportkManagementService.exportSubmitionDashborad(exportdatareqest)
      if(res?.status === 201){
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CandidateDetails${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }      
    }catch(error){
      console.log(error)
    }
  }


    return (
        <>
        <Box className='HiringDemadUiCustome'>
          <Box sx={{marginBottom:'5px'}}>
            <div>
            <Paper className='box-style' elevation={1}>
            <Box display='flex' justifyContent='space-between'>
              <Box className='tasklist' >
              {/* <KeyboardBackspaceIcon style={{color:'#002882',cursor:'pointer'}} onClick={()=>{navigate("/dashboard",{ state: { filterList: HirningDemandParamsDetails?.state?.filterList ,filterListLabels: HirningDemandParamsDetails?.state?.filterListLabels } })}}/> */}
              <Breadcrumb title={`Hiring Demand - Clients Active Profile (${HirningDemandParamsDetails?.state?.data?.count})`} />
                <Typography display='flex' gap={'5px'} className='tasklist-title' sx={{marginTop:'-15px', marginBottom:'10px',cursor:'pointer'}}>
                    <span>{`Overview Dashboard`}</span><span style={{color:'#0033a0'}}>{`> Hiring Demand - ${HirningDemandParamsDetails?.state?.datahandle?.resultType === "Job Orders"
                            ? "Job Orders"
                            : HirningDemandParamsDetails?.state?.datahandle?.resultType === "Client"
                            ? "Client"
                            : HirningDemandParamsDetails?.state?.datahandle?.resultType === "Mandate"
                            ? "Mandate"
                          :''}

                      ${HirningDemandParamsDetails?.state?.datahandle?.resultStage ? 
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'active' ? 'Active' :
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'inservice' ? 'In service' :
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'unservice' ? 'Un serviced' :
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'onHoldXpheno' ? 'On Hold By xpheno' :
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'onHoldClient' ? 'On Hold By Client' :
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'lostOpportunity' ? 'Lost Opportunity' :
                      HirningDemandParamsDetails?.state?.datahandle?.resultStage == 'onBoard' ? 'On Boards' :
                      '':''}`} Profile</span>
                </Typography>
                {/* <Box className="hiringInfoSection">
                  <ul>
                    <li><span className='circle'></span>Client <span className='number' style={{color: "#e17413"}}>{candidates?.clientCount}</span></li>
                    <li><span className='circle'></span>Mandate <span className='number' style={{color: "#2250d3"}}>{candidates?.mandateCount}</span></li>
                    <li><span className='circle'></span>Job Order <span className='number' style={{color: "#740805"}}>{candidates?.jobOrderCount}</span></li>
                    <li><span className='circle'></span>Candidate <span className='number' style={{color: "#538409"}}>{candidates?.candidateCount}</span></li>
                  </ul>
                </Box> */}
              </Box>
             
              <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',alignContent:'center',columnGap:'8px'}}>
                {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                
                <Box>
                <StyledButton onClick={handleClick} endIcon={isOpendrilldownfilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
                  Drill Down From
                </StyledButton>
                </Box>
             
                  <AppButton
                    variant='contained'
                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                    margin={5}
                    minwidth={40}
                    onClick={handleFilter}
                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                  >
                    {' '}
                    <FunnelIcon className='add-new-icon' />{' '}
                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </AppButton>
                  <AppButton
                    className='hiringExport'
                    variant='contained'
                    bgcolor={PRIMARY3}
                    margin={5}
                    minwidth={40}
                    onClick={getReportExport}
                  >
                    <DownloadIcon />{' '}
                  </AppButton>
                  <div style={{display:'flex',alignItems:'center'}}><MoreVertIcon/></div>
              </Box>
            </Box>


                    {filterChecked ?
                        <Box sx={{border:'1px solid #E5E8EB',borderRadius:'10px',marginBottom:'5px'}}>
                        <FilterComponent HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setreportSubmitdatalabels={setreportSubmitdatalabels} Coelist={Coelist} reportClientAPIList={reportClientAPIList} reportBussinessAPIList={reportBussinessAPIList} jobModeList={jobModeList} mandateSpocList={mandateSpocList} mandateTypeList={mandateTypeList} businessUnitList={businessUnitList} JobtitleList={JobtitleList} JobLocationList={JobLocationList} industryList={industryList} rolesdataList={rolesdataList} primaryskillList={primaryskillList} setReportSubmissionAPIData={setReportSubmissionAPIData} setResetFilter={setResetFilter} hideFields={[]} hideDashHeader={true} setApplyBtnActive={setApplyBtnActive}/>
                      </Box> : isOpendrilldownfilter ?
                      <DrillDownContainer>
                       
                        <Divider />
                        <div style={{width:'15%',display:'flex',alignItems:'flex-start'}}>
                        <Typography variant="body1" fontWeight="bold" mr={1}>
                          Drill Down from
                        </Typography>
                        </div>

                      <div style={{width:'85%',display:'flex',justifyContent:'flex-start',gap:'10px',flexWrap:'wrap'}}>
                      {Object.keys(HirningDemandParamsDetails).length > 0 && Object.keys(HirningDemandParamsDetails?.state).length > 0 && Object.keys(HirningDemandParamsDetails?.state?.filterListLabels).map((key,index) => {
                        console.log("Drillcheck",key)
                        let displayValue;

                        // Handle date range for customStartDate and customEndDate
                        if (key === 'customStartDate' || key === 'customEndDate') {
                          if (key === 'customStartDate') {
                            const startDate = dayjs(HirningDemandParamsDetails?.state?.filterListLabels['customStartDate']).format('DD-MMM-YYYY');
                            const endDate = dayjs(HirningDemandParamsDetails?.state?.filterListLabels['customEndDate']).format('DD-MMM-YYYY');
                            displayValue = `${startDate} to ${endDate}`;
                          } else {
                            return null; // Skip customEndDate
                          }
                        } else {
                          displayValue = Array.isArray(HirningDemandParamsDetails?.state?.filterListLabels[key]) 
                            ? HirningDemandParamsDetails?.state?.filterListLabels[key].filter((item: string) => item.length > 0).join(', ') 
                            : HirningDemandParamsDetails?.state?.filterListLabels[key];
                        }

                        // Skip rendering if displayValue is empty or has no length
                        if (!displayValue || displayValue.length === 0) {
                          return null;
                        }

                        
                        return(
                            <div key={index} style={{display:'flex',flex:'wrap',alignContent:'center',alignItems:'center'}}>
                                <Typography variant="body2" color="textSecondary" mr={1}>
                                    {keyToLabel[key] || key}
                                      </Typography>
                                <StyledChip label={`${displayValue}`} />
                              </div>
                            )})}
                    </div>

                    </DrillDownContainer>:null}


                </Paper>
            </div>
        </Box>
        </Box>
          
        </>
    )
}
export default HiringFilterComponent