import { useState } from 'react';
import user1 from './user_1.png';
import Modal from './base/Modal';
import { readFile } from './cropImage';
import ImageCropModalContent from './ImageCropModalContent';
import { useImageCropContext } from './ImageCropProvider';
import profileDefault from "../../../assets/images/profileDefault.png"
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

const ImageCrop = ({onChange,onBlur,value,isEdit,profileLink,enableClose}:any) => {
  const [openModal, setOpenModal] = useState(false);
  const [preview, setPreview] = useState(value);
  const [clearProfilePic, setclearProfilePic] = useState(0)
  const fileExtension = ["image/jpeg","image/jpeg","image/png"]

  const { getProcessedImage, setImage, resetStates } = useImageCropContext();

  const handleDone = async () => {
    const avatar = await getProcessedImage();
    onChange(avatar)
    onBlur()
    setPreview(window.URL.createObjectURL(avatar));
    resetStates();
    setOpenModal(false);
  };

  const handlereset = () => {
    onChange("")
    setPreview("");
    setclearProfilePic(clearProfilePic + 1)
  };

  const handleFileChange = async (files:any) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
    setOpenModal(true);
  };

  return (
    <div className="profileUploadImgSect">
      {enableClose && <Box className="profileCloseIcon"><CloseIcon onClick={() => {handlereset()}}/></Box>}
      <img
          src={preview ? preview : isEdit ? (profileLink.length > 0 && clearProfilePic == 0 ) ? profileLink : profileDefault : profileDefault}
          height={192}
          width={192}
          className="object-cover rounded-full h-48 w-48"
          alt=""
        />
      <input
        type="file"
        onChange={(event:any) => {
          if(fileExtension.includes(event?.target?.files?.[0]?.type)){
            if(event?.target?.files?.[0]?.size <= 5000000){
              handleFileChange(event.target.files)
              event.target.value=null
            }else{
              toast.error('Max file size is 5MB')                          
            }   
          }else{
            toast.error('Upload file type not accepted')
          } 
        }}
        className="hidden"
        id="avatarInput"
        accept="image/*"
      />
      <label htmlFor="avatarInput" className="cursor-pointer">
        <p>Add Profile Image</p>
      </label>

      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        <ImageCropModalContent handleDone={handleDone} handleClose={() => setOpenModal(false)} />
      </Modal>
    </div>
  );
};

export default ImageCrop;
