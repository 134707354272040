// import http from './http-common'
import { instance } from './http-common'
import ILoginData from '../types/Login'
class LoginService {
    login(data: ILoginData) {
        return instance.post<any>('user/login', data);
    }
    logout(id: string) {
        return instance.post(`user/logout/${id}`);
    }
    underManintenanceAPI() {
        return instance.get(`master/common/maintenance`);
    }
}

export default new LoginService();

// <Array<ITutorialData>