/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo, useState } from 'react'
import moment from "moment";
// import './MyTaskList.css'
import './MyTaskList.scss'
import {
  Box,
  List,
  ListItem,
  styled,
  Divider,
  Typography,
  IconButton,
  Paper,
  Grid,
  AvatarGroup,
  Avatar,
  Tooltip
} from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { AppDialog } from '../../AppComponents/AppDialog'
import { COLOR_WHITE, SECONDARY3, PRIMARY1 } from '../../../utils/constants'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.icon.svg'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash.svg'
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg'
export const ListContainer = styled(Box)({
  backgroundColor: `${COLOR_WHITE}`,
  marginTop: '1rem',
  padding: '1rem',
  borderRadius: '10px',
})

import { TaskModal } from '../TaskModal'
import { TaskDetailDrawer } from '../TaskDetail'
import { TaskFormInputProps } from '../TaskModal/TaskModal'
import _ from 'lodash'
import { UpdateTaskInfo } from '../../../services/TaskService'
import { Taskdetail } from '../../../pages/MyTasks'
import { convertToDisplayDateTime } from '../../../utils/date-utils'
import { Loading } from '../../../pages/Loading'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import StatusComponent from '../../StatusComponent'
import { TaskDrawerData } from '../TaskDrawerData'
import { selectedTask, taskdrawerclose, taskdraweropen } from '../../../features/taskDetail/DrawerReducer'
import { getLoggedInUserId } from '../../../utils/user-utils';

interface Props {
  data: Taskdetail[]
  handlePage?: () => void
  handleDelete: (id: number | undefined) => void
  onCreateSuccess: () => void
  onUpdateSuccess: (taskUpdateInfo: UpdateTaskInfo) => void
  assignedToUsers: []
  isHorizontal?:boolean,
  totalTaskCount: number
  isLoadingNextPage: boolean
  mandateOptions: any
}
export const MyTaskList: React.FC<Props> = ({
  data,
  totalTaskCount,
  isLoadingNextPage,
  handlePage,
  handleDelete,
  assignedToUsers,
  onCreateSuccess,
  onUpdateSuccess,
  mandateOptions
}) => {
  const [open, setOpen] = useState(false)
  const [isOpenConfim, setisOpenConfirm] = useState(false)
  const [editdata, setEditdata] = useState<TaskFormInputProps>()
  const [taskdata, setTaskdata] = useState<Partial<Taskdetail>>({})
  const [deleteid, setDeleteid] = useState<number>()
  const [colorForAlpha, setcolorForAlpha] = useState<any>({})
  const statusColor: any = { open: '#835AA8', inprogress: '#0E94F4', completed: '#2BAD63' }
  const hidelayout = useAppSelector((state) => state.taskdrawer.hideGridlayout)
  const opendrawer = useAppSelector((state) => state.taskdrawer.drawer)
  const taskData = useAppSelector((state) => state.taskdrawer.taskData)
  const loggedinUser = useMemo(() => getLoggedInUserId(), [])
  const dispatch = useAppDispatch()
  const handledrawerClose = () => {
    setTaskDetailShow(false)
    dispatch(taskdrawerclose())
  }
  const [taskDetailShow, setTaskDetailShow] = useState(false)

  const taskStatus: any = {
    'inprogress': 'In progress',
    'open': 'Open',
    'completed': 'Completed'
  }

  const handleClickOpen = (data: Taskdetail) => {
    setOpen(true)
    const assigness = _.get(data, 'assignees', [])
    const assignedToList = _.map(assigness, (assignee: any) => {
      return _.get(assignee, 'id')
    })
    setEditdata({
      taskId: data.taskId,
      taskname: data.taskName,
      description: data.description,
      assignto: assignedToList,
      mandates: data?.mandates,
      isHorizontal:data.isHorizontal,
      duedate: data?.dueDate,
      topost: data?.topost,
    })
  }
  const getRandomItem = (alpha: string) => {
    const arr: string[] = [
      '#FF6633', '#E6B333', '#3366E6', '#999966', '#B34D4D',
      '#80B300', '#809900', '#6680B3', '#66991A', '#E666FF',
      '#4D8000', '#B33300', '#CC80CC', '#66664D', '#1AB399',
      '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#4D8066',
      '#809980', '#1AFF33', '#999933', '#FF3380', '#CCCC00',
      '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380',
      '#FF4D4D', '#99E6E6', '#6666FF'
  ]
    const randomIndex = Math.floor(Math.random() * arr.length)
    const item = arr[randomIndex]
    setcolorForAlpha({ ...colorForAlpha, [alpha]: item })
    return item
  }
  const handleClose = () => {
    setOpen(false)
  }

  // drawer open and close function
  const handledrawerOpen = (taskdata: Taskdetail) => {
    setTaskDetailShow(true)
    console.log('clicked.............................')
    dispatch(taskdraweropen())
    dispatch(selectedTask(taskdata))
    setTaskdata(taskdata)
  }

  const handleConfirmModal = () => {
    setisOpenConfirm(false)
  }

  const handleOpenConfirm = (id: number) => {
    setDeleteid(id)
    setisOpenConfirm(true)
  }

  const handleCloseConfirm = () => {
    setisOpenConfirm(false)
  }

  const filters = useAppSelector((state) => state.filtersReducer.filters)

  const searchInputs: string[] = [];
  const commonFilterText = _.get(filters, 'commonFilterText');
  const nameText = _.get(filters, 'name.label');
  if (nameText) {
    searchInputs.push(nameText)
  }
  if (commonFilterText) {
    searchInputs.push(commonFilterText)
  }

  const searchValue = _.join(
    _.map(searchInputs, (searchInput: string) => `“${searchInput}”`),
    ` & `,
  )

  const Tasklist = () => {
    const renderAssigness = (data: any,assignees:any) => {
     
      const assigneeNames = _.map(assignees, 'name')    

      return (
        <ListItemText
          // primary={
          //   <>
          //     <Typography component='span' className='task-list-assignee-heading'>
          //       Assignee
          //     </Typography>
          //   </>
          // }
          className='task-list-text1'
          secondary={
            <>
             
              <AvatarGroup className='avatarGroup' max={5}>
                {assigneeNames.map((name: string) => {
                  return (
                    <Tooltip key={name} title={name} arrow>
                      <Avatar
                        key={name}
                        sx={{
                          bgcolor: colorForAlpha?.[name] || getRandomItem(name),
                          fontSize: 'medium',
                          width: '30px',
                          height: '30px',
                        }}
                        alt='Remy Sharp'
                        className={assigneeNames?.length > 1 ? 'task-assignee-avatar' : ''}
                      >
                        
                        {name[0]}
                      </Avatar>
                    </Tooltip>
                  )
                })}
              </AvatarGroup>
            </>
          }
          primaryTypographyProps={{ component: 'span', className: 'task-list-primary-text' }}
          secondaryTypographyProps={{ component: 'div', className: 'task-list-secondary-text' }}
        />
      )
    }

    const renderTaskBasicInfo = (data: any) => {
      return (
        <ListItemText
          primary={
            <Box className='task-basic-info-container'>
              <Typography component='span' className='task-name' title={data.taskName}>
                {data.taskName}
              </Typography>
              <Typography component='span' className='task-date' sx={{ color: `${SECONDARY3}` }}>
                {convertToDisplayDateTime(data.createdAt)}
              </Typography>
            </Box>
          }
          className='task-list-text'
          secondary={
            <>
              <UserIcon />
              <Typography
                component='span'
                className='task-assigner-name'
                sx={{ color: `${SECONDARY3}` }}
              >
                {data.createdByName}
              </Typography>
            </>
          }
        />
      )
    }
    // const headingStyle={{}}
    const dueOrNot = (date: any) => {
      const duedate = new Date(date)
      const currentDate = moment().startOf('day')
      const dueDate = moment(duedate).startOf('day')
      return dueDate.diff(currentDate, 'days')
    }
    return (
      <>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            {!!data.length && <Grid container className='mandate-full-page-one'>
              <Grid>
                {<Typography sx={{ fontSize: '12px', color: '#727376' }}>TASKS</Typography>}
              </Grid>
              <Grid>
                {<Typography sx={{ fontSize: '12px', color: '#727376' }}>ASSIGNEES</Typography>}
              </Grid>
              <Grid>
                {<Typography sx={{ fontSize: '12px', color: '#727376' }}> </Typography>}
              </Grid>
              <Grid>
                {<Typography sx={{ fontSize: '12px', color: '#727376' }}>DUE DATE</Typography>}
              </Grid> 
              <Grid>
                {<Typography sx={{ fontSize: '12px', color: '#727376' }}>STATUS</Typography>}
              </Grid>
              <Grid>
                {<Typography sx={{ fontSize: '12px', color: '#727376' }}>ACTIONS</Typography>}
              </Grid>
            </Grid>}
          </ListItemButton>
        </ListItem>
        {data.map((data: any) => {
          const editIconClass = !data.isEditAllowed ? ' edit-read-only-icon' : ''
          const deleteIconClass = !data.isDeleteAllowed ? ' delete-read-only-icon' : ''
          const taskColor =data.dueDate ? dueOrNot(data.dueDate) <= 0 ? '#FF0000' : '#000' : '#000'
          const day = ()=> dueOrNot(data.dueDate)==0 ? 'Today, ' : dueOrNot(data.dueDate)== -1 ? 'Yesterday, ':  ''
          const assignees = _.get(data, 'assignees', [])
          // const isNeedFilter =data?.isHorizontal ? rawAssignees?.find((x:any)=>x?.id === loggedinUser) ?  (data?.createdById=== loggedinUser) ? false :true :false :false
          // const assignees = isNeedFilter ? rawAssignees.filter((x:any)=>x.id === loggedinUser) : rawAssignees
          return (
            <div
              key={data.taskId}
              style={data.dueDate ? dueOrNot(data.dueDate) <= 0 ? { backgroundColor: '#FFF7F7' } : {} :{}}
            >
              <ListItem className='task-list-item' disablePadding>
                <ListItemButton>
                  <Grid container className='mandate-full-page-one'>
                    <Grid className='task-name' onClick={() => handledrawerOpen(data)}>
                      {renderTaskBasicInfo(data)}{' '}
                    </Grid>
                    <Grid className='mandate-full-page-task ' onClick={() => handledrawerOpen(data)}>
                      <Box >{renderAssigness(data, assignees)}</Box> 
                    </Grid>
                    <Grid className='mandate-full-page-task ' onClick={() => handledrawerOpen(data)}>
                      <Typography sx={{ fontSize: '12px', color: '#727376' }}> {assignees?.length} Assignee{assignees?.length >1 && "s"}</Typography>
                    </Grid>
                    <Grid className='mandate-full-page-task' onClick={() => handledrawerOpen(data)}>
                      <Typography sx={{ fontSize: '12px', color: taskColor }}>
                        {`${day()}${data.dueDate ? moment.utc(data.dueDate).local().format('DD MMM YYYY') :'-'}`}
                      </Typography>
                    </Grid>

                    <Grid className='task-status'>
                      <StatusComponent
                        status={taskStatus[data?.statusname]}
                        color={statusColor[data.statusname]}
                      />
                    </Grid>
                    <Grid className='delete-edit-wrapper'>
                      <IconButton
                        edge='end'
                        aria-label='comments'
                        onClick={() => {
                          if (!data.isEditAllowed) return
                          handleClickOpen(data)
                        }}
                        title={
                          !data.isEditAllowed
                            ? 'Edit option allowed only for user who created the task'
                            : ''
                        }
                        className={'edit-icon' + editIconClass}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge='end'
                        aria-label='comments'
                        onClick={() => {
                          if (!data.isDeleteAllowed) return
                          handleOpenConfirm(data.taskId)
                        }}
                        title={
                          !data.isDeleteAllowed
                            ? 'Delete option allowed only for user who created the task'
                            : ''
                        }
                        className={'delete-icon' + deleteIconClass}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </ListItem>
              <Divider flexItem />
            </div>
          )
        })}
        {data.length === 0 && (
          <div className='no-result-found'>
            <Grid container className='no-result-found' spacing={2}>
              <Grid xs={12} className='no-result-text'>
                <Typography>
                  <NoResultFound />
                </Typography>
                <Typography>
                  {searchValue ? `No results found for ${searchValue}` : 'No data found'}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    )
  }


  const LoadMore = ({ isLoadingNextPage }: { isLoadingNextPage: boolean }) => {
    if (isLoadingNextPage) {
      return (
        <Box className='load-more-section-loading-container'>
          <Loading />
        </Box>
      )
    }
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className='load-more-container'
        onClick={handlePage}
      >
        <Typography component='span' className='load-more' sx={{ color: `${PRIMARY1}` }}>
          Load More <LoadMoreIcon className='load-more-icon' />
        </Typography>
      </Box>
    )
  }

  return (
    <>
      {!hidelayout ? (
        <Paper elevation={1} className={data.length == 0 ? 'mytask-list-paper' : ''}>
          <ListContainer className={data.length == 0 ? 'mytask-list' : ''}>
            <List>
              <Tasklist />
            </List>
            {data.length < totalTaskCount && <LoadMore isLoadingNextPage={isLoadingNextPage} />}
          </ListContainer>
          <div>
            {open && <TaskModal
              onUpdateSuccess={onUpdateSuccess}
              assignedToUsers={assignedToUsers}
              open={open}
              handleClose={handleClose}
              onCreateSuccess={onCreateSuccess}
              title='Edit Task'
              editdata={editdata}
              mandateOptionsList={mandateOptions}
            />}
          </div>
          {taskDetailShow && <TaskDetailDrawer
            handledrawerClose={handledrawerClose}
            data={taskdata}
            draweropen={opendrawer}
          />}
          <AppDialog
            title='Delete Task'
            subtitle='Are you sure you want to delete the task'
            isOpen={isOpenConfim}
            handleClose={handleCloseConfirm}
            handleConfirm={() => {
              handleDelete(deleteid)
              handleConfirmModal()
            }}
          />
        </Paper>
      ) : (
        <TaskDrawerData data={taskData} />
      )}
    </>
  )
}
