import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/icons/modalcloseicon.svg'
import './UpdateMandateStatus.scss'
import { AppButton } from '../../AppComponents/AppButton'
import { MandateOptionTag } from '../../AppComponents/AppMandateDropdown/AppMandateDropdown'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { getMandateStatusDropdown, mandateStatusUpdate } from '../../../services/MandateService'
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal'

type Tprop = {
  manData: any
  onClose: () => any
  apiCallAfter: () => any
}

interface statusForm {
  mandateId: number
  statusId: number | null
  reason: string
}

const UpdateMandateStatus: React.FC<Tprop> = ({ manData, onClose, apiCallAfter }) => {
  const [statusList, setStatusList] = useState<any[]>([])
  const [successPopup, setSuccessPopup] = useState<boolean>(false)
  const [SuccessMessage, setSuccessMessage] = useState<string>('')
  const [hasError, setHasError] = useState(false)
  const [errorMessage, seterrorMessage] = useState<string>('Server Error')


  const defaultValues: statusForm = {
    mandateId: manData?.id,
    statusId: manData?.status ? manData?.status : null,
    reason: '',
  }

  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<statusForm>({ defaultValues })

  const reasonText = watch('reason')

  const getStatusList = async () => {
    try {
      const reqObj = {
        filters: [
          {
            field: 'workflowGroup',
            operator: 'equals',
            type: 'string',
            value: 'mandate_status',
          },
        ],
        page: {
          pageNo: -1,
          pageSize: 50,
        },
        sorting: {
          direction: 'ASC',
          field: 'statusOrder',
        },
      }
      const res = await getMandateStatusDropdown(reqObj)
      if (res?.status === 200) {
        setStatusList(res?.data?.result?.data ?? [])
      } else {
        setStatusList([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setHasError(false)
  }

  const statusSubmitHandler = async (data: any) => {
    const reqobj = {
      mandateId: manData?.id,
      statusId: data?.statusId,
      reason: data?.reason,
    }
    try {
      const res = await mandateStatusUpdate(reqobj)
      if (res?.status === 200) {
        setSuccessMessage(`Mandate status has been successfully updated.`)
        setSuccessPopup(true)
      } else {
        setHasError(true)
      }
    } catch (error:any) {
      setHasError(true)
      seterrorMessage(error?.response?.data?.error?.errorCode ?? "Server Error")
    }
  }

  useEffect(() => {
    getStatusList()
  }, [])

  useEffect(()=>{
    if(reasonText.length === 255){
      setError('reason', { type: 'custom', message: 'reached maximum limit' });
    }else{
      clearErrors('reason')
    }
  },[reasonText])

  return (
    <Modal
      open={true}
      aria-labelledby='mandate-status-model-title'
      aria-describedby='mandate-status-model'
      className='mandate-status-model'
      onClose={onClose}
    >
      <>
        <Box 
          className='container' 
          component={'form'} 
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(statusSubmitHandler)}
        >
          <Box className='header-wrapper'>
            <Typography>Update status</Typography>
            <CloseIcon style={{cursor:"pointer"}} onClick={onClose} />
          </Box>
          <Box className='body-wrapper'>
            <div className='field-container'>
              <div className='label-text'>Mandate</div>
              <MandateOptionTag
                option={{
                  title: manData?.jobTitle,
                  mandateId: manData?.xphenoId,
                  clientName: manData?.clientName,
                  createdByName: manData?.createdByName,
                }}
                className='man-option-tag'
              />
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Controller
                  control={control}
                  name='statusId'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      label='Status'
                      className='status-list'
                      labelId='mandate-status-label'
                      onChange={onChange}
                    >
                      {statusList?.map((status: any, index: number) => {
                        const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                        return (
                          <MenuItem value={status?.id} key={index} disabled={status?.id === 10}>
                            <span
                              className='status-tag'
                              style={
                                tagStyle
                                  ? {
                                      color: tagStyle?.color,
                                      backgroundColor: tagStyle?.background,
                                    }
                                  : {}
                              }
                            >
                              {status?.statusName}
                            </span>
                          </MenuItem>
                        )
                      })}
                      {statusList?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div className='field-container'>
              <FormControl fullWidth required>
                <Controller
                  control={control}
                  name='reason'
                  rules={{
                    required: {
                        value: true,
                        message: "Reason is required",
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label='Reason'
                      multiline
                      rows={3}
                      value={value}
                      onChange={onChange}
                      required={true}
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
                {errors?.reason && (
                  <span className="reminder_text-danger select-danger-text error-padding" style={{paddingTop:"10px"}} role="alert">
                      {errors?.reason?.message}
                  </span>
                )}
              </FormControl>
            </div>
          </Box>
          <Box className='footer-wrapper'>
            <AppButton
              bgcolor='#E5E8EB'
              onClick={onClose}
              textColor='#000'
              fontSize='14px'
              fontWeight={400}
              label='Cancel'
              width='96px'
            />
            <AppButton
              bgcolor='#002882'
              type='submit'
              textColor='#ffffff'
              fontSize='14px'
              fontWeight={400}
              label={'Update'}
              width='96px'
            />
          </Box>
          <AppErrorHandlerModal
            open={successPopup}
            content={SuccessMessage}
            handleClose={() => {
              setSuccessMessage('')
              setSuccessPopup(false)
              onClose()
              apiCallAfter()
            }}
            factor='Success'
            ButtonText='Done'
          >
            <></>
          </AppErrorHandlerModal>
        </Box>
        <Box>
          <Snackbar
            open={hasError}
            autoHideDuration={5000}
            onClose={handleAlertClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Alert
              onClose={handleAlertClose}
              severity='error'
              sx={{ width: '100%' }}
              variant='filled'
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </Box>
      </>
    </Modal>
  )
}

export default UpdateMandateStatus
