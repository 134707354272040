import { formParserinstance, instance } from './http-common'

export const getChatGPTFetch = async (data:any) => {
  const response = await formParserinstance.post(`chatbot`, data);
  return response;
} 

export const aiChatMessage = async (data:any) => {
  const response = await instance.post(`chat/message/send`, data);
  return response;
} 

export const getAIChatHistory = async (data:any) => {
  const response = await instance.post(`chat/message/history`, data);
  return response;
} 