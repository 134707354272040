import { Box, FormControl, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getRoleList } from '../../services/UserService';
import _ from 'lodash';
import RoleNameSuggestion from '../Mandates/AddMandate/MandateForm/RoleNameSuggestion';

const RoleInformation = ({isEdit}:any) => {
    const { control, formState: { errors },getValues,setValue,clearErrors,trigger } : any = useFormContext()
    const [mandatePreOptions, setMandatePreOptions] = useState([])
    const [mandateOptions, setMandateOptions] = useState([])
    const [mandateSearch, setMandateSearch] = useState("")
    useEffect(() => {
        if (mandateSearch) {
          setMandateOptions(mandatePreOptions.filter((ele: any) => ele?.title?.toLowerCase()?.includes(mandateSearch?.toLowerCase()) || String(ele.id).includes(mandateSearch)))
        } else {
          setMandateOptions([])
        }
      }, [mandateSearch])

      const onSelectSuggestion = (value: string) => {
        setValue("roleName", value)
        setMandateSearch("")
        setMandateOptions([])
      }

      const getData = () => {
        getRoleList().then((res: any) => {
          const dataItems = res?.data?.result?.data || []
          if (dataItems) {
            const options = dataItems.map((data: any) => {
              return {
                title: _.get(data, 'role_name'),
                id: _.get(data, 'id'),
              }
            })
            setMandatePreOptions(options)
          } else {
            setMandatePreOptions([])
          }
        })
      }

      useEffect(() => {
        getData()
      },[])

    return (
        <Grid className='container-wrapper'>
            <Box className="roleInfoWrapper">
                <Box className="roleInfoFieldWrapper">
                
            
            <Grid className='row-grid' style={{width:"calc(((100% / 3) * 2) - 7px)"}}>
            <FormControl fullWidth required className="job-title-box">
                <Controller
                    name="roleName"
                    control={control}
                    rules={{
                    required: {
                        value: true,
                        message: "This field is required",
                    }
                    }}
                    render={({ field: { onChange, value, onBlur } }) => {
                    const handleChange = (e: any) => {
                        onChange(e.target.value)
                        setMandateSearch(e.target.value)
                    }
                    const onBlurred = () => {
                        onBlur()
                        setTimeout(() => setMandateSearch(""), 200);
                    }
                    return (
                        <TextField
                        label='Role Name'
                        required={true}
                        value={value}
                        onChange={handleChange}
                        onBlur={onBlurred}
                        />
                    )
                    }}
                />
                {errors?.roleName && (
                    <span className="reminder_text-danger select-danger-text" role="alert">
                    {errors?.roleName?.message}
                    </span>
                )}
                {(mandateSearch && mandateOptions?.length) ? <RoleNameSuggestion mandateOptions={mandateOptions} onSelect={onSelectSuggestion} /> : <></>}
            </FormControl>            
                <FormControl fullWidth required>
                    <Controller
                        name="roleDescription"
                        control={control}
                        rules={{
                          required: {
                              value: true,
                              message: "This field is required",
                          }
                          }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Description'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            required={true}
                            multiline={true}
                            minRows={3}
                            inputProps={{ maxLength: 256 }}                
                        />
                        )}
                    />
                    {errors?.roleDescription && (
                    <span className="reminder_text-danger select-danger-text" role="alert">
                    {errors?.roleDescription?.message}
                    </span>
                )}
                </FormControl>
            </Grid>
                </Box>
                
            </Box>            
        </Grid>
    );
};

export default RoleInformation;