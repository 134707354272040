import { TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type MyTextFieldProps = {
  borderColor?: string
  borderRadius?: string
  svgcolor?:string
  name:string
} & TextFieldProps

export const CssTextField = styled(TextField)<MyTextFieldProps>(({borderColor,svgcolor}) => ({
  '& label.Mui-focused': {
    color: '#5e5b5d',
    fontWeight: 400,
  },
  '& .MuiInputBase-input': {
    borderColor: '#c8d0d4',
  },
  '& .MuiInput-underline:after': {
    border: 'none',
  },
  '& svg': {
    fill: svgcolor,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red',
      },
    },
    '& fieldset': {
      borderColor: borderColor,
      borderRadius: '10px',
    },
    '&:hover fieldset': {
      border: '1px solid #c8d0d4',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #c8d0d4',
    },
  },
}))

export const AppTextField: FC<MyTextFieldProps> = ({ name, value, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  return (
    <Controller
    control={control}
    name={name}
    
    defaultValue=''
    render={({ field }) => (
      <CssTextField
        {...field}
        {...otherProps}
        variant='outlined'
        sx={{ mb: '1.5rem' }}
        error={!!errors[name]}
        multiline = {otherProps?.multiline ? otherProps?.multiline : false}
        rows={otherProps?.rows ? otherProps?.rows : 1}
        helperText={errors[name] ? (errors[name]?.message as unknown as string) : ''}
      />
    )}
  />

  )

}