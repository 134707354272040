import _ from 'lodash';
import { objectToQueryString } from '../utils/common-utils';
import { instance } from './http-common';

export const getNotificationApi = async (obj: any) => {
    const response = await instance.post(`notification/log/get/notification`, obj);
    if(response?.status===200 || response?.status===201){
        return response?.data;
    }
} 
export const getNotificationCount = async (obj: any) => {
    const response = await instance.post(`notification/log/unread/count`, obj);
    if(response?.status===200 || response?.status===201){
        return response?.data;
    }
} 
export const updateNotificationStatus = async (obj: any) => {
    const response = await instance.post(`notification/log/update/read/status`, obj);
    if(response?.status===200 || response?.status===201){
        return response?.data;
    }
} 
export const callLogAllList = async (obj: any) => {
    const queryString = objectToQueryString({
        page: _.get(obj, 'page'),
        limit: _.get(obj, 'limit'),
        fromDate: _.get(obj, 'fromDate'),
        toDate: _.get(obj, 'toDate'),
        direction: _.get(obj, 'direction'),
        callType: _.get(obj, 'callType'),
        destination: null
    });
    const response = await instance.get(`notification/smartflo/agent/call/records?${queryString}`);
    if(response?.status===200 || response?.status===201){
        return response?.data;
    }
}