import React, { useEffect, useState } from 'react';
import {Grid,FormControl,TextField,Select,MenuItem,InputLabel,Autocomplete, Checkbox, ListItemText, OutlinedInput,} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as CalenderIcon } from '../../assets/icons/input-calendar.svg'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { dropDownLocation } from '../../services/MandateService';
import { getBussinessUnit, getRoleList, getUserCreateReportingTo, getUserReportingTo } from '../../services/UserService';
import _ from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EmployeeDetails = () => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { control, formState: { errors },getValues,setValue,clearErrors,trigger } : any = useFormContext()
    const [employeeLocation, setEmployeeLocation] = useState([
        {
            id:1,
            name: "Bengaluru"
        },
        {
            id:2,
            name: "Udupi"
        },
        {
            id:3,
            name: "Hyderabad"
        },
        {
            id:4,
            name: "Chennai"
        },
        {
            id:5,
            name: "Mumbai"
        }
    ])
    const [employeeBussinessUnit, setEmployeeBussinessUnit] = useState([])
    const [employeeRoleList, setEmployeeRoleList] = useState([])
    // const [reportingToDisable,setReportingToDisable] = useState(true)
    const [reportingToList, setReportingToList] = useState([])
    // const fetchLocations = async () => {
    //     const reqObj = {
    //       "filters": [
    //           {
    //               "field": "parentId",
    //               "operator": "equals",
    //               "type": "string",
    //               "value": "0"
    //           }
    //       ],
    //       "page": {
    //           "pageNo": -1,
    //           "pageSize": 10
    //       },
    //       "sorting": {
    //           "direction": "ASC",
    //           "field": "createdAt"
    //       }
    //   }
    //     try{
    //       const res:any = await dropDownLocation(reqObj)
    //       setEmployeeLocation(res.data.result.list)
    //     }
    //     catch(error:any){
    //         console.log(error)        
    //       }
    // }

    const getBussinessUnitDetailsApi = async () => {
        try{
          const res:any = await getBussinessUnit()
          setEmployeeBussinessUnit(res.data)
        }
        catch(error:any){
            console.log(error)        
          }
    }

    const getReportingToApi = async () => {
        const bussinessUnit = getValues("bussinessUnit");
        const roleReporting = getValues("roleReporting");
        try{
            const res:any = await getUserCreateReportingTo(roleReporting !=  undefined ? roleReporting : "",bussinessUnit != undefined ? bussinessUnit : "")
        //   setReportingToDisable(false)
          const options = _.map(res.data, (data: any) => {
            return {
              label: _.get(data, 'firstName'),
              value: _.get(data, 'id')
            }})
        //   setReportingToDisable(false)
          setReportingToList(options)
          const currentValue = options.filter((item:any) => item.value == getValues("reportingTo"))[0]
          setValue("reportingTo", currentValue ? currentValue : "")
        }
        catch(error:any){
            console.log(error)        
          }
    }

    const getRoleListApi = async () => {
        try{
          const res:any = await getRoleList()
          setEmployeeRoleList(res.data.result.data)
        }
        catch(error:any){
            console.log(error)        
          }
    }

    useEffect(() => {
        // fetchLocations();
        getBussinessUnitDetailsApi();
        getRoleListApi();
        getReportingToApi()
    },[])
    return (
        <Grid className='container-wrapper'>
            <Grid className='row-grid mb-3'>
                <FormControl fullWidth required >
                    <Controller
                        name="employeeCode"
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Employee Code'
                            required={true}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                    {errors?.employeeCode && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.employeeCode?.message}
                        </span>
                    )}
                </FormControl> 
                <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Controller
                        name={"role"}
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Role"
                        onChange={(e:any) => {
                            onChange(e.target.value)                            
                        }}
                        >
                        {employeeRoleList.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.id}>{item?.role_name}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {errors?.role && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.role?.message}
                        </span>
                    )}
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <Controller
                        name="xphenoJoinDate"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "This field is required",
                            }
                        }}
                        render={({field : {onChange, ...restField}})=>(
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',width:"100%"}}>
                            <DatePicker
                            label='Xpheno Joining Date'
                            className='datePicker'
                            inputFormat="DD/MM/YYYY"
                            onChange={(e:any)=>{onChange(e)}}
                            components={{
                                OpenPickerIcon: CalenderIcon,
                            }}
                            // minDate={moment().subtract(1,'years').toDate()}
                            // maxDate={moment().toDate()}
                            renderInput={(params:any) => (
                                <TextField
                                    name='Xpheno Joining Date'
                                    style={{ width: "100%" }}
                                    required={true}
                                    {...params}
                                />
                            )}
                            {...restField}
                        />
                        {errors?.xphenoJoinDate && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.xphenoJoinDate?.message}
                        </span>
                        )}
                        </div>
                        )}
                        />
                    </LocalizationProvider>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <Controller
                        name="careerStartDate"
                        control={control}
                        render={({field : {onChange, ...restField}})=>(
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch',width:"100%"}}>
                            <DatePicker
                            label='Career Start Date'
                            className='datePicker'
                            inputFormat="DD/MM/YYYY"
                            onChange={(e:any)=>{onChange(e)}}
                            components={{
                                OpenPickerIcon: CalenderIcon,
                            }}
                            // minDate={moment().subtract(1,'years').toDate()}
                            // maxDate={moment().toDate()}
                            renderInput={(params:any) => (
                                <TextField
                                    name='Career Start Date'
                                    style={{ width: "100%" }}
                                    required={false}
                                    {...params}
                                />
                            )}
                            {...restField}
                        />
                        </div>
                        )}
                        />
                    </LocalizationProvider> */}
                    <FormControl fullWidth required >
                    <Controller
                        name="previousExperience"
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Previous Experience'
                            required={true}
                            type='number'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                    {errors?.previousExperience && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.previousExperience?.message}
                        </span>
                    )}
                </FormControl> 
            </Grid>
            <Grid className='row-grid mb-3'>
                <FormControl fullWidth required >
                    <Controller
                        name="designation"
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({field : {onChange, value, onBlur}})=>(
                        <TextField
                            label='Designation'
                            required={true}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                    />
                    {errors?.designation && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.designation?.message}
                        </span>
                    )}
                </FormControl> 
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Bussiness Unit</InputLabel>
                    <Controller
                        name={"bussinessUnit"}
                        control={control}
                        // rules={{
                        // required: {
                        //     value: true,
                        //     message: "This field is required",
                        // }
                        // }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Bussiness Unit"
                        onChange={(e:any) => {
                            onChange(e.target.value)
                            getReportingToApi()
                        }}
                        >
                        {employeeBussinessUnit.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.id}>{item?.businessUnitName}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {/* {errors?.bussinessUnit && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.bussinessUnit?.message}
                        </span>
                    )} */}
                </FormControl>
                <FormControl fullWidth>
                    <Controller
                        name={"domain"}
                        control={control}                        
                        render={({ field: { onChange, value, onBlur } }) => (
                            <TextField
                            label='Domain'
                            required={false}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        )}
                        />
                        {/* {errors?.reportingTo && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.reportingTo?.message}
                        </span>
                        )} */}
                </FormControl>
                <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Employee Location</InputLabel>
              <Controller
                  name="employmentLocation"
                  control={control}
                  rules={{
                  required: {
                      value: true,
                      message: "This field is required",
                  }
                  }}
                  render={({field : {onChange, value, onBlur}})=>{
                    return(                   
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Employee Location"
                        onChange={(e:any) => {
                            onChange(e.target.value)
                        }}
                        >
                        {employeeLocation.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                            ))}
                        </Select>
                    )                    
                  }}
                />
                {errors?.employmentLocation && (
                    <span className="reminder_text-danger select-danger-text" role="alert">
                        {errors?.employmentLocation?.message}
                    </span>
                )}
              </FormControl>
            </Grid>
            <Grid className='row-grid mb-3'>            
              <FormControl fullWidth style={{width:"calc((100% / 4) - 15px)"}}>
                    <InputLabel id="demo-simple-select-label">Reporting Role</InputLabel>
                    <Controller
                        name={"roleReporting"}
                        control={control}
                        // rules={{
                        // required: {
                        //     value: true,
                        //     message: "This field is required",
                        // }
                        // }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Reporting Role"
                        onChange={(e:any) => {
                            onChange(e.target.value)
                            // setValue("role", e.target.value)
                            getReportingToApi()
                        }}
                        >
                        {employeeRoleList.map((item:any,index:number) => (
                            <MenuItem key={item?.id} value={item?.id}>{item?.role_name}</MenuItem>
                            ))}
                        </Select>
                        )}
                        />
                        {/* {errors?.role && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.role?.message}
                        </span>
                    )} */}
                </FormControl>
                <FormControl fullWidth style={{width:"calc((100% / 4) - 15px)"}}>
                    {/* <InputLabel id="demo-simple-select-label">Reporting to</InputLabel> */}
                    <Controller
                        name={"reportingTo"}
                        control={control}
                        rules={{
                        required: {
                            value: true,
                            message: "This field is required",
                        }
                        }}
                        render={({ field: { onChange, value, onBlur } }) => (
                        // <Select
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        // disabled={reportingToDisable}
                        // value={value}
                        // label="Reporting to"
                        // onChange={(e:any) => {
                        //     onChange(e.target.value)
                        // }}
                        // >
                        // {reportingToList.map((item:any,index:number) => (
                        //     <MenuItem key={item?.id} value={item?.id}>{item?.firstName}</MenuItem>
                        //     ))}
                        // </Select>
                        <Autocomplete                                
                            // disabled={reportingToDisable}
                            options={reportingToList}
                            // defaultValue={getValues("reportingTo")}
                            value={value}
                            onChange={(event, value) => {
                            onChange(value)
                            }}
                            renderInput={(params) => <TextField required={true} {...params} label={`Reporting To`} />}
                        />
                        )}
                        />
                        {errors?.reportingTo && (
                        <span className="reminder_text-danger select-danger-text" role="alert">
                            {errors?.reportingTo?.message}
                        </span>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default EmployeeDetails;